import React, { CSSProperties } from 'react';
import { Grid } from '@material-ui/core';

// import frontpageConstants from '../../../page/frontpage/constants.json';
import footer_logo from '../../../element/2024feb/logo2024_footer.png'

const styles: Record<string, CSSProperties> = {
    container: {
        background: '#4F4F4F',
        fontSize: '14px',
        position: 'relative',
        bottom: '0',
        padding: '50px 0 20px 0',
    },
    homepageLink: {
        textDecoration: 'none',
        fontFamily: 'Covered By Your Grace',
        color: '#FFF',
        fontSize: "26px",
        margin: "0 20px 0 40px",
    },
    link: {
        color: '#FFF',
        margin: '0 20px',
    },
    copyright: {
        fontFamily: 'Arial',
        color: 'white',
        float: 'right',
        margin: '0',
        marginRight: '80px',
        fontSize:"14px"
    },
};

const copyrightText = '© SEAM Studio 2024. All rights reserved';

export const FooterPanel = () => {
    return (
        <Grid
            container
            justify="flex-start"
            alignItems="center"
            style={styles.container}
        >
            <Grid item xs={6}>
                <a
                    href="/"
                    style={styles.homepageLink}
                >
                    <img style={{height:"30px"}} src={footer_logo} />
                </a>
                {/* <Link
                    href={frontpageConstants.links.introduction.src}
                    style={styles.link}
                >
                    About
                </Link>
                <Link href="#" style={styles.link}>
                    News
                </Link>
                <Link
                    href={frontpageConstants.links.support.src}
                    style={styles.link}
                >
                    Contact
                </Link> */}
            </Grid>
            <Grid item xs={6}>
                <p style={styles.copyright}>
                    {copyrightText}
                </p>
            </Grid>
        </Grid>
    );
};

export default FooterPanel;
