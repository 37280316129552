import Store from './store';

export default class User extends Store {
    _id;

    displayName;

    email;

    name;

  store = {
    _id: null,
    displayName: null,
  }


  constructor(props) {
    super(props);
    return this.setup(props);
  }

  static mock() {
    return new User({
      "email": "default@seamjam.com",
      "name": {
        "last": "default",
        "first": "default"
      },
    });
  }

}

