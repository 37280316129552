import { clientURI } from "../../../../api/clientURI";
import { UPDATE_JAMBUILDER, UPDATE_JAMBUILDER_NAME, UPDATE_JAMBUILDER_GENERAL } from "../../../../api/graphql-mutation";

export const defaultTemplate = [
  {
    id: 0,
    childNode: [1],
    type: "Game",
    name: "OUAT",
  },
  {
    id: 1,
    childNode: [2],
    parentNode: 0,
    type: "Phase",
    name: "Round",
    repeat: "Forever",
    repeatNum: 0,
    events: [
      {
        id: 0,
        name: " ",
        success: null,
        fail: null,
        nextId: null,
      },
    ],
  },
  {
    id: 2,
    childNode: [3, 8],
    parentNode: 1,
    type: "Turn Structure",
    name: "Turn A",
    repeat: "Forever",
    repeatNum: 0,
    targetedRole: "Storyteller",
    events: [
      {
        id: 0,
        name: " ",
        success: null,
        fail: null,
        nextId: null,
      },
    ],
  },
  {
    id: 3,
    childNode: [4],
    parentNode: 2,
    type: "Turn Type",
    name: "In Turn",
    instruction: [
      {
        role: "Storyteller",
        messageList: [
          { type: "Default", check: true, message: "" },
          { type: "Interrupted", check: true, message: "" },
        ],
      },
    ],
  },
  {
    id: 4,
    childNode: [5],
    parentNode: 3,
    type: "Role",
    name: "Storyteller",
    events: [
      {
        id: 0,
        name: " ",
        success: null,
        fail: null,
        nextId: null,
      },
    ],
  },
  {
    id: 5,
    childNode: [6],
    parentNode: 4,
    type: "Steps",
    name: "Step 1",
  },
  {
    id: 6,
    childNode: [7],
    parentNode: 5,
    type: "Key Action",
    name: "Unlimited",
  },
  {
    id: 7,
    childNode: [],
    parentNode: 6,
    type: "Action", 
    card: [],
    name: "Add Card",
    endStep: "Go To Event",
    shiftTo: "Next Step",
    "Add Card": {
      placement: "Canvas",
      cardType: "Any",
      card: "Blank",
    },
    "Pass Turn": {
      passType: "Next Player",
    },
    events: [
      {
        id: 0,
        name: " ",
        success: null,
        fail: null,
        nextId: null,
      },
    ],
  },
  {
    id: 8,
    childNode: [9],
    parentNode: 2,
    type: "Turn Type",
    name: "Out Turn",
    instruction: [
      {
        role: "Storyteller",
        messageList: [
          { type: "Default", check: true, message: "" },
          { type: "Interrupted", check: true, message: "" },
        ],
      },
    ],
  },
  {
    id: 9,
    childNode: [10],
    parentNode: 8,
    type: "Role",
    name: "Storyteller",
    events: [
      {
        id: 0,
        name: " ",
        success: null,
        fail: null,
        nextId: null,
      },
    ],
  },
  {
    id: 10,
    childNode: [11],
    parentNode: 9,
    type: "Steps",
    name: "Step 1",
  },
  {
    id: 11,
    childNode: [12],
    parentNode: 10,
    type: "Key Action",
    name: "Unlimited",
  },
  {
    id: 12,
    childNode: [],
    parentNode: 11,
    type: "Action", 
    card: [],
    name: "Add Card",
    endStep: "Go To Event",
    shiftTo: "Next Step",
    "Add Card": {
      placement: "Canvas",
      cardType: "Any",
      card: "Blank",
    },
    "Pass Turn": {
      passType: "Next Player",
    },
    events: [
      {
        id: 0,
        name: " ",
        success: null,
        fail: null,
        nextId: null,
      },
    ],
  },
];

export const storyGameTemplate = [
  {
    "id": 0,
    "childNode": [
      1
    ],
    "type": "Game",
    "name": "OUAT"
  },
  {
    "id": 1,
    "childNode": [
      2
    ],
    "parentNode": 0,
    "type": "Phase",
    "name": "Round",
    "repeat": "Forever",
    "repeatNum": 0,
    "events": [
      {
        "id": 0,
        "name": " ",
        "success": null,
        "fail": null,
        "nextId": null
      }
    ]
  },
  {
    "id": 2,
    "childNode": [
      3,
      8
    ],
    "parentNode": 1,
    "type": "Turn Structure",
    "name": "Turn A",
    "repeat": "Forever",
    "repeatNum": 0,
    "targetedRole": "Storyteller",
    "events": [
      {
        "id": 0,
        "name": " ",
        "success": null,
        "fail": null,
        "nextId": null
      }
    ]
  },
  {
    "id": 3,
    "childNode": [
      4
    ],
    "parentNode": 2,
    "type": "Turn Type",
    "name": "In Turn",
    "instruction": [
      {
        "role": "Storyteller",
        "messageList": [
          {
            "type": "Default",
            "check": true,
            "message": ""
          },
          {
            "type": "Interrupted",
            "check": true,
            "message": ""
          }
        ]
      }
    ]
  },
  {
    "id": 4,
    "childNode": [
      5
    ],
    "parentNode": 3,
    "type": "Role",
    "name": "Storyteller",
    "events": [
      {
        "id": 0,
        "name": " ",
        "success": null,
        "fail": null,
        "nextId": null
      }
    ]
  },
  {
    "id": 5,
    "childNode": [
      6,
      26
    ],
    "parentNode": 4,
    "type": "Steps",
    "name": "Step 1",
    "events": [
      {
        "id": 0,
        "name": " ",
        "success": null,
        "fail": null,
        "nextId": null
      }
    ]
  },
  {
    "id": 6,
    "childNode": [
      7,
      13,
      14,
      15,
      16,
      17,
      18,
      19
    ],
    "parentNode": 5,
    "type": "Key Action",
    "name": "Unlimited"
  },
  {
    "id": 7,
    "childNode": [],
    "parentNode": 6,
    "type": "Action",
    "card": [],
    "name": "Add Card",
    "endStep": "Go To Event",
    "shiftTo": "Next Step",
    "Add Card": {
      "placement": "Both",
      "cardType": "Any",
      "card": "Blank"
    },
    "Pass Turn": {
      "passType": "Next Player"
    },
    "events": [
      {
        "id": 0,
        "name": " ",
        "success": null,
        "fail": null,
        "nextId": null
      }
    ]
  },
  {
    "id": 8,
    "childNode": [
      9
    ],
    "parentNode": 2,
    "type": "Turn Type",
    "name": "Out Turn",
    "instruction": [
      {
        "role": "Storyteller",
        "messageList": [
          {
            "type": "Default",
            "check": true,
            "message": ""
          },
          {
            "type": "Interrupted",
            "check": true,
            "message": ""
          }
        ]
      }
    ]
  },
  {
    "id": 9,
    "childNode": [
      10
    ],
    "parentNode": 8,
    "type": "Role",
    "name": "Storyteller",
    "events": [
      {
        "id": 0,
        "name": " ",
        "success": null,
        "fail": null,
        "nextId": null
      }
    ]
  },
  {
    "id": 10,
    "childNode": [
      11
    ],
    "parentNode": 9,
    "type": "Steps",
    "name": "Step 1",
    "events": [
      {
        "id": 0,
        "name": " ",
        "success": null,
        "fail": null,
        "nextId": null
      }
    ]
  },
  {
    "id": 11,
    "childNode": [
      25
    ],
    "parentNode": 10,
    "type": "Key Action",
    "name": "Unlimited"
  },
  {
    "id": 13,
    "childNode": [],
    "parentNode": 6,
    "type": "Action",
    "card": [],
    "name": "Delete Card",
    "endStep": "Go To Event",
    "shiftTo": "Next Step",
    "Add Card": {
      "placement": "Canvas",
      "cardType": "Any",
      "card": "Blank"
    },
    "Pass Turn": {
      "passType": "Next Player"
    },
    "events": [
      {
        "id": 0,
        "name": " ",
        "success": null,
        "fail": null,
        "nextId": null
      }
    ]
  },
  {
    "id": 14,
    "childNode": [],
    "parentNode": 6,
    "type": "Action",
    "card": [],
    "name": "Retrieve Card",
    "endStep": "Go To Event",
    "shiftTo": "Next Step",
    "Add Card": {
      "placement": "Canvas",
      "cardType": "Any",
      "card": "Blank"
    },
    "Pass Turn": {
      "passType": "Next Player"
    },
    "events": [
      {
        "id": 0,
        "name": " ",
        "success": null,
        "fail": null,
        "nextId": null
      }
    ]
  },
  {
    "id": 15,
    "childNode": [],
    "parentNode": 6,
    "type": "Action",
    "card": [],
    "name": "Drag Card",
    "endStep": "Go To Event",
    "shiftTo": "Next Step",
    "Add Card": {
      "placement": "Canvas",
      "cardType": "Any",
      "card": "Blank"
    },
    "Pass Turn": {
      "passType": "Next Player"
    },
    "events": [
      {
        "id": 0,
        "name": " ",
        "success": null,
        "fail": null,
        "nextId": null
      }
    ]
  },
  {
    "id": 16,
    "childNode": [],
    "parentNode": 6,
    "type": "Action",
    "card": [],
    "name": "Flip Card",
    "endStep": "Go To Event",
    "shiftTo": "Next Step",
    "Add Card": {
      "placement": "Canvas",
      "cardType": "Any",
      "card": "Blank"
    },
    "Pass Turn": {
      "passType": "Next Player"
    },
    "events": [
      {
        "id": 0,
        "name": " ",
        "success": null,
        "fail": null,
        "nextId": null
      }
    ]
  },
  {
    "id": 17,
    "childNode": [],
    "parentNode": 6,
    "type": "Action",
    "card": [],
    "name": "Pair Card",
    "endStep": "Go To Event",
    "shiftTo": "Next Step",
    "Add Card": {
      "placement": "Canvas",
      "cardType": "Any",
      "card": "Blank"
    },
    "Pass Turn": {
      "passType": "Next Player"
    },
    "events": [
      {
        "id": 0,
        "name": " ",
        "success": null,
        "fail": null,
        "nextId": null
      }
    ]
  },
  {
    "id": 18,
    "childNode": [],
    "parentNode": 6,
    "type": "Action",
    "card": [],
    "name": "Pin Card",
    "endStep": "Go To Event",
    "shiftTo": "Next Step",
    "Add Card": {
      "placement": "Canvas",
      "cardType": "Any",
      "card": "Blank"
    },
    "Pass Turn": {
      "passType": "Next Player"
    },
    "events": [
      {
        "id": 0,
        "name": " ",
        "success": null,
        "fail": null,
        "nextId": null
      }
    ]
  },
  {
    "id": 19,
    "childNode": [],
    "parentNode": 6,
    "type": "Action",
    "card": [],
    "name": "Stack Card",
    "endStep": "Go To Event",
    "shiftTo": "Next Step",
    "Add Card": {
      "placement": "Canvas",
      "cardType": "Any",
      "card": "Blank"
    },
    "Pass Turn": {
      "passType": "Next Player"
    },
    "events": [
      {
        "id": 0,
        "name": " ",
        "success": null,
        "fail": null,
        "nextId": null
      }
    ]
  },
  {
    "id": 25,
    "childNode": [],
    "parentNode": 11,
    "type": "Action",
    "card": [],
    "name": "Pin Card",
    "endStep": "Go To Event",
    "shiftTo": "Next Step",
    "Add Card": {
      "placement": "Canvas",
      "cardType": "Any",
      "card": "Blank"
    },
    "Pass Turn": {
      "passType": "Next Player"
    },
    "events": [
      {
        "id": 0,
        "name": " ",
        "success": null,
        "fail": null,
        "nextId": null
      }
    ]
  },
  {
    "id": 26,
    "childNode": [
      27
    ],
    "parentNode": 5,
    "type": "Key Action",
    "name": "Single"
  },
  {
    "id": 27,
    "childNode": [],
    "parentNode": 26,
    "type": "Action",
    "card": [],
    "name": "Pass Turn",
    "endStep": "Shift To",
    "shiftTo": "Next Player",
    "Add Card": {
      "placement": "Canvas",
      "cardType": "Any",
      "card": "Blank"
    },
    "Pass Turn": {
      "passType": "Next Player"
    },
    "events": [
      {
        "id": 0,
        "name": " ",
        "success": null,
        "fail": null,
        "nextId": null
      }
    ]
  }
];

export const freeStyleTemplate = [
  {
      "id": 0,
      "childNode": [
      1
      ],
      "type": "Game",
      "name": "OUAT"
  },
  {
      "id": 1,
      "childNode": [
      2
      ],
      "parentNode": 0,
      "type": "Phase",
      "name": "Round",
      "repeat": "Forever",
      "repeatNum": 0,
      "events": [
      {
          "id": 0,
          "name": " ",
          "success": null,
          "fail": null,
          "nextId": null
      }
      ]
  },
  {
      "id": 2,
      "childNode": [
      3,
      8
      ],
      "parentNode": 1,
      "type": "Turn Structure",
      "name": "Turn A",
      "repeat": "Forever",
      "repeatNum": 0,
      "targetedRole": "Storyteller",
      "events": [
      {
          "id": 0,
          "name": " ",
          "success": null,
          "fail": null,
          "nextId": null
      }
      ]
  },
  {
      "id": 3,
      "childNode": [
      4
      ],
      "parentNode": 2,
      "type": "Turn Type",
      "name": "In Turn",
      "instruction": [
      {
          "role": "Storyteller",
          "messageList": [
          {
              "type": "Default",
              "check": true,
              "message": ""
          },
          {
              "type": "Interrupted",
              "check": true,
              "message": ""
          }
          ]
      }
      ]
  },
  {
      "id": 4,
      "childNode": [
      5
      ],
      "parentNode": 3,
      "type": "Role",
      "name": "Storyteller",
      "events": [
      {
          "id": 0,
          "name": " ",
          "success": null,
          "fail": null,
          "nextId": null
      }
      ]
  },
  {
      "id": 5,
      "childNode": [
      6
      ],
      "parentNode": 4,
      "type": "Steps",
      "name": "Step 1",
      "events": [
        {
          "id": 0,
          "name": " ",
          "success": null,
          "fail": null,
          "nextId": null
        }
      ]
  },
  {
      "id": 6,
      "childNode": [
      7,
      13,
      14,
      15,
      16,
      17,
      18,
      19
      ],
      "parentNode": 5,
      "type": "Key Action",
      "name": "Unlimited"
  },
  {
      "id": 7,
      "childNode": [],
      "parentNode": 6,
      "type": "Action", 
      "card": [],
      "name": "Add Card",
      "endStep": "Go To Event",
      "shiftTo": "Next Step",
      "Add Card": {
      "placement": "Both",
      "cardType": "Any",
      "card": "Blank"
      },
      "Pass Turn": {
      "passType": "Next Player"
      },
      "events": [
      {
          "id": 0,
          "name": " ",
          "success": null,
          "fail": null,
          "nextId": null
      }
      ]
  },
  {
      "id": 8,
      "childNode": [
      9
      ],
      "parentNode": 2,
      "type": "Turn Type",
      "name": "Out Turn",
      "instruction": [
      {
          "role": "Storyteller",
          "messageList": [
          {
              "type": "Default",
              "check": true,
              "message": ""
          },
          {
              "type": "Interrupted",
              "check": true,
              "message": ""
          }
          ]
      }
      ]
  },
  {
      "id": 9,
      "childNode": [
      10
      ],
      "parentNode": 8,
      "type": "Role",
      "name": "Storyteller",
      "events": [
      {
          "id": 0,
          "name": " ",
          "success": null,
          "fail": null,
          "nextId": null
      }
      ]
  },
  {
      "id": 10,
      "childNode": [
      11
      ],
      "parentNode": 9,
      "type": "Steps",
      "name": "Step 1",
      "events": [
        {
          "id": 0,
          "name": " ",
          "success": null,
          "fail": null,
          "nextId": null
        }
      ]
  },
  {
      "id": 11,
      "childNode": [
      12,
      20,
      21,
      22,
      23,
      24,
      25,
      26
      ],
      "parentNode": 10,
      "type": "Key Action",
      "name": "Unlimited"
  },
  {
      "id": 12,
      "childNode": [],
      "parentNode": 11,
      "type": "Action", 
      "card": [],
      "name": "Add Card",
      "endStep": "Go To Event",
      "shiftTo": "Next Step",
      "Add Card": {
      "placement": "Both",
      "cardType": "Any",
      "card": "Blank"
      },
      "Pass Turn": {
      "passType": "Next Player"
      },
      "events": [
      {
          "id": 0,
          "name": " ",
          "success": null,
          "fail": null,
          "nextId": null
      }
      ]
  },
  {
      "id": 13,
      "childNode": [],
      "parentNode": 6,
      "type": "Action", 
      "card": [],
      "name": "Delete Card",
      "endStep": "Go To Event",
      "shiftTo": "Next Step",
      "Add Card": {
      "placement": "Canvas",
      "cardType": "Any",
      "card": "Blank"
      },
      "Pass Turn": {
      "passType": "Next Player"
      },
      "events": [
      {
          "id": 0,
          "name": " ",
          "success": null,
          "fail": null,
          "nextId": null
      }
      ]
  },
  {
      "id": 14,
      "childNode": [],
      "parentNode": 6,
      "type": "Action", 
      "card": [],
      "name": "Retrieve Card",
      "endStep": "Go To Event",
      "shiftTo": "Next Step",
      "Add Card": {
      "placement": "Canvas",
      "cardType": "Any",
      "card": "Blank"
      },
      "Pass Turn": {
      "passType": "Next Player"
      },
      "events": [
      {
          "id": 0,
          "name": " ",
          "success": null,
          "fail": null,
          "nextId": null
      }
      ]
  },
  {
      "id": 15,
      "childNode": [],
      "parentNode": 6,
      "type": "Action", 
      "card": [],
      "name": "Drag Card",
      "endStep": "Go To Event",
      "shiftTo": "Next Step",
      "Add Card": {
      "placement": "Canvas",
      "cardType": "Any",
      "card": "Blank"
      },
      "Pass Turn": {
      "passType": "Next Player"
      },
      "events": [
      {
          "id": 0,
          "name": " ",
          "success": null,
          "fail": null,
          "nextId": null
      }
      ]
  },
  {
      "id": 16,
      "childNode": [],
      "parentNode": 6,
      "type": "Action", 
      "card": [],
      "name": "Flip Card",
      "endStep": "Go To Event",
      "shiftTo": "Next Step",
      "Add Card": {
      "placement": "Canvas",
      "cardType": "Any",
      "card": "Blank"
      },
      "Pass Turn": {
      "passType": "Next Player"
      },
      "events": [
      {
          "id": 0,
          "name": " ",
          "success": null,
          "fail": null,
          "nextId": null
      }
      ]
  },
  {
      "id": 17,
      "childNode": [],
      "parentNode": 6,
      "type": "Action", 
      "card": [],
      "name": "Pair Card",
      "endStep": "Go To Event",
      "shiftTo": "Next Step",
      "Add Card": {
      "placement": "Canvas",
      "cardType": "Any",
      "card": "Blank"
      },
      "Pass Turn": {
      "passType": "Next Player"
      },
      "events": [
      {
          "id": 0,
          "name": " ",
          "success": null,
          "fail": null,
          "nextId": null
      }
      ]
  },
  {
      "id": 18,
      "childNode": [],
      "parentNode": 6,
      "type": "Action", 
      "card": [],
      "name": "Pin Card",
      "endStep": "Go To Event",
      "shiftTo": "Next Step",
      "Add Card": {
      "placement": "Canvas",
      "cardType": "Any",
      "card": "Blank"
      },
      "Pass Turn": {
      "passType": "Next Player"
      },
      "events": [
      {
          "id": 0,
          "name": " ",
          "success": null,
          "fail": null,
          "nextId": null
      }
      ]
  },
  {
      "id": 19,
      "childNode": [],
      "parentNode": 6,
      "type": "Action", 
      "card": [],
      "name": "Stack Card",
      "endStep": "Go To Event",
      "shiftTo": "Next Step",
      "Add Card": {
      "placement": "Canvas",
      "cardType": "Any",
      "card": "Blank"
      },
      "Pass Turn": {
      "passType": "Next Player"
      },
      "events": [
      {
          "id": 0,
          "name": " ",
          "success": null,
          "fail": null,
          "nextId": null
      }
      ]
  },
  {
      "id": 20,
      "childNode": [],
      "parentNode": 11,
      "type": "Action", 
      "card": [],
      "name": "Retrieve Card",
      "endStep": "Go To Event",
      "shiftTo": "Next Step",
      "Add Card": {
      "placement": "Canvas",
      "cardType": "Any",
      "card": "Blank"
      },
      "Pass Turn": {
      "passType": "Next Player"
      },
      "events": [
      {
          "id": 0,
          "name": " ",
          "success": null,
          "fail": null,
          "nextId": null
      }
      ]
  },
  {
      "id": 21,
      "childNode": [],
      "parentNode": 11,
      "type": "Action", 
      "card": [],
      "name": "Delete Card",
      "endStep": "Go To Event",
      "shiftTo": "Next Step",
      "Add Card": {
      "placement": "Canvas",
      "cardType": "Any",
      "card": "Blank"
      },
      "Pass Turn": {
      "passType": "Next Player"
      },
      "events": [
      {
          "id": 0,
          "name": " ",
          "success": null,
          "fail": null,
          "nextId": null
      }
      ]
  },
  {
      "id": 22,
      "childNode": [],
      "parentNode": 11,
      "type": "Action", 
      "card": [],
      "name": "Drag Card",
      "endStep": "Go To Event",
      "shiftTo": "Next Step",
      "Add Card": {
      "placement": "Canvas",
      "cardType": "Any",
      "card": "Blank"
      },
      "Pass Turn": {
      "passType": "Next Player"
      },
      "events": [
      {
          "id": 0,
          "name": " ",
          "success": null,
          "fail": null,
          "nextId": null
      }
      ]
  },
  {
      "id": 23,
      "childNode": [],
      "parentNode": 11,
      "type": "Action", 
      "card": [],
      "name": "Flip Card",
      "endStep": "Go To Event",
      "shiftTo": "Next Step",
      "Add Card": {
      "placement": "Canvas",
      "cardType": "Any",
      "card": "Blank"
      },
      "Pass Turn": {
      "passType": "Next Player"
      },
      "events": [
      {
          "id": 0,
          "name": " ",
          "success": null,
          "fail": null,
          "nextId": null
      }
      ]
  },
  {
      "id": 24,
      "childNode": [],
      "parentNode": 11,
      "type": "Action", 
      "card": [],
      "name": "Pair Card",
      "endStep": "Go To Event",
      "shiftTo": "Next Step",
      "Add Card": {
      "placement": "Canvas",
      "cardType": "Any",
      "card": "Blank"
      },
      "Pass Turn": {
      "passType": "Next Player"
      },
      "events": [
      {
          "id": 0,
          "name": " ",
          "success": null,
          "fail": null,
          "nextId": null
      }
      ]
  },
  {
      "id": 25,
      "childNode": [],
      "parentNode": 11,
      "type": "Action", 
      "card": [],
      "name": "Pin Card",
      "endStep": "Go To Event",
      "shiftTo": "Next Step",
      "Add Card": {
      "placement": "Canvas",
      "cardType": "Any",
      "card": "Blank"
      },
      "Pass Turn": {
      "passType": "Next Player"
      },
      "events": [
      {
          "id": 0,
          "name": " ",
          "success": null,
          "fail": null,
          "nextId": null
      }
      ]
  },
  {
      "id": 26,
      "childNode": [],
      "parentNode": 11,
      "type": "Action", 
      "card": [],
      "name": "Stack Card",
      "endStep": "Go To Event",
      "shiftTo": "Next Step",
      "Add Card": {
      "placement": "Canvas",
      "cardType": "Any",
      "card": "Blank"
      },
      "Pass Turn": {
      "passType": "Next Player"
      },
      "events": [
      {
          "id": 0,
          "name": " ",
          "success": null,
          "fail": null,
          "nextId": null
      }
      ]
  }
];

export const addRowTemplate = [
  {
    id: null,
    childNode: [],
    parentNode: null,
    type: "Phase",
    name: "Round",
    repeat: "Forever",
    repeatNum: 0,
    events: [
      {
        id: 0,
        name: " ",
        success: null,
        fail: null,
        nextId: null,
      },
    ],
  },
  {
    id: null,
    childNode: [],
    parentNode: null,
    type: "Turn Structure",
    name: "Turn A",
    repeat: "Forever",
    repeatNum: 0,
    targetedRole: "Storyteller",
    events: [
      {
        id: 0,
        name: " ",
        success: null,
        fail: null,
        nextId: null,
      },
    ],
  },
  {
    id: null,
    childNode: [],
    parentNode: null,
    type: "Turn Type",
    name: "In Turn",
    instruction: [
      {
        role: "Storyteller",
        messageList: [
          { type: "Default", check: true, message: "" },
          { type: "Interrupted", check: true, message: "" },
        ],
      },
    ],
  },
  {
    id: null,
    childNode: [],
    parentNode: null,
    type: "Role",
    name: "Storyteller",
    events: [
      {
        id: 0,
        name: " ",
        success: null,
        fail: null,
        nextId: null,
      },
    ],
  },
  {
    id: null,
    childNode: [],
    parentNode: null,
    type: "Steps",
    name: "Step 1",
    events: [
      {
        "id": 0,
        "name": " ",
        "success": null,
        "fail": null,
        "nextId": null
      }
    ],
  },
  {
    id: null,
    childNode: [],
    parentNode: null,
    type: "Key Action",
    name: "Unlimited",
  },
  {
    id: null,
    childNode: [],
    parentNode: null,
    type: "Action", 
    card: [],
    name: "Add Card",
    endStep: "Go To Event",
    shiftTo: "Next Step",
    "Add Card": {
      placement: "Canvas",
      cardType: "Any",
      card: "Blank",
    },
    "Pass Turn": {
      passType: "Next Player",
    },
    events: [
      {
        id: 0,
        name: " ",
        success: null,
        fail: null,
        nextId: null,
      },
    ],
  },
  {
    id: null,
    childNode: [],
    parentNode: null,
    type: "Turn Type",
    name: "Out Turn",
    instruction: [
      {
        role: "Storyteller",
        messageList: [
          { type: "Default", check: true, message: "" },
          { type: "Interrupted", check: true, message: "" },
        ],
      },
    ],
  },
  {
    id: null,
    childNode: [],
    parentNode: null,
    type: "Role",
    name: "Storyteller",
    events: [
      {
        id: 0,
        name: " ",
        success: null,
        fail: null,
        nextId: null,
      },
    ],
  },
  {
    id: null,
    childNode: [],
    parentNode: null,
    type: "Steps",
    name: "Step 1",
  },
  {
    id: null,
    childNode: [],
    parentNode: null,
    type: "Key Action",
    name: "Unlimited",
  },
  {
    id: null,
    childNode: [],
    parentNode: null,
    type: "Action", 
    card: [],
    name: "Add Card",
    endStep: "Go To Event",
    shiftTo: "Next Step",
    "Add Card": {
      placement: "Canvas",
      cardType: "Any",
      card: "Blank",
    },
    "Pass Turn": {
      passType: "Next Player",
    },
    events: [
      {
        id: 0,
        name: " ",
        success: null,
        fail: null,
        nextId: null,
      },
    ],
  },
];

export const JamBuilderUtil = {
  getJBID() {
    return window.location.href.substring(
      window.location.href.indexOf("builder/") + 8,
      window.location.href.length
    );
  },

  saveGraph(_id, graph) {
    return clientURI
      .mutate({
        mutation: UPDATE_JAMBUILDER,
        variables: {
          _id: _id,
          graph: graph,
        },
      })
      .catch((err) => {
        console.log(err);
      });
  },

  saveGeneralSettings(general){
    return clientURI
    .mutate({
      mutation: UPDATE_JAMBUILDER_GENERAL,
      variables: {
        _id: this.getJBID(),
        general: general,
      },
    })
    .catch((err) => {
      console.log(err);
    });
  },

  updateName(_id, name){
    return clientURI.mutate({
        mutation: UPDATE_JAMBUILDER_NAME,
        variables: {
          _id: _id,
          graphName: name,
        },
      }).catch((err) => {
        console.log(err);
      });
  }
};
