import React from 'react';
import {Grid, Card, CardContent, Divider, Tooltip,
        Button, Modal, InputBase, FormControl, withStyles, Link,
        ClickAwayListener, Dialog, Typography, TablePagination } from '@material-ui/core';
import {Pagination} from '@material-ui/lab'
import {ViewCarouselRounded, PeopleAlt, TableChartOutlined, LocalOfferOutlined, Apps,VisibilityOutlined, VisibilityOffOutlined,
        ExpandMore, ExpandLess, Lock, BubbleChart, Mic,
        PetsOutlined, SportsEsportsOutlined} from '@material-ui/icons'
import {withApollo} from 'react-apollo'
import { withRouter } from 'react-router-dom'
import axios from 'axios'
import gql from 'graphql-tag';
import request from 'superagent';

import config from '../../../config/backend'
import GameTopPanel from './gameTopPanel'
import FooterPanel from './gameFooterPanel.tsx';
import {HOME_PAGE_JAMBUILDER_QUERY, HOME_PAGE_GAME_QUERY, GET_USER_ACTIVITY, GET_JAMS_TEMPLATES} from '../../../api/graphql'
import CardLoader from '../user_interface/assets/cardLoader'
import {CREATE_GAME, CREATE_JAMBUILDER, USER_UPDATE_ACTIVITY,GAME_GD_UPDATE_BY_ID, DUPLICATE_GAME} from '../../../api/graphql-mutation'
import { getUserId, getUsername, replaceWhiteSpace} from '../../../utility/function';
import {styles} from './assets/frontPageUI'
import {ReactComponent as FPCards} from '../../../element/Icons/fpcards.svg'

/** Components */
import { HelpFab } from '../../fabs/helpFab/helpFab';
import { HelpPopover } from '../../popovers/helpPopover/helpPopover';

/**temporary mock data */
import {gd,gd2} from '../../../utility/temp'

class FrontPage extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            jamListLength: 12,
            jamBuilderListLength: 3,
            templateListLength: 12,

            open: false,
            type: '',
            password: '',
            name: '',
            jamBuilder: [],
            deck: '1',
            loaded: true,

            //Activity list
            activityList: [],
            userJams: [],

            //Personal Jambuilders
            jambuilders: [],
            //Public jambuilders
            publicJambuilders: [],

            gamejams: [],
            spectateGame: [],
            uid: null,

            anchorEl: null,
            stickyBits: null,

            //Dialog configs
            dialogOpen: false,
            selectedJB: {
                type: "story",
                jbData: null,
            },
            dialogDeck: null,

            templateDialog: false,

            templateImage: null,

            openPreview: this.props?.location?.state?.openPreview,

            loading: false,

            // Paginated list state
            jamPage: 1,
        }

        this.jbIndex = null
    }

    componentDidMount() {
        document.body.style.background = "#F2F2F2"

        this.props.client.query({
            query: gql`${HOME_PAGE_GAME_QUERY}`
        })
        .then((res) =>{
            const {gameMany} = res.data
            // console.log(gameMany)

            let readyGame = gameMany.filter((n)=>{return n.status === "ready"})
            let spectateGame = gameMany.filter((n)=>{return n.status === "started" && n.data.general.spectateMode})

            const gamejams = readyGame.filter((game)=>{return game.automation !== "2" && game.automation !== "3"})
            this.setState({
                loaded: true,
                gamejams: gamejams.reverse(),
                spectateGame: spectateGame.reverse(),

            })
        })
        .catch(() =>{
            return <div>Error :( : frontpage </div>
        })

        if(this.props?.location?.state?.type){
            const {type, selectedJB} = this.props.location.state
            this.setState({
                type: type,
                selectedJB: {
                    type: selectedJB.type,
                    jbData: selectedJB?.jbData
                }
            }, () =>{

                this.handleOpen(type)
                this.jbIndex = this.props.location.state.jbIndex
                this.jbID = this.state.selectedJB.jbData._id
            })
        }

    }

    render(){
        const {classes} = this.props
        const {gamejams, jambuilders, publicJambuilders, uid, activityList, spectateGame, userJams} = this.state
        const popoverId = this.state.anchorEl ? 'help-popover' : undefined;

        //Shorthand alternative to "if" statement
        //So why is this a if statement? Putting the getUserId() in componentDidMount would return null even though
        //the user is logged in but will then return a valid uid after each subsequent function calls.
        //Therefore the stupid solution is to put it into render() and run the function until it returns
        //a valid uid and then query the data. This is what I call a pro gamer move
        Boolean(!uid && getUserId()) && this.jamPageQuery(getUserId())

        return(
            <>
                {!this.state.loaded ?
                    <div style ={{position: "absolute", left: "50%", top: "40%"}}><CardLoader/></div>
                    :
                    <>
                        {/* TOP PANEL */}
                        <GameTopPanel/>

                        {/* MAIN */}
                        <Grid container spacing={4} className={classes.container}>
                            {/* LEFT COLUMN */}
                            <Grid item sm={9} xs={12}>
                                {/* BANNER */}
                                <img alt="banner" className={classes.banner} src={require('../../../element/2024feb/banner_front.png')}/>
                                {/* FEATURED GAME JAM ROW */}
                                <Card className={`${classes.card} ${classes.cardHeaderContainer}`}>
                                    <Grid container >
                                        <Grid item xs={12} className={classes.cardHeader}>
                                            Featured Jam: Brainstorm Jam
                                        </Grid>
                                    </Grid>

                                </Card>

                                <Card className={classes.card}>
                                    <CardContent className={classes.cardContent}>
                                        <Grid container direction="row">
                                            <Grid item xs={2}>
                                                <div style={{position:"relative", width:"140px"}}>
                                                    <img alt="banner" style={{borderRadius:"10px", width:"140px"}} src={require('../../../element/2024feb/bs_highfive.png')}/>
                                                    {/*<div className={classes.featuredType}>GAME</div>
                                                    <p className={classes.featuredTitle}>Story Game</p>*/}
                                                </div>
                                            </Grid>
                                            <Grid item xs={8}>

                                                {/*<div style={{position:"absolute", right:"20px", top:"10px", fontSize:"14px"}}>
                                                    <span style={{fontSize:"12px"}}><PeopleAlt style={{color:"#4f4f4f"}}/></span> 2 - 6
                                                </div>*/}
                                                <p style={{fontSize:"14px", marginLeft:"10px"}}>
                                                    Increase productivity, participation, and get results!<br/><br/>
                                                    {"This game design consists of 5 Sessions (Questions, Requirements, Ideas, Actions, Assignments) to build consensus and action plan towards a specific Goal."}
                                                    <br/><br/>
                                                    👥 Person: 2 - 6 <br/>
                                                    🗃️ Session: 5 <br/>
                                                    ⏱️ Time: 30 - 60 min
                                                    <Button
                                                        onClick={()=>{
                                                            // this.setState({
                                                            //     dialogOpen: true,
                                                            //     selectedJB:{
                                                            //         type: "story"
                                                            //     }
                                                            // });

                                                            if(this.state.brainstormTemplate){
                                                                this.setState({
                                                                    dialogOpen: true,
                                                                    selectedJB: {
                                                                        jbData: this.state.brainstormTemplate,
                                                                        type: "custom-other",
                                                                        brainstorm: true,
                                                                    }
                                                                });
                                                            }else{
                                                                console.log("Unable to find brainstorm template ID")
                                                            }
                                                        }}
                                                        className={classes.modBtn}
                                                    >
                                                        START JAM
                                                    </Button>
                                                </p>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>

                                {/* CREATE YOUR OWN SECTION */} 
                                <Card className={`${classes.card} ${classes.cardHeaderContainer}`} style={{marginBottom:"-15px"}}>
                                    <Grid container >
                                        <Grid item xs={12} className={classes.cardHeader}>
                                           Guided Jams
                                        </Grid>
                                    </Grid>
                                </Card>
                                <Card className={classes.card} style={{background:"transparent", border:"none", marginTop:"15px"}}>
                                    <Grid container spacing={2}>
                                            <Grid item xs={5}>
                                                <Grid
                                                    container
                                                    justify="center"
                                                    alignItems="center"
                                                    spacing={0}
                                                    className={classes.displayBox}
                                                    onClick={()=>{
                                                        
                                                        if(this.state.firsttimeTemplate){
                                                            this.setState({
                                                                dialogOpen: true,
                                                                selectedJB: {
                                                                    jbData: this.state.firsttimeTemplate,
                                                                    type: "custom-other",
                                                                    firsttime: true,
                                                                }
                                                            });
                                                        }else{
                                                            console.log("Unable to find first-time template ID")
                                                        }
                                                    
                                                    }}
                                                >
                                                    <img alt="icon" className={classes.displayImg} style={{marginLeft:"3.5em",height:"110px",top:"10px"}} src={require('../../../element/2024feb/flagstart.png')}/>
                                                    <div className={classes.displayBg}>
                                                        <div className={classes.displayTitleWrapper} style={{marginTop:"5%"}}>
                                                            <p className={classes.displayTitle}>FIRST-TIME JAM</p>
                                                            <p className={classes.displaySubTitle}>Basic tutorial on JAMIT<br/>basic feature</p>
                                                        </div>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={5}>
                                                <Grid
                                                    container
                                                    justify="center"
                                                    alignItems="center"
                                                    spacing={0}
                                                    className={classes.displayBox}
                                                    onClick={()=>{
                                                        // this.setState({
                                                        //     dialogOpen: true,
                                                        //     selectedJB:{
                                                        //         type: "brainstorm"
                                                        //     }
                                                        // });
                                                        if(this.state.brainstormTemplate){
                                                            this.setState({
                                                                dialogOpen: true,
                                                                selectedJB: {
                                                                    jbData: this.state.brainstormTemplate,
                                                                    type: "custom-other",
                                                                    brainstorm: true,
                                                                }
                                                            });
                                                        }else{
                                                            console.log("Unable to find brainstorm template ID")
                                                        }
                                                    
                                                    }}
                                                >
                                                    <img alt="icon" className={classes.displayImg} style={{marginLeft:"2em",height:"110px",top:"10px"}} src={require('../../../element/2024feb/highfive.png')}/>
                                                    <div className={classes.displayBg}>
                                                        <div className={classes.displayTitleWrapper} style={{marginTop:"5%"}}>
                                                            <p className={classes.displayTitle}>BRAINSTORM JAM</p>
                                                            <p className={classes.displaySubTitle}>Process oriented<br/>collaboration</p>
                                                        </div>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                    </Grid>
                                </Card>
                                {/* HOST OR BUILD FROM JAMIT TEMPLATES */}
                                <Card className={`${classes.card} ${classes.cardHeaderContainer}`} style={{marginBottom:"-15px"}}>
                                    <Grid container >
                                        <Grid item xs={12} className={classes.cardHeader}>
                                            Self-guided Jams
                                        </Grid>
                                    </Grid>
                                </Card>

                                {/* QUICK START WITH JAM TEMPLATES */}
                                <Card className={classes.card} style={{background:"transparent", border:"none", marginTop:"15px"}}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={5}>
                                            <Grid
                                                container
                                                justify="center"
                                                alignItems="center"
                                                spacing={0}
                                                className={classes.displayBox}
                                                onClick={()=>{
                                                    this.setState({
                                                        dialogOpen: true,
                                                        selectedJB:{
                                                            type: "freeStyle"
                                                        }
                                                    });
                                                }}
                                            >
                                                <Grid item xs={4} style={{background:"#74bfc9",borderRadius:"10px 0 0 10px"}}>
                                                    <img alt="icon" className={classes.displayImg} style={{marginLeft:"2em",position:"relative"}} src={require('../../../element/2024feb/ballspin.png')}/>
                                                </Grid>
                                                <Grid item xs={8} className={classes.displayBg} style={{borderRadius:"0px 10px 10px 0px",background:"#faf7ea",border:"1px solid #faf7ea"}}>
                                                    <div className={classes.displayTitleWrapper} style={{marginLeft:"20px", marginTop:"10%"}}>
                                                        <p className={classes.displayTitle}>FREESTYLE JAM</p>
                                                        <p className={classes.displaySubTitle}>Anything goes!</p>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <Grid
                                                container
                                                justify="center"
                                                alignItems="center"
                                                spacing={0}
                                                className={classes.displayBox}
                                                onClick={()=>{
                                                    this.setState({
                                                        dialogOpen: true,
                                                        selectedJB:{
                                                            type: "story"
                                                        }
                                                    });
                                                }}
                                            >
                                                <Grid item xs={4} style={{background:"#74bfc9",borderRadius:"10px 0 0 10px",height:"120px"}}>
                                                    <img alt="icon" className={classes.displayImg} style={{marginLeft:"1.5em",height:"100px",position:"relative",top:"20px"}} src={require('../../../element/2024feb/stop.png')}/>
                                                </Grid>
                                                
                                                <Grid item xs={8} className={classes.displayBg} style={{borderRadius:"0px 10px 10px 0px",background:"#faf7ea",border:"1px solid #faf7ea"}}>
                                                    <div className={classes.displayTitleWrapper} style={{marginLeft:"20px", marginTop:"10%"}}>
                                                        <p className={classes.displayTitle}>TURN TAKING JAM</p>
                                                        <p className={classes.displaySubTitle}>One person at a time!</p>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Card>

                                {/* On-going jams */ }

                                <Card className={`${classes.card} ${classes.cardHeaderContainer}`}>
                                    <Grid container >
                                        <Grid item xs={12} className={classes.cardHeader}>
                                            🔴 Browse Jams
                                        </Grid>
                                    </Grid>
                                </Card>

                                <Card className={classes.card}>
                                    <CardContent className={classes.cardContent} style={{marginLeft:"0.75rem"}}>
                                        Spectators allowed to join 👋
                                    </CardContent>
                                    <Divider/>
                                    <CardContent className={classes.cardContent} style={{paddingBottom:"0.5rem"}}>
                                        {/* JAM TITLES */}
                                        <Grid container spacing={2}>
                                        {spectateGame && spectateGame.length !==0 ?
                                            spectateGame.map( (game,index) => (
                                                index < this.state.jamListLength ?

                                                    <Grid key={index} item xs={4}>
                                                        <Grid
                                                            container
                                                            onClick={()=>{window.location.href=`/game/${game._id}`}}
                                                            justify="center"
                                                            alignItems="center"
                                                            spacing={0}
                                                            className={classes.jamBox}
                                                            style={{height:"100px"}}
                                                        >
                                                            {/* JAM IMAGE */}
                                                            <Grid item xs={4} className={classes.jamBoxImg} style={{background:"#BB5E0B"}}>

                                                                <SportsEsportsOutlined style={{color:"white", marginTop:"10px",fontSize:"70px"}}/>
                                                            </Grid>
                                                            {/* JAM INFO */}
                                                            <Grid item xs={8} className={classes.jamBoxFont} style={{padding:"10px", fontSize:"12px"}}>
                                                                <span style={{fontWeight:"bold", fontSize:"16px"}}>
                                                                    {game?.name}
                                                                    {game?.protected && <Lock style={{fontSize:"14px", marginTop:"-3px", marginLeft:"5px", color:"#C4C4C4"}}/>}
                                                                </span><br/>
                                                                Hosted by {game?.createdBy?.name?.first} <br/>
                                                                <span style={{fontSize:"8px"}}>
                                                                    <PeopleAlt className={classes.jamBoxIcon}/>
                                                                </span>
                                                                {game?.participants?.length } / {game?.data?.general?.playerMode === "Quick" ? 6 : 12}
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                :
                                                null
                                                ))
                                            :
                                            <>
                                                <PetsOutlined style={{color:"#C4C4C4", fontSize:"10rem", margin:"auto"}}/>
                                                <p className={classes.jamEmpty}>No one is hosting a jam at this current moment.<br/>
                                                Would you like to host one?</p>
                                            </>
                                            }

                                        </Grid>
                                    </CardContent>
                                    {spectateGame.length>this.state.jamListLength && spectateGame.length !== 0 ?
                                    <>
                                        <Divider/>
                                        <Button className={classes.moreBtn} onClick={this.addJamListLength} style={{padding:"20px 0"}} startIcon={<ExpandMore/>}>
                                            Show {spectateGame.length - this.state.jamListLength >= 12 ? 12 : spectateGame.length - this.state.jamListLength} more
                                        </Button>
                                    </>
                                    : null}
                                </Card>

                                {/* LIST OF GAME JAM TITLES */}
                                <Card className={`${classes.card} ${classes.cardHeaderContainer}`}>
                                    <Grid container >
                                        <Grid item xs={12} className={classes.cardHeader}>
                                           ⏰ Starting Soon
                                        </Grid>
                                    </Grid>
                                </Card>

                                {/* LIST OF JAM TITLE CONTAINER */}
                                <Card className={classes.card}>
                                    <CardContent className={classes.cardContent} style={{marginLeft:"0.75rem"}}>
                                        Invite only allowed to join 🎟
                                    </CardContent>
                                    <Divider/>
                                    <CardContent className={classes.cardContent} style={{paddingBottom:"0.5rem"}}>
                                        {/* JAM TITLES */}
                                        <Grid container spacing={2}>
                                        {gamejams && gamejams.length !==0 ?
                                            gamejams.map( (game,index) => (
                                                index < this.state.jamListLength ?

                                                    <Grid key={index} item xs={4}>
                                                        <Grid
                                                            container
                                                            onClick={()=>{window.location.href=`/game/${game._id}`}}
                                                            justify="center"
                                                            alignItems="center"
                                                            spacing={0}
                                                            className={classes.jamBox}
                                                            style={{height:"100px"}}
                                                        >
                                                            {/* JAM IMAGE */}
                                                            <Grid item xs={4} className={classes.jamBoxImg} style={{background:"#BB5E0B"}}>

                                                                <SportsEsportsOutlined style={{color:"white", marginTop:"10px",fontSize:"70px"}}/>
                                                            </Grid>
                                                            {/* JAM INFO */}
                                                            <Grid item xs={8} className={classes.jamBoxFont} style={{padding:"10px", fontSize:"12px"}}>
                                                                <span style={{fontWeight:"bold", fontSize:"16px"}}>
                                                                    {game?.name}
                                                                    {game?.protected && <Lock style={{fontSize:"14px", marginTop:"-3px", marginLeft:"5px", color:"#C4C4C4"}}/>}
                                                                </span><br/>
                                                                Hosted by {game?.createdBy?.name?.first} <br/>
                                                                <span style={{fontSize:"8px"}}>
                                                                    <PeopleAlt className={classes.jamBoxIcon}/>
                                                                </span>
                                                                {game?.participants?.length } / {game?.data?.general?.playerMode === "Quick" ? 6 : 12}
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                :
                                                null
                                                ))
                                            :
                                            <>
                                                <PetsOutlined style={{color:"#C4C4C4", fontSize:"10rem", margin:"auto"}}/>
                                                <p className={classes.jamEmpty}>No one is hosting a jam at this current moment.<br/>
                                                Would you like to host one?</p>
                                            </>
                                            }

                                        </Grid>
                                    </CardContent>
                                    {gamejams.length>this.state.jamListLength && gamejams.length !== 0 ?
                                    <>
                                        <Divider/>
                                        <Button className={classes.moreBtn} onClick={this.addJamListLength} style={{padding:"20px 0"}} startIcon={<ExpandMore/>}>
                                            Show {gamejams.length - this.state.jamListLength >= 12 ? 12 : gamejams.length - this.state.jamListLength} more
                                        </Button>
                                    </>
                                    : null}
                                </Card>

                                {/* TEMPLATE TITLE */}
                                <Card className={`${classes.card} ${classes.cardHeaderContainer}`}>
                                    <Grid container >
                                        <Grid item xs={12} className={classes.cardHeader}>
                                            👥 Community Templates
                                        </Grid>
                                    </Grid>
                                </Card>

                                {/*LIST OF TEMPLATE CONTAINER */}
                                <Card className={classes.card}>
                                    <CardContent className={classes.cardContent} style={{marginLeft:"0.75rem"}}>
                                        Discover published jam templates made by the builder community
                                    </CardContent>
                                    <Divider/>
                                    <CardContent className={classes.cardContent} style={{paddingBottom:"0.5rem"}}>
                                        {/* TEMPLATE TITLES */}
                                        <Grid container spacing={2}>
                                        {publicJambuilders && publicJambuilders.length !==0 ?
                                            publicJambuilders.map((jambuilder,index) => (
                                                index < this.state.templateListLength ?
                                                    <Grid key={index} item xs={4}>

                                                    { /*console.log(publicJambuilders)*/}
                                                        <Grid
                                                            container
                                                            onClick={()=>{
                                                                this.setState({
                                                                    dialogOpen: true,
                                                                    selectedJB: {
                                                                        jbData: jambuilder,
                                                                        type: "custom-other"
                                                                    }
                                                                }, () =>{
                                                                    this.jbIndex = index
                                                                });
                                                            }}
                                                            justify="center" alignItems="center"
                                                            spacing={0}
                                                            className={classes.jamBox}
                                                            style={{height:"100px"}}
                                                        >
                                                            <Grid item xs={4} className={classes.jamBoxImg} style={{background:"#74BFC9"}}>
                                                                <p style={{marginTop:"7px", fontSize:"36px", fontWeight:"bold"}}>
                                                                    <TableChartOutlined  style={{fontSize:"3rem"}}/>
                                                                </p>
                                                            </Grid>
                                                            <Grid item xs={8} className={classes.jamBoxFont}  style={{padding:"10px", fontSize:"12px"}}>
                                                                <span style={{fontWeight:"bold", fontSize:"16px"}}>
                                                                    {jambuilder?.name}
                                                                    {jambuilder?.protected && <Lock style={{fontSize:"14px", marginTop:"-3px", marginLeft:"5px", color:"#C4C4C4"}}/>}
                                                                </span><br/>
                                                                Created by {jambuilder?.createdBy?.name?.first} <br/>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                :
                                                null
                                                ))
                                            :
                                            <>
                                                <PetsOutlined style={{color:"#C4C4C4", fontSize:"10rem", margin:"auto"}}/>
                                                <p className={classes.jamEmpty}>No one has built a template yet.<br/>
                                                Want to be the first to build one?</p>
                                            </>
                                            }
                                        </Grid>
                                    </CardContent>
                                    {publicJambuilders.length>this.state.templateListLength && publicJambuilders.length > 0 ?
                                    <>
                                        <Divider/>
                                        <Button className={classes.moreBtn} onClick={this.addTemplateListLength} style={{padding:"20px 0"}} startIcon={<ExpandMore/>}>
                                            Show {publicJambuilders.length - this.state.templateListLength >= 12 ? 12 : publicJambuilders.length - this.state.templateListLength} more
                                        </Button>
                                    </>
                                    : null}
                                </Card>

                            </Grid>

                            {/* RIGHT COLUMN */}
                            <Grid item sm={3} xs={12}>
                                {/* USER'S RECENT ACTIVITY */}
                                {/* //TODO: Change the user model to accomodate to recent activities */}
                                {uid && activityList &&
                                    <Grid item xs={12}>
                                        <Card className={classes.card} style={{background:"white", marginTop:"0"}}>
                                            <CardContent style={{padding:"20px"}}>
                                                <p style={{fontSize:"20px", fontWeight:"bold"}}>
                                                    Recent jams
                                                </p>
                                                <Grid container>
                                                {
                                                    activityList.length > 0 ?
                                                        this.displayActivity(classes, activityList)
                                                    :
                                                    <>
                                                        <img alt="empty" style={{width:"150px", height:"150px",  marginLeft:"18%"}} src={require('../../../element/cat.png')}/>
                                                        <p className={classes.ownJamEmpty}>
                                                            You don’t have anything on your <br/>dashboard yet. <br/><br/>
                                                            Why don't you start building<br/> or hosting a jam?
                                                        </p>
                                                    </>
                                                }
                                                </Grid>
                                            </CardContent>
                                            {/* {jambuilders.length > this.state.jamBuilderListLength?
                                                this.state.jamBuilderListLength !== jambuilders.length ?
                                                <>
                                                    <Divider/>
                                                    <Button  className={classes.moreBtn} onClick={()=>{this.setState({jamBuilderListLength:jambuilders.length})}} startIcon={<ExpandMore/>}>
                                                        Show all
                                                    </Button>
                                                </>
                                                :
                                                <>
                                                    <Divider/>
                                                    <Button  className={classes.moreBtn} onClick={()=>{this.setState({jamBuilderListLength: 2})}} startIcon={<ExpandLess/>}>
                                                        Show less
                                                    </Button>
                                                </>
                                            :
                                                 null
                                            }   */}
                                        </Card>
                                    </Grid>
                                }

                                {/* USER'S OWN TEMPLATES */}
                                {uid && userJams &&
                                    <Grid item xs={12}>
                                        <Card className={classes.card} style={{background:"white", marginTop:"0"}}>
                                            <CardContent style={{padding:"20px 20px 5px 20px"}}>
                                                <p style={{fontSize:"20px", fontWeight:"bold"}}>
                                                    All jams
                                                </p>
                                                {this.displayPaginated(classes,userJams)}
                                                
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                }

                                {/* BANNER */}
                                {!uid &&
                                    <Grid item xs={12}>
                                        <img alt="banner" className={classes.banner} src={require('../../../element/banner6.png')}/>
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                        {/* FOOTER */}

                        <FooterPanel/>

                        {/* POP UP */}
                        <Modal disableAutoFocus={true} className={classes.modal} open={this.state.open} onClose={this.handleClose}>
                            {this.popUp(classes)}
                        </Modal>

                        {/* HELP POPOVER */}
                        <ClickAwayListener onClickAway={this.closePopper}>
                            <div>
                                <HelpPopover
                                    id={popoverId}
                                    anchorEl={this.state.anchorEl}
                                />
                                <HelpFab openHelpPopper={this.openPopper} />
                            </div>
                        </ClickAwayListener>

                        {/* TEMPLATE PREVIEW DIALOG */}
                        <Dialog
                            onClose={() =>
                                this.setState({
                                    dialogOpen: false,
                                    dialogDeck: null,
                                })}
                            open={this.state.dialogOpen}
                        >
                            {this.state.dialogOpen && this.displayInfo()}
                        </Dialog>
                    </>
                }
            </>
        )
    }

    handleDropFile = (files) => {
        // console.log('Drop file', files);
        const file = files.shift();
        this.setState({
            templateImage: {...files}
        })
        //console.log(this.state.draft.toObject());
        // this.state.draft.syncImage(file);

        const data = new FormData();
        data.append('image', file);

        // const req = request.post(`${config.backend.uri}/upload`);
        const req = request.post(`${config.backend.uri}/api/upload`);

        req.on('progress', (event) => {
          const percent = Math.floor(event.percent);
          if (percent >= 100) {
            this.setState({ imageCompleted: 100 });
          } else {
            this.setState({ imageCompleted: percent });
          }
        });
        req.send(data);
        req.end((err, res) => {
          // this.state.draft.set('image', { ...file, ...res.body.data.upload });
          this.setState({
              templateImage: {...file, ...res.body.data.upload}
          })

          // console.log(this.state.templateImage)
        });
    }

    //Help popper
    openPopper = (e) =>{
        this.setState({
            anchorEl: e.currentTarget
        });
    }

    //Help popper
    closePopper = () =>{
        this.setState({
            anchorEl: null
        });
    }

    jamPageQuery = (uid) =>{
        this.props.client.query({
            query: gql`${HOME_PAGE_JAMBUILDER_QUERY}`,
            variables: {uid: uid}
        })
        .then((res) =>{
            const {jamBuilderMany} = res.data
            let personalJamBuilders = []
            let publicJambuilders = []

            this.props.client.query({
                query: GET_USER_ACTIVITY(uid)
            }).then((res) =>{
                //recentActivity has recentTemplates and recentJams,
                //these two data will store the oldest accessed activities starting from 0
                
                const recentActivity = res.data.userById.recentActivity
                const recentTemplates = recentActivity.recentTemplates.map((template) =>{
                    return template._id
                })
                const recentJams = recentActivity.recentJams.map((game) =>{
                    return game._id
                })

                const userJams = res.data.gameMany
                let filteredUserJams = userJams.filter((j)=>{
                    return j.name !== "Untitled Jam"
                })

                filteredUserJams.reverse()

                //console.log(userJams)
                //console.log(filteredUserJams)

                //Perhaps there's a better way to query activity, perhaps not
                //I spent way too much time and care so this nested query is the only way for now
                this.props.client.query({
                    query: gql`${GET_JAMS_TEMPLATES}`,
                    variables:{tempList : recentTemplates, jamList: recentJams}
                }).then((res) =>{

                    //Combines both objects into one based on ID
                    const jamBuilders = res.data.jamBuilderFilter.map(obj => Object.assign(obj, recentActivity.recentTemplates.find(templates => obj._id === templates._id)))
                    const games = res.data.gameFilter.map(obj => Object.assign(obj, recentActivity.recentJams.find(templates => obj._id === templates._id)))
                    
                    let activityList = jamBuilders.concat(games)

                    let sortedActivity = activityList.sort((a, b) => (new Date(a.date).getTime()) < (new Date(b.date).getTime()) ? 1 : (( (new Date(b.date).getTime()) <  (new Date(a.date).getTime())) ? -1 : 0))

                    jamBuilderMany.forEach((jamBuilder) => {
                        if(jamBuilder.createdBy._id === uid){
                            personalJamBuilders.push(jamBuilder)
                        }

                        if(jamBuilder?.general?.privacy === "Public" && jamBuilder?.status === "interpreted"){
                            publicJambuilders.push(jamBuilder)
                        }
                    })

                    let brainstormTemplate = publicJambuilders.find((pjb)=>{return pjb._id === "669e20bbcad6291beae230eb"})
                    let firsttimeTemplate = publicJambuilders.find((pjb)=>{return pjb._id === "662f21cc0d8d0f348cf5c3cd"})

                    this.setState({
                        jambuilders: personalJamBuilders.reverse(),
                        publicJambuilders: publicJambuilders.reverse(),
                        uid: uid,
                        activityList: sortedActivity,
                        userJams: filteredUserJams,
                        brainstormTemplate, // temporary state register for brainstormTemplate
                        firsttimeTemplate, // temporary state register for firsttimeTemplate
                    }, ()=>{
                        if(this.props?.location?.state?.openPreview){
                            this.reloadPage()

                            const {jbID} = this.props.location.state
                            let jbData = null

                            for(let i=0; i<this.state.jambuilders.length; i++){
                                if (this.state.jambuilders[i]._id === jbID){
                                    jbData = this.state.jambuilders[i]
                                    this.jbIndex = i
                                    break
                                }
                            }
                            this.jbID = jbID

                            this.setState({
                                dialogOpen: true,
                                selectedJB: {
                                    jbData: jbData,
                                    type: "custom-personal"
                                }
                            })
                        }
                    })
                })
            })
        })
        .catch(() =>{
            return <div>Error :( : frontpage </div>
        })
    }

    reloadPage = () =>{
        // The last "domLoading" Time //
        var currentDocumentTimestamp =
        new Date(performance.timing.domLoading).getTime();
        // Current Time //
        var now = Date.now();
        // Ten Seconds //
        var tenSec = 10 * 1000;
        // Plus Ten Seconds //
        var plusTenSec = currentDocumentTimestamp + tenSec;
        if (now > plusTenSec) {
            window.location.reload();
        } else {

        }
    }
    getParameter = () =>{
        return window.location.href.slice(-1)
    }

    addJamListLength = () => {
        this.setState({
            jamListLength: this.state.jamListLength + 12
        })
    }

    addTemplateListLength = () => {
        this.setState({
            templateListLength: this.state.templateListLength + 12
        })
    }

    handleOpen(getType){
        this.setState({
            open: true,
            type: getType
        })
    }

    handleClose = () => {
        this.setState({
            open: false
        })
    }

    passwordChecker = () => {
        if(this.state.password === ""){
            return null
        }else{
            return this.state.password
        }
    }

    handleChange = (event) => {
        let item = event.target.name
        this.setState({
            [item]: event.target.value
        })
    }

    handleCreateJam = () => {
        if (this.state.loading) {
            return;
        }

        if (this.state.type === '' || this.state.name === ''){
            alert('Please Fill All Information!');

            return;
        }

        this.createGame();
        this.setState({ loading: true });
    };

    //Game/Jambuilder modal
    popUp = (classes) => {
        //Note: type === 1 is Jam, 0 is Template
        const {type} = this.state

        return (
            <Card className={classes.popUp}>
                <div className={classes.popUpHeader}>
                    {type !== "0" ? "Host a new Jam" : "Create a new Template"}
                </div>
                <Divider/>
                <div className={classes.popUpContent}>
                    <FormControl style={{width: "100%", fontSize:"0.875rem", color:"#4F4F4F"}}>
                        <b>{type !=="0" ? "Jam Name *" : "Template Name *"}</b>
                        <p style={{color:"#828282", fontSize:"0.7rem", marginBottom:"5px"}}>Give your jam a unique name!</p>
                        <InputBase
                            className={classes.field}
                            placeholder="Example: Wayne's World"
                            name="name"
                            value={this.state.name}
                            onChange={this.handleChange}
                            inputProps={{style: {fontSize: "0.875rem", fontFamily:"arial" }, maxLength:17}}
                        />
                        {type !== "0" ?
                        <>
                            <div style={{fontSize:"0.875rem", color:"#4F4F4F",}}>
                                <b>Jam Password</b>
                                <i style={{marginLeft:"0.2em", color:"#B8B8B8"}}>(Optional)</i>
                                <p style={{color:"#828282", fontSize:"0.7rem", marginBottom:"5px"}}>Only those with your jam’s password may view your waiting room. If none is set, anyone can view your waiting room!</p>
                            </div>
                            <InputBase
                                className={classes.field}
                                type="password"
                                placeholder="password"
                                name="password"
                                value={this.state.password}
                                onChange={this.handleChange}
                                inputProps={{style: {fontSize: "0.875rem", fontFamily:"arial" }}}
                            />
                        </> :
                        null
                        }
                        {type !== "0" ?
                        <>
                            <Button
                                onClick={this.handleCreateJam}
                                disabled={(this.state.name === '' ? true : false) || this.state.loading}
                                className={this.state.name === '' ? classes.popUpBtnDisabled : classes.popUpBtn}
                            >
                                Go to waiting room
                            </Button>
                        </> :
                        <>
                            <Button
                                onClick={()=>{
                                    if(this.state.name === ""){
                                        alert("Please Fill All Information!")
                                    }else{
                                        this.createTemplate()
                                    }
                                }}
                                disabled={this.state.name === "" ? true:false}
                                className={this.state.name === "" ? classes.popUpBtnDisabled : classes.popUpBtn}
                            >
                                Go to Template Builder
                            </Button>
                        </>
                        }
                    </FormControl >
                </div>
            </Card>
        )
    }

    //Dialog popup to display info
    displayInfo = () =>{
        const {classes} = this.props
        const {selectedJB} = this.state
        //Default dialog data starts with "Basic Game"
        let image = require('../../../element/default-image.png')
        let name = "Basic Game"
        let description = "The most basic template ready to be used and modified to your needs. This template consists of 5 set of card decks from Character, Place, Events and 1 blank card for players to customize their own story"
        let endpoint = "storyDesign"

        // if redirect set jbID to redirect jbID
        if(this.props.location?.state?.redirect){
            this.jbID = this.props.location.state.jbID
        }

        if(selectedJB.type === "custom-personal" || selectedJB.type === "custom-other"){
            selectedJB?.jbData?.image ?
                image = `${config.backend.uri}/static/uploads/${selectedJB.jbData.image}` :
                image = require('../../../element/default-image.png')

            name = selectedJB.jbData.name
            description = selectedJB?.jbData?.general?.description
            endpoint = selectedJB.jbData.deckEndpoint
        }
        else if(selectedJB.type === "story"){
            name = "Story Game"
            description = "Everything you need to have to build story game. This template consists of 5 set of card decks from Character, Place, Events and 1 blank card for players to customize their own story"
            image = require('../../../element/OUAT1.jpg')
        }
        else if(selectedJB.type === "freeStyle"){
            name = "Free Style"
            description = "A jam in its simplest form. This template consists of many sets of card decks from Topics to Characters while allowing everyone to jam together at the same time."
        }else if(selectedJB.type === "brainstorm"){
            name = "Brainstorm"
            description = "New Brainstorm Jam"
        }

        if(!this.state.dialogDeck){

            var deckAPI;
            var p;
            if(endpoint === "storyDesign"){
                deckAPI = `/api/game/getDeck`
                p = {
                    endpoint,
                }
            }else if(endpoint === "brainstorm"){
                deckAPI = '/api/game/getDeck'
                p = {
                    endpoint: 'brainstorm'
                }
            }else{
                deckAPI = `/api/game/getCustomDeck`
                p = {
                    endpoint,
                    userDir: `${selectedJB.jbData.createdBy._id}-${replaceWhiteSpace(selectedJB.jbData.createdBy.name.first)}`,
                }
            }


            axios.get(`${config.backend.uri}${deckAPI}`,{params: p}).then((res)=>{
                let dialogDeck = res.data.deck.elements

                this.setState({
                    dialogDeck: dialogDeck
                })
            })
        }
        else{
            return(
                <div className={classes.preview}>
                    <Grid container>
                        <Grid item xs={8}>
                            {/* <img alt="media-preview" className={classes.previewImage} src={image}/>   */}

                            <div style={{backgroundImage: `url(${image})`, backgroundSize: 'cover'}} className={classes.previewImage}></div>

                            <p style={{fontSize:"14px",fontWeight:"bold", color:"#4F4F4F"}}>
                                {name}
                            </p>
                            <p style={{paddingRight:"20px", color:"#4F4F4F", fontSize:"14px"}}>
                                {description}
                            </p>
                        </Grid>
                        <Grid item xs ={4}>
                            <Button
                                className={classes.previewBtn}
                                onClick={()=>{
                                    if(!getUserId()){
                                        this.props.history.push({
                                            pathname: "/login",
                                            state:{
                                                selectedJB: selectedJB,
                                                type: "1",
                                                jbIndex: this.jbIndex
                                            }
                                        })
                                    }
                                    else{
                                        this.setState({
                                            dialogOpen: false
                                        },()=>{
                                            this.handleOpen("1")
                                        })
                                    }
                                }}
                                size={"small"}

                            >
                                Host This Template
                            </Button>

                            {/* Customize template button
                            <p style={{textAlign:"center"}}>
                                <Link
                                    style={{
                                        color:"orange",
                                        fontSize:"14px",
                                        fontWeight:"bold",
                                        textDecoration:"underline",
                                        cursor:"pointer",
                                    }}
                                    size={"small"}
                                    onClick={()=>{
                                        if(!getUserId()){
                                            this.props.history.push({
                                                pathname: "/login",
                                                state:{
                                                    selectedJB: selectedJB,
                                                    type: "0",
                                                    jbIndex: this.jbIndex
                                                }
                                            })
                                        }
                                        else{
                                            this.handleDisplayInfoClick(selectedJB)
                                        }
                                    }}
                                >
                                    Customize this Template
                                </Link>
                            </p>*/}
                            <p style={{fontWeight:"bold", marginTop:"20px", fontSize:"14px", color:"#4F4F4F"}}>
                                Deck List ({this.state.dialogDeck?.length})
                            </p>

                            {this.state.dialogDeck.slice(0,3).map((card, index) =>{
                                return(
                                    <div key={`${card}-${index}`} className={classes.previewCard}>
                                        <p style={{fontWeight:"bold", color:"#4F4F4F"}}>
                                           {card.content}
                                        </p>
                                        <Tooltip title="Category">
                                            <p style={{background:"#5C969A",}} className={classes.previewCardLabel}>
                                                <Apps className={classes.previewCardIcon}/>
                                                {card.category}
                                            </p>
                                        </Tooltip>
                                        {card.label.map((label, index) =>{
                                            return (
                                                <Tooltip key={`${label}-${index}`} title="Label">
                                                    <p style={{background:"#967B7B"}} className={classes.previewCardLabel}>
                                                        <LocalOfferOutlined className={classes.previewCardIcon}/>
                                                        {label}
                                                    </p>
                                                </Tooltip>
                                            )
                                        })}
                                    </div>
                                )
                            })}
                        </Grid>
                    </Grid>
                </div>
            )
        }
    }

    displayPaginated = (classes,userJams) => {
        // console.log(userJams)
        let page = this.state.jamPage
        
        //let itemsPerPage = 5
        //const startIndex = (page - 1) * itemsPerPage;
        //const endIndex = startIndex + itemsPerPage;
        //const currentItems = userJams.slice(startIndex, endIndex);

        let itemsPerPageOptions = [5];
        let rowsPerPage = 5
        const startIndex = page * rowsPerPage;
        const endIndex = startIndex + rowsPerPage;
        const currentItems = userJams.slice(startIndex,endIndex)

      
        return (
            <Grid container>
            {
                currentItems.length > 0 ?
                currentItems.map( (jam,index) => (
                        <Grid
                            key={`${index}`}
                            container
                            justify="center"
                            alignItems="center"
                            spacing={0}
                            className={classes.jamBox}
                            style={{height:"100px"}}
                            onClick={() =>{
                                this.props.client.mutate({
                                    mutation: USER_UPDATE_ACTIVITY,
                                    variables:{
                                        _id: this.state.uid,
                                        _aid: jam._id,
                                        type: "jam",
                                        date: new Date()
                                    }
                                }).then(() =>{
                                    this.props.history.push({
                                        pathname: `/game/${jam._id}`
                                    })
                                })
                            }}
                        >
                            {/* JAM IMAGE */}
                            <Grid item xs={4} className={classes.jamBoxImg} style={{background:"#faf7ea"}}>
                                <FPCards style={{color:"white", marginTop:"10px",fontSize:"50px"}}/>
                            </Grid>
                            {/* JAM INFO */}
                            <Grid item xs={8} className={classes.jamBoxFont} style={{fontSize:"12px", padding:"10px"}}>
                                <span style={{fontWeight:"bold", fontSize:"16px"}}>
                                    {jam?.name}
                                    {jam?.protected && <Lock style={{fontSize:"14px", marginTop:"-3px", marginLeft:"5px", color:"#C4C4C4"}}/>}
                                </span><br/>
                                Hosted by {jam?.createdBy?.name?.first} <br/>
                                <span style={{fontSize:"8px"}}>
                                    <PeopleAlt className={classes.jamBoxIcon}/>
                                </span>
                                {jam?.participants?.length }
                            </Grid>
                        </Grid>
                    ))
                :

                <>
                    <img alt="empty" style={{width:"150px", height:"150px", marginLeft:"18%"}} src={require('../../../element/cat.png')}/>
                    <p className={classes.ownJamEmpty}>
                        You don’t have anything on your <br/>dashboard yet. <br/><br/>
                        Why don't you start building<br/> or hosting a jam?
                    </p>
                </>
            }
            <Grid container style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2}}>
                {/**<Typography variant="body2">
                Showing {currentItems.length} of {userJams.length} jams
                </Typography>
                <Pagination
                count={Math.ceil(userJams.length / itemsPerPage)}
                page={page}
                onChange={this.handleChangePage}
                variant="outlined"
                shape="rounded"
                />**/}
                <div style={{ display: 'flex',justifyContent: 'space-between',alignItems: 'center',padding: '10px',borderTop: '1px solid #ccc'}}>
                    <Typography variant="body2">
                    Showing {startIndex + 1} to {Math.min(endIndex, userJams.length)} of {userJams.length} jams
                    </Typography>
                    <TablePagination
                    component="div"
                    count={userJams.length}
                    page={page}
                    onChangePage={this.handleChangePage}
                    rowsPerPage={5}
                    // onRowsPerPageChange={handleChangeRowsPerPage}
                    rowsPerPageOptions={0}
                    //labelRowsPerPage=""
                    labelDisplayedRows={({ from, to, count }) => ``}
                    style={{overflow:'hidden'}}
                    classes={{
                        actions: classes.pagination,
                        toolbar: classes.paginationToolbar  
                    }}
                
                    />
                </div>
            </Grid>
        </Grid>
        );

    }

    handleChangePage = (event,value) => {
        //console.log("CHANGEING PAGE",value)
        this.setState({
            jamPage: value
        })
    }

    displayActivity = (classes, activityList) =>{
        let listArr = []
        //console.log(activityList)

        for(let index = 0; index < activityList.length; index++){
            let activity = activityList[index]
            if(index > 2){
                break;
            }
            else if(activity.__typename === "UserRecentActivityRecentTemplates"){
                break;
                // listArr.push(
                //     <Grid
                //         key={`${index + activity}`}
                //         container
                //         justify="center"
                //         alignItems="center"
                //         spacing={0}
                //         className={classes.jamBox}
                //         style={{height:"100px"}}
                //         onClick={()=>{
                //             this.setState({
                //                 dialogOpen: activity.status!=="interpreted" ? false : true,
                //                 selectedJB: {
                //                     jbData: activity,
                //                     type: "custom-personal"
                //                 }
                //             }, () =>{
                //                 if(activity.status!=="interpreted"){
                //                     this.handleDisplayInfoClick(this.state.selectedJB)
                //                 }
                //                 else{
                //                     this.jbIndex = index
                //                     this.jbID = this.state.selectedJB.jbData._id
                //                 }
                //             });
                //         }}
                //     >
                //         <Grid item xs={4} className={classes.jamBoxImg} style={{background:"#74BFC9"}}>
                //             <p className={classes.ownJamIcon}>
                //                 <TableChartOutlined  style={{fontSize:"3rem"}}/>
                //             </p>
                //         </Grid>
                //         <Grid item xs={8} className={classes.jamBoxFont} style={{fontSize:"12px",padding:"10px"}}>
                //             <Button style={{position:"absolute", top:"-15px", left:"5px", outline:"none", background:"white", color:"#828282", height:"20px", fontSize:"2px", }} disabled
                //                 startIcon={<TableChartOutlined style={{color:"#828282", fontSize:"14px"}}/>}>
                //                 <span style={{fontSize:"10px"}}>TEMPLATE</span>
                //             </Button>
                //             <span style={{fontWeight:"bold", fontSize:"16px"}}>
                //                 {activity.name}
                //             </span><br/>
                //             Created by <span style={{color:"#4173D6"}}>{activity.createdBy.name.first}</span><br/>
                //                                     <div style={{position:"absolute", bottom:"-15px", right:"5px"}}>
                //             {/*  ONCE PUBLISHED IS ESTABLISHED */}
                //             {/* <Button style={{color:"white",background:"#c4c4c4", marginRight:"2px"}} classes={{disabled: classes.ownJamLabel}} disabled startIcon={<VisibilityOffOutlined/>}>PRIVATE</Button>
                //             <Button style={{color:"white",background:"#c4c4c4", marginRight:"2px"}} classes={{disabled: classes.ownJamLabel}} disabled startIcon={<VisibilityOutlined/>}>PUBLIC</Button>
                //             <Button style={{color:"white", background:"orange"}}classes={{disabled: classes.ownJamLabel}} disabled> PUBLISHED</Button> */}
                //             {activity.status === "interpreted"
                //                 ?
                //                 activity.general.privacy === "Public" ?
                //                 <>
                //                     <Button style={{color:"white",background:"#c4c4c4", marginRight:"2px"}} classes={{disabled: classes.ownJamLabel}} disabled
                //                     startIcon={<VisibilityOutlined style={{fontSize:"14px"}}/>}>
                //                         <span style={{fontSize:"10px"}}>
                //                             PUBLIC
                //                         </span>
                //                     </Button>
                //                     <Button style={{color:"white", background:"orange"}}classes={{disabled: classes.ownJamLabel}} disabled>
                //                         <span style={{fontSize:"10px"}}>
                //                             PUBLISHED
                //                         </span>
                //                     </Button>
                //                 </>
                //                 :
                //                 <>
                //                     <Button style={{color:"white",background:"#c4c4c4", marginRight:"2px"}} classes={{disabled: classes.ownJamLabel}} disabled
                //                     startIcon={<VisibilityOffOutlined style={{fontSize:"14px"}}/>}>
                //                         <span style={{fontSize:"10px"}}>
                //                             PRIVATE
                //                         </span>
                //                     </Button>
                //                     <Button style={{color:"white", background:"orange"}}classes={{disabled: classes.ownJamLabel}} disabled>
                //                         <span style={{fontSize:"10px"}}>
                //                             PUBLISHED
                //                         </span>
                //                     </Button>
                //                 </>
                //                 :
                //                 <Button style={{color:"white", background:"#c4c4c4"}} disabled classes={{disabled: classes.ownJamLabel}} >
                //                      <span style={{fontSize:"10px"}}>
                //                         UNPUBLISHED
                //                     </span>
                //                 </Button>
                //             }
                //             </div>
                //         </Grid>
                //     </Grid>
                // )
            }
            else if(activity.__typename === "UserRecentActivityRecentJams"){
                listArr.push(
                    <Grid
                        key={`${index + activity}`}
                        container
                        justify="center"
                        alignItems="center"
                        spacing={0}
                        className={classes.jamBox}
                        style={{height:"100px"}}
                        onClick={() =>{
                            this.props.client.mutate({
                                mutation: USER_UPDATE_ACTIVITY,
                                variables:{
                                    _id: this.state.uid,
                                    _aid: activity._id,
                                    type: "jam",
                                    date: new Date()
                                }
                            }).then(() =>{
                                this.props.history.push({
                                    pathname: `/game/${activity._id}`
                                })
                            })
                        }}
                    >
                        {/* JAM IMAGE */}
                        <Grid item xs={4} className={classes.jamBoxImg} style={{background:"#faf7ea"}}>
                            <FPCards style={{color:"white", marginTop:"10px",fontSize:"50px"}}/>
                        </Grid>
                        {/* JAM INFO */}
                        <Grid item xs={8} className={classes.jamBoxFont} style={{fontSize:"12px", padding:"10px"}}>
                            <span style={{fontWeight:"bold", fontSize:"16px"}}>
                                {activity?.name}
                                {activity?.protected && <Lock style={{fontSize:"14px", marginTop:"-3px", marginLeft:"5px", color:"#C4C4C4"}}/>}
                            </span><br/>
                            Hosted by {activity?.createdBy?.name?.first} <br/>
                            <span style={{fontSize:"8px"}}>
                                <PeopleAlt className={classes.jamBoxIcon}/>
                            </span>
                            {activity?.participants?.length }
                        </Grid>
                    </Grid>
                )
            }
        }

        return listArr
    }

    handleDisplayInfoClick = (selectedJB) =>{
        if(selectedJB.type === "custom-personal"){
            const {uid} = this.state

            this.props.client.mutate({
                mutation: USER_UPDATE_ACTIVITY,
                variables:{
                    _id: uid,
                    _aid: selectedJB.jbData._id,
                    type: "template",
                    date: new Date()
                }
            }).then(() =>{
                window.location.href=`/jambuilder/${selectedJB.jbData._id}`
            })
        }
        else{
            this.setState({
                dialogOpen: false,
            }, () =>{
                this.handleOpen("0")
            });
        }
    }

    createGame = () =>{
        let jamBuilder = null

        if(this.state.selectedJB.type === "custom-personal"){
            jamBuilder = this.state.jambuilders.find((jambuilder) => {
                if(jambuilder._id === this.jbID){
                    return jambuilder
                }
                else{
                    return false
                }
            })
        }
        else if(this.state.selectedJB.type === "custom-other"){
            jamBuilder = this.state.publicJambuilders[this.jbIndex]
        }
        
        if(this.state.selectedJB.brainstorm){
            jamBuilder = this.state.brainstormTemplate
        }

        if(this.state.selectedJB.firsttime){
            jamBuilder = this.state.firsttimeTemplate
        }

        const {name} = this.state
        const creator = getUserId();

        if(jamBuilder?.general && "gameLink" in jamBuilder.general){
            // duplicate jam
            // console.log("duplicate jam")
            let setup = {
                createdBy: creator,
                name,
                password: this.passwordChecker(),
            }

            this.props.client.mutate({
                mutation: DUPLICATE_GAME,
                variables: {
                    _id: jamBuilder.general.gameLink,
                    setup,
                }
            }).then((status)=>{
                console.log(status)
                this.setState({name:"",password:"", type: ""});
                this.jbIndex = null
                window.location.href=`/game/${status.data.gameDuplicate._id}`
                //window.location.reload(); // temporary for jambuilder setup
            })
        }else{
            
            const general = jamBuilder?.general ?
                                jamBuilder.general :
                                {
                                    name: "Game Jam",
                                    deckEndpoint: "storyDesign",
                                    rulebook:"💬 Host writes criteria by attaching card to their posts \n\n ⏱ Timer can be set manually\n\n💡 Share your idea during your turn\n\n🏆 Host will choose the best idea",
                                    description:"",
                                    privacy:"Private",
                                    playerMode:"Quick",
                                    spectateMode:true,
                                }
            const data = {
                objects : [],
                latestObject : null,
                playerTurn: 0,
                turn: 1,
                phase: [],
                latestID: "0",
                general: general
            }

            this.props.client.mutate({
                mutation: CREATE_GAME,
                variables:{
                    name: name,
                    createdBy: creator,
                    data: data,
                    automation: this.state.selectedJB.type === "custom-personal" || this.state.selectedJB.type === "custom-other" ?
                                    "JB:" + jamBuilder.configIndex + "/yamlConfig" :
                                    this.state.selectedJB.type,
                    password: this.passwordChecker(),
                    setup: false,
                }
            }).then((status)=>{
                this.setState({name:"",password:"", type: ""});
                this.jbIndex = null
                window.location.href=`/game/${status.data.gameCreateOne._id}`
                //console.log(status)
            })
        }
    }

    createTemplate = () =>{
        const creator = getUserId();
        const username = getUsername();
        const {jambuilders, selectedJB, publicJambuilders, uid} = this.state

        let jamBuilderGraph = []
        let general = {}
        let jamBuilder = null

        if(selectedJB.type === "custom-personal"){
            jamBuilder = jambuilders.find((jb) => {
                if(jb._id === this.jbID){
                    return jb
                }else{
                    console.log(false)
                }
            })
            jamBuilderGraph = jamBuilder.graph
            general = jamBuilder.general

            // jamBuilderGraph = jambuilders.find((jambuilder) => {
            //     if(jambuilder._id === this.jbID){
            //         return jambuilder.graph
            //     }
            //     else{
            //         return false
            //     }
            // })
            // general = jambuilders.find((jambuilder) => {
            //     if(jambuilder._id === this.jbID){
            //         return jambuilder.general
            //     }
            //     else{
            //         return false
            //     }
            // })
        }
        else if(selectedJB.type === "custom-other"){
            jamBuilder = publicJambuilders[this.jbIndex]
            jamBuilderGraph = publicJambuilders[this.jbIndex].graph
            general = publicJambuilders[this.jbIndex].general
        }

        let deckEndpoint = this.state.selectedJB.type === "custom-personal" || this.state.selectedJB.type === "custom-other" ? 
                                {
                                    endpoint: this.state.selectedJB.jbData.deckEndpoint ,
                                    userDir: `${this.state.selectedJB.jbData.createdBy._id}-${replaceWhiteSpace(this.state.selectedJB.jbData.createdBy.name.first)}`,
                                }
                            : 
                                {
                                    endpoint: "storyDesign",
                                }
                                
        let deckAPI = deckEndpoint.endpoint === "storyDesign" ? `/api/game/getDeck` : `/api/game/getCustomDeck`;
        
        console.log(deckEndpoint)

        axios.get(`${config.backend.uri}${deckAPI}`,{params: deckEndpoint}).then((res)=>{
            let deck = res.data.deck

            const name = "Untitled Jam"
            const creator = getUserId();
            const gameGeneral = 
                                {
                                    name: "Game Jam",
                                    deckEndpoint: "storyDesign",
                                    rulebook:"💬 Host writes criteria by attaching card to their posts \n\n ⏱ Timer can be set manually\n\n💡 Share your idea during your turn\n\n🏆 Host will choose the best idea",
                                    description:"",
                                    privacy:"Private",
                                    playerMode:"Quick",
                                    spectateMode: true,
                                }
            const data = {
                objects : [],
                latestObject : null,
                playerTurn: 0,
                turn: 1,
                phase: [],
                latestID: "0",
                general: gameGeneral
            }
    
            this.props.client.mutate({
                mutation: CREATE_GAME,
                variables:{
                    name: name,
                    createdBy: creator,
                    data: data,
                    // automation: "story",
                    automation: this.state.selectedJB.type === "custom-personal" || this.state.selectedJB.type === "custom-other" ?
                                "JB:" + jamBuilder.configIndex + "/yamlConfig" : this.state.selectedJB.type,
                    setup: false,
                    build: true,
                }
            }).then((status)=>{
                let jbGeneral = gameGeneral
                jbGeneral.gameLink = status.data.gameCreateOne._id
                let gameID = status.data.gameCreateOne._id

                this.props.client.mutate({
                    mutation: CREATE_JAMBUILDER,
                    variables:{
                        name: this.state.name,
                        createdBy: creator,
                        configIndex: `${creator}-${replaceWhiteSpace(username)}-${jambuilders.length+1}`,
                        deckEndpoint:  this.state.selectedJB.type === "custom-personal" || this.state.selectedJB.type === "custom-other" ?
                                            this.state.selectedJB.jbData.deckEndpoint :
                                            "storyDesign",
                        templateType: this.state.selectedJB.type,
                        jamBuilderGraph: jamBuilderGraph,
                        general: jbGeneral,
                        deck: deck,
                    }
                }).then((status) =>{

                    let fgd; // *** TEMPORARY FOR FREESTYLE & STORY
                    if(this.state.selectedJB.type === "story"){
                        fgd = gd
                    }else{
                        fgd = gd2
                    }

                    fgd.builderID = status.data.jamBuilderCreateOne._id
                    // console.log(gameID)
                    this.props.client.mutate({
                        mutation: GAME_GD_UPDATE_BY_ID,
                        variables: {
                          _id : gameID,
                          gameData: fgd
                        }
                    })

                    this.props.client.mutate({
                        mutation: USER_UPDATE_ACTIVITY,
                        variables:{
                            _id: uid,
                            _aid: status.data.jamBuilderCreateOne._id,
                            type: "template",
                            date: new Date()
                        }
                    }).then(() =>{
                        this.props.history.push({
                            pathname: `/jambuilder/${status.data.jamBuilderCreateOne._id}`,
                            state: {type: this.state.selectedJB.type}
                        })
                        this.setState({name: "", selectedJB: {type: null, jbData: null}});
                    })
    
                })  
            })
        })

    }
}


export default withApollo(withRouter(withStyles(styles)(FrontPage)));
