import React from 'react';
import { Fab } from '@material-ui/core';

import classes from './helpFab.module.scss';

/**
 * @typedef {import('../../../interfaces/helpFab.interface').IHelpFabProps} IHelpFabProps
 */

/** @param {IHelpFabProps} [props] */
export const HelpFab = (props) => {
    const { openHelpPopper } = props;

    return (
        <Fab
            aria-label="Help Button"
            size="medium"
            className={classes.helpFab}
            onClick={openHelpPopper}
        >
            <span className={classes.helpFabIcon}>?</span>
        </Fab>
    );
};
