import React from 'react';
import {Button} from 'react-bootstrap';
import theme from '../../config/theme'
import '../../app/app.css';
import axios from 'axios'
// import {Mutation} from 'react-apollo'
// import {UPDATE_SESSION, JAMBUILDER_INTERPRETER, TEST_YAML} from '../../api/graphql-mutation'
import config from '../../config/backend'
import {withStyles} from '@material-ui/core'


const styles = {
    smileyBox : {
        paddingTop: "10em",
    }
}

class Smiley extends React.Component{

    constructor(props){
        super(props)
        this.state = {
            repoName: 'default',
            errorMsg: '',
            topics: [],
        }
    }



    render(){
        // const _id = '5f640ab76ed1c8052d292733'
        // const title = this.state.repoName
        // const graph = [1,2,4,5]
        // const creator = '5d26fa553ec625083aa1d502'
        const {classes} = this.props

        return(
            <div className={classes.smileyBox + " smiley-box"}>
                <div className="btn-box">
                    <Button
                        onClick={()=>{console.log("Test Server Pressed"); this.getShellAxios()}}
                        className="btn-1"

                    >
                        Test Server
                    </Button>
                    <Button
                        onClick={()=>{console.log("Add Repo Pressed"); this.getCloneRepo(this.state.repoName)}}
                        className="btn-1"
                    >
                        Add Repo
                    </Button>
                    <Button
                        onClick={()=>{console.log("Checkout Pressed"); this.checkout(this.state.repoName)}}
                        className="btn-1"
                    >
                        Checkout
                    </Button>
                    <Button
                        style={{backgroundColor:theme.palette.secondary.action, borderColor:theme.palette.secondary.action}}
                        onClick={()=>{console.log("Get Topic Pressed"); this.getTopic()}}
                        className="btn-1"
                    >
                        Get Topic
                    </Button>
                    <Button
                        style={{backgroundColor:theme.palette.secondary.action, borderColor:theme.palette.secondary.action}}
                        onClick={()=>{console.log("Send Email Pressed"); this.sendEmail()}}
                        className="btn-1"
                    >
                        Send Email
                    </Button>
                    <Button
                        style={{backgroundColor:theme.palette.secondary.action, borderColor:theme.palette.secondary.action}}
                        onClick={()=>{this.getGameDecks()}}
                        className="btn-1"
                    >
                        Print DIR
                    </Button>

                </div>
                <div className="active-list">
                    <input className="active-input" type="text" placeholder="Click to change name (default repo name = default)" onChange={this.handleChange.bind(this)}/>
                </div>
                <div className="active-list">
                    <p>Repo Name: {this.state.repoName}</p><br/>
                    <p className="error-msg">{this.state.errorMsg}</p>
                </div>
            </div>
        )
    }

    stringChecker(str){
        if (/\s/.test(str)) {
           return false
        }else return true
    }

    handleChange(event){
        if(this.stringChecker(event.target.value) === true){
            this.setState({repoName: event.target.value, errorMsg: ''})
        }else{
            this.setState({errorMsg: 'invalid repo name', repoName: 'default'})
        }
    }

    getShellAxios = () => {
        axios.get(`${config.backend.uri}/api/getShell`);
    };

    getCloneRepo = (name) =>{

        axios.post(`${config.backend.uri}/api/cloneRepo`,{
            name: name
        }).then(function(response){
            console.log("from server: "+ response)
        })
    }

    pushRepo = (name) => {
        axios.post(`${config.backend.uri}/api/pushRepo`,{
            name: name
        }).then(function(response){
            console.log("from server: "+ response)
        })
    }

    getTopic = () => {
        axios.post(`${config.backend.uri}/api/getTopic`,{
            }).then((response) => {
                console.log("from server: " + JSON.stringify(response))
                this.setState({
                    topics: response.data.content.topic
                })
            })
       // return axios.post('http://${config.backend.uri}/api/getTopic').then((response) => {console.log(JSON.stringify(response)); this.setState({})})
    }

    sendEmail = () => {
        axios.post(`${config.backend.uri}/api/auth/sendEmail`,{}).then((response)=>{
            console.log('from server: ' + JSON.stringify(response))
        })
    }

    getGameDecks = () => {
        axios.post(`${config.backend.uri}/api/game/getDecks`,{}).then((res)=>{
            console.log(res.data)
        })
    }

}

export default withStyles(styles)(Smiley)

