/**
 * This is needed because you cannot manipulate the svg color via css:color, so this exists to serve as a 
 * lookup table for the computed filter values for our set of colors for players
 * https://codepen.io/sosuke/pen/Pjoqqp to compute the filter values for the svg cursor icons
 */

export const CursorColorTable = {
    "#6246EA": "invert(33%) sepia(29%) saturate(4123%) hue-rotate(234deg) brightness(89%) contrast(107%)",
    "#2CB67D": "invert(78%) sepia(7%) saturate(4297%) hue-rotate(98deg) brightness(78%) contrast(82%)",
    "#4FC4CF": "invert(81%) sepia(19%) saturate(1249%) hue-rotate(137deg) brightness(85%) contrast(89%)",
    "#FF8BA7": "invert(56%) sepia(67%) saturate(513%) hue-rotate(303deg) brightness(115%) contrast(104%)",
    "#F25F4C": "invert(47%) sepia(61%) saturate(3868%) hue-rotate(336deg) brightness(111%) contrast(90%)",
    "#4F4F4F": "invert(31%) sepia(0%) saturate(0%) hue-rotate(321deg) brightness(91%) contrast(86%)",
    "#9656A1": "invert(43%) sepia(9%) saturate(3172%) hue-rotate(245deg) brightness(91%) contrast(85%)",
    "#078080": "invert(26%) sepia(59%) saturate(3516%) hue-rotate(164deg) brightness(89%) contrast(94%)",
    "#3DA9FC": "invert(52%) sepia(96%) saturate(1141%) hue-rotate(181deg) brightness(101%) contrast(98%)",
    "#E53170": "invert(29%) sepia(53%) saturate(4784%) hue-rotate(323deg) brightness(95%) contrast(88%)",
    "#FF8E3C": "invert(85%) sepia(34%) saturate(6830%) hue-rotate(330deg) brightness(100%) contrast(102%)",
    "#C4C4C4": "invert(91%) sepia(0%) saturate(585%) hue-rotate(144deg) brightness(96%) contrast(72%)"
}