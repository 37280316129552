import React from 'react'
import {withStyles, List, ListItem, Collapse, Grid, Menu, MenuItem,
        IconButton, Tooltip} from '@material-ui/core'
import { ArrowDropUpRounded, ArrowDropDownRounded, PictureInPicture, LibraryBooks, MoreVert,DeleteOutline, FileCopyOutlined} from '@material-ui/icons';
import _ from 'lodash'
import EE from '../../../../../api/eventemitter'
import LazyLoad from 'react-lazyload';
import Linkify from 'linkifyjs/react';
import config from '../../../../../config/backend'

EE.setMaxListeners(1000) // temporary method to set max EE listeners , as there's yet a way to prevent each library object to have listeners on their own

const styles = {
    addCard:{
        cursor:"pointer"
    },

    category:{
        position:"relative",
        margin:"0",
        fontSize:"14px",
        fontWeight:"bold",
        color:"#fff",
        borderRadius:"5px",
        width:"fit-content",
        padding:"5px 10px",
        background:"#686868",
        zIndex:"2"
    },

    categoryCount:{
        float:"right",
        borderRadius:"5px",
        fontSize:"14px",
        padding:"0 10px",
        background:"#C4C4C4",
        color:"#4f4f4f",
        margin:"0"
    },

    expandBtn:{
        position:"relative",
        border:"1px solid #828282",
        background:"#E1E1E1",
        width:"10px",
        marginRight:"5px",
        height:"10px",
        color:"#828282",
        '&:focus':{
            outline:"none"
        },
        '&:hover':{
            background:"#E1E1E1",
        },
        zIndex:"2"

    }
}

class GameLibraryInfo extends React.Component {
    constructor(props) {
        super(props)
        this.state={
            anchorEl: null,
            //Will contain labels as a state
        }

        this.shiftRegister = null;
    }

    render(){
        const {objects} = this.props
        let uniqLabels = []
        let labelsMap = new Map()

        if(objects){
            let listOfLabels =  _.map(objects , "label")
            //Filters out all the duplicate labels
            uniqLabels = _.uniqBy(listOfLabels, function(item){
                return JSON.stringify(item)
            });

            uniqLabels.forEach(label =>{
                labelsMap.set(label, objects.filter((object) =>{
                    return JSON.stringify(object.label) === JSON.stringify(label)
                }))
            })
        }

        return(
            <>
                <div>
                    {this.displayCards(labelsMap)}
                </div>

            </>
        )
    }


    displayCards = (labelsMap) =>{
        let labelsArr = []
        const {classes, leftPanelState, handleLabelClick, category} = this.props
        const sortedLabelsMap = new Map([...labelsMap.entries()].sort())

        for(const [label, objects] of sortedLabelsMap){
            labelsArr.push(
                <List
                    key={`${label + objects}`}
                    style={{position:"relative", borderLeft:"2px solid #c4c4c4", marginLeft:"35px"}}
                >
                    <ListItem
                        key={`${label + objects}`}
                        button
                        onClick={handleLabelClick.bind(this, `${category}-${label}`)}
                        name = {`${label}`}
                        style={{padding:"0", marginTop:"20px", marginLeft:"-13px", width:"calc(100% + 13px)"}}
                    >
                        {this.state[label] ?
                            <IconButton className={classes.expandBtn}>
                                <ArrowDropUpRounded />
                            </IconButton>
                            :
                            <IconButton className={classes.expandBtn}>
                                <ArrowDropDownRounded />
                            </IconButton>
                        }
                        <Grid container justify="center" alignItems="center">
                            <Grid item xs={8}>
                                <div className={classes.category}>
                                    { !label || label.length === 0 ?
                                        <p style={{fontWeight:"normal", color:"#D0D0D0", margin:"0", fontStyle:"italic"}}>No label</p> :
                                        label.join(", ")
                                    }
                                </div>
                            </Grid>
                            <Grid item xs={4}>
                                <p className={classes.categoryCount}>
                                    {Object.keys(objects).length}
                                </p>
                            </Grid>
                        </Grid>
                    </ListItem>
                    <Collapse
                        in={leftPanelState[`${category}-${label}`]}
                        timeout="auto"
                        unmountOnExit
                        style={{marginLeft:"30px", borderLeft:"2px solid #c4c4c4",}}
                    >
                        {this.displayContent(objects)}
                    </Collapse>
                </List>
            )
        }

        labelsArr.push(
            <ListItem
                key={Math.floor(Math.random() * Date.now())}
                style={{padding:"0", marginTop:"0", marginLeft:"30px"}}
            >
                <IconButton disabled className={classes.expandBtn} style={{padding:"5px", background:"#c4c4c4"}}>
                </IconButton>
            </ListItem>
        )

        return labelsArr
    }

    displayContent = (objects) =>{
        const {
            classes,
            deleteLibrary,
            selectCardsInLibrary,
            selectedIDs,
            canDragLibCard,
            startSelectCardsLibrary,
            duplicateSingleLibraryCard,
        } = this.props
        let contentArr = []

        // console.log("This is library objects content: ",objects)

        for(let index=0; index<objects.length; index++){
            let object = objects[index]
            //C for canvas L for Library
            let objectPlacement = object?.objId ?
                                    <Tooltip title={"Canvas"}>
                                        <PictureInPicture style={{fontSize:"12px", color:"#c4c4c4"}} />
                                    </Tooltip> :
                                    <Tooltip title={"Library"}>
                                        <LibraryBooks style={{fontSize:"12px", color:"#c4c4c4"}} />
                                    </Tooltip>

            contentArr.push(
                <LazyLoad
                    key={`${index + object}`}
                    height={"10px"}
                    unmountIfInvisible={true}
                >
                    <div
                        key={`${index + object}`}
                        style={{position:"relative"}}
                    >
                        <div style={{position:"absolute",marginTop:"20px", width:"10px", height:"5px", background:"transparent", borderBottom:"2px solid #c4c4c4"}}/>
                        <ListItem
                            onClick={(e) =>{

                                let sr = this.shiftRegister

                                if((e.button === 0 && !e.shiftKey && !e.ctrlKey) /*&& selectedIDs.length === 0*/){
                                    if(sr){
                                        this.shiftRegister = sr
                                    }
                                    startSelectCardsLibrary(object)
                                }
                                else if(e.button === 0 && e.shiftKey){
                                    //selectCardsInLibrary(object)
                                    
                                    if(sr == null){
                                        //console.log(object)
                                        startSelectCardsLibrary(object)

                                        let temp = {
                                            object,
                                            index
                                        }

                                        this.shiftRegister = temp

                                    }else{
                                        if(sr.object.category === object.category){
                                            if(index > sr.index){
                                                //console.log(sr.index,index)
                                                for(let i = index; i>sr.index; i--){
                                                    selectCardsInLibrary(objects[i])
                                                }
                                            }else{
                                                //console.log(sr.index,index)
                                                for(let i = sr.index-1; i >= index; i--){
                                                    selectCardsInLibrary(objects[i])
                                                }
                                            }
                                        }else{
                                            let temp = {
                                                object,
                                                index,
                                            }
                                            this.shiftRegister = temp
                                        }
                                    }

                                }
                                
                                if(e.button === 0 && e.ctrlKey){
                                   
                                    if(sr){
                                        this.shiftRegister = null
                                    }
                                    selectCardsInLibrary(object)

                                }

                                
                            }}
                            style={{
                                position: "relative",
                                background: "white",
                                color: "#4f4f4f",
                                borderRadius: "5px",
                                padding: "10px",
                                marginTop: "20px",
                                marginLeft: "10px",
                                width: "calc(100% - 10px)",
                                border: selectedIDs.includes(object.libID)? "thick solid orange" : "transparent",
                                overflowWrap: "break-word",
                                wordWrap: "break-word",
                            }}
                            key={`${object} ${index}`}
                            draggable={canDragLibCard}
                        >

                            <Grid container style={{display:"block"}}>
                                <Grid container>
                                    <Grid item xs={9}>
                                        {
                                            object.imgUrl ?
                                            <div
                                                style={{
                                                    position:"relative",
                                                    width:"100%",
                                                    height:"62px",
                                                    overflow:"hidden",
                                                }}>
                                                <img
                                                    style={{width:"100%"}}
                                                    alt="img"
                                                    src={`${config.backend.uri}/static/uploads/${object.imgUrl}`}
                                                />
                                            </div>
                                            :
                                            object?.content?.length > 0 ?
                                                <Linkify
                                                    options={{
                                                        target: {
                                                            url: '_blank'
                                                        },
                                                    }}
                                                >
                                                    {new DOMParser().parseFromString(object.content, "text/html").body.textContent}
                                                </Linkify> :
                                                <i>No Content</i>
                                        }
                                    </Grid>
                                    <Grid item xs={3}>
                                        <IconButton style={{width:"12px", height:"12px", margin:"auto", outline:"none"}} onClick={(e)=>this.openMenu(object.libID, e)}>
                                            <MoreVert style={{color:"#c4c4c4", fontSize:"12px"}}/>
                                        </IconButton>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} style={{color:"#c4c4c4"}}>
                                    <Grid container justify="flex-start" alignItems="center">
                                        <Grid item>
                                            {objectPlacement}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </ListItem>
                        <Menu
                            anchorEl={this.state.anchorEl}
                            keepMounted
                            open={this.state.selectedLibID === object.libID}
                            onClose={this.closeMenu}
                        >
                            <MenuItem
                                onClick={() => duplicateSingleLibraryCard(object.libID)}
                                style={{
                                    color: "#4F4F4F",
                                    fontSize: 12,
                                }}
                            >
                                <FileCopyOutlined
                                    style={{
                                        color: "#4F4F4F",
                                        fontSize: 14,
                                        marginRight: 5,
                                    }}
                                />
                                Duplicate
                            </MenuItem>
                            <MenuItem onClick={()=>deleteLibrary(object.libID)}  style={{color:"#4F4F4F", fontSize:"12px"}} disabled={object?.objId ? true : false}>
                                <DeleteOutline style={{color:"#4F4F4F", fontSize:"14px", marginRight:"5px"}}/>
                                Delete
                            </MenuItem>
                        </Menu>
                    </div>
                </LazyLoad>
            )

        }

        contentArr.push(
            <ListItem
                key={Math.floor(Math.random() * Date.now())}
                style={{padding:"0", marginTop:"0", marginLeft:"-7px"}}
            >
                <IconButton disabled className={classes.expandBtn} style={{padding:"5px", background:"#c4c4c4"}}>
                </IconButton>
            </ListItem>
        )
        return contentArr
    }

    openMenu = (libId, event) =>{
        event.stopPropagation()

        this.setState({
            selectedLibID: libId,
            anchorEl: event.currentTarget
        })
    }

    closeMenu = () => {
        this.setState({
            selectedLibID: null,
            anchorEl: null
        })
    }
}

export default withStyles(styles)(GameLibraryInfo)
