import React from 'react'
import {Grid, 
        TextField,
        Tooltip,
        Popper, 
        Chip,
        Menu,
        MenuItem, 
        ListSubheader,
        Button, 
        Input,
        withStyles, 
        Avatar,
        IconButton, 
        ClickAwayListener} from '@material-ui/core'
import Dropzone from 'react-dropzone';
import csv from 'csv'
import LazyLoad from 'react-lazyload';
import Autocomplete, 
        {createFilterOptions} from '@material-ui/lab/Autocomplete';
import {AddBoxOutlined, 
        TextFields,
        LayersOutlined,
        DeleteOutlined, 
        FormatListBulleted,
        AddToPhotosOutlined, 
        CheckCircle, 
        LockRounded,
        AddRounded, 
        Create,
        ArrowDropDownCircleOutlined,
        ExpandMore, 
        MoreVert,
        GetAppRounded,
        SwapHoriz,
        LocalOfferOutlined,
        CloseRounded,
        ImageOutlined,
        Eco} from '@material-ui/icons'
import { CirclePicker  } from 'react-color';
import mongoose from 'mongoose';
import {withApollo} from 'react-apollo'
import GameTopPanel from '../game/user_interface/gameTopPanel'
import {styles} from './user_interface/deckBuilderUI'
import CardLoader from '../game/user_interface/assets/cardLoader'
import { getUserId, getUsername} from '../../utility/function';
import {GET_USER_DECKS} from '../../api/graphql'
import { USER_UPDATE_DECK, USER_UPDATE_DECK_NAME, USER_UPDATE_DECK_CARDS, USER_CREATE_DECK_YAML, USER_REMOVE_DECK} from '../../api/graphql-mutation';
import _ from 'lodash'
import session from '../../model/session'
import {userAUTH} from '../../config/authetication'
import { ToastContainer, toast } from 'react-toastify';
import request from 'superagent'
import config from '../../config/backend'
import axios from 'axios'

toast.configure()

class DeckBuilder extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            //Deck ID as key & {deckDump & yamlEndpoint} obj as value
            deckList: new Map(),

            //Deck transition view
            selectedDeck: null,

            //Selected card in the list
            cardSelected: null,
            cardValue: null,

            //Deck in top panel's ellipse 
            anchorEl: null,

            //Category in card editor panel's ellipse
            colorMenu:false,
            colorAnchorEl: null,


            moveAnchorEl: null,

            //Sort & filter drop down
            filterAnchorEl: null,
            filterName: "Last Created",
            filterType: 0,

            loaded:false,

            uid: null,

            //User data
            user: null,
            userCheck: false,

            contentAnchorEl: null,
            imgData: {
                imgSrc:"",
                imgUploaded: false,
            }
        }

        this.getSession();
        this.timeOut = 0
    }

    render(){
        const {deckList, selectedDeck, loaded, uid} = this.state
        const {classes} = this.props
        const locked = deckList.get(selectedDeck)?.locked
        // console.log(this.state)

        if(!loaded){
            Boolean(getUserId() && !uid) && this.getUserDecks(getUserId())

            return(
                <Grid container justify="center" alignItems="center" style={{position:"fixed", width:"100%", height:"80%", overflow:"hidden"}}>
                    <Grid item>
                        <CardLoader/>
                    </Grid>
                </Grid>
            )
        }
        else{
            return(
                <>
                    <GameTopPanel/>
                    <div style={{position:"fixed", height:"calc(100% - 4rem)", top:"4rem", width:"100%"}}>
                        {/*LEFT DECKLIST PANEL*/}
                        <div className={classes.leftPanel}>
                            <Grid container justify="flex-start" alignItems="center" className={classes.leftPanelWrapper}>
                                <Grid item xs={8}>
                                    <p className={classes.leftPanelHeader}>Deck</p>
                                </Grid>
                                <Grid item xs={4}>
                                    <AddBoxOutlined onClick={()=>this.addDeck()} className={classes.addDeck}/>
                                </Grid>
                                <Grid item xs={12} style={{marginTop:"1rem"}}>
                                    {/* Display decks */}
                                    {this.displayDecks(deckList)}
                                    
                                </Grid>
                            </Grid>  
                        </div>
        
                        {/*MIDDLE CARDLIST PANEL*/}
                        <div className={classes.centrePanel}>
                            <Grid container justify="center" alignItems="center">
                                {/* Top panel of the middle panel. Shows the deck's name and filter system. */}
                                <Grid item xs={12} style={{height:"3rem"}}>
                                    {deckList && selectedDeck &&
                                    this.displayTopPanel()}
                                </Grid>
                            </Grid>
                            <Grid container justify="center" alignItems="flex-start" style={{height: "calc(100vh - 7rem)", overflowX:"auto"}}>
                                {/* Display cards */}
                                {deckList.size !== 0 && selectedDeck ? 
                                    <div style={{width:"780px"}}>

                                        <Button className={classes.cards} style={{'&:focus':{outline:"none"}, border: locked ? "1px solid #D1D1D1" : "1px solid white" }} onClick={() =>{this.createCard(selectedDeck)}} disabled={locked}>
                                            <p style={{width:"70px", height:"70px", borderRadius:"200px", margin:"0", border: locked ? "1px solid #D1D1D1" : "1px solid white"}}>
                                                <AddRounded style={{fontSize:"50px", marginTop:"10px", color:locked ? "#D1D1D1" : "white"}}/>
                                            </p>
                                        </Button>
                                        {this.displayCards()}
                                    </div>
                                : 
                                    deckList.size === 0 ?
                                        this.displayNoDeckState()
                                    : 
                                        null
                                }
                                {/* The card's ellipse menu */}
                                <Menu
                                    anchorEl={this.state.cardAnchorEl}
                                    keepMounted
                                    open={this.state.selectedCardId && this.state.cardAnchorEl}
                                    onClose={()=>this.closeCardMenu()}
                                    PaperProps={{style: {color:"#4F4F4F",border:"0.5px solid #c4c4c4", boxShadow:"none"}}}  
                                    
                                >
                                    <MenuItem className={classes.cardMenuList} component="label" disabled={locked || this.state.cardValue?.content}>
                                        <ImageOutlined className={classes.cardMenuListIcon}/> 
                                        Upload Image
                                        <input
                                            onChange={(event)=>this.onImgUpload(event, this.state.selectedCardId)}
                                            type="file"
                                            hidden
                                        />
                                    </MenuItem>
                                    <MenuItem className={classes.cardMenuList} onClick={()=>this.duplicateCard(this.state.cardValue)} disabled={locked}>
                                        <AddToPhotosOutlined className={classes.cardMenuListIcon}/> 
                                        Duplicate
                                    </MenuItem>
                                    <MenuItem className={classes.cardMenuList} onClick={(e)=>this.openCardMoveMenu(this.state.selectedCardId, e)} disabled={locked}>
                                        <SwapHoriz className={classes.cardMenuListIcon}/> 
                                        Move to Deck...
                                    </MenuItem>
                                    <MenuItem className={classes.cardMenuList} onClick={()=>this.deleteCard(this.state.selectedCardId)} disabled={locked}>
                                        <DeleteOutlined className={classes.cardMenuListIcon}/> 
                                        Delete
                                    </MenuItem>
                                </Menu>

                                {/* The card's Move to Deck menu */}
                                <Menu
                                    anchorEl={this.state.moveAnchorEl}
                                    keepMounted
                                    open={this.state.selectedCardId && Boolean(this.state.moveAnchorEl)}
                                    onClose={()=>this.closeCardMoveMenu()}
                                    PaperProps={{style: {color:"#4F4F4F",border:"0.5px solid #c4c4c4", boxShadow:"none"}}}  
                                >
                                    <MenuItem className={classes.cardMenuList} disabled>
                                        Move card to
                                    </MenuItem>
                                    {this.moveMenu()}
                                </Menu>
                            </Grid>
                        </div>

                        {/*RIGHT EDITOR PANEL*/}
                        <div className={classes.rightPanel}>
                            {/* If card is selected, show editor
                                Else show empty state */}
                            {this.state.cardSelected ? 
                                this.displayCardEditor()
                                :
                                this.displayCardEditorEmpty() }
                        </div>

                        {/* SNACKBAR */}
                        <ToastContainer
                            position="bottom-left"
                            autoClose={5000}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                        />
                    </div>
                </>
            )
        }
    }

    /**
     * getDeckCategories is to get the selected deck's list of categories for filter menu
     */
    getDeckCategories = () =>{
        const {deckList, selectedDeck} = this.state
        const {classes} = this.props
        const deck = deckList?.get(selectedDeck)?.deckDump

        let unique = [];
        let categoryList = [];
        let filterMenu = []

        for (const [key, value] of deck.entries()) {
            let category = value.category
            
            if( !unique[category]){
                categoryList.push(category);
                filterMenu.push(
                    <MenuItem key={key} className={classes.filterItem}
                        onClick={()=>{this.handleFilter(category, 1)}}>
                            {!category ? "No Category" : category}
                    </MenuItem>)
                unique[category] = 1;
            }
        }

        return filterMenu
    }


    getUserDecks = (uid) =>{
        this.props.client.query({
            query: GET_USER_DECKS(uid)
        }).then((res) =>{
            const customDecks = res.data.userDecksById.customDecks
            let deckMap = new Map()

            for(let i = 0; i < customDecks.length; i++) {
                let deck = customDecks[i]
                deckMap.set(deck._id, {
                    name: deck.deckName,
                    deckDump: deck?.deckDump ? new Map(Object.entries(deck.deckDump)) : new Map(),
                    yamlEndpoint: deck.yamlEndpoint,
                    categoryColorMap: deck?.categoryColorMap ? new Map(Object.entries(deck.categoryColorMap)) : new Map(),
                    filterSettings: deck?.filterSettings,
                    published: deck?.published,
                    listOfCategories: deck?.listOfCategories,
                    listOfLabels: deck?.listOfLabels
                })
            }

            axios.get(`${config.backend.uri}/api/game/getDeck`, {params: {endpoint: "storyDesign"}}).then((res)=>{
                const {categories, elements, title} = res.data.deck

                let elementsMap = new Map()

                for(let i = 0; i < elements.length; i++){
                    let element = elements[i]

                    elementsMap.set(new mongoose.Types.ObjectId().toHexString(), element)
                }


                deckMap.set("1", {
                    name: title,
                    deckDump: elementsMap,
                    yamlEndpoint: "",
                    categoryColorMap: new Map([
                        ["Story", "#FF8BA7"],
                        ["Money", "#2CB67D"],
                        ["Request", "#3DA9FC"],
                        ["Genre", "#A31B1B"],
                        ["Topic", "#D08B5B"],
                        ["Premises", "#1A49A5"],
                        ["Modifier", "#219653"],
                        ["Character", "#5B3680"],
                        ["Archetype", "#FFC0C0"],
                        ["Conflict", "#FFAA0D"],
                        ["Crisis", "#4173D6"],
                        ["Issues", "#53CAA6"],
                        ["Resolution", "#7956C1"],
                        ["Dramatic Question", "#219653"],
                        ["Loglines", "#4173D6"],
                        ["Taglines", "#5B3680"],
                        ["Story Messages", "#D08B5B"],
                    ]),
                    filterSettings: { filterName: "Last Created", filterType: 0 },
                    published: true,
                    locked: true, 
                    listOfCategories: [
                        {category: "Story"},
                        {category: "Money"},
                        {category: "Request"},
                        {category: "Genre"},
                        {category: "Topic"},
                        {category: "Premises"},
                        {category: "Modifier"},
                        {category: "Character"},
                        {category: "Archetype"},
                        {category: "Conflict"},
                        {category: "Crisis"},
                        {category: "Issues"},
                        {category: "Resolution"},
                        {category: "Dramatic Question"},
                        {category: "Loglines"},
                        {category: "Taglines"},
                        {category: "Story Messages"}, 
                    ],
                  listOfLabels: [
                        {label:"Marker"}
                  ],
                });

                this.setState({
                    uid: uid,
                    loaded: true,
                    deckList: deckMap,
                    selectedDeck: deckMap.size > 0 ? deckMap.keys().next().value : null
                })
            })
        })
    }

    /**
     * openDeckNameMenu is to open deck name's menu which is location in the middle panel's top panel
     */
    openDeckNameMenu = (event) => {
        this.setState({
            anchorEl: event.currentTarget 
        })
    }
    
    /**
     * closeDeckMenu is to close deck name's menu which is location in the middle panel's top panel
     */
    closeDeckMenu = () => {
        this.setState({
            anchorEl: null
        })
    }
    
    /**
     * displayNoDeckState is to display the empty state if no deck is created on the left panel
     */
    displayNoDeckState = () =>{
        return(
            <Grid item style={{width:"200px", marginTop:"10%"}}>
                <img alt="banner" 
                style={{width:"100px", marginLeft:"25%"}} 
                src={require('../../element/sadFace.png')}/>

                <p style={{color:"#4F4F4F", fontWeight:"bold", fontSize:"14px", margin:"10px 0", textAlign:"center"}}>
                    You have no deck.<br/>Why not add new one?
                </p>
                <Button 
                startIcon={<AddBoxOutlined/>} 
                style={{marginLeft:"10%", color:"white", background:"#FA841B", '&:focus':{outline:"none"}}}
                onClick={()=>this.addDeck()}
                >
                    CREATE NEW DECK
                </Button>
            </Grid>
        )
    }

    /**
     * displayDecks is to display the list of decks in the left panel
     */
    displayDecks = (deckList) =>{
        const {classes} = this.props
        let buttonArr = []
        // let deckArray = Array.from(deckList.entries())
        
        for (const [key, value] of deckList.entries()) {
            let name = value.name
            let cards = value.deckDump
            
            buttonArr.push(
                <Grid container 
                    className={classes.leftPanelList}
                    style={this.state.selectedDeck === key ? {background:"#DBE4F6"} : null}
                    key={key}
                    onClick={() =>{
                        this.setState({
                            selectedDeck: key,
                            //card selected has to be null else an error will be thrown for the card editor
                            //in case user was still on the card editor and they switched deck
                            cardSelected: null,
                            selectedCardId: null,
                        })
                    }}
                >
                    <Grid item xs={2}>
                        {value.locked !== "undefined" && value.locked ?
                            <Tooltip title={"Locked"}>
                                <LockRounded style={{fontSize:"1rem", color:"#4F4F4F"}}/>
                            </Tooltip>
                        :
                            value.published ?
                            <Tooltip title={"Published"}>
                                <CheckCircle style={{fontSize:"1rem", color:"#4F4F4F"}}/>
                            </Tooltip>
                            :
                            <Tooltip title={"Draft"}>
                                <Create style={{fontSize:"1rem", color:"#4F4F4F"}}/>
                            </Tooltip>
                        }
                        
                        
                    </Grid>
                    <Grid item xs={7}>
                        <p className={classes.leftPanelListFont}>
                            {!name ? <i style={{color:"#c4c4c4"}}>Blank</i> : 
                                name.length > 12 ? name.slice(0,12).concat('...') : name}
                        </p>
                    </Grid>
                    <Grid item xs={3}>
                        <p className={classes.leftPanelListCardNo}>
                            {cards.size} 
                        </p>
                    </Grid>
                </Grid>
                
            )
        }
        return buttonArr
    }

    /**
     * displayTopPanel is to display the top panel within the middle panel
     */
    displayTopPanel = () =>{
        const {classes} = this.props
        const {deckList, selectedDeck} = this.state
        const published = deckList?.get(selectedDeck)?.published
        const filterName = deckList?.get(selectedDeck)?.filterSettings.filterName
        const isDefaultDeck = selectedDeck === "1"
        const locked = deckList.get(selectedDeck)?.locked
        return(
            <>
                <Grid container justify="center" alignItems="center" style={{marginTop:"0.6rem"}}>
                    <Grid container style={{width:"800px", margin:"auto"}}>
                        <Grid item xs={4}>
                        {/* Leave empty */}
                        </Grid>
                        <Grid item xs={4}>
                            <Grid container justify="center" alignItems="center">
                                <Grid item>
                                    {this.state.selectedDeck ?
                                        <div style={{fontSize:"14px", textAlign:"center", margin:"0", color:"#4F4F4F", display:"inline"}}>
                                            {/* Changes is it's published or not */}
                                            {locked ? "Locked" : published ? "Published" : "Draft"}
                                            &nbsp; / &nbsp; 
                                            
                                            {/* Deck name */}
                                            <ClickAwayListener
                                                onClickAway={() =>
                                                    this.setState({
                                                        canEditDeckName: false
                                                    })
                                                }
                                            >
                                                <Input
                                                    style={{
                                                        width: this.state.deckList.get(this.state.selectedDeck)?.name.length * 8,
                                                        minWidth:40
                                                    }}
                                                    className={this.state.canEditDeckName ? classes.deckEditOn : classes.deckEditOff}
                                                    width="auto"
                                                    inputProps={{maxLength:17}}
                                                    disableUnderline
                                                    name="Deck Name"
                                                    value={this.state.deckList.get(this.state.selectedDeck)?.name}
                                                    readOnly={!this.state.canEditDeckName}
                                                    onChange={event => this.handleDeckNameChange(event)}
                                                    onClick={() => this.setState({canEditDeckName: true})}
                                                />
                                            </ClickAwayListener>
                                            <IconButton onClick={(e) => this.openDeckNameMenu(e)} style={{width:"14px", height:"14px", marginTop:"-2px", outline:"none"}}>
                                                <ExpandMore style={{fontSize:"14px"}}/>
                                            </IconButton>
                                        </div>
                                    : 
                                    null
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={4}>
                            <div style={{marginRight:"20px", float:"right", borderRadius:"10px"}}>
                                {/* Filter / Sort Button */}
                                <Button style={{border:"1px solid #4F4F4F", color:"#828282", outline:"none", marginRight:"10px",'&:focus':{outline:"none"}}} 
                                        onClick={(event)=>{this.setState({filterAnchorEl: event.currentTarget})}}>
                                    <p style={{textTransform:"none", fontFamily:"Arial", margin:"0"}}>
                                        {filterName ? filterName : "No Category"}
                                    </p>
                                    <ExpandMore style={{marginLeft:"10px", fontSize:"14px"}}/>
                                </Button>
                                {/* Upload Button */}
                                <Dropzone
                                    multiple
                                    noDrag={true}
                                    onDrop={(files)=>{

                                        for(var i = 0; i<files.length;i++){
                                            const reader = new FileReader();
                                            let file = files[i]

                                            reader.onabort = () => console.log("file reading was aborted");
                                            reader.onerror = () => console.log("file reading failed");
                                            reader.onload = () => {
                                            // Parse CSV file
                                            csv.parse(reader.result,{delimiter: ',', trim: true, columns: true, bom: true}, (err, data) => {
                                                // console.log("Parsed CSV data: ", data);
                                                this.importCard(data)
                                            });
                                            };

                                            reader.readAsBinaryString(file);
                                        }
                                    }}
                                    accept=".csv"
                                    > 
                                        {({getRootProps, getInputProps}) => (
                                            <IconButton {...getRootProps()} style={{width:"30px", height:"30px", outline:"none"}} disabled={locked}>
                                                <input {...getInputProps()} />
                                                <GetAppRounded style={{fontSize:"18px"}}/>
                                            </IconButton>             
                                        )}
                                </Dropzone>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>

                {/* Deck ellipse menu */}
                <Menu 
                    anchorEl={this.state.anchorEl}
                    open={Boolean(this.state.anchorEl)}
                    onClose={()=>this.closeDeckMenu()}
                    getContentAnchorEl={null}
                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                    transformOrigin={{ vertical: "top", horizontal: "center" }}
                    PaperProps={{style: {color:"#4F4F4F",border:"0.5px solid #c4c4c4", boxShadow:"none"}}}  
                >
                    <MenuItem 
                        disabled={isDefaultDeck}
                        onClick={() =>{
                            this.handlePublish()
                            this.closeDeckMenu()
                        }} 
                        className={classes.deckMenuList}
                    >
                        {/* Publish deck */}
                        <CheckCircle className={classes.deckMenuListIcon}/> 
                        Publish
                    </MenuItem>
                    <MenuItem 
                        onClick={() => {
                            this.duplicateDeck() 
                            this.closeDeckMenu()
                        }} 
                        className={classes.deckMenuList}
                    >
                        {/* Duplicate deck */}
                        <AddToPhotosOutlined className={classes.deckMenuListIcon}/> 
                        Duplicate
                    </MenuItem>
                    <MenuItem
                        disabled={isDefaultDeck} 
                        onClick={() => {
                            this.removeDeck() 
                            this.closeDeckMenu()
                        }} 
                        className={classes.deckMenuList}
                    >
                        {/* Delete deck */}
                        <DeleteOutlined className={classes.deckMenuListIcon}/> 
                        Delete
                    </MenuItem>
                </Menu >
                
                {/* Filter and sort drop down menu */}
                <Menu 
                    disabled={isDefaultDeck}
                    anchorEl={this.state.filterAnchorEl}
                    open={Boolean(this.state.filterAnchorEl)}
                    onClose={()=>this.closeFilterMenu()}
                    getContentAnchorEl={null}
                    anchorOrigin={{ vertical:"bottom", horizontal:"right" }}
                    transformOrigin={{ vertical:"top", horizontal:"right" }}
                    PaperProps={{style: {color:"#4F4F4F",border:"0.5px solid #c4c4c4", boxShadow:"none"}}}  
                >
                    <ListSubheader className={classes.filterLabel}><strong>Sort By</strong></ListSubheader>
                        <MenuItem className={classes.filterItem} onClick={()=>{this.handleFilter("Last Created", 0)}}>Last Created</MenuItem>
                        <MenuItem className={classes.filterItem} onClick={()=>{this.handleFilter("Category", 0)}}>Category</MenuItem>
                  
                    <ListSubheader className={classes.filterLabel}><strong>Filter By Category</strong></ListSubheader>
                        {this.getDeckCategories()}
                </Menu >
            </>
        )
    }


    /**
     * closeFilterMenu to close the filter & sort dropdown menu
     */
    closeFilterMenu = () =>{
        this.setState({
            filterAnchorEl: null
        })
    }

    /**
     * handleFilter to handle the filter and sort system of the deck
     * @param {*} name - filter name 
     * @param {*} type - filter type (0 - Sort, 1 - Filter) By Default, the cards are sorted by CreatedOn value
     */
    handleFilter = (name, type) =>{
        const deckId = this.state.selectedDeck
    
        this.setState(state =>{
            const deckList = state.deckList
            deckList.get(deckId).filterSettings.filterName = name
            deckList.get(deckId).filterSettings.filterType = type
            return{
                deckList
            }
        })
    }

    /**
     * handleDeckNameChange to handle the change of deck name
     * @param {*} event - event target value
     */
    handleDeckNameChange = (event) => {
        const deckId = this.state.selectedDeck
        let textValue = event.target.value

        this.timeOut && clearTimeout(this.timeOut)

        this.setState(state =>{
            const deckList = state.deckList
            deckList.get(deckId).name = textValue

            return{
                deckList
            }
        }, () =>{
            this.timeOut = setTimeout(() =>{
                this.props.client.mutate({
                    mutation: USER_UPDATE_DECK_NAME,
                    variables:{
                        _id: this.state.uid,
                        deckID: deckId,
                        deckName: textValue
                    }
                })
            }, 500)
        })
    }
    
    /**
     * addDeck is to create a new deck
     */
    addDeck = () =>{
        //create new id for new deck object
        const newId = new mongoose.Types.ObjectId().toHexString()
        const deck = this.state.deckList
        
        deck.set(newId, {
            name:"Untitled", 
            deckDump: new Map(), 
            yamlEndpoint: null, 
            categoryColorMap: new Map(), 
            published:false, 
            filterSettings:{filterName: "Last Created", filterType: 0},
            listOfCategories:[],
            listOfLabels:[]
        })

        this.setState({
            deckList: deck,
            selectedDeck: newId,
            //card selected has to be null else an error will be thrown for the card editor
            //in case user was still on the card editor and they switched deck
            cardSelected: null,
            selectedCardId: null,
        }, () =>{

            const deckData = {
                deckName: "Untitled",
                deckDump: {},
                categoryColorMap: {},
                published: false,
                filterSettings:{filterName: "Last Created", filterType: 0},
                listOfCategories: [],
                listOfLabels: []
            }
            
            this.mutateDeck(newId, deckData)
        })
    }

    /**
     * removeDeck is to remove a new deck
     */
    removeDeck = () =>{
        const deck = this.state.deckList
        let selectedDeck = null

        deck.delete(this.state.selectedDeck)

        this.props.client.mutate({
            mutation: USER_REMOVE_DECK,
            variables:{
                _id: this.state.uid,
                name: getUsername(),
                deckID: this.state.selectedDeck
            }
        })

        if(deck.size > 0){ //to automatically select the 1st deck in decklist on delete
            selectedDeck = deck.keys().next().value
        }

        this.setState({
            deckList: deck,
            selectedDeck: selectedDeck,
            //card selected has to be null else an error will be thrown for the card editor
            //in case user was still on the card editor and they switched deck
            cardSelected: null,
            selectedCardId: null,
        },
        ()=>{
            this.snackbar("Deleted","Your deck is successfully deleted")
        })
    }

    /**
     * duplicateDeck is to duplicate the selected deck
     */
    duplicateDeck = () =>{
        //create new id for new deck object
        const newId = new mongoose.Types.ObjectId().toHexString()
        const deckList = this.state.deckList
        const deck = this.state.selectedDeck

        let name = deckList.get(deck)?.name.includes("Copy") ? deckList.get(deck)?.name : `${deckList.get(deck)?.name} Copy`
        let deckDump = deckList.get(deck)?.deckDump

        const {categoryColorMap, listOfCategories, listOfLabels} = deckList.get(deck)

        deckList.set(newId, _.cloneDeep(deckList.get(deck)))
        deckList.get(newId).name = name
        deckList.get(newId).locked = false
        deckList.get(newId).published = false
        
        this.setState({
            deckList: deckList,
            selectedDeck: newId,
            //card selected has to be null else an error will be thrown for the card editor
            //in case user was still on the card editor and they switched deck
            cardSelected: null,
            selectedCardId: null,
        }, () =>{
            const deckData = {
                deckName: name,
                deckDump: Object.fromEntries(deckDump),
                categoryColorMap: Object.fromEntries(categoryColorMap),
                published: false,
                filterSettings:{filterName: "Last Created", filterType: 0},
                listOfCategories: listOfCategories,
                listOfLabels: listOfLabels
            }

            this.mutateDeck(newId, deckData)

            this.snackbar("Duplicated", `Deck is duplicated as ${name}`)
        })
    }

    mutateDeck = (newId, deckData) =>{
        const {uid} = this.state
        this.props.client.mutate({
            mutation: USER_UPDATE_DECK,
            variables:{
                _id: uid,
                deckID: newId,
                deckData: deckData
            }
        })
    }

    getColor = (category) =>{
        const categoryColorMap = this.state.deckList.get(this.state.selectedDeck).categoryColorMap

        return categoryColorMap.get(category)
    }

    /**
     * displayCards is to display all the cards in the deck within the middle panel
     * @param {*} selectedDeck - deck that is selected
     */
    displayCards = () =>{
        let deckArr = []
        const {deckList, selectedDeck} = this.state
        const {imgUploaded, imgSrc} = this.state.imgData
        const {classes} = this.props
        let color = null
        const locked = deckList.get(selectedDeck)?.locked

        const deck = this.sortFilterCards()
        //console.log(deck)
        //Cards in the deck
        if(deck){
            for(let [key, value] of Array.from(deck).reverse()){
                if(value){
                    //console.log(value)
                    color = this.getColor(value.category)
                    deckArr.push(
                        <LazyLoad // LazyLoader Module
                            key={key}
                            //Set the height and width as the same as the height of the card
                            height={"100%"}
                            unmountIfInvisible={true}
                        >
                            <div>
                                {/* Card begins here */}
                                <div
                                    className={classes.cards}
                                    style={this.state.cardSelected === key ? 
                                            {border:"2px solid #FFAA0D", boxShadow:"0 0 5px #FFAA0D", background:"white", cursor:"pointer"}: 
                                            {border:"none", background:"white", cursor:"pointer"}}
                                    onClick={()=>{
                                        this.setState({
                                            cardSelected: key,
                                            cardValue: value
                                        })
                                    }}
                                >
                                    <div style={{height:"20%", display:"block"}}>
                                        <Grid container>
                                            <Grid item xs={6}>
                                                <IconButton disabled className={classes.cardIcon} style={{background: color ? color : "#C4C4C4"}}>
                                                    <Eco className={classes.cardIconSize} style={{color:"white"}}/>
                                                </IconButton>
                                            </Grid>
                                            <Grid item xs={6}>
                                                {/* Ellipse menu button */}
                                                <IconButton 
                                                    className={classes.cardEllipse} 
                                                    onClick={(e) => this.openCardMenu(key, e, value)}
                                                    disabled={locked}
                                                >
                                                    <MoreVert className={classes.cardIconSize}/>
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                    </div>
                                    <div style={{height:"60%"}}>
                                        {/* Category and content */}
                                        <p className={classes.cardFont} style={{color: color ? color : "#C4C4C4"}}>
                                            {value.category ? value.category : "No Category"}
                                        </p>
                                        <div className={classes.cardImgHolder} style={{display:value.imgUrl ? "block" : "none"}}> 
                                            <img 
                                                style={{width:"100%"}} 
                                                alt="img" 
                                                src={`${config.backend.uri}/static/uploads/${value.imgUrl}`}
                                            />
                                        </div>
                                        <p className={classes.cardFont} style={{display:value.imgUrl ? "none" : "block", width:"calc(100% - 20px)", overflowY:"auto", height:"100px"}}>
                                            {value.content ? value.content : 
                                            <span style={{fontWeight:"normal", color:"#c4c4c4"}}>Write your story here...</span>}
                                        </p>
                                    </div>
                                    <div style={{height:"20%"}}>
                                        {/* Label and word length */}
                                        <Tooltip title={
                                            value?.label?.map((tag, index)=>(
                                                index !== value?.label?.length - 1 ?
                                                tag + ", " : tag
                                            ))}
                                            disableFocusListener={value?.label?.length > 0 ? false : true}
                                            disableTouchListener={value?.label?.length > 0 ? false : true}
                                            disableHoverListener={value?.label?.length > 0 ? false : true}
                                            >
                                            <div className={classes.tag}>
                                                <LocalOfferOutlined className={classes.tagIconSize}/>
                                                    {value.label.length}
                                            </div>
                                        </Tooltip>
                                        <p className={classes.cardContentLength}>
                                            {imgUploaded ? 0: 150 - value.content.length}
                                        </p>
                                    </div>
                                </div>
                                {/* Card ends here */}
                            </div>
                        </LazyLoad>
                    )
                }
            }
        }
    
        return deckArr
    }

    /**
     * sortFilterCards is to sort or/and filter the deck into their respective choices
     * Filter type (0 - Sort, 1 - Filter)
     *  If filter type is 0, the cards will only sort by "Last Created" / "By Category" 
     *  If filter type is 1, the cards will sort by "Last Created" AND filter by specified category
     */
    sortFilterCards = () =>{
        const {deckList, selectedDeck} = this.state
        const cardList = deckList?.get(selectedDeck)?.deckDump
        const filterName = deckList.get(selectedDeck)?.filterSettings.filterName
        const filterType = deckList.get(selectedDeck)?.filterSettings.filterType
        //Convert map to array to begin sorting
        let cards = Array.from(cardList, ([key, value]) => ({key, value}))
        
        //console.log(cards)
        
        if(filterType===0 && filterName==="Category"){
            //Sort by Category
            cards = cards.sort((a,b) => (a.value.category < b.value.category) ? -1 : ((b.value.category > a.value.category) ? 1 : 0))
        }
        else{
            //Sort by Last Created
            cards = cards.sort((a,b) => (a.value.createdOn > b.value.createdOn) ? -1 : ((b.value.createdOn < a.value.createdOn) ? 1 : 0))
        
            if(filterType===1){
                //Filter by specified category
                cards = cards.filter(function(a) {return a.value.category === filterName;});
            }
        }
        
        //Convert array back to map
        let sortedCards = new Map(cards.map(i => [i.key, i.value]))

        return sortedCards
    }

    /**
     * moveMenu is to show to the list of decks the card can move to in the "Move To" menu
     */
    moveMenu = () =>{
        const deckList = this.state.deckList
        const {classes} = this.props
        const card = deckList?.get(this.state.selectedDeck)?.deckDump?.get(this.state.selectedCardId) 

        let menuArr = []

        for (const [key, value] of deckList.entries()) {
            if(key!== this.state.selectedDeck){
                menuArr.push(
                    <MenuItem key={key} className={classes.cardMenuList} onClick={() => this.moveCard(this.state.selectedCardId, card, key)}>
                        {value.name}
                    </MenuItem>
                )
            }
                
        }

        return menuArr
    }

    /**
     * openCardMenu is to open individual card ellipse menu
     * @param {*} cardId - the card's key / id value
     * @param {*} event - event target
     * @param {object} cardValue - card's value to handle the functions like duplicate 
     */
    openCardMenu = (cardId, event, cardValue) =>{
        event.stopPropagation() //stops the parent's event 

        this.setState({
            selectedCardId: cardId,
            cardSelected: cardId,
            cardValue: cardValue,
            cardAnchorEl: event.currentTarget
        })
    }

    /**
     * closeCardMenu is to close the card's ellipse menu
     */
    closeCardMenu = () =>{
        this.setState({
            selectedCardId: null,
            cardAnchorEl: null,
            cardSelected: null,
            cardValue: null,
        })
    }

    /**
     * openCardMoveMenu is to open individual card ellipse MOVE TO menu
     * @param {*} cardId - the card's key / id value
     * @param {*} event - event target
     */
    openCardMoveMenu = (cardId, event) =>{
        event.stopPropagation() //stops the parent's event 
        this.closeCardMenu() 
        this.setState({
            selectedCardId: cardId,
            moveAnchorEl: event.currentTarget
        })
    }

    /**
     * closeCardMenu is to close the card's ellipse menu
     */
    closeCardMoveMenu = () =>{
        this.setState({
            selectedCardId: null,
            moveAnchorEl: null
        })
    }

    /**
     * createCard is to create new cards
     * @param {*} selectedDeck is the selected deck 
     */
    createCard = (selectedDeck) =>{
        //create new id for new deck object
        const newId = new mongoose.Types.ObjectId().toHexString()
        this.setState(state =>{
            const deckList = state.deckList
            const deck = deckList.get(selectedDeck).deckDump
            let cardSelected = state.cardSelected
            //to open card editor targeting the newly created card
            cardSelected = newId

            deck.set(newId, {
                content: "",
                label: [],
                category: "", 
                createdOn: new Date()
            })

            return{
                deckList, 
                cardSelected
            }
        }, () => { 
            this.props.client.mutate({
                mutation: USER_UPDATE_DECK_CARDS,
                variables: {
                    _id: this.state.uid,
                    deckID: selectedDeck,
                    deckDump: Object.fromEntries(this.state.deckList.get(selectedDeck).deckDump),
                    categoryColorMap: Object.fromEntries(this.state.deckList.get(selectedDeck).categoryColorMap),
                    listOfCategories: this.state.deckList.get(selectedDeck).listOfCategories,
                    listOfLabels: this.state.deckList.get(selectedDeck).listOfLabels
                }
            })
        })
    }

    // importChecker = (data) => {

    // }

    importCard = (imports) => {
        const selectedDeck = this.state.selectedDeck
        let curDeck = this.state.deckList.get(selectedDeck).deckDump
        let listOfCategories = this.state.deckList.get(selectedDeck).listOfCategories
        const categoryColorMap = this.state.deckList.get(selectedDeck).categoryColorMap
        
        // Get import categories
        let n = _.uniqBy(imports,'category')
        for(let i = 0; i<n.length; i++){
            if(!listOfCategories.includes(n[i].category)){
                listOfCategories.push({category: n[i].category}) // push into current list of categories
                categoryColorMap.set(n[i].category, "#c4c4c4")
            }
        }

        // Create Card for each entry
        for(var i = 0; i<imports.length; i++){
            let newId = new mongoose.Types.ObjectId().toHexString()
            let card = imports[i]
            var label;
            //console.log(card)
            // console.log(card.content,card.category)
            // Process label with delimiter
            if(card.label !== ""){
                label = this.processLabel(card.label)
            }else{
                label = []
            }

            curDeck.set(newId,{
                content: card.content,
                label,
                category: card.category,
                createdOn: new Date(),
            })
        }

        this.setState({
            categoryColorMap,
            listOfCategories,
            deck: curDeck
        }, () => { 
            this.props.client.mutate({
                mutation: USER_UPDATE_DECK_CARDS,
                variables: {
                    _id: this.state.uid,
                    deckID: selectedDeck,
                    deckDump: Object.fromEntries(this.state.deckList.get(selectedDeck).deckDump),
                    categoryColorMap: Object.fromEntries(this.state.deckList.get(selectedDeck).categoryColorMap),
                    listOfCategories: this.state.deckList.get(selectedDeck).listOfCategories,
                    listOfLabels: this.state.deckList.get(selectedDeck).listOfLabels
                }
            })
            this.snackbar("Uploaded", `Card is successfully uploaded`)
        })
        
    }

    processLabel = (label) => {
        return label.split(/[ ,]+/)
    }

    /**
     * duplicateCard is to duplicate selected card
     * @param {*} card is the selected card 
     */
    duplicateCard = (card) =>{
        let selectedDeck = this.state.selectedDeck
        //create new id for new deck object
        const newId = new mongoose.Types.ObjectId().toHexString()

        this.setState(state =>{
            const deck = state.deckList.get(selectedDeck).deckDump

            deck.set(newId, {
                content: card.content,
                label: card.label,
                category: card.category, 
                createdOn: new Date()
            })

            return{
                deck
            }
        }, () =>{
            let deckData = {
                deckName: this.state.deckList.get(selectedDeck).name,
                deckDump: Object.fromEntries(this.state.deckList.get(selectedDeck).deckDump),
                categoryColorMap: Object.fromEntries(this.state.deckList.get(selectedDeck).categoryColorMap),
                published: this.state.deckList.get(selectedDeck).published,
                filterSettings:{filterName: "Last Created", filterType: 0},
                listOfCategories: this.state.deckList.get(selectedDeck).listOfCategories,
                listOfLabels: this.state.deckList.get(selectedDeck).listOfLabels
            }

            this.mutateDeck(selectedDeck, deckData)

            this.closeCardMenu()
        })
    }

    /**
     * deleteCard is to delete selected card
     * @param {*} cardId is the selected card's ID
     */
    deleteCard = (cardId, callSnackbar = true) =>{
        let selectedDeck = this.state.selectedDeck
        
        this.setState(state =>{
            const deck = state.deckList.get(selectedDeck).deckDump
            let cardSelected = state.cardSelected
            let selectedCardId = state.selectedCardId

            //card selected has to be null else an error will be thrown for the card editor
            //in case user was still on the card editor and they switched deck
            cardSelected = null
            selectedCardId = null

            deck.delete(cardId)
            
            return{
                deck, cardSelected, selectedCardId
            }
        }, () =>{
            const deckData = {
                deckName: this.state.deckList.get(selectedDeck).name,
                deckDump: Object.fromEntries(this.state.deckList.get(selectedDeck).deckDump),
                categoryColorMap: Object.fromEntries(this.state.deckList.get(selectedDeck).categoryColorMap),
                published: this.state.deckList.get(selectedDeck).published,
                filterSettings:{filterName: "Last Created", filterType: 0},
                listOfCategories: this.state.deckList.get(selectedDeck).listOfCategories,
                listOfLabels: this.state.deckList.get(selectedDeck).listOfLabels
            }
            
            this.mutateDeck(selectedDeck, deckData)

            if(callSnackbar){
                this.snackbar("Deleted", `Card is successfully deleted`)
            }
            

            this.closeCardMenu()
        })
    }

    /**
     * moveCard is to move selected card to the targeted deck
     * @param {*} card is the selected card's values
     * @param {*} targetedDeck is the targeted deck
     */
    moveCard = (cardId, card, targetedDeck) =>{
        const {deckList, selectedDeck} = this.state

        //create new id for new deck object
        const newId = new mongoose.Types.ObjectId().toHexString()

        //duplicate card into the targeted deck
        this.setState(state =>{
            const deck = deckList?.get(targetedDeck)?.deckDump
            let cardSelected = state.cardSelected
            let selectedCardId = state.selectedCardId

            //card selected has to be null else an error will be thrown for the card editor
            //in case user was still on the card editor and they switched deck
            cardSelected = null
            selectedCardId = null

            deck.set(newId, {content: card.content,
                label: card.label,
                category: card.category, 
                createdOn: new Date()
            })

            return{
                deck, cardSelected, selectedCardId
            }
        }, () =>{
            /**
             * Save selected deck's value
             */
            let deckData = {
                deckName: this.state.deckList.get(selectedDeck).name,
                deckDump: Object.fromEntries(this.state.deckList.get(selectedDeck).deckDump),
                categoryColorMap: Object.fromEntries(this.state.deckList.get(selectedDeck).categoryColorMap),
                published: this.state.deckList.get(selectedDeck).published,
                filterSettings:{filterName: "Last Created", filterType: 0},
                listOfCategories: this.state.deckList.get(selectedDeck).listOfCategories,
                listOfLabels: this.state.deckList.get(selectedDeck).listOfLabels
            }

            this.mutateDeck(selectedDeck, deckData)

            /**
             * Save targeted deck's value
             */
            deckData = {
                deckName: this.state.deckList.get(targetedDeck).name,
                deckDump: Object.fromEntries(this.state.deckList.get(targetedDeck).deckDump),
                categoryColorMap: Object.fromEntries(this.state.deckList.get(targetedDeck).categoryColorMap),
                published: this.state.deckList.get(targetedDeck).published,
                filterSettings:{filterName: "Last Created", filterType: 0},
                listOfCategories: this.state.deckList.get(targetedDeck).listOfCategories,
                listOfLabels: this.state.deckList.get(targetedDeck).listOfLabels
            }

            this.mutateDeck(targetedDeck, deckData)

            this.snackbar("Moved", `Card is successfully moved`)
        })

        this.deleteCard(cardId, false)

        this.closeCardMoveMenu()
    }

    /**
     * displayCardEditor is to show the right panel's card editor and to function as the card personalization editor
     */
    displayCardEditor = () =>{
        const {classes} = this.props
        const filter = createFilterOptions()

        const {cardSelected, selectedDeck, deckList, contentAnchorEl} = this.state
        const {imgUploaded, imgSrc} = this.state.imgData
        const deck = this.state.deckList.get(selectedDeck).deckDump
        const {listOfCategories, listOfLabels} = this.state.deckList.get(selectedDeck)
        const card = deck.get(cardSelected)
        const locked = deckList.get(selectedDeck)?.locked
        let categoryValue = card?.category ? 
                            [{category: card.category}] 
                            : 
                            []

        let labelValue =    card?.label ? 
                            card.label.map((value) =>{
                                return {label: value}
                            }) 
                            : 
                            []

        let contentValue = card?.content
        let color = this.getColor(card.category)
        let imgUrl = card?.imgUrl

        return(
            <>
            <Grid container style={{margin:"0 1rem 1rem 1rem", width:"calc(100% - 2rem)"}}>
                {/* Card category field */}
                <Grid item xs={12} className={classes.field}>
                    <Tooltip title={"Select"}>
                        <LayersOutlined className={classes.icon}/>
                    </Tooltip> 
                    Category
                    <p style={{color:"#8C8C8C", fontSize:"10px", marginBottom:"5px"}}>
                        This is the card’s category. The card’s color will follow the category’s color.
                    </p>
                    <Autocomplete
                        classes={{
                            option: classes.optionStyles
                        }}
                        multiple
                        disabled={locked}
                        options={listOfCategories ?? []}
                        value={categoryValue}
                        onChange={(event, value) =>{
                            if(typeof value[value.length - 1] === "string"){
                                value[value.length - 1] = {category: value[value.length - 1]}
                            }
                            this.handleCategoryChange(value, cardSelected)
                        }}
                        size="small"
                        filterOptions={(options, params) => {
                            const filtered = filter(options, params);
                    
                            // Suggest the creation of a new value
                            if (params.inputValue !== '') {
                                filtered.push({
                                    inputValue: params.inputValue,
                                    category: `Add "${params.inputValue}"`,
                                });
                            }

                            filtered.length > 0  && filtered.sort((a, b) => a.category.toLowerCase().localeCompare(b.category.toLowerCase()));
                            return filtered;
                        }}
                        getOptionLabel={(option) => option.category}
                        getOptionDisabled={(option) =>
                            categoryValue.map((object) =>{
                                return option.category !== object.category
                            })
                        }
                        getOptionSelected={(option, value) => option.label === value.label}
                        disableCloseOnSelect
                        disablePortal
                        selectOnFocus
                        clearOnBlur
                        freeSolo
                        underlinestyle={{display: 'none'}}
                        renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                                <Chip
                                    variant="default"
                                    label={option.category}
                                    style={{background: color ? color : "#C4C4C4", borderRadius:"5px", border:"none", color:"white"}}
                                    size="small"
                                    {...getTagProps({ index })}
                                />
                            ))
                        }
                        renderOption={(option,state)=>(
                            <>
                            <Grid container alignItems="center">
                                <Grid item xs={8}>{option.category}</Grid>
                                <Grid item xs={4}>
                                    <IconButton
                                        style={{padding:"5px", float:"right"}} 
                                        onClick={(event)=>{
                                            //This is required as to stop <Chip> from also registering
                                            //the click if we click on <IconButton>
                                            event.stopPropagation();
                                            this.setState({
                                                colorAnchorEl: event.currentTarget, 
                                                colorMenu:true}, 
                                                () =>{
                                                    this.selectedCategoryEllipsis = option.category 
                                                }
                                            )
                                        }}
                                    >
                                        <MoreVert style={{fontSize:"12px"}}/>
                                    </IconButton>
                                </Grid>
                            </Grid>
                             
                            </>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                placeholder={categoryValue.length > 0 ? "" : "Type for category"}
                                style={{background:"#FAF7EA", padding:"5px", fontSize:"12px", borderRadius:"5px", border:"1px solid #D1D1D1"}}
                                InputProps={{...params.InputProps, disableUnderline: true, style:{fontSize:"12px"}}}
                                variant="standard"
                            />
                        )}
                    />
                </Grid>

                {/* Card label field */}
                <Grid item xs={12} className={classes.field}>
                    <Tooltip title={"Multi-select"}>
                        <FormatListBulleted className={classes.icon}/>
                    </Tooltip> 
                    Label
                    <p style={{color:"#8C8C8C", fontSize:"10px", marginBottom:"5px"}}>
                        This is the card’s label. 
                    </p>
                    <Autocomplete
                        disabled={locked}
                        classes={{
                            option: classes.optionStyles
                        }}
                        multiple
                        options={listOfLabels ?? []}
                        value={labelValue}
                        onChange={(event, value) =>{
                            //Accommodating to some stupid behavior where if you press Enter outside the input box,
                            //the newly added label is a string therefore it is needed to change the data a little
                            if(typeof value[value.length - 1] === "string"){
                                value[value.length - 1] = {label: value[value.length - 1]}
                            }

                            this.handleLabelChange(value, cardSelected) 
                        }}
                        filterOptions={(options, params) => {
                            const filtered = filter(options, params);
                    
                            // Suggest the creation of a new value
                            if (params.inputValue !== '') {
                                filtered.push({
                                    inputValue: params.inputValue,
                                    label: `Add "${params.inputValue}"`,
                                });
                            }

                            filtered.length > 0  && filtered.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()))
                            return filtered;
                        }}
                        size="small"
                        getOptionLabel={(option) => {
                            // Value selected with enter, right from the input
                            if (typeof option === 'string') {
                                return option;
                            }
                            // Add "xxx" option created dynamically
                            if (option?.inputValue) {
                                return option.inputValue;
                            }
                            // Regular option
                            return option.label;
                        }}
                        getOptionSelected={(option, value) => option.label === value.label}
                        disableCloseOnSelect
                        disablePortal
                        underlinestyle={{display: 'none'}}
                        selectOnFocus
                        clearOnBlur
                        renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                                <Chip
                                    variant="default"
                                    label={option.label}
                                    style={{
                                        background:"#686868", 
                                        borderRadius:"5px", 
                                        border:"none", 
                                        color:"white",
                                    }}
                                    size="small"
                                    {...getTagProps({ index })}
                                />
                            ))
                        }
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                placeholder={labelValue.length > 0 ? "" : "Type for label"}
                                style={{background:"#FAF7EA", padding:"5px", fontSize:"12px", borderRadius:"5px", border:"1px solid #D1D1D1"}}
                                InputProps={{...params.InputProps, disableUnderline: true, style:{fontSize:"12px"}}}
                                variant="standard"
                            />
                        )}
                        renderOption={(option,state)=>(
                            <Grid container alignItems="center">
                                <Grid item xs={8}>
                                    {option.label}
                                </Grid>
                            </Grid>
                        )}
                        freeSolo
                    />
                </Grid>

                {/* Card content field */}
                <Grid item xs={12} className={classes.field} style={{position:"relative"}}>
                    <Tooltip title={"Text"}>
                        <TextFields className={classes.icon}/>
                    </Tooltip> 
                    Content
                    <p style={{color:"#8C8C8C", fontSize:"10px", marginBottom:"5px"}}>
                    This is the card’s content.
                    </p>
                    <IconButton 
                    onClick={(event)=>{this.setState({contentAnchorEl:event.currentTarget})}} 
                    disabled={locked}
                    style={{position:"absolute", 
                            color: locked ? "#D1D1D1" : "#4F4F4F", 
                            outline:"none", 
                            top:"0", 
                            right:"0"}}>
                        <MoreVert style={{fontSize:"16px"}}/>
                    </IconButton>
                    <Dropzone 
                        onDrop={(files)=>{this.onDrop(files,cardSelected)}}
                        noClick={true} 
                        noKeyboard={true} 
                    > 
                        {({getRootProps, getInputProps, isDragActive }) => (
                            <div {...getRootProps()} >
                                <input {...getInputProps()} />
                                {isDragActive && contentValue.length === 0 ?
                                    <p className={classes.dropZone}>
                                        <b>Drag and drop image here</b>
                                    </p>
                                :
                                    <>
                                        <div className={classes.imgHolderWrapper}  style={{display:imgUrl ? "block" : "none"}}>
                                            <div className={classes.imgHolder}>
                                                <IconButton className={classes.imgDelete}>
                                                    <CloseRounded style={{fontSize:"12px"}} onClick={()=>this.removeImg(cardSelected)}/>
                                                </IconButton>
                                                <img 
                                                    style={{width:"90%", margin:"auto"}} 
                                                    alt="img" 
                                                    src={`${config.backend.uri}/static/uploads/${imgUrl}`}
                                                />
                                            </div>
                                        </div>
                                        <TextField
                                            multiline
                                            onPaste={(event)=>{if(!card.content && event.clipboardData.types.includes('Files')){this.onImgPaste(event, cardSelected)}}}
                                            style={{display:imgUrl ? "none" : "block", background:"#FAF7EA", padding:"5px", width:"100%", fontSize:"12px", borderRadius:"5px", border:"1px solid #D1D1D1"}}
                                            InputProps={{disableUnderline: true, style:{fontSize:"12px"}}}
                                            variant="standard"
                                            value={contentValue}
                                            placeholder="Write your story here..."
                                            rows={4} 
                                            rowsMax={10} 
                                            inputProps={{maxLength: 150}}
                                            disabled={locked}
                                            onChange={(event) => this.handleContentChange(event, cardSelected)}
                                            // onKeyUp={()=>{
                                            //     clearTimeout(typingTimer);
                                            //     typingTimer = setTimeout(this.typingFunc(cardSelected), doneTypingInterval)
                                            // }}
                                            // onKeyDown={()=>{
                                            //     clearTimeout(typingTimer);
                                            // }}
                                        />
                                    </>
                                }
                            </div>
                        )}  
                    </Dropzone>
                    <p id="words" 
                        style={{
                        display:"inline-block",
                        fontSize:"10px",
                        margin:"0",
                        marginTop:"0px",
                        opacity:1,
                        color:"#8C8C8C"}}>
                        {imgUploaded ? 0 : 150 - contentValue.length}
                    </p>
                </Grid>
            </Grid>
            {/* Color picker for category */}
            <Popper 
                style={{position:"relative", background:"white", padding: "1rem"}} 
                open={this.state.colorAnchorEl} 
                anchorEl={this.state.colorAnchorEl}
                onClick={()=>{this.closePopper()}}
                transition
            >
                <p style={{fontSize:"12px", color:"#4f4f4f"}}>Category color</p>
                <ClickAwayListener onClickAway={this.closePopper}>
                    <CirclePicker
                        color={
                            card?.category ? 
                                this.state.deckList.get(selectedDeck).categoryColorMap.get(card?.category) : 
                                '#C4C4C4'
                        }
                        colors={['#6246EA', '#2CB67D', '#4FC4CF', '#FF8BA7', '#F25F4C', '#4F4F4F', '#9656A1', '#078080', '#3DA9FC', '#E53170', '#FF8E3C', '#C4C4C4']}
                        
                        onChangeComplete={(c,e)=>{
                            this.updateColor(card?.category, c.hex)
                        }}
                    />
                </ClickAwayListener>
            </Popper>
            <Menu
                id="simple-menu"
                anchorEl={contentAnchorEl}
                PaperProps={{style: { fontFamily:"Montserrat, sans-serif", color:"#4F4F4F", border:"0.5px solid #c4c4c4"}}}  
                keepMounted
                open={Boolean(contentAnchorEl)}
                onClose={()=>{this.setState({contentAnchorEl:null})}}
            >
                <MenuItem style={{fontSize:"12px"}} component="label" disabled={contentValue}>
                    <ImageOutlined style={{fontSize:"16px", marginRight:"5px"}}/> 
                    Upload Image
                    <input
                        onChange={(event)=>this.onImgUpload(event,cardSelected)}
                        type="file"
                        hidden
                    />
                </MenuItem>
            </Menu>
        </>)
    }

    /**
     * updateColor is to update the category's color
     * @param {*} category - category name
     * @param {*} color - color hex value
     */
    updateColor = (category, color) => {
        const {selectedDeck} = this.state
        this.setState(state =>{
            const categoryColorMap = state.deckList.get(selectedDeck).categoryColorMap
            let colorAnchorEl = state.colorAnchorEl

            colorAnchorEl = null
            categoryColorMap.set(category, color)

            return{
                categoryColorMap, colorAnchorEl
            }
        }, () =>{
            const deckData = {
                deckName: this.state.deckList.get(selectedDeck).name,
                deckDump: Object.fromEntries(this.state.deckList.get(selectedDeck).deckDump),
                categoryColorMap: Object.fromEntries(this.state.deckList.get(selectedDeck).categoryColorMap),
                published: this.state.deckList.get(selectedDeck).published,
                filterSettings:{filterName: "Last Created", filterType: 0},
                listOfCategories: this.state.deckList.get(selectedDeck).listOfCategories,
                listOfLabels: this.state.deckList.get(selectedDeck).listOfLabels
            }
            
            this.mutateDeck(selectedDeck, deckData)
        })
    }
    /**
     * closePopper is to close the color category popper
     */
    closePopper = () =>{
        this.setState({
            colorAnchorEl: null,
            colorMenu: false
        })
    }

    /**
     * handleCategoryChange is to handle the change of card's category in the card editor
     * @param {*} value - category value
     * @param {*} cardId - card key value
     */
    handleCategoryChange = (value, cardId) =>{
        const selectedDeck = this.state.selectedDeck
        let cardCategory = ""
        // value = value?.length===0 ? null : value[0]?.category
        if(value?.length !== 0){
            if(value[0]?.inputValue){
                //inputValue occurs when you click on the "Add ..." button, trimming the value to inputValue
                cardCategory = value[0].inputValue
            }
            else{
                cardCategory = value[0].category
            }
        }

        this.setState(state =>{
            const deck = state.deckList.get(selectedDeck).deckDump
            const listOfCategories = state.deckList.get(selectedDeck).listOfCategories
            const categoryColorMap = state.deckList.get(selectedDeck).categoryColorMap

            deck.get(cardId).category = cardCategory

            if(!listOfCategories.find((categoryObj) =>{return categoryObj.category === cardCategory}) && cardCategory != null && cardCategory !== ""){
                listOfCategories.push({category: cardCategory})
                categoryColorMap.set(cardCategory, "#c4c4c4")
            }

            return{
                listOfCategories,
                categoryColorMap,
                deck
            }
        }, () =>{
            this.props.client.mutate({
                mutation: USER_UPDATE_DECK_CARDS,
                variables: {
                    _id: this.state.uid,
                    deckID: selectedDeck,
                    deckDump: Object.fromEntries(this.state.deckList.get(selectedDeck).deckDump),
                    categoryColorMap: Object.fromEntries(this.state.deckList.get(selectedDeck).categoryColorMap),
                    listOfCategories: this.state.deckList.get(selectedDeck).listOfCategories,
                    listOfLabels: this.state.deckList.get(selectedDeck).listOfLabels
                }
            })
        })
    }

    /**
     * handleLabelChange is to handle the change of card's label in the card editor
     * @param {*} value - category value
     * @param {*} cardId - card key value
     */
    handleLabelChange = (value, cardId) =>{
        const selectedDeck = this.state.selectedDeck
        let labelArr = []

        if(value.length !== 0){
            if(value[value.length - 1]?.inputValue){
                //inputValue occurs when you click on the "Add ..." button, trimming the value to inputValue
                value[value.length - 1].label = value[value.length - 1].inputValue
                delete value[value.length - 1].inputValue
            }

            for(let i=0; i<value.length; i++){
                labelArr.push(value[i].label)
            }
        }
            
        this.setState(state =>{
            const deck = state.deckList.get(selectedDeck).deckDump
            const listOfLabels = state.deckList.get(selectedDeck).listOfLabels

            for(let i  = 0; i < labelArr.length; i++){
                let labelText = labelArr[i]
                if(!listOfLabels.find((labelObj) =>{return labelObj.label === labelText})){
                    listOfLabels.push({label: labelText})
                }
            }
            
            deck.get(cardId).label = labelArr

            return{
                listOfLabels: listOfLabels,
                deck
            }
        }, () =>{
            this.props.client.mutate({
                mutation: USER_UPDATE_DECK_CARDS,
                variables: {
                    _id: this.state.uid,
                    deckID: selectedDeck,
                    deckDump: Object.fromEntries(this.state.deckList.get(selectedDeck).deckDump),
                    categoryColorMap: Object.fromEntries(this.state.deckList.get(selectedDeck).categoryColorMap),
                    listOfCategories: this.state.deckList.get(selectedDeck).listOfCategories,
                    listOfLabels: this.state.deckList.get(selectedDeck).listOfLabels
                }
            })
        })
    }

    /**
     * handleLabelChange is to handle the change of card's label in the card editor
     * @param {*} event - card event target
     * @param {*} cardId - card key value
     */
    handleContentChange = (event, cardId) =>{
        let selectedDeck = this.state.selectedDeck
        let content = event.target.value

        this.timeOut && clearTimeout(this.timeOut)

        this.setState(state =>{
            const deck = state.deckList.get(selectedDeck).deckDump

            deck.get(cardId).content = content
            
            return{
                deck
            }
        }, () =>{
            this.timeOut = setTimeout(() =>{
                this.props.client.mutate({
                    mutation: USER_UPDATE_DECK_CARDS,
                    variables: {
                        _id: this.state.uid,
                        deckID: selectedDeck,
                        deckDump: Object.fromEntries(this.state.deckList.get(selectedDeck).deckDump),
                        categoryColorMap: Object.fromEntries(this.state.deckList.get(selectedDeck).categoryColorMap),
                        listOfCategories: this.state.deckList.get(selectedDeck).listOfCategories,
                        listOfLabels: this.state.deckList.get(selectedDeck).listOfLabels
                    }
                })
            }, 500)
        })
    }

    handlePublish = () =>{
        const {selectedDeck, uid, deckList} = this.state

        const deck = deckList
        
        deck.get(selectedDeck).published = true

        this.setState({
            deckList: deck
        }, ()=>{
            this.props.client.mutate({
                mutation: USER_CREATE_DECK_YAML,
                variables: {
                    _id: uid,
                    name: getUsername(),
                    deckID: selectedDeck,
                    deckName: deckList.get(selectedDeck).name,
                    deckDump: Object.fromEntries(deckList.get(selectedDeck).deckDump),
                    categoryColorMap: Object.fromEntries(deckList.get(selectedDeck).categoryColorMap)
                }
            })

            this.snackbar("Published", `${this.state.deckList.get(selectedDeck).name} is successfully published`)
            
        })
        
    }

    /**
     * displayCardEditorEmpty is to display empty state of card editor on the right panel
     */
    displayCardEditorEmpty = () =>{
        const {classes} = this.props
        return(
            <>
                <img 
                    alt="empty" 
                    style={{width:"200px", marginLeft:"8%", marginTop:"20px"}} 
                    src={require('../../element/cards1.png')}
                />
                <p className={classes.editorEmpty}>
                    Add or click on card<br/>to access this panel
                </p>
            </>
        )
    }

    async getSession(){
        // await session.fetchLocal();
        // const user = session.getUser();
        // //userAUTH(user,this.props.history)   
      
        if(!getUserId()){
            this.props.history.push({
                pathname:`/login`,
                state: {
                    redirect: window.location.pathname,
                }
            })
        }
    }

    /**
     * snackbar is to show the toastify message
     * @param {*} title is the snackbar's title
     * @param {*} message is the snackbar's message
     */
     snackbar = (title, message) =>{
        const {classes} = this.props
        toast(({ closeToast }) => 
                <Grid container direction="row" justify="flex-start" alignItems="center"
                style={{borderLeft:"5px solid #53CAA6", margin:"-10px", padding:"1em"}}>
                    <Grid item xs={2}>
                        <Avatar 
                            style={{float:"left", marginRight:"10px", width:"40px", height:"40px", border:"2px solid white"}} 
                            src={require('../../element/abu.png')}>
                        </Avatar> 
                    </Grid>
                    <Grid item xs={10}>
                        <p style={{color:"#53CAA6", fontWeight:"bold", margin:"0", marginLeft:"5px", fontSize:"12px"}}>
                            {title}
                        </p>
                        <p style={{margin:"0", overflowWrap: "break-word", wordWrap: "break-word", marginLeft:"5px", fontSize:"12px"}}>
                            {message}
                        </p>
                    </Grid>        
                </Grid>,
            {
                className:classes.toast,
                position:"bottom-left",
                autoClose: true,
                progressClassName: classes.toastBar
            })
    }

    /***
     * onImgUpload is to handle img upload
     */
     onImgUpload = (event, cardId) =>{

        //console.log(event.target.files)
        
        if (event.target.files && event.target.files[0]) {

            const file = event.target.files[0];
            const data = new FormData();
            data.append('image',file);

            const req = request.post(`${config.backend.uri}/api/upload`);

            req.on('progress',(event)=>{
                const percent = Math.floor(event.percent);
                if (percent >= 100) {
                  this.setState({ imageCompleted: 100 });
                } else {
                  this.setState({ imageCompleted: percent });
                }
            });

            req.send(data);
            req.end((err, res) => {
                // this.state.draft.set('image', { ...file, ...res.body.data.upload });
                // this.setState({
                //     templateImage: {...file, ...res.body.data.upload}
                // })
      
      
                // this.props.client.mutate({
                //   mutation: UPDATE_JAMBUILDER_IMAGE,
                //   variables:{
                //       _id: this.state.data._id,
                //       imageUrl: this.state.templateImage.url
                //   }
                //   }).then((res)=>{
                //       console.log(res)
                // })
                //console.log(res)

                let selectedDeck = this.state.selectedDeck

                this.setState(state =>{
                    const deck = state.deckList.get(selectedDeck).deckDump

                    deck.get(cardId).imgUrl = res.body.data.upload.url
                    
                    return{
                        deck,
                        contentAnchorEl: null
                    }
                }, () =>{

                    this.props.client.mutate({
                        mutation: USER_UPDATE_DECK_CARDS,
                        variables: {
                            _id: this.state.uid,
                            deckID: selectedDeck,
                            deckDump: Object.fromEntries(this.state.deckList.get(selectedDeck).deckDump),
                            categoryColorMap: Object.fromEntries(this.state.deckList.get(selectedDeck).categoryColorMap),
                            listOfCategories: this.state.deckList.get(selectedDeck).listOfCategories,
                            listOfLabels: this.state.deckList.get(selectedDeck).listOfLabels
                        }
                    })
                })
                
            });

            // let imgTemp = {
            //     imgSrc : URL.createObjectURL(event.target.files[0]), 
            //     imgUploaded: true
            // }
            // this.setState({
            //     imgData: imgTemp
            // })
        }
    }
    /***
     * onImgPaste is to handle paste event in textarea if img is pasted 
     */
    onImgPaste = (event, cardId) =>{
        // use event.originalEvent.clipboard for newer chrome versions
        var items = (event.clipboardData  || event.originalEvent.clipboardData).items;
        // find pasted image among pasted items
        var blob = null;
        for (var i = 0; i < items.length; i++) {
            if (items[i].type.indexOf("image") === 0) {
            blob = items[i].getAsFile();
            }
        }

        // load image if there is a pasted image & if textarea is null
        if (blob !== null) {
            const data = new FormData();
            data.append('image', blob);

            const req = request.post(`${config.backend.uri}/api/upload`);

            req.on('progress',(event)=>{
                const percent = Math.floor(event.percent);
                if (percent >= 100) {
                  this.setState({ imageCompleted: 100 });
                } else {
                  this.setState({ imageCompleted: percent });
                }
            });

            req.send(data);
            req.end((err, res) => {
                let selectedDeck = this.state.selectedDeck

                this.setState(state =>{
                    const deck = state.deckList.get(selectedDeck).deckDump

                    deck.get(cardId).imgUrl = res.body.data.upload.url
                    
                    return{
                        deck,
                        contentAnchorEl: null
                    }
                }, () =>{

                    this.props.client.mutate({
                        mutation: USER_UPDATE_DECK_CARDS,
                        variables: {
                            _id: this.state.uid,
                            deckID: selectedDeck,
                            deckDump: Object.fromEntries(this.state.deckList.get(selectedDeck).deckDump),
                            categoryColorMap: Object.fromEntries(this.state.deckList.get(selectedDeck).categoryColorMap),
                            listOfCategories: this.state.deckList.get(selectedDeck).listOfCategories,
                            listOfLabels: this.state.deckList.get(selectedDeck).listOfLabels
                        }
                    })
                })
                
            });

        }else{
            alert("No image detected")
        }

    }
    /**
     * removeImg is to remove the image on card
     */
    removeImg = (cardId) =>{
        const selectedDeck = this.state.selectedDeck

        this.setState(state =>{
            const deck = state.deckList.get(selectedDeck).deckDump

            deck.get(cardId).imgUrl = null
            
            return{
                deck
            }
        }, () =>{

            this.props.client.mutate({
                mutation: USER_UPDATE_DECK_CARDS,
                variables: {
                    _id: this.state.uid,
                    deckID: selectedDeck,
                    deckDump: Object.fromEntries(this.state.deckList.get(selectedDeck).deckDump),
                    categoryColorMap: Object.fromEntries(this.state.deckList.get(selectedDeck).categoryColorMap),
                    listOfCategories: this.state.deckList.get(selectedDeck).listOfCategories,
                    listOfLabels: this.state.deckList.get(selectedDeck).listOfLabels
                }
            })
        })
    }

    onDrop = (files, cardId) => {

        const file = files.shift();
        const data = new FormData();
        data.append('image', file);
        const req = request.post(`${config.backend.uri}/api/upload`);

        req.on('progress',(event)=>{
            const percent = Math.floor(event.percent);
            if (percent >= 100) {
              this.setState({ imageCompleted: 100 });
            } else {
              this.setState({ imageCompleted: percent });
            }
        });

        req.send(data);
        req.end((err, res) => {

            let selectedDeck = this.state.selectedDeck

            this.setState(state =>{
                const deck = state.deckList.get(selectedDeck).deckDump

                deck.get(cardId).imgUrl = res.body.data.upload.url
                
                return{
                    deck,
                    contentAnchorEl: null
                }
            }, () =>{

                this.props.client.mutate({
                    mutation: USER_UPDATE_DECK_CARDS,
                    variables: {
                        _id: this.state.uid,
                        deckID: selectedDeck,
                        deckDump: Object.fromEntries(this.state.deckList.get(selectedDeck).deckDump),
                        categoryColorMap: Object.fromEntries(this.state.deckList.get(selectedDeck).categoryColorMap),
                        listOfCategories: this.state.deckList.get(selectedDeck).listOfCategories,
                        listOfLabels: this.state.deckList.get(selectedDeck).listOfLabels
                    }
                })
            })

        });

    };
}

export default withApollo(withStyles(styles)(DeckBuilder))