import { createMuiTheme } from '@material-ui/core/styles'

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#FFFFFF'
        },
        secondary: {
            main: '#C4C4C4',
            elements: '#B7E8CB',
            action: '#219653'

        },
    },

    status: {
        danger: 'red',
    },

    typography: {
        fontFamily: [
            'Arial',   
        ].join(','),
        fontSize: 12,
    },
    overrides: {
        MuiCssBaseline: {
            '@global': {
                '@font-face': ['open-sans'],
            },
        },
        MuiInput:{
            underline: {
                "&&&&:before":{
                    borderBottom: "none"
                },
                "&&&&:after":{
                    borderBottom: "none"
                },
            }
        },
        MuiAvatar:{
            root:{
                fontSize: "0.8rem",
                width: "35px",
                height: "35PX",
            }
        },
        MuiInputBase:{
            input:{
                fontSize: "0.7rem",
                
            }
        },
        MuiFormLabel:{
            root:{
                fontSize: "0.7rem",
            }
        },
        MuiInputLabel:{
            outlined:{
                //transform: "translate(12px, -2px) scale(0.8) !important"
            },
            shrink:{
                //transform: "translate(12px, -2px) scale(0.8);"
            },
        },
        MuiButton: {
            root:{
                
            }
        }
    }
});

export default theme;