import React from 'react';
import ContentEditable, { ContentEditableEvent } from 'react-contenteditable';
import debounce from 'lodash/debounce';
import {
  Grid,
  Card as MuiCard,
  IconButton,
  Avatar,
} from '@material-ui/core';
import {
  MoreVert,
  Create,
  CloseRounded,
  Room,
  Eco,
} from '@material-ui/icons';

import { ICardBackProps } from './card-back.interface';
import { cardConfig } from '../card.config';
import config from '../../../config/backend';
import EE from '../../../api/eventemitter';
import socket from '../../../api/socket';
import { LabelChip } from '../../label-chip/label-chip';
import { CharacterCounter } from '../../character-counter/character-counter';

export const CardBack = (props: ICardBackProps): React.ReactElement => {
  const {
    id,
    category,
    categoryColor,
    content,
    setContent,
    edited = false,
    modTime,
    pinned = false,
    imgUrl,
    stack = false,
    stackIndex,
    flippedStack = false,
    stringText,
    contentInputRef,
    manageChecker = false,
    username,
    labels,
    readonly = false,
    setReadonly,
    openContextMenu,
    updateCardContent,
    receiveTargetTextfield,
    classes,
  } = props;

  const debounceOnChange = debounce(() => {
    updateCardContent(stackIndex, content);
  }, 300);

  const handleContentInputChange = (event: ContentEditableEvent) => {
    const nextContent = event.target.value.substring(0, cardConfig.maxCharCount);
    setContent(nextContent);

    if (stack) {
      debounceOnChange();
      socket.emit('Object Live Typing', { objId: id, content: nextContent });
    }
  };

  const handleBlurContentInput = () => {
    socket.emit('Game Object Parse Links', { selectedIDs: [id] });
    setReadonly(true);
  };

  return (
    <MuiCard
      style={{
        background: '#fff',
        border: manageChecker ? 'thick solid red' : undefined
      }}
      className={flippedStack ? classes.cardContentFlip : classes.cardContent}
    >
      <Grid container direction="row" justify="center" alignItems="center">
        <Grid item xs={3}>
          <Avatar style={{ background: categoryColor ? categoryColor : '#c4c4c4' }}>
            {category !== 'Blank' ?
              <Eco style={{ color: '#fff' }} />
              :
              <Create style={{ color: '#fff' }} />}
          </Avatar>
        </Grid>
        <Grid item xs={6} style={{ color: '#4f4f4f', fontSize: 10 }}>
          <p className={classes.username}>{username}</p>
          {edited ? modTime + " • (Edited)" : modTime}
          {pinned ? <Room className={classes.pin} /> : null}
        </Grid>
        <Grid item xs={3}>
          <IconButton className={classes.iconRight} onClick={openContextMenu}>
            <MoreVert style={{ color: '#c4c4c4' }} />
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          <p
            style={{
              margin: '10px 0 0 0',
              color: !categoryColor ? '#c4c4c4' : categoryColor,
              fontSize: '14px',
              fontWeight: 'bold',
            }}
          >
            {category == null ? 'No category' : category}
          </p>
        </Grid>
      </Grid>
      <Grid container className={classes.contentWrapper}>
        {
          imgUrl ?
            /** If image is uploaded in card */
            <div className={classes.imgHolder}>
              <IconButton className={classes.imgDelete} style={{ outline: 'none' }}>
                <CloseRounded style={{ fontSize: '12px' }} />
              </IconButton>
              <img
                style={{ width: '100%' }}
                alt="img"
                src={`${config.backend.uri}/static/uploads/${imgUrl}`}
              />
            </div>
            :
            <ContentEditable
              innerRef={contentInputRef}
              id="contentEditable"
              className={classes.content /*+ " cancel"*/}
              style={{
                height: '100%',
                overflowY: 'auto',
                width: '190px',
                zIndex: 1,
              }}
              html={content}
              onKeyDown={(e) => {
                if (e.keyCode === 13 && !e.shiftKey) {
                  // prevent shift + enter new lin
                  e.preventDefault();
                }
              }}
              onClick={(evt) => {
                evt.persist()
                if (evt?.target && category === 'Blank' && stack) {
                  receiveTargetTextfield(evt.target)
                  EE.emit('Canvas Defocus')
                }
                if ((evt.target as HTMLAnchorElement).tagName.toLowerCase() === 'a') {
                  const newWindow = window.open((evt.target as HTMLAnchorElement).href, '_blank', 'noopener noreferrer')
                  if (newWindow) { newWindow.opener = null }
                }
              }}
              onChange={handleContentInputChange}
              onBlur={handleBlurContentInput}
              // onPaste={(event)=>onImgPaste(event)}
              disabled={
                // (submitted && category === "Blank")||
                // category !== "Blank"
                Boolean(imgUrl) || readonly
              }
            />
        }
      </Grid>
      <Grid container justify="center" alignItems="center" style={{ width: '100%' }}>
        <Grid item xs={12}>
          <LabelChip labels={labels} />
          <CharacterCounter
            count={stringText?.length ?? 0}
            maxCount={cardConfig.maxCharCount}
            imgUrl={imgUrl}
          />
        </Grid>
      </Grid>
    </MuiCard>
  );
};
