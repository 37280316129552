import React from 'react'
import {SvgIcon} from '@material-ui/core'
const Cursor = ({cursorData}) =>{
    return(
        <div
            style={{
                top: (cursorData.y),
                left: cursorData.x,
                position: "absolute",
                pointerEvents: "none",
                zIndex:"10"
            }}
        >
            <SvgIcon>
                <svg width="30px" height="30px" viewBox="0 0 24 29" fill="none" stroke="white" xmlns="http://www.w3.org/2000/svg" style={{filter:"drop-shadow(0px 2px 2px #c4c4c4)"}}>
                    <path d="M0 16.015V0L11.591 11.619H4.55L4.399 11.743L0 16.015Z" fill="white" />
                    <path d="M1 2.40698V13.595L3.969 10.729L4.129 10.59H9.165L1 2.40698Z" fill={cursorData.usercolor}/>
                </svg>
            </SvgIcon>
            <div
                style={{
                    backgroundColor:cursorData.usercolor,
                    color:"white",
                    fontWeight:"bold",
                    fontSize:"12px",
                    fontFamily:"Arial",
                    padding:"0.2rem 0.5rem",
                    borderRadius:"3px",
                    boxShadow: '0px 2px 4px #c4c4c4',
                    marginTop:"-0.5rem",
                    marginLeft:"1rem"
                }}
            >
                {cursorData.username}
            </div>
        </div>
    )
}

export default Cursor