import gql from 'graphql-tag';

export const GAME_OBJECT_POSITION = gql`
  mutation GAME_OBJECT_POSITION(
    $_id: MongoID
    $objId: String
    $x: Float
    $y: Float
  ) {
    gameUpdateObjectPosition(_id: $_id, objId: $objId, x: $x, y: $y) {
      _id
      data
    }
  }
`;
