import React from 'react';
import {Input, InputLabel, Select, MenuItem, Divider, Grid, Tooltip, Button, 
        RadioGroup, Radio, FormControlLabel, withStyles, Chip, TextField, 
        Checkbox, IconButton, ListSubheader} from '@material-ui/core';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import {Add, 
        MoreVert,
        CheckBoxRounded,
        CheckBoxOutlineBlankRounded,
        Remove,
        CloseRounded, 
        AccountTreeOutlined, 
        Timeline, 
        TransferWithinAStation, 
        PersonOutline, 
        ClearAll, 
        VpnKeyOutlined, 
        FlashOnRounded, 
        AddBoxOutlined,
        OpenWithOutlined,
        RepeatOne,
        Loop,
        Cached,
        Person,
        ViewColumn,
        RoomOutlined,
        DeleteOutlineOutlined,
        TouchApp, 
        AllInbox,
        HowToVote,
        PanTool,
        WhereToVote,
        LayersClear,
        Layers,
        AccessTime,
        CallSplit,
        StarBorder,
        Star,
        TrendingFlatOutlined,
        Notifications,
        Timer,
        Email} from '@material-ui/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMousePointer } from '@fortawesome/free-solid-svg-icons'
import {styles} from './assets/jamNodeSettingsUI'
import _ from 'lodash'

class NodeSettings extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            selectedCategory: []
        }
    }

    render(){
        const {classes} = this.props
        const node = this.props.node

        return(
            <>
                <Grid container style={{padding:"20px 20px 10px 20px",}}>
                    <Grid item xs={11}>
                        <p className={classes.title}>
                            {this.typeIcon(node)} {node.type}
                        </p>
                    </Grid>
                    <Grid item xs={1}>
                        <IconButton onClick={this.props.closeNodeSettings} className={classes.closeButton}>
                            <CloseRounded/>
                        </IconButton>
                    </Grid>
                    <Grid item xs={12}>
                        <p className={classes.subtitle} style={{lineHeight: "1"}}>
                            {this.typeDescription(node)}
                        </p>
                    </Grid>
                </Grid>
                <div className={classes.formWrapper}>
                    {/* Basic settings */}
                    {this.basicSettings()}

                    {/* Where events starts  */}
                    {node.events!= null && (node.type==="Action" || node.type ==="Steps") &&
                        <div className={classes.eventField}>
                            <Grid container justify="center" 
                                style={{
                                        marginBottom:"20px", 
                                        opacity: this.getParentName(node) === "Single" && node.endStep !== "Go To Event" ? "0.5" : "1",
                                    }} 
                                alignItems="center"> 
                                <Grid item xs={6}>
                                    <p style={{margin:"0", fontWeight:"bold", color:"#4f4f4f", fontSize:"14px"}}>
                                        <AccessTime style={{marginTop:"-2px", marginRight:"5px", color:"#828282"}}/> Event
                                    </p> 
                                </Grid>
                                <Grid item xs={6}>
                                    <Button 
                                        className={classes.addEventLink} 
                                        startIcon={<Add className={classes.addEvent}/>} 
                                        onClick={(e)=>this.props.addEvent(0)}
                                        style={{background: this.getParentName(node) === "Single" && node.endStep !== "Go To Event" && "#C4C4C4"}}
                                    >
                                        Add Event
                                    </Button>
                                </Grid>
                                <Grid item xs={12}>
                                    <p style={{margin:"0", color:"#828282", fontSize:"12px", fontWeight:"normal"}}>
                                        The events that take place once the action has been done by the player
                                    </p>
                                </Grid>
                            </Grid>
                            {this.events(node.events[0].id)}
                        </div>
                    }
                    
                    
                </div>
                
            </>
        )
    }

    typeIcon = (node) =>{
        const {classes} = this.props
        var icon 
        if(node.type === "Phase"){
            icon = (<AccountTreeOutlined className={classes.oddIcon}/>)
        }else if(node.type === "Turn Structure"){
            icon = (<Timeline className={classes.evenIcon}/>)
        }else if(node.type === "Turn Type"){
            icon = (<TransferWithinAStation className={classes.oddIcon}/>)
        }else if(node.type === "Role"){
            icon = (<PersonOutline className={classes.evenIcon}/>)
        }else if(node.type === "Steps"){
            icon = (<ClearAll className={classes.oddIcon}/>)
        }else if(node.type === "Key Action"){
            icon = (<VpnKeyOutlined className={classes.evenIcon}/>)
        }else if(node.type === "Action"){
            icon = (<FlashOnRounded className={classes.oddIcon}/>)
        }

        return icon
    }

    typeDescription = (node) =>{
        var description = ""
        if (node.type==="Phase"){
            description = "A phase is completed when all the branches inside are completed"
        }
        else if(node.type==="Turn Structure"){
            description = "A branch that groups how a part of a phase is played and passed"
        }
        else if(node.type==="Turn Type"){
            description = "Decides if this branch of permission is given to the player who is In Turn (Active) or Out of Turn (Passive)"
        }
        else if(node.type==="Role"){
            description = "The role this branch of permission is applied to"
        }
        else if(node.type==="Steps"){
            description = "The order that a set of permissions are given to a player"
        }
        else if(node.type==="Key Action"){
            description = "Decides if the actions under this branch is repeatable or not"
        }
        else if(node.type==="Action"){
            description = "The set of permissions given to the player that holds the role of this branch during jam session"
        }

        return description
    }

    //getDeckCategories - put the deck categories into a specific structure for autocomplete
    getDeckCategories = () =>{
        let categories = []

        categories.push({category: "Blank"})
        this.props.deckCategories.map((n)=>
            categories.push({category: n})
        )
        
        return categories
    }

    //basicSettings - call basic settings
    basicSettings = () =>{
        const {classes} = this.props
        const node = this.props.node
        const deckCategories = this.props.deckCategories
        const categories = this.getDeckCategories()

        var settings 
        if(node.type==="Phase"){
            settings = (
            <>
                <div className={classes.formField}>
                    <Input value={node.name} name="name" placeholder="Example: Phase 1" onChange={this.props.updateSettings()} className={classes.fieldText} disableUnderline/>
                </div>
                {/* <div className={classes.formField}>
                    <InputLabel className={classes.fieldName}>Repeat</InputLabel>
                    <RadioGroup name="repeat" value={node.repeat} onChange={this.props.updateSettings()}>
                        <FormControlLabel value="Forever" control={<Radio />} label="Forever"/>
                        <FormControlLabel value="Number" control={<Radio />} label={<Input placeholder="0" name="repeatNum" value={node.repeatNum} onChange={this.props.updateSettings()} className={classes.fieldText} style={{width:"3rem"}} disableUnderline disabled={node.repeat!=="Number" && true}/>} />  
                    </RadioGroup>
                </div> */}
            </>)
        }
        else if(node.type==="Turn Structure"){
            settings = (
            <>
                <div className={classes.formField}>
                    <Input value={node.name} name="name" placeholder="Example: Turn Structure 1" onChange={this.props.updateSettings()} className={classes.fieldText} disableUnderline/>
                </div>
                {/* <div className={classes.formField}>
                    <InputLabel className={classes.fieldName}>Repeat</InputLabel>
                    <RadioGroup name="repeat" value={node.repeat} onChange={this.props.updateSettings()}>
                        <FormControlLabel value="Forever" control={<Radio />} label="Forever" />
                        <FormControlLabel value="Number" control={<Radio />} label={<Input placeholder="0" name="repeatNum" value={node.repeatNum} onChange={this.props.updateSettings()} className={classes.fieldText} style={{width:"3rem"}} disableUnderline disabled={node.repeat!=="Number" && true}/>}/>  
                    </RadioGroup>
                </div>
                <div className={classes.formField}>
                    <InputLabel className={classes.fieldName}>Targeted Role</InputLabel>
                    <Select name="targetedRole" value={node.targetedRole} className={classes.fieldSelect} style={{width:"100%"}} onChange={this.props.updateSettings()} disableUnderline>
                        <MenuItem value="Choose role">Choose role</MenuItem>
                        <MenuItem value="Executive">Executive</MenuItem>
                        <MenuItem value="Storyteller">Storyteller</MenuItem>
                    </Select>
                </div> */}
            </>)
        }
        else if(node.type==="Turn Type"){
            
            settings = (
            <>
                <div className={classes.formField}>
                    <Select name="name" value={node.name} className={classes.fieldSelect} style={{width:"100%"}} onChange={this.props.updateSettings()} disableUnderline>
                        <MenuItem value="In Turn">
                            <Grid container justify="center" alignItems="center">
                                <Grid item xs={3}>
                                    <span alt="icon" className={classes.selectionIcon}>
                                        <Star className={classes.selectionIconSize}/>
                                    </span>
                                </Grid>
                                <Grid item xs={9}>
                                    <p className={classes.selectionTitle}>In Turn</p>
                                    <p className={classes.selectionSubtitle}>The active player</p>
                                </Grid>
                            </Grid>
                        </MenuItem>
                        <MenuItem value="Out Turn">
                            <Grid container justify="center" alignItems="center">
                                <Grid item xs={3}>
                                    <span alt="icon" className={classes.selectionIcon}>
                                        <StarBorder className={classes.selectionIconSize}/>
                                    </span>
                                </Grid>
                                <Grid item xs={9}>
                                    <p className={classes.selectionTitle}>Out of Turn</p>
                                    <p className={classes.selectionSubtitle}>The inactive player</p>
                                </Grid>
                            </Grid>
                        </MenuItem>
                    </Select>
                </div>
               {/* <div className={classes.eventField}>
                    <InputLabel className={classes.fieldName}>Instruction <Add className={classes.addEvent} onClick={(e)=>this.props.addMessage()}/></InputLabel>
                    {
                        node.instruction.map((obj, index)=>{
                            return(
                                <>
                                    {index>0 ? <Divider/> : null}
                                    <InputLabel className={classes.fieldName} style={{marginTop:"20px"}}>Role</InputLabel>
                                    <Select name="role" value={obj.role} className={classes.fieldSelect} style={{width:"100%"}} onChange={this.props.handleMessage(index)} disableUnderline>
                                        <MenuItem value="Choose role">Choose role</MenuItem>
                                        <MenuItem value="Executive">Executive</MenuItem>
                                        <MenuItem value="Storyteller">Storyteller</MenuItem>
                                    </Select>
                                    <div className={classes.formField}>
                                        <InputLabel className={classes.fieldName} style={{marginTop:"20px"}}>Message</InputLabel>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={obj.messageList[0].check}
                                                    onChange={this.props.handleMessage(index)}
                                                    name={obj.messageList[0].type}
                                                />
                                            }
                                            label={obj.messageList[0].type}
                                        />
                                        <Input name="message1" value={obj.messageList[0].message} placeholder="Type your message here" multiline rows={2} rowsMax={4} className={classes.fieldText} style={{marginLeft:"30px", width:"85%"}} disableUnderline/>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={obj.messageList[1].check}
                                                    onChange={this.props.handleMessage(index)}
                                                    name={obj.messageList[1].type}
                                                />
                                            }
                                            label={obj.messageList[1].type}
                                        />
                                        <Input name="message2" value={obj.messageList[0].message} placeholder="Type your message here" multiline rows={2} rowsMax={4} className={classes.fieldText} style={{marginLeft:"30px", width:"85%"}} disableUnderline/>
                                    </div>
                                </>
                            )
                        })
                    }
                    
                </div> */}
            </>)
        }
        else if(node.type==="Role"){
            settings = (
            <>
               <div className={classes.formField}>
                    <Select name="name" value={node.name} className={classes.fieldSelect} style={{width:"100%"}} onChange={this.props.updateSettings()} disableUnderline>
                        {/* <MenuItem value="Executive">Executive</MenuItem> */}
                        <MenuItem value="Storyteller">
                            <Grid container justify="center" alignItems="center">
                                <Grid item xs={3}>
                                    <span alt="icon" className={classes.selectionIcon}>
                                        <Person className={classes.selectionIconSize}/>
                                    </span>
                                </Grid>
                                <Grid item xs={9}>
                                    <p className={classes.selectionTitle}>Storyteller</p>
                                    <p className={classes.selectionSubtitle}>A role for this branch</p>
                                </Grid>
                            </Grid>
                        </MenuItem>
                    </Select>
                </div>
            </>)
        }
        else if(node.type==="Steps"){
            settings = (
            <>
               <div className={classes.formField}>
                    <Input name="name" value={node.name} placeholder="Example: Step 1" onChange={this.props.updateSettings()} className={classes.fieldText} disableUnderline/>
                </div>
            </>)
        }
        else if(node.type==="Key Action"){
            settings = (
            <>
               <div className={classes.formField}>
                    <Select name="name" value={node.name} className={classes.fieldSelect} style={{width:"100%"}} onChange={this.props.updateSettings()} disableUnderline>
                        <MenuItem value="Single">
                            <Grid container justify="center" alignItems="center">
                                <Grid item xs={3}>
                                    <span alt="icon" className={classes.selectionIcon}>
                                        <RepeatOne className={classes.selectionIconSize}/>
                                    </span>
                                </Grid>
                                <Grid item xs={9}>
                                    <p className={classes.selectionTitle}>Single</p>
                                    <p className={classes.selectionSubtitle}>Can be done once</p>
                                </Grid>
                            </Grid>
                        </MenuItem>
                        <MenuItem value="Unlimited">
                            <Grid container justify="center" alignItems="center">
                                <Grid item xs={3}>
                                    <span alt="icon" className={classes.selectionIcon}>
                                        <Loop className={classes.selectionIconSize}/>
                                    </span>
                                </Grid>
                                <Grid item xs={9}>
                                    <p className={classes.selectionTitle}>Unlimited</p>
                                    <p className={classes.selectionSubtitle}>Can be repeated unlimited times</p>
                                </Grid>
                            </Grid>
                        </MenuItem>
                    </Select>
                </div>
            </>)
        }
        else{ //Action
            settings = (
            <>
               <div className={classes.formField}>
                    <Select name="name" value={node.name} className={classes.fieldSelect} style={{width:"100%"}} onChange={this.props.updateSettings()} disableUnderline>
                        <ListSubheader style={{pointerEvents: "none"}}><strong>Card Actions</strong></ListSubheader>
                        <MenuItem value="Add Card">
                            <Grid container justify="center" alignItems="center">
                                <Grid item xs={3}>
                                    <span alt="icon" className={classes.selectionIcon}>
                                        <AddBoxOutlined className={classes.selectionIconSize}/>
                                    </span>
                                </Grid>
                                <Grid item xs={9}>
                                    <p className={classes.selectionTitle}>Add</p>
                                    <p className={classes.selectionSubtitle}>Add cards to canvas and<br/>blank cards to hand</p>
                                </Grid>
                            </Grid>
                        </MenuItem>
                        <MenuItem value="Retrieve Card">
                            <Grid container justify="center" alignItems="center">
                                <Grid item xs={3}>
                                    <span alt="icon" className={classes.selectionIcon}>
                                        <TouchApp className={classes.selectionIconSize}/>
                                    </span>
                                </Grid>
                                <Grid item xs={9}>
                                    <p className={classes.selectionTitle}>Retrieve</p>
                                    <p className={classes.selectionSubtitle}>Take cards from canvas<br/>to hand</p>
                                </Grid>
                            </Grid>
                        </MenuItem>
                        <MenuItem value="Delete Card">
                            <Grid container justify="center" alignItems="center">
                                <Grid item xs={3}>
                                    <span alt="icon" className={classes.selectionIcon}>
                                        <DeleteOutlineOutlined className={classes.selectionIconSize}/>
                                    </span>
                                </Grid>
                                <Grid item xs={9}>
                                    <p className={classes.selectionTitle}>Delete</p>
                                    <p className={classes.selectionSubtitle}>Remove cards</p>
                                </Grid>
                            </Grid>
                        </MenuItem>
                        <MenuItem value="Drag Card">
                            <Grid container justify="center" alignItems="center">
                                <Grid item xs={3}>
                                    <span alt="icon" className={classes.selectionIcon}>
                                        <OpenWithOutlined className={classes.selectionIconSize}/>
                                    </span>
                                </Grid>
                                <Grid item xs={9}>
                                    <p className={classes.selectionTitle}>Drag</p>
                                    <p className={classes.selectionSubtitle}>Move cards on the canvas</p>
                                </Grid>
                            </Grid>
                        </MenuItem>
                        <MenuItem value="Flip Card">
                            <Grid container justify="center" alignItems="center">
                                <Grid item xs={3}>
                                    <span alt="icon" className={classes.selectionIcon}>
                                        <Cached className={classes.selectionIconSize}/>
                                    </span>
                                </Grid>
                                <Grid item xs={9}>
                                    <p className={classes.selectionTitle}>Flip</p>
                                    <p className={classes.selectionSubtitle}>Flip cards in an area</p>
                                </Grid>
                            </Grid>
                        </MenuItem>
                        <MenuItem value="Pair Card">
                            <Grid container justify="center" alignItems="center">
                                <Grid item xs={3}>
                                    <span alt="icon" className={classes.selectionIcon}>
                                        <ViewColumn className={classes.selectionIconSize}/>
                                    </span>
                                </Grid>
                                <Grid item xs={9}>
                                    <p className={classes.selectionTitle}>Pair</p>
                                    <p className={classes.selectionSubtitle}>Pair cards on the canvas</p>
                                </Grid>
                            </Grid>
                        </MenuItem>   
                        {/* <MenuItem value="Pin Card">
                            <Grid container justify="center" alignItems="center">
                                <Grid item xs={3}>
                                    <span alt="icon" className={classes.selectionIcon}>
                                        <RoomOutlined className={classes.selectionIconSize}/>
                                    </span>
                                </Grid>
                                <Grid item xs={9}>
                                    <p className={classes.selectionTitle}>Pin</p>
                                    <p className={classes.selectionSubtitle}>Pin cards to archive them</p>
                                </Grid>
                            </Grid>
                        </MenuItem> */}
                        
                        <MenuItem value="Stack Card">
                            <Grid container justify="center" alignItems="center">
                                <Grid item xs={3}>
                                    <span alt="icon" className={classes.selectionIcon}>
                                        <AllInbox className={classes.selectionIconSize}/>
                                    </span>
                                </Grid>
                                <Grid item xs={9}>
                                    <p className={classes.selectionTitle}>Stack</p>
                                    <p className={classes.selectionSubtitle}>Stack cards on the canvas</p>
                                </Grid>
                            </Grid>
                        </MenuItem>
                        <ListSubheader style={{pointerEvents: "none"}}><strong>Player Actions</strong></ListSubheader>
                        <MenuItem value="Pass Turn">
                            <Grid container justify="center" alignItems="center">
                                <Grid item xs={3}>
                                    <span alt="icon" className={classes.selectionIcon}>
                                        <TrendingFlatOutlined className={classes.selectionIconSize}/>
                                    </span>
                                </Grid>
                                <Grid item xs={9}>
                                    <p className={classes.selectionTitle}>Pass</p>
                                    <p className={classes.selectionSubtitle}>Clicking on the pass button</p>
                                </Grid>
                            </Grid>
                        </MenuItem>          
                    </Select>
                </div>

                
                {
                    //If action === Add Card
                    node.name === "Add Card" ? 
                        <div className={classes.formField}>
                            <InputLabel className={classes.fieldName}>
                                Placement
                                <p className={classes.subtitle}>
                                    Where can the card be dragged from and into?
                                </p>
                            </InputLabel>
                            <RadioGroup name="placement" defaultValue={"Canvas"} value={node["Add Card"].placement} onChange={(e) => {this.props.updateActionSettings(e, "Add Card", "placement")}}>
                                <FormControlLabel value="Both" control={<Radio />} label={<p className={classes.fieldRadio}>Both</p>} />  
                                <FormControlLabel value="Canvas" control={<Radio />} label={<p className={classes.fieldRadio}>Hand to Canvas</p>}/>
                                <FormControlLabel value="In Hand" control={<Radio />} label={<p className={classes.fieldRadio}>Blank Deck to Hand</p>}/>
                                
                            </RadioGroup>

                            {/* <RadioGroup name="cardType" defaultValue = "Any" value={node["Add Card"].cardType} onChange={this.props.updateActionSettings("Add Card")}>
                                <FormControlLabel value="Any" control={<Radio />} label={<p className={classes.fieldRadio}>Any</p>}/>
                                <FormControlLabel value="Specific" control={<Radio />} label={
                                    <Select 
                                        name="card"
                                        defaultValue = " " 
                                        value={node["Add Card"].card} 
                                        className={classes.fieldSelect} 
                                        style={{width:"100%"}} 
                                        disabled={node["Add Card"].cardType !== "Specific"} 
                                        onChange={this.props.updateActionSettings("Add Card")} 
                                        disableUnderline
                                    >
                                        <MenuItem value="Blank">Blank</MenuItem>
                                        {deckCategories && deckCategories.map((n)=>
                                            <MenuItem value={n}>{n}</MenuItem>
                                        )}
                                    </Select>
                                } />  
                            </RadioGroup> */}
                        </div>
                        
                    :
                        node.name === "Pull Card" &&
                        <div className={classes.formField}>
                            <InputLabel className={classes.fieldName}>Pass Type</InputLabel>
                            <RadioGroup name="pull card" onChange={(e)=>{this.props.updateActionSettings(e, "Pull Card", "pull card")}}/>
                        </div>
                }
                {/* {console.log(node[node.name]?.card)} */}
                {//If node is not pass turn, show target category settings
                    node.name !== "Pass Turn" && 
                    <div className={classes.formField}>
                        <InputLabel className={classes.fieldName} style={{marginBottom:"10px"}}>
                            Target by category
                            <p className={classes.subtitle}>
                                If no category is selected, this action can be inflicted onto any card category.
                            </p>
                        </InputLabel>
                    
                        <Autocomplete
                            multiple
                            value={node.card}
                            getOptionSelected={(option, value) => option.category === value.category}
                            options={categories}
                            disableCloseOnSelect
                            getOptionLabel={(option) => option.category}
                            renderOption={(option, { selected }) => (
                                <React.Fragment>
                                    {option.category}
                                </React.Fragment>
                            )}
                            style={{width: "100%"}}
                            name="card"

                            onChange={(event, value) =>{
                                this.props.updateActionSettings(event, node.name, "card",  value)
                            }}

                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    
                                    style={{background:"#FAF7EA", padding:"10px", fontSize:"12px", borderRadius:"5px", border:"1px solid #D1D1D1"}}
                                    InputProps={{...params.InputProps, disableUnderline: true, style:{fontSize:"12px"}}}
                                    variant="standard" placeholder="Categories"
                                />    
                            )}
                            />
                    </div>
                }

                {this.getParentName(node) === "Single" ?
                    <div className={classes.formField}>
                        <InputLabel htmlFor="endStep" className={classes.fieldName}>On Action Complete</InputLabel>
                        <RadioGroup name="endStep" defaultValue={this.getParentName(node) === "Single" ? "Shift To" : ""} value={node.endStep} onChange={this.props.updateSettings()}>
                            <FormControlLabel value="Shift To" control={<Radio />} label={
                                <>
                                    Shift to
                                    <Select name="shiftTo" value={node.shiftTo} className={classes.fieldSelect} style={{width:"100%"}} onChange={this.props.updateSettings()} disableUnderline>
                                        <ListSubheader style={{pointerEvents: "none"}}><strong>Step</strong></ListSubheader>
                                        <MenuItem value="Next Step">Next Step</MenuItem>
                                        <ListSubheader style={{pointerEvents: "none"}}><strong>Players</strong></ListSubheader>
                                        <MenuItem value="Chosen Player">Chosen Player</MenuItem>
                                        <MenuItem value="Next Player">Next Player</MenuItem>
                                        <ListSubheader style={{pointerEvents: "none"}}><strong>Turn Structure</strong></ListSubheader>
                                        <MenuItem value="Next Turn Structure">Next Turn Structure</MenuItem>     
                                        <ListSubheader style={{pointerEvents: "none"}}><strong>Phase</strong></ListSubheader>
                                        <MenuItem value="Next Phase">Next Phase</MenuItem>          
                                    </Select>
                                </>
                            }/>
                            <FormControlLabel value="Go To Event" control={<Radio />} label="Go to Event" />  
                        </RadioGroup>                   
                    </div> 
                    : null
                }
            </>)
        }
        return settings
    }

    handleCategoryChange = (value) =>{
        this.setState({
            selectedCategory: value
        })
    }
    getParentName(node){
        var name = null
        for(var i=0; i<this.props.graph.length; i++){
            if(node.parentNode === this.props.graph[i].id){
                name = this.props.graph[i].name
            }
        }
        return name
    }

    renderValue = (node) => {
        let value = null

        node.name === "Add Card" ? value = "Add " + node["Add Card"].card + " Card" : value = node

        return value
    }

    //events - start of event node iteration 
    events = (id) =>{
        let event = this.props.node.events
        var array = []

        //since events will always start at 0 index
        array.push(this.getEvent(event, 0, id))
        
        //begin iteration
        array = this.eventsUtil(0, event, array)

        return array
    }

    //eventsUtil - to iterate which event node comes first
    //      Get parent 
    //      If has success node, explore success node
    //      If has fail node, explore fail node
    //      If has next id node, explore next id node 
    eventsUtil = (id, event, array) =>{
        let currentEventName = null, success = null, fail = null, nextId = null, sIndex = null, fIndex = null, nIndex = null
        const {classes} = this.props
        const node = this.props.node 
    
        //get list of success, fail and next id of event
        success = event[id].success
        fail = event[id].fail
        nextId = event[id].nextId

            
        //get the index of success, fail and next id
        for(let j=0; j<event.length; j++){
            if(event[j].id===success){
                sIndex = j
            }
            if(event[j].id===fail){
                fIndex = j
            }
            if(event[j].id===nextId){ 
                nIndex = j
            }
        }

        if(success){          
            // console.log("CURRENT SUCCESS INDEX: ", success)    
            array.push(
                <>
                    <Divider style={{margin:"10px 0"}}/> 
                    <Grid container style={{margin:"10px 0"}} justify="center" alignItems="center" fieldSelect className={this.getParentName(node) === "Single" && node.endStep !== "Go To Event" ? classes.eventTextHidden : classes.eventText}>
                        <Grid item xs={6}>
                            <p className={classes.fieldName} style={{margin:"0"}}>Do this...</p>
                        </Grid>
                        <Grid item xs={6}>
                            <Button className={classes.addEventLink} startIcon={<Add className={classes.addEvent}/>} onClick={(e)=>this.props.addEvent(success)} 
                            style={{background: this.getParentName(node) === "Single" && node.endStep !== "Go To Event" && "#C4C4C4"}}>
                                Add Event
                            </Button>
                        </Grid>
                    </Grid>
                </>
            )
            array.push(this.getEvent(event, sIndex, success))

            array = this.eventsUtil(sIndex, event, array)
        }

        if(fail){          
            // console.log("CURRENT FAIL INDEX: ", fail)        
            array.push(
                <>
                    <Divider style={{margin:"10px 0"}}/> 
                    <Grid container style={{margin:"10px 0"}}justify="center"  alignItems="center" className={this.getParentName(node) === "Single" && node.endStep !== "Go To Event" ? classes.eventTextHidden : classes.eventText}>
                        <Grid item xs={6}>
                            <p className={classes.fieldName} style={{margin:"0"}}>Or else do this...</p>
                        </Grid>
                        <Grid item xs={6}>
                            <Button className={classes.addEventLink} startIcon={<Add className={classes.addEvent}/>} onClick={(e)=>this.props.addEvent(fail)} 
                            style={{background: this.getParentName(node) === "Single" && node.endStep !== "Go To Event" && "#C4C4C4"}}>
                                Add Event
                            </Button>
                        </Grid>
                    </Grid>
                    
                </>
            )
            array.push(this.getEvent(event, fIndex, fail))

            array = this.eventsUtil(fIndex, event, array)
        }

        if(nextId){
            // console.log("CURRENT NEXT ID: ", NEXTIDTEST)  
            // console.log(event) 
            array.push(
                <>
                    <Divider style={{margin:"10px 0"}}/> 
                </>
            )
            array.push(this.getEvent(event, nIndex, nextId))

            array = this.eventsUtil(nIndex, event, array)
        }
        
        return array
    }

    //getEvent - to retrieve event selection
    getEvent = (event, id, eventId) => {
        const {classes} = this.props
        const node = this.props.node 
        var script = (
            <>
                <Grid container justify="center"  alignItems="center" className={this.getParentName(node) === "Single" && node.endStep !== "Go To Event" ? classes.eventTextHidden : classes.eventText}>
                    <Grid item xs={10}>
                        <Select 
                            key={id} 
                            name="name" 
                            value={event[id].name} 
                            className={classes.fieldSelect} 
                            style={{width:"100%"}}
                            onChange={this.props.handleEventScript(node.id, id, eventId)} 
                            disableUnderline

                            disabled={(event[id].name==="Check Vote Amount") || (this.getParentName(node) === "Single" && node.endStep !== "Go To Event") ? true : false}
                        >
                            <MenuItem value=" ">
                                <p className={classes.defaultField}>Choose an event</p>
                            </MenuItem>
                            {/* Check Card in Hand */}
                            {/* <MenuItem value="Check Card in Hand">
                                <Grid container justify="center" alignItems="center">
                                    <Grid item xs={3}>
                                        <span alt="icon" className={classes.selectionIcon}>
                                            <PanTool className={classes.selectionIconSize}/>
                                        </span>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <p className={classes.selectionTitle}>Check Hand</p>
                                        <p className={classes.selectionSubtitle}>Achieve an output from<br/>checking player's hand</p>
                                    </Grid>
                                </Grid>
                            </MenuItem> */}

                            {/* Vote on Card */}
                            {/* <MenuItem value="Vote on Card">
                                <Grid container justify="center" alignItems="center">
                                    <Grid item xs={3}>
                                        <span alt="icon" className={classes.selectionIcon}>
                                            <HowToVote className={classes.selectionIconSize}/>
                                        </span>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <p className={classes.selectionTitle}>Vote</p>
                                        <p className={classes.selectionSubtitle}>Cast a vote session<br/>on a card</p>
                                    </Grid>
                                </Grid>
                            </MenuItem> */}

                            {/* Check Vote Amount */}
                            {/* <MenuItem value="Check Vote Amount" disabled>
                                <Grid container justify="center" alignItems="center">
                                    <Grid item xs={3}>
                                        <span alt="icon" className={classes.selectionIcon}>
                                            <WhereToVote className={classes.selectionIconSize}/>
                                        </span>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <p className={classes.selectionTitle}>Check Vote</p>
                                        <p className={classes.selectionSubtitle}>Achieve an output from<br/>vote's result</p>
                                    </Grid>
                                </Grid>
                            </MenuItem>  */}

                            {/* Add Point */}
                            {/* <MenuItem value="Add Point">Add Point</MenuItem>

                            {/* Clear Hand */}
                            <MenuItem value="Clear Card">
                                <Grid container justify="center" alignItems="center">
                                    <Grid item xs={3}>
                                        <span alt="icon" className={classes.selectionIcon}>
                                            <LayersClear className={classes.selectionIconSize}/>
                                        </span>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <p className={classes.selectionTitle}>Clear Cards</p>
                                        <p className={classes.selectionSubtitle}>Remove cards<br/>from the canvas</p>
                                    </Grid>
                                </Grid>
                            </MenuItem>

                            {/* Draw Card */}
                            <MenuItem value="Draw Card">
                                <Grid container justify="center" alignItems="center">
                                    <Grid item xs={3}>
                                        <span alt="icon" className={classes.selectionIcon}>
                                            <Layers className={classes.selectionIconSize}/>
                                        </span>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <p className={classes.selectionTitle}>Draw Cards</p>
                                        <p className={classes.selectionSubtitle}>Draw cards to players'<br/>hand</p>
                                    </Grid>
                                </Grid>
                            </MenuItem>

                            {/* Skip Phase */}
                            {/* <MenuItem value="Skip Phase">Skip Phase</MenuItem> */}

                            {/* Shift Turn */}
                            <MenuItem value="Shift To" disabled={this.props.node.type === "Steps"}>
                                <Grid container justify="center" alignItems="center">
                                    <Grid item xs={3}>
                                        <span alt="icon" className={classes.selectionIcon}>
                                            <CallSplit className={classes.selectionIconSize}/>
                                        </span>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <p className={classes.selectionTitle}>Shift To</p>
                                        <p className={classes.selectionSubtitle}>Shift the structure<br/>of the game</p>
                                    </Grid>
                                </Grid>
                            </MenuItem>
                            {/* Notification */}
                            <MenuItem value="Notification">
                                <Grid container justify="center" alignItems="center">
                                    <Grid item xs={3}>
                                        <span alt="icon" className={classes.selectionIcon}>
                                            <Notifications className={classes.selectionIconSize}/>
                                        </span>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <p className={classes.selectionTitle}>Notify</p>
                                        <p className={classes.selectionSubtitle}>Send a notification <br/> to jammers! </p>
                                    </Grid>
                                </Grid>
                            </MenuItem>
                            {/* Timer */}
                            <MenuItem value="Timer" /*disabled={this.props.node.type !== "Steps"}*/>
                                <Grid container justify="center" alignItems="center">
                                    <Grid item xs={3}>
                                        <span alt="icon" className={classes.selectionIcon}>
                                            <Timer className={classes.selectionIconSize}/>
                                        </span>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <p className={classes.selectionTitle}>Timer</p>
                                        <p className={classes.selectionSubtitle}>Set & start a timer</p>
                                    </Grid>
                                </Grid>
                            </MenuItem>
                            <MenuItem value="Email" /*disabled={this.props.node.type !== "Steps"}*/>
                                <Grid container justify="center" alignItems="center">
                                    <Grid item xs={3}>
                                        <span alt="icon" className={classes.selectionIcon}>
                                            <Email className={classes.selectionIconSize}/>
                                        </span>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <p className={classes.selectionTitle}>Email</p>
                                        <p className={classes.selectionSubtitle}>Send an email to <br/> jammers.</p>
                                    </Grid>
                                </Grid>
                            </MenuItem>
                            {/* Undo Action */}
                            {/* <MenuItem value="Undo Action">Undo Action</MenuItem> */}
                        </Select>
                    </Grid>
                    <Grid item xs={2}>
                        <IconButton style={{outline:"none"}} disabled = {!this.canDelete(id) || (this.getParentName(node) === "Single" && node.endStep !== "Go To Event") ? true : false} onClick={()=>this.props.removeEvent(id)}>
                            <Remove />
                        </IconButton>
                    </Grid>
                </Grid>
                <div className={`${event[id].name !== 0 ? classes.showAction : classes.closeAction}`}>
                    {this.callEventScript(id)}
                </div>

                
            </>
        )
        return script
    }
    
    canDelete = (id) => {
        let event = this.props.node.events
        let canDelete = true 
        
        //if canDelete is still false, check if its only 1 event or if its a fail or success event 
        if(canDelete){
            if(event.length===1){ 
                //if there's only 1 event
                canDelete = false
            }
            else if(event.length === 3 && (event[id].name === "Vote on Card" || event[id].name === "Check Card in Hand")){
                canDelete = false
            }
            else{
                //if its only 1 event or if its a fail or success event 
                for(let i=0; i<event.length; i++){
                    if(event[i].success === event[id].id || event[i].fail === event[id].id ){
                        canDelete = false
                    }
                }
            }
        }
        

        return canDelete
    }
    
    //callEventScript - to call the script details of an event
    callEventScript = (id) =>{
        //Get event details
        const {classes} = this.props
        const node = this.props.node
        const deckCategories = this.props.deckCategories
        let event = this.props.node.events
        let script = null

        if(event[id].name === "Check Card in Hand"){
            //Check card in hand
            script = (
                <div className={this.getParentName(node) === "Single" && node.endStep !== "Go To Event" ? classes.eventTextHidden : classes.eventText}>
                    If &nbsp;
                    <Select name="role" 
                    value={event[id].role} 
                    className={classes.fieldSelect} 
                    style={{width:"fit-content", margin:"5px"}} 
                    onChange={this.props.handleEventScriptFields(id)} 
                    disabled={this.getParentName(node) === "Single" && node.endStep !== "Go To Event" && true}
                    disableUnderline>
                        <MenuItem value=" "><p className={classes.defaultField}>Choose a role</p></MenuItem>
                        <MenuItem value={"Current In Turn"}>Current In Turn</MenuItem>
                        <MenuItem value={"Next In Turn"}>Next In Turn</MenuItem>
                        <MenuItem value={"Previous In Turn"}>Previous In Turn</MenuItem>
                        <MenuItem value={"Originator"}>Originator</MenuItem>
                        <MenuItem value={"Jam Host"}>Jam Host</MenuItem>
                    </Select>
                    &nbsp; has &nbsp;
                    <Select name="card" value={event[id].card} className={classes.fieldSelect} style={{ width:"fit-content", margin:"5px"}} onChange={this.props.handleEventScriptFields(id)} disableUnderline
                    disabled={this.getParentName(node) === "Single" && node.endStep !== "Go To Event" && true}
                    >
                        <MenuItem value=" "><p className={classes.defaultField}>Choose a card category</p></MenuItem>
                        {deckCategories && deckCategories.map((n)=>
                            <MenuItem value={n}>{n}</MenuItem>
                        )}
                        {/* <MenuItem value={"Once Upon a Time"}>Once Upon a Time</MenuItem>
                        <MenuItem value={"Story"} style={{paddingLeft:"40px"}}>Story</MenuItem>
                        <MenuItem value={"Ending"}  style={{paddingLeft:"40px"}}>Ending</MenuItem> */}
                    </Select>
                    <Select name="operation" value={event[id].operation} className={classes.fieldSelect} style={{ width:"fit-content", margin:"5px"}} onChange={this.props.handleEventScriptFields(id)} disableUnderline
                    disabled={this.getParentName(node) === "Single" && node.endStep !== "Go To Event" && true}
                    >
                        <MenuItem value=" "><p className={classes.defaultField}>Choose a logic</p></MenuItem>
                        <MenuItem value={"<"}>less than</MenuItem>
                        {/* <MenuItem value={"<="}>less and equal than</MenuItem> */}
                        <MenuItem value={"="}>equal to</MenuItem>
                        {/* <MenuItem value={">="}>more and equal than</MenuItem> */}
                        <MenuItem value={">"}>more than</MenuItem>
                    </Select>
                    <Input 
                        name="number" 
                        placeholder="0" 
                        value={event[id].number} 
                        className={classes.fieldText} 
                        style={{width:"3rem"}} 
                        onChange={this.props.handleEventScriptFields(id)} 
                        disableUnderline
                        disabled={this.getParentName(node) === "Single" && node.endStep !== "Go To Event" && true}
                    />
                    {/* <div className={classes.formField}>
                        <RadioGroup name="selection" value={event[id].selection} onChange={this.props.handleEventScriptFields(id)}>
                            <FormControlLabel value="Reference" control={<Radio />} 
                                label={
                                    <Select name="reference" value={event[id].reference} className={classes.fieldSelect} style={{ width:"fit-content", margin:"5px"}} onChange={this.props.handleEventScriptFields(id)} disableUnderline disabled={event[id].selection !== "Reference" && true}>
                                        <ListSubheader style={{pointerEvents: "none"}}><strong>By reference</strong></ListSubheader>
                                        <MenuItem value={"Current Turn Owner"}>Current Turn Owner</MenuItem>
                                        <MenuItem value={"Previous Turn Owner"}>Previous Turn Owner</MenuItem>
                                    </Select>
                                }/>
                            <FormControlLabel value="Number" control={<Radio />} 
                                label={
                                    <Input 
                                        name="number" 
                                        placeholder="0" 
                                        value={event[id].number} 
                                        className={classes.fieldText} 
                                        style={{width:"3rem"}} 
                                        onChange={this.props.handleEventScriptFields(id)} 
                                        disableUnderline 
                                        disabled={event[id].selection !== "Number" && true}
                                    />
                                } />  
                        </RadioGroup>    
                    </div> */}
                </div>
            )

        }
        // else if(event[id].name==="Check Vote Amount"){
        //     //Check vote amount
        //     script = (
        //         <div style={{fontSize:"14px", marginTop:"10px"}}>
        //             If &nbsp;
        //             <Select name="card" value={event[id].card} className={classes.fieldSelect} style={{ width:"fit-content", margin:"5px"}} onChange={this.props.handleEventScriptFields(id)} disableUnderline>
        //                 <MenuItem value=" "><p className={classes.defaultField}>Choose a card</p></MenuItem>
        //                 {deckCategories && deckCategories.map((n)=>
        //                     <MenuItem value={n}>{n}</MenuItem>
        //                 )}
        //             </Select>
        //             is
        //             <Select name="operation" value={event[id].operation} className={classes.fieldSelect} style={{ width:"fit-content", margin:"5px"}} onChange={this.props.handleEventScriptFields(id)} disableUnderline>
        //                 <MenuItem value=" "><p className={classes.defaultField}>Choose a logic</p></MenuItem>
        //                 <MenuItem value={"<"}>less than</MenuItem>
        //                 <MenuItem value={"="}>equal to</MenuItem>
        //                 <MenuItem value={">"}>more than</MenuItem>
        //             </Select>
        //             {(event[id].operation !== "least" && event[id].operation !== "most") ? 
        //                 <div className={classes.formField}>
        //                     <RadioGroup name="selection" value={event[id].selection} onChange={this.props.handleEventScriptFields(id)}>
        //                         <FormControlLabel value="Reference" control={<Radio />} 
        //                             label={
        //                                 <Select name="reference" value={event[id].reference} className={classes.fieldSelect} style={{ width:"fit-content", margin:"5px"}} onChange={this.props.handleEventScriptFields(id)} disableUnderline disabled={event[id].selection !== "Reference" && true}>
        //                                     <MenuItem value=" "><p className={classes.defaultField}>Choose a reference</p></MenuItem>
        //                                     <MenuItem value={"Total Players"}>Total number of players</MenuItem>
        //                                     <MenuItem value={"Half Players"}>Half of the number of players</MenuItem>
        //                                 </Select>
        //                             }/>
        //                         <FormControlLabel value="Number"control={<Radio />} 
        //                             label={
        //                                 <Input name="number" placeholder="0" value={event[id].number} className={classes.fieldText} style={{width:"3rem"}} onChange={this.props.handleEventScriptFields(id)} disableUnderline disabled={event[id].selection !== "Number" && true}/>
        //                             } />  
        //                     </RadioGroup>
        //                 </div>
        //                 : 
        //                 null
        //             }
        //         </div>
        //     )
        // }
        else if(event[id].name==="Vote on Card"){
            //Vote on card
            script = (
                <>
                    <div className={this.getParentName(node) === "Single" && node.endStep !== "Go To Event" ? classes.eventTextHidden : classes.eventText}>
                        Vote on the &nbsp;
                        <Select name="reference" 
                            value={event[id].reference} 
                            className={classes.fieldSelect} 
                            style={{ width:"fit-content"}} 
                            onChange={this.props.handleEventScriptFields(id)} 
                            disableUnderline
                            disabled={this.getParentName(node) === "Single" && node.endStep !== "Go To Event" && true}
                            >
                                <MenuItem value=" "><p className={classes.defaultField}>Choose a reference</p></MenuItem>
                                {/* <MenuItem value={"Paired Card"}>Paired Card</MenuItem> */}
                                <MenuItem value={"Latest Card"}>Latest Card</MenuItem>
                        </Select>
                    </div>
                    <Divider style={{margin:"20px 0"}}/>
                    {/* Check Vote Amount */}
                    <Grid container justify="center"  alignItems="center" >
                        <Grid item xs={12}>
                            {/* Check Vote Amount */}
                            <Grid container 
                            className={classes.fieldSelect} 
                            style={{width:"100%", background:"#D1D1D1", color:"#828282", cursor:"not-allowed"}}>
                                <Grid container justify="center" alignItems="center">
                                    <Grid item xs={3}>
                                        <span alt="icon" className={classes.selectionIcon}>
                                            <WhereToVote className={classes.selectionIconSize}  style={{color:"#828282"}}/>
                                        </span>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <p className={classes.selectionTitle} style={{color:"#828282"}}>Check Vote</p>
                                        <p className={classes.selectionSubtitle} style={{color:"#828282"}}>Achieve an output from<br/>vote's result</p>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <div className={this.getParentName(node) === "Single" && node.endStep !== "Go To Event" ? classes.eventTextHidden : classes.eventText}>
                        If votes on&nbsp;
                        <Select name="card" value={event[id].reference} className={classes.fieldSelect} style={{ width:"fit-content", margin:"5px", background:"#D1D1D1", color:"#828282", cursor:"not-allowed"}} onChange={this.props.handleEventScriptFields(id)} disableUnderline disabled>
                            <MenuItem value=" "><p className={classes.defaultField} style={{cursor:"not-allowed"}}>Select a card</p></MenuItem>
                            <MenuItem value="Latest Card">Latest Card</MenuItem>
                            {/* <MenuItem value=" ">Latest Card</MenuItem> */}
                            {deckCategories && deckCategories.map((n)=>
                                <MenuItem value={n}>{n}</MenuItem>
                            )}
                        </Select>
                        is
                        <Select name="operation" value={event[id].operation} className={classes.fieldSelect} style={{ width:"fit-content", margin:"5px"}} onChange={this.props.handleEventScriptFields(id)} disableUnderline
                        disabled={this.getParentName(node) === "Single" && node.endStep !== "Go To Event" && true}>
                            <MenuItem value=" "><p className={classes.defaultField}>Choose a logic</p></MenuItem>
                            <MenuItem value={"<"}>less than</MenuItem>
                            <MenuItem value={"="}>equal to</MenuItem>
                            <MenuItem value={">"}>more than</MenuItem>
                        </Select>
                        {(event[id].operation !== "least" && event[id].operation !== "most") ? 
                            <div className={classes.formField}>
                                <RadioGroup name="cvaselection" value={event[id].cvaselection} onChange={this.props.handleEventScriptFields(id)}
                                disabled={this.getParentName(node) === "Single" && node.endStep !== "Go To Event" && true}>
                                    <FormControlLabel value="Reference" control={<Radio />} 
                                        label={
                                            <Select name="cvareference" value={event[id].cvareference} className={classes.fieldSelect} style={{ width:"fit-content", margin:"5px"}} onChange={this.props.handleEventScriptFields(id)} disableUnderline>
                                                <MenuItem value=" "><p className={classes.defaultField}>Choose a reference</p></MenuItem>
                                                <MenuItem value={"Total Players"}>Total number of players</MenuItem>
                                                <MenuItem value={"Half Players"}>Half of the number of players</MenuItem>
                                            </Select>
                                        }/>
                                    <FormControlLabel value="Number" control={<Radio />} 
                                        label={
                                            <Input name="number" placeholder="0" value={event[id].number} className={classes.fieldText} style={{width:"3rem"}} onChange={this.props.handleEventScriptFields(id)} disableUnderline/>
                                        } />  
                                </RadioGroup>
                            </div>
                            : 
                            null
                        }
                    </div>
                </>
            )
        }
        else if(event[id].name==="Add Point"){
            //Add point
            script = 
            (<div className={this.getParentName(node) === "Single" && node.endStep !== "Go To Event" ? classes.eventTextHidden : classes.eventText}>
                Add &nbsp;
                <Input 
                    name="number" 
                    value={event[id].number} 
                    className={classes.fieldText} 
                    style={{width:"3rem"}} 
                    disableUnderline
                    onChange={this.props.handleEventScriptFields(id)}
                    disabled={this.getParentName(node) === "Single" && node.endStep !== "Go To Event" && true}
                />
                &nbsp; point to &nbsp;
                <Select 
                    name="reference" 
                    value={event[id].reference} 
                    className={classes.fieldSelect} 
                    style={{ width:"fit-content", margin:"5px"}} 
                    onChange={this.props.handleEventScriptFields(id)} 
                    disableUnderline
                    disabled={this.getParentName(node) === "Single" && node.endStep !== "Go To Event" && true}
                >
                    <ListSubheader style={{pointerEvents: "none"}}><strong>Choose reference</strong></ListSubheader>
                    <MenuItem value={"Current Player"}>Current Player</MenuItem>
                    <MenuItem value={"Next Player"}>Next Player</MenuItem>
                </Select>
            </div>)
        }
        else if(event[id].name==="Clear Card"){
            // Clear Cards
            script=(
                <div className={this.getParentName(node) === "Single" && node.endStep !== "Go To Event" ? classes.eventTextHidden : classes.eventText}>
                    <div className={classes.formField}>
                        <RadioGroup name="selection" value={event[id].selection} onChange={this.props.handleEventScriptFields(id)} disabled={this.getParentName(node) === "Single" && node.endStep !== "Go To Event" && true}>
                            <FormControlLabel value="All Card" control={<Radio />} label={<p className={classes.fieldRadio}>All Cards</p>}/>
                            <FormControlLabel value="Target Card" control={<Radio />} 
                                label={
                                    <>
                                        <Select name="card" value={event[id].card} className={classes.fieldSelect} style={{ width:"fit-content", margin:"5px"}} onChange={this.props.handleEventScriptFields(id)} disableUnderline disabled={this.getParentName(node) === "Single" && node.endStep !== "Go To Event" && true}>
                                            <MenuItem value=" "><p className={classes.defaultField}>Choose a card category</p></MenuItem>
                                            {deckCategories && deckCategories.map((n)=>
                                                <MenuItem value={n}>{n}</MenuItem>
                                            )}
                                            {/* <MenuItem value={"Story"} style={{paddingLeft:"40px"}}>Story</MenuItem>
                                            <MenuItem value={"Ending"} style={{paddingLeft:"40px"}}>Ending</MenuItem> */}
                                        </Select>
                                    </>
                                }/>
                        </RadioGroup>
                    </div>
                </div>
            )
        }
        else if(event[id].name==="Draw Card"){
            // Draw Card
            script=(
                <div className={this.getParentName(node) === "Single" && node.endStep !== "Go To Event" ? classes.eventTextHidden : classes.eventText}>
                    Draw &nbsp;
                    <Input name="number" placeholder="0" value={event[id].number} className={classes.fieldText} style={{width:"3rem"}} onChange={this.props.handleEventScriptFields(id)} disableUnderline disabled={this.getParentName(node) === "Single" && node.endStep !== "Go To Event" && true}/>
                    <Select name="card" value={event[id].card} className={classes.fieldSelect} style={{ width:"fit-content", margin:"5px"}} onChange={this.props.handleEventScriptFields(id)} disableUnderline disabled={this.getParentName(node) === "Single" && node.endStep !== "Go To Event" && true}>
                        <MenuItem value=" "><p className={classes.defaultField}>Choose a card category</p></MenuItem>
                        {deckCategories && deckCategories.map((n)=>
                            <MenuItem value={n}>{n}</MenuItem>
                        )}
                        {/* <MenuItem value="Once Upon a Time">Once Upon a Time</MenuItem>
                        <MenuItem value="Story" style={{paddingLeft:"40px"}}>Story</MenuItem>
                        <MenuItem value="Ending" style={{paddingLeft:"40px"}}>Ending</MenuItem> */}
                    </Select>

                    <div className={classes.formField} style={{marginTop:"10px"}}>
                        <RadioGroup name="selection" value={event[id].selection} disabled={this.getParentName(node) === "Single" && node.endStep !== "Go To Event" && true} onChange={this.props.handleEventScriptFields(id)}>
                            <FormControlLabel value="All Players" disabled={this.getParentName(node) === "Single" && node.endStep !== "Go To Event" && true} control={<Radio />} label={<p className={classes.fieldRadio}>All Players</p>}/>
                            {/* <FormControlLabel value="Reference" control={<Radio />} 
                                label={
                                    <>
                                        Reference &nbsp;
                                        <Select name="reference" value={event[id].reference} className={classes.fieldSelect} style={{ width:"fit-content"}} onChange={this.props.handleEventScriptFields(id)} disableUnderline disabled={event[id].selection !== "Reference" && true}>
                                            <MenuItem value={0}>Choose reference</MenuItem>
                                            <MenuItem value={1}>AAAAA</MenuItem>
                                        </Select>
                                    </>
                                }/> */}
                            <FormControlLabel value="Target" control={<Radio />} 
                                label={
                                    <>
                                        <Select name="target" value={event[id].target} className={classes.fieldSelect} style={{ width:"fit-content"}} onChange={this.props.handleEventScriptFields(id)} disableUnderline disabled={this.getParentName(node) === "Single" && node.endStep !== "Go To Event" && true}>
                                            <MenuItem value=" "><p className={classes.defaultField}>Choose a target</p></MenuItem>
                                            <MenuItem value="Current In-Turn">Current In Turn</MenuItem>
                                            <MenuItem value="Next In-Turn">Next In Turn</MenuItem>
                                            <MenuItem value="Previous In-Turn">Previous In Turn</MenuItem>
                                            <MenuItem value="Event Trigger Origin">Originator</MenuItem>
                                            <MenuItem value="Jam Host">Jam Host</MenuItem>
                                            <MenuItem value="Card Owner">Card Owner</MenuItem>
                                        </Select>
                                    </>
                                }/>
                            {/* <FormControlLabel value="Filter" control={<Radio />} 
                                label={
                                    <>
                                        Filter by &nbsp;
                                        <Select name="filter" value={event[id].filter} className={classes.fieldSelect} style={{ width:"fit-content"}} onChange={this.props.handleEventScriptFields(id)} disableUnderline disabled={event[id].selection !== "Filter" && true}>
                                            <MenuItem value={0}>Choose role</MenuItem>
                                            <MenuItem value={1}>Storyteller</MenuItem>
                                        </Select>
                                    </>
                                }/> */}
                        </RadioGroup>
                        
                    </div>
                </div>
            )
            
        }
        else if(event[id].name==="Skip Phase"){
            // Skip Phase
            script=(
                <div className={this.getParentName(node) === "Single" && node.endStep !== "Go To Event" ? classes.eventTextHidden : classes.eventText}>
                    Skip to &nbsp;
                    <Select disabled={this.getParentName(node) === "Single" && node.endStep !== "Go To Event" && true} name="phase" value={event[id].phase} className={classes.fieldSelect} style={{ width:"fit-content", margin:"5px"}} onChange={this.props.handleEventScriptFields(id)} disableUnderline>
                    <ListSubheader style={{pointerEvents: "none"}}><strong>Choose phase</strong></ListSubheader>
                        <MenuItem value={"Phase 1"}>Phase 1</MenuItem>
                    </Select>
                </div>
            )
        }
        else if(event[id].name==="Shift To"){
            script=(
                <div className={this.getParentName(node) === "Single" && node.endStep !== "Go To Event" ? classes.eventTextHidden : classes.eventText}>
                    Shift to &nbsp;
                    <Select 
                        name="reference" 
                        value={event[id].reference}
                        defaultValue={"Next Step"} 
                        className={classes.fieldSelect} 
                        style={{ width:"fit-content", margin:"5px"}} 
                        onChange={this.props.handleEventScriptFields(id)} 
                        disableUnderline
                        disabled={this.getParentName(node) === "Single" && node.endStep !== "Go To Event" && true}
                    >
                        <MenuItem value=" "><p className={classes.defaultField}>Choose a reference</p></MenuItem>
                        <MenuItem value="Next Step">Next Step</MenuItem>
                        <MenuItem value="Next Player">Next Player</MenuItem>
                        <MenuItem value="Next Turn Structure">Next Turn Structure</MenuItem>
                        <MenuItem value="Next Phase">Next Phase</MenuItem>
                        <MenuItem value="Card Owner">Card Owner</MenuItem>
                    </Select>
                </div>
            )
        }
        else if(event[id].name === "Notification"){
            script=(
                <div className={this.getParentName(node) === "Single" && node.endStep !== "Go To Event" ? classes.eventTextHidden : classes.eventText}>
                    Notify &nbsp;
                    <Select name="target" value={event[id].target} className={classes.fieldSelect} style={{ width:"fit-content"}} onChange={this.props.handleEventScriptFields(id)} disableUnderline disabled={this.getParentName(node) === "Single" && node.endStep !== "Go To Event" && true}>
                                            <MenuItem value=" "><p className={classes.defaultField}>Choose a target</p></MenuItem>
                                            <MenuItem value="All Players">All Players</MenuItem>
                                            <MenuItem value="Current In-Turn">Current In Turn</MenuItem>
                                            <MenuItem value="Next In-Turn">Next In Turn</MenuItem>
                                            <MenuItem value="Previous In-Turn">Previous In Turn</MenuItem>
                                            <MenuItem value="Event Trigger Origin">Originator</MenuItem>
                                            <MenuItem value="Jam Host">Jam Host</MenuItem>
                                            <MenuItem value="Card Owner">Card Owner</MenuItem>


                    </Select>
                    &nbsp; About 
                    <div>
                        <TextField name="notification" placeholder="Write notification here." value={event[id].notification} 
                            style={{marginTop:"1rem" , height:"100px", background:"#FAF7EA", padding:"10px", fontSize:"12px", borderRadius:"5px", border:"1px solid #D1D1D1"}} 
                            onChange={
                                // (event)=>{
                                // debounce((event) => {
                                //     this.props.handleEventScriptFields(id)
                                //   }, 300);
                                // } 
                                //_.debounce(this.props.handleEventScriptFields(id), 100)   
                                this.props.handleEventScriptFields(id)
                            } 
                            InputProps={{disableUnderline:true}} 
                            disabled={this.getParentName(node) === "Single" && node.endStep !== "Go To Event" && true}
                        />
                    </div>
                </div>
            )
        }
        else if(event[id].name === "Timer"){
            script=(
                <div className={this.getParentName(node) === "Single" && node.endStep !== "Go To Event" ? classes.eventTextHidden : classes.eventText}>
                    <strong>Duration</strong><br/>
                    <p style={{lineHeight:"3em", fontSize:"14px"}}>
                    Set a timer for &nbsp;
                    <TextField
                        name="timer-min"
                        //type="number"
                        style={{ borderRadius: "5px 0 0 5px", WebkitAppearance: "none" }}
                        className={classes.timerField}
                        InputProps={{
                            disableUnderline: true,
                            className: classes.fieldProp,
                        }}
                        inputProps={{ style: { textAlign: "center", WebkitAppearance: "none" } }}
                        onChange={this.props.handleEventScriptFields(id)}
                        value={this.formatTime(event[id].timerMin)}
                        onInput={this.handleTimerTextInput}
                    />
                    <TextField
                        name="timer-sec"
                        //type="number"
                        className={classes.timerField}
                        style={{ borderRadius: "0 5px 5px 0", marginLeft: "5px" }}
                        InputProps={{
                            disableUnderline: true,
                            className: classes.fieldProp,
                        }}
                        inputProps={{ style: { textAlign: "center" } }}
                        onChange={this.props.handleEventScriptFields(id)}
                        value={this.formatTime(event[id].timerSec)}
                        onInput={this.handleTimerTextInput}
                    />
                    </p>
                    Note: On-going timer will be overwritten by this timer.
                </div>
            )
        }
        else if(event[id].name === "Email"){
            script=(
                <div className={this.getParentName(node) === "Single" && node.endStep !== "Go To Event" ? classes.eventTextHidden : classes.eventText}>
                    Email &nbsp;
                    <Select name="target" value={event[id].target} className={classes.fieldSelect} style={{ width:"fit-content"}} onChange={this.props.handleEventScriptFields(id)} disableUnderline disabled={this.getParentName(node) === "Single" && node.endStep !== "Go To Event" && true}>
                                            <MenuItem value=" "><p className={classes.defaultField}>Choose a target</p></MenuItem>
                                            <MenuItem value="All Players">All Players</MenuItem>
                                            <MenuItem value="Current In-Turn">Current In Turn</MenuItem>
                                            <MenuItem value="Next In-Turn">Next In Turn</MenuItem>
                                            <MenuItem value="Previous In-Turn">Previous In Turn</MenuItem>
                                            <MenuItem value="Event Trigger Origin">Originator</MenuItem>
                                            <MenuItem value="Jam Host">Jam Host</MenuItem>
                                            <MenuItem value="Card Owner">Card Owner</MenuItem>
                    </Select>
                    &nbsp; About 
                    <div>
                        <TextField name="email" placeholder="Write email here." value={event[id].content} 
                            style={{marginTop:"1rem" , height:"100px", background:"#FAF7EA", padding:"10px", fontSize:"12px", borderRadius:"5px", border:"1px solid #D1D1D1"}} 
                            onChange={
                                // (event)=>{
                                // debounce((event) => {
                                //     this.props.handleEventScriptFields(id)
                                //   }, 300);
                                // } 
                                //_.debounce(this.props.handleEventScriptFields(id), 100)   
                                this.props.handleEventScriptFields(id)
                            } 
                            InputProps={{disableUnderline:true}} 
                            disabled={this.getParentName(node) === "Single" && node.endStep !== "Go To Event" && true}
                        />
                    </div>
                </div>
            )
        }
        return (script)
    }

    formatTime = (time) => {
        if(time<10){
            return ("0" + time).slice(0,2);
        }
        return time;
    }

    handleTimerTextInput = (e) => {
       // if(typeof e.target.value === "number"){
            const num = Number(e.target.value);
            if(Math.floor(num) === num && typeof num === "number" && num){
                e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString().slice(0,2);
                return;
            }
        // }
    }
}


export default withStyles(styles)(NodeSettings);