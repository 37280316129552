export const cardStyles = {
    panel: {
        position:"relative",
        width:"100%",
        height:"calc(100% - 20px)",
        padding:"0 1em",
        fontFamily:"Arial",
    },

    stackedCard:{
        marginTop:'-180px'
    },

    card: {
        position:'absolute',
        perspective: "1000px",
        width: "160px",
        height: "220px",
        background:"transparent",
        overflow:"visible",
        boxShadow:"none",
        border:"5px solid transparent",
        transitionProperty:"border",
        transitionDuration:"0.5s",
        '&:hover': {
            border:"5px solid #F2C94C",
        }
    },

    cardHover:{
        '&:hover': {
            "& $flip": {
                opacity:"1",
                display:"block",
            },
        }
    },

    content:{
        fontSize:"14px",
        color:"#231F20",
        marginTop:"10px",
        padding:"0 0 5px 0",
        flex:"1",
    },
    icon:{
        fontSize:"13px",
    },
    iconBtn:{
        width:"25px", 
        height:"25px", 
        '&:focus':{
            outline:"none"
        }
    }, 
    textArea:{
        border:"none",

        fontWeight:"500",
        width:"100%",
        fontFamily:"Montserrat",
        resize: "none",
        color:"black",
        background:"transparent",
        "&:focus":{
            outline:"none"
        },
    },
    user:{
        minHeight:"25px", 
        margin:"0", 
        width:"100%",
        fontSize:"10px", 
        color:"#231F20",
        fontFamily:"Montserrat",
        float:"left",
        textAlign:"left"
    },
    userBack:{
        position:"absolute", 
        width:"100%", 
        fontFamily:"Montserrat",
        color:"#231F20",
        textAlign:"center",
        bottom:"10px"
    },
    time:{
        fontSize:"7px", 
        margin:"0", 
        marginTop:"-2px"
    },
    menuIcon:{
        fontSize:"14px",
        marginRight:"10px",
        '&:focus':{
            outline:"none"
        }
    },
    menuItem:{
        fontSize:"14px",
        transition:"0.5s",
        '&:hover':{
            background:"#FFF3B4"
        }
    },
    sendIcon:{
        color:"#C4C4C4", 
        marginTop:"-5px", 
        fontSize:"13px"
    },

    flip:{
        transitionProperty:"opacity",
        transitionDuration:"0.5s",
        position:"absolute",
        cursor:"pointer",
        bottom:"-30px",
        left:"30%",
        padding: "5px 10px",
        borderRadius:"0 0 5px 5px",
        textTransform:"uppercase",
        background:"#DCB745",
        color:"white",
        fontSize:"12px",
        border:"none",
        opacity:"0",
        '&:focus':{
            outline:"none"
        }
    },

    cardInner:{
        position: "relative",
        width: "100%",
        height: "100%",
        textAlign: "center",
        transition: "transform 0.6s",
        transformStyle: "preserve-3d",
    },

    front:{
        position: "absolute",
        width: "100%",
        height: "100%",
        '-webkit-backface-visibility': "hidden",
        backfaceVisibility: "hidden",
        background:"#fff",
        color: "black",
        fontFamily:"Montserrat, sans-serif",
        padding:"10px",
        borderRadius:"5px",
        flexDirection:"column",
        display:"flex",
        transition:"opacity 0.6s",
        boxShadow:"4px 4px 4px rgb(0,0,0,0.1)",
    },
    
    back:{
        position: "absolute",
        width: "100%",
        height: "100%",
        '-webkit-backface-visibility': "hidden",
        backfaceVisibility: "hidden",
        background: "#fff",
        borderRadius:"5px",
        color: "black",
        transform: "rotateY(180deg)",
        transition:"opacity 0.6s",
        boxShadow:"4px 4px 4px rgb(0,0,0,0.1)",
    },

    backHeader:{
        width:'100%',
        fontFamily:'Montserrat',
        color:'#4F4F4F', 
        marginTop:'5px',
        fontWeight:'500',
        fontSize:'18px', 
        textAlign:'center'
    },

    backIconBg:{
        background:"#FFAA0D",
        width:"85px",
        height:"85px",
        margin:"auto",
        top:"15%",
    },

    backIcon:{
        color:"white",
        fontSize:"40px",
    },

    pin:{
        marginLeft:"2px",
        fontSize:"10px",
        color:"#4F4F4F", 
        display:"none"
    },

    cardIconBg:{
        background:'#FFAA0D'
    },

    moreIconBg:{
        float:'right',
        marginLeft:'5px' 
    },

    moreIcon:{
        color:'#C4C4C4'
    },

    bottomInfo:{
        position:"absolute", 
        width:"calc(100% - 20px)", 
        left:"10px", 
        bottom:"-5px"
    },

    word:{
        float:"left", 
        marginTop:"5px"
    },

    sendBg:{
        display:"block", 
        float:"right"
    },
    
    disabled:{
        cursor:"not-allowed"
    }
}