import { ApolloClient } from 'apollo-boost';

import { GAME_OBJECT_POSITION } from './mutations/game-object-position.mutation';
import { MutateGameObjectPositionDto } from './dtos/game-object-api.dto';

export const gameObjectApi = (client: ApolloClient<any>) => ({
  mutatePosition: async (dto: MutateGameObjectPositionDto) => client.mutate({
    mutation: GAME_OBJECT_POSITION,
    variables: {
      _id: dto.gid,
      objId: dto.objectId,
      x: dto.x,
      y: dto.y,
    },
  }),
});
