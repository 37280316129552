import React from 'react'
import './jambuilderLoader.css'
import jamBuilderPNG from './images/jamBuilderLoader.png'

class JamBuilderLoader extends React.Component {

    render() {
        return(
            <div className="loader" style={{position: 'fixed'}}>   
                <span className="box"/>
                <span className="box"/>  
                <div className="code"> 
                    <img src={jamBuilderPNG} alt="loader" width="120px" style ={{zIndex: "10"}}/>
                </div>    
                <span className="txt" style={{color:"white"}}>Building Your Jam...</span>
            </div>
        )
    }
}

export default JamBuilderLoader