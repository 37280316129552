import React, {Component} from 'react'
import {withStyles, Grid,Avatar,Button} from '@material-ui/core'


const styles = {
    container: {
        position:"fixed",
        height:"100%", 
        width:"100%",
        background:"#F2F2F2",
        fontFamily:"arial",
        textAlign: 'center'
    },

    box:{
        margin:"auto",
        height:"90%",
    },

    field:{
        display:"block",
        padding:"0 5px",
        width:"15rem",
        background:"#FAF7EA",
        border:"1px solid #D1D1D1",
        borderRadius:"5px",
        marginBottom:"1em",
    },

    buttons:{
        width:"15rem",
        background:"#FDAF2A",
        color:"#FFFFFF",
    },

    avatar:{
        width:"7rem",
        height:"7rem"
    },
    
    header:{
        fontWeight:"bold",
        fontSize:"1rem",
    },

    redirectMessage: {
        textAlign: 'center',
        marginTop: "1em"
    }
}


class Error extends Component {
    constructor(props){
        super(props)
        this.state = {
            count: 10,
        }

        this.countDown()
    }

    componentDidMount(){

    }

    render(){
        const {message, classes} = this.props

        return(
            <div className={classes.container}>
                <Grid container justify="center" alignItems="center" className={classes.box}>
                    <Grid direction="column" justify="center" alignItems="center" spacing="2" container>
                        <Grid item>
                            <Avatar className={classes.avatar} src={require('../element/error-face.png')}></Avatar>
                        
                        </Grid>
                        <Grid item className={classes.header}>
                            ERROR
                        </Grid>
                        <Grid item>
                            {message ? 
                                <>{message}</>
                            :   <>Well, this is awkward 😅</>
                            }
                        </Grid>
                        <Grid item>
                            <Button onClick={()=>{window.location.href="/"}} className={classes.buttons} id="button">
                                HOME
                            </Button>
                            <p className={classes.redirectMessage}>You will be directed to homepage in {this.state.count} seconds.</p> 
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        )
    }

    countDown = () => {
        setInterval(()=>{
            if(this.state.count > 0){
                this.setState((prev)=>({
                    count: prev.count - 1
                }))
            }else{
                window.location.href="/"
            }
        },1000)
    }
}

export default withStyles(styles)(Error)
