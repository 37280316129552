import React from 'react';
import './cardLoader.scss'

class CardLoader extends React.Component {

    render() {
        return(
            <>
                <div className="container">
                    <div className="box"></div>
                    <div className="box"></div>
                    <div className="box"></div>
                    <div className="box"></div>
                </div>
            </>
        )
    }
}

export default CardLoader
