import io from 'socket.io-client';
import config from '../config/backend';


const socket = io(config.backend.uri,{
    path: '/events',
    reconnection: true,
    transports: ['websocket'],
    upgrade: false,

})

export default socket;
