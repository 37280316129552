import * as React from 'react';

import { useAppDispatch } from '../../../store/use-app-dispatch.hook';
import { setUserId, setUsername } from '../../auth/auth.reducer';
import { setJamId, setRoomName } from '../jam.reducer';

export interface IJamCanvasProps {
    jamId: string;
    roomName: string;
    userId: string;
    username: string;
}

export const JamCanvas = (props: IJamCanvasProps) => {
    const { jamId, roomName, userId, username } = props;
    const dispatch = useAppDispatch();

    React.useEffect(() => {
        dispatch(setJamId(jamId));
        dispatch(setRoomName(roomName));
        dispatch(setUserId(userId));
        dispatch(setUsername(username));
    }, []);

    return <></>;
};
