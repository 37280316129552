import React from 'react'
import {Grid, Card, IconButton, Avatar, Menu, MenuItem,
        TextField,  Tooltip, withStyles} from '@material-ui/core';
import {MoreVert, CloseRounded, ImageOutlined, Create, LocalOfferOutlined,
        Repeat, Eco, Room, RoomOutlined,FormatBold, FormatListBulleted, FormatListNumbered,
        InsertLink, TextFormat, FormatItalic, FormatUnderlined, StrikethroughS, DeleteForeverOutlined} from '@material-ui/icons'
import moment from 'moment'
import Dropzone from 'react-dropzone'
import ContentEditable from 'react-contenteditable';
import _ from 'lodash'
import sanitizeHtml from 'sanitize-html'

import EE from '../../../../api/eventemitter'
import {styles} from '../../user_interface/assets/cardHandStyle'
import config from '../../../../config/backend'
import socket from '../../../../api/socket'
import '../../user_interface/assets/card.css'

import '../../../../app/app.css'

class HandCard extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            styling: {
                iconBg: null,
                color: null,
                backgrond: null,

            },
            anchorEl: null,
            imgUploaded: false,
            imgSrc:null,
            editorMenu: false,
            editorSub: null,
            // for text selection
            textSelection : null,
            textfieldTarget: null,
        }

        this.sanitizeConf = {
            allowedTags: ["b", "i", "em", "strong", "a", "p", "h1", "ol", "ul","li","strike","u","div"],
            allowedAttributes: { a: ["href"]}
        }

        this.deleteTimer = null


    }


    render(){
        const {editable, hasFlip, hasPin, hasImage, hasMenu, setOnDrag, onClick, listPinIds,
                onChange, openMenu, closeMenu, flipCard, checkLink,
                index, card, handleChangePlayerHand,
                onSubmit, categoryColor, classes, onImgUpload, onImgPaste, removeImg, isPlayerHand} = this.props
        const {imgUploaded, imgSrc} = this.props.imgData
        let background = card.category === "Blank" ? "#fff" : "#C4C4C4"
        let color =  card.category === "Blank" ? "#231F20" : "#fff"
        let iconBg = card.category === "Blank" ? "orange" : "#C4C4C4"
        const edited = card.edited
        const pin = card.pin
        const label = card.label ? card.label : card.labels
        const username = card?.origin?.username ? card?.origin?.username : card.username
        const modTime = RegExp('(pm|am)', 'g').test(card.time) ?  card.time : moment(card.time).local().format("h:mm a")
        const editTime = edited ? modTime + " • (Edited)" : modTime

        //So the new content is now a HTML string and to change the value into a string we do this
        const stringText = _.isString(card.content) ? card.content : new DOMParser().parseFromString(card.content, "text/html").body.textContent

        //console.log(window.getSelection());
        return(
                <Card
                    // style={{userSelect:"none"}}
                    key={index}
                    className={classes.card}
                    style={{overflow:"visible"}}
                    draggable="true"
                    onDragStart={
                        (e)=>{
                            if (editable) {
                                EE.emit("Game Object Dragging", {card, from: "InHand"});
                                // this.setState({onDragIndex: index})
                                setOnDrag(index);
                            }

                        }
                    }
                    >
                    {/* THE BACK OF THE CARD (CONTAINS CONTENT) */}
                    <Card
                        style={
                                card.category !== "Blank" ?
                                    card.category == null ?
                                        {background: "#fff",overflow:"visible"}
                                    :
                                        {background: color,overflow:"visible"}
                                :
                                    card.status==="completed" ?
                                        {background:"#F5EFD2",overflow:"visible"}
                                        :
                                        {background: background,overflow:"visible"}
                            }
                        className={card.flip && hasMenu ? classes.cardContentFlip : classes.cardContent}
                    >
                        <Grid container direction="row" justify="center" alignItems="center">
                            <Grid item xs={3}>
                                <Avatar
                                    style={
                                        card.category == null ?
                                            {background: "#C4C4C4", width:"30px", height:"30px"} :
                                            {background: categoryColor, width:"30px", height:"30px"}
                                        }
                                >
                                    {card.category !== "Blank" ?
                                        <Eco style={
                                            card.category==null?
                                                {color: "#fff", fontSize:"16px"}
                                            :
                                                {color: color, fontSize:"16px"}} />
                                    :
                                        <Create style={{color: background, fontSize:"16px"}} />}
                                </Avatar>
                            </Grid>
                            <Grid item xs={6} style={{color: "#4f4f4f", fontSize:"8px"}}>
                                <p className={classes.username}>{username}</p>
                                {editTime}
                                {listPinIds?.includes(card.libID) && hasPin && <Room className={classes.pin} style={{fontSize:"10px"}}/>}
                            </Grid>
                            <Grid item xs={3}>
                                {hasMenu &&
                                    <IconButton className={classes.iconRight} onClick={(e) =>this.openMenu(e)}>
                                        <MoreVert style={{color:"#C4C4C4", fontSize:"16px"}}/>
                                    </IconButton>
                                }

                            </Grid>
                            <Grid item xs={12}>
                                <p style={{margin:"5px 0 0 0", color: !categoryColor ? "#c4c4c4" : categoryColor, fontSize:"10px", fontWeight:"bold"}}>
                                    {card.category == null ?
                                    "No category" :
                                    card.category}
                                </p>
                            </Grid>
                        </Grid>
                        <Grid container className={classes.contentWrapper}>
                            <div
                                style={{
                                    display: this.state.editorMenu ? 'block' : 'none',
                                    position: "absolute",
                                    top: "10px",
                                    left: "40px",
                                    backgroundColor: "white",
                                    borderRadius: "5px",
                                    padding: "10px",
                                    boxShadow: "1px 1px #c4c4c4",
                                    // userSelect:"none",

                                }}
                                id="editable-menu-inhand"

                            >
                                <TextFormat onMouseDown={(e)=>{e.preventDefault(); this.setState({editorSub: 'format'}); /*document.execCommand("bold",false,"italic")*/;}}  className={classes.menuIcon}/>
                                <FormatListBulleted onMouseDown={(e)=>{e.preventDefault(); this.setState({editorSub: 'list'}); /*document.execCommand("insertOrderedList",false,"insertOrderedList")*/;}}  className={classes.menuIcon} />
                                <InsertLink 
                                onMouseDown={(e)=>{
                                    e.preventDefault(); 
                                    this.saveSelection(); 
                                    this.setState({editorSub: "link"},()=>{
                                        if (this.hyperlinkRef?.current) {
                                            this.hyperlinkRef.current.focus();
                                        }
                                    }); /*document.execCommand("createLink",false,"https://google.com");*/}}  className={classes.menuIcon}/>
                            </div>
                            {this.editableSubMenu()}
                            <Dropzone
                                onDrop={(files)=>{this.props.onDrop(files, isPlayerHand ? index : card.libID)}}
                                noClick={true}
                                noKeyboard={true}
                                //onDragOver={()=>{this.setState({dragOver:true})}}
                                //onDragLeave={()=>{this.setState({dragOver:false})}}
                            >
                                {({getRootProps, getInputProps, isDragActive }) => (
                                    <div {...getRootProps()} style={{width:"100%", height:"125px"}}>
                                        <input {...getInputProps()} />
                                        {isDragActive && !card.content && hasImage?
                                            <p className={classes.dropZone}>
                                                <b>Drag and drop image here</b>
                                            </p>
                                            :
                                            card.imgUrl ?
                                                <div className={classes.imgHolder}>
                                                    {
                                                        hasImage &&
                                                        <IconButton className={classes.imgDelete} style={{outline:"none"}}>
                                                            <CloseRounded style={{fontSize:"12px"}} onClick={()=>removeImg(isPlayerHand ? index : card.libID)}/>
                                                        </IconButton>
                                                    }

                                                    <img
                                                        style={{width:"100%"}}
                                                        alt="img"
                                                        src={`${config.backend.uri}/static/uploads/${card.imgUrl}`}
                                                    />
                                                </div>
                                                :
                                                <ContentEditable
                                                    tagName='pre'
                                                    id="contentEditableHand"
                                                    className={classes.content  + " cancel"}
                                                    style ={{
                                                        height:"170px",
                                                        //fontSize:"11px",
                                                        outline:"none",
                                                        zIndex: 1,
                                                        whiteSpace:"pre-line",
                                                        // userSelect: "text",
                                                    }}
                                                    contentEditable="true"
                                                    html={card.content}
                                                    //onKeyDown={(e)=>this.onKeyDown(e)}
                                                    onClick={(e)=>{
                                                        editable && onClick(e,index)

                                                        if(e.target.tagName.toLowerCase() === 'a'){
                                                            const newWindow = window.open(e.target.href, '_blank', 'noopener noreferrer')
                                                            if (newWindow){newWindow.opener = null}
                                                        }
                                                    }}
                                                    onFocus={(evt)=>{
                                                        this.setState({
                                                            editorMenu: true,
                                                        })
                                                        // evt.persist()
                                                        // if (evt?.target && card.category === 'Blank') {
                                                        //     console.log("target")
                                                        //     // this.receiveTargetTextfield(evt.target)
                                                        //     this.setState({
                                                        //         textfieldTarget: evt.target
                                                        //     })
                                                        //     EE.emit('Canvas Defocus')
                                                        //   }
                                                    }}
                                                    onChange={(e)=>{editable && handleChangePlayerHand(e,index)}}
                                                    onBlur={(e)=>{
                                                            if(this.state.editorSub == null){
                                                                //socket.emit("Game Object Parse Links", {selectedIDs: [this.props.obj.libID]})
                                                                checkLink(index)
                                                                this.setState({
                                                                    readonly: true,
                                                                    editorMenu: false,
                                                                    content: sanitizeHtml(this.state.content, this.sanitizeConf),

                                                                })
                                                            }
                                                        }
                                                    }
                                                    onPaste={(event)=>{
                                                        if(event.clipboardData.types.includes('Files')){
                                                            onImgPaste(event,isPlayerHand ? index : card.libID)
                                                            this.setState({
                                                                readonly: true,
                                                                editorMenu: false,
                                                            })
                                                        }}}
                                                    
                                                    disabled={imgUploaded}
                                                />
                                        }

                                    </div>
                                )}
                            </Dropzone>

                        </Grid>
                        <Grid container direction="row" justify="center" alignItems="center"  style={{height:"20px"}}>
                            <Grid item xs={9}>
                                <Tooltip title={
                                    label.map((tag, index)=>(
                                        index !== label.length - 1 ?
                                        tag + ", " : tag
                                    ))}
                                    disableFocusListener={label.length > 0 ? false : true}
                                    disableTouchListener={label.length > 0 ? false : true}
                                    disableHoverListener={label.length > 0 ? false : true}>
                                    <div className={classes.tag}>
                                        <LocalOfferOutlined style={{marginRight:"5px", fontSize:"10px"}}/>
                                        {label.length}
                                    </div>
                                </Tooltip>

                                <p id="words"
                                    className={classes.counterShow}>
                                    {card.imgUrl ? 0 : 150 - stringText.length}
                                </p>
                            </Grid>
                            <Grid item xs={3}>
                            </Grid>
                        </Grid>
                    </Card>
                    {/* THE FRONT OF THE CARD (CARD COVER) */}
                    <Card
                        style={ card.category === "Blank" ? {background: "#fff"}
                                :
                                    card.category == null ? {background:"#c4c4c4"}
                                    :
                                    {background: categoryColor ? categoryColor : "#c4c4c4"}
                                }
                            className= {card.flip && hasMenu ? classes.cardCoverFlip : classes.cardCover}>
                        <Grid container direction="row" justify="center" alignItems="center">
                            <Grid container direction="row" justify="center" alignItems="center">
                                <Grid item xs={3}>

                                </Grid>
                                <Grid item xs={6}  className={classes.cardCoverCategory}
                                    style={
                                        card.category == null ?
                                            {color: "white"}
                                        :
                                            {color: color}
                                    }>
                                    {card.category == null ?
                                        "No category" :
                                        card.category}
                                </Grid>
                                <Grid item xs={3} >
                                    {hasMenu &&
                                        <IconButton className={classes.iconRight} style={{color:"#C4C4C4"}} onClick={(e) =>this.openMenu(e)}>
                                            <MoreVert style={card.category == null || !categoryColor || card.category!=="Blank" ? {color: "white", fontSize:"16px"} : {color:"#c4c4c4", fontSize:"16px"}}/>
                                        </IconButton>
                                    }

                                </Grid>
                            </Grid>
                            <Grid container direction="row" justify="center" alignItems="center" className={classes.cardCoverIconWrapper}>
                                <Grid item xs={12}>
                                    {card.category !== "Blank" ?
                                        <Avatar style={{width:"80px", height:"80px", margin:"auto", background: "transparent"}}>
                                            <Eco style={
                                                card.category == null ?
                                                    {fontSize:"70px", color: "#fff"}
                                                :
                                                    {fontSize:"70px", color: color}
                                                }/>
                                        </Avatar>

                                    :
                                        <Avatar style={{width:"80px", height:"80px", margin:"auto", background: iconBg}}>
                                            <Create style={{fontSize:"30px", color: background}}/>
                                        </Avatar>
                                    }

                                </Grid>
                            </Grid>
                            <Grid item xs={12} className={classes.cardCoverInfo}
                                style={
                                    card.category == null ?
                                        {color: "white"}
                                    :
                                        {color: color}
                                }>
                                <p className={classes.username}>{username}</p>
                                {editTime}
                                {listPinIds?.includes(card.libID) && hasPin && <Room className={classes.pin} style={{fontSize:"10px"}}/>}
                            </Grid>
                        </Grid>
                    </Card>
                        <Menu
                        id="simple-menu"
                        anchorEl={this.state.anchorEl}
                        PaperProps={{style: { fontFamily:"Montserrat, sans-serif", color:"#4F4F4F", border:"0.5px solid #c4c4c4"}}}
                        keepMounted
                        open={Boolean(this.state.anchorEl)}
                        onClose={this.closeMenu}
                    >
                        {hasImage &&
                            <MenuItem className={classes.menuItem} component="label" disabled={Boolean(card.content)}>
                                <ImageOutlined className={classes.menuIcon}/>
                                Upload Image
                                <input
                                    onChange={(event)=>{onImgUpload(event, isPlayerHand ? index : card.libID); this.closeMenu()}}
                                    type="file"
                                    hidden
                                />
                            </MenuItem>
                        }
                        {hasFlip && // flip to appear on menu with card meta
                            <MenuItem
                                className={classes.menuItem}
                                onClick={()=>{this.props.flipCard(index); this.closeMenu()}}
                            >
                                <Repeat className={classes.menuIcon}/>
                                Flip
                            </MenuItem>
                        }
                        {hasPin &&
                            <MenuItem
                                className={classes.menuItem}
                                onClick={()=>{
                                    //can be found in gameCanvas
                                    this.props.pinRightPanel(card.objId, card.libID);
                                    this.closeMenu()}}
                            >
                                <RoomOutlined className={classes.menuIcon}/>
                                {listPinIds?.includes(card.libID) ? "Unpin" : "Pin"}
                            </MenuItem>
                        }
                        { 
                        <Tooltip title="Long press for 2 seconds to delete">
                            <MenuItem
                                className={classes.menuItem}
                                //disabled={!removable}
                                onMouseDown={() => {
                                    /*this.cardFeature("Delete")*/ 
                                    this.deleteTimer = window.setTimeout(()=>{
                                            // console.log("REMOVEING")
                                            this.props.removeCards(index)
                                            this.closeMenu()
                                    },2000)
                                }}
                                onMouseUp={() => {window.clearTimeout(this.deleteTimer)}}
                            >
                                <DeleteForeverOutlined className={classes.menuIcon} style = {{color:"red"}} />
                                Delete Card
                            </MenuItem>
                    </Tooltip> }
                    </Menu>
                </Card>
        )
    }

    openMenu = (e) => {
        this.setState({
            anchorEl: e.currentTarget
        })
    }

    closeMenu = () => {
        this.setState({
            anchorEl: null
        })
    }

    onKeyDown = (e) =>{
        if (e.keyCode === 13 && !e.shiftKey)
        {
            // prevent default behavior
            e.preventDefault();
        }
    }


    editableSubMenu = () => {

        const {objId} = this.props.card
        const {classes} = this.props

        switch(this.state.editorSub){  // editor sub menu
            case "list":
                return(
                    <div
                        style={{
                            position: "absolute",
                            top: "35px",
                            left: "40px",
                            backgroundColor: "white",
                            borderRadius: "5px",
                            padding: "10px",
                            boxShadow: "1px 1px #c4c4c4",

                        }}
                        id="inhand-editable-sub"
                    >
                            <div>
                                <FormatListBulleted onMouseDown={(e)=>{e.preventDefault(); document.execCommand("insertUnorderedList",false,"insertOrderedList");  this.setState({editorSub: null});}}  className={classes.menuIcon}/>
                                <FormatListNumbered onMouseDown={(e)=>{e.preventDefault(); document.execCommand("insertOrderedList",false,"insertOrderedList"); this.setState({editorSub: null});}}  className={classes.menuIcon}/>
                            </div>
                    </div>
                )
            case "format":
                return(
                    <div
                        style={{
                            position: "absolute",
                            top: "35px",
                            left: "40px",
                            backgroundColor: "white",
                            borderRadius: "5px",
                            padding: "10px",
                            boxShadow: "1px 1px #c4c4c4",

                        }}
                        id="inhand-editable-sub"
                    >
                            <div>
                                <FormatBold onMouseDown={(e)=>{e.preventDefault(); document.execCommand("bold",false,"bold");  this.setState({editorSub: null});}}  className={classes.menuIcon}/>
                                <FormatItalic onMouseDown={(e)=>{e.preventDefault(); document.execCommand("italic",false,"italic"); this.setState({editorSub: null});}}  className={classes.menuIcon}/>
                                <FormatUnderlined onMouseDown={(e)=>{e.preventDefault(); document.execCommand("underline",false,"underline"); this.setState({editorSub: null});}}  className={classes.menuIcon}/>
                                <StrikethroughS onMouseDown={(e)=>{e.preventDefault(); document.execCommand("strikeThrough",false,"strikeThrough"); this.setState({editorSub: null});}}  className={classes.menuIcon}/>
                            </div>
                    </div>
                )
            case "link":
                return(
                    <div
                        style={{
                            position: "absolute",
                            top: "35px",
                            left: "10px",
                            backgroundColor: "white",
                            borderRadius: "5px",
                            padding: "10px",
                            boxShadow: "1px 1px #c4c4c4",

                        }}
                        id="inhand-editable-sub"
                    >
                            <div style={{display:"flex"}}>
                                <input ref={this.hyperlinkRef} style={{width:"120px"}} type="text" placeholder='Paste a link' id={`inhand-editable-link-input-${objId}`}/>
                                <button
                                    style={{fontSize:"10px", marginLeft:"5px","borderRadius":"3px",fontWeight:"bold"}}
                                    onMouseDown={(e)=>{
                                        e.preventDefault();
                                        this.restoreSelection();
                                        const x = document.getElementById(`inhand-editable-link-input-${objId}`).value
                                        //console.log(x)
                                        if (x) {
                                            document.execCommand('createLink', false, x);
                                          }
                                        this.setState({
                                            editorSub: null,
                                        })
                                    }}

                                >Apply</button>
                            </div>
                    </div>
                )
            default:
                break;
        }
    }

    saveSelection = () => {
        if (window.getSelection) {
            const sel = window.getSelection();
            console.log(sel)
            if (sel && sel.getRangeAt && sel.rangeCount) {
             // console.log("RANGE")
              const ranges = [];
              for (let i = 0, len = sel.rangeCount; i < len; ++i) {
                ranges.push(sel.getRangeAt(i));
              }
              this.setState({ textSelection: ranges });
            }
          } else if (document.selection && document.selection.createRange) {
            //console.log("Docs")
            this.setState({ textSelection: document.selection.createRange() });
          }
    }

    restoreSelection = () => {
        let savedSel = this.state.textSelection;
        //console.log(savedSel)
        if (savedSel) {
            if (window.getSelection) {
                let sel = window.getSelection();
                sel.removeAllRanges();
                for (var i = 0, len = savedSel.length; i < len; ++i) {
                    sel.addRange(savedSel[i]);
                }
            } else if (document.selection && savedSel.select) {
                savedSel.select();
            }
        }
    }
}



export default withStyles(styles)(HandCard)
