export const voteStyle = {
    panel: {
        position:"relative",
        width:"100%",
        height:"calc(100% - 20px)",
        padding:"0 1em",
        fontFamily:"Arial",
    },

    listPanel:{
        padding:"0px 18px 18px 18px",
        position:"absolute"
    },

    listTitle:{
        cursor:"pointer", 
        margin:"0", 
        color:"#4F4F4F", 
        fontWeight:"bold", 
        fontSize:"14px",
        opacity:"1",
        transition:"0.5s",
        "&:hover":{
            opacity:"0.5"
        }
    },

    listOwner:{
        color:"#888888", 
        margin:"0", 
        fontSize:"12px"
    },

    listStatus:{
        color:"#888888", 
        margin:"0",
        fontSize:"10px", 
        marginBottom:"5px"
    },

    sessionPanel:{
        background:"#E1E1E1",
        position:"absolute", 
        display:"block",
        height:"calc(100% - 4rem)",
        left:"250px",
        overflow:"auto"
    },

    sessionHeader:{
        fontSize:"16px"
    },

    back:{
        fontSize:"18px",
    },

    sessionStartBtn:{
        boxShadow:"none", 
        display:"block", 
        width:"150px", 
        margin:"auto", 
        background:"#4173D6", 
        color:"white", 
        borderRadius:"5px",

        "&:hover":{
            boxShadow:"none"
        }
    },

    sessionTimer:{
        position:"relative", 
        width:"150px", 
        margin:"auto"
    },

    sessionTimerText:{
        color:"white", 
        textAlign:"center", 
        position:"absolute", 
        padding:"6px 0", 
        zIndex:"2", 
        width:"100%"
    },

    sessionTimerBar:{
        height:"30px", 
        borderRadius:"5px", 
        position:"relative", 
        zIndex:"1"
    },

    timerbar:{
        background:"#FFAA0D"
    },

    timerbarbg:{
        background:"#CACACA"
    },

    sessionCards:{
        position:"relative"
    },

    voteBtn:{
        background:"white", 
        borderRadius:"5px", 
        position:"absolute", 
        bottom:"15px", 
        right:"50px", 
        zIndex:"5"
    }
}