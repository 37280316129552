const styles = {
    canvasWrapper:{
        height: "100%",
        overflow: "hidden",
        position: "relative",
        backgroundColor:"#F2F2F2",
    },

    title:{
        position: 'absolute',
        top: '25px',
        left: '25px',
        zIndex: '5',
        opacity: '0.7',
        fontWeight: 'bold',
    },

    consoleBox: {
        position: 'absolute',
        top: '70px',
        left: '20px',
        zIndex: '5',
        padding: "1em",
        display: 'flex',
    },

    consoleActionBtn : {
        marginLeft: ".5rem",
        padding: ".5em",
        borderRadius: "10px",
    },

    canvasCursor:{
        height: "5000px", 
        width:"5000px", 
        cursor:"default",
    },

    canvasHand:{
        height: "5000px", 
        width:"5000px", 
        cursor:"grab",
        cursor: "-moz-grab",
        cursor: "-webkit-grab",
        "&:active":{
            cursor: "grabbing",
            cursor: "-moz-grabbing",
            cursor: "-webkit-grabbing"
        },
    },

    containerCursor:{
        height: "5000px",
        width: "6000px",
        zIndex: 0,
        //background:"blue", 
        //opacity:"0.5",
        overflowX: "hidden",
        overflowY: "hidden",
    },

    containerHand:{
        height: "5000px",
        width: "6000px",
        zIndex: 0,
        overflowX: "hidden",
        overflowY: "hidden",
        // background:"blue", 
        // opacity:"0.5",
        cursor:"grab",
        cursor: "-moz-grab",
        cursor: "-webkit-grab",
        "&:active":{
            cursor: "grabbing",
            cursor: "-moz-grabbing",
            cursor: "-webkit-grabbing"
        },
    },

    canvasBtn:{
        zIndex:"1",
        borderRadius:"none",
        background:"white",
        '&:focus':{
            outline:"none"
        }
    },

    canvasBtnWrapper:{
        position:"absolute",
        bottom:"10px", 
        right:"10px",
        boxShadow:"0px 0px 3px #d1d1d1",
    },

    roomTitleWrapper:{
        position:"fixed",
        height:"3rem",
        zIndex:"1",
        transition:"0.5s",
        background:"#C4C4C4"
    },

    panelOpen:{
        width:"calc(100% - 308px)", 
    },

    panelClose:{
        width:"100%", 
    },

    roomTitle:{
        position: "relative",
        left: "1rem",
    },
    
    roomTitleName:{
        display:"inline-block",
        color: "#4F4F4F",        
        fontWeight: "bold",
        fontSize: "20px",
    },

    hostTitle:{
        float:"right",
        textAlign:"right",
        marginRight:"1em",
        marginLeft:"1em",
        marginTop:"1.2em",
        color: "#4F4F4F",
        fontSize: "12px"
    },

    automationTitle:{
        display:"inline-block",
        borderRadius:"4px",
        marginLeft:"1rem",
        verticalAlign:"2px",
        padding:"0.3rem 0.5rem 0.3rem 0.5rem",
        color: "white",
        fontSize: "10px",
        backgroundColor: "#E97610",
        position:"relative",
        width:"fit-content"
    },

    iconTopPanel:{
        color:"#fff",
        transition:"0.5s", 
        '&:hover':{
            color:"#4f4f4f"
        },
        '&:focus':{
          outline:"none"
        }
    },

    secondaryBtn:{
        background:"#FFF", 
        padding:"2px", 
        fontWeight:"bold", 
        marginRight:"0.5em", 
        color:"#FA841B", 
        fontSize:"10px",
        border:"1px solid #FA841B",
        outline:"none",
        '&:focus':{
            outline:"none",
        }
    },

    primaryBtn:{
        background:"#FA841B", 
        fontWeight:"bold", 
        padding:"3px", 
        fontSize:"10px",
        color:"white",
        outline:"none",
        '&:focus':{
            outline:"none",
        }
    },

    popperTitle:{
        color:"#4F4F4F", 
        fontWeight:"bold", 
        fontSize:"14px", 
        margin:"0"
    },

    popper:{
        background:"#FAF9FA", 
        color:"#4F4F4F", 
        padding:"2rem", 
        borderRadius:"5px", 
        boxShadow:"0px 4px 4px #c4c4c4", 
        transition:"0.5s"
    },

    popperTriangle:{
        position:"absolute", 
        color:"#FAF9FA", 
        fontSize:"40px", 
        top:"-20px", 
        right:"3px"
    },

    popperBtns:{
        position:"absolute", 
        top:"2rem", 
        right:"2rem"
    },

    timerLabels:{
        display:"inline-block", 
        margin:"10px 0 0 0", 
        width:"70px", 
        textAlign:"center", 
        fontSize:"12px",
        color:"#C4C4C4"
    },

    field:{
        width:"70px", 
        background: "#FFF", 
        border:"0.5px solid #C4C4C4"
    },

    fieldProp:{
        color: "#4F4F4F", 
        fontSize:"24px", 
        fontFamily:"Arial",
        fontWeight:"bold",
    },

    timerDisplay:{
        margin:"10px 0.5em 0 0.5em", 
        padding:"7px 10px", 
        borderRadius:"5px", 
        display:"inline-block", 
        textAlign:"center", 
        background:"#A9A9A9", 
        color:"#fff"
    },

    timerDisplayOn:{
        margin:"10px 0.5em 0 0.5em", 
        padding:"7px 10px", 
        borderRadius:"5px", 
        display:"inline-block", 
        textAlign:"center", 
        background:"#FA841B", 
        color:"#fff"
    },

    toast:{
        background:"#494949",
        color:"white !important",
        
        overflowWrap: "break-word",
        wordWrap: "break-word",
        width:"330px"
    },

    toastBar:{
        background:"#53CAA6"
    },
    
    zoomBtn:{
        margin:"0", 
        border:"1px solid #4F4F4F", 
        minWidth:"0", 
        marginTop:"-1.7px", 
        padding:"8.8px",
        '&:focus':{
            outline:"none", 
        }
    },

    zoomIcon:{
        fontSize:"14px", 
        color:"#4F4F4F"
    },

    zoomText:{
        display:"inline-block", 
        fontSize:"12px", 
        color:"#4F4F4F", 
        textTransform:"none", 
        marginTop:"1px", 
        padding:"6.9px", 
        textAlign:"center", 
        minWidth:"60px", 
        fontFamily:"Arial", 
        margin:"0", 
        borderTop:"1px solid #4F4F4F", 
        borderBottom:"1px solid #4F4F4F",
        lineHeight: "1.5"
    },

    specField:{
        display:"block",
        background:"#FAF7EA",
        border:"1px solid #d1d1d1",
        borderRadius:"5px",
        margin:"0.5em 0 1em 0",
        padding:"0.5em 1em"
    },

    specModal:{
        width: "20rem",
        background: "#FAF9F9",
        border: '1px solid #d1d1d1',
        fontFamily:"arial",
        padding:"0",
        borderRadius:"5px",
        margin:"auto", 
        marginTop:"15%", 
        outline:'none'
    },
    specModalHeader:{
        margin:"1.2rem",
        fontWeight:"bold",
        fontSize:"1.2rem",
        color:"#4F4F4F",
        textAlign:"center"
    },

    specPopUpBtn:{
        display:"block",
        background:"#FDAF2A",
        color:"#fff",
        borderRadius:"5px",
        width:"100%",
        padding:"0.5rem 0.5rem",
        marginTop:"0.5rem",
        fontSize: "0.875rem",
        fontWeight:"bold"
    },

    specPopUpBtnDisabled:{
        display:"block",
        background:"#D1D1D1",
        color:"#fff",
        borderRadius:"5px",
        width:"100%",
        padding:"0.5rem 0.5rem",
        marginTop:"0.5rem",
        fontSize: "0.875rem",
        fontWeight:"bold"
    },

    specModalContent:{
        margin:"1.8rem",
    },

    outputModal:{
        width: "900px",
        height: "550px",
        overflow:"auto",
        background: "#FAF9F9",
        border: '1px solid #d1d1d1',
        fontFamily:"arial",
        padding:"2em",
        borderRadius:"5px",
        margin:"auto", 
        marginTop:"15%", 
        outline:'none'
    },

    outputModalHeader:{
        margin:"1.2rem",
        fontWeight:"bold",
        fontSize:"1.2rem",
        color:"#4F4F4F",
        textAlign:"center"
    },

    outputModalMenu:{
    }

}

export default styles