import React from 'react'
import {withStyles, Button, IconButton, LinearProgress} from '@material-ui/core'
import {KeyboardBackspace} from '@material-ui/icons';
import '../../../app/app.css'
import socket from '../../../api/socket';
import EE from '../../../api/eventemitter'
// import BlankCard from '../objects/inhand/blankCard'
// import StoryCard from '../objects/inhand/storyCard';
import HandCard from '../objects/hand/card';
import {EventManager} from '../helper/gameCanvasHelper'
import {voteStyle} from '../user_interface/assets/voteUI'
import {getUserId} from '../../../utility/function'
import { ceil } from 'lodash';

class GameVoteSession extends React.Component{
    constructor(props){
        super(props)
        this.session = React.createRef();
        this.state = {
            sessionID: this.props.sessionID,
            voteStart: false,
            cards: this.props.vote.cards,
            title: this.props.vote.title,
            owner: this.props.vote.owner,
            status: this.props.vote.status,
            progress: this.props.vote.progress,
            voted: this.props.vote.voted,
            playerList: this.props.vote.playerList,
            interruptorId: this.props.vote.interruptorId,
            receiverId: this.props.vote.receiverId,
            operation: this.props.vote.operation,
            condition: this.props.vote.condition,
            successEvent: this.props.vote.successEvent,
            failEvent: this.props.vote.failEvent,
            nextEvent: this.props.vote.nextEvent
        }

        this.timer = []
        this.time = null
    }
    componentDidMount() {
        //Called from gameVoteSession
        EE.on("Vote Button Click", (data) =>{
            const {sessionID} = data

            if(sessionID === this.state.sessionID){
                this.startTimer()
                socket.emit("Vote Button Click", {sessionID: this.state.sessionID})
            }
        })

        socket.on("Vote Button Click", (data) =>{
            const {sessionID} = data

            if(sessionID === this.state.sessionID){
                this.startTimer()
            }
        })

        //Called from gameVoteSession
        socket.on("Vote Sync", data =>{
            const {cards, voted, sessionID} = data

            if(sessionID === this.state.sessionID){
                this.setState({
                    cards: cards,
                    voted: voted
                }, () =>{
                    this.props.updateCards(this.state.cards, this.state.sessionID)
                    this.props.updateVoted(this.state.voted, this.state.sessionID)
                });
            }
        })

        //Called from gameVote
        socket.on("Vote Ends", (data) =>{
            const {sessionID} = data

            if(sessionID === this.state.sessionID){
                this.props.updateStatus(this.state.status, this.state.sessionID)
                document.getElementById(this.state.sessionID + "timerText").innerHTML="VOTE HAS ENDED"
                clearInterval(this.timer[sessionID]);
            }
        })

        //Called from gameVoteSession when timer runs to 0
        socket.on("End Vote Session Progress", (data) =>{
            const {sessionID} = data

            if(sessionID === this.state.sessionID){
                this.setState({
                    progress: 0
                }, () =>{
                    clearInterval(this.timer[this.state.sessionID])
                    this.props.updateProgress(this.state.progress, this.state.sessionID)
                });
            }
        })
    }

    componentDidUpdate(prevProps, prevState){
        //This piece of code will mess the the vote count state of the card whenever upvoting
        if(prevState.cards !== this.props.vote.cards){
            this.setState({
                cards: this.props.vote.cards
            });
        }
        if(prevState.sessionID !== this.props.sessionID){
            this.setState({
                sessionID: this.props.sessionID
            });
        }
        if(prevState.voted !== this.props.vote.voted){
            this.setState({
                voted: this.props.vote.voted
            });
        }
        if(prevState.status !== this.props.vote.status){
            this.setState({
                status: this.props.vote.status
            });
        }
    }

    render(){
        const {classes} = this.props

        let {openMenu, closeMenu, flipCard, onClick, setOnDrag} = this
        let func = {
            openMenu, closeMenu, flipCard, onClick, setOnDrag
        }

        return(
            <>
                <p className={classes.sessionHeader}>
                    <IconButton style={{marginRight:"5px", outline:"none", "&:focus":{outline:"none"}}}>
                        <KeyboardBackspace className={classes.back} onClick={() => this.props.closeVote()}/>
                    </IconButton>

                    {this.state.title}
                </p>
                <Button
                    id={this.state.sessionID + "voteBtn"}
                    variant="contained"
                    onClick={() =>{
                        socket.emit("Vote Button Click", {sessionID: this.state.sessionID})
                        this.startTimer()
                    }}
                    className={classes.sessionStartBtn}
                    style={{
                        display: (this.state.voteStart || this.state.status === "Completed") && "none"
                    }}
                >
                    Start 30:00
                </Button>
                <div
                    id= {this.state.sessionID + "timer"}
                    className={classes.sessionTimer}
                    style={{
                        display: (this.state.voteStart || this.state.status === "Completed") ? "block" : "none"
                    }}
                >
                    <p id={this.state.sessionID + "timerText"} className={classes.sessionTimerText}>
                        {this.state.status === "Completed" ? "VOTE HAS ENDED" : "VOTE NOW"}
                    </p>
                    <LinearProgress
                        variant="determinate"
                        className={classes.sessionTimerBar}
                        classes={{colorPrimary: classes.timerbarbg, barColorPrimary: classes.timerbar}}
                        value={this.state.progress}
                    />
                </div>
                <br/>
                {this.state.cards && this.state.cards.map((card,index)=>{
                    return (
                        <div className={classes.sessionCards}>
                            <Button
                                disabled={(this.state.voted.includes(getUserId())) || this.state.status === "Completed" || !this.state.voteStart}
                                onClick={() => {this.upVote(index)}}
                                className={classes.voteBtn}
                            >
                                <span role="img" aria-label="up-vote">👍</span> {card.vote}
                            </Button>
                            <HandCard
                                key={index}
                                {...func}
                                index={index}
                                card={card}
                                hasFlip={false}
                                hasPin={false}
                                hasImage={false}
                                hasMenu={false}
                                listPinIds={this.props.listPinIds}
                                categoryColor={this.props.categoryColorMap.get(card.category)}
                                imgData = {this.props.imgData}
                                onImgUpload = {this.props.onImgUpload}
                                onImgPaste = {this.props.onImgPaste}
                                removeImg = {this.props.removeImg}
                                onDrop = {this.props.onDrop}
                            />
                        </div>
                    );
                    }
                )}
            </>
        )
    }

    onClick = () => {}

    openMenu = () => {}

    closeMenu = () => {}

    flipCard = () => {}

    setOnDrag = () => {}

    upVote = (index) => {
        let newCards = this.state.cards
        let newVoted = this.state.voted
        newCards[index].vote +=1

        if(!this.state.voted.includes(getUserId())){
            newVoted.push(getUserId())
        }

        this.setState({
            cards: newCards,
            voted: newVoted
        }, () =>{
            //Emits to gameVoteSession
            socket.emit('Vote Sync', {cards: this.state.cards, voted: this.state.voted, sessionID: this.state.sessionID})
            if(this.state.successEvent || this.state.failEvent){
                this.checkVoteCount(index)
            }
        });
    }

    startTimer = () =>{
        // const sID = this.state.sessionOpen
        this.setState({
            voteStart: true
        }, ()=>{
            EE.emit("Vote Starts", {voteStart: true, sessionID: this.state.sessionID})
        })
        this.time = new Date().getTime()

        let duration = 30 //seconds
        let interval = duration * 10 //converted to milleseconds
        let progressDiff = 100 / duration

        this.timer[this.state.sessionID] = setInterval(() => {
            if(this.state.progress <= 0){
                this.setState({
                    voteStart: false,
                    status: "Completed"
                },()=>{
                    if(this.state.successEvent || this.state.failEvent){
                        EventManager.eventPathWay(this.state.failEvent)
                        EventManager.eventPathWay(this.state.nextEvent)
                    }

                    //Emits to gameVoteSession
                    socket.emit("End Vote Session Progress", {sessionID: this.state.sessionID})
                    //Emits to gameVote
                    EE.emit("Vote Ends", {voteStart: false, sessionID: this.state.sessionID})

                    this.props.updateStatus(this.state.status, this.state.sessionID)
                    clearInterval(this.timer[this.state.sessionID]);
                })
            }
            else{
                let timeDifference = (new Date().getTime() - this.time) / 1000
                this.time = new Date().getTime()

                this.setState(prev => ({
                    progress: prev.progress - (progressDiff * timeDifference)
                }), () =>{
                    this.props.updateProgress(this.state.progress, this.state.sessionID)
                })
            }
        }, interval);
    }

    checkVoteCount = (index) =>{
        switch(this.state.condition){
            case "Half Players":
                let noPlayersAbleToVote = ceil((this.state.playerList.length - 1)/2)

                if(EventManager.compOperation(this.state.operation, this.state.cards[index].vote, noPlayersAbleToVote)){
                    this.setState({
                        voteStart: false,
                        status: "Completed"
                    },()=>{
                        EventManager.eventPathWay(this.state.successEvent)
                        EventManager.eventPathWay(this.state.nextEvent)

                        //Emits to gameVote
                        EE.emit("Vote Ends", {voteStart: false, sessionID: this.state.sessionID})

                        this.props.updateStatus(this.state.status, this.state.sessionID)
                        clearInterval(this.timer[this.state.sessionID]);
                    })
                }
                break
            case "Total Players":
                if(EventManager.compOperation(this.state.operation, this.state.cards[index].vote, this.state.playerList.length)){
                    this.setState({
                        voteStart: false,
                        status: "Completed"
                    },()=>{
                        EventManager.eventPathWay(this.state.successEvent)
                        EventManager.eventPathWay(this.state.nextEvent)

                        //Emits to gameVote
                        EE.emit("Vote Ends", {voteStart: false, sessionID: this.state.sessionID})

                        this.props.updateStatus(this.state.status, this.state.sessionID)
                        clearInterval(this.timer[this.state.sessionID]);
                    })
                }
                break
            default:
                if(EventManager.compOperation(this.state.operation, this.state.cards[index].vote, this.state.condition)){
                    this.setState({
                        voteStart: false,
                        status: "Completed"
                    },()=>{
                        EventManager.eventPathWay(this.state.successEvent)
                        EventManager.eventPathWay(this.state.nextEvent)

                        //Emits to gameVote
                        EE.emit("Vote Ends", {voteStart: false, sessionID: this.state.sessionID})

                        this.props.updateStatus(this.state.status, this.state.sessionID)
                        clearInterval(this.timer[this.state.sessionID]);
                    })
                }
                break
        }
    }

}

export default withStyles(voteStyle)(GameVoteSession)
