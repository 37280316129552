//Node modules
import React from 'react';
import {MuiThemeProvider, Button, withStyles, Card, Avatar,Menu, MenuItem, IconButton, Tooltip} from '@material-ui/core';
import {MoreVert, Create, Launch, FilterFrames} from '@material-ui/icons';
//Other modules
import {theme} from '../user_interface/assets/constants'
import EE from '../../../api/eventemitter'

const styles = {
    deck:{
        position:"fixed",
        width: "163px", 
        height:"240px", 
        zIndex: "1",
        top:"150px",
        transition:"0.5s"
    },

    onClose:{
        right:"55px",
    },
    onOpen:{
        right:"310px",
    },

    card: {
        position:"relative",
        width: "143px",
        height: "213px",
        fontFamily:"Montserrat, sans-serif",
        padding:"10px",
        display:"flex",
        border:"0.5px solid #c4c4c4",
        flexDirection:"column",
        boxShadow:"4px 4px 4px rgb(0,0,0,0.1)"
    },

    category:{
        color:"#4F4F4F",
        position:"absolute",
        width:"calc(100% - 20px)",
        textAlign:"center",
        fontWeight:"bold",
        fontSize:"14px",
        margin:"10px 0 0 0"
    },

    iconBg:{
        background:"#FFAA0D",
        width:"75px",
        height:"75px",
        margin:"auto"
    },

    icon:{
        color:"white",
        fontSize:"28px",
    },
    
    menu:{
        color:"#C4C4C4",
        fontSize:"13px",
    },
    menuBtn:{
        width:"25px", 
        height:"25px", 
        position:"absolute", 
        right:"10px", 
        top:"15px", 
        zIndex:"1",
        '&:focus':{
            outline:"none"
        }
    }, 
    menuIcon:{
        fontSize:"14px",
        marginRight:"10px"
    },
    menuItem:{
        fontSize:"14px",
        transition:"0.5s",
        '&:hover':{
            background:"#FFF3B4"
        }
    },

    disableHighlight:{
        "-webkit-touch-callout": "none",
        "-webkit-user-select": "none",
        "-khtml-user-select": "none",
        "-moz-user-select": "none",
        "-ms-user-select": "none",
        "user-select": "none",
    },

    disabled:{
        cursor:"not-allowed"
    }
}

class BlankCard extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            anchorEl: null,
            inTurn: false
        };
    }

    render(){
        const {classes, panelOpen, clearMultiSelect} = this.props;
        const pullableCanvas = this.props.permissionsConfig?.["cardAddition"]?.["Blank"]?.["canvas"] ?? this.props.permissionsConfig?.["cardAddition"]?.["Any"]?.["canvas"] ?? false
        const pullableInHand = this.props.permissionsConfig?.["cardAddition"]?.["Blank"]?.["inHand"] ?? this.props.permissionsConfig?.["cardAddition"]?.["Any"]?.["inHand"] ?? false

        return (
            <MuiThemeProvider theme={theme}>
                <div className={`${classes.deck} ${panelOpen === true ? classes.onOpen : classes.onClose}`}>
                    {this.createDeck(8, classes, pullableCanvas)}
                    <Tooltip
                        title={!pullableCanvas && "You cannot draw a new card"}
                        disableHoverListener={pullableCanvas && true}
                        disableFocusListener={pullableCanvas && true}
                        disableTouchListener={pullableCanvas && true}
                        placement="bottom"
                    >
                        <Card
                            draggable={pullableCanvas.toString()}
                            onDragStart={()=>{
                                clearMultiSelect()
                                /*Emits to gameCanvas*/ 
                                EE.emit("Canvas Blank Card Dragging")
                            }}
                            className={`${classes.card} ${!pullableCanvas ? classes.disabled : ""}`}
                        >
                            
                            <h1 className={`${classes.category} ${classes.disableHighlight}`} >Draw Card</h1>
                            <Avatar className={classes.iconBg}><Create className={classes.icon}/></Avatar>
                            <Button 
                                onClick={this.optionMenu("Add")}
                                endIcon={<Launch style={{fontSize:"14px"}}/>} 
                                style={{textTransform:"none", color:"#A9A9A9", outline:"none"}}>
                                Add to Hand
                            </Button>
                        </Card>
                    </Tooltip>
                </div>      

            </MuiThemeProvider>
          );
    }  

    openMenu = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    optionMenu = (option) => (event) =>{
        if(option === "Add"){
            //Emits to gamePlayerHand
            EE.emit("IHL Blank Card")
            this.setState({ anchorEl: null })
        }
    }

    closeMenu = () => {
     this.setState({ anchorEl: null });
    };
    
    createDeck = (j,classes, pullableCanvas) => {
        var deck = []

        for(var i=0;i<j;i++){
            deck.push(
                <Tooltip key={i} 
                    title={!pullableCanvas && "You cannot draw a new card"}
                    disableHoverListener={pullableCanvas && true}
                    disableFocusListener={pullableCanvas && true}
                    disableTouchListener={pullableCanvas && true}
                    placement="bottom"
                >
                    <Card
                        draggable={pullableCanvas.toString()}
                        style={{marginBottom:"-215px",}}
                        onDragEnd={()=>{EE.emit("Canvas Blank Card Stop")}} 
                        onDragStart={()=>{
                            this.props.clearMultiSelect()
                            EE.emit("Canvas Blank Card Dragging")
                        }} 
                        className={`${classes.card} ${!pullableCanvas ? classes.disabled : ""}`}
                    >
                        <h1 className={`${classes.category} ${classes.disableHighlight}`}>Blank</h1>
                        <Avatar className={classes.iconBg}><Create className={classes.icon}/></Avatar>
                    </Card>
                </Tooltip>
                );
        }
        return(deck);
    }
}

export default withStyles(styles)(BlankCard);