import React from 'react'
import {List, ListItem, Collapse,
        IconButton, Grid, Link, withStyles} from '@material-ui/core'
import {ArrowDropDownRounded, ArrowDropUpRounded} from '@material-ui/icons';
import GameLibraryInfo from './gameLibraryInfo'
import {isEmpty, ctrl_cmd_mapper} from '../../../../../utility/function'


const styles = {
    addCard:{
        cursor:"pointer"
    },

    all:{
        margin:"0",
        fontSize:"14px",
        fontWeight:"bold",
        color:"#4f4f4f"
    },

    category:{
        position:"relative",
        margin:"0",
        fontSize:"14px",
        fontWeight:"bold",
        color:"#fff",
        borderRadius:"5px",
        width:"fit-content",
        padding:"5px 10px",
        background:"#686868",
        zIndex:"2"
    },



    categoryCount:{
        float:"right",
        borderRadius:"5px",
        fontSize:"14px",
        padding:"0 10px",
        background:"#C4C4C4",
        color:"#4f4f4f",
        margin:"0"
    },

    expandBtn:{
        position:"relative",
        border:"1px solid #828282",
        background:"#E1E1E1",
        width:"10px",
        marginRight:"5px",
        height:"10px",
        color:"#828282",
        '&:focus':{
            outline:"none"
        },
        '&:hover':{
            background:"#E1E1E1",
        },
        zIndex:"2"
    },

}

class GameLibrary extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            //Will contain the card categories as a state
        }
    }

    componentDidMount(){
        document.addEventListener("keydown", this.libraryShortcut.bind(this))
    }

    componentWillUnmount(){
        document.removeEventListener("keydown", this.libraryShortcut.bind(this))
    }

    libraryShortcut = (e) => {
        if(e.key === "d" && ctrl_cmd_mapper(e) && !isEmpty(this.props.selectedIDs)){
            e.preventDefault()
            this.props.duplicateLibrary();
        }
    }

    render(){
        const {objects , addNewLibraryCard, clearLibrarySelection, classes} = this.props
        let libLength = objects.size

        //console.log(this.props.selectedIDs)
        //categoriesMap will have a key value of category and array of cards under the category

        let categoriesMap = new Map()
        for(let libID of objects.keys()){
            let object = objects.get(libID)
            let categoriesArr = [object]

            if(categoriesMap.has(object.category)){
                categoriesArr = categoriesArr.concat(categoriesMap.get(object.category))
            }

            categoriesMap.set(object.category, categoriesArr)
        }

        return(
            <div
                onClick={(e) =>{
                    e.persist()
                    if(e.target.id === 'library'){
                        clearLibrarySelection()
                    }
                }}
            >
                <div style={{padding:"0.5rem 1rem"}}>
                    <Grid container justify="flex-end" alignItems="center">
                        <Grid item>
                            <Link
                                style={{color:"#FA841B", fontWeight:"bold", '&:hover':{textDecoration:"underline"}}}
                                className={classes.addCard}
                                onClick={() =>addNewLibraryCard()}
                            >
                                    + Add Card
                            </Link>
                        </Grid>
                    </Grid>
                </div>
                <div
                    style={{marginTop:"-10px", padding:"0 1rem"}}
                    id ="library"
                >
                {categoriesMap.size > 0 ?
                    <List>
                        <Grid container justify="center" alignItems="center">
                            <Grid item xs={8}>
                                <p className={classes.all}>
                                    All Cards
                                </p>
                            </Grid>
                            <Grid item xs={4}>
                                <p className={classes.categoryCount}>
                                    {libLength}
                                </p>
                            </Grid>
                        </Grid>

                        {this.displayCategories(categoriesMap)}
                    </List>
                    :
                    null

                }
                </div>
            </div>
        )
    }

    displayCategories = (categoriesMap) =>{
        let categoriesArr = []
        const {
            classes,
            categoryColorMap,
            handleCategoryClick,
            handleLabelClick,
            leftPanelState,
            deleteLibrary,
            selectCardsInLibrary,
            selectedIDs,
            canDragLibCard,
            startSelectCardsLibrary,
            duplicateSingleLibraryCard,
        } = this.props
        const sortedCategoryMap = new Map([...categoriesMap.entries()].sort())

        for(const [category, objects] of sortedCategoryMap){
            const {moveViewToCard} = this.props
            categoriesArr.push(
                <div
                    key={`${category + objects}`}
                >
                    <ListItem
                        style={{padding:"0", marginTop:"20px"}}
                        key={`${category + objects}`}
                        button
                        onClick={handleCategoryClick.bind(this, category)}
                        name = {`${category}`}
                    >
                        {this.state[category] ?
                            <IconButton className={classes.expandBtn}>
                                <ArrowDropUpRounded />
                            </IconButton>
                            :
                            <IconButton className={classes.expandBtn}>
                                <ArrowDropDownRounded />
                            </IconButton>
                        }

                        <Grid container justify="center" alignItems="center">
                            <Grid item xs={8}>
                                <p className={classes.category} style={{ background: categoryColorMap.get(category)}}>
                                   {category != null ? category : <i>No category</i>}
                                </p>
                            </Grid>
                            <Grid item xs={4}>
                                <p className={classes.categoryCount}>
                                    {Object.keys(objects).length}
                                </p>
                            </Grid>
                        </Grid>
                    </ListItem>
                    <Collapse
                        in={leftPanelState[category]}
                        timeout="auto"
                        unmountOnExit
                    >
                       <GameLibraryInfo
                            objects={objects}
                            category={category}
                            moveViewToCard={moveViewToCard}
                            deleteLibrary = {deleteLibrary}
                            duplicateSingleLibraryCard={duplicateSingleLibraryCard}
                            handleLabelClick = {handleLabelClick}
                            leftPanelState = {leftPanelState}
                            selectCardsInLibrary = {selectCardsInLibrary}
                            selectedIDs = {selectedIDs}
                            canDragLibCard = {canDragLibCard}
                            startSelectCardsLibrary = {startSelectCardsLibrary}
                            imgData = {this.props.imgData}
                            canvasFocus = {this.props.canvasFocus}
                       />
                    </Collapse>
                </div>
            )
        }

        return(categoriesArr)
    }

    // handleCategoryClick = (category) =>{
    //     this.setState({
    //         [category]: !this.state[category]
    //     })
    // }
}

export default withStyles(styles)(GameLibrary)
