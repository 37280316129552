export const styles = {
  '@global': {
      '*::-webkit-scrollbar': {
        width: '0.4em',
        height: '0.4em',
        backgroundColor:"#C4C4C4",
      },
      '*::-webkit-scrollbar-track': {
        '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
      },
      '*::-webkit-scrollbar-thumb': {
        backgroundColor: '#FDAF2A',
        borderRadius:"10px",
        outline: '1px solid slategrey'
      },
      'body':{
          fontFamily:"arial"
      }
  },

  toastSuccess:{
    background: "#07bc0c"
  },

  toastWarning:{
    background: "#f1c40f"
  },

  toastError:{
    background: "#e74c3c"
  },

  topPanel:{
    // height:"4rem", 
    padding:"0 20px", 
    color:"#4F4F4F"
  },

  topPanelContract:{
    width:"calc(100% - 20rem)",
    // height:"4rem", 
    padding:"0 20px", 
    color:"#4F4F4F"
  },
  topHeader:{
    fontSize:"18px", 
    fontWeight:"bold"
  },

  topIcon:{
    verticalAlign:"middle"
  },

  topButton:{
    background:"#FFAA0D", 
    color:"#FFF", 
    float:"right",
    marginRight:"10px",
    paddingLeft:"20px",
    paddingRight:"20px",
  },

  topButtonUpload:{
    background:"grey", 
    color:"#FFF", 
    float:"right",
    marginRight:"10px",
    paddingLeft:"20px",
    paddingRight:"20px",
  },

  leftPanel:{
    position:"relative", 
    background:"#C4C4C4", 
    color:"white", 
    width:"100%", 
    height:"3rem",
    padding:"0 2rem"
  },


  leftPanelBtnWrapper:{
    textAlign:"center",
    margin: "20px 0",
   
  },
  leftPanelBtn:{
    color:"white",
    transition:"0.5s",
    borderRadius:"0px",
    "&:hover":{
      background:"rgba(0,0,0,0.1)"
    },
    '&:focus':{
      outline:"none"
    },
    
  },
  
  leftPanelBtnSelected:{
    color:"#4F4F4F",
    borderRadius:"0px",
    transition:"0.5s",
    "&:hover":{
      background:"rgba(0,0,0,0.1)"
    },
    '&:focus':{
      outline:"none"
    }
  },

  main:{
    position:"fixed", 
    background:"#F2F2F2", 
    width: "100%",
    height:"calc(100% - 4rem)",
  },

  nodeSettingsOpen:{
    position:"relative", 
    width:"calc(100% - 22rem)",
    height:"calc(100% - 3rem)",
    padding:"2rem", 
    overflow: "auto",
  },

  nodeSettingsClose:{
    position:"absolute", 
    width:"100%",
    height:"calc(100% - 3rem)",
    padding:"2rem", 
    overflow: "auto",
    paddingRight:"1rem",
  },

  onOpen:{
    position:"absolute", 
    right:"0", 
    top:"0",
    background:"#FAF9F9", 
    borderLeft:"1px solid #D1D1D1", 
    height:"calc(100% - 3rem)", 
    overflowY:"auto",
    width:"22rem"

  },
  
  onClose:{
    position:"absolute", 
    right:"-420px", 
    background:"#FAF9F9", 
    borderLeft:"1px solid #D1D1D1", 
    height:"100%", 
    width:"20rem"
  },

  nodeEvenIcon:{
    color:"#4173D6", 
    fontSize:"17px", 
    marginRight:"5px",
    marginTop:"-3px"
  },

  nodeOddIcon:{
    color:"#FFAA0D",
    fontSize:"17px", 
    marginRight:"5px",
    marginTop:"-3px"
  },
  nodeStart:{
    background:"#BFBFBF", 
    color:"white", 
    position:"absolute", 
    fontWeight:"bold",
    textAlign:"center",
    marginLeft:"50px",
    width:"100px", 
    fontSize:"14px", 
    fontFamily:"Arial",
    padding:"10px", 
    borderRadius:"20px",
    boxShadow:"0 0 10px rgba(0,0,0,0.1)", 
    marginTop:"24px",
  },

  nodeRemove:{
    position:"absolute", 
    fontSize:"5px",
    width:"20px",
    height:"20px",
    right:"5px", 
    top:"4px", 
    color:"#4F4F4F", 
    opacity:"0",
    marginTop:"3px", 
    zIndex:"2",
    transition:"0.5s",
    '&:focus':{
      outline:"none"
    },
    '&:hover':{
      background:"none", 
    }
  },

  node:{
    // display:"inline-block", 
    borderRadius:"10px", 
    position:"absolute",
    width:"150px", 
    padding:"0", 
    textTransform:"none",
    marginBottom:"100px",
    fontFamily:"Arial",
    cursor:"pointer",
    boxShadow:"0 0 10px rgba(0,0,0,0.1)", 
    border:"2px solid #D1D1D1",
    '&:hover':{
      '& $addIcon':{
        marginBottom:"-40px",
        opacity:"1"
      },
      '& $nodeRemove':{
        opacity:"1"
      }

    }
  },

  nodeType:{
    background:"#E9E9E9", 
    fontSize:"12px", 
    margin:"0", 
    color:"#4F4F4F", 
    padding:"10px"
  },

  nodeAttribute:{
    background:"#FFF", 
    margin:"0", 
    color:"#4F4F4F",
    fontSize:"12px",  
    padding:"10px", 
    fontWeight:"bold"
  },


  nodeDisabled:{
    background:"#F2F2F2"
  },

  nodeAttributeDisable:{
    background:"#F2F2F2", 
  },

  nodeArrow:{
    display:"inline-block", 
    position:"absolute", 
    width:"31px", 
    left:"-33px",
    margin:"41px 5px 0 0",
    height:"2px", 
    marginBottom:"36px", 
    background:"#828282",
    zIndex:"2"
  },

  nodeArrowBend:{
    display:"inline-block", 
    position:"absolute", 
    width:"16px", 
    left:"-17px",
    margin:"41px 3px 35px 0",
    height:"2px", 
    background:"#828282", 
    zIndex:"2"
  },

  nodeArrowIcon:{
    float:"right", 
    marginTop:"-15px", 
    marginRight:"-20px", 
    fontSize:"20px", 
    color:"#828282"
  },

  nodeCircleLeft:{
    width:"9px",
    height:"9px",
    background:"white", 
    marginTop:"-3px", 
    marginLeft:"-5px", 
    float:"left",
    border:"2px solid #828282",
    borderRadius:"100px",
    zIndex:"1"
  },

  nodeCircle:{
    width:"9px",
    height:"9px",
    background:"white", 
    marginTop:"-3px", 
    marginRight:"-6px", 
    position:"relative",
    float:"right",
    border:"2px solid #828282",
    borderRadius:"100px",
    zIndex:"1"
  },


  nodeSelected:{
    border:"2px solid orange"
  },

  // nodeErrorHighlight:{
  //   border:"2px solid red"
  // },


  addIcon:{
    position:"absolute",
    opacity:"0",
    bottom:"0",
    left:"0",
    marginLeft:"50px",
    width:"40px",
    height:"40px",
    color:"#4F4F4F", 
    transition:"0.5s",
    cursor:"pointer",
    '&:focus':{
      outline:"none"
    }
  },

  row:{
    display:"block",
    height:"75px",
    marginBottom:"60px",
    width:"100%"
  },

  column:{
    display:"inline-block",
    width:"190px",
    height:"100%",
  },

  /*  SETTINGS */
  settingsPage:{
    overflowY:"auto", 
    width:"100%", 
    height:"calc(100% - 3rem)"
  }, 

  fieldRow:{
    marginBottom:"20px",
    fontSize:"14px", 
  },

  fieldText:{
    fontSize:"14px", 
    color:"#4F4F4F"
  },

  deleteButton:{
    background:"red", 
    color:"white", 
    outline:"none", 
    marginTop:"20px"
  },

  fieldHeader:{
    fontWeight:"bold",
    fontSize:"16px",
    color:"#4F4F4F"
  },

  /* Deck List */
  deckPage:{
    position:"relative",
    width:"100%",
    height:"calc(100% - 3rem)",
    overflow: "hidden",
  },

  deckCard:{
    padding:"10px", 
    marginBottom:"10px", 
    background:"white", 
    border:"1px solid #C4C4C4", 
    borderRadius:"10px", 
  },

  deckContent:{
    fontWeight:"bold", 
    color:"#4F4F4F", 
    fontSize:"14px",
    margin:"0",
    marginBottom:"10px"
  },

  deckLabel:{
    background:"#5C969A", 
    marginRight:"5px", 
    borderRadius:"5px", 
    fontSize:"14px", 
    padding:"0 5px 2px 5px", 
    color:"white", 
    margin:"0", 
    display:"inline-block"
  },
  
  deckLabelIcon:{
    fontSize:"12px", 
    marginTop:"-2px", 
    marginRight:"5px"
  },

  card:{
    background:"transparent",
    fontFamily:"Montserrat, sans-serif",
    boxShadow:"none",
    position:"relative", 
    overflow:"hidden", 
    margin:"0 10px",
    display:"inline-block",
    width:"170px", 
    height:"270px", 
    borderRadius:"10px",
  },

  cardCover:{
      height:"calc(100%)",
      width:"100%",
      padding:"10px",
      top:"0",
      left:"0",
      transition:"0.5s",
      background:"#FFF",
      opacity: 1,
      transformStyle:"preserve-3d",
      transform:"rotateY(0deg)",
      border:"0.5px solid #c4c4c4",
      borderRadius:"10px",
      zIndex:1
  },

  cardCoverIconWrapper:{
      margin:"40px 0",
  },

  cardCoverCategory:{
      fontSize:"18px",
      textAlign:"center",
      fontWeight:"bold"
  },

  cardContent:{
    height:"calc(100%)",
    padding:"10px",
    top:"0",
    left:"0",
    background:"#FFF",
    transition:"0.5s",
    transformStyle:"preserve-3d",
    transform:"rotateY(0deg)",
    border:"0.5px solid #c4c4c4",
    borderRadius:"10px",
    opacity:1,
    zIndex:1
  },

  contentWrapper:{
      margin:"10px 0 10px 0",
      height:"calc(100% - 115px)",
      cursor:"pointer"
  },

  content:{
      width:"100%",
      fontFamily:"Montserrat",
      fontWeight:"bold",
  },

  deckSelection:{
    background:"#FAF9FA"
  },

  deckWrapper:{
    display:"block",
    position:"relative", 
    width:"fit-content", 
    padding:"20px 10px", 
    borderRadius:"10px", 
    margin:"10px",
    cursor:"pointer",
    transition:"background 0.5s",
    "&:hover":{
      background:"#FAF9FA"
    }
  },

  switchDeckButton:{
    background:"#4F4F4F", 
    color:"white",
    outline:"none",
    '&:focus':{
      outline:"none"
    }
  },

  switchDeckButtonDisabled:{
    background:"#D1D1D1", 
    color:"white"
  },

  modalInfo:{
    background:"#fff", 
    color:"#4F4F4F", 
    borderRadius:"5px", 
    border:"1px solid #c4c4c4", 
    padding:"2.5rem", 
    width:"400px", 
    margin:"0 auto", 
    marginTop:"15%"
  },

  modalHeader:{
    color:"#2CB67D", 
    fontSize:"14px", 
    fontWeight:"bold"
  },

  modalBtn1:{
    border:"1px solid #c4c4c4", 
    color:"#4F4F4F", 
    marginRight:"10px",
    '&:focus':{
      outline:"none"
    }
  },

  modalBtn2:{
    background:"orange", 
    color:"white",
    '&:focus':{
      outline:"none"
    }
  },

  dropzone: {
    width: "100%",
    height: 300,
    background: "#eee",
  },
  
  dropzoneInput: {
    width: "100%",
    height: "100%",
  },

  deckMenu:{
    background:"white", 
    borderRadius:"5px", 
    border:"1px solid #c4c4c4", 
    boxShadow:"none"
  },

  deckMenuList:{
    fontSize:"12px",
    color:"#4F4F4F"
  },

  filterItem:{
    fontSize:"12px",
    color:"#4F4F4F"
  },

  filterLabel:{
    pointerEvents: "none",
    fontSize:"12px",
    color:"#c4c4c4",
    margin:"-5px 0",
  },

  deckName:{
    color: "#4F4F4F",
    fontSize:"14px", 
    fontWeight:"bold",
    display:"inline-block", 
    margin:"0",
    marginTop:"4px"
  },

  deckEllipse:{
    width:"14px", 
    height:"14px", 
    marginTop:"-2", 
    marginLeft:"10px", 
    outline:"none",
    '&:focus':{
      outline:"none"
    }
  },

  deckMenuListIcon:{
    marginRight:"10px",
    fontSize:"16px",
  },

  cards:{
    overflow:"hidden",
    float: "left",
    width: "140px",
    height: "212px",
    borderRadius:"5px",
    margin: "0.5rem",
    border:"none", 
    background:"white"
  },
  cardFont:{
    fontSize:"11px", 
    fontWeight:"bold", 
    fontFamily:"Montserrat", 
    margin:"5px 10px", 
    color:"#4f4f4f",
    overflowWrap: "break-word",
    wordWrap: "break-word",
    
  },

  tag:{
    display:"inline-block", 
    cursor:"pointer", 
    background:"#D1D1D1", 
    width:"fit-content", 
    outline:"none", 
    color:"#4F4F4F", 
    fontSize:"10px",
    padding:"5px 10px", 
    margin:"0 10px", 
    borderRadius:"10px"
  },

  tagIconSize:{
    marginRight:"5px", 
    fontSize:"10px"
  },

  cardIcon:{
    outline:"none", 
    height:"30px", 
    width:"30px", 
    margin:"10px 0 0 10px",
    background:"#6246EA",
    '&:focus':{
      outline:"none"
    }
  },

  cardEllipse:{
    float:"right", 
    outline:"none", 
    height:"30px", 
    width:"30px", 
    margin:"10px 10px 0 0",
    '&:focus':{
      outline:"none"
    }
  },

  cardIconSize:{
    fontSize:"18px", 
  },

  cardContentLength:{
    display:"inline-block", 
    margin:"0", 
    color:"#c4c4c4"
  },

  cardMenuList:{
    fontSize:"12px",
    color:"#4F4F4F"
  },

  cardMenuListIcon:{
    marginRight:"10px",
    fontSize:"16px",
    color:"#4F4F4F"
  },

  nodeMenu:{
    fontSize:"12px",
    color:"#4F4F4F",
    width:"150px"
  },

  nodeMenuIcon:{
    //marginRight:"10px",
    marginTop:"-2px",
    fontSize:"14px",
    color:"#4F4F4F"
  },

  toast:{
    background:"#494949",
    color:"white !important",
    width:"330px"
  },

  toastBar:{
    background:"#E45858"
  },

  toggle:{
    "& .MuiSwitch-switchBase.Mui-checked":{
      color: 'white',
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":{
      backgroundColor: "#FAC710",
      opacity:"1"
    }
  },
}