import React from 'react'
import {withStyles} from '@material-ui/core'
import {cardStyles} from '../user_interface/assets/cardUI'
import '../../../app/app.css'
import moment from "moment";
import EE from '../../../api/eventemitter'
import Cards from '../objects/hand/card';

// styles.n_card.marginBottom = "0"

class GamePinned extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            cards: [],
        }
    }

    componentDidMount(){
    }

    render(){
        const {classes, categoryColorMap, listPinIds, libraryObjects} = this.props
        const editable = false
        const hasMenu = false
        return(
            <div>
                {this.cards()}
                {/* {libraryObjects.values().map((value ,index)=>
                    <>
                        <Cards
                            key={`${index}-${value.libID}`}
                            index={index}
                            card={value}
                            categoryColor={this.props.categoryColorMap.get(value.category)}
                            editable={editable}
                            hasMenu={hasMenu}
                        />
                    </>
                    )}     */}
            </div>
        )
    }

    cards = () =>{
        const {classes, categoryColorMap, inhand, listPinIds, libraryObjects} = this.props
        const editable = false
        const hasFlip = false
        const hasImage = false
        const hasPin = true
        const hasMenu = true
        let cardList = []

        for(var i=0; i<listPinIds.length; i++){
            const value = libraryObjects.get(listPinIds[i])

            if(value){
                cardList.push(<Cards
                    key={listPinIds[i]}
                    index={i}
                    card={value}
                    categoryColor={this.props.categoryColorMap.get(value.category)}
                    editable={editable}
                    pinRightPanel={this.props.pinRightPanel}
                    hasFlip={hasFlip}
                    hasPin={hasPin}
                    hasMenu={hasMenu}
                    listPinIds={this.props.listPinIds}
                    imgData = {this.props.imgData}
                    onImgUpload = {this.props.onImgUpload}
                    onImgPaste = {this.props.onImgPaste}
                    removeImg = {this.props.removeImg}
                    onDrop = {this.props.onDrop}
                /> )
            }


        }

        return cardList
    }
}

export default withStyles(cardStyles)(GamePinned)
