import React from 'react'
import { Route, Switch,  Router } from 'react-router-dom'
import history from '../utility/history'

import Home from '../page/home'
import session from '../model/session'
import Game from '../page/game'
import Smiley from '../page/test/smiley'
import SignIn from '../page/signin'
import Register from '../component/game/user_interface/registration'
import ForgotPassword from '../component/game/user_interface/forgotPassword'
import PasswordReset from '../page/passwordReset'
import Error from '../page/error'
import JamBuilder from '../component/game/user_interface/jamBuilder'
import DeckBuilder from '../../src/component/deckbuilder/deckBuilderMain'
import frontPageOld from '../component/game/user_interface/frontPageOld'

// import Topic from '../page/topic'
// import Jam from '../page/jam'
// import Prejam from '../page/prejam'
// import Project from '../page/project'
// import ProjectList from '../page/projectList'
// import JamList from '../page/jamList'
// import Profile from '../page/profile'
// import Chat from '../page/test/chat'
// import TestCanvas from '../page/test/canvas'
// import PreGame from '../page/pregame'
// import Playground from '../page/test/playground'

const Routing = () => {
  //console.log(process.env.VERSION)
  return(
    <Router history={history}>
        <Switch>
          <Route exact path="/test/smiley" component={Smiley}/>
          <Route exact path="/game/:gid" component={Game}/>
          <Route exact path="/jambuilder/:jbid" component={JamBuilder}/>
          <Route exact path="/" component={Home} />
          <Route exact path="/auth/reset/:token" component={PasswordReset}/>
          <Route exact path="/login" component={SignIn}/>
          <Route exact path="/register" component={Register}/>
          <Route exact path="/recovery" component={ForgotPassword}/>
          <Route exact path="/logout" render = {() => {
              session.clear().then(()=>{
                window.location.href = "/login"
              });
          }} />
          <Route exact path="/deckbuilder" component={DeckBuilder}/>
          <Route exact path="/old" component={Home} />

          <Route path="/:open" component={Home} />
          <Route path="*" render={(props)=><Error {...props} message={"Page Not Found."}/>}/>

        </Switch>
    </Router>
  )

  // return(
  //   <>
  //         <Route exact path="/test/state" component={State}/>
  //         <Route exact path="/test/playground" component={Playground} />
  //         <Route exact path="/test/canvas" component={TestCanvas} />
  //         <Route exact path="/test/chat" component={Chat}/>
  //         <Route exact path="/test/smiley" component={Smiley}/>
  //         <Route exact path="/game" component={PreGame}/>
  //         <Route exact path="/test/jambuilder" component={JamBuilder}/>

  //         <Route exact path="/topic" component={Topic}/>
  //         <Route exact path="/:id/jamsetup" component={Prejam}/>
  //         <Route exact path="/:id/jam/:sid" component={Jam}/>
  //         <Route exact path="/project" component={ProjectList}/>
  //         <Route exact path="/jam" component={JamList}/>
  //         <Route exact path="/:id/project/:pid" component={Project}/>
  //         <Route exact path="/profile" component={Profile}/>
  //   </>
  // )
}

export default Routing;