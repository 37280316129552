import * as React from 'react';

import { ICategoryLabelProps } from './category-label.interface';
import classes from './category-label.module.scss';

export const CategoryLabel = (props: ICategoryLabelProps): React.ReactElement => {
  const {
    category,
    categoryColor,
  } = props;

  return (
    <p
      className={classes.categoryLabel}
      style={{
        color: (category && categoryColor) ? categoryColor : '#c4c4c4',
      }}
    >
      {!category ? 'No category' : category}
    </p>
  );
};
