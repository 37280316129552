const CanvasState = {
    latestObject: {},
    playerList: {},
    currentPlayer: {
        id: null,
        name: null,
        index: null
    },
    objRefs: new Map(),
    objRefMethods: new Map(),
    gameConfig: null
}

export default CanvasState