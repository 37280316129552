/** Built-in Modules */

/** Third-Party Modules */
import React from 'react';
import { withApollo } from 'react-apollo';
import moment from 'moment';
import {
  Grid,
  Card as MuiCard,
  IconButton,
  Avatar,
  Menu,
  MenuItem,
  Tooltip,
  withStyles,
} from '@material-ui/core';
import {
  AllInbox,
  CloseRounded,
  ImageOutlined,
  MoreVert,
  Create,
  FilterFrames,
  Repeat,
  RoomOutlined,
  PollOutlined,
  DeleteOutlined,
  Eco,
  Room,
  FormatBold,
  FormatListBulleted,
  FormatListNumbered,
  InsertLink,
  TextFormat,
  FormatItalic,
  FormatUnderlined,
  StrikethroughS,
  KeyboardArrowDown,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  KeyboardArrowUp,
} from '@material-ui/icons';
import Draggable, { DraggableData, DraggableEvent } from 'react-draggable';
import _ from 'lodash'
import ContentEditable, { ContentEditableEvent } from 'react-contenteditable';
import sanitizeHtml from 'sanitize-html';
import Dropzone from 'react-dropzone';
import {hexToHSL, getUserId} from '../../utility/function';


/** Types */
import { IDraggableCardProps, IDraggableCardState } from './draggable-card.interface';
import { Vector2 } from '../common/vector/vector.interface';

/** Config */
import config from '../../config/backend';
import { cardConfig as cardConstants } from '../card/card.config';

/** API */
import {
  GAME_OBJECT_CONTENT,
  GAME_UPDATE_OBJECT_FLIP,
} from '../../api/graphql-mutation';
import EE from '../../api/eventemitter';
import socket from '../../api/socket';
import { gameObjectApi } from '../game-object/game-object.api';

import CanvasState from '../../component/game/state/canvasState'
import { CanvasUtil } from '../../component/game/helper/gameCanvasHelper'

/** Components */
import { CharacterCounter } from '../character-counter/character-counter';
import { LabelChip } from '../label-chip/label-chip';
import { CategoryLabel } from '../category-label/category-label';

/** Styling */
import styles from '../../component/game/user_interface/assets/cardStyle';
import '../../component/game/user_interface/assets/card.css';
import { CardFront } from '../card/card-front/card-front';

let linkify = require('linkify-it')();

const sanitizeHtmlConfig = {
  allowedTags: ['b', 'i', 'em', 'strong', 'a', 'p', 'h1', 'ol', 'ul', 'li', 'strike', 'u','div'],
  allowedAttributes: { a: ['href'] }
};

class DraggableCard extends React.Component<IDraggableCardProps | any, IDraggableCardState | any> {
  private snapping: boolean = false;

  private snappingPos: Vector2 = {
    x: 0,
    y: 0,
  };

  private dragCardState: null = null;

  private touchCardState: null = null;

  private deleteTimer?: number;

  private inputRef: React.RefObject<HTMLInputElement>;

  private hyperlinkRef: React.RefObject<HTMLInputElement>;

  private snapTo: any = null;

  // private dragging: Boolean = false;

  constructor(props: IDraggableCardProps | any) {
    super(props);
    const { obj } = props;

    this.state = {
      id: obj.objId!,
      flip: false,
      pin: false,
      anchorEl: null,
      submitted: false,
      edited: false,
      vote: false,
      category: undefined,
      labels: [],
      type: null,
      styling: {
        iconBg: null,
        color: null,
        background: null
      },
      content: undefined,
      x: null,
      y: null,
      origin: null,
      imgUploaded: false,
      imgSrc: null,
      loaded: false,
      imgUrl: undefined,
      // Selected from library
      selected: false,
      dragOver: false,
      readonly: true,
      editorMenu: false,
      editorSub: null,
      // for text selection
      textSelection: null,
      pairDir: null,
      paired: {
        left: null,
        right: null,
        up: null,
        down: null
      },
      locked: null,
    }

    this.inputRef = React.createRef();
    this.hyperlinkRef = React.createRef();
  }

  componentDidMount() {
    const { obj } = this.props;
    // console.log(obj.imgUrl)

    // let element = document.querySelector(`#card-${this.state.id}`)
    // console.log(element)
    // this.calcZIndex(element);

    this.setState({
      x: obj?.pos?.x ?? 1,
      y: obj?.pos?.y ?? 1,
      flip: obj.flip,
      //Need to test if the obj has already been changed to local time from stack or it's a brand new card
      time: RegExp('(pm|am)', 'g').test(obj.time!) ? obj.time : moment(obj.time!).local().format('h:mm a'),
      content: obj.content ?? "",
      category: obj.category,
      type: obj.type,
      labels: obj.label ? obj.label : obj.labels,
      styling: {
        background: obj.category === 'Blank' ? '#fff' : '#4173D6',
        color: obj.category === 'Blank' ? '#231F20' : '#fff',
        iconBg: obj.category === 'Blank' ? 'orange' : '#4173D6',
      },
      origin: obj.origin,
      vote: obj.vote,
      submitted: obj.category === 'Blank' ? obj.submitted : false,
      pin: obj.pin,
      loaded: true,
      imgUrl: obj.imgUrl,
      paired: obj.paired ? obj.paired : {left:null,right:null,up:null,down:null},
    }, () => {
      this.checkLink();
      CanvasState.objRefMethods.set(obj.objId, this);
    });

    socket.on('Game Card Position Update', (data: Vector2 & { id: string }) => {
      const { id } = this.state;
      const objId = data.id;

      if (id === objId) {
        this.setState({
          x: data.x,
          y: data.y,
        })
      }
    })

    socket.on('Game Card Position Sync', (data: Vector2 & { id: string }) => {
      const { id } = this.state;
      const objId = data.id

      if (id === objId) {
        this.setState({
          x: data.x,
          y: data.y
        });
      }
    })

    // Testing some weird method to update the state using an array of socket namespace
    // for(let [socketNameSpace, handler] of Object.entries(DraggableHelper.sockets)){
    //     socket.on(socketNameSpace, (data: any) => handler(this, this.state, data))
    // }
    socket.on('Canvas Edit Object', (data: any) => {
      const { id } = this.state
      const objId = data.id

      if (id === objId) {
        this.setState({
          edited: true
        });
      }
    })

    socket.on('Object Live Typing', (data: any) => {
      const { id } = this.state;
      const { content, objId } = data;

      if (id === objId) {
        this.setState({ content });
      }
    });

    socket.on('Canvas Flip Object', (data: any) => {
      const { id } = this.state
      const objId = data.id;
      const flip = data.flip

      if (id === objId) {
        this.setState({
          flip: flip
        })
      }
    });

    socket.on('Game Card Submit Sync', (data: any) => {
      const { objId, submitted } = data;
      const { id } = this.state;

      if (id === objId) {
        this.setState({ submitted });
      }
    });

    socket.on('Library Update Object', (data: any) => {
      const { id, change } = data;
      if (this.state.id === id) {
        const { type, data } = change;

        this.setState((state: any) => {
          const { content, category, labels } = state;

          const result = {
            ...state,
            content: type === 'Content' ? data : content,
            category: type === 'Category' ? data : category,
            labels: type === 'Label' ? data : labels,
          };

          return result;
        });
      }
    });

    socket.on('Multiple Card Action', (data: any) => {
      const { option, listOfMultiSelect } = data;

      if (listOfMultiSelect.includes(this.state.id)) {
        switch (option) {
          case 'Flip':
            this.setState((prevState: any) => ({ flip: !prevState.flip }));
            break
          default: break
        }
      }
    });

    socket.on('Game Object Parse Links', (data: any) => {
      const { selectedIDs } = data;

      if (selectedIDs.includes(this.props.obj.libID)) {
        this.checkLink()
      }
    });

    EE.on("Pair Event",(data: any) =>{
      const {objId, dir} = data
      if(objId === this.state.id){
        this.setState({
          pairDir: dir
        })
      }
    })

    EE.on("Unpair Event",(data: any) =>{
      const {objId} = data
      if(objId === this.state.id){
        this.setState({
          pairDir: null
        })
      }
    })

    EE.on("Card Snapping",(data: any)=>{
      const {origin, target, originPaired}  = data;
      if(this.state.id === target){
        let n = this.state.paired;
        n[this.state.pairDir] = {
          to: origin,
          type: "target"
        }

        this.setState({
          pairDir: null,
          paired: n,
        },()=>{
          //EE.emit("Update Object Pairing",{id:this.state.id, paired: this.state.paired})
          socket.emit("Card Pairing",{id:this.state.id, paired: this.state.paired, origin, originPaired})
        })
      }
    })

    EE.on("Unpair Action",(data: any)=>{ // replace by card unpairing
      const {unpairInfo} = data;
      for(let i = 0; i<unpairInfo.length; i++){
        if(unpairInfo[i].to === this.state.id){
          let paired = this.state.paired
          paired[unpairInfo[i].dir] = null

          this.setState({
            paired: paired
          })
        }
      }
    })


    socket.on("Card Pairing",(data:any)=>{
      if(data.id === this.state.id){
        this.setState({
          paired: data.paired
        })
      }

      if(data.origin === this.state.id){
        this.setState({
          paired: data.originPaired
        })
      }
    })


    socket.on("Card Unpairing",(data:any)=>{
        let {unpairInfo} = data

        for(let i = 0; i<unpairInfo.length; i++){
          if(this.state.id === unpairInfo[i].to){
            let p = this.state.paired
            p[unpairInfo[i].dir] = null

            this.setState({
              paired: p,
            })
          }
        }

    })

    socket.on("Card Locking",(data:any)=>{
        let {uid,cid} = data
        if(cid === this.state.id){
          //let x = getUserId()
            this.setState({
              locked: uid
            })
        }

    })

    socket.on("Card Unlocking",(data:any)=>{
      let {cid} = data
      if(cid === this.state.id){
        this.setState({
          locked: null
        })
      }
    })

  }

  componentWillUnmount() {
    CanvasState.objRefs.delete((this.state.id));
    CanvasState.objRefMethods.delete((this.state.id));
  }

  public willSnap = (newCoords: Vector2,snapTo: any): void => {
    this.pairingFunc(true, { x: newCoords.x, y: newCoords.y });
    //let p = this.state.pairDir;

    this.snapTo = snapTo

    // this.setState({
    //   pairDir: null,
    //   paired: p
    // })
  }

  public willNotSnap = (): void => {
    this.pairingFunc(false, { x: null, y: null });
    this.snapTo = null;
  }

  public willStack = (dragCardState: any, touchCardState: any): void => {
    this.dragCardState = dragCardState;
    this.touchCardState = touchCardState;
  }

  public willNotStack = (): void => {
    this.dragCardState = null;
    this.touchCardState = null;
  };

  public pairingFunc = (willSnap: boolean, newCoords: Vector2): void => {
    this.snapping = willSnap;
    this.snappingPos = {
      x: newCoords.x,
      y: newCoords.y,
    };
  };

  public modifyCard = (change: { type: string; data: any }) => {
    const { type, data } = change;
    this.setState((state: IDraggableCardState) => {
        const { id } = state;
        let { content, category, labels, imgUrl } = state;

        if (type === 'Content') {
            content = data
        } else if(type === 'Category') {
            category = data
        } else if(type === 'Label') {
            labels = data
        } else if(type === 'Card Image') {
            imgUrl = data
        }

        socket.emit('Library Update Object', { id, change });

        return {
            content,
            category,
            labels,
            imgUrl
        };
    });
  };

  private lockedPrompt = (): React.ReactNode => {
    const {locked} = this.state

    //console.log(locked)
    if(locked && (locked !== getUserId())){
      //console.log("RENDER LOCK")
      let n = this.props.playerList.find((p: any)=>{
        return p.uid === locked
      })

      return(
        <div
          id="lock-prompt"
          style={{
            position: 'absolute',
            bottom: '-10px',
            backgroundColor: n.usercolor,
            borderRadius: '5px',
            padding: '10px',
            boxShadow: '1px 1px #c4c4c4',
          }}
        >
          <span style={{color:"white"}}>{n.username}</span> is Editing
        </div>
      )
    }else{
      return;
    }
  };

  private editableSubMenu = (): React.ReactNode => {
    const { classes, obj } = this.props;

    const { objId } = obj;

    switch (this.state.editorSub) {
      case 'list':
        return (
          <div
            id="editable-sub"
            style={{
              position: 'absolute',
              top: '-50px',
              left: '40px',
              backgroundColor: '#fff',
              borderRadius: '5px',
              padding: '10px',
              boxShadow: '1px 1px #c4c4c4',
            }}
          >
            <div>
              <FormatListBulleted onMouseDown={(e) => { e.preventDefault(); document.execCommand('insertUnorderedList', false, 'insertOrderedList'); this.setState({ editorSub: null }); }} className={classes.menuIcon} />
              <FormatListNumbered onMouseDown={(e) => { e.preventDefault(); document.execCommand('insertOrderedList', false, 'insertOrderedList'); this.setState({ editorSub: null }); }} className={classes.menuIcon} />
            </div>
          </div>
        );
      case 'format':
        return (
          <div
            id="editable-sub"
            style={{
              position: 'absolute',
              top: '-50px',
              left: '40px',
              backgroundColor: 'white',
              borderRadius: '5px',
              padding: '10px',
              boxShadow: '1px 1px #c4c4c4',
            }}
          >
            <div>
              <FormatBold onMouseDown={(e) => { e.preventDefault(); document.execCommand('bold', false, 'bold'); this.setState({ editorSub: null }); }} className={classes.menuIcon} />
              <FormatItalic onMouseDown={(e) => { e.preventDefault(); document.execCommand('italic', false, 'italic'); this.setState({ editorSub: null }); }} className={classes.menuIcon} />
              <FormatUnderlined onMouseDown={(e) => { e.preventDefault(); document.execCommand('underline', false, 'underline'); this.setState({ editorSub: null }); }} className={classes.menuIcon} />
              <StrikethroughS onMouseDown={(e) => { e.preventDefault(); document.execCommand('strikeThrough', false, 'strikeThrough'); this.setState({ editorSub: null }); }} className={classes.menuIcon} />
            </div>
          </div>
        );
      case 'link':
        return (
          <div
            id="editable-sub"
            style={{
              position: 'absolute',
              top: '-50px',
              left: '10px',
              backgroundColor: 'white',
              borderRadius: '5px',
              padding: '10px',
              boxShadow: '1px 1px #c4c4c4',

            }}
          >
            <div style={{ display: 'flex' }}>
              <input ref={this.hyperlinkRef} style={{ width: '120px' }} type="text" placeholder='Paste a link' id={`editable-link-input-${objId}`} />
              <button
                style={{ fontSize: '10px', marginLeft: '5px', borderRadius: '3px', fontWeight: 'bold' }}
                onMouseDown={(e) => {
                  e.preventDefault();
                  this.restoreSelection();
                  const x = (document?.getElementById(`editable-link-input-${objId}`) as HTMLInputElement).value;
                  if (x) {
                    document.execCommand('createLink', false, x);
                  }
                  this.setState({ editorSub: null });
                }}
              >
                Apply
              </button>
            </div>
          </div>
        );
      default: return <></>;
    }
  };

  private checkLink = (): void => {
    let newContent = this.state.content as string;
    const listOfUrls = linkify.match(newContent);

    //If there is a valid array and link
    if (listOfUrls && listOfUrls.length > 0) {
      for (let i = 0; i < listOfUrls.length; i++) {
        const url = listOfUrls[i];
        //Check if there is a href string due to us parsing pure HTML rather than string
        const hrefString = newContent.substring(url.index - 2, url.index - 6);
        //Check if the closing tag is "a" to prevent double operation
        const closingTag = newContent.substring(url.lastIndex + 2, url.lastIndex + 3);

        if (closingTag !== 'a' && hrefString !== 'href') {
          const slicedHTMLBeforeLink = newContent.substr(0, url.index)
          const sliceHTMLAfterLink = newContent.substr(url.lastIndex, newContent.length)

          newContent = slicedHTMLBeforeLink + `<a href="${url.url}">${url.text}</a>` + sliceHTMLAfterLink;
        }
      }
    }

    this.setState({ content: sanitizeHtml(newContent, sanitizeHtmlConfig) });
  };

  private checkNotStackable = (): boolean => {
    const { selectedIDs, libraryObjects, cardConfig } = this.props;
    const { category } = this.state;
    const { stackable } = cardConfig;

    if (selectedIDs.length > 1) {
      for (let i = 0; i < selectedIDs.length; i++) {
        if (libraryObjects?.get(selectedIDs[i])?.category === 'Stack') {
          return true;
        }
      }

      if (!(stackable.includes('Any') || stackable.includes(category!))) {
        return true;
      }

      return false;
    }

    return true;
  };

  private stackingFunc = (): void => {
    if (this.dragCardState && this.touchCardState) {
      this.props.addStackObject(this.dragCardState, this.touchCardState);
    }
  };

  private updatePosition = (data: DraggableData): void => {
    const { x, y } = this.state;

    const currentX = x! + data.deltaX;
    const currentY = y! + data.deltaY;

    this.setState({
      x: currentX,
      y: currentY
    }, () => {
      socket.emit('Game Card Position Update', {
        id: this.state.id,
        x: this.state.x,
        y: this.state.y,
        color: this.state.color,
      });
    });
  };

  private syncPosition = (e: DraggableEvent): void => {
    //After dragging cards
    e.preventDefault();
    const { x, y } = this.state;
    // console.log(x,y)
    // console.log(this.snappingPos)
    // console.log(this.snapping)
    
    //Pairing
    this.setState({
      x: this.snapping ? this.snappingPos.x : x,
      y: this.snapping ? this.snappingPos.y : y,
    }, () => {
      //Shorthand if statement
      this.snapping && this.props.checkAction('Pair Card', this.state.category);

      this.snapping = false;
      this.snappingPos.x = null;
      this.snappingPos.y = null;
      
      gameObjectApi(this.props.client).mutatePosition({
        gid: this.props.gid,
        objectId: this.props.obj.objId!,
        x: this.state.x!,
        y: this.state.y!,
      }).then(() => {
        // console.log("position did update")
        // here should add mutation
        socket.emit('Game Card Position Sync', { id: this.state.id, x: this.state.x, y: this.state.y })
      });
    });
  }

  private openMenu = (event: React.MouseEvent<HTMLButtonElement | HTMLDivElement>): void => {
    this.setState({ anchorEl: event.currentTarget });
  };

  private closeMenu = (): void => this.setState({ anchorEl: null });

  private cardFeature = (option: string, targetedCardId = null): void => {
    const { gid, featureMultiSelect, multiSelect } = this.props;
    
    this.props.changeAEC(this.state);

    switch (option) {
      case 'Submit': {
        const { id, content } = this.state;
        this.setState((prevState: any) => ({
          submitted: !prevState.submitted,
          edited: prevState.submitted ? true : false
        }), () => {
          socket.emit('Game Card Submit Sync', { objId: id, submitted: this.state.submitted })
          this.props.client.mutate({
            mutation: GAME_OBJECT_CONTENT,
            variables: {
              _id: gid,
              objId: id,
              content: content,
              submitted: this.state.submitted
            }
          });
          // Leaving this commented until more definition for editted cards is found
          this.state.edited ? socket.emit("Canvas Edit Object", { id: id, edited: this.state.edited }) : this.closeMenu();
        });
        this.closeMenu();
        break;
      }
      case 'Add IHL': {
        if (multiSelect) {
          featureMultiSelect('Add IHL');
        } else {
          const { id, flip, time, content, category, type, labels, styling, origin } = this.state;
          const cardState = {
            id,
            flip,
            time,
            content,
            category,
            type,
            label: labels,
            styling,
            origin,
          };
          //Emits to gamePlayerHand
          EE.emit('Privatise Card', { cardState: cardState });
          EE.emit('Delete Card', { objID: this.state.id });
        }

        this.closeMenu();
        this.props.checkAction("Retrieve Card", this.state.category);
        break
      }
      case 'Flip': {
        if (multiSelect) {
          featureMultiSelect('Flip');
          this.setState({ flip: this.props.obj.flip });
        } else {
          const { id } = this.state;
          // Flip card
          this.setState({ flip: !this.state.flip }, () => {
            this.props.client.mutate({
              mutation: GAME_UPDATE_OBJECT_FLIP,
              variables: {
                _id: this.props.gid,
                objId: id,
                flip: this.state.flip
              }
            });
            socket.emit("Canvas Flip Object", { id: id, flip: this.state.flip });
          });
        }

        this.closeMenu();
        this.props.checkAction("Flip Card", this.state.category);
        break;
      }
      case 'Vote': {
        this.setState((prevState: any) => ({
          vote: !(prevState).vote
        }), () => {
          this.state.vote ?
            //Emit to gameVote
            EE.emit('Vote Cards', { card: this.state, obj: this.props.obj }) :
            EE.emit('Unvote Cards', { card: this.state, obj: this.props.obj });

          socket.emit('Vote Label', { vote: this.state.vote, obj: this.props.obj });
        })
        this.closeMenu();
        break;
      }
      case 'Pin':
        featureMultiSelect('Pin');
        this.closeMenu();
        break;
      case 'Stack':
        //The reason for not using the this.closeMenu function is because
        //if we use the function together with this featureMultiSelect,
        //it will cause a infinite loop of state update and crash the platform
        this.setState({
          anchorEl: null
        }, () => {
          featureMultiSelect('Stack', targetedCardId);
          this.props.checkAction('Stack Card');
        });
        break;
      case 'Delete':
        this.deleteTimer = window.setTimeout(() => {
          this.setState({
            anchorEl: null
          }, () => {
            if (multiSelect) {
              featureMultiSelect('Delete')
            }
            else {
              EE.emit('Delete Card', { objID: this.state.id });
            }

            this.props.checkAction('Delete Card', this.state.category);
          });
        }, 2000);
        break
      default: break
    }
  }

  private onChange = (event: ContentEditableEvent): void => {
    // To parse the non-HTML string and length of the content
    const stringContent = new DOMParser().parseFromString(event.target.value, 'text/html').body.textContent;

    if (stringContent!.length < 151) {
      this.setState({ content: event.target.value }, () => {
        const { id, content } = this.state;
        socket.emit('Object Live Typing', { objId: id, content: content })
        this.debounceOnChange();
      });
    }
  }

  private debounceOnChange = _.debounce(() => {
    const { changeCard, obj } = this.props;
    const { content } = this.state;
    EE.emit('Sync Editor Content', {
      content: new DOMParser().parseFromString(content!, 'text/html').body.textContent,
    });

    changeCard(obj.libID, { type: 'Content', data: content });
  }, 300);

  private saveSelection = (): void => {
    if (window.getSelection) {
      const sel = window.getSelection();
      if (sel && sel.getRangeAt && sel.rangeCount) {
        const ranges: Range[] = [];
        for (let i = 0, len = sel.rangeCount; i < len; ++i) {
          ranges.push(sel.getRangeAt(i));
        }
        this.setState({ textSelection: ranges });
      }
    } else if ((document as any).selection && (document as any).selection.createRange) {
      this.setState({ textSelection: (document as any).selection.createRange() });
    }
  };

  private restoreSelection = (): void => {
    const { textSelection: savedSel } = this.state;

    if (savedSel) {
      if (window.getSelection) {
        const sel = window.getSelection();
        if (sel) {
          sel.removeAllRanges();
          for (let i = 0, len = savedSel.length; i < len; ++i) {
            sel.addRange(savedSel[i]);
          }
        }
      } else if ((document as any).selection && savedSel.select) {
        savedSel.select();
      }
    }
  };

  private handleUnpair = (): void => {
    let p = this.state.paired;
    let unpairInfo : any[] = [];
    Object.keys(p).map(key => {
      if(p[key]){
        unpairInfo.push({
          to: p[key].to,
          type: p[key].type,
          dir: key
        })
      }
    })
    // EE.emit("Unpair Action",{unpairInfo});
    this.setState({
      paired: {
        left: null,
        right: null,
        up: null,
        down: null
      },
    },()=>{
      socket.emit("Card Unpairing",{unpairInfo, unpairOrigin:this.state.id})
    })
  }

  // private displayPairingArrow = (paired: any, key: any) => {
  //   const { classes } = this.props;
  //   console.log(paired,key)
  //   if(key === "left" && paired.left){
  //     if(paired.left.type === "target"){
  //       console.log("LEFT")
  //       return(<span><KeyboardArrowLeft className={classes.arrowLeftPairOverlay}/></span>)
  //     }
  //   }

  //   if(key === "right" && paired.right){
  //     if(paired.right.type === "target"){
  //       console.log("right")
  //       return(<span><KeyboardArrowRight className={classes.arrowRightPairOverlay}/></span>)
  //     }
  //   }

  //   if(key === "up" && paired.up){
  //     if(paired.up.type === "target"){
  //       console.log("up")
  //       return(<span><KeyboardArrowUp className={classes.arrowUpPairOverlay}/></span>)
  //     }
  //   }

  //   if(key === "down" && paired.down){
  //     if(paired.down.type === "target"){
  //       console.log("down")
  //       return(<span><KeyboardArrowDown className={classes.arrowDownPairOverlay}/></span>)
  //     }
  //   }

  //   return;
  // }

  private calcZIndex = () => {
    let {up,down,left,right} = this.state.paired
    let index = 1
    
    if(up && up.type === "target"){
      index++
    }

    if(down && down.type === "target"){
      index++
    }

    if(left && left.type === "target"){
      index++
    }

    if(right && right.type === "target"){
      index++
    }

    return index.toString()
    // element.style.zIndex = index.toString()
  }

  private displayPairing = (): React.ReactNode => {
    const { classes } = this.props;
    const {pairDir,paired} = this.state;

    if(pairDir){
      return(
        <div>
          <span><KeyboardArrowLeft style={{backgroundColor: pairDir === "left" ? "orange" : "grey",  }} className={classes.arrowLeftPairOverlay}/></span>
          <span><KeyboardArrowDown style={{backgroundColor: pairDir === "down" ? "orange" : "grey",  }} className={classes.arrowDownPairOverlay}/></span>
          <span><KeyboardArrowUp style={{backgroundColor: pairDir === "up" ? "orange" : "grey" ,  }} className={classes.arrowUpPairOverlay}/></span>
          <span><KeyboardArrowRight style={{backgroundColor: pairDir === "right" ? "orange" : "grey",   }} className={classes.arrowRightPairOverlay}/></span>
        </div>
      )
    }else{
      let left = false;
      let right = false;
      let up = false;
      let down = false;

      if(paired.left){
        if(paired.left.type === "target"){
          left = true
        }
      }

      if(paired.right){
        if(paired.right.type === "target"){
          right = true
        }
      }

      if(paired.up){
        if(paired.up.type === "target"){
          up = true
        }
      }

      if(paired.down){
        if(paired.down.type === "target"){
          down = true
        }
      }

      return(
        <div>
          <span><KeyboardArrowLeft style={{display: left ? "block": "none"}} className={classes.arrowLeftPairOverlay}/></span>
          <span><KeyboardArrowDown  style={{display: down ? "block": "none"}} className={classes.arrowDownPairOverlay}/></span>
          <span><KeyboardArrowUp  style={{display: up ? "block": "none"}} className={classes.arrowUpPairOverlay}/></span>
          <span><KeyboardArrowRight  style={{display: right ? "block": "none"}} className={classes.arrowRightPairOverlay}/></span>
        </div>
      )
      // let outputArrows = [];


      // if(paired.left){
      //   if(paired.left.type === "target"){
      //     return(<span><KeyboardArrowLeft className={classes.arrowLeftPairOverlay}/></span>)
      //   }
      // }

      // if(paired.right){
      //   if(paired.right.type === "target"){
      //     return(<span><KeyboardArrowRight className={classes.arrowRightPairOverlay}/></span>)
      //   }
      // }

      // if(paired.up){
      //   if(paired.up.type === "target"){
      //     return(<span><KeyboardArrowUp className={classes.arrowUpPairOverlay}/></span>)
      //   }
      // }

      // if(paired.down){
      //   if(paired.down.type === "target"){
      //     return(<span><KeyboardArrowDown className={classes.arrowDownPairOverlay}/></span>)
      //   }
      // }
      // Object.keys(paired).forEach(key => {
      //     console.log(paired[key])
      //     if(key === "left" && paired[key]){
      //       if(paired[key].type === "target"){
      //         console.log("left")
      //
      //       }
      //     }
      //     if(key === "down" && paired[key]){

      //       if(paired[key].type === "target"){
      //         console.log("left")
      //         return(<div><KeyboardArrowDown className={classes.arrowDownPairOverlay}/></div>)
      //       }
      //     }
      //     if(key === "up" && paired[key]){
      //       if(paired[key].type === "target"){
      //         console.log("left")
      //         return(<span><KeyboardArrowUp className={classes.arrowUpPairOverlay}/></span>)
      //       }
      //     }
      //     if(key === "right" && paired[key]){
      //       if(paired[key].type === "target"){
      //         console.log("left")
      //         return(<span><KeyboardArrowRight className={classes.arrowRightPairOverlay}/></span>)
      //       }
      //     }
      //     return;
      // })

      // return;
      // return(
      //   <div>
      //     <span><KeyboardArrowLeft style={{display: this.state.paired.left.to && this.state.paired.left.type === "target" ? "block":"none" }} className={classes.arrowLeftPairOverlay}/></span>
      //     <span><KeyboardArrowDown style={{display: this.state.paired.down && this.state.paired.down.type === "target" ? "block":"none" }} className={classes.arrowDownPairOverlay}/></span>
      //     <span><KeyboardArrowUp style={{display: this.state.paired.up && this.state.paired.up.type === "target" ? "block":"none" }} className={classes.arrowUpPairOverlay}/></span>
      //     <span><KeyboardArrowRight style={{display: this.state.paired.right && this.state.paired.right.type === "target" ? "block":"none" }} className={classes.arrowRightPairOverlay}/></span>
      //   </div>
      // )
    }
  }

  render(): React.ReactElement {
    const {
      classes,
      obj,
      scale,
      canvasCoordinates,
      categoryColor,
      multiSelect,
      listPinIds,
      newMultiSelect,
      updateMultiPosition,
      syncMultiPosition,
      checkAction,
      moveBack,
      startMultiSelect,
      cursorMode,
      onlySelect,
      receiveTargetTextfield,
      onImgUpload,
      onImgPaste,
      removeImg,
      spectatorID,
      isSpectate,
      cardConfig,
    } = this.props;
    const { flip, time, anchorEl, x, y, content, origin, edited, id, loaded, labels, category, styling } = this.state;

    const { flippable, stackable, pairable, removable, draggable, retrieveable } = cardConfig;
    const { iconBg, color } = styling;
    const { imgUrl, objId } = obj;

    // So the new content is now a HTML string and to change the value into a string we do this
    const stringText = new DOMParser().parseFromString(content, 'text/html').body.textContent;
    let hsl = [0,0,0]
    if(categoryColor){
      let x = hexToHSL(categoryColor)
      hsl = [x[0],x[1],x[2]]
    }

    if (!loaded) {
      return <></>;
    }

    let disabled = !(
      draggable.includes('Any')
        || draggable.includes(category))
        || cursorMode === 'Hand'
        || this.state.editorMenu
        || (isSpectate ? spectatorID !== origin.id : false
    );

    // disabled = false /* for debug */

    // const handleUnpair = () => {
    //   let p = this.state.paired;
    //   let unpairInfo : any[] = [];
    //   Object.keys(p).map(key => {
    //     if(p[key]){
    //       unpairInfo.push({
    //         to: p[key].to,
    //         type: p[key].type,
    //         dir: key
    //       })
    //     }
    //   })
    //   // EE.emit("Unpair Action",{unpairInfo});
    //   this.setState({
    //     paired: {
    //       left: null,
    //       right: null,
    //       up: null,
    //       down: null
    //     },
    //   },()=>{
    //     socket.emit("Card Unpairing",{unpairInfo, unpairOrigin:this.state.id})
    //   })
    // }

    const handleStartDrag = (event: DraggableEvent | any) => {
      event.persist();
      event.preventDefault();
      event.button === 0 && !event.shiftKey && !multiSelect && newMultiSelect(id, obj.libID);
      this.props.changeAEC(this.state)
      checkAction('Drag Card', category);
      moveBack(objId);

      if (this.state.readonly === false) {
        this.setState({ readonly: true });
      }
    };

    const handleDrag = (event: DraggableEvent, data: DraggableData) => {
      event.preventDefault();
      event.stopPropagation();

      // Cannot handle unpair in onStart/onEnd, hence putting it here with a value check
      if(Object.values(this.state.paired).every(x=>!x) == false){
        this.handleUnpair()
      }

      if (onlySelect) {
        this.updatePosition(data);
        CanvasUtil.checkCardStackAndPair(id, scale, canvasCoordinates, category, pairable, stackable);
      } else if (multiSelect) {
        updateMultiPosition(data);
      }
    };

    const handleStopDrag = (event: DraggableEvent) => {

      //console.log(onlySelect,multiSelect)
      //console.log(onlySelect,multiSelect,this.snapTo)

      if (stackable.includes('Any') || stackable.includes(category)) {
        this.stackingFunc();
      }

      if(this.snapTo){ // set $paired for both card
        let n = this.state.paired;
        n[this.snapTo.dir] = {
          to: this.snapTo.to,
          type: "origin",
        }

         this.setState({
           pairDir: null,
           paired: n,
         },()=>{
          // EE.emit("Update Object Pairing",{id:this.state.id, paired: this.state.paired})
          // Send signal to target card
          EE.emit("Card Snapping",{origin:this.state.id, target: this.snapTo.to, originPaired: n})
         })

        if(onlySelect) { // position update
          this.syncPosition(event)
        } else if (multiSelect) {
          syncMultiPosition(event);
        }
      }else{
        if(multiSelect) { // position update
          syncMultiPosition(event);
        } else {
          this.syncPosition(event)
        }
      }
      
    };

    return (
      <Draggable
        disabled={disabled}
        cancel=".cancel"
        onStart={handleStartDrag}
        onDrag={handleDrag}
        onStop={handleStopDrag}
        position={{ x, y }}
        scale={scale}
      >
        <div
          id={`card-${obj.objId}`}
          onDoubleClick={() => {
            if (!imgUrl) {
              let {locked} = this.state;
              //console.log(locked)
              if(locked == null){
                //console.log("pass")
                locked = getUserId();
                socket.emit("Card Locking",{uid:getUserId(),cid:this.state.id})
              }else{
                return;
              }

              this.setState({ readonly: false,locked}, () => {
                if (this.inputRef?.current) {
                  this.inputRef.current.focus();
                }
              });
            }
          }}
          onContextMenu={(e) => { e.preventDefault(); e.stopPropagation(); this.openMenu(e) }}
          onClick={e => e.shiftKey && startMultiSelect(id, obj.libID)}
          style={{ position: 'absolute', zIndex:`${this.calcZIndex()}`}}
        >
          {this.displayPairing()}
          <MuiCard
            className={classes.card}
            ref={ref => ref && CanvasState.objRefs.set(id, ref)}
            data-card-selected={multiSelect}
          >
            {/* THE BACK OF THE CARD (CONTAINS CONTENT) */}
            <MuiCard
              style={{ background: categoryColor && category !== "Blank" ? `hsl(${hsl[0]},${hsl[1]+10}%,${hsl[2]+((100-hsl[2])/1.25)}%)`: "#fff"}}
              className={flip ? classes.cardContentFlip : classes.cardContent}
            >
              <Grid container direction="row" justify="center" alignItems="center">
                <Grid item xs={3}>
                  <Avatar style={{ background: categoryColor ? categoryColor : '#c4c4c4' }}>
                    {category !== 'Blank' ?
                      <Eco style={{ color: '#fff' }} />
                      :
                      <Create style={{ color: '#fff' }} />}
                  </Avatar>
                </Grid>
                <Grid item xs={6} style={{ color: "#4f4f4f", fontSize: "10px" }}>
                  <p className={classes.username}>{origin.username}</p>
                  {edited ? time + " • Edited" : time}
                  {listPinIds.includes(obj.libID) && <Room className={classes.pin} />}
                </Grid>
                <Grid item xs={3}>
                  <IconButton className={classes.iconRight} onClick={(e) => this.openMenu(e)}>
                    <MoreVert style={{ color: '#c4c4c4' }} />
                  </IconButton>
                </Grid>
                <Grid item xs={12}>
                  <CategoryLabel category={category} categoryColor={categoryColor} />
                </Grid>
              </Grid>
              <Grid container className={classes.contentWrapper}>
                <div
                  id="editable-menu"
                  style={{
                    display: this.state.editorMenu ? 'block' : 'none',
                    position: 'absolute',
                    top: '-100px',
                    left: '40px',
                    backgroundColor: 'white',
                    borderRadius: '5px',
                    padding: '10px',
                    boxShadow: '1px 1px #c4c4c4',
                  }}
                >
                  <TextFormat onMouseDown={(e) => { e.preventDefault(); this.setState({ editorSub: 'format' }); /*document.execCommand('bold',false,'bold');*/ }} className={classes.menuIcon} />
                  <FormatListBulleted onMouseDown={(e) => { e.preventDefault(); this.setState({ editorSub: 'list' }); /*document.execCommand('insertOrderedList',false,'insertOrderedList');*/ }} className={classes.menuIcon} />
                  <InsertLink
                    onMouseDown={(e) => {
                      e.preventDefault();
                      this.saveSelection();
                      this.setState({ editorSub: 'link' }, () => {
                        if (this.hyperlinkRef?.current) {
                          this.hyperlinkRef.current.focus();
                        }
                      });
                      /*document.execCommand("createLink",false,"https://google.com");*/
                    }}
                    className={classes.menuIcon}
                  />
                </div>
                {this.editableSubMenu()}
                {this.lockedPrompt()}
                <Dropzone
                  onDrop={(files) => { this.props.onDrop(files, obj.libID) }}
                  noClick={true}
                  noKeyboard={true}
                  onDragOver={() => {
                    //newMultiSelect(id, obj.libID)
                    this.setState({ dragOver: true })
                  }}
                  onDragLeave={() => {
                    //newMultiSelect(null, null)
                    this.setState({ dragOver: false })
                  }}
                >
                  {({ getRootProps, getInputProps, isDragActive }) => (
                    <div {...getRootProps()} style={{ width: '188px', height: '170px' }}>
                      <input {...getInputProps()} />
                      {isDragActive && stringText!.length === 0 && multiSelect ?
                        <p className={classes.dropZone}>
                          <b>Drag and drop image here</b>
                        </p>
                        :
                        imgUrl ?
                          <div className={classes.imgHolder} style={{ display: imgUrl ? 'block' : 'none' }}>
                            <IconButton onClick={() => removeImg(obj.libID)} className={classes.imgDelete}>
                              <CloseRounded style={{ fontSize: '12px' }} />
                            </IconButton>
                            <img
                              style={{ width: '100%' }}
                              alt="img"
                              src={`${config.backend.uri}/static/uploads/${imgUrl}`}
                            />
                          </div>
                          :
                          <ContentEditable
                            tagName="pre"
                            innerRef={this.inputRef}
                            id="contentEditable"
                            className={classes.content}
                            style={{
                              height: '170px',
                              overflowY: 'auto',
                              zIndex: 1,
                              whiteSpace: 'pre-line'
                            }}
                            contentEditable="true"
                            html={content}
                            //onKeyDown={(e)=>this.onKeyDown(e)}
                            // onDoubleClick={()=>{this.setState({readonly: false})}}
                            onClick={(event) => {
                              if ((event.target as any).tagName.toLowerCase() === 'a') {
                                event.preventDefault();
                                const newWindow = window.open((event.target as any).href, '_blank', 'noopener noreferrer')
                                if (newWindow) { newWindow.opener = null }
                              }
                            }}
                            onFocus={(evt) => {
                              this.setState({
                                editorMenu: true,
                              })
                              EE.emit('Sync Editor Content', { content: stringText })
                              evt.persist();
                              if (evt?.target && category === 'Blank') {
                                receiveTargetTextfield(evt.target)
                                EE.emit('Canvas Defocus')
                              }
                            }}
                            onChange={this.onChange}
                            onBlur={() => {
                              if (this.state.editorSub == null) {
                                socket.emit("Game Object Parse Links", { selectedIDs: [this.props.obj.libID] })
                                socket.emit("Card Unlocking",{cid:this.state.id})
                                this.setState({
                                  readonly: true,
                                  editorMenu: false,
                                  content: sanitizeHtml(this.state.content, sanitizeHtmlConfig),
                                  locked: null,

                                })
                              }
                            }}
                            onPaste={(event) => {
                              if (event.clipboardData.types.includes('Files')) {
                                onImgPaste(event, obj.libID)
                                this.setState({
                                  readonly: true,
                                  editorMenu: false,
                                })
                              }
                            }}
                            disabled={imgUrl || this.state.readonly || (isSpectate ? spectatorID !== origin.id : false)}
                          />
                      }
                    </div>
                  )}
                </Dropzone>
              </Grid>
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                style={{ height: '20px' }}
              >
                <Grid item xs={9}>
                  <LabelChip labels={labels} />
                  <CharacterCounter
                    count={stringText?.length}
                    maxCount={cardConstants.maxCharCount}
                    imgUrl={imgUrl}
                  />
                </Grid>
                <Grid item xs={3} />
              </Grid>
            </MuiCard>
            <CardFront
              category={category}
              categoryColor={categoryColor}
              flipped={flip}
              edited={edited}
              updatedAt={time}
              username={origin!.username}
              color={color}
              iconBg={iconBg}
              pinned={listPinIds.includes(obj.libID)}
              openContextMenu={(e) => { e.preventDefault(); e.stopPropagation(); this.openMenu(e) }}
              classes={classes}
            />
          </MuiCard>
          {Boolean(anchorEl) && (
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              PaperProps={{ style: { fontFamily: '"Montserrat", sans-serif', color: '#4f4f4f', border: '0.5px solid #c4c4c4' } }}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={this.closeMenu}
            >
              <MenuItem
                className={classes.menuItem}
                disabled={!(flippable.includes('Any') || flippable.includes(category))}
                onClick={() => this.cardFeature('Flip')}
              >
                <Repeat className={classes.menuIcon} />
                Flip
              </MenuItem>
              <MenuItem className={classes.menuItem} component="label" disabled={Boolean(content)}>
                <ImageOutlined className={classes.menuIcon} />
                Upload Image
                <input
                  onChange={(event) => { onImgUpload(event, obj.libID); this.closeMenu() }}
                  type="file"
                  hidden
                />
              </MenuItem>
              <MenuItem
                className={classes.menuItem}
                disabled={!(retrieveable.includes('Any') || retrieveable.includes(category))}
                onClick={() => this.cardFeature('Add IHL')}
              >
                <FilterFrames className={classes.menuIcon} />
                Add to Hand
              </MenuItem>
              <MenuItem
                className={classes.menuItem}
                disabled
                //disabled={!(pinnable.includes('Any') || pinnable.includes(category))}
                onClick={() => this.cardFeature('Pin')}
              >
                <RoomOutlined className={classes.menuIcon} />
                {listPinIds.includes(obj.libID) ? 'Unpin' : 'Pin'}
              </MenuItem>
              <MenuItem
                className={classes.menuItem}
                //disabled={!(stackable.includes('Any') || stackable.includes(category))}
                disabled={this.checkNotStackable()}
                onClick={() => { this.cardFeature('Stack', obj.objId) }}
              >
                <AllInbox className={classes.menuIcon} />
                Stack
              </MenuItem>
              <MenuItem
                className={classes.menuItem}
                disabled
                onClick={() => this.cardFeature('Vote')}
              >
                <PollOutlined className={classes.menuIcon} />
                Send to vote
              </MenuItem>
              <Tooltip title="Long press for 2 seconds to delete">
                <MenuItem
                  className={classes.menuItem}
                  disabled={!(removable.includes('Any') || removable.includes(category))}
                  onMouseDown={() => this.cardFeature('Delete')}
                  onMouseUp={() => window.clearTimeout(this.deleteTimer)}
                >
                  <DeleteOutlined className={classes.menuIcon} />
                  Delete
                </MenuItem>
              </Tooltip>
            </Menu>
          )}
        </div>
      </Draggable>
    );
  }
}

export default withApollo(withStyles(styles as any)(DraggableCard) as any) as React.ComponentClass<IDraggableCardProps | any, IDraggableCardState | any>;
