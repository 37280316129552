import React, { Component } from 'react';
import { withStyles, Grid } from '@material-ui/core';
import { Query } from 'react-apollo';

import { GET_USER_BY_ID } from '../../../api/graphql';
import { getUserId, getUsername } from '../../../utility/function';
import GameCanvas from './gameCanvas';
import Loader from '../../loader';
import { JamCanvas } from '../../../components/jam/jam-canvas/jam-canvas.tsx';

const styles = {};

class GameMainPanel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            leader: null,
        };
    }

    render() {
        //console.log(this.props.build)
        return (
            <Query query={GET_USER_BY_ID(this.props.leader._id)}>
                {({ loading, error, data }) => {
                    if (loading) return <Loader />
                    if (error) return <div>Yikes, a monkey ruined the website</div>
                    return (
                        <Grid container style={{ width: "100%", height: "100%" }}>
                            <JamCanvas
                                jamId={this.props.gid}
                                roomName={this.props.name}
                                userId={getUserId()}
                                username={getUsername()}
                            />
                            <GameCanvas
                                roomName={this.props.name}
                                hostName={data.userById.displayName}
                                gameData={this.props.gameData}
                                gid={this.props.gid}
                                data={this.props.data}
                                automation={this.props.automation}
                                participants={this.props.participants}
                                inhand={this.props.inhand}
                                createdBy={this.props.createdBy}
                                usedDeck={this.props.usedDeck}
                                yamlEndpoint={this.props.yamlEndpoint}
                                tppData={this.props.tppData}
                                setup={this.props.setup}
                                setupChanges={this.props.setupChanges}
                                status={this.props.status}
                                gameTranscript={this.props.gameTranscript}
                                refetch={this.props.refetch}
                                build={this.props.build}
                                builderID = {this.props.builderID}
                            />
                        </Grid>
                    )
                }}
            </Query>
        )
    }

    getDefaultActiveSession = (data) => {
        if (data.phases.length === 0) {
            return null
        } else {
            return data.phases[data.phases.length - 1]
        }
    }
}

export default withStyles(styles)(GameMainPanel);
