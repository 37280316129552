export const styles = {
  '@global': {
      'p':{
        fontFamily:"arial"
      },
      '*::-webkit-scrollbar': {
        width: '0.4em',
        height: '0.4em',
        backgroundColor:"#C4C4C4",
      },
      '*::-webkit-scrollbar-track': {
        '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
      },
      '*::-webkit-scrollbar-thumb': {
        backgroundColor: '#FDAF2A',
        borderRadius:"10px",
        outline: '1px solid slategrey'
      },
  },

  leftPanel:{
    position:"fixed",
    overflowY:"auto",
    width:"231px",
    height:"100%",
    background: "#E1E1E1",
  },

  centrePanel:{
    background: "#DBE4F6",
    overflowY:"auto",
    marginLeft:"231px",
    width:"calc(100% -  462px)",
    height:"100%"
  },

  rightPanel:{
    background: "#E1E1E1",
    position:"fixed",
    overflowY:"auto",
    right:"0",
    top:"4rem",
    width:"231px",
    height:"100%"
  },

  leftPanelWrapper:{
    margin:"1rem",
    width:"calc(100% - 2rem)",
  },

  addDeck:{
    color:"#717171",
    float:"right",
    cursor:"pointer",
    opacity:"1",
    '&:hover':{
      opacity:"0.5",
    }
  },

  leftPanelHeader:{
    margin:"0",
    fontSize:"14px",
    color:"#828282",
    fontWeight:"bold",
    textTransform:"uppercase"
  },

  leftPanelList:{
    margin:"0",
    width:"100%",
    padding:"5px 10px",
    borderRadius:"5px",
    transition:"0.5s",
    cursor: "pointer",
    '&:hover':{
      background:"#DBE4F6"
    }
  },

  leftPanelListFont:{
    margin:"0",
    fontSize:"14px",
    textAlign:"left",
    color:"#4F4F4F",
  },

  leftPanelListCardNo:{
    float:"right",
    fontSize:"12px",
    margin:"0",
    width:"fit-content",
    textAlign:"center",
    background:"#C4C4C4",
    padding:"2px 10px",
    borderRadius:"5px",
    color:"#4F4F4F",
  },

  /*Centre Panel*/
  cards:{
    overflow:"hidden",
    float: "left",
    width: "140px",
    height: "212px",
    borderRadius:"5px",
    margin: "0.5rem",
    border: "1px solid white"
  },

  deckMenu:{
    background:"white", 
    borderRadius:"5px", 
    border:"1px solid #c4c4c4", 
    boxShadow:"none"
  },

  deckMenuList:{
    fontSize:"12px",
    color:"#4F4F4F"
  },

  deckMenuListIcon:{
    marginRight:"10px",
    fontSize:"16px",
    color:"#4F4F4F"
  },

  icon:{
    color:"#4F4F4F",
    fontSize:"14px",
    marginTop:"-2px",
    marginRight:"10px"
  },

  field:{
    fontFamily:"Arial",
    fontSize:"14px",
    marginTop:"10px",
    color:"#4f4f4f"
  },

  editorEmpty:{
    color:"#C4C4C4", 
    width:"100%", 
    textAlign:"center", 
    fontSize:"14px",
    marginBottom:"20px",
    marginTop:"10px"
  },

  cardFont:{
    fontSize:"11px", 
    fontWeight:"bold", 
    fontFamily:"Montserrat", 
    margin:"5px 10px", 
    color:"#4f4f4f",
    overflowWrap: "break-word",
    wordWrap: "break-word",
    
  },

  tag:{
    display:"inline-block", 
    cursor:"pointer", 
    background:"#D1D1D1", 
    width:"fit-content", 
    outline:"none", 
    color:"#4F4F4F", 
    fontSize:"10px",
    padding:"5px 10px", 
    margin:"0 10px", 
    borderRadius:"10px"
  },

  tagIconSize:{
    marginRight:"5px", 
    fontSize:"10px"
  },

  cardIcon:{
    outline:"none", 
    height:"30px", 
    width:"30px", 
    margin:"10px 0 0 10px",
    background:"#6246EA",
    '&:focus':{
      outline:"none"
    }
  },

  cardEllipse:{
    float:"right", 
    outline:"none", 
    height:"30px", 
    width:"30px", 
    margin:"10px 10px 0 0",
    '&:focus':{
      outline:"none"
    }
  },

  cardIconSize:{
    fontSize:"18px", 
  },

  cardContentLength:{
    display:"inline-block", 
    margin:"0", 
    color:"#c4c4c4"
  },

  cardMenuList:{
    fontSize:"12px",
    color:"#4F4F4F"
  },

  cardMenuListIcon:{
    marginRight:"10px",
    fontSize:"16px",
    color:"#4F4F4F"
  },

  filterItem:{
    fontSize:"12px",
    color:"#4F4F4F"
  },

  filterLabel:{
    pointerEvents: "none",
    fontSize:"12px",
    color:"#c4c4c4"
  },

  deckEditOn:{
    color: "#4F4F4F",
    fontSize:"14px", 
    fontWeight:"bold",
    background:"#ADC2EC",
  },

  deckEditOff:{
    color: "#4F4F4F",
    fontSize:"14px", 
    fontWeight:"bold",
    background:"transparent",
  },

  toast:{
    background:"#494949",
    color:"white !important",
    marginLeft:"250px",
    overflowWrap: "break-word",
    wordWrap: "break-word",
    width:"330px"
  },

  toastBar:{
    background:"#53CAA6"
  },

    
  imgHolderWrapper:{
    position:"relative", 
    width:"100%", 
    height:"180px",
    padding:"10px",
    borderRadius:"5px",
    background:"#FAF7EA",
    border:"1px solid #c4c4c4",
    "&:hover $imgDelete":{ 
        background:"rgba(0,0,0, 0.7)",
        color:"white"
    }
  },

  imgHolder:{
      width:"calc(100% - 20px)", 
      maxHeight:"160px",
      marginLeft:"10%",
      overflow:"hidden"
  },

  imgDelete:{
      position:"absolute", 
      right:"40px", 
      top:"20px", 
      outline:"none",
      width:"20px", 
      height:"20px", 
      background:"transparent", 
      color:"transparent", 
      zIndex:"2", 
      transition:"0.5s", 
      '&:focus':{
        outline:"none"
      }
  },

    cardImgHolder:{
      position:"relative", 
      width:"calc(100% - 20px)",
      margin:"0 10px",
      height:"100px",
      overflow:"hidden",
      "&:hover $imgDelete":{ 
          background:"rgba(0,0,0, 0.7)",
          color:"white"
      }
    },
    
    dropZone:{
      width:"100%", 
      height:"170px", 
      border:"1px dashed #C4C4C4", 
      background:"#F2F2F2", 
      color:"#C4C4C4", 
      lineHeight: "160px", 
      fontSize:"12px", 
      fontFamily:"Montserrat", 
      textAlign:"center"
    }
}

