export const gd2 = {
  "gameConfig": {
    "phaseConfig": {
      "startingPhase": "Round",
      "phases": [
        {
          "phaseName": "Round",
          "phaseIndex": 1
        }
      ]
    },
    "events": {},
    "Round": {
      "startMessage": null,
      "endCondition": {},
      "endMessage": null,
      "endCommands": null,
      "scoreChange": 0,
      "nextPhase": null,
      "startingTurnStruct": "Turn A",
      "turnStructs": [
        {
          "turnStructName": "Turn A",
          "turnStructIndex": 2
        }
      ]
    },
    "Turn A": {
      "endCondition": null,
      "nextTurnStruct": null,
      "inTurn": {
        "Storyteller": {
          "stepMetaData": [
            {
              "stepName": "Step 1",
              "stepIndex": 5
            }
          ],
          "startingStep": "Step 1",
          "Step 1": {
            "actionMetaData": {
              "7": "Add Card",
              "13": "Delete Card",
              "14": "Retrieve Card",
              "15": "Drag Card",
              "16": "Flip Card",
              "17": "Pair Card",
              "18": "Pin Card",
              "19": "Stack Card"
            },
            "actions": {},
            "cardConfig": {
              "flippable": [
                "Any"
              ],
              "stackable": [
                "Any"
              ],
              "pairable": [
                "Any"
              ],
              "pinnable": [
                "Any"
              ],
              "edittable": true,
              "removable": [
                "Any"
              ],
              "draggable": [
                "Any"
              ],
              "retrieveable": [
                "Any"
              ]
            },
            "cardAddition": {
              "Any": {
                "canvas": true,
                "inHand": true
              }
            },
            "componentConfig": {
              "voting": {
                "abletoVote": false
              },
              "passing": {
                "canPass": false,
                "passType": null
              }
            }
          }
        },
        "mainRole": "Storyteller"
      },
      "outTurn": {
        "Storyteller": {
          "stepMetaData": [
            {
              "stepName": "Step 1",
              "stepIndex": 10
            }
          ],
          "startingStep": "Step 1",
          "Step 1": {
            "actionMetaData": {
              "12": "Add Card",
              "20": "Retrieve Card",
              "21": "Delete Card",
              "22": "Drag Card",
              "23": "Flip Card",
              "24": "Pair Card",
              "25": "Pin Card",
              "26": "Stack Card"
            },
            "actions": {},
            "cardConfig": {
              "flippable": [
                "Any"
              ],
              "stackable": [
                "Any"
              ],
              "pairable": [
                "Any"
              ],
              "pinnable": [
                "Any"
              ],
              "edittable": true,
              "removable": [
                "Any"
              ],
              "draggable": [
                "Any"
              ],
              "retrieveable": [
                "Any"
              ]
            },
            "cardAddition": {
              "Any": {
                "canvas": true,
                "inHand": true
              }
            },
            "componentConfig": {
              "voting": {
                "abletoVote": false
              },
              "passing": {
                "canPass": false,
                "passType": null
              }
            }
          }
        },
        "mainRole": "Storyteller"
      },
      "turnMetaData": {
        "3": "In Turn",
        "8": "Out Turn"
      }
    },
    "deck": {
      "endpoint": "storyDesign",
      "title": "Game Jam",
      "drawConfig": {
        "loop": true,
        "initialDraw": null
      }
    }
  },
  "gameDeck": {
    "name": "Story Design Cards",
    "deck": {
      "Story": [
        {
          "type": "predefined",
          "category": "Story",
          "label": [
            "Marker"
          ],
          "color": "#FF8BA7",
          "content": "Story",
          "flip": false,
          "cardId": "Story-0"
        },
        {
          "type": "predefined",
          "category": "Story",
          "label": [],
          "color": "#FF8BA7",
          "content": "Found Something",
          "flip": false,
          "cardId": "Story-1"
        },
        {
          "type": "predefined",
          "category": "Story",
          "label": [],
          "color": "#FF8BA7",
          "content": "Entertainment",
          "flip": false,
          "cardId": "Story-2"
        },
        {
          "type": "predefined",
          "category": "Story",
          "label": [],
          "color": "#FF8BA7",
          "content": "Birth",
          "flip": false,
          "cardId": "Story-3"
        },
        {
          "type": "predefined",
          "category": "Story",
          "label": [],
          "color": "#FF8BA7",
          "content": "Zero Sum",
          "flip": false,
          "cardId": "Story-4"
        },
        {
          "type": "predefined",
          "category": "Story",
          "label": [],
          "color": "#FF8BA7",
          "content": "Mental Health",
          "flip": false,
          "cardId": "Story-5"
        },
        {
          "type": "predefined",
          "category": "Story",
          "label": [],
          "color": "#FF8BA7",
          "content": "Gain",
          "flip": false,
          "cardId": "Story-6"
        },
        {
          "type": "predefined",
          "category": "Story",
          "label": [],
          "color": "#FF8BA7",
          "content": "Loss",
          "flip": false,
          "cardId": "Story-7"
        },
        {
          "type": "predefined",
          "category": "Story",
          "label": [],
          "color": "#FF8BA7",
          "content": "Achieve Something",
          "flip": false,
          "cardId": "Story-8"
        },
        {
          "type": "predefined",
          "category": "Story",
          "label": [],
          "color": "#FF8BA7",
          "content": "Animal Lover",
          "flip": false,
          "cardId": "Story-9"
        },
        {
          "type": "predefined",
          "category": "Story",
          "label": [],
          "color": "#FF8BA7",
          "content": "Emotionally Draining",
          "flip": false,
          "cardId": "Story-10"
        },
        {
          "type": "predefined",
          "category": "Story",
          "label": [],
          "color": "#FF8BA7",
          "content": "Savior",
          "flip": false,
          "cardId": "Story-11"
        },
        {
          "type": "predefined",
          "category": "Story",
          "label": [],
          "color": "#FF8BA7",
          "content": "End of the World",
          "flip": false,
          "cardId": "Story-12"
        },
        {
          "type": "predefined",
          "category": "Story",
          "label": [],
          "color": "#FF8BA7",
          "content": "Separation",
          "flip": false,
          "cardId": "Story-13"
        },
        {
          "type": "predefined",
          "category": "Story",
          "label": [],
          "color": "#FF8BA7",
          "content": "Failing",
          "flip": false,
          "cardId": "Story-14"
        },
        {
          "type": "predefined",
          "category": "Story",
          "label": [],
          "color": "#FF8BA7",
          "content": "Heartbreak",
          "flip": false,
          "cardId": "Story-15"
        },
        {
          "type": "predefined",
          "category": "Story",
          "label": [],
          "color": "#FF8BA7",
          "content": "Lust",
          "flip": false,
          "cardId": "Story-16"
        },
        {
          "type": "predefined",
          "category": "Story",
          "label": [],
          "color": "#FF8BA7",
          "content": "Envy",
          "flip": false,
          "cardId": "Story-17"
        },
        {
          "type": "predefined",
          "category": "Story",
          "label": [],
          "color": "#FF8BA7",
          "content": "Gluttony",
          "flip": false,
          "cardId": "Story-18"
        },
        {
          "type": "predefined",
          "category": "Story",
          "label": [],
          "color": "#FF8BA7",
          "content": "Death",
          "flip": false,
          "cardId": "Story-19"
        },
        {
          "type": "predefined",
          "category": "Story",
          "label": [],
          "color": "#FF8BA7",
          "content": "Embarassment",
          "flip": false,
          "cardId": "Story-20"
        },
        {
          "type": "predefined",
          "category": "Story",
          "label": [],
          "color": "#FF8BA7",
          "content": "Addiction",
          "flip": false,
          "cardId": "Story-21"
        },
        {
          "type": "predefined",
          "category": "Story",
          "label": [],
          "color": "#FF8BA7",
          "content": "Potential",
          "flip": false,
          "cardId": "Story-22"
        },
        {
          "type": "predefined",
          "category": "Story",
          "label": [],
          "color": "#FF8BA7",
          "content": "Hatred",
          "flip": false,
          "cardId": "Story-23"
        },
        {
          "type": "predefined",
          "category": "Story",
          "label": [],
          "color": "#FF8BA7",
          "content": "Failure",
          "flip": false,
          "cardId": "Story-24"
        },
        {
          "type": "predefined",
          "category": "Story",
          "label": [],
          "color": "#FF8BA7",
          "content": "Left Behind",
          "flip": false,
          "cardId": "Story-25"
        },
        {
          "type": "predefined",
          "category": "Story",
          "label": [],
          "color": "#FF8BA7",
          "content": "Love",
          "flip": false,
          "cardId": "Story-26"
        },
        {
          "type": "predefined",
          "category": "Story",
          "label": [],
          "color": "#FF8BA7",
          "content": "Clumsy",
          "flip": false,
          "cardId": "Story-27"
        },
        {
          "type": "predefined",
          "category": "Story",
          "label": [],
          "color": "#FF8BA7",
          "content": "Bully",
          "flip": false,
          "cardId": "Story-28"
        },
        {
          "type": "predefined",
          "category": "Story",
          "label": [],
          "color": "#FF8BA7",
          "content": "Family",
          "flip": false,
          "cardId": "Story-29"
        },
        {
          "type": "predefined",
          "category": "Story",
          "label": [],
          "color": "#FF8BA7",
          "content": "Murder",
          "flip": false,
          "cardId": "Story-30"
        },
        {
          "type": "predefined",
          "category": "Story",
          "label": [],
          "color": "#FF8BA7",
          "content": "Talented",
          "flip": false,
          "cardId": "Story-31"
        },
        {
          "type": "predefined",
          "category": "Story",
          "label": [],
          "color": "#FF8BA7",
          "content": "Talent",
          "flip": false,
          "cardId": "Story-32"
        },
        {
          "type": "predefined",
          "category": "Story",
          "label": [],
          "color": "#FF8BA7",
          "content": "Isolation",
          "flip": false,
          "cardId": "Story-33"
        },
        {
          "type": "predefined",
          "category": "Story",
          "label": [],
          "color": "#FF8BA7",
          "content": "Chains of Woe",
          "flip": false,
          "cardId": "Story-34"
        },
        {
          "type": "predefined",
          "category": "Story",
          "label": [],
          "color": "#FF8BA7",
          "content": "Rescue",
          "flip": false,
          "cardId": "Story-35"
        },
        {
          "type": "predefined",
          "category": "Story",
          "label": [],
          "color": "#FF8BA7",
          "content": "Wonderwall",
          "flip": false,
          "cardId": "Story-36"
        }
      ],
      "Money": [
        {
          "type": "predefined",
          "category": "Money",
          "label": [
            "Marker"
          ],
          "color": "#2CB67D",
          "content": "Money",
          "flip": false,
          "cardId": "Money-0"
        },
        {
          "type": "predefined",
          "category": "Money",
          "label": [],
          "color": "#2CB67D",
          "content": "$1",
          "flip": false,
          "cardId": "Money-1"
        },
        {
          "type": "predefined",
          "category": "Money",
          "label": [],
          "color": "#2CB67D",
          "content": "$2",
          "flip": false,
          "cardId": "Money-2"
        },
        {
          "type": "predefined",
          "category": "Money",
          "label": [],
          "color": "#2CB67D",
          "content": "$5",
          "flip": false,
          "cardId": "Money-3"
        },
        {
          "type": "predefined",
          "category": "Money",
          "label": [],
          "color": "#2CB67D",
          "content": "$10",
          "flip": false,
          "cardId": "Money-4"
        },
        {
          "type": "predefined",
          "category": "Money",
          "label": [],
          "color": "#2CB67D",
          "content": "$20",
          "flip": false,
          "cardId": "Money-5"
        },
        {
          "type": "predefined",
          "category": "Money",
          "label": [],
          "color": "#2CB67D",
          "content": "$50",
          "flip": false,
          "cardId": "Money-6"
        },
        {
          "type": "predefined",
          "category": "Money",
          "label": [],
          "color": "#2CB67D",
          "content": "$100",
          "flip": false,
          "cardId": "Money-7"
        }
      ],
      "Request": [
        {
          "type": "predefined",
          "category": "Request",
          "label": [
            "Marker"
          ],
          "color": "#3DA9FC",
          "content": "Request",
          "flip": false,
          "cardId": "Request-0"
        },
        {
          "type": "predefined",
          "category": "Request",
          "label": [],
          "color": "#3DA9FC",
          "content": "Please add a TIMESKIP to your story",
          "flip": false,
          "cardId": "Request-1"
        },
        {
          "type": "predefined",
          "category": "Request",
          "label": [],
          "color": "#3DA9FC",
          "content": "Everyone turns blind for some reason",
          "flip": false,
          "cardId": "Request-2"
        },
        {
          "type": "predefined",
          "category": "Request",
          "label": [],
          "color": "#3DA9FC",
          "content": "Be inclusive of LGBT Character",
          "flip": false,
          "cardId": "Request-3"
        },
        {
          "type": "predefined",
          "category": "Request",
          "label": [],
          "color": "#3DA9FC",
          "content": "Please include a cameo for Elsa",
          "flip": false,
          "cardId": "Request-4"
        },
        {
          "type": "predefined",
          "category": "Request",
          "label": [],
          "color": "#3DA9FC",
          "content": "Make it more positive",
          "flip": false,
          "cardId": "Request-5"
        },
        {
          "type": "predefined",
          "category": "Request",
          "label": [],
          "color": "#3DA9FC",
          "content": "Add Horror element",
          "flip": false,
          "cardId": "Request-6"
        },
        {
          "type": "predefined",
          "category": "Request",
          "label": [],
          "color": "#3DA9FC",
          "content": "Make it melodramatic",
          "flip": false,
          "cardId": "Request-7"
        },
        {
          "type": "predefined",
          "category": "Request",
          "label": [],
          "color": "#3DA9FC",
          "content": "Reader's Request (10$)",
          "flip": false,
          "cardId": "Request-8"
        },
        {
          "type": "predefined",
          "category": "Request",
          "label": [],
          "color": "#3DA9FC",
          "content": "Just show us the villain already!!",
          "flip": false,
          "cardId": "Request-9"
        },
        {
          "type": "predefined",
          "category": "Request",
          "label": [],
          "color": "#3DA9FC",
          "content": "Make it more positive",
          "flip": false,
          "cardId": "Request-10"
        },
        {
          "type": "predefined",
          "category": "Request",
          "label": [],
          "color": "#3DA9FC",
          "content": "Go to space and add a bunch of laser sword!",
          "flip": false,
          "cardId": "Request-11"
        },
        {
          "type": "predefined",
          "category": "Request",
          "label": [],
          "color": "#3DA9FC",
          "content": "Include highschool characters!",
          "flip": false,
          "cardId": "Request-12"
        },
        {
          "type": "predefined",
          "category": "Request",
          "label": [],
          "color": "#3DA9FC",
          "content": "End the story with a cliffhanger",
          "flip": false,
          "cardId": "Request-13"
        },
        {
          "type": "predefined",
          "category": "Request",
          "label": [],
          "color": "#3DA9FC",
          "content": "Introduce 3 new characters",
          "flip": false,
          "cardId": "Request-14"
        },
        {
          "type": "predefined",
          "category": "Request",
          "label": [],
          "color": "#3DA9FC",
          "content": "Let there be a superhero",
          "flip": false,
          "cardId": "Request-15"
        },
        {
          "type": "predefined",
          "category": "Request",
          "label": [],
          "color": "#3DA9FC",
          "content": "Add some Vegan themed story",
          "flip": false,
          "cardId": "Request-16"
        }
      ],
      "Genre": [
        {
          "type": "predefined",
          "category": "Genre",
          "label": [
            "Marker"
          ],
          "color": "#f54242",
          "content": "Genre",
          "flip": false,
          "cardId": "Genre-0"
        },
        {
          "type": "predefined",
          "category": "Genre",
          "label": [],
          "color": "#f54242",
          "content": "Action",
          "flip": false,
          "cardId": "Genre-1"
        },
        {
          "type": "predefined",
          "category": "Genre",
          "label": [],
          "color": "#f54242",
          "content": "Adventure",
          "flip": false,
          "cardId": "Genre-2"
        },
        {
          "type": "predefined",
          "category": "Genre",
          "label": [],
          "color": "#f54242",
          "content": "Animation",
          "flip": false,
          "cardId": "Genre-3"
        },
        {
          "type": "predefined",
          "category": "Genre",
          "label": [],
          "color": "#f54242",
          "content": "Black Comedy",
          "flip": false,
          "cardId": "Genre-4"
        },
        {
          "type": "predefined",
          "category": "Genre",
          "label": [],
          "color": "#f54242",
          "content": "Comedy",
          "flip": false,
          "cardId": "Genre-5"
        },
        {
          "type": "predefined",
          "category": "Genre",
          "label": [],
          "color": "#f54242",
          "content": "Crime",
          "flip": false,
          "cardId": "Genre-6"
        },
        {
          "type": "predefined",
          "category": "Genre",
          "label": [],
          "color": "#f54242",
          "content": "Fantasy",
          "flip": false,
          "cardId": "Genre-7"
        },
        {
          "type": "predefined",
          "category": "Genre",
          "label": [],
          "color": "#f54242",
          "content": "Folktale",
          "flip": false,
          "cardId": "Genre-8"
        },
        {
          "type": "predefined",
          "category": "Genre",
          "label": [],
          "color": "#f54242",
          "content": "Historical Fiction",
          "flip": false,
          "cardId": "Genre-9"
        },
        {
          "type": "predefined",
          "category": "Genre",
          "label": [],
          "color": "#f54242",
          "content": "Horror",
          "flip": false,
          "cardId": "Genre-10"
        },
        {
          "type": "predefined",
          "category": "Genre",
          "label": [],
          "color": "#f54242",
          "content": "Melodrama",
          "flip": false,
          "cardId": "Genre-11"
        },
        {
          "type": "predefined",
          "category": "Genre",
          "label": [],
          "color": "#f54242",
          "content": "Musical",
          "flip": false,
          "cardId": "Genre-12"
        },
        {
          "type": "predefined",
          "category": "Genre",
          "label": [],
          "color": "#f54242",
          "content": "Mystery",
          "flip": false,
          "cardId": "Genre-13"
        },
        {
          "type": "predefined",
          "category": "Genre",
          "label": [],
          "color": "#f54242",
          "content": "Noir",
          "flip": false,
          "cardId": "Genre-14"
        },
        {
          "type": "predefined",
          "category": "Genre",
          "label": [],
          "color": "#f54242",
          "content": "Police Procedural",
          "flip": false,
          "cardId": "Genre-15"
        },
        {
          "type": "predefined",
          "category": "Genre",
          "label": [],
          "color": "#f54242",
          "content": "Romance",
          "flip": false,
          "cardId": "Genre-16"
        },
        {
          "type": "predefined",
          "category": "Genre",
          "label": [],
          "color": "#f54242",
          "content": "Romantic Comedy",
          "flip": false,
          "cardId": "Genre-17"
        },
        {
          "type": "predefined",
          "category": "Genre",
          "label": [],
          "color": "#f54242",
          "content": "Satire",
          "flip": false,
          "cardId": "Genre-18"
        },
        {
          "type": "predefined",
          "category": "Genre",
          "label": [],
          "color": "#f54242",
          "content": "Sci-Fi",
          "flip": false,
          "cardId": "Genre-19"
        },
        {
          "type": "predefined",
          "category": "Genre",
          "label": [],
          "color": "#f54242",
          "content": "Suspense",
          "flip": false,
          "cardId": "Genre-20"
        },
        {
          "type": "predefined",
          "category": "Genre",
          "label": [],
          "color": "#f54242",
          "content": "Thriller",
          "flip": false,
          "cardId": "Genre-21"
        },
        {
          "type": "predefined",
          "category": "Genre",
          "label": [],
          "color": "#f54242",
          "content": "Western",
          "flip": false,
          "cardId": "Genre-22"
        }
      ],
      "Topic": [
        {
          "type": "predefined",
          "category": "Topic",
          "label": [
            "Marker"
          ],
          "color": "#319476",
          "content": "Topic",
          "flip": false,
          "cardId": "Topic-0"
        },
        {
          "type": "predefined",
          "category": "Topic",
          "label": [],
          "color": "#319476",
          "content": "AI / Robots",
          "flip": false,
          "cardId": "Topic-1"
        },
        {
          "type": "predefined",
          "category": "Topic",
          "label": [],
          "color": "#319476",
          "content": "Aliens",
          "flip": false,
          "cardId": "Topic-2"
        },
        {
          "type": "predefined",
          "category": "Topic",
          "label": [],
          "color": "#319476",
          "content": "Apropos of Nothing",
          "flip": false,
          "cardId": "Topic-3"
        },
        {
          "type": "predefined",
          "category": "Topic",
          "label": [],
          "color": "#319476",
          "content": "Attention Economy",
          "flip": false,
          "cardId": "Topic-4"
        },
        {
          "type": "predefined",
          "category": "Topic",
          "label": [],
          "color": "#319476",
          "content": "Baby Outsourcing",
          "flip": false,
          "cardId": "Topic-5"
        },
        {
          "type": "predefined",
          "category": "Topic",
          "label": [],
          "color": "#319476",
          "content": "Backhanded compliment",
          "flip": false,
          "cardId": "Topic-6"
        },
        {
          "type": "predefined",
          "category": "Topic",
          "label": [],
          "color": "#319476",
          "content": "Bingeing",
          "flip": false,
          "cardId": "Topic-7"
        },
        {
          "type": "predefined",
          "category": "Topic",
          "label": [],
          "color": "#319476",
          "content": "Body Art",
          "flip": false,
          "cardId": "Topic-8"
        },
        {
          "type": "predefined",
          "category": "Topic",
          "label": [],
          "color": "#319476",
          "content": "Body Shaming",
          "flip": false,
          "cardId": "Topic-9"
        },
        {
          "type": "predefined",
          "category": "Topic",
          "label": [],
          "color": "#319476",
          "content": "Cloning",
          "flip": false,
          "cardId": "Topic-10"
        },
        {
          "type": "predefined",
          "category": "Topic",
          "label": [],
          "color": "#319476",
          "content": "Collateral Damage",
          "flip": false,
          "cardId": "Topic-11"
        },
        {
          "type": "predefined",
          "category": "Topic",
          "label": [],
          "color": "#319476",
          "content": "Crisis Capitalism",
          "flip": false,
          "cardId": "Topic-12"
        },
        {
          "type": "predefined",
          "category": "Topic",
          "label": [],
          "color": "#319476",
          "content": "Crisis Cleansing",
          "flip": false,
          "cardId": "Topic-13"
        },
        {
          "type": "predefined",
          "category": "Topic",
          "label": [],
          "color": "#319476",
          "content": "Crispr",
          "flip": false,
          "cardId": "Topic-14"
        },
        {
          "type": "predefined",
          "category": "Topic",
          "label": [],
          "color": "#319476",
          "content": "Crispr Art",
          "flip": false,
          "cardId": "Topic-15"
        },
        {
          "type": "predefined",
          "category": "Topic",
          "label": [],
          "color": "#319476",
          "content": "Cruel Optimism",
          "flip": false,
          "cardId": "Topic-16"
        },
        {
          "type": "predefined",
          "category": "Topic",
          "label": [],
          "color": "#319476",
          "content": "Cult of Me",
          "flip": false,
          "cardId": "Topic-17"
        },
        {
          "type": "predefined",
          "category": "Topic",
          "label": [],
          "color": "#319476",
          "content": "Deep Fake",
          "flip": false,
          "cardId": "Topic-18"
        },
        {
          "type": "predefined",
          "category": "Topic",
          "label": [],
          "color": "#319476",
          "content": "Disaster Magnet",
          "flip": false,
          "cardId": "Topic-19"
        },
        {
          "type": "predefined",
          "category": "Topic",
          "label": [],
          "color": "#319476",
          "content": "Double Standard",
          "flip": false,
          "cardId": "Topic-20"
        },
        {
          "type": "predefined",
          "category": "Topic",
          "label": [],
          "color": "#319476",
          "content": "Doubling Down",
          "flip": false,
          "cardId": "Topic-21"
        },
        {
          "type": "predefined",
          "category": "Topic",
          "label": [],
          "color": "#319476",
          "content": "Eat the Rich",
          "flip": false,
          "cardId": "Topic-22"
        },
        {
          "type": "predefined",
          "category": "Topic",
          "label": [],
          "color": "#319476",
          "content": "Echo Chamber",
          "flip": false,
          "cardId": "Topic-23"
        },
        {
          "type": "predefined",
          "category": "Topic",
          "label": [],
          "color": "#319476",
          "content": "Embarassing Moment",
          "flip": false,
          "cardId": "Topic-24"
        },
        {
          "type": "predefined",
          "category": "Topic",
          "label": [],
          "color": "#319476",
          "content": "Extreme Wellness",
          "flip": false,
          "cardId": "Topic-25"
        },
        {
          "type": "predefined",
          "category": "Topic",
          "label": [],
          "color": "#319476",
          "content": "Factoids & Factotums",
          "flip": false,
          "cardId": "Topic-26"
        },
        {
          "type": "predefined",
          "category": "Topic",
          "label": [],
          "color": "#319476",
          "content": "Fake News",
          "flip": false,
          "cardId": "Topic-27"
        },
        {
          "type": "predefined",
          "category": "Topic",
          "label": [],
          "color": "#319476",
          "content": "Follow the money",
          "flip": false,
          "cardId": "Topic-28"
        },
        {
          "type": "predefined",
          "category": "Topic",
          "label": [],
          "color": "#319476",
          "content": "Free Will",
          "flip": false,
          "cardId": "Topic-29"
        },
        {
          "type": "predefined",
          "category": "Topic",
          "label": [],
          "color": "#319476",
          "content": "Gig Economy",
          "flip": false,
          "cardId": "Topic-30"
        },
        {
          "type": "predefined",
          "category": "Topic",
          "label": [],
          "color": "#319476",
          "content": "Grassroots Propaganda",
          "flip": false,
          "cardId": "Topic-31"
        },
        {
          "type": "predefined",
          "category": "Topic",
          "label": [],
          "color": "#319476",
          "content": "Guilty Pleasure",
          "flip": false,
          "cardId": "Topic-32"
        },
        {
          "type": "predefined",
          "category": "Topic",
          "label": [],
          "color": "#319476",
          "content": "Hedonistic Treadmill",
          "flip": false,
          "cardId": "Topic-33"
        },
        {
          "type": "predefined",
          "category": "Topic",
          "label": [],
          "color": "#319476",
          "content": "Herd Immunity",
          "flip": false,
          "cardId": "Topic-34"
        },
        {
          "type": "predefined",
          "category": "Topic",
          "label": [],
          "color": "#319476",
          "content": "Herd Mentality",
          "flip": false,
          "cardId": "Topic-35"
        },
        {
          "type": "predefined",
          "category": "Topic",
          "label": [],
          "color": "#319476",
          "content": "Homeschooling",
          "flip": false,
          "cardId": "Topic-36"
        },
        {
          "type": "predefined",
          "category": "Topic",
          "label": [],
          "color": "#319476",
          "content": "Illusion of Continuity",
          "flip": false,
          "cardId": "Topic-37"
        },
        {
          "type": "predefined",
          "category": "Topic",
          "label": [],
          "color": "#319476",
          "content": "Imaginary Friend",
          "flip": false,
          "cardId": "Topic-38"
        },
        {
          "type": "predefined",
          "category": "Topic",
          "label": [],
          "color": "#319476",
          "content": "Immortality",
          "flip": false,
          "cardId": "Topic-39"
        },
        {
          "type": "predefined",
          "category": "Topic",
          "label": [],
          "color": "#319476",
          "content": "Impulse Control",
          "flip": false,
          "cardId": "Topic-40"
        },
        {
          "type": "predefined",
          "category": "Topic",
          "label": [],
          "color": "#319476",
          "content": "Income Inequality",
          "flip": false,
          "cardId": "Topic-41"
        },
        {
          "type": "predefined",
          "category": "Topic",
          "label": [],
          "color": "#319476",
          "content": "Individual vs Society",
          "flip": false,
          "cardId": "Topic-42"
        },
        {
          "type": "predefined",
          "category": "Topic",
          "label": [],
          "color": "#319476",
          "content": "Infinite Scroll",
          "flip": false,
          "cardId": "Topic-43"
        },
        {
          "type": "predefined",
          "category": "Topic",
          "label": [],
          "color": "#319476",
          "content": "Instant Gratification",
          "flip": false,
          "cardId": "Topic-44"
        },
        {
          "type": "predefined",
          "category": "Topic",
          "label": [],
          "color": "#319476",
          "content": "Instrumental Rationality",
          "flip": false,
          "cardId": "Topic-45"
        },
        {
          "type": "predefined",
          "category": "Topic",
          "label": [],
          "color": "#319476",
          "content": "Life after Death",
          "flip": false,
          "cardId": "Topic-46"
        },
        {
          "type": "predefined",
          "category": "Topic",
          "label": [],
          "color": "#319476",
          "content": "Love Octopus",
          "flip": false,
          "cardId": "Topic-47"
        },
        {
          "type": "predefined",
          "category": "Topic",
          "label": [],
          "color": "#319476",
          "content": "Lucid Dreaming",
          "flip": false,
          "cardId": "Topic-48"
        },
        {
          "type": "predefined",
          "category": "Topic",
          "label": [],
          "color": "#319476",
          "content": "Mass Customization",
          "flip": false,
          "cardId": "Topic-49"
        },
        {
          "type": "predefined",
          "category": "Topic",
          "label": [],
          "color": "#319476",
          "content": "Mass Migration",
          "flip": false,
          "cardId": "Topic-50"
        },
        {
          "type": "predefined",
          "category": "Topic",
          "label": [],
          "color": "#319476",
          "content": "Mass Optimization",
          "flip": false,
          "cardId": "Topic-51"
        },
        {
          "type": "predefined",
          "category": "Topic",
          "label": [],
          "color": "#319476",
          "content": "Matchmaking",
          "flip": false,
          "cardId": "Topic-52"
        },
        {
          "type": "predefined",
          "category": "Topic",
          "label": [],
          "color": "#319476",
          "content": "Mental Hygiene",
          "flip": false,
          "cardId": "Topic-53"
        },
        {
          "type": "predefined",
          "category": "Topic",
          "label": [],
          "color": "#319476",
          "content": "Metrosexual",
          "flip": false,
          "cardId": "Topic-54"
        },
        {
          "type": "predefined",
          "category": "Topic",
          "label": [],
          "color": "#319476",
          "content": "Microbiome",
          "flip": false,
          "cardId": "Topic-55"
        },
        {
          "type": "predefined",
          "category": "Topic",
          "label": [],
          "color": "#319476",
          "content": "Moral Bankruptcy",
          "flip": false,
          "cardId": "Topic-56"
        },
        {
          "type": "predefined",
          "category": "Topic",
          "label": [],
          "color": "#319476",
          "content": "Nature vs Nurture",
          "flip": false,
          "cardId": "Topic-57"
        },
        {
          "type": "predefined",
          "category": "Topic",
          "label": [],
          "color": "#319476",
          "content": "Obesity Epidemic",
          "flip": false,
          "cardId": "Topic-58"
        },
        {
          "type": "predefined",
          "category": "Topic",
          "label": [],
          "color": "#319476",
          "content": "Open Source",
          "flip": false,
          "cardId": "Topic-59"
        },
        {
          "type": "predefined",
          "category": "Topic",
          "label": [],
          "color": "#319476",
          "content": "Opportunity Cost",
          "flip": false,
          "cardId": "Topic-60"
        },
        {
          "type": "predefined",
          "category": "Topic",
          "label": [],
          "color": "#319476",
          "content": "Outrage Industrial Complex",
          "flip": false,
          "cardId": "Topic-61"
        },
        {
          "type": "predefined",
          "category": "Topic",
          "label": [],
          "color": "#319476",
          "content": "Overpopulation",
          "flip": false,
          "cardId": "Topic-62"
        },
        {
          "type": "predefined",
          "category": "Topic",
          "label": [],
          "color": "#319476",
          "content": "Overton Window",
          "flip": false,
          "cardId": "Topic-63"
        },
        {
          "type": "predefined",
          "category": "Topic",
          "label": [],
          "color": "#319476",
          "content": "Painkiller",
          "flip": false,
          "cardId": "Topic-64"
        },
        {
          "type": "predefined",
          "category": "Topic",
          "label": [],
          "color": "#319476",
          "content": "Persuasion Design",
          "flip": false,
          "cardId": "Topic-65"
        },
        {
          "type": "predefined",
          "category": "Topic",
          "label": [],
          "color": "#319476",
          "content": "Pet Art",
          "flip": false,
          "cardId": "Topic-66"
        },
        {
          "type": "predefined",
          "category": "Topic",
          "label": [],
          "color": "#319476",
          "content": "Plastic Surgery",
          "flip": false,
          "cardId": "Topic-67"
        },
        {
          "type": "predefined",
          "category": "Topic",
          "label": [],
          "color": "#319476",
          "content": "Playdates",
          "flip": false,
          "cardId": "Topic-68"
        },
        {
          "type": "predefined",
          "category": "Topic",
          "label": [],
          "color": "#319476",
          "content": "Poverty Gap",
          "flip": false,
          "cardId": "Topic-69"
        },
        {
          "type": "predefined",
          "category": "Topic",
          "label": [],
          "color": "#319476",
          "content": "Procrastination",
          "flip": false,
          "cardId": "Topic-70"
        },
        {
          "type": "predefined",
          "category": "Topic",
          "label": [],
          "color": "#319476",
          "content": "Propaganda",
          "flip": false,
          "cardId": "Topic-71"
        },
        {
          "type": "predefined",
          "category": "Topic",
          "label": [],
          "color": "#319476",
          "content": "Refreshingly Horrific",
          "flip": false,
          "cardId": "Topic-72"
        },
        {
          "type": "predefined",
          "category": "Topic",
          "label": [],
          "color": "#319476",
          "content": "Revisionist History",
          "flip": false,
          "cardId": "Topic-73"
        },
        {
          "type": "predefined",
          "category": "Topic",
          "label": [],
          "color": "#319476",
          "content": "Ruin Porn",
          "flip": false,
          "cardId": "Topic-74"
        },
        {
          "type": "predefined",
          "category": "Topic",
          "label": [],
          "color": "#319476",
          "content": "Scarcity vs Abundance",
          "flip": false,
          "cardId": "Topic-75"
        },
        {
          "type": "predefined",
          "category": "Topic",
          "label": [],
          "color": "#319476",
          "content": "Schadenfreude",
          "flip": false,
          "cardId": "Topic-76"
        },
        {
          "type": "predefined",
          "category": "Topic",
          "label": [],
          "color": "#319476",
          "content": "Sharing Economy",
          "flip": false,
          "cardId": "Topic-77"
        },
        {
          "type": "predefined",
          "category": "Topic",
          "label": [],
          "color": "#319476",
          "content": "Sink Hole",
          "flip": false,
          "cardId": "Topic-78"
        },
        {
          "type": "predefined",
          "category": "Topic",
          "label": [],
          "color": "#319476",
          "content": "Social Cataclysm",
          "flip": false,
          "cardId": "Topic-79"
        },
        {
          "type": "predefined",
          "category": "Topic",
          "label": [],
          "color": "#319476",
          "content": "Social Distancing",
          "flip": false,
          "cardId": "Topic-80"
        },
        {
          "type": "predefined",
          "category": "Topic",
          "label": [],
          "color": "#319476",
          "content": "Spontaneous Combustion",
          "flip": false,
          "cardId": "Topic-81"
        },
        {
          "type": "predefined",
          "category": "Topic",
          "label": [],
          "color": "#319476",
          "content": "Strange Hobbies",
          "flip": false,
          "cardId": "Topic-82"
        },
        {
          "type": "predefined",
          "category": "Topic",
          "label": [],
          "color": "#319476",
          "content": "Stream of Consciousness",
          "flip": false,
          "cardId": "Topic-83"
        },
        {
          "type": "predefined",
          "category": "Topic",
          "label": [],
          "color": "#319476",
          "content": "Surveillance Capitalism",
          "flip": false,
          "cardId": "Topic-84"
        },
        {
          "type": "predefined",
          "category": "Topic",
          "label": [],
          "color": "#319476",
          "content": "Swiping Left",
          "flip": false,
          "cardId": "Topic-85"
        },
        {
          "type": "predefined",
          "category": "Topic",
          "label": [],
          "color": "#319476",
          "content": "Teachable Moment",
          "flip": false,
          "cardId": "Topic-86"
        },
        {
          "type": "predefined",
          "category": "Topic",
          "label": [],
          "color": "#319476",
          "content": "Tele-medicine",
          "flip": false,
          "cardId": "Topic-87"
        },
        {
          "type": "predefined",
          "category": "Topic",
          "label": [],
          "color": "#319476",
          "content": "Toxic Relationship",
          "flip": false,
          "cardId": "Topic-88"
        },
        {
          "type": "predefined",
          "category": "Topic",
          "label": [],
          "color": "#319476",
          "content": "Transcendental Meditation",
          "flip": false,
          "cardId": "Topic-89"
        },
        {
          "type": "predefined",
          "category": "Topic",
          "label": [],
          "color": "#319476",
          "content": "Trolling",
          "flip": false,
          "cardId": "Topic-90"
        },
        {
          "type": "predefined",
          "category": "Topic",
          "label": [],
          "color": "#319476",
          "content": "Tyranny of the Crowd",
          "flip": false,
          "cardId": "Topic-91"
        },
        {
          "type": "predefined",
          "category": "Topic",
          "label": [],
          "color": "#319476",
          "content": "Victim Blaming",
          "flip": false,
          "cardId": "Topic-92"
        },
        {
          "type": "predefined",
          "category": "Topic",
          "label": [],
          "color": "#319476",
          "content": "Viral Marketing",
          "flip": false,
          "cardId": "Topic-93"
        },
        {
          "type": "predefined",
          "category": "Topic",
          "label": [],
          "color": "#319476",
          "content": "Virtue Signalling",
          "flip": false,
          "cardId": "Topic-94"
        },
        {
          "type": "predefined",
          "category": "Topic",
          "label": [],
          "color": "#319476",
          "content": "Weaponized Nostalgia",
          "flip": false,
          "cardId": "Topic-95"
        },
        {
          "type": "predefined",
          "category": "Topic",
          "label": [],
          "color": "#319476",
          "content": "Worst Job Ever",
          "flip": false,
          "cardId": "Topic-96"
        },
        {
          "type": "predefined",
          "category": "Topic",
          "label": [],
          "color": "#319476",
          "content": "Zoom Bombing",
          "flip": false,
          "cardId": "Topic-97"
        }
      ],
      "Premises": [
        {
          "type": "predefined",
          "category": "Premises",
          "label": [
            "Marker"
          ],
          "color": null,
          "content": "Premises",
          "flip": false,
          "cardId": "Premises-0"
        },
        {
          "type": "predefined",
          "category": "Premises",
          "label": [],
          "color": "#f59e42",
          "content": "Absolute power corrupts absolutely",
          "flip": false,
          "cardId": "Premises-1"
        },
        {
          "type": "predefined",
          "category": "Premises",
          "label": [],
          "color": "#f59e42",
          "content": "Advice is what we ask for when we already know the answer but wish we didn’t",
          "flip": false,
          "cardId": "Premises-2"
        },
        {
          "type": "predefined",
          "category": "Premises",
          "label": [],
          "color": "#f59e42",
          "content": "All cruelty springs from weakness",
          "flip": false,
          "cardId": "Premises-3"
        },
        {
          "type": "predefined",
          "category": "Premises",
          "label": [],
          "color": "#f59e42",
          "content": "It is easier to resist at the beginning than at the end",
          "flip": false,
          "cardId": "Premises-4"
        },
        {
          "type": "predefined",
          "category": "Premises",
          "label": [],
          "color": "#f59e42",
          "content": "An artist never really finishes his work, he merely abandons it",
          "flip": false,
          "cardId": "Premises-5"
        },
        {
          "type": "predefined",
          "category": "Premises",
          "label": [],
          "color": "#f59e42",
          "content": "An escape from reality leads to a day of reckoning",
          "flip": false,
          "cardId": "Premises-6"
        },
        {
          "type": "predefined",
          "category": "Premises",
          "label": [],
          "color": "#f59e42",
          "content": "Art is a lie that makes us realise the truth",
          "flip": false,
          "cardId": "Premises-7"
        },
        {
          "type": "predefined",
          "category": "Premises",
          "label": [],
          "color": "#f59e42",
          "content": "Blind trust leads to destruction",
          "flip": false,
          "cardId": "Premises-8"
        },
        {
          "type": "predefined",
          "category": "Premises",
          "label": [],
          "color": "#f59e42",
          "content": "Better to die on your feet than live on your knees",
          "flip": false,
          "cardId": "Premises-9"
        },
        {
          "type": "predefined",
          "category": "Premises",
          "label": [],
          "color": "#f59e42",
          "content": "Death is not the worst that can happen",
          "flip": false,
          "cardId": "Premises-10"
        },
        {
          "type": "predefined",
          "category": "Premises",
          "label": [],
          "color": "#f59e42",
          "content": "There is no expedient to which a man will not resort to avoid the real labor of thinking",
          "flip": false,
          "cardId": "Premises-11"
        },
        {
          "type": "predefined",
          "category": "Premises",
          "label": [],
          "color": "#f59e42",
          "content": "Where all think alike, no one thinks very much",
          "flip": false,
          "cardId": "Premises-12"
        },
        {
          "type": "predefined",
          "category": "Premises",
          "label": [],
          "color": "#f59e42",
          "content": "The lesser evil always paves the way for the greater evil",
          "flip": false,
          "cardId": "Premises-13"
        },
        {
          "type": "predefined",
          "category": "Premises",
          "label": [],
          "color": "#f59e42",
          "content": "Power concedes nothing without a demand",
          "flip": false,
          "cardId": "Premises-14"
        },
        {
          "type": "predefined",
          "category": "Premises",
          "label": [],
          "color": "#f59e42",
          "content": "Evil flourishes when good men do nothing",
          "flip": false,
          "cardId": "Premises-15"
        },
        {
          "type": "predefined",
          "category": "Premises",
          "label": [],
          "color": "#f59e42",
          "content": "We are militarising our society at home to protect our geopolitical alliances abroad",
          "flip": false,
          "cardId": "Premises-16"
        },
        {
          "type": "predefined",
          "category": "Premises",
          "label": [],
          "color": "#f59e42",
          "content": "Money Talks / Wealth is power",
          "flip": false,
          "cardId": "Premises-17"
        },
        {
          "type": "predefined",
          "category": "Premises",
          "label": [],
          "color": "#f59e42",
          "content": "To those who have, more is given",
          "flip": false,
          "cardId": "Premises-18"
        },
        {
          "type": "predefined",
          "category": "Premises",
          "label": [],
          "color": "#f59e42",
          "content": "Enjoy the little things in life, for one day you’ll look back and realise they were big things",
          "flip": false,
          "cardId": "Premises-19"
        },
        {
          "type": "predefined",
          "category": "Premises",
          "label": [],
          "color": "#f59e42",
          "content": "It is easier to love humanity than to love your neighbour",
          "flip": false,
          "cardId": "Premises-20"
        },
        {
          "type": "predefined",
          "category": "Premises",
          "label": [],
          "color": "#f59e42",
          "content": "A citizen of the world is a citizen of nowhere",
          "flip": false,
          "cardId": "Premises-21"
        },
        {
          "type": "predefined",
          "category": "Premises",
          "label": [],
          "color": "#f59e42",
          "content": "The road to bell is paved with good intentions",
          "flip": false,
          "cardId": "Premises-22"
        },
        {
          "type": "predefined",
          "category": "Premises",
          "label": [],
          "color": "#f59e42",
          "content": "Too humble is half-proud.",
          "flip": false,
          "cardId": "Premises-23"
        },
        {
          "type": "predefined",
          "category": "Premises",
          "label": [],
          "color": "#f59e42",
          "content": "Beware of your friends, not your enemies.",
          "flip": false,
          "cardId": "Premises-24"
        },
        {
          "type": "predefined",
          "category": "Premises",
          "label": [],
          "color": "#f59e42",
          "content": "An idea isn’t responsible for the people who believe in it",
          "flip": false,
          "cardId": "Premises-25"
        },
        {
          "type": "predefined",
          "category": "Premises",
          "label": [],
          "color": "#f59e42",
          "content": "Rules are for the obedience of fools and the guidance of wise men",
          "flip": false,
          "cardId": "Premises-26"
        },
        {
          "type": "predefined",
          "category": "Premises",
          "label": [],
          "color": "#f59e42",
          "content": "Justice is what love looks like in public",
          "flip": false,
          "cardId": "Premises-27"
        },
        {
          "type": "predefined",
          "category": "Premises",
          "label": [],
          "color": "#f59e42",
          "content": "Not strong morals, but weak stomachs, keep us from being vultures",
          "flip": false,
          "cardId": "Premises-28"
        },
        {
          "type": "predefined",
          "category": "Premises",
          "label": [],
          "color": "#f59e42",
          "content": "All work is the avoidance of harder work",
          "flip": false,
          "cardId": "Premises-29"
        },
        {
          "type": "predefined",
          "category": "Premises",
          "label": [],
          "color": "#f59e42",
          "content": "Fortune cannot take away what she did not give",
          "flip": false,
          "cardId": "Premises-30"
        },
        {
          "type": "predefined",
          "category": "Premises",
          "label": [],
          "color": "#f59e42",
          "content": "Freedom is not procured by indulgence but by controlling desire",
          "flip": false,
          "cardId": "Premises-31"
        },
        {
          "type": "predefined",
          "category": "Premises",
          "label": [],
          "color": "#f59e42",
          "content": "The best time is stolen time",
          "flip": false,
          "cardId": "Premises-32"
        },
        {
          "type": "predefined",
          "category": "Premises",
          "label": [],
          "color": "#f59e42",
          "content": "Of all the ways to avoid living, perfect discipline is the most admired",
          "flip": false,
          "cardId": "Premises-33"
        },
        {
          "type": "predefined",
          "category": "Premises",
          "label": [],
          "color": "#f59e42",
          "content": "The first abuse of power is not realizing that you have it",
          "flip": false,
          "cardId": "Premises-34"
        },
        {
          "type": "predefined",
          "category": "Premises",
          "label": [],
          "color": "#f59e42",
          "content": "To be sincere is one thing; to practice Sincerity is to burden everyone else with believing you",
          "flip": false,
          "cardId": "Premises-35"
        },
        {
          "type": "predefined",
          "category": "Premises",
          "label": [],
          "color": "#f59e42",
          "content": "Water deepens where it has to wait",
          "flip": false,
          "cardId": "Premises-36"
        },
        {
          "type": "predefined",
          "category": "Premises",
          "label": [],
          "color": "#f59e42",
          "content": "There are silences harder to take back than words",
          "flip": false,
          "cardId": "Premises-37"
        },
        {
          "type": "predefined",
          "category": "Premises",
          "label": [],
          "color": "#f59e42",
          "content": "Each lock makes two prisons",
          "flip": false,
          "cardId": "Premises-38"
        },
        {
          "type": "predefined",
          "category": "Premises",
          "label": [],
          "color": "#f59e42",
          "content": "The ruts are deepest in the middle of the road",
          "flip": false,
          "cardId": "Premises-39"
        },
        {
          "type": "predefined",
          "category": "Premises",
          "label": [],
          "color": "#f59e42",
          "content": "We sell our time to get enough money to buy it back",
          "flip": false,
          "cardId": "Premises-40"
        },
        {
          "type": "predefined",
          "category": "Premises",
          "label": [],
          "color": "#f59e42",
          "content": "A man can be happy with any woman, so long as he does not love her",
          "flip": false,
          "cardId": "Premises-41"
        },
        {
          "type": "predefined",
          "category": "Premises",
          "label": [],
          "color": "#f59e42",
          "content": "One cannot learn what they think they already know",
          "flip": false,
          "cardId": "Premises-42"
        },
        {
          "type": "predefined",
          "category": "Premises",
          "label": [],
          "color": "#f59e42",
          "content": "Wealth consists not in having great possessions, but in having few wants",
          "flip": false,
          "cardId": "Premises-43"
        },
        {
          "type": "predefined",
          "category": "Premises",
          "label": [],
          "color": "#f59e42",
          "content": "Happiness isn’t getting what you want, it’s wanting what you’ve got",
          "flip": false,
          "cardId": "Premises-44"
        },
        {
          "type": "predefined",
          "category": "Premises",
          "label": [],
          "color": "#f59e42",
          "content": "The search for happiness is one of the chief sources of unhappiness",
          "flip": false,
          "cardId": "Premises-45"
        },
        {
          "type": "predefined",
          "category": "Premises",
          "label": [],
          "color": "#f59e42",
          "content": "A great success is made up of an aggregation of little ones",
          "flip": false,
          "cardId": "Premises-46"
        },
        {
          "type": "predefined",
          "category": "Premises",
          "label": [],
          "color": "#f59e42",
          "content": "A hero is one who knows how to hang on one minute longer",
          "flip": false,
          "cardId": "Premises-47"
        },
        {
          "type": "predefined",
          "category": "Premises",
          "label": [],
          "color": "#f59e42",
          "content": "Excessive liberty leads both nations and individuals into excessive slavery",
          "flip": false,
          "cardId": "Premises-48"
        },
        {
          "type": "predefined",
          "category": "Premises",
          "label": [],
          "color": "#f59e42",
          "content": "Facts are the enemies of the truth",
          "flip": false,
          "cardId": "Premises-49"
        },
        {
          "type": "predefined",
          "category": "Premises",
          "label": [],
          "color": "#f59e42",
          "content": "A lie has speed, but truth has endurance",
          "flip": false,
          "cardId": "Premises-50"
        },
        {
          "type": "predefined",
          "category": "Premises",
          "label": [],
          "color": "#f59e42",
          "content": "Good fences make good neighbours",
          "flip": false,
          "cardId": "Premises-51"
        },
        {
          "type": "predefined",
          "category": "Premises",
          "label": [],
          "color": "#f59e42",
          "content": "Great love defies even death",
          "flip": false,
          "cardId": "Premises-52"
        },
        {
          "type": "predefined",
          "category": "Premises",
          "label": [],
          "color": "#f59e42",
          "content": "He that is discontented in one place will seldom be content in another",
          "flip": false,
          "cardId": "Premises-53"
        },
        {
          "type": "predefined",
          "category": "Premises",
          "label": [],
          "color": "#f59e42",
          "content": "Hell hath no fury like a woman scorned",
          "flip": false,
          "cardId": "Premises-54"
        },
        {
          "type": "predefined",
          "category": "Premises",
          "label": [],
          "color": "#f59e42",
          "content": "How bitter a thing it is to look into happiness through another man’s eyes",
          "flip": false,
          "cardId": "Premises-55"
        },
        {
          "type": "predefined",
          "category": "Premises",
          "label": [],
          "color": "#f59e42",
          "content": "Jealousy destroys itself and the object of its affection",
          "flip": false,
          "cardId": "Premises-56"
        },
        {
          "type": "predefined",
          "category": "Premises",
          "label": [],
          "color": "#f59e42",
          "content": "Liberty is the right to do what the law permits",
          "flip": false,
          "cardId": "Premises-57"
        },
        {
          "type": "predefined",
          "category": "Premises",
          "label": [],
          "color": "#f59e42",
          "content": "The end justifies the means",
          "flip": false,
          "cardId": "Premises-58"
        },
        {
          "type": "predefined",
          "category": "Premises",
          "label": [],
          "color": "#f59e42",
          "content": "Crisis presents opportunity",
          "flip": false,
          "cardId": "Premises-59"
        },
        {
          "type": "predefined",
          "category": "Premises",
          "label": [],
          "color": "#f59e42",
          "content": "Sometimes one pays dearest for that whish is free",
          "flip": false,
          "cardId": "Premises-60"
        },
        {
          "type": "predefined",
          "category": "Premises",
          "label": [],
          "color": "#f59e42",
          "content": "The cruelest lies are often told in silence",
          "flip": false,
          "cardId": "Premises-61"
        },
        {
          "type": "predefined",
          "category": "Premises",
          "label": [],
          "color": "#f59e42",
          "content": "What doesn't kill you makes you stronger",
          "flip": false,
          "cardId": "Premises-62"
        },
        {
          "type": "predefined",
          "category": "Premises",
          "label": [],
          "color": "#f59e42",
          "content": "Don't judge a book by its cover",
          "flip": false,
          "cardId": "Premises-63"
        },
        {
          "type": "predefined",
          "category": "Premises",
          "label": [],
          "color": "#f59e42",
          "content": "Necessity is the mother of invention",
          "flip": false,
          "cardId": "Premises-64"
        },
        {
          "type": "predefined",
          "category": "Premises",
          "label": [],
          "color": "#f59e42",
          "content": "Don't count your chickens until they are hatched",
          "flip": false,
          "cardId": "Premises-65"
        },
        {
          "type": "predefined",
          "category": "Premises",
          "label": [],
          "color": "#f59e42",
          "content": "Blood is thicker than water",
          "flip": false,
          "cardId": "Premises-66"
        },
        {
          "type": "predefined",
          "category": "Premises",
          "label": [],
          "color": "#f59e42",
          "content": "We have fewer friends than we imagine but more than we know",
          "flip": false,
          "cardId": "Premises-67"
        },
        {
          "type": "predefined",
          "category": "Premises",
          "label": [],
          "color": "#f59e42",
          "content": "It is more shameful to distrust one's friends than to be deceived by them",
          "flip": false,
          "cardId": "Premises-68"
        },
        {
          "type": "predefined",
          "category": "Premises",
          "label": [],
          "color": "#f59e42",
          "content": "In prosperity our friends know us; in adversity we know our friends",
          "flip": false,
          "cardId": "Premises-69"
        },
        {
          "type": "predefined",
          "category": "Premises",
          "label": [],
          "color": "#f59e42",
          "content": "How much easier to make pets of our friends' weaknesses than to put up with their strengths",
          "flip": false,
          "cardId": "Premises-70"
        },
        {
          "type": "predefined",
          "category": "Premises",
          "label": [],
          "color": "#f59e42",
          "content": "There is no feeling of liberty like that of escape from half-friends",
          "flip": false,
          "cardId": "Premises-71"
        },
        {
          "type": "predefined",
          "category": "Premises",
          "label": [],
          "color": "#f59e42",
          "content": "He whose friendship is worth having, must hate and be hated",
          "flip": false,
          "cardId": "Premises-72"
        },
        {
          "type": "predefined",
          "category": "Premises",
          "label": [],
          "color": "#f59e42",
          "content": "It is easier to forgive an enemy than to forgive a friend",
          "flip": false,
          "cardId": "Premises-73"
        },
        {
          "type": "predefined",
          "category": "Premises",
          "label": [],
          "color": "#f59e42",
          "content": "He that has a thousand friends has not a friend to spare; and he who has one enemy will meet him everywhere",
          "flip": false,
          "cardId": "Premises-74"
        },
        {
          "type": "predefined",
          "category": "Premises",
          "label": [],
          "color": "#f59e42",
          "content": "Pay attention to your enemies for they are the ones to first discover your mistakes",
          "flip": false,
          "cardId": "Premises-75"
        },
        {
          "type": "predefined",
          "category": "Premises",
          "label": [],
          "color": "#f59e42",
          "content": "No viper so little but hath its venom",
          "flip": false,
          "cardId": "Premises-76"
        },
        {
          "type": "predefined",
          "category": "Premises",
          "label": [],
          "color": "#f59e42",
          "content": "God created the world but it is the devil who keeps it going",
          "flip": false,
          "cardId": "Premises-77"
        },
        {
          "type": "predefined",
          "category": "Premises",
          "label": [],
          "color": "#f59e42",
          "content": "Temptation laughs at the fool who takes it seriously",
          "flip": false,
          "cardId": "Premises-78"
        },
        {
          "type": "predefined",
          "category": "Premises",
          "label": [],
          "color": "#f59e42",
          "content": "Virtue is so praiseworthy that wicked people practise it from self-interest",
          "flip": false,
          "cardId": "Premises-79"
        },
        {
          "type": "predefined",
          "category": "Premises",
          "label": [],
          "color": "#f59e42",
          "content": "Virtue itself needs limits",
          "flip": false,
          "cardId": "Premises-80"
        },
        {
          "type": "predefined",
          "category": "Premises",
          "label": [],
          "color": "#f59e42",
          "content": "If you are not paying for the product, you are the product",
          "flip": false,
          "cardId": "Premises-81"
        },
        {
          "type": "predefined",
          "category": "Premises",
          "label": [],
          "color": "#f59e42",
          "content": "Not planning ahead is planning for failure",
          "flip": false,
          "cardId": "Premises-82"
        },
        {
          "type": "predefined",
          "category": "Premises",
          "label": [],
          "color": "#f59e42",
          "content": "Monsters are made; they don't come out of nowhere",
          "flip": false,
          "cardId": "Premises-83"
        },
        {
          "type": "predefined",
          "category": "Premises",
          "label": [],
          "color": "#f59e42",
          "content": "Lies are not used to convince others but to undermine truth itself",
          "flip": false,
          "cardId": "Premises-84"
        },
        {
          "type": "predefined",
          "category": "Premises",
          "label": [],
          "color": "#f59e42",
          "content": "If you aren't paying then you're not the customer - you're the product",
          "flip": false,
          "cardId": "Premises-85"
        },
        {
          "type": "predefined",
          "category": "Premises",
          "label": [],
          "color": "#f59e42",
          "content": "You can calculate the worth of a man by the number of his enemies, and the importance of a work of art by the harm that is spoken of it.",
          "flip": false,
          "cardId": "Premises-86"
        },
        {
          "type": "predefined",
          "category": "Premises",
          "label": [],
          "color": "#f59e42",
          "content": "Those who foolishly sought power by riding the back of the tiger ended up inside",
          "flip": false,
          "cardId": "Premises-87"
        },
        {
          "type": "predefined",
          "category": "Premises",
          "label": [],
          "color": "#f59e42",
          "content": "It’s only when the tide goes out that you learn who’s been swimming naked",
          "flip": false,
          "cardId": "Premises-88"
        },
        {
          "type": "predefined",
          "category": "Premises",
          "label": [],
          "color": "#f59e42",
          "content": "One man's trash is another man's treasure",
          "flip": false,
          "cardId": "Premises-89"
        },
        {
          "type": "predefined",
          "category": "Premises",
          "label": [],
          "color": "#f59e42",
          "content": "The road to Hell is paved with good intentions",
          "flip": false,
          "cardId": "Premises-90"
        },
        {
          "type": "predefined",
          "category": "Premises",
          "label": [],
          "color": "#f59e42",
          "content": "If you don't pay anything then you are the product",
          "flip": false,
          "cardId": "Premises-91"
        }
      ],
      "Modifier": [
        {
          "type": "predefined",
          "category": "Modifier",
          "label": [
            "Marker"
          ],
          "color": null,
          "content": "Modifier",
          "flip": false,
          "cardId": "Modifier-0"
        },
        {
          "type": "predefined",
          "category": "Modifier",
          "label": [],
          "color": "#e3f542",
          "content": "Absentminded",
          "flip": false,
          "cardId": "Modifier-1"
        },
        {
          "type": "predefined",
          "category": "Modifier",
          "label": [],
          "color": "#e3f542",
          "content": "Alcoholic",
          "flip": false,
          "cardId": "Modifier-2"
        },
        {
          "type": "predefined",
          "category": "Modifier",
          "label": [],
          "color": "#e3f542",
          "content": "Amorous",
          "flip": false,
          "cardId": "Modifier-3"
        },
        {
          "type": "predefined",
          "category": "Modifier",
          "label": [],
          "color": "#e3f542",
          "content": "Arrogant",
          "flip": false,
          "cardId": "Modifier-4"
        },
        {
          "type": "predefined",
          "category": "Modifier",
          "label": [],
          "color": "#e3f542",
          "content": "Atrocious",
          "flip": false,
          "cardId": "Modifier-5"
        },
        {
          "type": "predefined",
          "category": "Modifier",
          "label": [],
          "color": "#e3f542",
          "content": "Atypical",
          "flip": false,
          "cardId": "Modifier-6"
        },
        {
          "type": "predefined",
          "category": "Modifier",
          "label": [],
          "color": "#e3f542",
          "content": "Awkward",
          "flip": false,
          "cardId": "Modifier-7"
        },
        {
          "type": "predefined",
          "category": "Modifier",
          "label": [],
          "color": "#e3f542",
          "content": "Blackmailed",
          "flip": false,
          "cardId": "Modifier-8"
        },
        {
          "type": "predefined",
          "category": "Modifier",
          "label": [],
          "color": "#e3f542",
          "content": "Blind",
          "flip": false,
          "cardId": "Modifier-9"
        },
        {
          "type": "predefined",
          "category": "Modifier",
          "label": [],
          "color": "#e3f542",
          "content": "Bloodthirsty",
          "flip": false,
          "cardId": "Modifier-10"
        },
        {
          "type": "predefined",
          "category": "Modifier",
          "label": [],
          "color": "#e3f542",
          "content": "Brave",
          "flip": false,
          "cardId": "Modifier-11"
        },
        {
          "type": "predefined",
          "category": "Modifier",
          "label": [],
          "color": "#e3f542",
          "content": "Caustic",
          "flip": false,
          "cardId": "Modifier-12"
        },
        {
          "type": "predefined",
          "category": "Modifier",
          "label": [],
          "color": "#e3f542",
          "content": "Clueless",
          "flip": false,
          "cardId": "Modifier-13"
        },
        {
          "type": "predefined",
          "category": "Modifier",
          "label": [],
          "color": "#e3f542",
          "content": "Cruel",
          "flip": false,
          "cardId": "Modifier-14"
        },
        {
          "type": "predefined",
          "category": "Modifier",
          "label": [],
          "color": "#e3f542",
          "content": "Cursed",
          "flip": false,
          "cardId": "Modifier-15"
        },
        {
          "type": "predefined",
          "category": "Modifier",
          "label": [],
          "color": "#e3f542",
          "content": "Damaged",
          "flip": false,
          "cardId": "Modifier-16"
        },
        {
          "type": "predefined",
          "category": "Modifier",
          "label": [],
          "color": "#e3f542",
          "content": "Deranged",
          "flip": false,
          "cardId": "Modifier-17"
        },
        {
          "type": "predefined",
          "category": "Modifier",
          "label": [],
          "color": "#e3f542",
          "content": "Desperate",
          "flip": false,
          "cardId": "Modifier-18"
        },
        {
          "type": "predefined",
          "category": "Modifier",
          "label": [],
          "color": "#e3f542",
          "content": "Devoted",
          "flip": false,
          "cardId": "Modifier-19"
        },
        {
          "type": "predefined",
          "category": "Modifier",
          "label": [],
          "color": "#e3f542",
          "content": "Diguised",
          "flip": false,
          "cardId": "Modifier-20"
        },
        {
          "type": "predefined",
          "category": "Modifier",
          "label": [],
          "color": "#e3f542",
          "content": "Down & Out",
          "flip": false,
          "cardId": "Modifier-21"
        },
        {
          "type": "predefined",
          "category": "Modifier",
          "label": [],
          "color": "#e3f542",
          "content": "Dysfunctional",
          "flip": false,
          "cardId": "Modifier-22"
        },
        {
          "type": "predefined",
          "category": "Modifier",
          "label": [],
          "color": "#e3f542",
          "content": "Eternal optimist",
          "flip": false,
          "cardId": "Modifier-23"
        },
        {
          "type": "predefined",
          "category": "Modifier",
          "label": [],
          "color": "#e3f542",
          "content": "Exiled",
          "flip": false,
          "cardId": "Modifier-24"
        },
        {
          "type": "predefined",
          "category": "Modifier",
          "label": [],
          "color": "#e3f542",
          "content": "Extra-ordinary",
          "flip": false,
          "cardId": "Modifier-25"
        },
        {
          "type": "predefined",
          "category": "Modifier",
          "label": [],
          "color": "#e3f542",
          "content": "Extroverted",
          "flip": false,
          "cardId": "Modifier-26"
        },
        {
          "type": "predefined",
          "category": "Modifier",
          "label": [],
          "color": "#e3f542",
          "content": "Forgotten",
          "flip": false,
          "cardId": "Modifier-27"
        },
        {
          "type": "predefined",
          "category": "Modifier",
          "label": [],
          "color": "#e3f542",
          "content": "Forlorn",
          "flip": false,
          "cardId": "Modifier-28"
        },
        {
          "type": "predefined",
          "category": "Modifier",
          "label": [],
          "color": "#e3f542",
          "content": "Greedy",
          "flip": false,
          "cardId": "Modifier-29"
        },
        {
          "type": "predefined",
          "category": "Modifier",
          "label": [],
          "color": "#e3f542",
          "content": "Guilt ridden",
          "flip": false,
          "cardId": "Modifier-30"
        },
        {
          "type": "predefined",
          "category": "Modifier",
          "label": [],
          "color": "#e3f542",
          "content": "Hippy",
          "flip": false,
          "cardId": "Modifier-31"
        },
        {
          "type": "predefined",
          "category": "Modifier",
          "label": [],
          "color": "#e3f542",
          "content": "Hypochondriac",
          "flip": false,
          "cardId": "Modifier-32"
        },
        {
          "type": "predefined",
          "category": "Modifier",
          "label": [],
          "color": "#e3f542",
          "content": "Impulsive",
          "flip": false,
          "cardId": "Modifier-33"
        },
        {
          "type": "predefined",
          "category": "Modifier",
          "label": [],
          "color": "#e3f542",
          "content": "In Hiding",
          "flip": false,
          "cardId": "Modifier-34"
        },
        {
          "type": "predefined",
          "category": "Modifier",
          "label": [],
          "color": "#e3f542",
          "content": "Incompetent",
          "flip": false,
          "cardId": "Modifier-35"
        },
        {
          "type": "predefined",
          "category": "Modifier",
          "label": [],
          "color": "#e3f542",
          "content": "Incomplete",
          "flip": false,
          "cardId": "Modifier-36"
        },
        {
          "type": "predefined",
          "category": "Modifier",
          "label": [],
          "color": "#e3f542",
          "content": "Inept",
          "flip": false,
          "cardId": "Modifier-37"
        },
        {
          "type": "predefined",
          "category": "Modifier",
          "label": [],
          "color": "#e3f542",
          "content": "Insecure",
          "flip": false,
          "cardId": "Modifier-38"
        },
        {
          "type": "predefined",
          "category": "Modifier",
          "label": [],
          "color": "#e3f542",
          "content": "Invisible",
          "flip": false,
          "cardId": "Modifier-39"
        },
        {
          "type": "predefined",
          "category": "Modifier",
          "label": [],
          "color": "#e3f542",
          "content": "Jaded",
          "flip": false,
          "cardId": "Modifier-40"
        },
        {
          "type": "predefined",
          "category": "Modifier",
          "label": [],
          "color": "#e3f542",
          "content": "Kickass",
          "flip": false,
          "cardId": "Modifier-41"
        },
        {
          "type": "predefined",
          "category": "Modifier",
          "label": [],
          "color": "#e3f542",
          "content": "Know it all",
          "flip": false,
          "cardId": "Modifier-42"
        },
        {
          "type": "predefined",
          "category": "Modifier",
          "label": [],
          "color": "#e3f542",
          "content": "Kooky",
          "flip": false,
          "cardId": "Modifier-43"
        },
        {
          "type": "predefined",
          "category": "Modifier",
          "label": [],
          "color": "#e3f542",
          "content": "Lazy",
          "flip": false,
          "cardId": "Modifier-44"
        },
        {
          "type": "predefined",
          "category": "Modifier",
          "label": [],
          "color": "#e3f542",
          "content": "Lustful",
          "flip": false,
          "cardId": "Modifier-45"
        },
        {
          "type": "predefined",
          "category": "Modifier",
          "label": [],
          "color": "#e3f542",
          "content": "Macho",
          "flip": false,
          "cardId": "Modifier-46"
        },
        {
          "type": "predefined",
          "category": "Modifier",
          "label": [],
          "color": "#e3f542",
          "content": "Messianic",
          "flip": false,
          "cardId": "Modifier-47"
        },
        {
          "type": "predefined",
          "category": "Modifier",
          "label": [],
          "color": "#e3f542",
          "content": "Mysterious",
          "flip": false,
          "cardId": "Modifier-48"
        },
        {
          "type": "predefined",
          "category": "Modifier",
          "label": [],
          "color": "#e3f542",
          "content": "Narcissist",
          "flip": false,
          "cardId": "Modifier-49"
        },
        {
          "type": "predefined",
          "category": "Modifier",
          "label": [],
          "color": "#e3f542",
          "content": "Nature nerd",
          "flip": false,
          "cardId": "Modifier-50"
        },
        {
          "type": "predefined",
          "category": "Modifier",
          "label": [],
          "color": "#e3f542",
          "content": "Ne're do well",
          "flip": false,
          "cardId": "Modifier-51"
        },
        {
          "type": "predefined",
          "category": "Modifier",
          "label": [],
          "color": "#e3f542",
          "content": "Obstinate",
          "flip": false,
          "cardId": "Modifier-52"
        },
        {
          "type": "predefined",
          "category": "Modifier",
          "label": [],
          "color": "#e3f542",
          "content": "On the run",
          "flip": false,
          "cardId": "Modifier-53"
        },
        {
          "type": "predefined",
          "category": "Modifier",
          "label": [],
          "color": "#e3f542",
          "content": "Overworked",
          "flip": false,
          "cardId": "Modifier-54"
        },
        {
          "type": "predefined",
          "category": "Modifier",
          "label": [],
          "color": "#e3f542",
          "content": "Pathological",
          "flip": false,
          "cardId": "Modifier-55"
        },
        {
          "type": "predefined",
          "category": "Modifier",
          "label": [],
          "color": "#e3f542",
          "content": "Reluctant",
          "flip": false,
          "cardId": "Modifier-56"
        },
        {
          "type": "predefined",
          "category": "Modifier",
          "label": [],
          "color": "#e3f542",
          "content": "Selfish",
          "flip": false,
          "cardId": "Modifier-57"
        },
        {
          "type": "predefined",
          "category": "Modifier",
          "label": [],
          "color": "#e3f542",
          "content": "Slippery",
          "flip": false,
          "cardId": "Modifier-58"
        },
        {
          "type": "predefined",
          "category": "Modifier",
          "label": [],
          "color": "#e3f542",
          "content": "Soft spoken",
          "flip": false,
          "cardId": "Modifier-59"
        },
        {
          "type": "predefined",
          "category": "Modifier",
          "label": [],
          "color": "#e3f542",
          "content": "Spineless",
          "flip": false,
          "cardId": "Modifier-60"
        },
        {
          "type": "predefined",
          "category": "Modifier",
          "label": [],
          "color": "#e3f542",
          "content": "Struggling",
          "flip": false,
          "cardId": "Modifier-61"
        },
        {
          "type": "predefined",
          "category": "Modifier",
          "label": [],
          "color": "#e3f542",
          "content": "Tragic",
          "flip": false,
          "cardId": "Modifier-62"
        },
        {
          "type": "predefined",
          "category": "Modifier",
          "label": [],
          "color": "#e3f542",
          "content": "Trigger happy",
          "flip": false,
          "cardId": "Modifier-63"
        },
        {
          "type": "predefined",
          "category": "Modifier",
          "label": [],
          "color": "#e3f542",
          "content": "Unattractive",
          "flip": false,
          "cardId": "Modifier-64"
        },
        {
          "type": "predefined",
          "category": "Modifier",
          "label": [],
          "color": "#e3f542",
          "content": "Undercover",
          "flip": false,
          "cardId": "Modifier-65"
        },
        {
          "type": "predefined",
          "category": "Modifier",
          "label": [],
          "color": "#e3f542",
          "content": "Unethical",
          "flip": false,
          "cardId": "Modifier-66"
        },
        {
          "type": "predefined",
          "category": "Modifier",
          "label": [],
          "color": "#e3f542",
          "content": "Unhinged",
          "flip": false,
          "cardId": "Modifier-67"
        },
        {
          "type": "predefined",
          "category": "Modifier",
          "label": [],
          "color": "#e3f542",
          "content": "Violent",
          "flip": false,
          "cardId": "Modifier-68"
        },
        {
          "type": "predefined",
          "category": "Modifier",
          "label": [],
          "color": "#e3f542",
          "content": "Washed up",
          "flip": false,
          "cardId": "Modifier-69"
        },
        {
          "type": "predefined",
          "category": "Modifier",
          "label": [],
          "color": "#e3f542",
          "content": "Wily",
          "flip": false,
          "cardId": "Modifier-70"
        },
        {
          "type": "predefined",
          "category": "Modifier",
          "label": [],
          "color": "#e3f542",
          "content": "World-famous",
          "flip": false,
          "cardId": "Modifier-71"
        }
      ],
      "Character": [
        {
          "type": "predefined",
          "category": "Character",
          "label": [
            "Marker"
          ],
          "color": null,
          "content": "Character",
          "flip": false,
          "cardId": "Character-0"
        }
      ],
      "Archetype": [
        {
          "type": "predefined",
          "category": "Archetype",
          "label": [
            "Marker"
          ],
          "color": "#59fff9",
          "content": "Archetype",
          "flip": false,
          "cardId": "Archetype-0"
        },
        {
          "type": "predefined",
          "category": "Archetype",
          "label": [],
          "color": "#59fff9",
          "content": "All Seeing Eye",
          "flip": false,
          "cardId": "Archetype-1"
        },
        {
          "type": "predefined",
          "category": "Archetype",
          "label": [],
          "color": "#59fff9",
          "content": "Bad Boy/Girl",
          "flip": false,
          "cardId": "Archetype-2"
        },
        {
          "type": "predefined",
          "category": "Archetype",
          "label": [],
          "color": "#59fff9",
          "content": "Boss",
          "flip": false,
          "cardId": "Archetype-3"
        },
        {
          "type": "predefined",
          "category": "Archetype",
          "label": [],
          "color": "#59fff9",
          "content": "Career Criminal",
          "flip": false,
          "cardId": "Archetype-4"
        },
        {
          "type": "predefined",
          "category": "Archetype",
          "label": [],
          "color": "#59fff9",
          "content": "Cheerleader",
          "flip": false,
          "cardId": "Archetype-5"
        },
        {
          "type": "predefined",
          "category": "Archetype",
          "label": [],
          "color": "#59fff9",
          "content": "Child Prodigy",
          "flip": false,
          "cardId": "Archetype-6"
        },
        {
          "type": "predefined",
          "category": "Archetype",
          "label": [],
          "color": "#59fff9",
          "content": "Chosen One",
          "flip": false,
          "cardId": "Archetype-7"
        },
        {
          "type": "predefined",
          "category": "Archetype",
          "label": [],
          "color": "#59fff9",
          "content": "Confidant",
          "flip": false,
          "cardId": "Archetype-8"
        },
        {
          "type": "predefined",
          "category": "Archetype",
          "label": [],
          "color": "#59fff9",
          "content": "Corruptor",
          "flip": false,
          "cardId": "Archetype-9"
        },
        {
          "type": "predefined",
          "category": "Archetype",
          "label": [],
          "color": "#59fff9",
          "content": "Country Bumpkin",
          "flip": false,
          "cardId": "Archetype-10"
        },
        {
          "type": "predefined",
          "category": "Archetype",
          "label": [],
          "color": "#59fff9",
          "content": "Fair Weather Friend",
          "flip": false,
          "cardId": "Archetype-11"
        },
        {
          "type": "predefined",
          "category": "Archetype",
          "label": [],
          "color": "#59fff9",
          "content": "Fall Guy",
          "flip": false,
          "cardId": "Archetype-12"
        },
        {
          "type": "predefined",
          "category": "Archetype",
          "label": [],
          "color": "#59fff9",
          "content": "Father FIgure",
          "flip": false,
          "cardId": "Archetype-13"
        },
        {
          "type": "predefined",
          "category": "Archetype",
          "label": [],
          "color": "#59fff9",
          "content": "Femme Fatale",
          "flip": false,
          "cardId": "Archetype-14"
        },
        {
          "type": "predefined",
          "category": "Archetype",
          "label": [],
          "color": "#59fff9",
          "content": "Ferryman",
          "flip": false,
          "cardId": "Archetype-15"
        },
        {
          "type": "predefined",
          "category": "Archetype",
          "label": [],
          "color": "#59fff9",
          "content": "Fish out of water",
          "flip": false,
          "cardId": "Archetype-16"
        },
        {
          "type": "predefined",
          "category": "Archetype",
          "label": [],
          "color": "#59fff9",
          "content": "Fool",
          "flip": false,
          "cardId": "Archetype-17"
        },
        {
          "type": "predefined",
          "category": "Archetype",
          "label": [],
          "color": "#59fff9",
          "content": "Friend with Benefits",
          "flip": false,
          "cardId": "Archetype-18"
        },
        {
          "type": "predefined",
          "category": "Archetype",
          "label": [],
          "color": "#59fff9",
          "content": "Gentle Giant",
          "flip": false,
          "cardId": "Archetype-19"
        },
        {
          "type": "predefined",
          "category": "Archetype",
          "label": [],
          "color": "#59fff9",
          "content": "Herald",
          "flip": false,
          "cardId": "Archetype-20"
        },
        {
          "type": "predefined",
          "category": "Archetype",
          "label": [],
          "color": "#59fff9",
          "content": "Hero",
          "flip": false,
          "cardId": "Archetype-21"
        },
        {
          "type": "predefined",
          "category": "Archetype",
          "label": [],
          "color": "#59fff9",
          "content": "Hot Shot",
          "flip": false,
          "cardId": "Archetype-22"
        },
        {
          "type": "predefined",
          "category": "Archetype",
          "label": [],
          "color": "#59fff9",
          "content": "Hunter",
          "flip": false,
          "cardId": "Archetype-23"
        },
        {
          "type": "predefined",
          "category": "Archetype",
          "label": [],
          "color": "#59fff9",
          "content": "Imaginary Friend",
          "flip": false,
          "cardId": "Archetype-24"
        },
        {
          "type": "predefined",
          "category": "Archetype",
          "label": [],
          "color": "#59fff9",
          "content": "Ingenue",
          "flip": false,
          "cardId": "Archetype-25"
        },
        {
          "type": "predefined",
          "category": "Archetype",
          "label": [],
          "color": "#59fff9",
          "content": "Jock",
          "flip": false,
          "cardId": "Archetype-26"
        },
        {
          "type": "predefined",
          "category": "Archetype",
          "label": [],
          "color": "#59fff9",
          "content": "Last One Standing",
          "flip": false,
          "cardId": "Archetype-27"
        },
        {
          "type": "predefined",
          "category": "Archetype",
          "label": [],
          "color": "#59fff9",
          "content": "Law Man",
          "flip": false,
          "cardId": "Archetype-28"
        },
        {
          "type": "predefined",
          "category": "Archetype",
          "label": [],
          "color": "#59fff9",
          "content": "Loner",
          "flip": false,
          "cardId": "Archetype-29"
        },
        {
          "type": "predefined",
          "category": "Archetype",
          "label": [],
          "color": "#59fff9",
          "content": "Loser",
          "flip": false,
          "cardId": "Archetype-30"
        },
        {
          "type": "predefined",
          "category": "Archetype",
          "label": [],
          "color": "#59fff9",
          "content": "Love Child",
          "flip": false,
          "cardId": "Archetype-31"
        },
        {
          "type": "predefined",
          "category": "Archetype",
          "label": [],
          "color": "#59fff9",
          "content": "Love Interest",
          "flip": false,
          "cardId": "Archetype-32"
        },
        {
          "type": "predefined",
          "category": "Archetype",
          "label": [],
          "color": "#59fff9",
          "content": "Loveable Rogue",
          "flip": false,
          "cardId": "Archetype-33"
        },
        {
          "type": "predefined",
          "category": "Archetype",
          "label": [],
          "color": "#59fff9",
          "content": "Lover",
          "flip": false,
          "cardId": "Archetype-34"
        },
        {
          "type": "predefined",
          "category": "Archetype",
          "label": [],
          "color": "#59fff9",
          "content": "Loyalist",
          "flip": false,
          "cardId": "Archetype-35"
        },
        {
          "type": "predefined",
          "category": "Archetype",
          "label": [],
          "color": "#59fff9",
          "content": "Mad Scienctist",
          "flip": false,
          "cardId": "Archetype-36"
        },
        {
          "type": "predefined",
          "category": "Archetype",
          "label": [],
          "color": "#59fff9",
          "content": "Magus",
          "flip": false,
          "cardId": "Archetype-37"
        },
        {
          "type": "predefined",
          "category": "Archetype",
          "label": [],
          "color": "#59fff9",
          "content": "Mentor",
          "flip": false,
          "cardId": "Archetype-38"
        },
        {
          "type": "predefined",
          "category": "Archetype",
          "label": [],
          "color": "#59fff9",
          "content": "Momma's Boy",
          "flip": false,
          "cardId": "Archetype-39"
        },
        {
          "type": "predefined",
          "category": "Archetype",
          "label": [],
          "color": "#59fff9",
          "content": "Mother Figure",
          "flip": false,
          "cardId": "Archetype-40"
        },
        {
          "type": "predefined",
          "category": "Archetype",
          "label": [],
          "color": "#59fff9",
          "content": "Nemesis",
          "flip": false,
          "cardId": "Archetype-41"
        },
        {
          "type": "predefined",
          "category": "Archetype",
          "label": [],
          "color": "#59fff9",
          "content": "Nerd",
          "flip": false,
          "cardId": "Archetype-42"
        },
        {
          "type": "predefined",
          "category": "Archetype",
          "label": [],
          "color": "#59fff9",
          "content": "Outlaw",
          "flip": false,
          "cardId": "Archetype-43"
        },
        {
          "type": "predefined",
          "category": "Archetype",
          "label": [],
          "color": "#59fff9",
          "content": "Outsider",
          "flip": false,
          "cardId": "Archetype-44"
        },
        {
          "type": "predefined",
          "category": "Archetype",
          "label": [],
          "color": "#59fff9",
          "content": "Peacemaker",
          "flip": false,
          "cardId": "Archetype-45"
        },
        {
          "type": "predefined",
          "category": "Archetype",
          "label": [],
          "color": "#59fff9",
          "content": "Prodigy",
          "flip": false,
          "cardId": "Archetype-46"
        },
        {
          "type": "predefined",
          "category": "Archetype",
          "label": [],
          "color": "#59fff9",
          "content": "Psycopath",
          "flip": false,
          "cardId": "Archetype-47"
        },
        {
          "type": "predefined",
          "category": "Archetype",
          "label": [],
          "color": "#59fff9",
          "content": "Rebel",
          "flip": false,
          "cardId": "Archetype-48"
        },
        {
          "type": "predefined",
          "category": "Archetype",
          "label": [],
          "color": "#59fff9",
          "content": "Sentient Creature",
          "flip": false,
          "cardId": "Archetype-49"
        },
        {
          "type": "predefined",
          "category": "Archetype",
          "label": [],
          "color": "#59fff9",
          "content": "Shaman",
          "flip": false,
          "cardId": "Archetype-50"
        },
        {
          "type": "predefined",
          "category": "Archetype",
          "label": [],
          "color": "#59fff9",
          "content": "Sidekick",
          "flip": false,
          "cardId": "Archetype-51"
        },
        {
          "type": "predefined",
          "category": "Archetype",
          "label": [],
          "color": "#59fff9",
          "content": "Sleuth",
          "flip": false,
          "cardId": "Archetype-52"
        },
        {
          "type": "predefined",
          "category": "Archetype",
          "label": [],
          "color": "#59fff9",
          "content": "Soldier",
          "flip": false,
          "cardId": "Archetype-53"
        },
        {
          "type": "predefined",
          "category": "Archetype",
          "label": [],
          "color": "#59fff9",
          "content": "Tom Boy",
          "flip": false,
          "cardId": "Archetype-54"
        },
        {
          "type": "predefined",
          "category": "Archetype",
          "label": [],
          "color": "#59fff9",
          "content": "Tortured Artist",
          "flip": false,
          "cardId": "Archetype-55"
        },
        {
          "type": "predefined",
          "category": "Archetype",
          "label": [],
          "color": "#59fff9",
          "content": "Tragic Hero",
          "flip": false,
          "cardId": "Archetype-56"
        },
        {
          "type": "predefined",
          "category": "Archetype",
          "label": [],
          "color": "#59fff9",
          "content": "Trickster",
          "flip": false,
          "cardId": "Archetype-57"
        },
        {
          "type": "predefined",
          "category": "Archetype",
          "label": [],
          "color": "#59fff9",
          "content": "Troubled Teen",
          "flip": false,
          "cardId": "Archetype-58"
        },
        {
          "type": "predefined",
          "category": "Archetype",
          "label": [],
          "color": "#59fff9",
          "content": "Turncoat",
          "flip": false,
          "cardId": "Archetype-59"
        },
        {
          "type": "predefined",
          "category": "Archetype",
          "label": [],
          "color": "#59fff9",
          "content": "Underdog",
          "flip": false,
          "cardId": "Archetype-60"
        },
        {
          "type": "predefined",
          "category": "Archetype",
          "label": [],
          "color": "#59fff9",
          "content": "Useful Idiot",
          "flip": false,
          "cardId": "Archetype-61"
        },
        {
          "type": "predefined",
          "category": "Archetype",
          "label": [],
          "color": "#59fff9",
          "content": "Village Idiot",
          "flip": false,
          "cardId": "Archetype-62"
        },
        {
          "type": "predefined",
          "category": "Archetype",
          "label": [],
          "color": "#59fff9",
          "content": "Villain",
          "flip": false,
          "cardId": "Archetype-63"
        },
        {
          "type": "predefined",
          "category": "Archetype",
          "label": [],
          "color": "#59fff9",
          "content": "Whiskey Priest",
          "flip": false,
          "cardId": "Archetype-64"
        },
        {
          "type": "predefined",
          "category": "Archetype",
          "label": [],
          "color": "#59fff9",
          "content": "White Knight",
          "flip": false,
          "cardId": "Archetype-65"
        },
        {
          "type": "predefined",
          "category": "Archetype",
          "label": [],
          "color": "#59fff9",
          "content": "Whizz Kid",
          "flip": false,
          "cardId": "Archetype-66"
        },
        {
          "type": "predefined",
          "category": "Archetype",
          "label": [],
          "color": "#59fff9",
          "content": "Wise Elder",
          "flip": false,
          "cardId": "Archetype-67"
        },
        {
          "type": "predefined",
          "category": "Archetype",
          "label": [],
          "color": "#59fff9",
          "content": "Guy/Girl Next Door",
          "flip": false,
          "cardId": "Archetype-68"
        },
        {
          "type": "predefined",
          "category": "Archetype",
          "label": [],
          "color": "#59fff9",
          "content": "Wise Fool",
          "flip": false,
          "cardId": "Archetype-69"
        }
      ],
      "Conflict": [
        {
          "type": "predefined",
          "category": "Conflict",
          "label": [
            "Marker"
          ],
          "color": "#598eff",
          "content": "Conflict",
          "flip": false,
          "cardId": "Conflict-0"
        },
        {
          "type": "predefined",
          "category": "Conflict",
          "label": [],
          "color": "#598eff",
          "content": "Arduous Journey",
          "flip": false,
          "cardId": "Conflict-1"
        },
        {
          "type": "predefined",
          "category": "Conflict",
          "label": [],
          "color": "#598eff",
          "content": "Attempted Murder",
          "flip": false,
          "cardId": "Conflict-2"
        },
        {
          "type": "predefined",
          "category": "Conflict",
          "label": [],
          "color": "#598eff",
          "content": "Blackmail",
          "flip": false,
          "cardId": "Conflict-3"
        },
        {
          "type": "predefined",
          "category": "Conflict",
          "label": [],
          "color": "#598eff",
          "content": "Body Swap",
          "flip": false,
          "cardId": "Conflict-4"
        },
        {
          "type": "predefined",
          "category": "Conflict",
          "label": [],
          "color": "#598eff",
          "content": "Bridge Burning",
          "flip": false,
          "cardId": "Conflict-5"
        },
        {
          "type": "predefined",
          "category": "Conflict",
          "label": [],
          "color": "#598eff",
          "content": "Caught in the act",
          "flip": false,
          "cardId": "Conflict-6"
        },
        {
          "type": "predefined",
          "category": "Conflict",
          "label": [],
          "color": "#598eff",
          "content": "Cheating",
          "flip": false,
          "cardId": "Conflict-7"
        },
        {
          "type": "predefined",
          "category": "Conflict",
          "label": [],
          "color": "#598eff",
          "content": "Class Divide",
          "flip": false,
          "cardId": "Conflict-8"
        },
        {
          "type": "predefined",
          "category": "Conflict",
          "label": [],
          "color": "#598eff",
          "content": "Con Job",
          "flip": false,
          "cardId": "Conflict-9"
        },
        {
          "type": "predefined",
          "category": "Conflict",
          "label": [],
          "color": "#598eff",
          "content": "Coverup",
          "flip": false,
          "cardId": "Conflict-10"
        },
        {
          "type": "predefined",
          "category": "Conflict",
          "label": [],
          "color": "#598eff",
          "content": "Dangerous Discovery",
          "flip": false,
          "cardId": "Conflict-11"
        },
        {
          "type": "predefined",
          "category": "Conflict",
          "label": [],
          "color": "#598eff",
          "content": "Disturbing Prophecy",
          "flip": false,
          "cardId": "Conflict-12"
        },
        {
          "type": "predefined",
          "category": "Conflict",
          "label": [],
          "color": "#598eff",
          "content": "Exposed",
          "flip": false,
          "cardId": "Conflict-13"
        },
        {
          "type": "predefined",
          "category": "Conflict",
          "label": [],
          "color": "#598eff",
          "content": "Failing Business",
          "flip": false,
          "cardId": "Conflict-14"
        },
        {
          "type": "predefined",
          "category": "Conflict",
          "label": [],
          "color": "#598eff",
          "content": "Failure to Launch",
          "flip": false,
          "cardId": "Conflict-15"
        },
        {
          "type": "predefined",
          "category": "Conflict",
          "label": [],
          "color": "#598eff",
          "content": "Faustian Bargain",
          "flip": false,
          "cardId": "Conflict-16"
        },
        {
          "type": "predefined",
          "category": "Conflict",
          "label": [],
          "color": "#598eff",
          "content": "Future of Mankind",
          "flip": false,
          "cardId": "Conflict-17"
        },
        {
          "type": "predefined",
          "category": "Conflict",
          "label": [],
          "color": "#598eff",
          "content": "Grand Inheritance",
          "flip": false,
          "cardId": "Conflict-18"
        },
        {
          "type": "predefined",
          "category": "Conflict",
          "label": [],
          "color": "#598eff",
          "content": "Grand Theft",
          "flip": false,
          "cardId": "Conflict-19"
        },
        {
          "type": "predefined",
          "category": "Conflict",
          "label": [],
          "color": "#598eff",
          "content": "Illness",
          "flip": false,
          "cardId": "Conflict-20"
        },
        {
          "type": "predefined",
          "category": "Conflict",
          "label": [],
          "color": "#598eff",
          "content": "Impending Disaster",
          "flip": false,
          "cardId": "Conflict-21"
        },
        {
          "type": "predefined",
          "category": "Conflict",
          "label": [],
          "color": "#598eff",
          "content": "Inexplicable Phenomena",
          "flip": false,
          "cardId": "Conflict-22"
        },
        {
          "type": "predefined",
          "category": "Conflict",
          "label": [],
          "color": "#598eff",
          "content": "Last Day before",
          "flip": false,
          "cardId": "Conflict-23"
        },
        {
          "type": "predefined",
          "category": "Conflict",
          "label": [],
          "color": "#598eff",
          "content": "Lost in translation",
          "flip": false,
          "cardId": "Conflict-24"
        },
        {
          "type": "predefined",
          "category": "Conflict",
          "label": [],
          "color": "#598eff",
          "content": "Magical Creature",
          "flip": false,
          "cardId": "Conflict-25"
        },
        {
          "type": "predefined",
          "category": "Conflict",
          "label": [],
          "color": "#598eff",
          "content": "Missing Person",
          "flip": false,
          "cardId": "Conflict-26"
        },
        {
          "type": "predefined",
          "category": "Conflict",
          "label": [],
          "color": "#598eff",
          "content": "Mistaken Identity",
          "flip": false,
          "cardId": "Conflict-27"
        },
        {
          "type": "predefined",
          "category": "Conflict",
          "label": [],
          "color": "#598eff",
          "content": "Only Survivor",
          "flip": false,
          "cardId": "Conflict-28"
        },
        {
          "type": "predefined",
          "category": "Conflict",
          "label": [],
          "color": "#598eff",
          "content": "Overhears Something",
          "flip": false,
          "cardId": "Conflict-29"
        },
        {
          "type": "predefined",
          "category": "Conflict",
          "label": [],
          "color": "#598eff",
          "content": "Power Grab",
          "flip": false,
          "cardId": "Conflict-30"
        },
        {
          "type": "predefined",
          "category": "Conflict",
          "label": [],
          "color": "#598eff",
          "content": "Redemption",
          "flip": false,
          "cardId": "Conflict-31"
        },
        {
          "type": "predefined",
          "category": "Conflict",
          "label": [],
          "color": "#598eff",
          "content": "Revenge",
          "flip": false,
          "cardId": "Conflict-32"
        },
        {
          "type": "predefined",
          "category": "Conflict",
          "label": [],
          "color": "#598eff",
          "content": "Rising/Falling Star",
          "flip": false,
          "cardId": "Conflict-33"
        },
        {
          "type": "predefined",
          "category": "Conflict",
          "label": [],
          "color": "#598eff",
          "content": "Role Swap",
          "flip": false,
          "cardId": "Conflict-34"
        },
        {
          "type": "predefined",
          "category": "Conflict",
          "label": [],
          "color": "#598eff",
          "content": "Search for Self",
          "flip": false,
          "cardId": "Conflict-35"
        },
        {
          "type": "predefined",
          "category": "Conflict",
          "label": [],
          "color": "#598eff",
          "content": "Secret Revealed",
          "flip": false,
          "cardId": "Conflict-36"
        },
        {
          "type": "predefined",
          "category": "Conflict",
          "label": [],
          "color": "#598eff",
          "content": "Seeks revenge",
          "flip": false,
          "cardId": "Conflict-37"
        },
        {
          "type": "predefined",
          "category": "Conflict",
          "label": [],
          "color": "#598eff",
          "content": "Sensory Loss",
          "flip": false,
          "cardId": "Conflict-38"
        },
        {
          "type": "predefined",
          "category": "Conflict",
          "label": [],
          "color": "#598eff",
          "content": "Shocking Discovery",
          "flip": false,
          "cardId": "Conflict-39"
        },
        {
          "type": "predefined",
          "category": "Conflict",
          "label": [],
          "color": "#598eff",
          "content": "Skeletons in Closet",
          "flip": false,
          "cardId": "Conflict-40"
        },
        {
          "type": "predefined",
          "category": "Conflict",
          "label": [],
          "color": "#598eff",
          "content": "Spiritual Journey ends badly",
          "flip": false,
          "cardId": "Conflict-41"
        },
        {
          "type": "predefined",
          "category": "Conflict",
          "label": [],
          "color": "#598eff",
          "content": "Stalker",
          "flip": false,
          "cardId": "Conflict-42"
        },
        {
          "type": "predefined",
          "category": "Conflict",
          "label": [],
          "color": "#598eff",
          "content": "Strange Phenomena",
          "flip": false,
          "cardId": "Conflict-43"
        },
        {
          "type": "predefined",
          "category": "Conflict",
          "label": [],
          "color": "#598eff",
          "content": "Super Spreader Event",
          "flip": false,
          "cardId": "Conflict-44"
        },
        {
          "type": "predefined",
          "category": "Conflict",
          "label": [],
          "color": "#598eff",
          "content": "Super Strain",
          "flip": false,
          "cardId": "Conflict-45"
        },
        {
          "type": "predefined",
          "category": "Conflict",
          "label": [],
          "color": "#598eff",
          "content": "Survival",
          "flip": false,
          "cardId": "Conflict-46"
        },
        {
          "type": "predefined",
          "category": "Conflict",
          "label": [],
          "color": "#598eff",
          "content": "Teachable Moment",
          "flip": false,
          "cardId": "Conflict-47"
        },
        {
          "type": "predefined",
          "category": "Conflict",
          "label": [],
          "color": "#598eff",
          "content": "Terrible Breakup",
          "flip": false,
          "cardId": "Conflict-48"
        },
        {
          "type": "predefined",
          "category": "Conflict",
          "label": [],
          "color": "#598eff",
          "content": "The Apocalypse",
          "flip": false,
          "cardId": "Conflict-49"
        },
        {
          "type": "predefined",
          "category": "Conflict",
          "label": [],
          "color": "#598eff",
          "content": "Time Warp",
          "flip": false,
          "cardId": "Conflict-50"
        },
        {
          "type": "predefined",
          "category": "Conflict",
          "label": [],
          "color": "#598eff",
          "content": "Truthseeking",
          "flip": false,
          "cardId": "Conflict-51"
        },
        {
          "type": "predefined",
          "category": "Conflict",
          "label": [],
          "color": "#598eff",
          "content": "Worst thing ever",
          "flip": false,
          "cardId": "Conflict-52"
        },
        {
          "type": "predefined",
          "category": "Conflict",
          "label": [],
          "color": "#598eff",
          "content": "Wrongly Accused",
          "flip": false,
          "cardId": "Conflict-53"
        },
        {
          "type": "predefined",
          "category": "Conflict",
          "label": [],
          "color": "#598eff",
          "content": "Bad Hair Day",
          "flip": false,
          "cardId": "Conflict-54"
        }
      ],
      "Crisis": [
        {
          "type": "predefined",
          "category": "Crisis",
          "label": [
            "Marker"
          ],
          "color": "#ff33a3",
          "content": "Crisis",
          "flip": false,
          "cardId": "Crisis-0"
        },
        {
          "type": "predefined",
          "category": "Crisis",
          "label": [],
          "color": "#ff33a3",
          "content": "Abduction",
          "flip": false,
          "cardId": "Crisis-1"
        },
        {
          "type": "predefined",
          "category": "Crisis",
          "label": [],
          "color": "#ff33a3",
          "content": "Everything a Lie",
          "flip": false,
          "cardId": "Crisis-2"
        },
        {
          "type": "predefined",
          "category": "Crisis",
          "label": [],
          "color": "#ff33a3",
          "content": "Apocalypse/Armageddon",
          "flip": false,
          "cardId": "Crisis-3"
        },
        {
          "type": "predefined",
          "category": "Crisis",
          "label": [],
          "color": "#ff33a3",
          "content": "Changes Course of History",
          "flip": false,
          "cardId": "Crisis-4"
        },
        {
          "type": "predefined",
          "category": "Crisis",
          "label": [],
          "color": "#ff33a3",
          "content": "Death Cult",
          "flip": false,
          "cardId": "Crisis-5"
        },
        {
          "type": "predefined",
          "category": "Crisis",
          "label": [],
          "color": "#ff33a3",
          "content": "Dumpster Fire",
          "flip": false,
          "cardId": "Crisis-6"
        },
        {
          "type": "predefined",
          "category": "Crisis",
          "label": [],
          "color": "#ff33a3",
          "content": "Hair on Fire",
          "flip": false,
          "cardId": "Crisis-7"
        },
        {
          "type": "predefined",
          "category": "Crisis",
          "label": [],
          "color": "#ff33a3",
          "content": "Humiliation",
          "flip": false,
          "cardId": "Crisis-8"
        },
        {
          "type": "predefined",
          "category": "Crisis",
          "label": [],
          "color": "#ff33a3",
          "content": "Murder",
          "flip": false,
          "cardId": "Crisis-9"
        },
        {
          "type": "predefined",
          "category": "Crisis",
          "label": [],
          "color": "#ff33a3",
          "content": "National Emergency",
          "flip": false,
          "cardId": "Crisis-10"
        },
        {
          "type": "predefined",
          "category": "Crisis",
          "label": [],
          "color": "#ff33a3",
          "content": "Natural Disaster",
          "flip": false,
          "cardId": "Crisis-11"
        },
        {
          "type": "predefined",
          "category": "Crisis",
          "label": [],
          "color": "#ff33a3",
          "content": "No Return",
          "flip": false,
          "cardId": "Crisis-12"
        },
        {
          "type": "predefined",
          "category": "Crisis",
          "label": [],
          "color": "#ff33a3",
          "content": "No Way Out",
          "flip": false,
          "cardId": "Crisis-13"
        },
        {
          "type": "predefined",
          "category": "Crisis",
          "label": [],
          "color": "#ff33a3",
          "content": "Sole Survivor",
          "flip": false,
          "cardId": "Crisis-14"
        },
        {
          "type": "predefined",
          "category": "Crisis",
          "label": [],
          "color": "#ff33a3",
          "content": "Spiritual Journey Ends Badly",
          "flip": false,
          "cardId": "Crisis-15"
        },
        {
          "type": "predefined",
          "category": "Crisis",
          "label": [],
          "color": "#ff33a3",
          "content": "Worst Thing Ever",
          "flip": false,
          "cardId": "Crisis-16"
        },
        {
          "type": "predefined",
          "category": "Crisis",
          "label": [],
          "color": "#ff33a3",
          "content": "Exile",
          "flip": false,
          "cardId": "Crisis-17"
        },
        {
          "type": "predefined",
          "category": "Crisis",
          "label": [],
          "color": "#ff33a3",
          "content": "Intervention",
          "flip": false,
          "cardId": "Crisis-18"
        }
      ],
      "Issues": [
        {
          "type": "predefined",
          "category": "Issues",
          "label": [
            "Marker"
          ],
          "color": "#14ffbd",
          "content": "Issues",
          "flip": false,
          "cardId": "Issues-0"
        },
        {
          "type": "predefined",
          "category": "Issues",
          "label": [],
          "color": "#14ffbd",
          "content": "What if A.I. compounds our prejudices?",
          "flip": false,
          "cardId": "Issues-1"
        },
        {
          "type": "predefined",
          "category": "Issues",
          "label": [],
          "color": "#14ffbd",
          "content": "What if babies are lifestyle accessories?",
          "flip": false,
          "cardId": "Issues-2"
        },
        {
          "type": "predefined",
          "category": "Issues",
          "label": [],
          "color": "#14ffbd",
          "content": "What if common sense is the enemy of creativity?",
          "flip": false,
          "cardId": "Issues-3"
        },
        {
          "type": "predefined",
          "category": "Issues",
          "label": [],
          "color": "#14ffbd",
          "content": "What if community makes us stronger individuals?",
          "flip": false,
          "cardId": "Issues-4"
        },
        {
          "type": "predefined",
          "category": "Issues",
          "label": [],
          "color": "#14ffbd",
          "content": "What if conflict is good for you?",
          "flip": false,
          "cardId": "Issues-5"
        },
        {
          "type": "predefined",
          "category": "Issues",
          "label": [],
          "color": "#14ffbd",
          "content": "What if culture is detrimental to humanity?",
          "flip": false,
          "cardId": "Issues-6"
        },
        {
          "type": "predefined",
          "category": "Issues",
          "label": [],
          "color": "#14ffbd",
          "content": "What if Facebook is used for human breeding?",
          "flip": false,
          "cardId": "Issues-7"
        },
        {
          "type": "predefined",
          "category": "Issues",
          "label": [],
          "color": "#14ffbd",
          "content": "What if friends are more dangerous than strangers?",
          "flip": false,
          "cardId": "Issues-8"
        },
        {
          "type": "predefined",
          "category": "Issues",
          "label": [],
          "color": "#14ffbd",
          "content": "What if getting what you want isn't what you need?",
          "flip": false,
          "cardId": "Issues-9"
        },
        {
          "type": "predefined",
          "category": "Issues",
          "label": [],
          "color": "#14ffbd",
          "content": "What if honesty isn't always the best policy?",
          "flip": false,
          "cardId": "Issues-10"
        },
        {
          "type": "predefined",
          "category": "Issues",
          "label": [],
          "color": "#14ffbd",
          "content": "What if human misery is a business model?",
          "flip": false,
          "cardId": "Issues-11"
        },
        {
          "type": "predefined",
          "category": "Issues",
          "label": [],
          "color": "#14ffbd",
          "content": "What if it's not in our best interest to get what we want?",
          "flip": false,
          "cardId": "Issues-12"
        },
        {
          "type": "predefined",
          "category": "Issues",
          "label": [],
          "color": "#14ffbd",
          "content": "What if lies were without consequence?",
          "flip": false,
          "cardId": "Issues-13"
        },
        {
          "type": "predefined",
          "category": "Issues",
          "label": [],
          "color": "#14ffbd",
          "content": "What if life is without meaning?",
          "flip": false,
          "cardId": "Issues-14"
        },
        {
          "type": "predefined",
          "category": "Issues",
          "label": [],
          "color": "#14ffbd",
          "content": "What if life streaming fuels narcissism?",
          "flip": false,
          "cardId": "Issues-15"
        },
        {
          "type": "predefined",
          "category": "Issues",
          "label": [],
          "color": "#14ffbd",
          "content": "What if love is a virus?",
          "flip": false,
          "cardId": "Issues-16"
        },
        {
          "type": "predefined",
          "category": "Issues",
          "label": [],
          "color": "#14ffbd",
          "content": "What if manking is a problem?",
          "flip": false,
          "cardId": "Issues-17"
        },
        {
          "type": "predefined",
          "category": "Issues",
          "label": [],
          "color": "#14ffbd",
          "content": "What if marriage is the best way to ruin a good friendship?",
          "flip": false,
          "cardId": "Issues-18"
        },
        {
          "type": "predefined",
          "category": "Issues",
          "label": [],
          "color": "#14ffbd",
          "content": "What if obesity is self-protection?",
          "flip": false,
          "cardId": "Issues-19"
        },
        {
          "type": "predefined",
          "category": "Issues",
          "label": [],
          "color": "#14ffbd",
          "content": "What if opposites attract but obliterate?",
          "flip": false,
          "cardId": "Issues-20"
        },
        {
          "type": "predefined",
          "category": "Issues",
          "label": [],
          "color": "#14ffbd",
          "content": "What if our brains can't comprehend our own creations?",
          "flip": false,
          "cardId": "Issues-21"
        },
        {
          "type": "predefined",
          "category": "Issues",
          "label": [],
          "color": "#14ffbd",
          "content": "What if overindulgence is self-defense?",
          "flip": false,
          "cardId": "Issues-22"
        },
        {
          "type": "predefined",
          "category": "Issues",
          "label": [],
          "color": "#14ffbd",
          "content": "What if patience isn't a virtue?",
          "flip": false,
          "cardId": "Issues-23"
        },
        {
          "type": "predefined",
          "category": "Issues",
          "label": [],
          "color": "#14ffbd",
          "content": "What if polygamy solves income inequality?",
          "flip": false,
          "cardId": "Issues-24"
        },
        {
          "type": "predefined",
          "category": "Issues",
          "label": [],
          "color": "#14ffbd",
          "content": "What if prejudices are positive?",
          "flip": false,
          "cardId": "Issues-25"
        },
        {
          "type": "predefined",
          "category": "Issues",
          "label": [],
          "color": "#14ffbd",
          "content": "What if principles don't hold up when there's a gun to your head?",
          "flip": false,
          "cardId": "Issues-26"
        },
        {
          "type": "predefined",
          "category": "Issues",
          "label": [],
          "color": "#14ffbd",
          "content": "What if prisons are slave plantations?",
          "flip": false,
          "cardId": "Issues-27"
        },
        {
          "type": "predefined",
          "category": "Issues",
          "label": [],
          "color": "#14ffbd",
          "content": "What if security is more important than freedom?",
          "flip": false,
          "cardId": "Issues-28"
        },
        {
          "type": "predefined",
          "category": "Issues",
          "label": [],
          "color": "#14ffbd",
          "content": "What if social media creates social problems?",
          "flip": false,
          "cardId": "Issues-29"
        },
        {
          "type": "predefined",
          "category": "Issues",
          "label": [],
          "color": "#14ffbd",
          "content": "What if social media endangers democracy?",
          "flip": false,
          "cardId": "Issues-30"
        },
        {
          "type": "predefined",
          "category": "Issues",
          "label": [],
          "color": "#14ffbd",
          "content": "What if social media is a form of social engineering?",
          "flip": false,
          "cardId": "Issues-31"
        },
        {
          "type": "predefined",
          "category": "Issues",
          "label": [],
          "color": "#14ffbd",
          "content": "What if social media platforms keep your secrets?",
          "flip": false,
          "cardId": "Issues-32"
        },
        {
          "type": "predefined",
          "category": "Issues",
          "label": [],
          "color": "#14ffbd",
          "content": "What if society is selling us mass extinction?",
          "flip": false,
          "cardId": "Issues-33"
        },
        {
          "type": "predefined",
          "category": "Issues",
          "label": [],
          "color": "#14ffbd",
          "content": "What if society protects us from ourselves?",
          "flip": false,
          "cardId": "Issues-34"
        },
        {
          "type": "predefined",
          "category": "Issues",
          "label": [],
          "color": "#14ffbd",
          "content": "What if solitude is the cure for loneliness?",
          "flip": false,
          "cardId": "Issues-35"
        },
        {
          "type": "predefined",
          "category": "Issues",
          "label": [],
          "color": "#14ffbd",
          "content": "What if technology makes us stupid?",
          "flip": false,
          "cardId": "Issues-36"
        },
        {
          "type": "predefined",
          "category": "Issues",
          "label": [],
          "color": "#14ffbd",
          "content": "What if the cure is worse than the problem?",
          "flip": false,
          "cardId": "Issues-37"
        },
        {
          "type": "predefined",
          "category": "Issues",
          "label": [],
          "color": "#14ffbd",
          "content": "What if the gene pool becomes the purview of the State?",
          "flip": false,
          "cardId": "Issues-38"
        },
        {
          "type": "predefined",
          "category": "Issues",
          "label": [],
          "color": "#14ffbd",
          "content": "What if the Internet kills nostalgia?",
          "flip": false,
          "cardId": "Issues-39"
        },
        {
          "type": "predefined",
          "category": "Issues",
          "label": [],
          "color": "#14ffbd",
          "content": "What if the secret to happiness is low expectations?",
          "flip": false,
          "cardId": "Issues-40"
        },
        {
          "type": "predefined",
          "category": "Issues",
          "label": [],
          "color": "#14ffbd",
          "content": "What if the truth doesn't set you free?",
          "flip": false,
          "cardId": "Issues-41"
        },
        {
          "type": "predefined",
          "category": "Issues",
          "label": [],
          "color": "#14ffbd",
          "content": "What if the virtual is more authentic than reality?",
          "flip": false,
          "cardId": "Issues-42"
        },
        {
          "type": "predefined",
          "category": "Issues",
          "label": [],
          "color": "#14ffbd",
          "content": "What if the virus is good for you?",
          "flip": false,
          "cardId": "Issues-43"
        },
        {
          "type": "predefined",
          "category": "Issues",
          "label": [],
          "color": "#14ffbd",
          "content": "What if there's too much freedom of choice?",
          "flip": false,
          "cardId": "Issues-44"
        },
        {
          "type": "predefined",
          "category": "Issues",
          "label": [],
          "color": "#14ffbd",
          "content": "What if unconditional love is a bad idea?",
          "flip": false,
          "cardId": "Issues-45"
        },
        {
          "type": "predefined",
          "category": "Issues",
          "label": [],
          "color": "#14ffbd",
          "content": "What if we are more a product of our ancestors than our own experience?",
          "flip": false,
          "cardId": "Issues-46"
        },
        {
          "type": "predefined",
          "category": "Issues",
          "label": [],
          "color": "#14ffbd",
          "content": "What if we can't ever be satisfied?",
          "flip": false,
          "cardId": "Issues-47"
        },
        {
          "type": "predefined",
          "category": "Issues",
          "label": [],
          "color": "#14ffbd",
          "content": "What if we lose our individuality the more we network?",
          "flip": false,
          "cardId": "Issues-48"
        },
        {
          "type": "predefined",
          "category": "Issues",
          "label": [],
          "color": "#14ffbd",
          "content": "What if we no longer need to give birth to procreate?",
          "flip": false,
          "cardId": "Issues-49"
        },
        {
          "type": "predefined",
          "category": "Issues",
          "label": [],
          "color": "#14ffbd",
          "content": "What if we're better off marrying ourselves?",
          "flip": false,
          "cardId": "Issues-50"
        },
        {
          "type": "predefined",
          "category": "Issues",
          "label": [],
          "color": "#14ffbd",
          "content": "What if we're secretly happy when our friends don't succeed?",
          "flip": false,
          "cardId": "Issues-51"
        },
        {
          "type": "predefined",
          "category": "Issues",
          "label": [],
          "color": "#14ffbd",
          "content": "What if you could kill someone with kindness?",
          "flip": false,
          "cardId": "Issues-52"
        },
        {
          "type": "predefined",
          "category": "Issues",
          "label": [],
          "color": "#14ffbd",
          "content": "What if you could marry someone exactly like yourself?",
          "flip": false,
          "cardId": "Issues-53"
        },
        {
          "type": "predefined",
          "category": "Issues",
          "label": [],
          "color": "#14ffbd",
          "content": "What if you could persuade anyone of anything?",
          "flip": false,
          "cardId": "Issues-54"
        },
        {
          "type": "predefined",
          "category": "Issues",
          "label": [],
          "color": "#14ffbd",
          "content": "What if you feed off of your friend's misfortune?",
          "flip": false,
          "cardId": "Issues-55"
        },
        {
          "type": "predefined",
          "category": "Issues",
          "label": [],
          "color": "#14ffbd",
          "content": "What if your child is born evil?",
          "flip": false,
          "cardId": "Issues-56"
        },
        {
          "type": "predefined",
          "category": "Issues",
          "label": [],
          "color": "#14ffbd",
          "content": "What if your memory is better than the real thing?",
          "flip": false,
          "cardId": "Issues-57"
        },
        {
          "type": "predefined",
          "category": "Issues",
          "label": [],
          "color": "#14ffbd",
          "content": "What if your life became your job?",
          "flip": false,
          "cardId": "Issues-58"
        }
      ],
      "Resolution": [
        {
          "type": "predefined",
          "category": "Resolution",
          "label": [
            "Marker"
          ],
          "color": "#99ff14",
          "content": "Resolution",
          "flip": false,
          "cardId": "Resolution-0"
        },
        {
          "type": "predefined",
          "category": "Resolution",
          "label": [],
          "color": "#99ff14",
          "content": "As we rely increasingly upon technology, we become less resilient in ourselves",
          "flip": false,
          "cardId": "Resolution-1"
        },
        {
          "type": "predefined",
          "category": "Resolution",
          "label": [],
          "color": "#99ff14",
          "content": "Child labor keeps kids off the streets and teaches them valuable skills",
          "flip": false,
          "cardId": "Resolution-2"
        },
        {
          "type": "predefined",
          "category": "Resolution",
          "label": [],
          "color": "#99ff14",
          "content": "Citizenship should be earned not granted as a birth-right",
          "flip": false,
          "cardId": "Resolution-3"
        },
        {
          "type": "predefined",
          "category": "Resolution",
          "label": [],
          "color": "#99ff14",
          "content": "Community makes us stronger",
          "flip": false,
          "cardId": "Resolution-4"
        },
        {
          "type": "predefined",
          "category": "Resolution",
          "label": [],
          "color": "#99ff14",
          "content": "Humanity preserves itself through mutually assured destruction",
          "flip": false,
          "cardId": "Resolution-5"
        },
        {
          "type": "predefined",
          "category": "Resolution",
          "label": [],
          "color": "#99ff14",
          "content": "Pre-emptive strikes improve security",
          "flip": false,
          "cardId": "Resolution-6"
        },
        {
          "type": "predefined",
          "category": "Resolution",
          "label": [],
          "color": "#99ff14",
          "content": "Survival of the fittest doesn't lead to survival of the strongest",
          "flip": false,
          "cardId": "Resolution-7"
        },
        {
          "type": "predefined",
          "category": "Resolution",
          "label": [],
          "color": "#99ff14",
          "content": "Survival of the fittest is not a zero-sum game",
          "flip": false,
          "cardId": "Resolution-8"
        },
        {
          "type": "predefined",
          "category": "Resolution",
          "label": [],
          "color": "#99ff14",
          "content": "The more connected we are, the lonelier we become",
          "flip": false,
          "cardId": "Resolution-9"
        },
        {
          "type": "predefined",
          "category": "Resolution",
          "label": [],
          "color": "#99ff14",
          "content": "The preservation of community demands sacrifice of the individual",
          "flip": false,
          "cardId": "Resolution-10"
        },
        {
          "type": "predefined",
          "category": "Resolution",
          "label": [],
          "color": "#99ff14",
          "content": "Torture is justified when it can save lives",
          "flip": false,
          "cardId": "Resolution-11"
        },
        {
          "type": "predefined",
          "category": "Resolution",
          "label": [],
          "color": "#99ff14",
          "content": "When we outsource thinking to machine, technology makes us stupid",
          "flip": false,
          "cardId": "Resolution-12"
        },
        {
          "type": "predefined",
          "category": "Resolution",
          "label": [],
          "color": "#99ff14",
          "content": "You should do a Spring Cleaning of your friends",
          "flip": false,
          "cardId": "Resolution-13"
        },
        {
          "type": "predefined",
          "category": "Resolution",
          "label": [],
          "color": "#99ff14",
          "content": "You should never marry yourself",
          "flip": false,
          "cardId": "Resolution-14"
        }
      ],
      "Dramatic Question": [
        {
          "type": "predefined",
          "category": "Dramatic Question",
          "label": [
            "Marker"
          ],
          "color": "#00966c",
          "content": "Dramatic Question",
          "flip": false,
          "cardId": "Dramatic Question-0"
        },
        {
          "type": "predefined",
          "category": "Dramatic Question",
          "label": [],
          "color": "#00966c",
          "content": "What if your life became your job?",
          "flip": false,
          "cardId": "Dramatic Question-1"
        },
        {
          "type": "predefined",
          "category": "Dramatic Question",
          "label": [],
          "color": "#00966c",
          "content": "What if you could use a service to get away with anything?",
          "flip": false,
          "cardId": "Dramatic Question-2"
        },
        {
          "type": "predefined",
          "category": "Dramatic Question",
          "label": [],
          "color": "#00966c",
          "content": "What if you witnessed an immaculate conception?",
          "flip": false,
          "cardId": "Dramatic Question-3"
        },
        {
          "type": "predefined",
          "category": "Dramatic Question",
          "label": [],
          "color": "#00966c",
          "content": "What if a virus was used to enhance the human race?",
          "flip": false,
          "cardId": "Dramatic Question-4"
        },
        {
          "type": "predefined",
          "category": "Dramatic Question",
          "label": [],
          "color": "#00966c",
          "content": "What if a pandemic was really an alien invasion?",
          "flip": false,
          "cardId": "Dramatic Question-5"
        },
        {
          "type": "predefined",
          "category": "Dramatic Question",
          "label": [],
          "color": "#00966c",
          "content": "What if you killed everyone you came in contact with?",
          "flip": false,
          "cardId": "Dramatic Question-6"
        }
      ],
      "Loglines": [
        {
          "type": "predefined",
          "category": "Loglines",
          "label": [
            "Marker"
          ],
          "color": "#fcc060",
          "content": "Loglines",
          "flip": false,
          "cardId": "Loglines-0"
        }
      ],
      "Taglines": [
        {
          "type": "predefined",
          "category": "Taglines",
          "label": [
            "Marker"
          ],
          "color": "#fa9600",
          "content": "Taglines",
          "flip": false,
          "cardId": "Taglines-0"
        }
      ],
      "Story Messages": [
        {
          "type": "predefined",
          "category": "Story Messages",
          "label": [
            "Marker"
          ],
          "color": "#7536ff",
          "content": "Story Messages",
          "flip": false,
          "cardId": "Story Messages-0"
        },
        {
          "type": "predefined",
          "category": "Story Messages",
          "label": [],
          "color": "#7536ff",
          "content": "The more inteconnected we are, the lonelier we become.",
          "flip": false,
          "cardId": "Story Messages-1"
        },
        {
          "type": "predefined",
          "category": "Story Messages",
          "label": [],
          "color": "#7536ff",
          "content": "Passion confuses intensity with intimacy; it enslaves its object of desire instead of respecting it.",
          "flip": false,
          "cardId": "Story Messages-2"
        },
        {
          "type": "predefined",
          "category": "Story Messages",
          "label": [],
          "color": "#7536ff",
          "content": "Life isn't worth living unless it's shared.",
          "flip": false,
          "cardId": "Story Messages-3"
        },
        {
          "type": "predefined",
          "category": "Story Messages",
          "label": [],
          "color": "#7536ff",
          "content": "When you leave morality up to society, you become amoral.",
          "flip": false,
          "cardId": "Story Messages-4"
        },
        {
          "type": "predefined",
          "category": "Story Messages",
          "label": [],
          "color": "#7536ff",
          "content": "The more humanity advances, the more it devolves.",
          "flip": false,
          "cardId": "Story Messages-5"
        },
        {
          "type": "predefined",
          "category": "Story Messages",
          "label": [],
          "color": "#7536ff",
          "content": "Love is giving someone permission to drive you crazy.",
          "flip": false,
          "cardId": "Story Messages-6"
        },
        {
          "type": "predefined",
          "category": "Story Messages",
          "label": [],
          "color": "#7536ff",
          "content": "You can't control love - only mitigate the disaster.",
          "flip": false,
          "cardId": "Story Messages-7"
        },
        {
          "type": "predefined",
          "category": "Story Messages",
          "label": [],
          "color": "#7536ff",
          "content": "Responsibility is not the price of freedom but the benefit.",
          "flip": false,
          "cardId": "Story Messages-8"
        },
        {
          "type": "predefined",
          "category": "Story Messages",
          "label": [],
          "color": "#7536ff",
          "content": "Failure to spend quality time with yourself means having little to offer anyone else.",
          "flip": false,
          "cardId": "Story Messages-9"
        },
        {
          "type": "predefined",
          "category": "Story Messages",
          "label": [],
          "color": "#7536ff",
          "content": "Technology degrades Humanity",
          "flip": false,
          "cardId": "Story Messages-10"
        }
      ],
      "categoryColorMap": {
        "Story": "#FF8BA7",
        "Money": "#2CB67D",
        "Request": "#3DA9FC",
        "Genre": "#A31B1B",
        "Topic": "#D08B5B",
        "Premises": "#1A49A5",
        "Modifier": "#219653",
        "Character": "#5B3680",
        "Archetype": "#FFC0C0",
        "Conflict": "#FFAA0D",
        "Crisis": "#4173D6",
        "Issues": "#53CAA6",
        "Resolution": "#7956C1",
        "Dramatic Question": "#219653",
        "Loglines": "#4173D6",
        "Taglines": "#5B3680",
        "Story Messages": "#D08B5B"
      },
      "uncategorized": []
    }
  },
  "categoryColorMap": {
    "Story": "#FF8BA7",
    "Money": "#2CB67D",
    "Request": "#3DA9FC",
    "Genre": "#A31B1B",
    "Topic": "#D08B5B",
    "Premises": "#1A49A5",
    "Modifier": "#219653",
    "Character": "#5B3680",
    "Archetype": "#FFC0C0",
    "Conflict": "#FFAA0D",
    "Crisis": "#4173D6",
    "Issues": "#53CAA6",
    "Resolution": "#7956C1",
    "Dramatic Question": "#219653",
    "Loglines": "#4173D6",
    "Taglines": "#5B3680",
    "Story Messages": "#D08B5B",
    "Blank": "#FFAA0D",
    "": "#C4C4C4"
  }
}

export const gd = {
    "gameConfig": {
      "phaseConfig": {
        "startingPhase": "Round",
        "phases": [
          {
            "phaseName": "Round",
            "phaseIndex": 1
          }
        ]
      },
      "events": {},
      "Round": {
        "startMessage": null,
        "endCondition": {},
        "endMessage": null,
        "endCommands": null,
        "scoreChange": 0,
        "nextPhase": null,
        "startingTurnStruct": "Turn A",
        "turnStructs": [
          {
            "turnStructName": "Turn A",
            "turnStructIndex": 2
          }
        ]
      },
      "Turn A": {
        "endCondition": null,
        "nextTurnStruct": null,
        "inTurn": {
          "Storyteller": {
            "stepMetaData": [
              {
                "stepName": "Step 1",
                "stepIndex": 5
              }
            ],
            "startingStep": "Step 1",
            "Step 1": {
              "actionMetaData": {
                "7": "Add Card",
                "13": "Drag Card",
                "14": "Flip Card",
                "15": "Retrieve Card",
                "16": "Delete Card",
                "18": "Pass Turn",
                "19": "Pair Card",
                "20": "Pin Card",
                "21": "Stack Card"
              },
              "actions": {
                "Pass Turn": {
                  "shiftAction": "Next Player",
                  "shiftTo": null
                }
              },
              "cardConfig": {
                "flippable": [
                  "Any"
                ],
                "stackable": [
                  "Any"
                ],
                "pairable": [
                  "Any"
                ],
                "pinnable": [
                  "Any"
                ],
                "edittable": true,
                "removable": [
                  "Any"
                ],
                "draggable": [
                  "Any"
                ],
                "retrieveable": [
                  "Any"
                ]
              },
              "cardAddition": {
                "Any": {
                  "canvas": true,
                  "inHand": false
                }
              },
              "componentConfig": {
                "voting": {
                  "abletoVote": false
                },
                "passing": {
                  "canPass": true
                }
              }
            }
          },
          "mainRole": "Storyteller"
        },
        "outTurn": {
          "Storyteller": {
            "stepMetaData": [
              {
                "stepName": "Step 1",
                "stepIndex": 10
              }
            ],
            "startingStep": "Step 1",
            "Step 1": {
              "actionMetaData": {
                "12": "Pin Card"
              },
              "actions": {},
              "cardConfig": {
                "flippable": [],
                "stackable": [],
                "pairable": [],
                "pinnable": [
                  "Any"
                ],
                "edittable": true,
                "removable": [],
                "draggable": [],
                "retrieveable": []
              },
              "cardAddition": {},
              "componentConfig": {
                "voting": {
                  "abletoVote": false
                },
                "passing": {
                  "canPass": false,
                  "passType": null
                }
              }
            }
          },
          "mainRole": "Storyteller"
        },
        "turnMetaData": {
          "3": "In Turn",
          "8": "Out Turn"
        }
      },
      "deck": {
        "endpoint": "storyDesign",
        "title": "Game Jam",
        "drawConfig": {
          "loop": true,
          "initialDraw": null
        }
      }
    },
    "gameDeck": {
      "name": "Story Design Cards",
      "deck": {
        "Story": [
          {
            "type": "predefined",
            "category": "Story",
            "label": [
              "Marker"
            ],
            "color": "#FF8BA7",
            "content": "Story",
            "flip": false,
            "cardId": "Story-0"
          },
          {
            "type": "predefined",
            "category": "Story",
            "label": [],
            "color": "#FF8BA7",
            "content": "Found Something",
            "flip": false,
            "cardId": "Story-1"
          },
          {
            "type": "predefined",
            "category": "Story",
            "label": [],
            "color": "#FF8BA7",
            "content": "Entertainment",
            "flip": false,
            "cardId": "Story-2"
          },
          {
            "type": "predefined",
            "category": "Story",
            "label": [],
            "color": "#FF8BA7",
            "content": "Birth",
            "flip": false,
            "cardId": "Story-3"
          },
          {
            "type": "predefined",
            "category": "Story",
            "label": [],
            "color": "#FF8BA7",
            "content": "Zero Sum",
            "flip": false,
            "cardId": "Story-4"
          },
          {
            "type": "predefined",
            "category": "Story",
            "label": [],
            "color": "#FF8BA7",
            "content": "Mental Health",
            "flip": false,
            "cardId": "Story-5"
          },
          {
            "type": "predefined",
            "category": "Story",
            "label": [],
            "color": "#FF8BA7",
            "content": "Gain",
            "flip": false,
            "cardId": "Story-6"
          },
          {
            "type": "predefined",
            "category": "Story",
            "label": [],
            "color": "#FF8BA7",
            "content": "Loss",
            "flip": false,
            "cardId": "Story-7"
          },
          {
            "type": "predefined",
            "category": "Story",
            "label": [],
            "color": "#FF8BA7",
            "content": "Achieve Something",
            "flip": false,
            "cardId": "Story-8"
          },
          {
            "type": "predefined",
            "category": "Story",
            "label": [],
            "color": "#FF8BA7",
            "content": "Animal Lover",
            "flip": false,
            "cardId": "Story-9"
          },
          {
            "type": "predefined",
            "category": "Story",
            "label": [],
            "color": "#FF8BA7",
            "content": "Emotionally Draining",
            "flip": false,
            "cardId": "Story-10"
          },
          {
            "type": "predefined",
            "category": "Story",
            "label": [],
            "color": "#FF8BA7",
            "content": "Savior",
            "flip": false,
            "cardId": "Story-11"
          },
          {
            "type": "predefined",
            "category": "Story",
            "label": [],
            "color": "#FF8BA7",
            "content": "End of the World",
            "flip": false,
            "cardId": "Story-12"
          },
          {
            "type": "predefined",
            "category": "Story",
            "label": [],
            "color": "#FF8BA7",
            "content": "Separation",
            "flip": false,
            "cardId": "Story-13"
          },
          {
            "type": "predefined",
            "category": "Story",
            "label": [],
            "color": "#FF8BA7",
            "content": "Failing",
            "flip": false,
            "cardId": "Story-14"
          },
          {
            "type": "predefined",
            "category": "Story",
            "label": [],
            "color": "#FF8BA7",
            "content": "Heartbreak",
            "flip": false,
            "cardId": "Story-15"
          },
          {
            "type": "predefined",
            "category": "Story",
            "label": [],
            "color": "#FF8BA7",
            "content": "Lust",
            "flip": false,
            "cardId": "Story-16"
          },
          {
            "type": "predefined",
            "category": "Story",
            "label": [],
            "color": "#FF8BA7",
            "content": "Envy",
            "flip": false,
            "cardId": "Story-17"
          },
          {
            "type": "predefined",
            "category": "Story",
            "label": [],
            "color": "#FF8BA7",
            "content": "Gluttony",
            "flip": false,
            "cardId": "Story-18"
          },
          {
            "type": "predefined",
            "category": "Story",
            "label": [],
            "color": "#FF8BA7",
            "content": "Death",
            "flip": false,
            "cardId": "Story-19"
          },
          {
            "type": "predefined",
            "category": "Story",
            "label": [],
            "color": "#FF8BA7",
            "content": "Embarassment",
            "flip": false,
            "cardId": "Story-20"
          },
          {
            "type": "predefined",
            "category": "Story",
            "label": [],
            "color": "#FF8BA7",
            "content": "Addiction",
            "flip": false,
            "cardId": "Story-21"
          },
          {
            "type": "predefined",
            "category": "Story",
            "label": [],
            "color": "#FF8BA7",
            "content": "Potential",
            "flip": false,
            "cardId": "Story-22"
          },
          {
            "type": "predefined",
            "category": "Story",
            "label": [],
            "color": "#FF8BA7",
            "content": "Hatred",
            "flip": false,
            "cardId": "Story-23"
          },
          {
            "type": "predefined",
            "category": "Story",
            "label": [],
            "color": "#FF8BA7",
            "content": "Failure",
            "flip": false,
            "cardId": "Story-24"
          },
          {
            "type": "predefined",
            "category": "Story",
            "label": [],
            "color": "#FF8BA7",
            "content": "Left Behind",
            "flip": false,
            "cardId": "Story-25"
          },
          {
            "type": "predefined",
            "category": "Story",
            "label": [],
            "color": "#FF8BA7",
            "content": "Love",
            "flip": false,
            "cardId": "Story-26"
          },
          {
            "type": "predefined",
            "category": "Story",
            "label": [],
            "color": "#FF8BA7",
            "content": "Clumsy",
            "flip": false,
            "cardId": "Story-27"
          },
          {
            "type": "predefined",
            "category": "Story",
            "label": [],
            "color": "#FF8BA7",
            "content": "Bully",
            "flip": false,
            "cardId": "Story-28"
          },
          {
            "type": "predefined",
            "category": "Story",
            "label": [],
            "color": "#FF8BA7",
            "content": "Family",
            "flip": false,
            "cardId": "Story-29"
          },
          {
            "type": "predefined",
            "category": "Story",
            "label": [],
            "color": "#FF8BA7",
            "content": "Murder",
            "flip": false,
            "cardId": "Story-30"
          },
          {
            "type": "predefined",
            "category": "Story",
            "label": [],
            "color": "#FF8BA7",
            "content": "Talented",
            "flip": false,
            "cardId": "Story-31"
          },
          {
            "type": "predefined",
            "category": "Story",
            "label": [],
            "color": "#FF8BA7",
            "content": "Talent",
            "flip": false,
            "cardId": "Story-32"
          },
          {
            "type": "predefined",
            "category": "Story",
            "label": [],
            "color": "#FF8BA7",
            "content": "Isolation",
            "flip": false,
            "cardId": "Story-33"
          },
          {
            "type": "predefined",
            "category": "Story",
            "label": [],
            "color": "#FF8BA7",
            "content": "Chains of Woe",
            "flip": false,
            "cardId": "Story-34"
          },
          {
            "type": "predefined",
            "category": "Story",
            "label": [],
            "color": "#FF8BA7",
            "content": "Rescue",
            "flip": false,
            "cardId": "Story-35"
          },
          {
            "type": "predefined",
            "category": "Story",
            "label": [],
            "color": "#FF8BA7",
            "content": "Wonderwall",
            "flip": false,
            "cardId": "Story-36"
          }
        ],
        "Money": [
          {
            "type": "predefined",
            "category": "Money",
            "label": [
              "Marker"
            ],
            "color": "#2CB67D",
            "content": "Money",
            "flip": false,
            "cardId": "Money-0"
          },
          {
            "type": "predefined",
            "category": "Money",
            "label": [],
            "color": "#2CB67D",
            "content": "$1",
            "flip": false,
            "cardId": "Money-1"
          },
          {
            "type": "predefined",
            "category": "Money",
            "label": [],
            "color": "#2CB67D",
            "content": "$2",
            "flip": false,
            "cardId": "Money-2"
          },
          {
            "type": "predefined",
            "category": "Money",
            "label": [],
            "color": "#2CB67D",
            "content": "$5",
            "flip": false,
            "cardId": "Money-3"
          },
          {
            "type": "predefined",
            "category": "Money",
            "label": [],
            "color": "#2CB67D",
            "content": "$10",
            "flip": false,
            "cardId": "Money-4"
          },
          {
            "type": "predefined",
            "category": "Money",
            "label": [],
            "color": "#2CB67D",
            "content": "$20",
            "flip": false,
            "cardId": "Money-5"
          },
          {
            "type": "predefined",
            "category": "Money",
            "label": [],
            "color": "#2CB67D",
            "content": "$50",
            "flip": false,
            "cardId": "Money-6"
          },
          {
            "type": "predefined",
            "category": "Money",
            "label": [],
            "color": "#2CB67D",
            "content": "$100",
            "flip": false,
            "cardId": "Money-7"
          }
        ],
        "Request": [
          {
            "type": "predefined",
            "category": "Request",
            "label": [
              "Marker"
            ],
            "color": "#3DA9FC",
            "content": "Request",
            "flip": false,
            "cardId": "Request-0"
          },
          {
            "type": "predefined",
            "category": "Request",
            "label": [],
            "color": "#3DA9FC",
            "content": "Please add a TIMESKIP to your story",
            "flip": false,
            "cardId": "Request-1"
          },
          {
            "type": "predefined",
            "category": "Request",
            "label": [],
            "color": "#3DA9FC",
            "content": "Everyone turns blind for some reason",
            "flip": false,
            "cardId": "Request-2"
          },
          {
            "type": "predefined",
            "category": "Request",
            "label": [],
            "color": "#3DA9FC",
            "content": "Be inclusive of LGBT Character",
            "flip": false,
            "cardId": "Request-3"
          },
          {
            "type": "predefined",
            "category": "Request",
            "label": [],
            "color": "#3DA9FC",
            "content": "Please include a cameo for Elsa",
            "flip": false,
            "cardId": "Request-4"
          },
          {
            "type": "predefined",
            "category": "Request",
            "label": [],
            "color": "#3DA9FC",
            "content": "Make it more positive",
            "flip": false,
            "cardId": "Request-5"
          },
          {
            "type": "predefined",
            "category": "Request",
            "label": [],
            "color": "#3DA9FC",
            "content": "Add Horror element",
            "flip": false,
            "cardId": "Request-6"
          },
          {
            "type": "predefined",
            "category": "Request",
            "label": [],
            "color": "#3DA9FC",
            "content": "Make it melodramatic",
            "flip": false,
            "cardId": "Request-7"
          },
          {
            "type": "predefined",
            "category": "Request",
            "label": [],
            "color": "#3DA9FC",
            "content": "Reader's Request (10$)",
            "flip": false,
            "cardId": "Request-8"
          },
          {
            "type": "predefined",
            "category": "Request",
            "label": [],
            "color": "#3DA9FC",
            "content": "Just show us the villain already!!",
            "flip": false,
            "cardId": "Request-9"
          },
          {
            "type": "predefined",
            "category": "Request",
            "label": [],
            "color": "#3DA9FC",
            "content": "Make it more positive",
            "flip": false,
            "cardId": "Request-10"
          },
          {
            "type": "predefined",
            "category": "Request",
            "label": [],
            "color": "#3DA9FC",
            "content": "Go to space and add a bunch of laser sword!",
            "flip": false,
            "cardId": "Request-11"
          },
          {
            "type": "predefined",
            "category": "Request",
            "label": [],
            "color": "#3DA9FC",
            "content": "Include highschool characters!",
            "flip": false,
            "cardId": "Request-12"
          },
          {
            "type": "predefined",
            "category": "Request",
            "label": [],
            "color": "#3DA9FC",
            "content": "End the story with a cliffhanger",
            "flip": false,
            "cardId": "Request-13"
          },
          {
            "type": "predefined",
            "category": "Request",
            "label": [],
            "color": "#3DA9FC",
            "content": "Introduce 3 new characters",
            "flip": false,
            "cardId": "Request-14"
          },
          {
            "type": "predefined",
            "category": "Request",
            "label": [],
            "color": "#3DA9FC",
            "content": "Let there be a superhero",
            "flip": false,
            "cardId": "Request-15"
          },
          {
            "type": "predefined",
            "category": "Request",
            "label": [],
            "color": "#3DA9FC",
            "content": "Add some Vegan themed story",
            "flip": false,
            "cardId": "Request-16"
          }
        ],
        "Genre": [
          {
            "type": "predefined",
            "category": "Genre",
            "label": [
              "Marker"
            ],
            "color": "#f54242",
            "content": "Genre",
            "flip": false,
            "cardId": "Genre-0"
          },
          {
            "type": "predefined",
            "category": "Genre",
            "label": [],
            "color": "#f54242",
            "content": "Action",
            "flip": false,
            "cardId": "Genre-1"
          },
          {
            "type": "predefined",
            "category": "Genre",
            "label": [],
            "color": "#f54242",
            "content": "Adventure",
            "flip": false,
            "cardId": "Genre-2"
          },
          {
            "type": "predefined",
            "category": "Genre",
            "label": [],
            "color": "#f54242",
            "content": "Animation",
            "flip": false,
            "cardId": "Genre-3"
          },
          {
            "type": "predefined",
            "category": "Genre",
            "label": [],
            "color": "#f54242",
            "content": "Black Comedy",
            "flip": false,
            "cardId": "Genre-4"
          },
          {
            "type": "predefined",
            "category": "Genre",
            "label": [],
            "color": "#f54242",
            "content": "Comedy",
            "flip": false,
            "cardId": "Genre-5"
          },
          {
            "type": "predefined",
            "category": "Genre",
            "label": [],
            "color": "#f54242",
            "content": "Crime",
            "flip": false,
            "cardId": "Genre-6"
          },
          {
            "type": "predefined",
            "category": "Genre",
            "label": [],
            "color": "#f54242",
            "content": "Fantasy",
            "flip": false,
            "cardId": "Genre-7"
          },
          {
            "type": "predefined",
            "category": "Genre",
            "label": [],
            "color": "#f54242",
            "content": "Folktale",
            "flip": false,
            "cardId": "Genre-8"
          },
          {
            "type": "predefined",
            "category": "Genre",
            "label": [],
            "color": "#f54242",
            "content": "Historical Fiction",
            "flip": false,
            "cardId": "Genre-9"
          },
          {
            "type": "predefined",
            "category": "Genre",
            "label": [],
            "color": "#f54242",
            "content": "Horror",
            "flip": false,
            "cardId": "Genre-10"
          },
          {
            "type": "predefined",
            "category": "Genre",
            "label": [],
            "color": "#f54242",
            "content": "Melodrama",
            "flip": false,
            "cardId": "Genre-11"
          },
          {
            "type": "predefined",
            "category": "Genre",
            "label": [],
            "color": "#f54242",
            "content": "Musical",
            "flip": false,
            "cardId": "Genre-12"
          },
          {
            "type": "predefined",
            "category": "Genre",
            "label": [],
            "color": "#f54242",
            "content": "Mystery",
            "flip": false,
            "cardId": "Genre-13"
          },
          {
            "type": "predefined",
            "category": "Genre",
            "label": [],
            "color": "#f54242",
            "content": "Noir",
            "flip": false,
            "cardId": "Genre-14"
          },
          {
            "type": "predefined",
            "category": "Genre",
            "label": [],
            "color": "#f54242",
            "content": "Police Procedural",
            "flip": false,
            "cardId": "Genre-15"
          },
          {
            "type": "predefined",
            "category": "Genre",
            "label": [],
            "color": "#f54242",
            "content": "Romance",
            "flip": false,
            "cardId": "Genre-16"
          },
          {
            "type": "predefined",
            "category": "Genre",
            "label": [],
            "color": "#f54242",
            "content": "Romantic Comedy",
            "flip": false,
            "cardId": "Genre-17"
          },
          {
            "type": "predefined",
            "category": "Genre",
            "label": [],
            "color": "#f54242",
            "content": "Satire",
            "flip": false,
            "cardId": "Genre-18"
          },
          {
            "type": "predefined",
            "category": "Genre",
            "label": [],
            "color": "#f54242",
            "content": "Sci-Fi",
            "flip": false,
            "cardId": "Genre-19"
          },
          {
            "type": "predefined",
            "category": "Genre",
            "label": [],
            "color": "#f54242",
            "content": "Suspense",
            "flip": false,
            "cardId": "Genre-20"
          },
          {
            "type": "predefined",
            "category": "Genre",
            "label": [],
            "color": "#f54242",
            "content": "Thriller",
            "flip": false,
            "cardId": "Genre-21"
          },
          {
            "type": "predefined",
            "category": "Genre",
            "label": [],
            "color": "#f54242",
            "content": "Western",
            "flip": false,
            "cardId": "Genre-22"
          }
        ],
        "Topic": [
          {
            "type": "predefined",
            "category": "Topic",
            "label": [
              "Marker"
            ],
            "color": "#319476",
            "content": "Topic",
            "flip": false,
            "cardId": "Topic-0"
          },
          {
            "type": "predefined",
            "category": "Topic",
            "label": [],
            "color": "#319476",
            "content": "AI / Robots",
            "flip": false,
            "cardId": "Topic-1"
          },
          {
            "type": "predefined",
            "category": "Topic",
            "label": [],
            "color": "#319476",
            "content": "Aliens",
            "flip": false,
            "cardId": "Topic-2"
          },
          {
            "type": "predefined",
            "category": "Topic",
            "label": [],
            "color": "#319476",
            "content": "Apropos of Nothing",
            "flip": false,
            "cardId": "Topic-3"
          },
          {
            "type": "predefined",
            "category": "Topic",
            "label": [],
            "color": "#319476",
            "content": "Attention Economy",
            "flip": false,
            "cardId": "Topic-4"
          },
          {
            "type": "predefined",
            "category": "Topic",
            "label": [],
            "color": "#319476",
            "content": "Baby Outsourcing",
            "flip": false,
            "cardId": "Topic-5"
          },
          {
            "type": "predefined",
            "category": "Topic",
            "label": [],
            "color": "#319476",
            "content": "Backhanded compliment",
            "flip": false,
            "cardId": "Topic-6"
          },
          {
            "type": "predefined",
            "category": "Topic",
            "label": [],
            "color": "#319476",
            "content": "Bingeing",
            "flip": false,
            "cardId": "Topic-7"
          },
          {
            "type": "predefined",
            "category": "Topic",
            "label": [],
            "color": "#319476",
            "content": "Body Art",
            "flip": false,
            "cardId": "Topic-8"
          },
          {
            "type": "predefined",
            "category": "Topic",
            "label": [],
            "color": "#319476",
            "content": "Body Shaming",
            "flip": false,
            "cardId": "Topic-9"
          },
          {
            "type": "predefined",
            "category": "Topic",
            "label": [],
            "color": "#319476",
            "content": "Cloning",
            "flip": false,
            "cardId": "Topic-10"
          },
          {
            "type": "predefined",
            "category": "Topic",
            "label": [],
            "color": "#319476",
            "content": "Collateral Damage",
            "flip": false,
            "cardId": "Topic-11"
          },
          {
            "type": "predefined",
            "category": "Topic",
            "label": [],
            "color": "#319476",
            "content": "Crisis Capitalism",
            "flip": false,
            "cardId": "Topic-12"
          },
          {
            "type": "predefined",
            "category": "Topic",
            "label": [],
            "color": "#319476",
            "content": "Crisis Cleansing",
            "flip": false,
            "cardId": "Topic-13"
          },
          {
            "type": "predefined",
            "category": "Topic",
            "label": [],
            "color": "#319476",
            "content": "Crispr",
            "flip": false,
            "cardId": "Topic-14"
          },
          {
            "type": "predefined",
            "category": "Topic",
            "label": [],
            "color": "#319476",
            "content": "Crispr Art",
            "flip": false,
            "cardId": "Topic-15"
          },
          {
            "type": "predefined",
            "category": "Topic",
            "label": [],
            "color": "#319476",
            "content": "Cruel Optimism",
            "flip": false,
            "cardId": "Topic-16"
          },
          {
            "type": "predefined",
            "category": "Topic",
            "label": [],
            "color": "#319476",
            "content": "Cult of Me",
            "flip": false,
            "cardId": "Topic-17"
          },
          {
            "type": "predefined",
            "category": "Topic",
            "label": [],
            "color": "#319476",
            "content": "Deep Fake",
            "flip": false,
            "cardId": "Topic-18"
          },
          {
            "type": "predefined",
            "category": "Topic",
            "label": [],
            "color": "#319476",
            "content": "Disaster Magnet",
            "flip": false,
            "cardId": "Topic-19"
          },
          {
            "type": "predefined",
            "category": "Topic",
            "label": [],
            "color": "#319476",
            "content": "Double Standard",
            "flip": false,
            "cardId": "Topic-20"
          },
          {
            "type": "predefined",
            "category": "Topic",
            "label": [],
            "color": "#319476",
            "content": "Doubling Down",
            "flip": false,
            "cardId": "Topic-21"
          },
          {
            "type": "predefined",
            "category": "Topic",
            "label": [],
            "color": "#319476",
            "content": "Eat the Rich",
            "flip": false,
            "cardId": "Topic-22"
          },
          {
            "type": "predefined",
            "category": "Topic",
            "label": [],
            "color": "#319476",
            "content": "Echo Chamber",
            "flip": false,
            "cardId": "Topic-23"
          },
          {
            "type": "predefined",
            "category": "Topic",
            "label": [],
            "color": "#319476",
            "content": "Embarassing Moment",
            "flip": false,
            "cardId": "Topic-24"
          },
          {
            "type": "predefined",
            "category": "Topic",
            "label": [],
            "color": "#319476",
            "content": "Extreme Wellness",
            "flip": false,
            "cardId": "Topic-25"
          },
          {
            "type": "predefined",
            "category": "Topic",
            "label": [],
            "color": "#319476",
            "content": "Factoids & Factotums",
            "flip": false,
            "cardId": "Topic-26"
          },
          {
            "type": "predefined",
            "category": "Topic",
            "label": [],
            "color": "#319476",
            "content": "Fake News",
            "flip": false,
            "cardId": "Topic-27"
          },
          {
            "type": "predefined",
            "category": "Topic",
            "label": [],
            "color": "#319476",
            "content": "Follow the money",
            "flip": false,
            "cardId": "Topic-28"
          },
          {
            "type": "predefined",
            "category": "Topic",
            "label": [],
            "color": "#319476",
            "content": "Free Will",
            "flip": false,
            "cardId": "Topic-29"
          },
          {
            "type": "predefined",
            "category": "Topic",
            "label": [],
            "color": "#319476",
            "content": "Gig Economy",
            "flip": false,
            "cardId": "Topic-30"
          },
          {
            "type": "predefined",
            "category": "Topic",
            "label": [],
            "color": "#319476",
            "content": "Grassroots Propaganda",
            "flip": false,
            "cardId": "Topic-31"
          },
          {
            "type": "predefined",
            "category": "Topic",
            "label": [],
            "color": "#319476",
            "content": "Guilty Pleasure",
            "flip": false,
            "cardId": "Topic-32"
          },
          {
            "type": "predefined",
            "category": "Topic",
            "label": [],
            "color": "#319476",
            "content": "Hedonistic Treadmill",
            "flip": false,
            "cardId": "Topic-33"
          },
          {
            "type": "predefined",
            "category": "Topic",
            "label": [],
            "color": "#319476",
            "content": "Herd Immunity",
            "flip": false,
            "cardId": "Topic-34"
          },
          {
            "type": "predefined",
            "category": "Topic",
            "label": [],
            "color": "#319476",
            "content": "Herd Mentality",
            "flip": false,
            "cardId": "Topic-35"
          },
          {
            "type": "predefined",
            "category": "Topic",
            "label": [],
            "color": "#319476",
            "content": "Homeschooling",
            "flip": false,
            "cardId": "Topic-36"
          },
          {
            "type": "predefined",
            "category": "Topic",
            "label": [],
            "color": "#319476",
            "content": "Illusion of Continuity",
            "flip": false,
            "cardId": "Topic-37"
          },
          {
            "type": "predefined",
            "category": "Topic",
            "label": [],
            "color": "#319476",
            "content": "Imaginary Friend",
            "flip": false,
            "cardId": "Topic-38"
          },
          {
            "type": "predefined",
            "category": "Topic",
            "label": [],
            "color": "#319476",
            "content": "Immortality",
            "flip": false,
            "cardId": "Topic-39"
          },
          {
            "type": "predefined",
            "category": "Topic",
            "label": [],
            "color": "#319476",
            "content": "Impulse Control",
            "flip": false,
            "cardId": "Topic-40"
          },
          {
            "type": "predefined",
            "category": "Topic",
            "label": [],
            "color": "#319476",
            "content": "Income Inequality",
            "flip": false,
            "cardId": "Topic-41"
          },
          {
            "type": "predefined",
            "category": "Topic",
            "label": [],
            "color": "#319476",
            "content": "Individual vs Society",
            "flip": false,
            "cardId": "Topic-42"
          },
          {
            "type": "predefined",
            "category": "Topic",
            "label": [],
            "color": "#319476",
            "content": "Infinite Scroll",
            "flip": false,
            "cardId": "Topic-43"
          },
          {
            "type": "predefined",
            "category": "Topic",
            "label": [],
            "color": "#319476",
            "content": "Instant Gratification",
            "flip": false,
            "cardId": "Topic-44"
          },
          {
            "type": "predefined",
            "category": "Topic",
            "label": [],
            "color": "#319476",
            "content": "Instrumental Rationality",
            "flip": false,
            "cardId": "Topic-45"
          },
          {
            "type": "predefined",
            "category": "Topic",
            "label": [],
            "color": "#319476",
            "content": "Life after Death",
            "flip": false,
            "cardId": "Topic-46"
          },
          {
            "type": "predefined",
            "category": "Topic",
            "label": [],
            "color": "#319476",
            "content": "Love Octopus",
            "flip": false,
            "cardId": "Topic-47"
          },
          {
            "type": "predefined",
            "category": "Topic",
            "label": [],
            "color": "#319476",
            "content": "Lucid Dreaming",
            "flip": false,
            "cardId": "Topic-48"
          },
          {
            "type": "predefined",
            "category": "Topic",
            "label": [],
            "color": "#319476",
            "content": "Mass Customization",
            "flip": false,
            "cardId": "Topic-49"
          },
          {
            "type": "predefined",
            "category": "Topic",
            "label": [],
            "color": "#319476",
            "content": "Mass Migration",
            "flip": false,
            "cardId": "Topic-50"
          },
          {
            "type": "predefined",
            "category": "Topic",
            "label": [],
            "color": "#319476",
            "content": "Mass Optimization",
            "flip": false,
            "cardId": "Topic-51"
          },
          {
            "type": "predefined",
            "category": "Topic",
            "label": [],
            "color": "#319476",
            "content": "Matchmaking",
            "flip": false,
            "cardId": "Topic-52"
          },
          {
            "type": "predefined",
            "category": "Topic",
            "label": [],
            "color": "#319476",
            "content": "Mental Hygiene",
            "flip": false,
            "cardId": "Topic-53"
          },
          {
            "type": "predefined",
            "category": "Topic",
            "label": [],
            "color": "#319476",
            "content": "Metrosexual",
            "flip": false,
            "cardId": "Topic-54"
          },
          {
            "type": "predefined",
            "category": "Topic",
            "label": [],
            "color": "#319476",
            "content": "Microbiome",
            "flip": false,
            "cardId": "Topic-55"
          },
          {
            "type": "predefined",
            "category": "Topic",
            "label": [],
            "color": "#319476",
            "content": "Moral Bankruptcy",
            "flip": false,
            "cardId": "Topic-56"
          },
          {
            "type": "predefined",
            "category": "Topic",
            "label": [],
            "color": "#319476",
            "content": "Nature vs Nurture",
            "flip": false,
            "cardId": "Topic-57"
          },
          {
            "type": "predefined",
            "category": "Topic",
            "label": [],
            "color": "#319476",
            "content": "Obesity Epidemic",
            "flip": false,
            "cardId": "Topic-58"
          },
          {
            "type": "predefined",
            "category": "Topic",
            "label": [],
            "color": "#319476",
            "content": "Open Source",
            "flip": false,
            "cardId": "Topic-59"
          },
          {
            "type": "predefined",
            "category": "Topic",
            "label": [],
            "color": "#319476",
            "content": "Opportunity Cost",
            "flip": false,
            "cardId": "Topic-60"
          },
          {
            "type": "predefined",
            "category": "Topic",
            "label": [],
            "color": "#319476",
            "content": "Outrage Industrial Complex",
            "flip": false,
            "cardId": "Topic-61"
          },
          {
            "type": "predefined",
            "category": "Topic",
            "label": [],
            "color": "#319476",
            "content": "Overpopulation",
            "flip": false,
            "cardId": "Topic-62"
          },
          {
            "type": "predefined",
            "category": "Topic",
            "label": [],
            "color": "#319476",
            "content": "Overton Window",
            "flip": false,
            "cardId": "Topic-63"
          },
          {
            "type": "predefined",
            "category": "Topic",
            "label": [],
            "color": "#319476",
            "content": "Painkiller",
            "flip": false,
            "cardId": "Topic-64"
          },
          {
            "type": "predefined",
            "category": "Topic",
            "label": [],
            "color": "#319476",
            "content": "Persuasion Design",
            "flip": false,
            "cardId": "Topic-65"
          },
          {
            "type": "predefined",
            "category": "Topic",
            "label": [],
            "color": "#319476",
            "content": "Pet Art",
            "flip": false,
            "cardId": "Topic-66"
          },
          {
            "type": "predefined",
            "category": "Topic",
            "label": [],
            "color": "#319476",
            "content": "Plastic Surgery",
            "flip": false,
            "cardId": "Topic-67"
          },
          {
            "type": "predefined",
            "category": "Topic",
            "label": [],
            "color": "#319476",
            "content": "Playdates",
            "flip": false,
            "cardId": "Topic-68"
          },
          {
            "type": "predefined",
            "category": "Topic",
            "label": [],
            "color": "#319476",
            "content": "Poverty Gap",
            "flip": false,
            "cardId": "Topic-69"
          },
          {
            "type": "predefined",
            "category": "Topic",
            "label": [],
            "color": "#319476",
            "content": "Procrastination",
            "flip": false,
            "cardId": "Topic-70"
          },
          {
            "type": "predefined",
            "category": "Topic",
            "label": [],
            "color": "#319476",
            "content": "Propaganda",
            "flip": false,
            "cardId": "Topic-71"
          },
          {
            "type": "predefined",
            "category": "Topic",
            "label": [],
            "color": "#319476",
            "content": "Refreshingly Horrific",
            "flip": false,
            "cardId": "Topic-72"
          },
          {
            "type": "predefined",
            "category": "Topic",
            "label": [],
            "color": "#319476",
            "content": "Revisionist History",
            "flip": false,
            "cardId": "Topic-73"
          },
          {
            "type": "predefined",
            "category": "Topic",
            "label": [],
            "color": "#319476",
            "content": "Ruin Porn",
            "flip": false,
            "cardId": "Topic-74"
          },
          {
            "type": "predefined",
            "category": "Topic",
            "label": [],
            "color": "#319476",
            "content": "Scarcity vs Abundance",
            "flip": false,
            "cardId": "Topic-75"
          },
          {
            "type": "predefined",
            "category": "Topic",
            "label": [],
            "color": "#319476",
            "content": "Schadenfreude",
            "flip": false,
            "cardId": "Topic-76"
          },
          {
            "type": "predefined",
            "category": "Topic",
            "label": [],
            "color": "#319476",
            "content": "Sharing Economy",
            "flip": false,
            "cardId": "Topic-77"
          },
          {
            "type": "predefined",
            "category": "Topic",
            "label": [],
            "color": "#319476",
            "content": "Sink Hole",
            "flip": false,
            "cardId": "Topic-78"
          },
          {
            "type": "predefined",
            "category": "Topic",
            "label": [],
            "color": "#319476",
            "content": "Social Cataclysm",
            "flip": false,
            "cardId": "Topic-79"
          },
          {
            "type": "predefined",
            "category": "Topic",
            "label": [],
            "color": "#319476",
            "content": "Social Distancing",
            "flip": false,
            "cardId": "Topic-80"
          },
          {
            "type": "predefined",
            "category": "Topic",
            "label": [],
            "color": "#319476",
            "content": "Spontaneous Combustion",
            "flip": false,
            "cardId": "Topic-81"
          },
          {
            "type": "predefined",
            "category": "Topic",
            "label": [],
            "color": "#319476",
            "content": "Strange Hobbies",
            "flip": false,
            "cardId": "Topic-82"
          },
          {
            "type": "predefined",
            "category": "Topic",
            "label": [],
            "color": "#319476",
            "content": "Stream of Consciousness",
            "flip": false,
            "cardId": "Topic-83"
          },
          {
            "type": "predefined",
            "category": "Topic",
            "label": [],
            "color": "#319476",
            "content": "Surveillance Capitalism",
            "flip": false,
            "cardId": "Topic-84"
          },
          {
            "type": "predefined",
            "category": "Topic",
            "label": [],
            "color": "#319476",
            "content": "Swiping Left",
            "flip": false,
            "cardId": "Topic-85"
          },
          {
            "type": "predefined",
            "category": "Topic",
            "label": [],
            "color": "#319476",
            "content": "Teachable Moment",
            "flip": false,
            "cardId": "Topic-86"
          },
          {
            "type": "predefined",
            "category": "Topic",
            "label": [],
            "color": "#319476",
            "content": "Tele-medicine",
            "flip": false,
            "cardId": "Topic-87"
          },
          {
            "type": "predefined",
            "category": "Topic",
            "label": [],
            "color": "#319476",
            "content": "Toxic Relationship",
            "flip": false,
            "cardId": "Topic-88"
          },
          {
            "type": "predefined",
            "category": "Topic",
            "label": [],
            "color": "#319476",
            "content": "Transcendental Meditation",
            "flip": false,
            "cardId": "Topic-89"
          },
          {
            "type": "predefined",
            "category": "Topic",
            "label": [],
            "color": "#319476",
            "content": "Trolling",
            "flip": false,
            "cardId": "Topic-90"
          },
          {
            "type": "predefined",
            "category": "Topic",
            "label": [],
            "color": "#319476",
            "content": "Tyranny of the Crowd",
            "flip": false,
            "cardId": "Topic-91"
          },
          {
            "type": "predefined",
            "category": "Topic",
            "label": [],
            "color": "#319476",
            "content": "Victim Blaming",
            "flip": false,
            "cardId": "Topic-92"
          },
          {
            "type": "predefined",
            "category": "Topic",
            "label": [],
            "color": "#319476",
            "content": "Viral Marketing",
            "flip": false,
            "cardId": "Topic-93"
          },
          {
            "type": "predefined",
            "category": "Topic",
            "label": [],
            "color": "#319476",
            "content": "Virtue Signalling",
            "flip": false,
            "cardId": "Topic-94"
          },
          {
            "type": "predefined",
            "category": "Topic",
            "label": [],
            "color": "#319476",
            "content": "Weaponized Nostalgia",
            "flip": false,
            "cardId": "Topic-95"
          },
          {
            "type": "predefined",
            "category": "Topic",
            "label": [],
            "color": "#319476",
            "content": "Worst Job Ever",
            "flip": false,
            "cardId": "Topic-96"
          },
          {
            "type": "predefined",
            "category": "Topic",
            "label": [],
            "color": "#319476",
            "content": "Zoom Bombing",
            "flip": false,
            "cardId": "Topic-97"
          }
        ],
        "Premises": [
          {
            "type": "predefined",
            "category": "Premises",
            "label": [
              "Marker"
            ],
            "color": null,
            "content": "Premises",
            "flip": false,
            "cardId": "Premises-0"
          },
          {
            "type": "predefined",
            "category": "Premises",
            "label": [],
            "color": "#f59e42",
            "content": "Absolute power corrupts absolutely",
            "flip": false,
            "cardId": "Premises-1"
          },
          {
            "type": "predefined",
            "category": "Premises",
            "label": [],
            "color": "#f59e42",
            "content": "Advice is what we ask for when we already know the answer but wish we didn’t",
            "flip": false,
            "cardId": "Premises-2"
          },
          {
            "type": "predefined",
            "category": "Premises",
            "label": [],
            "color": "#f59e42",
            "content": "All cruelty springs from weakness",
            "flip": false,
            "cardId": "Premises-3"
          },
          {
            "type": "predefined",
            "category": "Premises",
            "label": [],
            "color": "#f59e42",
            "content": "It is easier to resist at the beginning than at the end",
            "flip": false,
            "cardId": "Premises-4"
          },
          {
            "type": "predefined",
            "category": "Premises",
            "label": [],
            "color": "#f59e42",
            "content": "An artist never really finishes his work, he merely abandons it",
            "flip": false,
            "cardId": "Premises-5"
          },
          {
            "type": "predefined",
            "category": "Premises",
            "label": [],
            "color": "#f59e42",
            "content": "An escape from reality leads to a day of reckoning",
            "flip": false,
            "cardId": "Premises-6"
          },
          {
            "type": "predefined",
            "category": "Premises",
            "label": [],
            "color": "#f59e42",
            "content": "Art is a lie that makes us realise the truth",
            "flip": false,
            "cardId": "Premises-7"
          },
          {
            "type": "predefined",
            "category": "Premises",
            "label": [],
            "color": "#f59e42",
            "content": "Blind trust leads to destruction",
            "flip": false,
            "cardId": "Premises-8"
          },
          {
            "type": "predefined",
            "category": "Premises",
            "label": [],
            "color": "#f59e42",
            "content": "Better to die on your feet than live on your knees",
            "flip": false,
            "cardId": "Premises-9"
          },
          {
            "type": "predefined",
            "category": "Premises",
            "label": [],
            "color": "#f59e42",
            "content": "Death is not the worst that can happen",
            "flip": false,
            "cardId": "Premises-10"
          },
          {
            "type": "predefined",
            "category": "Premises",
            "label": [],
            "color": "#f59e42",
            "content": "There is no expedient to which a man will not resort to avoid the real labor of thinking",
            "flip": false,
            "cardId": "Premises-11"
          },
          {
            "type": "predefined",
            "category": "Premises",
            "label": [],
            "color": "#f59e42",
            "content": "Where all think alike, no one thinks very much",
            "flip": false,
            "cardId": "Premises-12"
          },
          {
            "type": "predefined",
            "category": "Premises",
            "label": [],
            "color": "#f59e42",
            "content": "The lesser evil always paves the way for the greater evil",
            "flip": false,
            "cardId": "Premises-13"
          },
          {
            "type": "predefined",
            "category": "Premises",
            "label": [],
            "color": "#f59e42",
            "content": "Power concedes nothing without a demand",
            "flip": false,
            "cardId": "Premises-14"
          },
          {
            "type": "predefined",
            "category": "Premises",
            "label": [],
            "color": "#f59e42",
            "content": "Evil flourishes when good men do nothing",
            "flip": false,
            "cardId": "Premises-15"
          },
          {
            "type": "predefined",
            "category": "Premises",
            "label": [],
            "color": "#f59e42",
            "content": "We are militarising our society at home to protect our geopolitical alliances abroad",
            "flip": false,
            "cardId": "Premises-16"
          },
          {
            "type": "predefined",
            "category": "Premises",
            "label": [],
            "color": "#f59e42",
            "content": "Money Talks / Wealth is power",
            "flip": false,
            "cardId": "Premises-17"
          },
          {
            "type": "predefined",
            "category": "Premises",
            "label": [],
            "color": "#f59e42",
            "content": "To those who have, more is given",
            "flip": false,
            "cardId": "Premises-18"
          },
          {
            "type": "predefined",
            "category": "Premises",
            "label": [],
            "color": "#f59e42",
            "content": "Enjoy the little things in life, for one day you’ll look back and realise they were big things",
            "flip": false,
            "cardId": "Premises-19"
          },
          {
            "type": "predefined",
            "category": "Premises",
            "label": [],
            "color": "#f59e42",
            "content": "It is easier to love humanity than to love your neighbour",
            "flip": false,
            "cardId": "Premises-20"
          },
          {
            "type": "predefined",
            "category": "Premises",
            "label": [],
            "color": "#f59e42",
            "content": "A citizen of the world is a citizen of nowhere",
            "flip": false,
            "cardId": "Premises-21"
          },
          {
            "type": "predefined",
            "category": "Premises",
            "label": [],
            "color": "#f59e42",
            "content": "The road to bell is paved with good intentions",
            "flip": false,
            "cardId": "Premises-22"
          },
          {
            "type": "predefined",
            "category": "Premises",
            "label": [],
            "color": "#f59e42",
            "content": "Too humble is half-proud.",
            "flip": false,
            "cardId": "Premises-23"
          },
          {
            "type": "predefined",
            "category": "Premises",
            "label": [],
            "color": "#f59e42",
            "content": "Beware of your friends, not your enemies.",
            "flip": false,
            "cardId": "Premises-24"
          },
          {
            "type": "predefined",
            "category": "Premises",
            "label": [],
            "color": "#f59e42",
            "content": "An idea isn’t responsible for the people who believe in it",
            "flip": false,
            "cardId": "Premises-25"
          },
          {
            "type": "predefined",
            "category": "Premises",
            "label": [],
            "color": "#f59e42",
            "content": "Rules are for the obedience of fools and the guidance of wise men",
            "flip": false,
            "cardId": "Premises-26"
          },
          {
            "type": "predefined",
            "category": "Premises",
            "label": [],
            "color": "#f59e42",
            "content": "Justice is what love looks like in public",
            "flip": false,
            "cardId": "Premises-27"
          },
          {
            "type": "predefined",
            "category": "Premises",
            "label": [],
            "color": "#f59e42",
            "content": "Not strong morals, but weak stomachs, keep us from being vultures",
            "flip": false,
            "cardId": "Premises-28"
          },
          {
            "type": "predefined",
            "category": "Premises",
            "label": [],
            "color": "#f59e42",
            "content": "All work is the avoidance of harder work",
            "flip": false,
            "cardId": "Premises-29"
          },
          {
            "type": "predefined",
            "category": "Premises",
            "label": [],
            "color": "#f59e42",
            "content": "Fortune cannot take away what she did not give",
            "flip": false,
            "cardId": "Premises-30"
          },
          {
            "type": "predefined",
            "category": "Premises",
            "label": [],
            "color": "#f59e42",
            "content": "Freedom is not procured by indulgence but by controlling desire",
            "flip": false,
            "cardId": "Premises-31"
          },
          {
            "type": "predefined",
            "category": "Premises",
            "label": [],
            "color": "#f59e42",
            "content": "The best time is stolen time",
            "flip": false,
            "cardId": "Premises-32"
          },
          {
            "type": "predefined",
            "category": "Premises",
            "label": [],
            "color": "#f59e42",
            "content": "Of all the ways to avoid living, perfect discipline is the most admired",
            "flip": false,
            "cardId": "Premises-33"
          },
          {
            "type": "predefined",
            "category": "Premises",
            "label": [],
            "color": "#f59e42",
            "content": "The first abuse of power is not realizing that you have it",
            "flip": false,
            "cardId": "Premises-34"
          },
          {
            "type": "predefined",
            "category": "Premises",
            "label": [],
            "color": "#f59e42",
            "content": "To be sincere is one thing; to practice Sincerity is to burden everyone else with believing you",
            "flip": false,
            "cardId": "Premises-35"
          },
          {
            "type": "predefined",
            "category": "Premises",
            "label": [],
            "color": "#f59e42",
            "content": "Water deepens where it has to wait",
            "flip": false,
            "cardId": "Premises-36"
          },
          {
            "type": "predefined",
            "category": "Premises",
            "label": [],
            "color": "#f59e42",
            "content": "There are silences harder to take back than words",
            "flip": false,
            "cardId": "Premises-37"
          },
          {
            "type": "predefined",
            "category": "Premises",
            "label": [],
            "color": "#f59e42",
            "content": "Each lock makes two prisons",
            "flip": false,
            "cardId": "Premises-38"
          },
          {
            "type": "predefined",
            "category": "Premises",
            "label": [],
            "color": "#f59e42",
            "content": "The ruts are deepest in the middle of the road",
            "flip": false,
            "cardId": "Premises-39"
          },
          {
            "type": "predefined",
            "category": "Premises",
            "label": [],
            "color": "#f59e42",
            "content": "We sell our time to get enough money to buy it back",
            "flip": false,
            "cardId": "Premises-40"
          },
          {
            "type": "predefined",
            "category": "Premises",
            "label": [],
            "color": "#f59e42",
            "content": "A man can be happy with any woman, so long as he does not love her",
            "flip": false,
            "cardId": "Premises-41"
          },
          {
            "type": "predefined",
            "category": "Premises",
            "label": [],
            "color": "#f59e42",
            "content": "One cannot learn what they think they already know",
            "flip": false,
            "cardId": "Premises-42"
          },
          {
            "type": "predefined",
            "category": "Premises",
            "label": [],
            "color": "#f59e42",
            "content": "Wealth consists not in having great possessions, but in having few wants",
            "flip": false,
            "cardId": "Premises-43"
          },
          {
            "type": "predefined",
            "category": "Premises",
            "label": [],
            "color": "#f59e42",
            "content": "Happiness isn’t getting what you want, it’s wanting what you’ve got",
            "flip": false,
            "cardId": "Premises-44"
          },
          {
            "type": "predefined",
            "category": "Premises",
            "label": [],
            "color": "#f59e42",
            "content": "The search for happiness is one of the chief sources of unhappiness",
            "flip": false,
            "cardId": "Premises-45"
          },
          {
            "type": "predefined",
            "category": "Premises",
            "label": [],
            "color": "#f59e42",
            "content": "A great success is made up of an aggregation of little ones",
            "flip": false,
            "cardId": "Premises-46"
          },
          {
            "type": "predefined",
            "category": "Premises",
            "label": [],
            "color": "#f59e42",
            "content": "A hero is one who knows how to hang on one minute longer",
            "flip": false,
            "cardId": "Premises-47"
          },
          {
            "type": "predefined",
            "category": "Premises",
            "label": [],
            "color": "#f59e42",
            "content": "Excessive liberty leads both nations and individuals into excessive slavery",
            "flip": false,
            "cardId": "Premises-48"
          },
          {
            "type": "predefined",
            "category": "Premises",
            "label": [],
            "color": "#f59e42",
            "content": "Facts are the enemies of the truth",
            "flip": false,
            "cardId": "Premises-49"
          },
          {
            "type": "predefined",
            "category": "Premises",
            "label": [],
            "color": "#f59e42",
            "content": "A lie has speed, but truth has endurance",
            "flip": false,
            "cardId": "Premises-50"
          },
          {
            "type": "predefined",
            "category": "Premises",
            "label": [],
            "color": "#f59e42",
            "content": "Good fences make good neighbours",
            "flip": false,
            "cardId": "Premises-51"
          },
          {
            "type": "predefined",
            "category": "Premises",
            "label": [],
            "color": "#f59e42",
            "content": "Great love defies even death",
            "flip": false,
            "cardId": "Premises-52"
          },
          {
            "type": "predefined",
            "category": "Premises",
            "label": [],
            "color": "#f59e42",
            "content": "He that is discontented in one place will seldom be content in another",
            "flip": false,
            "cardId": "Premises-53"
          },
          {
            "type": "predefined",
            "category": "Premises",
            "label": [],
            "color": "#f59e42",
            "content": "Hell hath no fury like a woman scorned",
            "flip": false,
            "cardId": "Premises-54"
          },
          {
            "type": "predefined",
            "category": "Premises",
            "label": [],
            "color": "#f59e42",
            "content": "How bitter a thing it is to look into happiness through another man’s eyes",
            "flip": false,
            "cardId": "Premises-55"
          },
          {
            "type": "predefined",
            "category": "Premises",
            "label": [],
            "color": "#f59e42",
            "content": "Jealousy destroys itself and the object of its affection",
            "flip": false,
            "cardId": "Premises-56"
          },
          {
            "type": "predefined",
            "category": "Premises",
            "label": [],
            "color": "#f59e42",
            "content": "Liberty is the right to do what the law permits",
            "flip": false,
            "cardId": "Premises-57"
          },
          {
            "type": "predefined",
            "category": "Premises",
            "label": [],
            "color": "#f59e42",
            "content": "The end justifies the means",
            "flip": false,
            "cardId": "Premises-58"
          },
          {
            "type": "predefined",
            "category": "Premises",
            "label": [],
            "color": "#f59e42",
            "content": "Crisis presents opportunity",
            "flip": false,
            "cardId": "Premises-59"
          },
          {
            "type": "predefined",
            "category": "Premises",
            "label": [],
            "color": "#f59e42",
            "content": "Sometimes one pays dearest for that whish is free",
            "flip": false,
            "cardId": "Premises-60"
          },
          {
            "type": "predefined",
            "category": "Premises",
            "label": [],
            "color": "#f59e42",
            "content": "The cruelest lies are often told in silence",
            "flip": false,
            "cardId": "Premises-61"
          },
          {
            "type": "predefined",
            "category": "Premises",
            "label": [],
            "color": "#f59e42",
            "content": "What doesn't kill you makes you stronger",
            "flip": false,
            "cardId": "Premises-62"
          },
          {
            "type": "predefined",
            "category": "Premises",
            "label": [],
            "color": "#f59e42",
            "content": "Don't judge a book by its cover",
            "flip": false,
            "cardId": "Premises-63"
          },
          {
            "type": "predefined",
            "category": "Premises",
            "label": [],
            "color": "#f59e42",
            "content": "Necessity is the mother of invention",
            "flip": false,
            "cardId": "Premises-64"
          },
          {
            "type": "predefined",
            "category": "Premises",
            "label": [],
            "color": "#f59e42",
            "content": "Don't count your chickens until they are hatched",
            "flip": false,
            "cardId": "Premises-65"
          },
          {
            "type": "predefined",
            "category": "Premises",
            "label": [],
            "color": "#f59e42",
            "content": "Blood is thicker than water",
            "flip": false,
            "cardId": "Premises-66"
          },
          {
            "type": "predefined",
            "category": "Premises",
            "label": [],
            "color": "#f59e42",
            "content": "We have fewer friends than we imagine but more than we know",
            "flip": false,
            "cardId": "Premises-67"
          },
          {
            "type": "predefined",
            "category": "Premises",
            "label": [],
            "color": "#f59e42",
            "content": "It is more shameful to distrust one's friends than to be deceived by them",
            "flip": false,
            "cardId": "Premises-68"
          },
          {
            "type": "predefined",
            "category": "Premises",
            "label": [],
            "color": "#f59e42",
            "content": "In prosperity our friends know us; in adversity we know our friends",
            "flip": false,
            "cardId": "Premises-69"
          },
          {
            "type": "predefined",
            "category": "Premises",
            "label": [],
            "color": "#f59e42",
            "content": "How much easier to make pets of our friends' weaknesses than to put up with their strengths",
            "flip": false,
            "cardId": "Premises-70"
          },
          {
            "type": "predefined",
            "category": "Premises",
            "label": [],
            "color": "#f59e42",
            "content": "There is no feeling of liberty like that of escape from half-friends",
            "flip": false,
            "cardId": "Premises-71"
          },
          {
            "type": "predefined",
            "category": "Premises",
            "label": [],
            "color": "#f59e42",
            "content": "He whose friendship is worth having, must hate and be hated",
            "flip": false,
            "cardId": "Premises-72"
          },
          {
            "type": "predefined",
            "category": "Premises",
            "label": [],
            "color": "#f59e42",
            "content": "It is easier to forgive an enemy than to forgive a friend",
            "flip": false,
            "cardId": "Premises-73"
          },
          {
            "type": "predefined",
            "category": "Premises",
            "label": [],
            "color": "#f59e42",
            "content": "He that has a thousand friends has not a friend to spare; and he who has one enemy will meet him everywhere",
            "flip": false,
            "cardId": "Premises-74"
          },
          {
            "type": "predefined",
            "category": "Premises",
            "label": [],
            "color": "#f59e42",
            "content": "Pay attention to your enemies for they are the ones to first discover your mistakes",
            "flip": false,
            "cardId": "Premises-75"
          },
          {
            "type": "predefined",
            "category": "Premises",
            "label": [],
            "color": "#f59e42",
            "content": "No viper so little but hath its venom",
            "flip": false,
            "cardId": "Premises-76"
          },
          {
            "type": "predefined",
            "category": "Premises",
            "label": [],
            "color": "#f59e42",
            "content": "God created the world but it is the devil who keeps it going",
            "flip": false,
            "cardId": "Premises-77"
          },
          {
            "type": "predefined",
            "category": "Premises",
            "label": [],
            "color": "#f59e42",
            "content": "Temptation laughs at the fool who takes it seriously",
            "flip": false,
            "cardId": "Premises-78"
          },
          {
            "type": "predefined",
            "category": "Premises",
            "label": [],
            "color": "#f59e42",
            "content": "Virtue is so praiseworthy that wicked people practise it from self-interest",
            "flip": false,
            "cardId": "Premises-79"
          },
          {
            "type": "predefined",
            "category": "Premises",
            "label": [],
            "color": "#f59e42",
            "content": "Virtue itself needs limits",
            "flip": false,
            "cardId": "Premises-80"
          },
          {
            "type": "predefined",
            "category": "Premises",
            "label": [],
            "color": "#f59e42",
            "content": "If you are not paying for the product, you are the product",
            "flip": false,
            "cardId": "Premises-81"
          },
          {
            "type": "predefined",
            "category": "Premises",
            "label": [],
            "color": "#f59e42",
            "content": "Not planning ahead is planning for failure",
            "flip": false,
            "cardId": "Premises-82"
          },
          {
            "type": "predefined",
            "category": "Premises",
            "label": [],
            "color": "#f59e42",
            "content": "Monsters are made; they don't come out of nowhere",
            "flip": false,
            "cardId": "Premises-83"
          },
          {
            "type": "predefined",
            "category": "Premises",
            "label": [],
            "color": "#f59e42",
            "content": "Lies are not used to convince others but to undermine truth itself",
            "flip": false,
            "cardId": "Premises-84"
          },
          {
            "type": "predefined",
            "category": "Premises",
            "label": [],
            "color": "#f59e42",
            "content": "If you aren't paying then you're not the customer - you're the product",
            "flip": false,
            "cardId": "Premises-85"
          },
          {
            "type": "predefined",
            "category": "Premises",
            "label": [],
            "color": "#f59e42",
            "content": "You can calculate the worth of a man by the number of his enemies, and the importance of a work of art by the harm that is spoken of it.",
            "flip": false,
            "cardId": "Premises-86"
          },
          {
            "type": "predefined",
            "category": "Premises",
            "label": [],
            "color": "#f59e42",
            "content": "Those who foolishly sought power by riding the back of the tiger ended up inside",
            "flip": false,
            "cardId": "Premises-87"
          },
          {
            "type": "predefined",
            "category": "Premises",
            "label": [],
            "color": "#f59e42",
            "content": "It’s only when the tide goes out that you learn who’s been swimming naked",
            "flip": false,
            "cardId": "Premises-88"
          },
          {
            "type": "predefined",
            "category": "Premises",
            "label": [],
            "color": "#f59e42",
            "content": "One man's trash is another man's treasure",
            "flip": false,
            "cardId": "Premises-89"
          },
          {
            "type": "predefined",
            "category": "Premises",
            "label": [],
            "color": "#f59e42",
            "content": "The road to Hell is paved with good intentions",
            "flip": false,
            "cardId": "Premises-90"
          },
          {
            "type": "predefined",
            "category": "Premises",
            "label": [],
            "color": "#f59e42",
            "content": "If you don't pay anything then you are the product",
            "flip": false,
            "cardId": "Premises-91"
          }
        ],
        "Modifier": [
          {
            "type": "predefined",
            "category": "Modifier",
            "label": [
              "Marker"
            ],
            "color": null,
            "content": "Modifier",
            "flip": false,
            "cardId": "Modifier-0"
          },
          {
            "type": "predefined",
            "category": "Modifier",
            "label": [],
            "color": "#e3f542",
            "content": "Absentminded",
            "flip": false,
            "cardId": "Modifier-1"
          },
          {
            "type": "predefined",
            "category": "Modifier",
            "label": [],
            "color": "#e3f542",
            "content": "Alcoholic",
            "flip": false,
            "cardId": "Modifier-2"
          },
          {
            "type": "predefined",
            "category": "Modifier",
            "label": [],
            "color": "#e3f542",
            "content": "Amorous",
            "flip": false,
            "cardId": "Modifier-3"
          },
          {
            "type": "predefined",
            "category": "Modifier",
            "label": [],
            "color": "#e3f542",
            "content": "Arrogant",
            "flip": false,
            "cardId": "Modifier-4"
          },
          {
            "type": "predefined",
            "category": "Modifier",
            "label": [],
            "color": "#e3f542",
            "content": "Atrocious",
            "flip": false,
            "cardId": "Modifier-5"
          },
          {
            "type": "predefined",
            "category": "Modifier",
            "label": [],
            "color": "#e3f542",
            "content": "Atypical",
            "flip": false,
            "cardId": "Modifier-6"
          },
          {
            "type": "predefined",
            "category": "Modifier",
            "label": [],
            "color": "#e3f542",
            "content": "Awkward",
            "flip": false,
            "cardId": "Modifier-7"
          },
          {
            "type": "predefined",
            "category": "Modifier",
            "label": [],
            "color": "#e3f542",
            "content": "Blackmailed",
            "flip": false,
            "cardId": "Modifier-8"
          },
          {
            "type": "predefined",
            "category": "Modifier",
            "label": [],
            "color": "#e3f542",
            "content": "Blind",
            "flip": false,
            "cardId": "Modifier-9"
          },
          {
            "type": "predefined",
            "category": "Modifier",
            "label": [],
            "color": "#e3f542",
            "content": "Bloodthirsty",
            "flip": false,
            "cardId": "Modifier-10"
          },
          {
            "type": "predefined",
            "category": "Modifier",
            "label": [],
            "color": "#e3f542",
            "content": "Brave",
            "flip": false,
            "cardId": "Modifier-11"
          },
          {
            "type": "predefined",
            "category": "Modifier",
            "label": [],
            "color": "#e3f542",
            "content": "Caustic",
            "flip": false,
            "cardId": "Modifier-12"
          },
          {
            "type": "predefined",
            "category": "Modifier",
            "label": [],
            "color": "#e3f542",
            "content": "Clueless",
            "flip": false,
            "cardId": "Modifier-13"
          },
          {
            "type": "predefined",
            "category": "Modifier",
            "label": [],
            "color": "#e3f542",
            "content": "Cruel",
            "flip": false,
            "cardId": "Modifier-14"
          },
          {
            "type": "predefined",
            "category": "Modifier",
            "label": [],
            "color": "#e3f542",
            "content": "Cursed",
            "flip": false,
            "cardId": "Modifier-15"
          },
          {
            "type": "predefined",
            "category": "Modifier",
            "label": [],
            "color": "#e3f542",
            "content": "Damaged",
            "flip": false,
            "cardId": "Modifier-16"
          },
          {
            "type": "predefined",
            "category": "Modifier",
            "label": [],
            "color": "#e3f542",
            "content": "Deranged",
            "flip": false,
            "cardId": "Modifier-17"
          },
          {
            "type": "predefined",
            "category": "Modifier",
            "label": [],
            "color": "#e3f542",
            "content": "Desperate",
            "flip": false,
            "cardId": "Modifier-18"
          },
          {
            "type": "predefined",
            "category": "Modifier",
            "label": [],
            "color": "#e3f542",
            "content": "Devoted",
            "flip": false,
            "cardId": "Modifier-19"
          },
          {
            "type": "predefined",
            "category": "Modifier",
            "label": [],
            "color": "#e3f542",
            "content": "Diguised",
            "flip": false,
            "cardId": "Modifier-20"
          },
          {
            "type": "predefined",
            "category": "Modifier",
            "label": [],
            "color": "#e3f542",
            "content": "Down & Out",
            "flip": false,
            "cardId": "Modifier-21"
          },
          {
            "type": "predefined",
            "category": "Modifier",
            "label": [],
            "color": "#e3f542",
            "content": "Dysfunctional",
            "flip": false,
            "cardId": "Modifier-22"
          },
          {
            "type": "predefined",
            "category": "Modifier",
            "label": [],
            "color": "#e3f542",
            "content": "Eternal optimist",
            "flip": false,
            "cardId": "Modifier-23"
          },
          {
            "type": "predefined",
            "category": "Modifier",
            "label": [],
            "color": "#e3f542",
            "content": "Exiled",
            "flip": false,
            "cardId": "Modifier-24"
          },
          {
            "type": "predefined",
            "category": "Modifier",
            "label": [],
            "color": "#e3f542",
            "content": "Extra-ordinary",
            "flip": false,
            "cardId": "Modifier-25"
          },
          {
            "type": "predefined",
            "category": "Modifier",
            "label": [],
            "color": "#e3f542",
            "content": "Extroverted",
            "flip": false,
            "cardId": "Modifier-26"
          },
          {
            "type": "predefined",
            "category": "Modifier",
            "label": [],
            "color": "#e3f542",
            "content": "Forgotten",
            "flip": false,
            "cardId": "Modifier-27"
          },
          {
            "type": "predefined",
            "category": "Modifier",
            "label": [],
            "color": "#e3f542",
            "content": "Forlorn",
            "flip": false,
            "cardId": "Modifier-28"
          },
          {
            "type": "predefined",
            "category": "Modifier",
            "label": [],
            "color": "#e3f542",
            "content": "Greedy",
            "flip": false,
            "cardId": "Modifier-29"
          },
          {
            "type": "predefined",
            "category": "Modifier",
            "label": [],
            "color": "#e3f542",
            "content": "Guilt ridden",
            "flip": false,
            "cardId": "Modifier-30"
          },
          {
            "type": "predefined",
            "category": "Modifier",
            "label": [],
            "color": "#e3f542",
            "content": "Hippy",
            "flip": false,
            "cardId": "Modifier-31"
          },
          {
            "type": "predefined",
            "category": "Modifier",
            "label": [],
            "color": "#e3f542",
            "content": "Hypochondriac",
            "flip": false,
            "cardId": "Modifier-32"
          },
          {
            "type": "predefined",
            "category": "Modifier",
            "label": [],
            "color": "#e3f542",
            "content": "Impulsive",
            "flip": false,
            "cardId": "Modifier-33"
          },
          {
            "type": "predefined",
            "category": "Modifier",
            "label": [],
            "color": "#e3f542",
            "content": "In Hiding",
            "flip": false,
            "cardId": "Modifier-34"
          },
          {
            "type": "predefined",
            "category": "Modifier",
            "label": [],
            "color": "#e3f542",
            "content": "Incompetent",
            "flip": false,
            "cardId": "Modifier-35"
          },
          {
            "type": "predefined",
            "category": "Modifier",
            "label": [],
            "color": "#e3f542",
            "content": "Incomplete",
            "flip": false,
            "cardId": "Modifier-36"
          },
          {
            "type": "predefined",
            "category": "Modifier",
            "label": [],
            "color": "#e3f542",
            "content": "Inept",
            "flip": false,
            "cardId": "Modifier-37"
          },
          {
            "type": "predefined",
            "category": "Modifier",
            "label": [],
            "color": "#e3f542",
            "content": "Insecure",
            "flip": false,
            "cardId": "Modifier-38"
          },
          {
            "type": "predefined",
            "category": "Modifier",
            "label": [],
            "color": "#e3f542",
            "content": "Invisible",
            "flip": false,
            "cardId": "Modifier-39"
          },
          {
            "type": "predefined",
            "category": "Modifier",
            "label": [],
            "color": "#e3f542",
            "content": "Jaded",
            "flip": false,
            "cardId": "Modifier-40"
          },
          {
            "type": "predefined",
            "category": "Modifier",
            "label": [],
            "color": "#e3f542",
            "content": "Kickass",
            "flip": false,
            "cardId": "Modifier-41"
          },
          {
            "type": "predefined",
            "category": "Modifier",
            "label": [],
            "color": "#e3f542",
            "content": "Know it all",
            "flip": false,
            "cardId": "Modifier-42"
          },
          {
            "type": "predefined",
            "category": "Modifier",
            "label": [],
            "color": "#e3f542",
            "content": "Kooky",
            "flip": false,
            "cardId": "Modifier-43"
          },
          {
            "type": "predefined",
            "category": "Modifier",
            "label": [],
            "color": "#e3f542",
            "content": "Lazy",
            "flip": false,
            "cardId": "Modifier-44"
          },
          {
            "type": "predefined",
            "category": "Modifier",
            "label": [],
            "color": "#e3f542",
            "content": "Lustful",
            "flip": false,
            "cardId": "Modifier-45"
          },
          {
            "type": "predefined",
            "category": "Modifier",
            "label": [],
            "color": "#e3f542",
            "content": "Macho",
            "flip": false,
            "cardId": "Modifier-46"
          },
          {
            "type": "predefined",
            "category": "Modifier",
            "label": [],
            "color": "#e3f542",
            "content": "Messianic",
            "flip": false,
            "cardId": "Modifier-47"
          },
          {
            "type": "predefined",
            "category": "Modifier",
            "label": [],
            "color": "#e3f542",
            "content": "Mysterious",
            "flip": false,
            "cardId": "Modifier-48"
          },
          {
            "type": "predefined",
            "category": "Modifier",
            "label": [],
            "color": "#e3f542",
            "content": "Narcissist",
            "flip": false,
            "cardId": "Modifier-49"
          },
          {
            "type": "predefined",
            "category": "Modifier",
            "label": [],
            "color": "#e3f542",
            "content": "Nature nerd",
            "flip": false,
            "cardId": "Modifier-50"
          },
          {
            "type": "predefined",
            "category": "Modifier",
            "label": [],
            "color": "#e3f542",
            "content": "Ne're do well",
            "flip": false,
            "cardId": "Modifier-51"
          },
          {
            "type": "predefined",
            "category": "Modifier",
            "label": [],
            "color": "#e3f542",
            "content": "Obstinate",
            "flip": false,
            "cardId": "Modifier-52"
          },
          {
            "type": "predefined",
            "category": "Modifier",
            "label": [],
            "color": "#e3f542",
            "content": "On the run",
            "flip": false,
            "cardId": "Modifier-53"
          },
          {
            "type": "predefined",
            "category": "Modifier",
            "label": [],
            "color": "#e3f542",
            "content": "Overworked",
            "flip": false,
            "cardId": "Modifier-54"
          },
          {
            "type": "predefined",
            "category": "Modifier",
            "label": [],
            "color": "#e3f542",
            "content": "Pathological",
            "flip": false,
            "cardId": "Modifier-55"
          },
          {
            "type": "predefined",
            "category": "Modifier",
            "label": [],
            "color": "#e3f542",
            "content": "Reluctant",
            "flip": false,
            "cardId": "Modifier-56"
          },
          {
            "type": "predefined",
            "category": "Modifier",
            "label": [],
            "color": "#e3f542",
            "content": "Selfish",
            "flip": false,
            "cardId": "Modifier-57"
          },
          {
            "type": "predefined",
            "category": "Modifier",
            "label": [],
            "color": "#e3f542",
            "content": "Slippery",
            "flip": false,
            "cardId": "Modifier-58"
          },
          {
            "type": "predefined",
            "category": "Modifier",
            "label": [],
            "color": "#e3f542",
            "content": "Soft spoken",
            "flip": false,
            "cardId": "Modifier-59"
          },
          {
            "type": "predefined",
            "category": "Modifier",
            "label": [],
            "color": "#e3f542",
            "content": "Spineless",
            "flip": false,
            "cardId": "Modifier-60"
          },
          {
            "type": "predefined",
            "category": "Modifier",
            "label": [],
            "color": "#e3f542",
            "content": "Struggling",
            "flip": false,
            "cardId": "Modifier-61"
          },
          {
            "type": "predefined",
            "category": "Modifier",
            "label": [],
            "color": "#e3f542",
            "content": "Tragic",
            "flip": false,
            "cardId": "Modifier-62"
          },
          {
            "type": "predefined",
            "category": "Modifier",
            "label": [],
            "color": "#e3f542",
            "content": "Trigger happy",
            "flip": false,
            "cardId": "Modifier-63"
          },
          {
            "type": "predefined",
            "category": "Modifier",
            "label": [],
            "color": "#e3f542",
            "content": "Unattractive",
            "flip": false,
            "cardId": "Modifier-64"
          },
          {
            "type": "predefined",
            "category": "Modifier",
            "label": [],
            "color": "#e3f542",
            "content": "Undercover",
            "flip": false,
            "cardId": "Modifier-65"
          },
          {
            "type": "predefined",
            "category": "Modifier",
            "label": [],
            "color": "#e3f542",
            "content": "Unethical",
            "flip": false,
            "cardId": "Modifier-66"
          },
          {
            "type": "predefined",
            "category": "Modifier",
            "label": [],
            "color": "#e3f542",
            "content": "Unhinged",
            "flip": false,
            "cardId": "Modifier-67"
          },
          {
            "type": "predefined",
            "category": "Modifier",
            "label": [],
            "color": "#e3f542",
            "content": "Violent",
            "flip": false,
            "cardId": "Modifier-68"
          },
          {
            "type": "predefined",
            "category": "Modifier",
            "label": [],
            "color": "#e3f542",
            "content": "Washed up",
            "flip": false,
            "cardId": "Modifier-69"
          },
          {
            "type": "predefined",
            "category": "Modifier",
            "label": [],
            "color": "#e3f542",
            "content": "Wily",
            "flip": false,
            "cardId": "Modifier-70"
          },
          {
            "type": "predefined",
            "category": "Modifier",
            "label": [],
            "color": "#e3f542",
            "content": "World-famous",
            "flip": false,
            "cardId": "Modifier-71"
          }
        ],
        "Character": [
          {
            "type": "predefined",
            "category": "Character",
            "label": [
              "Marker"
            ],
            "color": null,
            "content": "Character",
            "flip": false,
            "cardId": "Character-0"
          }
        ],
        "Archetype": [
          {
            "type": "predefined",
            "category": "Archetype",
            "label": [
              "Marker"
            ],
            "color": "#59fff9",
            "content": "Archetype",
            "flip": false,
            "cardId": "Archetype-0"
          },
          {
            "type": "predefined",
            "category": "Archetype",
            "label": [],
            "color": "#59fff9",
            "content": "All Seeing Eye",
            "flip": false,
            "cardId": "Archetype-1"
          },
          {
            "type": "predefined",
            "category": "Archetype",
            "label": [],
            "color": "#59fff9",
            "content": "Bad Boy/Girl",
            "flip": false,
            "cardId": "Archetype-2"
          },
          {
            "type": "predefined",
            "category": "Archetype",
            "label": [],
            "color": "#59fff9",
            "content": "Boss",
            "flip": false,
            "cardId": "Archetype-3"
          },
          {
            "type": "predefined",
            "category": "Archetype",
            "label": [],
            "color": "#59fff9",
            "content": "Career Criminal",
            "flip": false,
            "cardId": "Archetype-4"
          },
          {
            "type": "predefined",
            "category": "Archetype",
            "label": [],
            "color": "#59fff9",
            "content": "Cheerleader",
            "flip": false,
            "cardId": "Archetype-5"
          },
          {
            "type": "predefined",
            "category": "Archetype",
            "label": [],
            "color": "#59fff9",
            "content": "Child Prodigy",
            "flip": false,
            "cardId": "Archetype-6"
          },
          {
            "type": "predefined",
            "category": "Archetype",
            "label": [],
            "color": "#59fff9",
            "content": "Chosen One",
            "flip": false,
            "cardId": "Archetype-7"
          },
          {
            "type": "predefined",
            "category": "Archetype",
            "label": [],
            "color": "#59fff9",
            "content": "Confidant",
            "flip": false,
            "cardId": "Archetype-8"
          },
          {
            "type": "predefined",
            "category": "Archetype",
            "label": [],
            "color": "#59fff9",
            "content": "Corruptor",
            "flip": false,
            "cardId": "Archetype-9"
          },
          {
            "type": "predefined",
            "category": "Archetype",
            "label": [],
            "color": "#59fff9",
            "content": "Country Bumpkin",
            "flip": false,
            "cardId": "Archetype-10"
          },
          {
            "type": "predefined",
            "category": "Archetype",
            "label": [],
            "color": "#59fff9",
            "content": "Fair Weather Friend",
            "flip": false,
            "cardId": "Archetype-11"
          },
          {
            "type": "predefined",
            "category": "Archetype",
            "label": [],
            "color": "#59fff9",
            "content": "Fall Guy",
            "flip": false,
            "cardId": "Archetype-12"
          },
          {
            "type": "predefined",
            "category": "Archetype",
            "label": [],
            "color": "#59fff9",
            "content": "Father FIgure",
            "flip": false,
            "cardId": "Archetype-13"
          },
          {
            "type": "predefined",
            "category": "Archetype",
            "label": [],
            "color": "#59fff9",
            "content": "Femme Fatale",
            "flip": false,
            "cardId": "Archetype-14"
          },
          {
            "type": "predefined",
            "category": "Archetype",
            "label": [],
            "color": "#59fff9",
            "content": "Ferryman",
            "flip": false,
            "cardId": "Archetype-15"
          },
          {
            "type": "predefined",
            "category": "Archetype",
            "label": [],
            "color": "#59fff9",
            "content": "Fish out of water",
            "flip": false,
            "cardId": "Archetype-16"
          },
          {
            "type": "predefined",
            "category": "Archetype",
            "label": [],
            "color": "#59fff9",
            "content": "Fool",
            "flip": false,
            "cardId": "Archetype-17"
          },
          {
            "type": "predefined",
            "category": "Archetype",
            "label": [],
            "color": "#59fff9",
            "content": "Friend with Benefits",
            "flip": false,
            "cardId": "Archetype-18"
          },
          {
            "type": "predefined",
            "category": "Archetype",
            "label": [],
            "color": "#59fff9",
            "content": "Gentle Giant",
            "flip": false,
            "cardId": "Archetype-19"
          },
          {
            "type": "predefined",
            "category": "Archetype",
            "label": [],
            "color": "#59fff9",
            "content": "Herald",
            "flip": false,
            "cardId": "Archetype-20"
          },
          {
            "type": "predefined",
            "category": "Archetype",
            "label": [],
            "color": "#59fff9",
            "content": "Hero",
            "flip": false,
            "cardId": "Archetype-21"
          },
          {
            "type": "predefined",
            "category": "Archetype",
            "label": [],
            "color": "#59fff9",
            "content": "Hot Shot",
            "flip": false,
            "cardId": "Archetype-22"
          },
          {
            "type": "predefined",
            "category": "Archetype",
            "label": [],
            "color": "#59fff9",
            "content": "Hunter",
            "flip": false,
            "cardId": "Archetype-23"
          },
          {
            "type": "predefined",
            "category": "Archetype",
            "label": [],
            "color": "#59fff9",
            "content": "Imaginary Friend",
            "flip": false,
            "cardId": "Archetype-24"
          },
          {
            "type": "predefined",
            "category": "Archetype",
            "label": [],
            "color": "#59fff9",
            "content": "Ingenue",
            "flip": false,
            "cardId": "Archetype-25"
          },
          {
            "type": "predefined",
            "category": "Archetype",
            "label": [],
            "color": "#59fff9",
            "content": "Jock",
            "flip": false,
            "cardId": "Archetype-26"
          },
          {
            "type": "predefined",
            "category": "Archetype",
            "label": [],
            "color": "#59fff9",
            "content": "Last One Standing",
            "flip": false,
            "cardId": "Archetype-27"
          },
          {
            "type": "predefined",
            "category": "Archetype",
            "label": [],
            "color": "#59fff9",
            "content": "Law Man",
            "flip": false,
            "cardId": "Archetype-28"
          },
          {
            "type": "predefined",
            "category": "Archetype",
            "label": [],
            "color": "#59fff9",
            "content": "Loner",
            "flip": false,
            "cardId": "Archetype-29"
          },
          {
            "type": "predefined",
            "category": "Archetype",
            "label": [],
            "color": "#59fff9",
            "content": "Loser",
            "flip": false,
            "cardId": "Archetype-30"
          },
          {
            "type": "predefined",
            "category": "Archetype",
            "label": [],
            "color": "#59fff9",
            "content": "Love Child",
            "flip": false,
            "cardId": "Archetype-31"
          },
          {
            "type": "predefined",
            "category": "Archetype",
            "label": [],
            "color": "#59fff9",
            "content": "Love Interest",
            "flip": false,
            "cardId": "Archetype-32"
          },
          {
            "type": "predefined",
            "category": "Archetype",
            "label": [],
            "color": "#59fff9",
            "content": "Loveable Rogue",
            "flip": false,
            "cardId": "Archetype-33"
          },
          {
            "type": "predefined",
            "category": "Archetype",
            "label": [],
            "color": "#59fff9",
            "content": "Lover",
            "flip": false,
            "cardId": "Archetype-34"
          },
          {
            "type": "predefined",
            "category": "Archetype",
            "label": [],
            "color": "#59fff9",
            "content": "Loyalist",
            "flip": false,
            "cardId": "Archetype-35"
          },
          {
            "type": "predefined",
            "category": "Archetype",
            "label": [],
            "color": "#59fff9",
            "content": "Mad Scienctist",
            "flip": false,
            "cardId": "Archetype-36"
          },
          {
            "type": "predefined",
            "category": "Archetype",
            "label": [],
            "color": "#59fff9",
            "content": "Magus",
            "flip": false,
            "cardId": "Archetype-37"
          },
          {
            "type": "predefined",
            "category": "Archetype",
            "label": [],
            "color": "#59fff9",
            "content": "Mentor",
            "flip": false,
            "cardId": "Archetype-38"
          },
          {
            "type": "predefined",
            "category": "Archetype",
            "label": [],
            "color": "#59fff9",
            "content": "Momma's Boy",
            "flip": false,
            "cardId": "Archetype-39"
          },
          {
            "type": "predefined",
            "category": "Archetype",
            "label": [],
            "color": "#59fff9",
            "content": "Mother Figure",
            "flip": false,
            "cardId": "Archetype-40"
          },
          {
            "type": "predefined",
            "category": "Archetype",
            "label": [],
            "color": "#59fff9",
            "content": "Nemesis",
            "flip": false,
            "cardId": "Archetype-41"
          },
          {
            "type": "predefined",
            "category": "Archetype",
            "label": [],
            "color": "#59fff9",
            "content": "Nerd",
            "flip": false,
            "cardId": "Archetype-42"
          },
          {
            "type": "predefined",
            "category": "Archetype",
            "label": [],
            "color": "#59fff9",
            "content": "Outlaw",
            "flip": false,
            "cardId": "Archetype-43"
          },
          {
            "type": "predefined",
            "category": "Archetype",
            "label": [],
            "color": "#59fff9",
            "content": "Outsider",
            "flip": false,
            "cardId": "Archetype-44"
          },
          {
            "type": "predefined",
            "category": "Archetype",
            "label": [],
            "color": "#59fff9",
            "content": "Peacemaker",
            "flip": false,
            "cardId": "Archetype-45"
          },
          {
            "type": "predefined",
            "category": "Archetype",
            "label": [],
            "color": "#59fff9",
            "content": "Prodigy",
            "flip": false,
            "cardId": "Archetype-46"
          },
          {
            "type": "predefined",
            "category": "Archetype",
            "label": [],
            "color": "#59fff9",
            "content": "Psycopath",
            "flip": false,
            "cardId": "Archetype-47"
          },
          {
            "type": "predefined",
            "category": "Archetype",
            "label": [],
            "color": "#59fff9",
            "content": "Rebel",
            "flip": false,
            "cardId": "Archetype-48"
          },
          {
            "type": "predefined",
            "category": "Archetype",
            "label": [],
            "color": "#59fff9",
            "content": "Sentient Creature",
            "flip": false,
            "cardId": "Archetype-49"
          },
          {
            "type": "predefined",
            "category": "Archetype",
            "label": [],
            "color": "#59fff9",
            "content": "Shaman",
            "flip": false,
            "cardId": "Archetype-50"
          },
          {
            "type": "predefined",
            "category": "Archetype",
            "label": [],
            "color": "#59fff9",
            "content": "Sidekick",
            "flip": false,
            "cardId": "Archetype-51"
          },
          {
            "type": "predefined",
            "category": "Archetype",
            "label": [],
            "color": "#59fff9",
            "content": "Sleuth",
            "flip": false,
            "cardId": "Archetype-52"
          },
          {
            "type": "predefined",
            "category": "Archetype",
            "label": [],
            "color": "#59fff9",
            "content": "Soldier",
            "flip": false,
            "cardId": "Archetype-53"
          },
          {
            "type": "predefined",
            "category": "Archetype",
            "label": [],
            "color": "#59fff9",
            "content": "Tom Boy",
            "flip": false,
            "cardId": "Archetype-54"
          },
          {
            "type": "predefined",
            "category": "Archetype",
            "label": [],
            "color": "#59fff9",
            "content": "Tortured Artist",
            "flip": false,
            "cardId": "Archetype-55"
          },
          {
            "type": "predefined",
            "category": "Archetype",
            "label": [],
            "color": "#59fff9",
            "content": "Tragic Hero",
            "flip": false,
            "cardId": "Archetype-56"
          },
          {
            "type": "predefined",
            "category": "Archetype",
            "label": [],
            "color": "#59fff9",
            "content": "Trickster",
            "flip": false,
            "cardId": "Archetype-57"
          },
          {
            "type": "predefined",
            "category": "Archetype",
            "label": [],
            "color": "#59fff9",
            "content": "Troubled Teen",
            "flip": false,
            "cardId": "Archetype-58"
          },
          {
            "type": "predefined",
            "category": "Archetype",
            "label": [],
            "color": "#59fff9",
            "content": "Turncoat",
            "flip": false,
            "cardId": "Archetype-59"
          },
          {
            "type": "predefined",
            "category": "Archetype",
            "label": [],
            "color": "#59fff9",
            "content": "Underdog",
            "flip": false,
            "cardId": "Archetype-60"
          },
          {
            "type": "predefined",
            "category": "Archetype",
            "label": [],
            "color": "#59fff9",
            "content": "Useful Idiot",
            "flip": false,
            "cardId": "Archetype-61"
          },
          {
            "type": "predefined",
            "category": "Archetype",
            "label": [],
            "color": "#59fff9",
            "content": "Village Idiot",
            "flip": false,
            "cardId": "Archetype-62"
          },
          {
            "type": "predefined",
            "category": "Archetype",
            "label": [],
            "color": "#59fff9",
            "content": "Villain",
            "flip": false,
            "cardId": "Archetype-63"
          },
          {
            "type": "predefined",
            "category": "Archetype",
            "label": [],
            "color": "#59fff9",
            "content": "Whiskey Priest",
            "flip": false,
            "cardId": "Archetype-64"
          },
          {
            "type": "predefined",
            "category": "Archetype",
            "label": [],
            "color": "#59fff9",
            "content": "White Knight",
            "flip": false,
            "cardId": "Archetype-65"
          },
          {
            "type": "predefined",
            "category": "Archetype",
            "label": [],
            "color": "#59fff9",
            "content": "Whizz Kid",
            "flip": false,
            "cardId": "Archetype-66"
          },
          {
            "type": "predefined",
            "category": "Archetype",
            "label": [],
            "color": "#59fff9",
            "content": "Wise Elder",
            "flip": false,
            "cardId": "Archetype-67"
          },
          {
            "type": "predefined",
            "category": "Archetype",
            "label": [],
            "color": "#59fff9",
            "content": "Guy/Girl Next Door",
            "flip": false,
            "cardId": "Archetype-68"
          },
          {
            "type": "predefined",
            "category": "Archetype",
            "label": [],
            "color": "#59fff9",
            "content": "Wise Fool",
            "flip": false,
            "cardId": "Archetype-69"
          }
        ],
        "Conflict": [
          {
            "type": "predefined",
            "category": "Conflict",
            "label": [
              "Marker"
            ],
            "color": "#598eff",
            "content": "Conflict",
            "flip": false,
            "cardId": "Conflict-0"
          },
          {
            "type": "predefined",
            "category": "Conflict",
            "label": [],
            "color": "#598eff",
            "content": "Arduous Journey",
            "flip": false,
            "cardId": "Conflict-1"
          },
          {
            "type": "predefined",
            "category": "Conflict",
            "label": [],
            "color": "#598eff",
            "content": "Attempted Murder",
            "flip": false,
            "cardId": "Conflict-2"
          },
          {
            "type": "predefined",
            "category": "Conflict",
            "label": [],
            "color": "#598eff",
            "content": "Blackmail",
            "flip": false,
            "cardId": "Conflict-3"
          },
          {
            "type": "predefined",
            "category": "Conflict",
            "label": [],
            "color": "#598eff",
            "content": "Body Swap",
            "flip": false,
            "cardId": "Conflict-4"
          },
          {
            "type": "predefined",
            "category": "Conflict",
            "label": [],
            "color": "#598eff",
            "content": "Bridge Burning",
            "flip": false,
            "cardId": "Conflict-5"
          },
          {
            "type": "predefined",
            "category": "Conflict",
            "label": [],
            "color": "#598eff",
            "content": "Caught in the act",
            "flip": false,
            "cardId": "Conflict-6"
          },
          {
            "type": "predefined",
            "category": "Conflict",
            "label": [],
            "color": "#598eff",
            "content": "Cheating",
            "flip": false,
            "cardId": "Conflict-7"
          },
          {
            "type": "predefined",
            "category": "Conflict",
            "label": [],
            "color": "#598eff",
            "content": "Class Divide",
            "flip": false,
            "cardId": "Conflict-8"
          },
          {
            "type": "predefined",
            "category": "Conflict",
            "label": [],
            "color": "#598eff",
            "content": "Con Job",
            "flip": false,
            "cardId": "Conflict-9"
          },
          {
            "type": "predefined",
            "category": "Conflict",
            "label": [],
            "color": "#598eff",
            "content": "Coverup",
            "flip": false,
            "cardId": "Conflict-10"
          },
          {
            "type": "predefined",
            "category": "Conflict",
            "label": [],
            "color": "#598eff",
            "content": "Dangerous Discovery",
            "flip": false,
            "cardId": "Conflict-11"
          },
          {
            "type": "predefined",
            "category": "Conflict",
            "label": [],
            "color": "#598eff",
            "content": "Disturbing Prophecy",
            "flip": false,
            "cardId": "Conflict-12"
          },
          {
            "type": "predefined",
            "category": "Conflict",
            "label": [],
            "color": "#598eff",
            "content": "Exposed",
            "flip": false,
            "cardId": "Conflict-13"
          },
          {
            "type": "predefined",
            "category": "Conflict",
            "label": [],
            "color": "#598eff",
            "content": "Failing Business",
            "flip": false,
            "cardId": "Conflict-14"
          },
          {
            "type": "predefined",
            "category": "Conflict",
            "label": [],
            "color": "#598eff",
            "content": "Failure to Launch",
            "flip": false,
            "cardId": "Conflict-15"
          },
          {
            "type": "predefined",
            "category": "Conflict",
            "label": [],
            "color": "#598eff",
            "content": "Faustian Bargain",
            "flip": false,
            "cardId": "Conflict-16"
          },
          {
            "type": "predefined",
            "category": "Conflict",
            "label": [],
            "color": "#598eff",
            "content": "Future of Mankind",
            "flip": false,
            "cardId": "Conflict-17"
          },
          {
            "type": "predefined",
            "category": "Conflict",
            "label": [],
            "color": "#598eff",
            "content": "Grand Inheritance",
            "flip": false,
            "cardId": "Conflict-18"
          },
          {
            "type": "predefined",
            "category": "Conflict",
            "label": [],
            "color": "#598eff",
            "content": "Grand Theft",
            "flip": false,
            "cardId": "Conflict-19"
          },
          {
            "type": "predefined",
            "category": "Conflict",
            "label": [],
            "color": "#598eff",
            "content": "Illness",
            "flip": false,
            "cardId": "Conflict-20"
          },
          {
            "type": "predefined",
            "category": "Conflict",
            "label": [],
            "color": "#598eff",
            "content": "Impending Disaster",
            "flip": false,
            "cardId": "Conflict-21"
          },
          {
            "type": "predefined",
            "category": "Conflict",
            "label": [],
            "color": "#598eff",
            "content": "Inexplicable Phenomena",
            "flip": false,
            "cardId": "Conflict-22"
          },
          {
            "type": "predefined",
            "category": "Conflict",
            "label": [],
            "color": "#598eff",
            "content": "Last Day before",
            "flip": false,
            "cardId": "Conflict-23"
          },
          {
            "type": "predefined",
            "category": "Conflict",
            "label": [],
            "color": "#598eff",
            "content": "Lost in translation",
            "flip": false,
            "cardId": "Conflict-24"
          },
          {
            "type": "predefined",
            "category": "Conflict",
            "label": [],
            "color": "#598eff",
            "content": "Magical Creature",
            "flip": false,
            "cardId": "Conflict-25"
          },
          {
            "type": "predefined",
            "category": "Conflict",
            "label": [],
            "color": "#598eff",
            "content": "Missing Person",
            "flip": false,
            "cardId": "Conflict-26"
          },
          {
            "type": "predefined",
            "category": "Conflict",
            "label": [],
            "color": "#598eff",
            "content": "Mistaken Identity",
            "flip": false,
            "cardId": "Conflict-27"
          },
          {
            "type": "predefined",
            "category": "Conflict",
            "label": [],
            "color": "#598eff",
            "content": "Only Survivor",
            "flip": false,
            "cardId": "Conflict-28"
          },
          {
            "type": "predefined",
            "category": "Conflict",
            "label": [],
            "color": "#598eff",
            "content": "Overhears Something",
            "flip": false,
            "cardId": "Conflict-29"
          },
          {
            "type": "predefined",
            "category": "Conflict",
            "label": [],
            "color": "#598eff",
            "content": "Power Grab",
            "flip": false,
            "cardId": "Conflict-30"
          },
          {
            "type": "predefined",
            "category": "Conflict",
            "label": [],
            "color": "#598eff",
            "content": "Redemption",
            "flip": false,
            "cardId": "Conflict-31"
          },
          {
            "type": "predefined",
            "category": "Conflict",
            "label": [],
            "color": "#598eff",
            "content": "Revenge",
            "flip": false,
            "cardId": "Conflict-32"
          },
          {
            "type": "predefined",
            "category": "Conflict",
            "label": [],
            "color": "#598eff",
            "content": "Rising/Falling Star",
            "flip": false,
            "cardId": "Conflict-33"
          },
          {
            "type": "predefined",
            "category": "Conflict",
            "label": [],
            "color": "#598eff",
            "content": "Role Swap",
            "flip": false,
            "cardId": "Conflict-34"
          },
          {
            "type": "predefined",
            "category": "Conflict",
            "label": [],
            "color": "#598eff",
            "content": "Search for Self",
            "flip": false,
            "cardId": "Conflict-35"
          },
          {
            "type": "predefined",
            "category": "Conflict",
            "label": [],
            "color": "#598eff",
            "content": "Secret Revealed",
            "flip": false,
            "cardId": "Conflict-36"
          },
          {
            "type": "predefined",
            "category": "Conflict",
            "label": [],
            "color": "#598eff",
            "content": "Seeks revenge",
            "flip": false,
            "cardId": "Conflict-37"
          },
          {
            "type": "predefined",
            "category": "Conflict",
            "label": [],
            "color": "#598eff",
            "content": "Sensory Loss",
            "flip": false,
            "cardId": "Conflict-38"
          },
          {
            "type": "predefined",
            "category": "Conflict",
            "label": [],
            "color": "#598eff",
            "content": "Shocking Discovery",
            "flip": false,
            "cardId": "Conflict-39"
          },
          {
            "type": "predefined",
            "category": "Conflict",
            "label": [],
            "color": "#598eff",
            "content": "Skeletons in Closet",
            "flip": false,
            "cardId": "Conflict-40"
          },
          {
            "type": "predefined",
            "category": "Conflict",
            "label": [],
            "color": "#598eff",
            "content": "Spiritual Journey ends badly",
            "flip": false,
            "cardId": "Conflict-41"
          },
          {
            "type": "predefined",
            "category": "Conflict",
            "label": [],
            "color": "#598eff",
            "content": "Stalker",
            "flip": false,
            "cardId": "Conflict-42"
          },
          {
            "type": "predefined",
            "category": "Conflict",
            "label": [],
            "color": "#598eff",
            "content": "Strange Phenomena",
            "flip": false,
            "cardId": "Conflict-43"
          },
          {
            "type": "predefined",
            "category": "Conflict",
            "label": [],
            "color": "#598eff",
            "content": "Super Spreader Event",
            "flip": false,
            "cardId": "Conflict-44"
          },
          {
            "type": "predefined",
            "category": "Conflict",
            "label": [],
            "color": "#598eff",
            "content": "Super Strain",
            "flip": false,
            "cardId": "Conflict-45"
          },
          {
            "type": "predefined",
            "category": "Conflict",
            "label": [],
            "color": "#598eff",
            "content": "Survival",
            "flip": false,
            "cardId": "Conflict-46"
          },
          {
            "type": "predefined",
            "category": "Conflict",
            "label": [],
            "color": "#598eff",
            "content": "Teachable Moment",
            "flip": false,
            "cardId": "Conflict-47"
          },
          {
            "type": "predefined",
            "category": "Conflict",
            "label": [],
            "color": "#598eff",
            "content": "Terrible Breakup",
            "flip": false,
            "cardId": "Conflict-48"
          },
          {
            "type": "predefined",
            "category": "Conflict",
            "label": [],
            "color": "#598eff",
            "content": "The Apocalypse",
            "flip": false,
            "cardId": "Conflict-49"
          },
          {
            "type": "predefined",
            "category": "Conflict",
            "label": [],
            "color": "#598eff",
            "content": "Time Warp",
            "flip": false,
            "cardId": "Conflict-50"
          },
          {
            "type": "predefined",
            "category": "Conflict",
            "label": [],
            "color": "#598eff",
            "content": "Truthseeking",
            "flip": false,
            "cardId": "Conflict-51"
          },
          {
            "type": "predefined",
            "category": "Conflict",
            "label": [],
            "color": "#598eff",
            "content": "Worst thing ever",
            "flip": false,
            "cardId": "Conflict-52"
          },
          {
            "type": "predefined",
            "category": "Conflict",
            "label": [],
            "color": "#598eff",
            "content": "Wrongly Accused",
            "flip": false,
            "cardId": "Conflict-53"
          },
          {
            "type": "predefined",
            "category": "Conflict",
            "label": [],
            "color": "#598eff",
            "content": "Bad Hair Day",
            "flip": false,
            "cardId": "Conflict-54"
          }
        ],
        "Crisis": [
          {
            "type": "predefined",
            "category": "Crisis",
            "label": [
              "Marker"
            ],
            "color": "#ff33a3",
            "content": "Crisis",
            "flip": false,
            "cardId": "Crisis-0"
          },
          {
            "type": "predefined",
            "category": "Crisis",
            "label": [],
            "color": "#ff33a3",
            "content": "Abduction",
            "flip": false,
            "cardId": "Crisis-1"
          },
          {
            "type": "predefined",
            "category": "Crisis",
            "label": [],
            "color": "#ff33a3",
            "content": "Everything a Lie",
            "flip": false,
            "cardId": "Crisis-2"
          },
          {
            "type": "predefined",
            "category": "Crisis",
            "label": [],
            "color": "#ff33a3",
            "content": "Apocalypse/Armageddon",
            "flip": false,
            "cardId": "Crisis-3"
          },
          {
            "type": "predefined",
            "category": "Crisis",
            "label": [],
            "color": "#ff33a3",
            "content": "Changes Course of History",
            "flip": false,
            "cardId": "Crisis-4"
          },
          {
            "type": "predefined",
            "category": "Crisis",
            "label": [],
            "color": "#ff33a3",
            "content": "Death Cult",
            "flip": false,
            "cardId": "Crisis-5"
          },
          {
            "type": "predefined",
            "category": "Crisis",
            "label": [],
            "color": "#ff33a3",
            "content": "Dumpster Fire",
            "flip": false,
            "cardId": "Crisis-6"
          },
          {
            "type": "predefined",
            "category": "Crisis",
            "label": [],
            "color": "#ff33a3",
            "content": "Hair on Fire",
            "flip": false,
            "cardId": "Crisis-7"
          },
          {
            "type": "predefined",
            "category": "Crisis",
            "label": [],
            "color": "#ff33a3",
            "content": "Humiliation",
            "flip": false,
            "cardId": "Crisis-8"
          },
          {
            "type": "predefined",
            "category": "Crisis",
            "label": [],
            "color": "#ff33a3",
            "content": "Murder",
            "flip": false,
            "cardId": "Crisis-9"
          },
          {
            "type": "predefined",
            "category": "Crisis",
            "label": [],
            "color": "#ff33a3",
            "content": "National Emergency",
            "flip": false,
            "cardId": "Crisis-10"
          },
          {
            "type": "predefined",
            "category": "Crisis",
            "label": [],
            "color": "#ff33a3",
            "content": "Natural Disaster",
            "flip": false,
            "cardId": "Crisis-11"
          },
          {
            "type": "predefined",
            "category": "Crisis",
            "label": [],
            "color": "#ff33a3",
            "content": "No Return",
            "flip": false,
            "cardId": "Crisis-12"
          },
          {
            "type": "predefined",
            "category": "Crisis",
            "label": [],
            "color": "#ff33a3",
            "content": "No Way Out",
            "flip": false,
            "cardId": "Crisis-13"
          },
          {
            "type": "predefined",
            "category": "Crisis",
            "label": [],
            "color": "#ff33a3",
            "content": "Sole Survivor",
            "flip": false,
            "cardId": "Crisis-14"
          },
          {
            "type": "predefined",
            "category": "Crisis",
            "label": [],
            "color": "#ff33a3",
            "content": "Spiritual Journey Ends Badly",
            "flip": false,
            "cardId": "Crisis-15"
          },
          {
            "type": "predefined",
            "category": "Crisis",
            "label": [],
            "color": "#ff33a3",
            "content": "Worst Thing Ever",
            "flip": false,
            "cardId": "Crisis-16"
          },
          {
            "type": "predefined",
            "category": "Crisis",
            "label": [],
            "color": "#ff33a3",
            "content": "Exile",
            "flip": false,
            "cardId": "Crisis-17"
          },
          {
            "type": "predefined",
            "category": "Crisis",
            "label": [],
            "color": "#ff33a3",
            "content": "Intervention",
            "flip": false,
            "cardId": "Crisis-18"
          }
        ],
        "Issues": [
          {
            "type": "predefined",
            "category": "Issues",
            "label": [
              "Marker"
            ],
            "color": "#14ffbd",
            "content": "Issues",
            "flip": false,
            "cardId": "Issues-0"
          },
          {
            "type": "predefined",
            "category": "Issues",
            "label": [],
            "color": "#14ffbd",
            "content": "What if A.I. compounds our prejudices?",
            "flip": false,
            "cardId": "Issues-1"
          },
          {
            "type": "predefined",
            "category": "Issues",
            "label": [],
            "color": "#14ffbd",
            "content": "What if babies are lifestyle accessories?",
            "flip": false,
            "cardId": "Issues-2"
          },
          {
            "type": "predefined",
            "category": "Issues",
            "label": [],
            "color": "#14ffbd",
            "content": "What if common sense is the enemy of creativity?",
            "flip": false,
            "cardId": "Issues-3"
          },
          {
            "type": "predefined",
            "category": "Issues",
            "label": [],
            "color": "#14ffbd",
            "content": "What if community makes us stronger individuals?",
            "flip": false,
            "cardId": "Issues-4"
          },
          {
            "type": "predefined",
            "category": "Issues",
            "label": [],
            "color": "#14ffbd",
            "content": "What if conflict is good for you?",
            "flip": false,
            "cardId": "Issues-5"
          },
          {
            "type": "predefined",
            "category": "Issues",
            "label": [],
            "color": "#14ffbd",
            "content": "What if culture is detrimental to humanity?",
            "flip": false,
            "cardId": "Issues-6"
          },
          {
            "type": "predefined",
            "category": "Issues",
            "label": [],
            "color": "#14ffbd",
            "content": "What if Facebook is used for human breeding?",
            "flip": false,
            "cardId": "Issues-7"
          },
          {
            "type": "predefined",
            "category": "Issues",
            "label": [],
            "color": "#14ffbd",
            "content": "What if friends are more dangerous than strangers?",
            "flip": false,
            "cardId": "Issues-8"
          },
          {
            "type": "predefined",
            "category": "Issues",
            "label": [],
            "color": "#14ffbd",
            "content": "What if getting what you want isn't what you need?",
            "flip": false,
            "cardId": "Issues-9"
          },
          {
            "type": "predefined",
            "category": "Issues",
            "label": [],
            "color": "#14ffbd",
            "content": "What if honesty isn't always the best policy?",
            "flip": false,
            "cardId": "Issues-10"
          },
          {
            "type": "predefined",
            "category": "Issues",
            "label": [],
            "color": "#14ffbd",
            "content": "What if human misery is a business model?",
            "flip": false,
            "cardId": "Issues-11"
          },
          {
            "type": "predefined",
            "category": "Issues",
            "label": [],
            "color": "#14ffbd",
            "content": "What if it's not in our best interest to get what we want?",
            "flip": false,
            "cardId": "Issues-12"
          },
          {
            "type": "predefined",
            "category": "Issues",
            "label": [],
            "color": "#14ffbd",
            "content": "What if lies were without consequence?",
            "flip": false,
            "cardId": "Issues-13"
          },
          {
            "type": "predefined",
            "category": "Issues",
            "label": [],
            "color": "#14ffbd",
            "content": "What if life is without meaning?",
            "flip": false,
            "cardId": "Issues-14"
          },
          {
            "type": "predefined",
            "category": "Issues",
            "label": [],
            "color": "#14ffbd",
            "content": "What if life streaming fuels narcissism?",
            "flip": false,
            "cardId": "Issues-15"
          },
          {
            "type": "predefined",
            "category": "Issues",
            "label": [],
            "color": "#14ffbd",
            "content": "What if love is a virus?",
            "flip": false,
            "cardId": "Issues-16"
          },
          {
            "type": "predefined",
            "category": "Issues",
            "label": [],
            "color": "#14ffbd",
            "content": "What if manking is a problem?",
            "flip": false,
            "cardId": "Issues-17"
          },
          {
            "type": "predefined",
            "category": "Issues",
            "label": [],
            "color": "#14ffbd",
            "content": "What if marriage is the best way to ruin a good friendship?",
            "flip": false,
            "cardId": "Issues-18"
          },
          {
            "type": "predefined",
            "category": "Issues",
            "label": [],
            "color": "#14ffbd",
            "content": "What if obesity is self-protection?",
            "flip": false,
            "cardId": "Issues-19"
          },
          {
            "type": "predefined",
            "category": "Issues",
            "label": [],
            "color": "#14ffbd",
            "content": "What if opposites attract but obliterate?",
            "flip": false,
            "cardId": "Issues-20"
          },
          {
            "type": "predefined",
            "category": "Issues",
            "label": [],
            "color": "#14ffbd",
            "content": "What if our brains can't comprehend our own creations?",
            "flip": false,
            "cardId": "Issues-21"
          },
          {
            "type": "predefined",
            "category": "Issues",
            "label": [],
            "color": "#14ffbd",
            "content": "What if overindulgence is self-defense?",
            "flip": false,
            "cardId": "Issues-22"
          },
          {
            "type": "predefined",
            "category": "Issues",
            "label": [],
            "color": "#14ffbd",
            "content": "What if patience isn't a virtue?",
            "flip": false,
            "cardId": "Issues-23"
          },
          {
            "type": "predefined",
            "category": "Issues",
            "label": [],
            "color": "#14ffbd",
            "content": "What if polygamy solves income inequality?",
            "flip": false,
            "cardId": "Issues-24"
          },
          {
            "type": "predefined",
            "category": "Issues",
            "label": [],
            "color": "#14ffbd",
            "content": "What if prejudices are positive?",
            "flip": false,
            "cardId": "Issues-25"
          },
          {
            "type": "predefined",
            "category": "Issues",
            "label": [],
            "color": "#14ffbd",
            "content": "What if principles don't hold up when there's a gun to your head?",
            "flip": false,
            "cardId": "Issues-26"
          },
          {
            "type": "predefined",
            "category": "Issues",
            "label": [],
            "color": "#14ffbd",
            "content": "What if prisons are slave plantations?",
            "flip": false,
            "cardId": "Issues-27"
          },
          {
            "type": "predefined",
            "category": "Issues",
            "label": [],
            "color": "#14ffbd",
            "content": "What if security is more important than freedom?",
            "flip": false,
            "cardId": "Issues-28"
          },
          {
            "type": "predefined",
            "category": "Issues",
            "label": [],
            "color": "#14ffbd",
            "content": "What if social media creates social problems?",
            "flip": false,
            "cardId": "Issues-29"
          },
          {
            "type": "predefined",
            "category": "Issues",
            "label": [],
            "color": "#14ffbd",
            "content": "What if social media endangers democracy?",
            "flip": false,
            "cardId": "Issues-30"
          },
          {
            "type": "predefined",
            "category": "Issues",
            "label": [],
            "color": "#14ffbd",
            "content": "What if social media is a form of social engineering?",
            "flip": false,
            "cardId": "Issues-31"
          },
          {
            "type": "predefined",
            "category": "Issues",
            "label": [],
            "color": "#14ffbd",
            "content": "What if social media platforms keep your secrets?",
            "flip": false,
            "cardId": "Issues-32"
          },
          {
            "type": "predefined",
            "category": "Issues",
            "label": [],
            "color": "#14ffbd",
            "content": "What if society is selling us mass extinction?",
            "flip": false,
            "cardId": "Issues-33"
          },
          {
            "type": "predefined",
            "category": "Issues",
            "label": [],
            "color": "#14ffbd",
            "content": "What if society protects us from ourselves?",
            "flip": false,
            "cardId": "Issues-34"
          },
          {
            "type": "predefined",
            "category": "Issues",
            "label": [],
            "color": "#14ffbd",
            "content": "What if solitude is the cure for loneliness?",
            "flip": false,
            "cardId": "Issues-35"
          },
          {
            "type": "predefined",
            "category": "Issues",
            "label": [],
            "color": "#14ffbd",
            "content": "What if technology makes us stupid?",
            "flip": false,
            "cardId": "Issues-36"
          },
          {
            "type": "predefined",
            "category": "Issues",
            "label": [],
            "color": "#14ffbd",
            "content": "What if the cure is worse than the problem?",
            "flip": false,
            "cardId": "Issues-37"
          },
          {
            "type": "predefined",
            "category": "Issues",
            "label": [],
            "color": "#14ffbd",
            "content": "What if the gene pool becomes the purview of the State?",
            "flip": false,
            "cardId": "Issues-38"
          },
          {
            "type": "predefined",
            "category": "Issues",
            "label": [],
            "color": "#14ffbd",
            "content": "What if the Internet kills nostalgia?",
            "flip": false,
            "cardId": "Issues-39"
          },
          {
            "type": "predefined",
            "category": "Issues",
            "label": [],
            "color": "#14ffbd",
            "content": "What if the secret to happiness is low expectations?",
            "flip": false,
            "cardId": "Issues-40"
          },
          {
            "type": "predefined",
            "category": "Issues",
            "label": [],
            "color": "#14ffbd",
            "content": "What if the truth doesn't set you free?",
            "flip": false,
            "cardId": "Issues-41"
          },
          {
            "type": "predefined",
            "category": "Issues",
            "label": [],
            "color": "#14ffbd",
            "content": "What if the virtual is more authentic than reality?",
            "flip": false,
            "cardId": "Issues-42"
          },
          {
            "type": "predefined",
            "category": "Issues",
            "label": [],
            "color": "#14ffbd",
            "content": "What if the virus is good for you?",
            "flip": false,
            "cardId": "Issues-43"
          },
          {
            "type": "predefined",
            "category": "Issues",
            "label": [],
            "color": "#14ffbd",
            "content": "What if there's too much freedom of choice?",
            "flip": false,
            "cardId": "Issues-44"
          },
          {
            "type": "predefined",
            "category": "Issues",
            "label": [],
            "color": "#14ffbd",
            "content": "What if unconditional love is a bad idea?",
            "flip": false,
            "cardId": "Issues-45"
          },
          {
            "type": "predefined",
            "category": "Issues",
            "label": [],
            "color": "#14ffbd",
            "content": "What if we are more a product of our ancestors than our own experience?",
            "flip": false,
            "cardId": "Issues-46"
          },
          {
            "type": "predefined",
            "category": "Issues",
            "label": [],
            "color": "#14ffbd",
            "content": "What if we can't ever be satisfied?",
            "flip": false,
            "cardId": "Issues-47"
          },
          {
            "type": "predefined",
            "category": "Issues",
            "label": [],
            "color": "#14ffbd",
            "content": "What if we lose our individuality the more we network?",
            "flip": false,
            "cardId": "Issues-48"
          },
          {
            "type": "predefined",
            "category": "Issues",
            "label": [],
            "color": "#14ffbd",
            "content": "What if we no longer need to give birth to procreate?",
            "flip": false,
            "cardId": "Issues-49"
          },
          {
            "type": "predefined",
            "category": "Issues",
            "label": [],
            "color": "#14ffbd",
            "content": "What if we're better off marrying ourselves?",
            "flip": false,
            "cardId": "Issues-50"
          },
          {
            "type": "predefined",
            "category": "Issues",
            "label": [],
            "color": "#14ffbd",
            "content": "What if we're secretly happy when our friends don't succeed?",
            "flip": false,
            "cardId": "Issues-51"
          },
          {
            "type": "predefined",
            "category": "Issues",
            "label": [],
            "color": "#14ffbd",
            "content": "What if you could kill someone with kindness?",
            "flip": false,
            "cardId": "Issues-52"
          },
          {
            "type": "predefined",
            "category": "Issues",
            "label": [],
            "color": "#14ffbd",
            "content": "What if you could marry someone exactly like yourself?",
            "flip": false,
            "cardId": "Issues-53"
          },
          {
            "type": "predefined",
            "category": "Issues",
            "label": [],
            "color": "#14ffbd",
            "content": "What if you could persuade anyone of anything?",
            "flip": false,
            "cardId": "Issues-54"
          },
          {
            "type": "predefined",
            "category": "Issues",
            "label": [],
            "color": "#14ffbd",
            "content": "What if you feed off of your friend's misfortune?",
            "flip": false,
            "cardId": "Issues-55"
          },
          {
            "type": "predefined",
            "category": "Issues",
            "label": [],
            "color": "#14ffbd",
            "content": "What if your child is born evil?",
            "flip": false,
            "cardId": "Issues-56"
          },
          {
            "type": "predefined",
            "category": "Issues",
            "label": [],
            "color": "#14ffbd",
            "content": "What if your memory is better than the real thing?",
            "flip": false,
            "cardId": "Issues-57"
          },
          {
            "type": "predefined",
            "category": "Issues",
            "label": [],
            "color": "#14ffbd",
            "content": "What if your life became your job?",
            "flip": false,
            "cardId": "Issues-58"
          }
        ],
        "Resolution": [
          {
            "type": "predefined",
            "category": "Resolution",
            "label": [
              "Marker"
            ],
            "color": "#99ff14",
            "content": "Resolution",
            "flip": false,
            "cardId": "Resolution-0"
          },
          {
            "type": "predefined",
            "category": "Resolution",
            "label": [],
            "color": "#99ff14",
            "content": "As we rely increasingly upon technology, we become less resilient in ourselves",
            "flip": false,
            "cardId": "Resolution-1"
          },
          {
            "type": "predefined",
            "category": "Resolution",
            "label": [],
            "color": "#99ff14",
            "content": "Child labor keeps kids off the streets and teaches them valuable skills",
            "flip": false,
            "cardId": "Resolution-2"
          },
          {
            "type": "predefined",
            "category": "Resolution",
            "label": [],
            "color": "#99ff14",
            "content": "Citizenship should be earned not granted as a birth-right",
            "flip": false,
            "cardId": "Resolution-3"
          },
          {
            "type": "predefined",
            "category": "Resolution",
            "label": [],
            "color": "#99ff14",
            "content": "Community makes us stronger",
            "flip": false,
            "cardId": "Resolution-4"
          },
          {
            "type": "predefined",
            "category": "Resolution",
            "label": [],
            "color": "#99ff14",
            "content": "Humanity preserves itself through mutually assured destruction",
            "flip": false,
            "cardId": "Resolution-5"
          },
          {
            "type": "predefined",
            "category": "Resolution",
            "label": [],
            "color": "#99ff14",
            "content": "Pre-emptive strikes improve security",
            "flip": false,
            "cardId": "Resolution-6"
          },
          {
            "type": "predefined",
            "category": "Resolution",
            "label": [],
            "color": "#99ff14",
            "content": "Survival of the fittest doesn't lead to survival of the strongest",
            "flip": false,
            "cardId": "Resolution-7"
          },
          {
            "type": "predefined",
            "category": "Resolution",
            "label": [],
            "color": "#99ff14",
            "content": "Survival of the fittest is not a zero-sum game",
            "flip": false,
            "cardId": "Resolution-8"
          },
          {
            "type": "predefined",
            "category": "Resolution",
            "label": [],
            "color": "#99ff14",
            "content": "The more connected we are, the lonelier we become",
            "flip": false,
            "cardId": "Resolution-9"
          },
          {
            "type": "predefined",
            "category": "Resolution",
            "label": [],
            "color": "#99ff14",
            "content": "The preservation of community demands sacrifice of the individual",
            "flip": false,
            "cardId": "Resolution-10"
          },
          {
            "type": "predefined",
            "category": "Resolution",
            "label": [],
            "color": "#99ff14",
            "content": "Torture is justified when it can save lives",
            "flip": false,
            "cardId": "Resolution-11"
          },
          {
            "type": "predefined",
            "category": "Resolution",
            "label": [],
            "color": "#99ff14",
            "content": "When we outsource thinking to machine, technology makes us stupid",
            "flip": false,
            "cardId": "Resolution-12"
          },
          {
            "type": "predefined",
            "category": "Resolution",
            "label": [],
            "color": "#99ff14",
            "content": "You should do a Spring Cleaning of your friends",
            "flip": false,
            "cardId": "Resolution-13"
          },
          {
            "type": "predefined",
            "category": "Resolution",
            "label": [],
            "color": "#99ff14",
            "content": "You should never marry yourself",
            "flip": false,
            "cardId": "Resolution-14"
          }
        ],
        "Dramatic Question": [
          {
            "type": "predefined",
            "category": "Dramatic Question",
            "label": [
              "Marker"
            ],
            "color": "#00966c",
            "content": "Dramatic Question",
            "flip": false,
            "cardId": "Dramatic Question-0"
          },
          {
            "type": "predefined",
            "category": "Dramatic Question",
            "label": [],
            "color": "#00966c",
            "content": "What if your life became your job?",
            "flip": false,
            "cardId": "Dramatic Question-1"
          },
          {
            "type": "predefined",
            "category": "Dramatic Question",
            "label": [],
            "color": "#00966c",
            "content": "What if you could use a service to get away with anything?",
            "flip": false,
            "cardId": "Dramatic Question-2"
          },
          {
            "type": "predefined",
            "category": "Dramatic Question",
            "label": [],
            "color": "#00966c",
            "content": "What if you witnessed an immaculate conception?",
            "flip": false,
            "cardId": "Dramatic Question-3"
          },
          {
            "type": "predefined",
            "category": "Dramatic Question",
            "label": [],
            "color": "#00966c",
            "content": "What if a virus was used to enhance the human race?",
            "flip": false,
            "cardId": "Dramatic Question-4"
          },
          {
            "type": "predefined",
            "category": "Dramatic Question",
            "label": [],
            "color": "#00966c",
            "content": "What if a pandemic was really an alien invasion?",
            "flip": false,
            "cardId": "Dramatic Question-5"
          },
          {
            "type": "predefined",
            "category": "Dramatic Question",
            "label": [],
            "color": "#00966c",
            "content": "What if you killed everyone you came in contact with?",
            "flip": false,
            "cardId": "Dramatic Question-6"
          }
        ],
        "Loglines": [
          {
            "type": "predefined",
            "category": "Loglines",
            "label": [
              "Marker"
            ],
            "color": "#fcc060",
            "content": "Loglines",
            "flip": false,
            "cardId": "Loglines-0"
          }
        ],
        "Taglines": [
          {
            "type": "predefined",
            "category": "Taglines",
            "label": [
              "Marker"
            ],
            "color": "#fa9600",
            "content": "Taglines",
            "flip": false,
            "cardId": "Taglines-0"
          }
        ],
        "Story Messages": [
          {
            "type": "predefined",
            "category": "Story Messages",
            "label": [
              "Marker"
            ],
            "color": "#7536ff",
            "content": "Story Messages",
            "flip": false,
            "cardId": "Story Messages-0"
          },
          {
            "type": "predefined",
            "category": "Story Messages",
            "label": [],
            "color": "#7536ff",
            "content": "The more inteconnected we are, the lonelier we become.",
            "flip": false,
            "cardId": "Story Messages-1"
          },
          {
            "type": "predefined",
            "category": "Story Messages",
            "label": [],
            "color": "#7536ff",
            "content": "Passion confuses intensity with intimacy; it enslaves its object of desire instead of respecting it.",
            "flip": false,
            "cardId": "Story Messages-2"
          },
          {
            "type": "predefined",
            "category": "Story Messages",
            "label": [],
            "color": "#7536ff",
            "content": "Life isn't worth living unless it's shared.",
            "flip": false,
            "cardId": "Story Messages-3"
          },
          {
            "type": "predefined",
            "category": "Story Messages",
            "label": [],
            "color": "#7536ff",
            "content": "When you leave morality up to society, you become amoral.",
            "flip": false,
            "cardId": "Story Messages-4"
          },
          {
            "type": "predefined",
            "category": "Story Messages",
            "label": [],
            "color": "#7536ff",
            "content": "The more humanity advances, the more it devolves.",
            "flip": false,
            "cardId": "Story Messages-5"
          },
          {
            "type": "predefined",
            "category": "Story Messages",
            "label": [],
            "color": "#7536ff",
            "content": "Love is giving someone permission to drive you crazy.",
            "flip": false,
            "cardId": "Story Messages-6"
          },
          {
            "type": "predefined",
            "category": "Story Messages",
            "label": [],
            "color": "#7536ff",
            "content": "You can't control love - only mitigate the disaster.",
            "flip": false,
            "cardId": "Story Messages-7"
          },
          {
            "type": "predefined",
            "category": "Story Messages",
            "label": [],
            "color": "#7536ff",
            "content": "Responsibility is not the price of freedom but the benefit.",
            "flip": false,
            "cardId": "Story Messages-8"
          },
          {
            "type": "predefined",
            "category": "Story Messages",
            "label": [],
            "color": "#7536ff",
            "content": "Failure to spend quality time with yourself means having little to offer anyone else.",
            "flip": false,
            "cardId": "Story Messages-9"
          },
          {
            "type": "predefined",
            "category": "Story Messages",
            "label": [],
            "color": "#7536ff",
            "content": "Technology degrades Humanity",
            "flip": false,
            "cardId": "Story Messages-10"
          }
        ],
        "categoryColorMap": {
          "Story": "#FF8BA7",
          "Money": "#2CB67D",
          "Request": "#3DA9FC",
          "Genre": "#A31B1B",
          "Topic": "#D08B5B",
          "Premises": "#1A49A5",
          "Modifier": "#219653",
          "Character": "#5B3680",
          "Archetype": "#FFC0C0",
          "Conflict": "#FFAA0D",
          "Crisis": "#4173D6",
          "Issues": "#53CAA6",
          "Resolution": "#7956C1",
          "Dramatic Question": "#219653",
          "Loglines": "#4173D6",
          "Taglines": "#5B3680",
          "Story Messages": "#D08B5B"
        },
        "uncategorized": []
      }
    },
    "categoryColorMap": {
      "Story": "#FF8BA7",
      "Money": "#2CB67D",
      "Request": "#3DA9FC",
      "Genre": "#A31B1B",
      "Topic": "#D08B5B",
      "Premises": "#1A49A5",
      "Modifier": "#219653",
      "Character": "#5B3680",
      "Archetype": "#FFC0C0",
      "Conflict": "#FFAA0D",
      "Crisis": "#4173D6",
      "Issues": "#53CAA6",
      "Resolution": "#7956C1",
      "Dramatic Question": "#219653",
      "Loglines": "#4173D6",
      "Taglines": "#5B3680",
      "Story Messages": "#D08B5B",
      "Blank": "#FFAA0D",
      "": "#C4C4C4"
    }
  }
