import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { Vector2 } from "../common/vector/vector.interface";
import { ECursorMode } from "../cursor/cursor-mode";

export interface IJamStoreState {
    id: string;
    roomName: string;
    host: string;
    selectedCards: string[];
    selectedMulti: string[];
    cursorMode: ECursorMode;
    isSpectator: boolean;

    canvasCoordinates: Vector2;
    latestCardId: string | null;
}

export const initialState: IJamStoreState = {
    id: "",
    roomName: "",
    host: "",
    selectedCards: [],
    selectedMulti: [],
    cursorMode: ECursorMode.HAND,
    isSpectator: false,

    canvasCoordinates: { x: 0, y: 0 },
    latestCardId: null,
};

export const jamSlice = createSlice({
    name: "jam",
    initialState,
    reducers: {
        setJamId: (state: IJamStoreState, action: PayloadAction<IJamStoreState['id']>) => {
            state.id = action.payload;
        },
        setRoomName: (state: IJamStoreState, action: PayloadAction<IJamStoreState['roomName']>) => {
            state.roomName = action.payload;
        },
        setSelectedCards: (state: IJamStoreState, action: PayloadAction<IJamStoreState['selectedCards']>) => {
            state.selectedCards = action.payload;
        },
        setSelectedMulti: (state: IJamStoreState, action: PayloadAction<IJamStoreState['selectedMulti']>) => {
            state.selectedMulti = action.payload;
        },
        setCursorMode: (state: IJamStoreState, action: PayloadAction<IJamStoreState['cursorMode']>) => {
            state.cursorMode = action.payload;
        },
    },
});

export const { setJamId, setRoomName, setSelectedCards, setSelectedMulti, setCursorMode } = jamSlice.actions;

export const jamReducer = jamSlice.reducer;
