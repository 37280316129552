import { ReceiptTwoTone } from '@material-ui/icons'
import React from 'react'
import socket from '../../../../api/socket'
import EE from '../../../../api/eventemitter'
import Cursor from './cursor'
import {getUserId} from '../../../../utility/function'

class cursorMap extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            playerCursorMap: new Map(),
            initial: false,
        }
    }


    componentDidMount(){

        if(this.state.initial == false){
            this.setState({
                playerCursorMap: this.props.initialCursorMap,
                initial: true
            })
        }

        socket.on("Sync Player Cursor", (data) =>{
            const {uID, playerCursor} = data
            this.setState(state =>{
                const playerCursorMap = state.playerCursorMap

                playerCursorMap.set(uID, playerCursor)

                return{
                    playerCursorMap
                }
            })
        })

        EE.on("Cursor Move", data => {
            const {event, uID} = data;
            this.trackPlayerCursor(event,uID)
        })
    }

    render(){
        const playerCursorMap = this.state.playerCursorMap
        const playerCursorArr = []
        for(const [uID, cursorData] of playerCursorMap.entries()){
            if(getUserId() !== uID){
                playerCursorArr.push(
                    <Cursor
                        key={uID}
                        cursorData={cursorData}
                    />
                )
            }
        }

        return  playerCursorArr
    }

    trackPlayerCursor = (event, uID) =>{   
        this.setState(state =>{
            const playerCursorMap = state.playerCursorMap
            const playerCursorData = playerCursorMap.get(uID)

            playerCursorData.x = ((-this.props.canvasX + event.pageX) / this.props.scale)
            playerCursorData.y = ((-this.props.canvasY + event.pageY - 112) / this.props.scale)

            playerCursorMap.set(uID, playerCursorData)

            return{
                playerCursorMap
            }
        }, () =>{
            const playerCursorMap = this.state.playerCursorMap
            
            socket.emit("Sync Player Cursor", {uID: uID, playerCursor: playerCursorMap.get(uID)})
        })
    }
}


export default cursorMap