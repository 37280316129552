import { IVersionInfo } from './version-info.interface';
import { appConfig } from '../../app/app.config';

export const getVersionInfo = async (): Promise<IVersionInfo | null> => {
    try {
        const response = await fetch('/version.json', {
            method: 'GET',
        });
        const data = await response.json();

        return data;
    } catch (error) {
        if (appConfig.__DEV__) {
            console.error('Failed to retrieve version information');
        }

        return null;
    }
};

export const versionInfoApi = {
    get: getVersionInfo,
};
