import * as React from 'react';
import {
  Grid,
  Card as MuiCard,
  IconButton,
  Avatar,
} from '@material-ui/core';
import {
  MoreVert,
  Create,
  Room,
  Eco,
} from '@material-ui/icons';

import { ICardFrontProps } from './card-front.interface';

export const CardFront = (props: ICardFrontProps): React.ReactElement => {
  const {
    category,
    categoryColor,
    flipped = false,
    edited = false,
    updatedAt,
    pinned = false,
    username,
    color = '#000',
    iconBg,
    openContextMenu,
    classes,
  } = props;

  return (
    <MuiCard
      className={flipped ? classes.cardCoverFlip : classes.cardCover}
      style={{
        background: category === 'Blank'
          ? '#fff'
          : (!category ? '#c4c4c4' : categoryColor ?? '#c4c4c4')
      }}
    >
      <Grid container direction="row" justify="center" alignItems="center">
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item xs={3} />
          <Grid
            item xs={6}
            className={classes.cardCoverCategory}
            style={{ color: category === 'Blank' ? color : '#fff' }}
          >
            {!category ? 'No category' : category}
          </Grid>
          <Grid item xs={3}>
            <IconButton
              className={classes.iconRight}
              style={{ color: '#c4c4c4' }}
              onClick={openContextMenu}
            >
              <MoreVert
                style={(category == null || !categoryColor || category !== 'Blank') ? { color: '#fff' } : { color: '#c4c4c4' }}
              />
            </IconButton>
          </Grid>
        </Grid>
        <Grid container direction="row" justify="center" alignItems="center" className={classes.cardCoverIconWrapper}>
          <Grid item xs={12}>
            {category !== 'Blank' ?
              <Avatar style={{ width: '120px', height: '120px', margin: 'auto', background: 'transparent' }}>
                <Eco style={{ fontSize: '100px', color: '#fff' }} />
              </Avatar>
              :
              <Avatar style={{ width: '120px', height: '120px', margin: 'auto', background: iconBg ?? categoryColor }}>
                <Create style={{ fontSize: '50px', color: '#fff' }} />
              </Avatar>
            }
          </Grid>
        </Grid>
        <Grid
          item xs={12}
          className={classes.cardCoverInfo}
          style={{ color: category !== 'Blank' ? '#fff' : color }}
        >
          <p className={classes.username}>{username}</p>
          {edited ? updatedAt + ' • (Edited)' : updatedAt}
          {pinned && <Room className={classes.pin} />}
        </Grid>
      </Grid>
    </MuiCard>
  );
};
