//Node modules
import React from 'react'
import {withStyles, Divider} from '@material-ui/core'
import moment from "moment";
//Other modules
import '../../../app/app.css'
import socket from '../../../api/socket';
import EE from '../../../api/eventemitter'
import {voteStyle} from '../user_interface/assets/voteUI'
import GameVoteSession from './gameVoteSession';
import {getUserId} from '../../../utility/function'

//TODO: Fix the bug where the timer doesn't run in the background (gameVote)

class GameVote extends React.Component{
    constructor(props){
        super(props)
        this.session = React.createRef();
        this.state = {
            voteStart: false,
            open: null,
            prevOpen: null,
            sessions: [],
            playerList: null
        }

        this.timer = []
    }

    componentDidMount(){
        EE.on("Call for Vote", (data) =>{
            const {playerNames, title, latestObject, userIds, playerList, operation, condition, successEvent, failEvent, nextEvent} = data
            let tempSessions = this.state.sessions
            let sessionID = this.state.sessions.length

            // let modifiedTitle = title
            // let rex = null

            // for(let i = 0; i < playerNames.length; i++){
            //     rex = new RegExp("(\\[" + i + "\\])", "g")
            //     modifiedTitle = modifiedTitle.replace(rex, playerNames[i])
            // }

            let cardToVote = {
                id: latestObject.id,
                content: latestObject.content,
                status: latestObject.status,
                time: latestObject.time,
                username: latestObject.origin.username,
                flip: false,
                label: latestObject.labels ? latestObject.labels : latestObject.label,
                category: latestObject.category,
                value: latestObject.content,
                anchorEl : null,
                type: latestObject.type,
                vote: 1
            }

            let newSession = {
                title: `Voting Session ${sessionID + 1}`,
                sessionID: sessionID,
                owner: playerNames[0], //should be replaced with user that starts timer
                status: "Ongoing",
                progress: 100,
                cards:[cardToVote],
                voted: [...userIds],
                interruptorId: userIds[0],
                receiverId: userIds[1],
                playerList: playerList,
                operation: operation,
                condition: condition,
                successEvent: successEvent,
                failEvent: failEvent,
                nextEvent: nextEvent,
            }

            tempSessions.push(newSession)

            this.setState(({
                sessions: tempSessions,
                open: sessionID,
                playerList: playerList
            }), ()=>{
                // Emits to gameVote
                socket.emit("Vote Cards", {open: this.state.open, sessions: this.state.sessions})
                //Emits to gameVoteSession
                EE.emit("Vote Button Click", {sessionID: this.state.open})
            })
        });

        EE.on("Vote Starts", (data) => {
            const {voteStart} = data
            this.setState({voteStart: voteStart})
        })

        //Called from gameVoteSession
        EE.on("Vote Ends", (data) => {
            const {voteStart, sessionID} = data

            let newSession = this.state.sessions
            newSession[sessionID].status = "Completed"

            this.setState({
                voteStart: voteStart,
                sessions: newSession
            }, () =>{
                socket.emit("Vote Ends", {voteStart: this.state.voteStart, sessions: this.state.sessions, sessionID: sessionID})
            })
        })

        socket.on("Vote Ends", (data) =>{
            const {voteStart, sessions} = data

            this.setState({
                voteStart: voteStart,
                sessions: sessions
            })
        })

        //Called from cards/gameCanvasObject
        EE.on("Vote Cards", (data) => {
            const {card, obj} = data
            // Check if an ongoing session exists && takes the latest voting session to store vote card to
            let sessionID = -1
            let isNew = false
            let newSession = null

            //Check if latest session is ongoing
            let latestSession = this.state.sessions.slice(-1).pop()
            if(latestSession && latestSession.status === "Ongoing"){
                sessionID = this.state.sessions.length - 1
                newSession = this.state.sessions[sessionID]
            }
            //If doesn't exist, create new session
            else if(sessionID === -1){
                sessionID = this.state.sessions.length
                isNew = true
                newSession = {
                    title: "Vote " + (this.state.sessions.length + 1),
                    owner: "System", //should be replaced with user that starts timer
                    status: "Ongoing",
                    progress: 100,
                    cards:[],
                    voted: [getUserId()],
                    playerList: this.state.playerList,
                    sessionID: sessionID
                }
            }

            let newCard = {
                id: obj.objId,
                content: card.content ?? obj.content,
                status: card.status,
                time: card.time,
                username: obj.origin.username,
                flip: card.flip,
                label: card.labels,
                category: card.category,
                value: card.content,
                anchorEl : null,
                type: obj.type,
                vote: 1
            }

            newSession.cards.push(newCard)

            this.setState(state =>{
                let sessions = state.sessions
                let open = state.open
                let newList = JSON.parse(JSON.stringify(sessions))

                isNew ? sessions = [...state.sessions, newSession] : sessions = newList
                open = sessionID

                return{
                    open,
                    sessions
                }
            }, () =>{
                socket.emit("Vote Cards", {open: this.state.open, sessions: this.state.sessions})
            })
        })

        //Called from gameVote
        socket.on("Vote Cards", data => {
            const {open, sessions} = data
            this.setState({
                open: open,
                sessions: sessions,
            })
        })

        //Called from cards
        EE.on("Unvote Cards", (data) => {
            const {obj} = data

            const newCards = this.state.sessions[this.state.open].cards.filter((stateCard) =>{
                return stateCard.id !== obj.objId
            })

            let newSession = this.state.sessions
            newSession[this.state.open].cards = newCards
            this.setState(({
                sessions: newSession
            }), () =>{
                socket.emit("Unvote Cards", {sessions: this.state.sessions})
            })

        })

        socket.on("Unvote Cards", data =>{
            const {sessions} = data

            this.setState({
                sessions: sessions
            });
        })
    }

    render(){
        const {classes} = this.props

        return(
            <>
                <div className={`${classes.panel} ${classes.listPanel}`}>
                    {this.state.sessions.map((s,index)=>
                        <div style={{marginBottom:"10px"}}>
                            <p onClick={(e) => this.openVote(index)} className={classes.listTitle}>
                                {(index + 1) + ". " + s.title}
                            </p>
                            <p className={classes.listOwner}>
                                {s.interruptorId ?
                                    <>Triggered by: {this.getInterrupter(s.playerList, s.interruptorId)}</>
                                    :
                                    <>Owner: {s.owner}</>
                                }
                            </p><br/>
                            <p className={classes.listStatus}>
                                {s.status}
                            </p>
                            <Divider/>
                        </div>
                    )}
                </div>

                {this.state.sessions ?
                    this.state.sessions.map((session, index) =>(
                        <div
                            style={{
                                left: this.state.open === session.sessionID ? "0px" : "250px"
                            }}
                            key={session.title}
                            id={index+"-panel"}
                            className={`
                                ${classes.panel}
                                ${classes.sessionPanel}
                            `}
                        >
                            {console.log(session)}
                            <GameVoteSession
                                key={session.title + index}
                                vote={session}
                                voteStart = {this.state.voteStart}
                                sessionID={index}
                                updateProgress={this.updateProgress}
                                updateStatus={this.updateStatus}
                                updateCards={this.updateCards}
                                updateVoted={this.updateVoted}
                                closeVote={this.closeVote}
                                listPinIds={this.props.listPinIds}
                                categoryColorMap={this.props.categoryColorMap}
                                imgData = {this.props.imgData}
                                onImgUpload = {this.props.onImgUpload}
                                onImgPaste = {this.props.onImgPaste}
                                removeImg = {this.props.removeImg}
                                onDrop = {this.props.onDrop}
                            />
                        </div>
                    ))
                    :
                        null

                }
            </>
        )
    }

    getInterrupter = (playerList, interruptorId) =>{
        for(let i=0; i<playerList.length; i++){
            if(playerList[i].uid === interruptorId){
                return playerList[i].username
            }
        }
        return null
    }

    openVote = (id) =>{
        this.setState({
            open: id
        })
    }

    closeVote = () =>{
        this.setState({
            open: null
        })
    }

    updateProgress = (progress, id) =>{
        this.setState(state =>{
            let sessions = state.sessions
            sessions[id].progress = progress

            return{
                sessions
            }
        })
    }

    updateStatus = (status, id) =>{
        let updatedSession = this.state.sessions
        updatedSession[id].status = status
        this.setState({
            sessions: updatedSession
        })
    }

    updateCards = (cards, id) =>{
        let updatedSession = this.state.sessions
        updatedSession[id].cards = cards
        this.setState({
            sessions: updatedSession
        });
    }

    updateVoted = (voted, id) =>{
        let updatedSession = this.state.sessions
        updatedSession[id].voted = voted
        this.setState({
            sessions: updatedSession
        });
    }
}

export default withStyles(voteStyle)(GameVote)
