/** Built-in Modules */

/** Third-Party Modules */
import * as React from 'react';
import { withStyles } from '@material-ui/core';
import Draggable, { DraggableData, DraggableEvent } from 'react-draggable';
import { withApollo } from 'react-apollo';

/** Types */
import { IDraggableStackProps, IDraggableStackState } from './draggable-stack.interface';
import { Vector2 } from '../common/vector/vector.interface';
import { ICard } from '../card/card.interface';

/** Config */

/** API */
import socket from '../../api/socket';
import { gameObjectApi } from '../game-object/game-object.api';

/** Components */
import CanvasState from '../../component/game/state/canvasState';
import { CanvasUtil } from '../../component/game/helper/gameCanvasHelper';
import CardStack from '../card-stack/card-stack';

/** Styling */
import styles from '../../component/game/user_interface/assets/cardStyle';

import { getUserId } from '../../utility/function';

class DraggableStack extends React.Component<IDraggableStackProps, IDraggableStackState> {
  private snapping: boolean;

  private snappingPos: Vector2;

  private dragCardState: any;

  private touchCardState: any;

  constructor(props: IDraggableStackProps) {
    super(props);
    const { obj } = props;

    this.state = {
      id: obj.objId!,
      attachment: null,
      interrupt: null,
      origin: obj.origin,
      edited: false,
      pin: false,
      vote: obj.vote,
      type: obj.type ?? 'stack',
      category: 'Stack',
      labels: ['Stack'],
      time: obj.time,
      objs: obj.objs!,
      x: obj.pos.x ?? 1,
      y: obj.pos.y ?? 1,
      flipStack: obj.flip,
      color: '',

      loaded: false,
      isManage: null,
    }

    this.snapping = false;
    this.snappingPos = { x: 0, y: 0 };
  }

  componentDidMount() {
    this.setState({ loaded: true }, () => {
      CanvasState.objRefMethods.set(this.state.id, this);
    });

    socket.on('Sync Stack', (data: Partial<ICard>) => {
      const { objId, objs } = data;
      if (this.state.id === objId) {
        this.setState({ objs: objs ?? [] });
      }
    });

    socket.on('Game Card Position Update', (data: Partial<ICard> & ICard['pos']) => {
      const { id } = this.state;
      const objId = data.id;

      if (id === objId) {
        this.setState({
          x: data.x,
          y: data.y,
        });
      }
    });

    socket.on('Game Card Position Sync', (data: Partial<ICard> & ICard['pos']) => {
      const { id } = this.state;
      const objId = data.id;

      if (id === objId) {
        this.setState({
          x: data.x,
          y: data.y,
        });
      }
    });

    socket.on('Canvas Flip Object', (data: Partial<ICard>) => {
      const { id } = this.state;
      const objId = data.id;
      const flip = data.flip;

      if (id === objId) {
        this.setState({ flipStack: flip ?? false });
      }
    });
  }

  componentWillUnmount() {
    // socket.off();
    CanvasState.objRefs.delete(this.state.id);
    CanvasState.objRefMethods.delete(this.state.id);
  }

  public willSnap = (newCoords: Vector2): void => {
    this.pairingFunc(true, { x: newCoords.x, y: newCoords.y });
  }

  public willNotSnap = (): void => {
    this.pairingFunc(false, { x: null, y: null });
  }

  public willStack = (dragCardState: any, touchCardState: any): void => {
    this.dragCardState = dragCardState;
    this.touchCardState = touchCardState;
  }

  public willNotStack = (): void => {
    this.dragCardState = null;
    this.touchCardState = null;
  };

  public pairingFunc = (willSnap: boolean, newCoords: Vector2): void => {
    this.snapping = willSnap;
    this.snappingPos = {
      x: newCoords.x,
      y: newCoords.y,
    };
  };

  public addObj = (objs: ICard[] | null): void => {
    this.setState({ objs }, () => {
      socket.emit('Sync Stack', {
        objId: this.state.id,
        objs: this.state.objs,
      });
    });
  };

  private handleClick = (event: MouseEvent): void => {
    if (!event) { return; }

    event.stopPropagation();
    event.preventDefault();

    if (
      event.button === 0
      && event.shiftKey
      && typeof this.props.selectCardsInLibrary === 'function'
      && this.props.obj
    ) {
      const { selectCardsInLibrary } = this.props;
      selectCardsInLibrary(this.props.obj);

      return;
    }

    const { selectedCards, setSelectedCard } = this.props;
    const selected = selectedCards.includes(this.state.id);
    if (
      (selectedCards.length === 0 || !selected)
      && typeof this.props?.setSelectedCard === 'function'
    ) {
      setSelectedCard(this.state.id);
    }
  };

  private setManage = (stackIndex: number): void => this.setState({ isManage: stackIndex });

  private quitManage = (): void => {
    const { isManage } = this.state;
    if (isManage) {
      this.setState({ isManage: null });
    }
  };

  private updateCardContent = (stackIndex: number, content: string): void => {
    this.setState((state: IDraggableStackState): Pick<IDraggableStackState, 'objs'> => {
      const { objs } = state;

      if (objs) {
        objs[stackIndex].content = content;

        return { objs };
      }

      return state;
    }, () => {
      const { updateStackObject } = this.props;
      socket.emit('Sync Stack', { objId: this.state.id, objs: this.state.objs })
      updateStackObject(this.state.id, { objs: this.state.objs }, 'Objects')
    })
  }

  private stackingFunc = (): void => {
    const { addStackObject } = this.props;
    if (this.dragCardState && this.touchCardState) {
      addStackObject(this.dragCardState, this.touchCardState);
    }
  };

  private updatePosition = (data: DraggableData): void => {
    const { x, y } = this.state;

    const currentX = x! + data.deltaX;
    const currentY = y! + data.deltaY;

    this.setState({
      x: currentX,
      y: currentY
    }, () => {
      socket.emit('Game Card Position Update', {
        id: this.state.id,
        x: this.state.x,
        y: this.state.y,
        color: this.state.color,
      });
    });

  }

  private syncPosition = (event: DraggableEvent): void => {
    //After dragging cards
    event.preventDefault();
    const { x, y } = this.state;

    //Pairing
    this.setState({
      x: this.snapping ? this.snappingPos.x : x,
      y: this.snapping ? this.snappingPos.y : y,
    }, () => {
      this.snapping = false;
      this.snappingPos.x = null;
      this.snappingPos.y = null;

      gameObjectApi(this.props.client!).mutatePosition({
        gid: this.props.gid,
        objectId: this.props.obj.objId!,
        x: this.state.x!,
        y: this.state.y!,
      }).then(() => {
        socket.emit('Game Card Position Sync', {
          id: this.state.id,
          x: this.state.x,
          y: this.state.y,
        });
      });
    });
  }

  private bringForward = (_event: React.MouseEvent, index: number): void => {
    const { objs } = this.state;

    if (objs) {
      let cards = [...objs];

      if (index !== cards.length - 1) {
        let temp = cards[index + 1]; //temp = card 2
        cards[index + 1] = cards[index]; //card 2 = card 1
        cards[index] = temp; //card 1 = temp
        // cards[index + 1].anchorEl = null;

        this.setState({ objs: cards }, () => {
          // Emit to stackCard
          socket.emit('Sync Stack', {
            objId: this.state.id,
            objs: this.state.objs,
          });
        });
      }
    }
  };

  private bringBackward = (_event: React.MouseEvent, index: number): void => {
    const { objs } = this.state;
    if (objs) {
      let cards = [...objs];

      // Need to pass events to register the click 🙄
      if (index !== 0) {
        let temp = cards[index - 1]; // temp = card 1
        cards[index - 1] = cards[index]; // card 1 = card 2
        cards[index] = temp; // card 2 = temp
        // cards[index - 1].anchorEl = null;

        this.setState({ objs: cards }, () => {
          // Emit to stackCard
          socket.emit('Sync Stack', {
            objId: this.state.id,
            objs: this.state.objs,
          });
        });
      }
    }
  };

  private checkPopCard = (
    event: React.MouseEvent,
    index: number,
  ): Partial<ICard> | null => {
    const { objs } = this.state;
    if (objs) {
      const { canvasData, latestID } = this.props;

      const card = objs[index];
      const uid = getUserId();
      const { x, y, scale } = canvasData;

      const obj = {
        objId: `${uid}-${latestID}`,
        origin: card.origin,
        flip: this.state.flipStack,
        edited: card.edited,
        pin: card.pin,
        time: card.time,
        type: card.type,
        status: card.status,
        label: (card.labels ? card.labels : card.label) as string,
        category: card.category,
        content: card.content,
        submitted: card.category === 'Blank' ? card.submitted : false,
        pos: {
          x: (-x + event.clientX) * (1 / scale),
          y: (-y + (event.clientY - 112)) * (1 / scale)
        },
        anchorEl: null,
        imgUrl: card.imgUrl,
        pairDir: null,
        paired: {
          left: null,
          right: null,
          up: null,
          down: null
        },
      }

      return obj;
    }

    return null;
  };

  private popCard = (event: React.MouseEvent, index: number): void => {
    const { objs } = this.state;
    const {
      canvasData,
      latestID,
      addObject,
      addMultiObject,
    } = this.props;
    if (objs) {
      const cards = [...objs];
      const uid = getUserId()
      const { x, y, scale } = canvasData;

      // get position of stacks
      const oriX = this.state.x!;
      const oriY = this.state.y!;

      if (cards.length > 2) {
        let obj = this.checkPopCard(event, index);
        addObject(obj);
        // cards[index].anchorEl = null;
        cards.splice(index, 1);

        this.setState({
          isManage: null,
          objs: cards
        }, () => {
          //Emit to stackCard
          socket.emit('Sync Stack', { objId: this.state.id, objs: cards })
        });
      } else {
        const objArr: Partial<ICard>[] = [];

        for (let i = 0; i < cards.length; i++) {
          const card = cards[i];

          objArr.push({
            objId: `${uid}-${(parseInt(latestID) + i).toString()}`,
            origin: card.origin,
            flip: this.state.flipStack,
            edited: card.edited,
            pin: card.pin,
            time: card.time,
            type: card.type,
            status: card.status,
            label: (card.labels ? card.labels : card.label) as string,
            category: card.category,
            submitted: card.category === 'Blank' ? card.submitted : false,
            content: card.content,
            pos: index === i
              ? {
                x: (-x + event.clientX) * (1 / scale),
                y: (-y + (event.clientY - 112)) * (1 / scale)
              } : {
                x: oriX,
                y: oriY,
              },
            imgUrl: card.imgUrl,
            pairDir: null,
            paired: {
              left: null,
              right: null,
              up: null,
              down: null
            },
          });
        }

        addMultiObject(objArr, this.state.id);
      }
    }
  }

  render() {
    const {
      gid,
      cardConfig,
      scale,
      canvasCoordinates,
      cursorMode,
      selectedCards,
      updateMultiPosition,
      categoryColorMap,
      formDeck,
      receiveTargetTextfield,
      setSubDragging,
      classes,
    } = this.props;
    const { x, y, id, objs, loaded, category, flipStack, isManage } = this.state;

    const { stackable, pairable, draggable } = cardConfig;

    const selected = selectedCards.includes(id);

    const classNames = [
      classes.draggableStackContainer,
      selected ? classes.draggableStackContainerSelected : '',
    ].join(' ');

    const handleDrag = (event: DraggableEvent, data: DraggableData) => {
      event.preventDefault();
      event.stopPropagation();

      if (selectedCards.length > 1) {
        updateMultiPosition(data);

        return;
      }

      this.updatePosition(data);
      CanvasUtil.checkCardStackAndPair(id, scale, canvasCoordinates, category, pairable, stackable);
    };

    const handleStopDrag = (event: DraggableEvent) => {
      this.syncPosition(event);
      if (stackable.includes('Any') || stackable.includes(category)) {
        this.stackingFunc();
      }
    };

    if (!loaded || !objs?.length) {
      return <></>;
    }

    return (
      <Draggable
        defaultClassName={classNames}
        disabled={(!draggable || cursorMode === 'Hand' || Boolean(isManage))}
        cancel=".cancel"
        onMouseDown={this.handleClick}
        onDrag={handleDrag}
        onStop={handleStopDrag}
        position={{ x: x!, y: y! }}
        scale={scale}
      >
        <div
          ref={(ref) => ref && CanvasState.objRefs.set(this.state.id, ref)}
          // This is needed where the height is dynamically calculated from the number of Cards
          // so that the pairing/stacking handler can work as expected
          style={{ position: 'absolute', height: `${285 + (70 * objs.length)}px` }}
        >
          <CardStack
            gid={gid}
            stackId={this.state.id}
            isManage={isManage}
            bringForward={this.bringForward}
            bringBackward={this.bringBackward}
            categoryColorMap={categoryColorMap}
            flipped={flipStack}
            formDeck={formDeck}
            popCard={this.popCard}
            setManage={this.setManage}
            quitManage={this.quitManage}
            receiveTargetTextfield={receiveTargetTextfield}
            setSubDragging={setSubDragging}
            updateCardContent={this.updateCardContent}
          >
            {objs}
          </CardStack>
        </div>
      </Draggable>
    )
  }
}

// @ts-ignore
export default withApollo(withStyles(styles as any)(DraggableStack));
