import * as React from 'react';
import { Tooltip } from '@material-ui/core';
import { LocalOfferOutlined } from '@material-ui/icons';

import { ILabelChipProps } from './label-chip.interface';
import classes from './label-chip.module.scss';

const labelTooltipMapper = (tag: string, index: number, array: string[]) => (
  index !== array.length - 1 ?
    tag + ', ' : tag
);

export const LabelChip = (props: ILabelChipProps): React.ReactElement => {
  const { labels } = props;

  const disableListeners = !Boolean(labels.length > 0);

  return (
    <Tooltip
      title={labels.map(labelTooltipMapper)}
      disableFocusListener={disableListeners}
      disableTouchListener={disableListeners}
      disableHoverListener={disableListeners}
    >
      <div className={classes.container}>
        <LocalOfferOutlined style={{ marginRight: '5px' }} />
        {labels.length}
      </div>
    </Tooltip>
  );
}
