import React , { Component} from 'react'
import {withStyles, Grid, IconButton, Badge} from '@material-ui/core'
import {FilterFrames, 
        TextsmsOutlined,
        CloseOutlined, 
        Equalizer, 
        RoomOutlined,
        Print,
        Create,Notifications} from '@material-ui/icons'
import GamePlayerHand from './gamePlayerHand'
import GameGeneralChat from './gameGeneralChat'
import GameVote from './gameVote'
import GameEditor from './gameEditor'
import GamePinned from './gamePinned'
import NotificationLog from './notificationLog'
import Loader from '../../loader'
import EE from '../../../api/eventemitter'
import socket from '../../../api/socket'

const styles = {
    container: {
        display: "flex",
        flexFlow: "column",
        height: "calc(100% - 7rem)",
        width: "250px",
        position: "fixed",
        zIndex: "3",
        fontFamily: "Arial",
        top: "7rem",
        right: "0",
        background: "#E1E1E1",
        transition: "0.5s",
        opacity: "0.99",
    },

    header:{
        padding:"1rem",
        fontWeight:"bold",
        color:"#4F4F4F",
        fontSize:"18px",
    },
      
    closebtn: {
        marginTop:"4px",
        color: "#4F4F4F",
        "&:focus":{
            outline:"none"
        }
    },
      
    openbtn: {
        color: "#4f4f4f",
        display:"block",
        cursor:"pointer",
        padding:"0.75rem",
        transition:".5s",
        borderRadius:"0px",
        "&:focus":{
            outline:"none"
        },
        "&:hover":{
            background:"rgba(0,0,0,0.5)"
        },
    },
    
    active:{
        //color:"black",
        background: "rgba(0,0,0,0.1)"
    },

    main: {
        transition: ".5s",
        height:"calc(100% - 7rem)",
        background:"#DBDBDB",
        position:"fixed",
        top: "7rem",
        right: "0",
        zIndex:"4",
        marginRight:"250px"
    },

    show:{
        display: "block",
        flex:"1",
        overflowY: 'auto'
    },

    hide:{
        display: "none",
    },
}
class GameRightPanel extends Component {
    constructor(props){
        super(props)
        this.sidebar = React.createRef();
        this.main = React.createRef();
        this.state = {
            deck: [],
            open: "My Hand",
            messageCount: 0,
            usedDeck: this.props.usedDeck
        }

        this.gameDeck = null;
    }

    componentDidMount(){
        EE.on("Call for Vote", () => {
            this.openNav("Vote")
        })

        socket.on("Call for Vote", () =>{
            this.openNav("Vote")
        })
    }
    
    render(){
        const {classes, automation, inhand, gid, participants, isLeader} = this.props
        // console.log(this.state.open)
        // this.openNav("My Hand")
        if(this.state.usedDeck){
            // console.log(this.state.usedDeck)
            return (
                <>
                    <div onClick={()=>{EE.emit("Canvas Defocus")}} ref={this.sidebar} className={classes.container}>
                        <Grid container>
                            <Grid item xs={9}>
                                <h1 id="header" className={classes.header}>{this.state.open!== "none" && this.state.open}</h1>
                            </Grid>
                            <Grid item xs={3}>
                                <center>
                                    <IconButton className={classes.closebtn} onClick={this.closeNav}>
                                        <CloseOutlined/>
                                    </IconButton> 
                                </center>
                            </Grid>
                        </Grid>
                        <div className={this.state.open === "Chat" ? classes.show : classes.hide}>
                            <GameGeneralChat
                                participants={this.props.participants}
                                isOpen ={this.state.open === "chat" ? true : false}
                                updateMessageCount = {this.updateMessageCount}
                                messageCount = {this.state.messageCount}
                                gid = {gid}
                                gameTranscript = {this.props.gameTranscript}
                                isSpectate = {this.props.isSpectate}
                                spectateInfo = {this.props.spectateInfo}

                            />
                        </div>
                        <div className={`game-hand ${this.state.open === "My Hand" ? classes.show : classes.hide}`}>
                            <GamePlayerHand 
                                deckConfig={this.props.deckConfig} 
                                listPinIds={this.props.listPinIds}
                                participants={participants} 
                                automation={automation} 
                                gameDeck={this.state.usedDeck} 
                                inhand={inhand} gid={gid} 
                                createdBy={this.props.createdBy}
                                categoryColorMap={this.props.categoryColorMap}
                                imgData = {this.props.imgData}
                                onImgUpload = {this.props.onImgUpload}
                                onImgPaste = {this.props.onImgPaste}
                                removeImg = {this.props.removeImg}
                                onDrop = {this.props.onDrop}
                            />
                        </div>
                        <div className={this.state.open === "Pinned" ? classes.show : classes.hide}>
                            <GamePinned
                                categoryColorMap={this.props.categoryColorMap}
                                listPinIds={this.props.listPinIds}
                                pinRightPanel={this.props.pinRightPanel}
                                libraryObjects={this.props.libraryObjects}
                                inhand={inhand}
                                imgData = {this.props.imgData}
                                onImgUpload = {this.props.onImgUpload}
                                onImgPaste = {this.props.onImgPaste}
                                removeImg = {this.props.removeImg}
                                onDrop = {this.props.onDrop}
                                />
                        </div>
                        <div className={this.state.open === "Vote" ? classes.show : classes.hide}>
                            <GameVote
                                listPinIds={this.props.listPinIds}
                                categoryColorMap={this.props.categoryColorMap}
                                imgData = {this.props.imgData}
                                onImgUpload = {this.props.onImgUpload}
                                onImgPaste = {this.props.onImgPaste}
                                removeImg = {this.props.removeImg}
                                onDrop = {this.props.onDrop}
                            />
                        </div>
                        <div className={this.state.open === "Notification Log" ? classes.show : classes.hide}>
                            <NotificationLog
                                participants={this.props.participants}
                            />
                        </div>

                        {
                            isLeader && 
                            <div className={this.state.open === "Editor" ? classes.show : classes.hide}>
                                <GameEditor
                                    listOfLabels={this.props.listOfLabels}
                                    listOfCategories={this.props.listOfCategories}
                                    categoryColorMap={this.props.categoryColorMap}
                                    changeColor={this.props.changeColor}
                                    changeCard={this.props.changeCard}
                                    cardSelected={this.props.cardSelected}
                                    imgData = {this.props.imgData}
                                    onImgUpload = {this.props.onImgUpload}
                                    onImgPaste = {this.props.onImgPaste}
                                    removeImg = {this.props.removeImg}
                                    selectedIDs = {this.props.selectedIDs}
                                    onDrop = {this.props.onDrop}
                                />
                            </div>
                        }

                    </div>
                    <div onClick={()=>{EE.emit("Canvas Defocus")}} ref={this.main} className={classes.main}>
                        <IconButton className={`${classes.openbtn} ${this.state.open === "My Hand" && classes.active}`} onClick={() => this.openNav("My Hand")}>
                            <span className="material-symbols-outlined">front_hand</span>
                        </IconButton>  
                        {/* <IconButton className={`${classes.openbtn} ${this.state.open === "Pinned" && classes.active}`} onClick={() => this.openNav("Pinned")}>
                            <RoomOutlined/>
                        </IconButton> */}
                        <IconButton className={`${classes.openbtn} ${this.state.open === "Chat" && classes.active}`} onClick={() => this.openNav("Chat")}>
                            <Badge badgeContent={this.state.messageCount} color="primary">
                                <TextsmsOutlined/>
                            </Badge>
                        </IconButton>
                        {/* <IconButton className={`${classes.openbtn} ${this.state.open === "Vote" && classes.active}`} onClick={() => this.openNav("Vote")}>
                            <Equalizer/>
                        </IconButton> */}
                        {isLeader &&
                            <IconButton className={`${classes.openbtn} ${this.state.open === "Editor" && classes.active}`} onClick={() => this.openNav("Editor")}>
                                <Create/>
                            </IconButton>
                        }
                        <IconButton className={`${classes.openbtn} ${this.state.open === "Output" && classes.active}`} onClick={() => this.openNav("Output")}>
                            <span className="material-symbols-outlined">output</span>
                        </IconButton>
                        <IconButton className={`${classes.openbtn} ${this.state.open === "Notification Log" && classes.active}`} onClick={() => this.openNav("Notification Log")}>
                            <span className="material-symbols-outlined">notifications</span>
                        </IconButton>
                        
                    </div>
                </>
            )             
        }else{
            return (<Loader/>)
        }
    }
    
    openNav = (focus) =>{
        if(this.state.open==="none"){
            this.props.panelOpen(false)
            this.main.current.style.marginRight = "250px";
        }
        this.setState({open: focus}, ()=>{
            this.props.panelOpen(true)
            this.sidebar.current.style.right = "0";
            this.main.current.style.marginRight = "250px";
            
            if(this.state.open==="Chat"){
                this.setState({
                    messageCount: 0
                });
            }

            if(focus === "Output"){
                EE.emit("Open Output")
            }
        })
    }

    closeNav = () => {
        this.main.current.style.marginRight= "0";
        this.sidebar.current.style.right = "-250px";
        this.props.panelOpen(false)
        this.setState({open: "none"})
    }

    updateMessageCount = (count) =>{
        if(this.state.messageCount !==  count){
            this.setState({
                messageCount: count
            });
        }

    }

}



export default withStyles(styles)(GameRightPanel)