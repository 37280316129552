import * as React from 'react';
import {
  Menu,
  MenuItem,
} from '@material-ui/core';
import {
  FlipToFront,
  FlipToBack,
  Reply,
  Repeat,
  Layers,
} from '@material-ui/icons';

import { ICardStackMenuProps } from './card-stack-menu.interface';
import classes from './card-stack-menu.module.scss';

const menuPaperStyle = {
  fontFamily: 'Montserrat, sans-serif',
  color: '#4F4F4F',
  border: '0.5px solid #c4c4c4',
};

export const CardStackMenu = (props: ICardStackMenuProps): React.ReactElement => {
  const {
    anchorEl,
    stackId,
    stackIndex,
    closeMenu,
    flipStack,
    bringForward,
    bringBackward,
    formDeck,
    popCard,
  } = props;

  const handleBringForward = (event: React.MouseEvent<HTMLLIElement>) => {
    bringForward(event, stackIndex);
    closeMenu(stackIndex);
  };

  const handleBringBackward = (event: React.MouseEvent<HTMLLIElement>) => {
    bringBackward(event, stackIndex);
    closeMenu(stackIndex);
  };

  const handleFlipStack = () => {
    flipStack();
    closeMenu(stackIndex);
  };

  const handleFormDeck = () => {
    closeMenu(stackIndex);
    formDeck(stackId);
  };

  const handleRemoveFromStack = (event: React.MouseEvent<HTMLLIElement>) => {
    closeMenu(stackIndex);
    popCard(event, stackIndex);
  };

  return (
    <Menu
      anchorEl={anchorEl as Element | ((element: Element) => Element) | null | undefined}
      PaperProps={{ style: menuPaperStyle }}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={() => closeMenu(stackIndex)}
    >
      <MenuItem className={classes.menuItem} onClick={handleBringForward}>
        <FlipToFront className={classes.menuIcon} />
        Bring Forward
      </MenuItem>
      <MenuItem className={classes.menuItem} onClick={handleBringBackward}>
        <FlipToBack className={classes.menuIcon} />
        Bring Backward
      </MenuItem>
      <MenuItem className={classes.menuItem} onClick={handleFlipStack}>
        <Repeat className={classes.menuIcon} />
        Flip Stack
      </MenuItem>
      <MenuItem className={classes.menuItem} onClick={handleFormDeck}>
        <Layers className={classes.menuIcon} />
        Form Deck
      </MenuItem>
      <MenuItem className={classes.menuItem} onClick={handleRemoveFromStack}>
        <Reply className={classes.menuIcon} />
        Remove from Stack
      </MenuItem>
    </Menu>
  );
};
