import React from 'react';
import {withApollo} from 'react-apollo'
import ContentEditable from "react-contenteditable";
import sanitizeHtml from "sanitize-html"
import _ from 'lodash'

import {Grid, Card, IconButton, Avatar, Menu, MenuItem,  Tooltip, withStyles} from '@material-ui/core';
import {MoreVert, Create, Repeat, CloseRounded, Room, FlipToFront, FlipToBack, Reply, LocalOfferOutlined, Eco,Layers, Shuffle} from '@material-ui/icons'
import moment from 'moment'
import EE from '../../../api/eventemitter'
import socket from '../../../api/socket'
import styles from '../user_interface/assets/cardStyle'
import {GAME_OBJECT_CONTENT, GAME_UPDATE_OBJECT_FLIP} from '../../../api/graphql-mutation'
import config from '../../../config/backend'

let linkify = require('linkify-it')()
class Cards extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            id: null,
            flip:false,
            pin: false, 
            anchorEl: null,
            submitted:false,
            edited:false,
            vote: false,
            category: null,
            labels: null,
            type: null,
            styling: {
                iconBg: null,
                color: null,
                background: null
            },
            content: null,
            origin: null,

            loaded: false,
            readonly: true,
        }

        this.sanitizeConf = {
            allowedTags: ["b", "i", "em", "strong", "a", "p", "h1"],
            allowedAttributes: { a: ["href"]}
        }

        this.inputRef = React.createRef();
    }
    
    componentDidMount() {        
        const {obj, stack} = this.props

        this.setState({
            id: obj.id,
            flip:  obj.flip, //Can you even flip stack cards??
            time: obj.time,
            content: obj.content,
            category: obj.category,
            type: obj.type,
            labels: obj.labels ? obj.labels : obj.label,
            styling: stack ? 
                    obj.styling : 
                    {
                        background: obj.category === "Blank" ? "#fff" : "#4173D6",
                        color:  obj.category === "Blank" ? "#231F20" : "#fff",
                        iconBg: obj.category === "Blank" ? "orange" : "#4173D6"
                    },
            origin: obj.origin,
            vote: obj.vote,
            submitted: obj.category === "Blank" ? obj.submitted : false,

            loaded: true
        }, () =>{
            this.checkLink()
        })

        socket.on("Object Live Typing", (data)=>{
            const {id} = this.state
            const {content, objId} = data

            if(id === objId){
                this.setState({
                    content: content
                })
            }
        })

        socket.on("Game Object Parse Links", (data) =>{
            const {selectedIDs} = data
            
            if(selectedIDs.includes(this.state.id)){
                this.checkLink()
            }
        })
    }

    render(){
        const {classes, stack, stackIndex, isManage, stackId, obj, categoryColor, flipStack, flipStackFn, bringBackward, bringForward} = this.props
        const {flip, labels, submitted, time, category, pin, anchorEl, content, origin, edited, loaded, id} = this.state
        const {imgUrl} = obj
        const modTime = RegExp('(pm|am)', 'g').test(time) ?  time : moment(time).local().format("h:mm a")
        
        //So the new content is now a HTML string and to change the value into a string we do this
        const stringText = new DOMParser().parseFromString(content, "text/html").body.textContent

        let manageChecker = isManage === stackIndex ? true : false;

        return(
            <>
                {loaded &&
                <>
                    <Card
                        // onBlur={()=>{this.props.quitManage();}}
                        // onDoubleClick={()=>{
                            
                        //     if(isManage){
                        //         this.setState(
                        //             {
                        //                 readonly: false
                        //             },()=>{
                        //                 this.inputRef.current.focus(); 
                        //         })
                        //     }else{
                        //         this.props.setManage(stackIndex)
                        //     }
                        // }}
                        draggable="true"
                        onDragStart = {(e)=>{

                            // this.props.setSubDragging(true)
                        }} 
                        onDragEnd={
                            (e)=>{
                                this.props.popCard(e, stackIndex)
                                // this.setState({onDragIndex: index})
                            }
                        }
                        onContextMenu={(e)=>{
                            e.preventDefault();
                            e.stopPropagation();
                            stack ? this.props.openMenu(stackIndex, e) : this.openMenu(e)
                        }}  
                        className={classes.deck_card}
                    > 
                        {/* THE BACK OF THE CARD (CONTAINS CONTENT) */}
                        <Card 
                            style={{
                                background: "#fff", 
                                border: manageChecker ? "thick solid red" : null
                            }} 
                            className={classes.cardContentFlip}
                        >
                            <Grid container direction="row" justify="center" alignItems="center">
                                <Grid item xs={3}>
                                    <Avatar style={{background: categoryColor ? categoryColor : "#c4c4c4"}}>
                                        {category !== "Blank" ? 
                                         <Eco style={{color: "#fff"}}/> 
                                         : 
                                         <Create style={{color: "#FFF"}}/>}
                                    </Avatar>
                                </Grid>
                                <Grid item xs={6} style={{color: "#4f4f4f", fontSize:"10px"}}>
                                    <p className={classes.username}>{origin.username}</p>
                                    {edited ? modTime + " • (Edited)" : modTime}
                                    {pin ? <Room className={classes.pin}/> : null}
                                </Grid>
                                <Grid item xs={3}>
                                    <IconButton className={classes.iconRight} onClick={(e) => stack ? this.props.openMenu(stackIndex, e) : this.openMenu(e)}>
                                        <MoreVert style={{color:"#C4C4C4"}}/>
                                    </IconButton>
                                </Grid>
                                <Grid item xs={12}>
                                    <p style={{margin:"10px 0 0 0", color: !categoryColor ? "#c4c4c4" : categoryColor, fontSize:"14px", fontWeight:"bold"}}>
                                        {category == null ?
                                        "No category" :
                                        category}
                                    </p>
                                </Grid>
                            </Grid>
                            <Grid container className={classes.contentWrapper}>
                                {
                                imgUrl ? 
                                /**
                                 * If image is uploaded in card
                                 */
                                <div className={classes.imgHolder}>
                                    <IconButton className={classes.imgDelete} style={{outline:"none"}}>
                                        <CloseRounded style={{fontSize:"12px"}}/>
                                    </IconButton>
                                    <img 
                                        style={{width:"100%"}} 
                                        alt="img" 
                                        src={`${config.backend.uri}/static/uploads/${imgUrl}`}
                                    />
                                </div>
                                :
                                <ContentEditable
                                    innerRef={this.inputRef}
                                    id="contentEditable" 
                                    className={classes.content /*+ " cancel"*/}
                                    style={{
                                        height:"100%",
                                        overflowY: "auto",
                                        width:"190px",
                                        zIndex: 1,
                                    }}
                                    html={content}
                                    onKeyDown={(e)=>{
                                        if (e.keyCode === 13 && !e.shiftKey){
                                            // prevent shift + enter new lin
                                            e.preventDefault();
                                        }
                                    }}
                                    onClick={(evt) =>{
                                        evt.persist()
                                        if(evt?.target && category === "Blank" && this.props.stack){
                                            this.props.receiveTargetTextfield(evt.target)
                                            EE.emit("Canvas Defocus")
                                        }
                                        if(evt.target.tagName.toLowerCase() === 'a'){
                                            const newWindow = window.open(evt.target.href, '_blank', 'noopener noreferrer')
                                            if (newWindow){newWindow.opener = null}
                                        }
                                    }}
                                    onChange={(e)=>{
                                        this.onChange(e)
                                    }}
                                    onBlur={() =>{
                                        socket.emit("Game Object Parse Links", {selectedIDs: [id]})
                                        this.setState({
                                            readonly: true
                                        })
                                    }}
                                    // onPaste={(event)=>onImgPaste(event)}
                                    disabled={
                                        // (submitted && category === "Blank")|| 
                                        // category !== "Blank"
                                        imgUrl || this.state.readonly
                                    }
                                />
                                // <TextField
                                //     className={classes.content  + " cancel"}
                                //     placeholder={submitted ? "" : "Write your story here"}
                                //     InputProps={{disableUnderline: true, style:{color: "#4f4f4f", fontWeight:"bold", fontFamily:"Montserrat"}}}
                                //     inputProps={{maxLength: 150}}
                                //     multiline={true}
                                //     onChange={ (e)=>this.onChange(e) }
                                //     value={content}
                                //     rows={9} 
                                //     rowsMax={9} 
                                //     onDoubleClick={()=> {if(submitted){this.cardFeature("Submit")}}}
                                //     disabled={
                                //         (submitted && category === "Blank")|| 
                                //         category !== "Blank"
                                //     }
                                // />
                            }
                            </Grid>
                            <Grid container justify="center" alignItems="center" style={{width:"100%"}}>
                                <Grid item xs={12}>
                                    <Tooltip title={
                                        labels?.map((tag, index)=>(
                                            index !== labels.length - 1 ?
                                            tag + ", " : tag
                                        ))}
                                        disableFocusListener={labels.length > 0 ? false : true}
                                        disableTouchListener={labels.length > 0 ? false : true}
                                        disableHoverListener={labels.length > 0 ? false : true}
                                        >
                                        <div style={{display:"inline-block", cursor:"pointer", background:"#D1D1D1", width:"fit-content", margin:"0", outline:"none", color:"#4F4F4F", marginTop:"10px",padding:"5px 10px", marginRight:"10px", borderRadius:"10px"}}>
                                            <LocalOfferOutlined style={{marginRight:"5px"}}/>
                                            {labels.length}
                                        </div>
                                    </Tooltip>
                                            
                                    <p id="words" 
                                        className={classes.counterShow}>
                                        {imgUrl ? 0 : 150 - stringText.length}
                                    </p>
                                    {/* {!submitted ? 
                                        <p id="words" 
                                            className={classes.counterShow}>
                                            {170 - content.length}
                                        </p>
                                    :
                                        null
                                    } */}

                                    {/* <IconButton 
                                        className={submitted ? classes.sendRightHide : classes.sendRight} 
                                        onClick={()=>this.cardFeature("Submit")} 
                                        disabled={submitted}
                                    >
                                        <Send/>
                                    </IconButton> */}
                                </Grid>
                            </Grid>
                        </Card>
                        {/* THE FRONT OF THE CARD (CARD COVER) */}
                        { 
                        <Card 
                            style={
                                category === "Blank" ? 
                                {background: "#fff"} : 
                                category == null ? {background:"#c4c4c4"} :
                                {background: categoryColor ? categoryColor : "#c4c4c4"}
                            } 
                            className= {classes.cardCoverFlip}
                        >
                            <Grid container direction="row" justify="center" alignItems="center">
                                <Grid container direction="row" justify="center" alignItems="center">
                                    <Grid item xs={3}>

                                    </Grid>
                                    <Grid item xs={6}  className={classes.cardCoverCategory} 
                                        style={
                                            category !=="Blank" ?
                                                {color: "white"} 
                                            :
                                                {color: "#000"}
                                            }>
                                        {category == null ?
                                            "No category" :
                                            category}
                                    </Grid>
                                    <Grid item xs={3} >
                                        <IconButton className={classes.iconRight} style={{color:"#C4C4C4"}} onClick={(e) => stack ? this.props.openMenu(stackIndex, e) : this.openMenu(e)}>
                                            <MoreVert style={category == null || !categoryColor || category!=="Blank" ? {color: "white"} : {color:"#c4c4c4"}}/>
                                        </IconButton>
                                    </Grid>
                                </Grid>
                                <Grid container direction="row" justify="center" alignItems="center" className={classes.cardCoverIconWrapper}>
                                    <Grid item xs={12}>
                                        {category !== "Blank" ?
                                            <Avatar style={{width:"120px", height:"120px", margin:"auto", background: "transparent"}}>
                                                <Eco style={{fontSize:"100px", color: "#fff"}}/>
                                            </Avatar>
                                            
                                        :
                                            <Avatar style={{width:"120px", height:"120px", margin:"auto", background: categoryColor}}>
                                                <Create style={{fontSize:"50px", color: "#fff"}}/>
                                            </Avatar>
                                        }
                                        
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} className={classes.cardCoverInfo} 
                                    style=  {category !== "Blank" ?
                                                {color: "white"} 
                                            :
                                                {color: "#000"}}>
                                    <p className={classes.username}>{origin.username}</p>
                                    {edited ? modTime + " • (Edited)" : modTime}
                                    {pin ? <Room className={classes.pin}/> : null}
                                </Grid>
                            </Grid>
                        </Card>
                        }
                    </Card>
                    {stack &&
                    <Menu
                        id="simple-menu"
                        anchorEl={obj.anchorEl}
                        PaperProps={{style: { fontFamily:"Montserrat, sans-serif", color:"#4F4F4F", border:"0.5px solid #c4c4c4"}}}  
                        keepMounted
                        open={Boolean(obj.anchorEl)}
                        onClose={() =>this.props.closeMenu(stackIndex)}
                    >
                        <MenuItem className={classes.menuItem} onClick={(e) =>{ this.props.closeMenu(stackIndex); this.props.formStack(this.props.stackId);}}>
                            <Layers className={classes.menuIcon}/> 
                            Form Stack
                        </MenuItem>
                        <MenuItem className={classes.menuItem} onClick={(e) =>{ this.props.closeMenu(stackIndex); this.props.shuffle();}}>
                            <Shuffle className={classes.menuIcon}/> 
                            Shuffle Deck
                        </MenuItem>
                    </Menu>
                    }
                    
                </>
                }
            </>
        )
    }

    checkLink = () =>{
        let newContent = this.state.content
        let listOfUrls = linkify.match(this.state.content)

        //If there is a valid array and link
        if(listOfUrls && listOfUrls.length > 0){
            //The reason why we skip every two iteration is because whenever the link gets shortened,
            //linkify will think it's a new link, therefore we always skip the extra result
            for(let i = 0; i < listOfUrls.length; i+=2){
                let url = listOfUrls[i]
                // let urlLastIndex = url.text.length > 23 ? url.index + 26 : url.lastIndex 
                //Check if there is a href string due to us parsing pure HTML rather than string
                let hrefString = newContent.substring(url.index - 2, url.index - 6)
                //Check if the closing tag is "a" to prevent double operation
                let closingTag = newContent.substring(url.lastIndex  + 2, url.lastIndex  + 3)

                if(closingTag !== "a" && hrefString !== 'href'){
                    let slicedHTMLBeforeLink = newContent.substr(0, url.index)
                    let sliceHTMLAfterLink = newContent.substr(url.lastIndex, newContent.length)
                    let shortenedText = url.text.length > 23 ? url.text.substring(0,23).concat("...") : url.text

                    newContent = slicedHTMLBeforeLink + `<a href="${url.url}">${shortenedText}</a>`+ sliceHTMLAfterLink
                }
            }
        }

        this.setState({ 
            content: sanitizeHtml(newContent, this.sanitizeConf) 
        })

        return
    }
    
    onChange = (e) =>{
        this.setState({
            content: e.target.value.substring(0,150),
        },()=>{
            if(this.props.stack){
                this.debounceOnChange()
                socket.emit("Object Live Typing", {objId: this.state.id, content: this.state.content})
            } 
        })
    }

    debounceOnChange = _.debounce(() =>{
        const stackIndex = this.props.stackIndex
        const content = this.state.content

        this.props.updateCardContent(stackIndex, content)
    }, 300)

    cardFeature = (option) =>{
        const {gid} = this.props

        switch(option){    
            case "Submit":{
                const {id, content} = this.state
                this.setState(prev =>({
                    submitted: !prev.submitted,
                    edited: prev.submitted ? true : false
                }), () =>{
                    socket.emit("Game Card Submit Sync", {objId: id, submitted: this.state.submitted})
                    this.props.client.mutate({
                        mutation: GAME_OBJECT_CONTENT,
                        variables: {
                            _id: gid,
                            objId: id,
                            content: content,
                            submitted: this.state.submitted
                        }
                    })                    
                    // Leaving this commented until more definition for editted cards is found
                    this.state.edited ? socket.emit("Canvas Edit Object", {id: id, edited: this.state.edited}) : this.closeMenu()
                })
                break
            }
            case "Add IHL":{
                const {id, flip, time, content, category, type, labels, styling, origin} = this.state
                let cardState ={
                    id: id, flip: flip, time: time, content: content, category: category, type: type, labels: labels, styling: styling, origin: origin
                }
                //Emits to gamePlayerHand
                EE.emit("Privatise Blank Card", {cardState: cardState})
                //Emits to everyone in gameCanvas
                socket.emit("Remove Blank Card", {objId: id})       
                break
            }
            case "Flip":{
                const {id} = this.state
                //Flip card
                this.setState({
                    flip: !this.state.flip
                }, () =>{
                    this.props.client.mutate({
                        mutation: GAME_UPDATE_OBJECT_FLIP,
                        variables: {
                            _id: this.props.gid,
                            objId: id,
                            flip: this.state.flip
                        }
                    })
                    socket.emit("Canvas Flip Object", {id: id, flip: this.state.flip})
                })
                break
            }
            case "Vote":{
                this.setState(prev =>({
                    vote: !prev.vote
                }), ()=>{
                    this.state.vote ?
                       //Emit to gameVote
                        EE.emit("Vote Cards", {card: this.state, obj: this.props.obj}) :
                        EE.emit("Unvote Cards", {card: this.state, obj: this.props.obj})
                    
                    socket.emit("Vote Label", {vote: this.state.vote, obj:this.props.obj})
                })
                break
            }
            case "Pin": 
                //Pin card
                this.setState({
                    pin: !this.state.pin
                })
                break
            default: break
        }

        this.closeMenu()
    }

    closeMenu = () => {
        this.setState({
            anchorEl: null
        })
    }

    openMenu = () => {
        this.setState({
            anchorEl: true
        })
    }
}

export default withApollo(withStyles(styles)((Cards)))