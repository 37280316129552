import React from 'react'
import {withStyles, Menu, MenuItem, Grid, Tooltip, TextField, Chip, Popper, ClickAwayListener, IconButton} from '@material-ui/core'
import {ImageOutlined, CloseRounded, ArrowDropDownCircleOutlined, FormatListBulleted, TextFields, MoreVert,LayersOutlined} from '@material-ui/icons';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { CirclePicker  } from 'react-color';
import Linkify from 'linkifyjs/react';
import Dropzone from 'react-dropzone'
import EE from '../../../api/eventemitter'
import socket from '../../../api/socket'
import config from '../../../config/backend'

const styles = {
    icon:{
        color:"#4F4F4F",
        fontSize:"14px",
        marginTop:"-2px",
        marginRight:"10px"
    },

    field:{
        fontFamily:"Arial",
        fontSize:"14px",
        color:"#4f4f4f",
        marginTop:"10px"
    },

    underline: {
        "&&&:before": {
          borderBottom: "none"
        },
        "&&:after": {
          borderBottom: "none"
        }
    },

    ownJamEmpty:{
        color:"#828282", 
        width:"100%", 
        textAlign:"center", 
        marginBottom:"20px",
        marginTop:"10px"
    },

    optionStyles:{
        '&[data-focus="true"]': {
            backgroundColor: "transparent",
        },

        '&[aria-selected="true"]': {
            backgroundColor: "#E5E5E5",
        },
    },

    imgHolderWrapper:{
        position:"relative", 
        width:"100%", 
        height:"200px",
        padding:"10px",
        borderRadius:"5px",
        background:"#FAF7EA",
        border:"1px solid #c4c4c4",
        "&:hover $imgDelete":{ 
            background:"rgba(0,0,0, 0.7)",
            color:"white"
        }
    },
    imgHolder:{
        width:"calc(100% - 20px)", 
        height:"180px",
        marginLeft:"10%",
        overflow:"hidden",
    },

    imgDelete:{
        position:"absolute", 
        right:"30px", 
        top:"20px", 
        outline:"none",
        width:"20px", 
        height:"20px", 
        background:"transparent", 
        color:"transparent", 
        zIndex:"2", 
        transition:"0.5s", 
        '&:focus':{
            outline:"none"
        }
    },
    dropZone:{
        width:"100%", 
        height:"170px", 
        border:"1px dashed #C4C4C4", 
        background:"#F2F2F2", 
        color:"#C4C4C4", 
        lineHeight: "160px", 
        fontSize:"12px", 
        fontFamily:"Montserrat", 
        textAlign:"center"
    }
}

var typingTimer;
var doneTypingInterval = 1500;
const filter = createFilterOptions()
class GameEditor extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            //Input state
            contentValue: "",
            //Popper state
            anchorEl:null,
            open: false,
            //Selection state
            selected: false,

            imgUploaded: false, 
            imgSrc:null,
            contentAnchorEl: null
        }

        this.selectedCategoryEllipsis  = null
    }

    componentDidMount(){
        //These two EE is the dirtiest hack since if updating the content via prop
        //the words appear out really slow, hence these EE event emitters is to
        //receive prop.content while also maintaining state.contentValue
        EE.on("Sync Editor Content", (data) => {
            const {content} = data
            
            if(this.props.cardSelected){
                this.setState({
                    contentValue: content
                })
            }
        })
    }

    render(){
        const {classes, cardSelected, listOfCategories, listOfLabels, categoryColorMap, onImgUpload, onImgPaste, removeImg} = this.props
        const {anchorEl, contentAnchorEl, open} = this.state
        const {imgUploaded, imgSrc} = this.props.imgData
        let imgUrl = cardSelected?.imgUrl
        if(cardSelected){
            const categoryValue =  cardSelected?.category ? [{category: cardSelected.category}] : []
            const labelValue = cardSelected?.label ? 
                                cardSelected.label.map((value) =>{
                                    return {label: value}
                                }) : 
                                []

        
            const {contentValue} = this.state


            return(
                <>
                    <div style={{padding:"0 1rem", marginTop:"-5px"}}>
                        <Grid container>
                            <Grid item xs={12} className={classes.field}>
                                <Tooltip title={"Select"}>
                                    <LayersOutlined className={classes.icon}/>
                                </Tooltip> 
                                Category
                                <p style={{color:"#8C8C8C", fontSize:"10px", marginBottom:"10px"}}>
                                    This is the card’s category. The card’s color will follow the category’s color.
                                </p>
                                <Autocomplete
                                    classes={{
                                        option: classes.optionStyles
                                    }}
                                    value={categoryValue}
                                    multiple
                                    options={listOfCategories}
                                    onChange={(event, value) =>{

                                        if(typeof value[value.length - 1] === "string"){
                                            value[value.length - 1] = {category: value[value.length - 1]}
                                        }
                                        this.handleCategoryChange(value, cardSelected)
                                    }}
                                    size="small"
                                    filterOptions={(options, params) => {
                                        const filtered = filter(options, params);
                                
                                        // Suggest the creation of a new value
                                        if (params.inputValue !== '') {
                                            filtered.push({
                                                inputValue: params.inputValue,
                                                category: `Add "${params.inputValue}"`,
                                            });
                                        }

                                        filtered.length > 0  && filtered.sort((a, b) => a.category.toLowerCase().localeCompare(b.category.toLowerCase()));
                                        return filtered;
                                    }}
                                    getOptionLabel={(option) => option.category}
                                    getOptionDisabled={(option) =>
                                        categoryValue.map((object) =>{
                                            return option.category !== object.category
                                        })
                                    }
                                    getOptionSelected={(option, value) => option.label === value.label}
                                    disableCloseOnSelect
                                    disablePortal
                                    selectOnFocus
                                    clearOnBlur
                                    freeSolo
                                    underlinestyle={{display: 'none'}}
                                    renderTags={(value, getTagProps) =>
                                        value.map((option, index) => (
                                            <Chip
                                                variant="default"
                                                label={option.category}
                                                style={{background: categoryColorMap.get(option.category), borderRadius:"5px", border:"none", color:"white"}}
                                                size="small"
                                                {...getTagProps({ index })}
                                            />
                                        ))
                                    }
                                    renderOption={(option,state)=>(
                                        <Grid container alignItems="center">
                                            <Grid item xs={8}>{option.category}</Grid>
                                            <Grid item xs={4}>
                                                <IconButton
                                                    style={{padding:"5px", float:"right"}} 
                                                    onClick={(event)=>{
                                                        //This is required as to stop <Chip> from also registering
                                                        //the click if we click on <IconButton>
                                                        event.stopPropagation();
                                                        this.setState({anchorEl: event.currentTarget, open: true}, () =>{
                                                            this.selectedCategoryEllipsis = option.category 
                                                        })
                                                    }}
                                                >
                                                    <MoreVert style={{fontSize:"12px"}}/>
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder={categoryValue.length > 0 ? "" : "Type for category"}
                                            style={{background:"#FAF7EA", padding:"5px", fontSize:"12px", borderRadius:"5px", border:"1px solid #D1D1D1"}}
                                            InputProps={{...params.InputProps, disableUnderline: true, style:{fontSize:"12px"}}}
                                            variant="standard"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} className={classes.field}>
                                <Tooltip title={"Multi-select"}>
                                    <FormatListBulleted className={classes.icon}/>
                                </Tooltip> 
                                Label
                                <p style={{color:"#8C8C8C", fontSize:"10px", marginBottom:"10px"}}>
                                    This is the card’s label. 
                                </p>
                                <Autocomplete
                                    classes={{
                                        option: classes.optionStyles
                                    }}
                                    value={labelValue}
                                    multiple
                                    options={listOfLabels}
                                    onChange={(event, value) =>{
                                        //Accommodating to some stupid behavior where if you press Enter outside the input box,
                                        //the newly added label is a string therefore it is needed to change the data a little
                                        if(typeof value[value.length - 1] === "string"){
                                            value[value.length - 1] = {label: value[value.length - 1]}
                                        }
                                        this.handleLabelChange(value, cardSelected) 
                                    }}
                                    filterOptions={(options, params) => {
                                        const filtered = filter(options, params);
                                
                                        // Suggest the creation of a new value
                                        if (params.inputValue !== '') {
                                            filtered.push({
                                                inputValue: params.inputValue,
                                                label: `Add "${params.inputValue}"`,
                                            });
                                        }

                                        filtered.length > 0  && filtered.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()))
                                        return filtered;
                                    }}
                                    size="small"
                                    getOptionLabel={(option) => {
                                        // Value selected with enter, right from the input
                                        if (typeof option === 'string') {
                                          return option;
                                        }
                                        // Add "xxx" option created dynamically
                                        if (option?.inputValue) {
                                          return option.inputValue;
                                        }
                                        // Regular option
                                        return option.label;
                                    }}
                                    getOptionSelected={(option, value) => option.label === value.label}
                                    disableCloseOnSelect
                                    disablePortal
                                    underlinestyle={{display: 'none'}}
                                    selectOnFocus
                                    clearOnBlur
                                    renderTags={(value, getTagProps) =>
                                        value.map((option, index) => (
                                            <Chip
                                                variant="default"
                                                label={option.label}
                                                style={{
                                                    background:"#686868", 
                                                    borderRadius:"5px", 
                                                    border:"none", 
                                                    color:"white",
                                                }}
                                                size="small"
                                                {...getTagProps({ index })}
                                            />
                                        ))
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            style={{background:"#FAF7EA", padding:"5px", fontSize:"12px", borderRadius:"5px", border:"1px solid #D1D1D1"}}
                                            InputProps={{...params.InputProps, disableUnderline: true, style:{fontSize:"12px"}}}
                                            variant="standard"
                                            placeholder={labelValue.length > 0 ? "" : "Type for label"}
                                        />
                                    )}
                                    renderOption={(option,state)=>(
                                        <Grid container alignItems="center">
                                            <Grid item xs={8}>
                                                {option.label}
                                            </Grid>
                                        </Grid>
                                    )}
                                    freeSolo
                                />
                            </Grid>
                            <Grid item xs={12} className={classes.field} style={{position:"relative"}}>
                                <Tooltip title={"Text"}>
                                    <TextFields className={classes.icon}/>
                                </Tooltip> 
                                Content
                                <p style={{color:"#8C8C8C", fontSize:"10px", marginBottom:"10px"}}>
                                This is the card’s content.
                                </p> 
                                <IconButton 
                                onClick={(event)=>{this.setState({contentAnchorEl:event.currentTarget})}} 
                                style={{position:"absolute", 
                                        color:"#4F4F4F", 
                                        outline:"none", 
                                        top:"0", 
                                        right:"0"}}>
                                    <MoreVert style={{fontSize:"16px"}}/>
                                </IconButton>

                                <Dropzone 
                                       onDrop={(files)=>{this.props.onDrop(files,cardSelected.libID)}}
                                        noClick={true} 
                                        noKeyboard={true} 
                                    > 
                                    {({getRootProps, getInputProps, isDragActive }) => (
                                        <div {...getRootProps()} >
                                            <input {...getInputProps()} />
                                            {isDragActive && contentValue.length === 0 ?
                                            <p className={classes.dropZone}>
                                                <b>Drag and drop image here</b>
                                            </p>
                                            :
                                            <>
                                                <div className={classes.imgHolderWrapper}  style={{display:imgUrl ? "block" : "none"}}>
                                                <div className={classes.imgHolder}>
                                                    <IconButton onClick={()=>removeImg(cardSelected.libID)} className={classes.imgDelete}>
                                                        <CloseRounded style={{fontSize:"12px"}}/>
                                                    </IconButton>
                                                    <img 
                                                        style={{width:"90%", margin:"auto"}} 
                                                        alt="img" 
                                                        src={`${config.backend.uri}/static/uploads/${imgUrl}`}
                                                    />
                                                </div>
                                                </div>
                                                <TextField
                                                    multiline
                                                    onPaste={(event)=>{if(event.clipboardData.types.includes('Files')){onImgPaste(event)}}}
                                                    style={{display:imgUrl ? "none" : "block", background:"#FAF7EA", padding:"5px", width:"100%", fontSize:"12px", borderRadius:"5px", border:"1px solid #D1D1D1"}}
                                                    InputProps={{disableUnderline: true, style:{fontSize:"12px"}}}
                                                    variant="standard"
                                                    value={contentValue}
                                                    placeholder="Write your story here..."
                                                    rows={4} 
                                                    rowsMax={10} 
                                                    inputProps={{maxLength: 150}}
                                                    onChange={(event) =>this.handleContentChange(event, cardSelected)}
                                                    onKeyUp={()=>{
                                                        clearTimeout(typingTimer);
                                                        typingTimer = setTimeout(this.typingFunc(cardSelected), doneTypingInterval)
                                                    }}
                                                    onKeyDown={()=>{
                                                        clearTimeout(typingTimer);
                                                    }}
                                                    onBlur={() =>{
                                                        socket.emit("Game Object Parse Links", {selectedIDs: this.props.selectedIDs})
                                                    }}
                                                />
                                            </>
                                            }
                                        </div>
                                    )}
                                </Dropzone>
                                <p id="words" 
                                    style={{
                                    display:"inline-block",
                                    fontSize:"10px",
                                    margin:"0",
                                    marginTop:"0px",
                                    opacity:1,
                                    color:"#8C8C8C"}}>
                                    {imgUrl ? 0 : 150 - contentValue.length}
                                </p>
                            </Grid>
                        </Grid>
                    </div>
                    
                    <Popper 
                        style={{position:"relative", background:"white", padding: "1.5rem"}} 
                        open={open} 
                        anchorEl={anchorEl}
                        transition
                    >
                        <p style={{fontSize:"12px", color:"#4f4f4f"}}>Category color</p>
                        <ClickAwayListener onClickAway={this.closePopper}>
                            <CirclePicker
                                color={cardSelected?.category ? categoryColorMap.get(cardSelected.category) : '#C4C4C4'}
                                // width={} circleSize={} circleSpacing={} onSwatchHover={(c,e)=>{console.log(c)}}
                                colors={['#6246EA', '#2CB67D', '#4FC4CF', '#FF8BA7', '#F25F4C', '#4F4F4F', '#9656A1', '#078080', '#3DA9FC', '#E53170', '#FF8E3C', '#C4C4C4']}
                                onChangeComplete={(c,e)=>{
                                    if(cardSelected.category == null){
                                        if(this.selectedCategoryEllipsis.substring(0,5) === 'Add "'){
                                            this.selectedCategoryEllipsis = this.selectedCategoryEllipsis.slice(6, this.selectedCategoryEllipsis.length)
                                            this.selectedCategoryEllipsis = this.selectedCategoryEllipsis.slice(0, this.selectedCategoryEllipsis.length - 1)
                                        }
                                        this.changeCardInLib(cardSelected.libID, "Category", [{category: this.selectedCategoryEllipsis}])
                                    }
                                    const {changeColor} = this.props
                                    changeColor(cardSelected.category, c.hex)
                                    this.closePopper();
                                }}
                            />
                        </ClickAwayListener>
                    </Popper>

                    <Menu
                        id="simple-menu"
                        anchorEl={contentAnchorEl}
                        PaperProps={{style: { fontFamily:"Montserrat, sans-serif", color:"#4F4F4F", border:"0.5px solid #c4c4c4"}}}  
                        keepMounted
                        open={Boolean(contentAnchorEl)}
                        onClose={()=>{this.setState({contentAnchorEl:null})}}
                    >
                        <MenuItem style={{fontSize:"12px"}} component="label" disabled={Boolean(contentValue)}>
                            <ImageOutlined style={{fontSize:"16px", marginRight:"5px"}}/> 
                            Upload Image
                            <input
                                onChange={(event)=>{onImgUpload(event, cardSelected.libID); this.setState({contentAnchorEl: null})}}
                                type="file"
                                hidden
                            />
                        </MenuItem>
                    </Menu>
                </>
            )
        }
        else{
            return(
                <>
                    <img alt="empty" style={{width:"150px", height:"150px", marginTop:"20px", marginLeft:"18%"}} src={require('../../../element/cat.png')}/>
                    <p className={classes.ownJamEmpty}>
                        Select a card on the canvas or<br/>the library to edit here
                    </p>
                </>
            )
        }
    }

    closePopper = () =>{
        this.setState({
            anchorEl: null,
            open: false
        })
    }

    handleLabelChange = (value, cardSelected) =>{
        //Due to the nature of how MUI Autocomplete works, if users types in a new label,
        //check inputValue rather than the label itself and new labels are always at the end 
        if(value[value.length - 1]?.inputValue){
           value[value.length - 1].label = value[value.length - 1].inputValue 
           delete value[value.length - 1].inputValue
        }

        this.changeCardInLib(cardSelected.libID, "Label", value)
    }

    handleCategoryChange = (value, cardSelected) =>{
        if(value[value.length - 1]?.inputValue){
            value[value.length - 1].category = value[value.length - 1].inputValue 
            delete value[value.length - 1].inputValue
         }
        this.changeCardInLib(cardSelected.libID, "Category", value)
    }

    handleContentChange = (event) =>{
        this.setState({
            contentValue: event.target.value
        })
    }

    typingFunc = (cardSelected) => {
        const {contentValue} = this.state
        this.changeCardInLib(cardSelected.libID, "Content", contentValue)
    }

    changeCardInLib = (libID, type, value) =>{
        const {changeCard} = this.props
        const change = {
            type: type, 
            data: value
        }
        changeCard(libID, change)
    }

    handleClick = (event) => {
        this.setState({
            anchorEl:event.currentTarget
        });
    };
}

export default withStyles(styles)(GameEditor)

