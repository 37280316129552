import React from 'react'
import '../app/app.css'
import FrontPage from '../component/game/user_interface/frontPage'
import {isSessionUser} from '../utility/function'
import FrontPageOld from '../component/game/user_interface/frontPageOld'


class Home extends React.Component {

    render(){

        if(window.location.pathname === "/old"){
            return(
                <>
                    <FrontPageOld isLoggedIn={isSessionUser()}/>
                </>
                
            )
        }else{
            return(
                <>
                    <FrontPage isLoggedIn={isSessionUser()}/>
                </>
            )
        }
    }

}


export default Home;
