import React from 'react';
import {
    Divider,
    Fade,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    Popper,
} from '@material-ui/core';
import { MailOutline, BookOutlined } from '@material-ui/icons';

import { IHelpPopoverProps } from './help-popover.interface';
import { IVersionInfo } from '../../../components/version-info/version-info.interface';
import frontpageConstants from '../../../page/frontpage/constants.json';
import { versionInfoApi } from '../../../components/version-info/version-info.api';
import { VersionInfo } from '../../../components/version-info/version-info';

import classes from './helpPopover.module.scss';

const versionInfoDenylist = ['main', 'staging'];

export const HelpPopover = (props: IHelpPopoverProps) => {
    const { id, anchorEl } = props;
    const [versionInfo, setVersionInfo] = React.useState<IVersionInfo | null>(null);

    React.useEffect(() => {
        if (!versionInfo) {
            versionInfoApi.get().then((data) => {
                if (
                    data?.build
                    && !versionInfoDenylist.some((env) => data.build.startsWith(`${env}.`))
                ) {
                    setVersionInfo(data);
                }
            });
        }
    }, [versionInfo]);

    return (
        <Popper
            id={id}
            placement="top-end"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            transition
        >
            {({ TransitionProps }) => (
                <Fade {...TransitionProps}>
                    <List
                        component="nav"
                        aria-label="help popper"
                        className={classes.helpPopoverList}
                    >
                        <ListItem
                            button
                            component="a"
                            href={frontpageConstants.links.faq.src}
                            className={classes.helpPopoverListItemButton}
                        >
                            <ListItemIcon
                                style={{
                                    minWidth: '20px',
                                    transform: 'scaleX(-1)',
                                }}
                            >
                                <BookOutlined />
                            </ListItemIcon>
                            <ListItemText
                                primary="Help Centre"
                                style={{ marginLeft: '0.7rem' }}
                            />
                        </ListItem>
                        <Divider />
                        <ListItem
                            button
                            component="a"
                            href={frontpageConstants.links.support.src}
                            className={classes.helpPopoverListItemButton}
                        >
                            <ListItemIcon style={{ minWidth: '35px' }}>
                                <MailOutline />
                            </ListItemIcon>
                            <ListItemText primary="Send Email" />
                        </ListItem>
                        {versionInfo && <VersionInfo info={versionInfo} />}
                    </List>
                </Fade>
            )}
        </Popper>
    );
};
