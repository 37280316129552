let backend = {
  uri: process.env.REACT_APP_BACKEND_URI,   /** || "http://192.168.0.116:4000" **/
  ws: process.env.REACT_APP_WEBSOCKET_URI   /** || "ws://192.168.0.116:4000" **/
}

if(window.location.host.includes("staging")){
  backend.uri = "https://staging.jamit.io"
  backend.ws = "wss://staging.jamit.io"
}

// if(true){ // testing purpose
//   backend.uri = "https://staging.jamit.io"
//   backend.ws = "wss://staging.jamit.io"
// }

module.exports = {
  backend
}