import session from '../model/session'
import axios from 'axios';
import config from '../config/backend'


export const ctrl_cmd_mapper = (e) => {
    let os = getPlatform();

    if(os === "Windows"){
        if(e.ctrlKey){
            return true
        }
    }

    if(os === "MacOS"){
        if(e.metaKey){
            return true
        }
    }

    return false;
}

export const getPlatform = () => {
    var n = "Unknown OS";
    if (navigator.appVersion.indexOf("Win")!==-1) n = "Windows";
    if (navigator.appVersion.indexOf("Mac")!==-1) n = "MacOS";
    if (navigator.appVersion.indexOf("X11")!==-1) n = "UNIX";
    if (navigator.appVersion.indexOf("Linux")!==-1) n = "Linux";
    return n
}

export const replaceWhiteSpace = (n) => {
    let result = n.replace(/ /g,"-");
    return result
}

export const getPlayerFromPlayerList = (list,index,factor) => {
    let l = list.length

    if(factor === 0){
        return list[index].uid
    }

    if(factor === -1){
        if(index + factor < 0){
            return list[l-1].uid
        }else{
            return list[index + factor].uid
        }
    }

    if(factor === 1){
        if(index + factor >= l){
            return list[0].uid
        }else{
            return list[index + factor].uid
        }
    }
}

export const getUserId = () => {
    session.fetchLocal();
    const user = session.getUser();
    return user.store._id
}

export const getEmail = () => {
    session.fetchLocal();
    const user = session.getUser();
    return user.store.email
}

export const getGeneralUserId = (isSpectate, spectateInfo) => {
    if(spectateInfo){
        return spectateInfo.spectator_id
    }else{
        return getUserId();
    }
}

export const getUsername = () => {
    session.fetchLocal();
    const user = session.getUser();
    return user.store.name.first
}


export const getTopic = () => {
    session.fetchLocal();
    const topics = session.getTopic();
    return topics;
}



export const dirSlugGenerator = (name,num) => {
    var result = name + "-" + num
    return result;
}


export const isLeader = (userId,createdBy) => {
    if(userId === createdBy){
        return true
    }
    return false
}


export const generateIndex = (arr, userID) => {
    console.log(arr)
    if(isEmpty(arr)){
        const index = userID + "-" + 0
        console.log("index: ", index)
        return index

    }else{
        var last = arr[arr.length-1]
        var newIndex = parseInt(last.index.slice(last.index.indexOf("-")+1,last.index.length)) + 1
        const index = userID + "-" + newIndex
        console.log("index: ", index)
        return index
    }
}

export const isSessionUser = () => {
    session.fetchLocal();
    const user = session.getUser();
    
    if (user._id) {
        return true
    }else{
        return false
    }
}

export const emailChecker = (email) => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)){
        return true
    }else{
        return false
    }
} 

export const isEmpty = (arr) => {
    if(arr.length === 0){
        return true
    }
    return false;
}

export const generateString = (length) => {
    const characters ='ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    const charactersLength = characters.length;
    for ( let i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
}

export const hexToHSL = (hex) => {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

    var r = parseInt(result[1], 16);
    var g = parseInt(result[2], 16);
    var b = parseInt(result[3], 16);

    r /= 255; g /= 255; b /= 255;
    var max = Math.max(r, g, b), min = Math.min(r, g, b);
    var h, s, l = (max + min) / 2;

    if(max == min){
        h = s = 0; // achromatic
    } else {
        var d = max - min;
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
        switch(max) {
            case r: h = (g - b) / d + (g < b ? 6 : 0); break;
            case g: h = (b - r) / d + 2; break;
            case b: h = (r - g) / d + 4; break;
        }
        h /= 6;
    }

    s = s*100;
    s = Math.round(s);
    l = l*100;
    l = Math.round(l);
    h = Math.round(360*h);

    return [h,s,l]
}

export const isLocal = () => {
    if(window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1"){
        return "localhost:8080/"
    }else{
        return 'https://client.jamit.io/'
    }
}

export const isObjEmpty = (obj) => {
    return Object.keys(obj).length === 0;
}


// export const generatePasswordHash = (password) => {
//     var salt = bcrypt.genSaltSync(10);
//     var hash = bcrypt.hashSync(password, salt);
//     return hash;
// }


