import * as React from 'react';
import { withApollo } from 'react-apollo';

import { ICardStackProps } from './card-stack.interface';
import { ICard } from '../card/card.interface';
import socket from '../../api/socket';
import { GAME_UPDATE_OBJECT_FLIP } from '../../api/graphql-mutation';
import { Card } from '../card/card';

const CardStack = (props: ICardStackProps) => {
  const {
    gid,
    stackId,
    isManage,
    flipped = false,
    // imgData,
    categoryColorMap,
    receiveTargetTextfield,
    formDeck,
    setSubDragging,
    bringForward,
    bringBackward,
    popCard,
    updateCardContent,
    quitManage,
    setManage,
    client: apollo,
    children: cards,
  } = props;
  const [flipped_, setFlipped] = React.useState<boolean>(flipped);

  const flip = (): void => {
    const nextState = !flipped_;
    setFlipped(nextState);

    apollo?.mutate({
      mutation: GAME_UPDATE_OBJECT_FLIP,
      variables: {
        _id: gid,
        objId: stackId,
        flip: nextState,
      }
    });

    socket.emit('Canvas Flip Object', {
      id: stackId,
      flip: nextState,
    });
  };

  return (
    <>
      {cards.map((currentCard: ICard, index: number) => {
        const {
          id,
          category,
        } = currentCard;
        const cardKey = `${currentCard + currentCard.id}`;

        return (
          <Card
            key={cardKey}
            stack
            obj={currentCard}
            id={id}
            stackId={stackId}
            isManage={isManage}
            quitManage={quitManage}
            setManage={setManage}
            stackIndex={index}
            bringForward={bringForward}
            bringBackward={bringBackward}
            popCard={popCard}
            flipped={flipped_}
            flipStackFn={flip}
            categoryColor={categoryColorMap.get(category)}
            // imgData={imgData}
            updateCardContent={updateCardContent}
            receiveTargetTextfield={receiveTargetTextfield}
            formDeck={formDeck}
            setSubDragging={setSubDragging}
          />
        );
      })}
    </>
  );
};

export default withApollo(CardStack);
