//import '../../wdyr'
//Node modules
import React from 'react';
import { withStyles, Tooltip} from '@material-ui/core';
import Draggable from 'react-draggable';
import {withApollo} from 'react-apollo'
import _ from 'lodash'

//Other modules
import {GAME_OBJECT_POSITION} from '../../../api/graphql-mutation'
import styles from '../user_interface/assets/cardStyle'
import socket from '../../../api/socket'
import CanvasState from '../state/canvasState'
import {CanvasUtil} from '../helper/gameCanvasHelper'
import Cards from './deckCard'
import {getUserId} from '../../../utility/function'
import {GAME_UPDATE_OBJECT_FLIP} from '../../../api/graphql-mutation'

class DraggableDeck extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            id: null,
            attachment: null,
            interrupt: null,
            origin: null,
            edited:false,
            pin:false,
            vote: false,
            anchorEl: null,
            type: "stack",
            category: "Stack",
            labels: ["Stack"],
            time: null,
            objs: null,
            x: null,
            y: null,
            flipStack: false,

            loaded: false,
            isManage: null,

        }
        this.snapping = false
        this.snappingPos = {
            x: 0,
            y: 0
        }

        //this.objRef = null
    }

    componentDidMount(){
        const {obj} = this.props
        this.setState({
            x: obj.pos.x ?? 1,
            y: obj.pos.y ?? 1,
            id: obj.objId,
            flipStack: obj.flip,
            time: obj.time,
            type: obj.type,
            origin: obj.origin,
            vote: obj.vote,
            objs: obj.objs,

            loaded: true
        }, ()=>{
            //CanvasState.objRefMethods.set(this.state.id, this)
        });

        socket.on("Sync Stack", (data) =>{
            const {objId, objs} = data
            if(this.state.id === objId){
                this.setState({
                    objs: objs
                });
            }
        })

        socket.on("Game Card Position Update", (data)=>{
            const {id} = this.state
            const objId = data.id

            if(id === objId){
                this.setState({
                    x: data.x,
                    y: data.y,
                })
            }
        })

        socket.on("Game Card Position Sync", (data) =>{
            const {id} = this.state
            const objId = data.id

            if(id === objId){
                this.setState({
                    x: data.x,
                    y: data.y
                });
            }
        })

        socket.on("Canvas Flip Object", data => {
            const {id} = this.state
            const objId = data.id;
            const flip = data.flip

            if(id === objId){
                this.setState({
                    flipStack: flip
                })
            }
        })
    }

    componentWillUnmount(){
        //socket.off();
        //CanvasState.objRefs.delete(this.state.id)
        //CanvasState.objRefMethods.delete(this.state.id)
    }

    render(){
        const {classes, scale, canvasCoordinates, cursorMode} = this.props
        const {stackable, pinnable, pairable, draggable} = this.props.cardConfig
        const {x, y, id, objs, loaded, category} = this.state

        let uniq = ``
        if(id !== null){
            uniq = id.substring(id.indexOf('-')+1)
        }

        return(
            <>
                {loaded &&
                <Draggable
                    disabled = {!draggable || cursorMode === "Hand" || this.state.isManage}
                    handle = {`.deck${uniq}`}
                    onDrag ={(e,ui)=>{
                        e.preventDefault()
                        e.stopPropagation()
                        this.updatePosition(ui);
                        // CanvasUtil.checkCardStackAndPair(id, scale, canvasCoordinates, category, pairable, stackable)
                    }}
                    onStop ={(e)=>{
                        this.syncPosition(e)
                        if(stackable.includes("Any") || stackable.includes(category)){
                            this.stackingFunc()
                        }
                    }}
                    position = {{x: x, y: y}}
                    scale={scale}
                >
                    {this.createStack(objs, classes,uniq)}
                </Draggable>
                }
            </>
        )
    }

    createStack = (cards, classes,uniq) =>{
        let stack = []

        for(let index=0; index < cards.length; index++){
            stack.push(
                <Cards
                    isManage={this.state.isManage}
                    quitManage={this.quitManage}
                    setManage={this.setManage}
                    key = {`${cards[index] + cards[index].id}`}
                    stackIndex = {index}
                    stack = {this.state.category === "Stack" ? true : false}
                    classes = {classes}
                    obj = {cards[index]}
                    openMenu = {this.openMenu}
                    closeMenu = {this.closeMenu}
                    bringForward={this.bringForward}
                    bringBackward = {this.bringBackward}
                    popCard = {this.popCard}
                    flipStack = {this.state.flipStack}
                    flipStackFn = {this.flipStackFn}
                    categoryColor = {this.props.categoryColorMap.get(cards[index].category)}
                    stackId = {this.props.obj.objId}
                    imgData = {this.props.imgData}
                    updateCardContent = {this.updateCardContent}
                    receiveTargetTextfield = {this.props.receiveTargetTextfield}
                    objectType = {this.state.type}
                    formStack = {this.props.formStack}
                    shuffle = {this.shuffle}
                    setSubDragging = {this.props.setSubDragging}
                    clearMultiSelect = {this.props.clearMultiSelect}
                />
            )
        }

        return (
            //This is needed where the height is dynamically calculated from the number of Cards
            //so that the pairing/stacking handler can work as expected
            <div
                //ref={ref => ref && CanvasState.objRefs.set(this.state.id, ref)}
                style={{position:"absolute", height:`${285 + (70 * stack.length)}px`,}}
            >
                <div className={`deck${uniq}`} style={{position:"absolute", height:"100px", width:"100%", top:"250px", zIndex:"100"}}></div>
                {stack}
            </div>
        )
    }

    setManage = (stackIndex) => {
        console.log(stackIndex)
        this.setState({
            isManage: stackIndex,
        })
    }

    quitManage = () => {
      const { isManage } = this.state;
      if (isManage) {
        this.setState({ isManage: null });
      }
    }

    updateCardContent = (stackIndex, content) =>{
        this.setState(state =>{
            const objs = state.objs

            objs[stackIndex].content = content

            return{
                objs
            }
        }, () =>{
            socket.emit("Sync Stack", {objId: this.state.id, objs: this.state.objs})
            this.props.updateStackObject(this.state.id, {objs: this.state.objs}, "Objects")
        })
    }

    willSnap = (newCoords) =>{
        this.pairingFunc(true, {x: newCoords.x, y: newCoords.y})
    }

    willNotSnap = () =>{
        this.pairingFunc(false, {x: null, y: null})
    }

    willStack = (dragCardState, touchCardState) =>{
        this.dragCardState = dragCardState
        this.touchCardState = touchCardState
    }

    willNotStack = () =>{
        this.dragCardState = null
        this.touchCardState = null
    }

    stackingFunc = () =>{
        this.dragCardState && this.touchCardState && this.props.addStackObject(this.dragCardState, this.touchCardState)
    }

    pairingFunc = (willSnap, newCoords) =>{
        this.snapping = willSnap
        this.snappingPos = {
            x: newCoords.x,
            y: newCoords.y
        }
    }

    updatePosition = (ui) =>{
        const {x, y} = this.state

        let currentX = x + ui.deltaX
        let currentY = y + ui.deltaY

        this.setState({
            x: currentX,
            y: currentY
        }, ()=>{
            socket.emit("Game Card Position Update",{id: this.state.id, x: this.state.x, y: this.state.y, color: this.state.color})
        });

    }

    syncPosition = (e) => {
        //After dragging cards
        e.preventDefault()
        const {x, y} = this.state

        //Pairing
        this.setState({
            x: this.snapping ? this.snappingPos.x : x,
            y: this.snapping ? this.snappingPos.y : y,
        }, ()=>{
            this.snapping = false
            this.snappingPos.x = null
            this.snappingPos.y = null
            this.props.client.mutate({
                mutation: GAME_OBJECT_POSITION,
                variables: {
                    _id: this.props.gid,
                    objId: this.props.obj.objId,
                    x: this.state.x,
                    y: this.state.y
                }
            }).then(()=>{
                socket.emit("Game Card Position Sync",{id: this.state.id, x: this.state.x, y: this.state.y})
            })
        });
    }

    flipStackFn = () => {
        this.setState(prevState =>({
            flipStack: !prevState.flipStack
        }), () =>{
            this.props.client.mutate({
                mutation: GAME_UPDATE_OBJECT_FLIP,
                variables: {
                    _id: this.props.gid,
                    objId: this.state.id,
                    flip: this.state.flipStack
                }
            })
            socket.emit("Canvas Flip Object", {id: this.state.id, flip: this.state.flipStack})
        });
    }

    addObj = (objs) =>{
        this.setState({
            objs: objs
        }, () =>{
            socket.emit("Sync Stack", {objId: this.state.id, objs: this.state.objs})
        });
    }

    shuffle = () => {
        this.setState(prevState => ({
            objs: _.shuffle(prevState.objs)
        }),()=>{
            socket.emit("Sync Stack", {objId: this.state.id, objs: this.state.objs})
        })
    }

    bringForward = (e, i) =>{
        //Need to pass events to register the click 🙄
        let cards = this.state.objs

        if(i !== cards.length-1 ){
            let temp = cards[i+1] //temp = card 2
            cards[i+1]=cards[i] //card 2 = card 1
            cards[i]=temp //card 1 = temp
            cards[i+1].anchorEl = null

            this.setState({
                objs: cards
            }, ()=>{
                //Emit to stackCard
                socket.emit("Sync Stack", {objId: this.state.id, objs: this.state.objs})
            })
        }
    }

    bringBackward = (e, i) =>{
        //Need to pass events to register the click 🙄
        let cards = this.state.objs
        if(i !== 0 ){
            let temp = cards[i-1] //temp = card 1
            cards[i-1]=cards[i] //card 1 = card 2
            cards[i]=temp //card 2 = temp
            cards[i-1].anchorEl = null

            this.setState({
                objs: cards
            }, ()=>{
                //Emit to stackCard
                socket.emit("Sync Stack", {objId: this.state.id, objs: this.state.objs})
            })

        }
    }

    checkPopCard = (e,index) =>{
        let card = this.state.objs[index]
        let uid = getUserId()
        const {x, y, scale} = this.props.canvasData

        let obj = {
            objId: `${uid}-${this.props.latestID}`,
            origin: card.origin,
            flip: true/*this.state.flipStack*/,
            edited: card.edited,
            pin: card.pin,
            time: card.time,
            type: card.type,
            status: card.status,
            label: card.labels ? card.labels : card.label,
            category: card.category,
            content: card.content,
            submitted: card.category === "Blank" ? card.submitted : false,
            pos: {
                // x: this.state.x + 30,
                // y: this.state.y + 30
                x: (-x + e.clientX) * (1/scale),
                y: (-y + (e.clientY-112)) * (1/scale)
            },
            anchorEl: null,
            imgUrl: card.imgUrl,
            pairDir: null,
            paired: {
              left: null,
              right: null,
              up: null,
              down: null
            },
        }

        return obj
    }

    popCard = (e, index) => {
        //Need to pass events to register the click 🙄
        let cards = this.state.objs
        let uid = getUserId()
        let {x,y,scale} = this.props.canvasData

        // get position of stacks
        let oriX = this.state.x
        let oriY = this.state.y

        if(cards.length > 2){
            let obj = this.checkPopCard(e,index)
            this.props.addObject(obj)
            cards[index].anchorEl = null
            cards.splice(index, 1)

            this.setState({
                isManage: false,
                objs: cards
            }, ()=>{
                //Emit to stackCard
                socket.emit("Sync Stack", {objId: this.state.id, objs: this.state.objs})
            });
        }
        else{
            let objArr = []

            for(let i = 0; i < cards.length; i++){

                let card = this.state.objs[i]
                let space = 100 * i;
                let obj = {
                    objId: `${uid}-${(parseInt(this.props.latestID) + i).toString()}`,
                    origin: card.origin,
                    flip: true /*this.state.flipStack*/,
                    edited: card.edited,
                    pin: card.pin,
                    time: card.time,
                    type: card.type,
                    status: card.status,
                    label: card.labels ? card.labels : card.label,
                    category: card.category,
                    submitted: card.category === "Blank" ? card.submitted : false,
                    content: card.content,
                    // pos: {
                    //     // x: this.state.x + (i % 2 ? -1 * 50 * (i + 1) : 50 * (i + 1)),
                    //     // y: this.state.y + (i % 2 ? -1 * 50 * (i + 1) : 50 * (i + 1))
                    //     x: (-x + (e.clientX+space)) * (1/scale),
                    //     y: (-y + (e.clientY-112)) * (1/scale)
                    // },
                    pos: index === i ?
                        {
                            x: (-x + e.clientX) * (1/scale),
                            y: (-y + (e.clientY-112)) * (1/scale)
                        }
                        :{
                            x: oriX,
                            y: oriY,
                        }
                    ,
                    anchorEl: null,
                    imgUrl: card.imgUrl,
                    pairDir: null,
                    paired: {
                      left: null,
                      right: null,
                      up: null,
                      down: null
                    },
                }
                objArr.push(obj)
            }

            this.props.addMultiObject(objArr, this.state.id)
        }
    }

    openMenu = (i, e) => {
        const objsClone = this.state.objs
        objsClone[i].anchorEl = e.currentTarget
        this.setState({
            objs: objsClone
        })
    }

    closeMenu = (i) => {
        const objsClone = this.state.objs
        objsClone[i].anchorEl = null
        this.setState({
            objs: objsClone
        })
    }
}

export default withApollo(withStyles(styles)(DraggableDeck))
