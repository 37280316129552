import React , { Component } from 'react'
import {withStyles} from '@material-ui/core'
import Login from '../component/game/user_interface/login'
import GameTopPanel from '../component/game/user_interface/gameTopPanel'
import session from '../model/session'
import axios from 'axios'
import config from '../config/backend'
import { withApollo } from 'react-apollo'
import {Redirect} from 'react-router-dom'
import {isSessionUser} from '../utility/function'

const styles = {
    container: {
        position:"fixed",
        height:"100%", 
        width:"100%",
        background:"#F2F2F2",
        fontFamily:"arial",
    },
}

class Signin extends Component {

    constructor(props){
        super(props);
        this.state = {

            progress: "login",

            email: "",
            name: "",
            password: "",
            isNewUser: null,

            user: null,

            error: false
        }
    }

    render(){
       const {classes, location} = this.props 
       const {error} = this.state

       const redirect = this.props?.location?.state?.redirect
       const skipPassword = this.props?.location?.state?.skipPassword
       const joining = this.props?.location?.state?.joining
       const type = this.props?.location?.state?.type
       const jbIndex = this.props?.location?.state?.jbIndex
       const selectedJB = this.props?.location?.state?.selectedJB
       
       const redirectProps = redirect ? redirect : "/"

       if(isSessionUser()){
            return( 
                <Redirect 
                    to={{
                        pathname: redirectProps,
                        state: {
                            skipPassword: skipPassword,
                            joining: joining,
                            type: type,
                            jbIndex: jbIndex,
                            selectedJB: selectedJB
                        }
                    }}
                />
            )
       }else{
            return (
                <div className={classes.container}>
                    <GameTopPanel reqLogin={false}/>
                    {this.state.progress === "login" && (
                        <Login
                            type={type}
                            jbIndex={jbIndex}
                            selectedJB={selectedJB}
                            joining={joining}
                            skipPassword={skipPassword}
                            redirectProps={redirectProps}
                            action={location?.state?.action}
                            error={error}
                            emailValue={this.state.email}
                            passwordValue={this.state.password} 
                            handleSignIn={this.handleSignIn} 
                            handleChange={this.handleChange}
                        />
                    )}
                </div>
            )
       }

    }

    handleChange = (e) => {
        let n = e.target.name
        this.setState({
            [n]: e.target.value
        })
    }

    // Existing User 
    handleSignIn = () => {
        axios.post(`${config.backend.uri}/signin`,{
            email: this.state.email,
            password: this.state.password
        }).then((res)=>{
            session.setUser(res.data.user);

            this.setState({
                user: res.data.user
            })
        }).catch(() =>{
            this.handleError()
        })
    }

    //Error
    handleError = () =>{
        this.setState({
            error: true
        }, () =>{
            setTimeout(()=>{
                this.setState({
                    error: false
                });
            }, 3000)
        });
    } 
}


export default withApollo(withStyles(styles)(Signin))
