import React from 'react';
import {withStyles, Avatar, InputBase, Button, Grid} from '@material-ui/core';
import GameTopPanel from './gameTopPanel'
import socket from '../../../api/socket'

const styles = {
    container: {
        position:"fixed",
        height:"100%", 
        width:"100%",
        background:"#F2F2F2",
        fontFamily:"arial",
    },

    box:{
        margin:"auto",
        height:"90%",
    },

    field:{
        display:"block",
        padding:"0 5px",
        width:"15rem",
        background:"#FAF7EA",
        border:"1px solid #D1D1D1",
        borderRadius:"5px",
        marginBottom:"1em",
    },

    buttons:{
        width:"15rem",
        background:"#D1D1D1",
        color:"#FFFFFF",
    },

    avatar:{
        width:"7rem",
        height:"7rem"
    },
    
    header:{
        fontWeight:"bold",
        fontSize:"1rem",
        textAlign:"center"
    },

    highlight:{
        fontWeight:"bold",
        fontSize:"1rem",
        color:"#4173D6",
        margin:"0"
    }
}
class GetPassword extends React.Component {
    constructor(props) { //holds data to be parsed into the page
        super(props);
        this.state = {
            password: "",
        };
    }


    componentDidMount(){
        socket.on("Game Start", ()=>{
            window.location.reload();
        })
    }

    onChange(event){
        if(event.target.value===""){
            document.getElementById("button").style.background = "#D1D1D1"
        }else{
            document.getElementById("button").style.background = "#FDAF2A"
        }
    }

    handleChange(event){
        this.setState({
            password: event.target.value
        })
    }

    render() {
        
        const {classes, name, checkPassword, password } = this.props
        return (
            <div className={classes.container}>
                <GameTopPanel/>
                <Grid container justify="center" alignItems="center" className={classes.box}>
                    <Grid direction="column" justify="center" alignItems="center" spacing={2} container>
                        <Grid item>
                            <Avatar className={classes.avatar} src={require('../../../element/man2.png')}></Avatar>
                        </Grid>
                        <Grid item className={classes.header}>
                            Oh no! A password is set for <p className={classes.highlight}>{name}</p>
                        </Grid>
                        <Grid item>
                            <form onSubmit={(e)=>{e.preventDefault();}} noValidate autoComplete="off">
                                <InputBase className={classes.field} onChange={(e)=>{this.onChange(e); this.handleChange(e)}}
                                    autoFocus
                                    type="password"
                                    value={this.state.password}
                                    name="password"
                                    placeholder="password"
                                    inputProps={{style: {fontSize: "0.875rem", fontFamily:"arial", textAlign:"center"}}}
                                />
                                <Button type="submit" onClick={()=>{checkPassword(this.state.password, password)}}  className={classes.buttons} id="button"> 
                                    Join Jam
                                </Button> 
                            </form>
                            
                        </Grid>
                        
                    </Grid>

                </Grid>
            </div>
        );
    }
}

export default withStyles(styles)(GetPassword)