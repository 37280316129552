import { gql } from 'apollo-boost';

export const GET_JAMBUILDER_BY_ID = (jbid) => gql`
  query{
    jamBuilderById(_id: "${jbid}"){
      _id
      name
      createdBy{
        _id
        name{
          first
        }
      }
      graph
      configIndex
      deckEndpoint
      templateType
      general
      image
      deck
    }
  }



`



export const GET_USER_BY_TOKEN = (token) => gql`
  query{
    userMany(filter: {reset_token: "${token}"}){
      _id
      name{
        first
        last
      }
    }
  }
`


export const GET_GAME_BY_ID = (gid) => gql`
  query{
    gameById(_id: "${gid}"){
      _id
      name
      createdBy{
        _id
        name{
          first
        }
      }
      data
      status
      automation
      participants
      password
      inhand
      deck
      yamlEndpoint
      gameData
      tppData{
        playerPermissions
        currentPhase
        currentTurnStruct
      }
      transcript
      build
      builderID
    }
  }
`

export const HOME_PAGE_QUERY = (uid) => gql`

query{
  gameMany{
    _id
    name
    automation
    createdBy{
      _id
      name{
        first
      }
    }
    participants
    protected
  }
  
  userById(_id: "${uid}"){
    _id
    name{
      first
      last
    }
  }

  jamBuilderMany(filter:{createdBy: "${uid}"}){
    _id
    name
    createdBy{
      name{
        first
      }
    }
    status
    configIndex
    image
  }
}


`;

export const HOME_PAGE_JAMBUILDER_QUERY = `
  query($uid: MongoID!){
    jamBuilderMany(
      filter:{
          OR:[
            {general:{privacy: "Public"}},
            {createdBy:$uid}
          ]
       }
    )
    {
        name
        createdBy{
          name{
            first
          }
          _id
        }
        graph
        password
        interpreterMessage
        configIndex
        status
        general
        deckEndpoint
        templateType
        _id
        image
    }
  }
`

export const HOME_PAGE_GAME_QUERY = `
  query{
    gameMany(filter:{build: null}){
      _id
      name
      automation
      createdBy{
        _id
        name{
          first
        }
      }
      participants
      protected
      data
      status
    }
  }
`

export const GET_GAME_MANY = gql`
  query{
    gameMany{
      _id
      name
      automation
      createdBy{
        _id
        name{
          first
        }
      }
      participants
      protected
    }
  }
`
export const USER_RESET_EMAIL = (email) => gql`
    query{
      userResetEmail(email: "${email}"){
        _id
        email
      }
    }
`

export const GET_USER_BY_EMAIL = (email) => gql`

  query{
    userMany(filter: {email: "${email}"}){
      _id
      name{
        first
        last
      }
      email
    }
  }
`

export const GET_USER_BY_ID = (uid) => gql`
  query{
    userById(_id: "${uid}"){
      _id
      name{
        first
        last
      }
      email
    }
  }


`;

export const GET_USER_ACTIVITY = (uid) => gql`
  query{
    userById(_id: "${uid}"){
      recentActivity{
        recentJams{
          _id
          date
        }
        recentTemplates{
          _id
          date
        }
      }
    }

    gameMany(filter:{createdBy:"${uid}"}){
      _id
      name
      automation
      createdBy{
        _id
        name{
          first
        }
      }
      participants
      protected
   }
    
  }
`
export const GET_USER_DECKS = (uid) => gql`
  query{
    userDecksById(_id:"${uid}"){
      customDecks{
        _id
        deckDump
        deckName
        yamlEndpoint
        categoryColorMap
        filterSettings
        published
        listOfCategories,
        listOfLabels
      }
    }
  }
`
export const GET_JAMS_TEMPLATES = `
  query jamBuilderFilter($tempList: [MongoID], $jamList: [MongoID]){
    jamBuilderFilter(list: $tempList){
      name
      _id
      createdBy{
        name{
          first
        }
        _id
      }
      graph
      password
      interpreterMessage
      configIndex
      status
      general
      deckEndpoint
      templateType
    }

    gameFilter(list: $jamList){
      protected
      participants
      name
      createdBy{
        _id
        name{
          first
        }
      }
      _id
      data
    }
  }
`

export const GET_USER_MANY = gql`
    query{
        userMany{
          _id
          name{
              first
          }
        }
    }
`;


export const GET_SESSION_BY_ID = (_id) => gql`
    query{
      jamsessionById(_id:"${_id}"){
        _id
        index
        title
        description
        status
        jamtype
        requirement
        participants{
          _id
          name{
            first
          }
        }
        transcript{
          chats{
            owner
            msg
            _id
            pinned
            cid
            ownerName
            history{
              msg
              editedOn
              editedBy
            }
            quote{
              ownerName
              msg
              cid
            }
          }
          discussion{
            owner
            msg
            _id
            pinned
            cid
            ownerName
            history{
              msg
              editedOn
              editedBy
            }
            quote{
              ownerName
              msg
              cid
            }
          }
        }
        parentFruit
        seed
        ideabank{
          _id
          idea
          ideaBy
          ideaByName
          inspiredBy
          inspiredByName
          fromPhase
          fromChatId
          iteration{
            idea
            ideaBy
            ideaByName
            fromChatId
          }
          generatedOn
        }
        createdBy{
          _id
          name{
            first
          }
        }
        phases{
          _id
          index
          name
          instruction
          field
          settings
          jamId
        }


      }        
    }

`;

export const GET_SESSION_MANY_BY_USER = gql`
    query{
      jamsessionMany{
        _id
        index
        title
        description
        participants{
          _id
          name{
            first
          }
        }
        transcript{
          chats{
            owner
            msg
          }
        }
        seed
        ideabank{
          idea
          ideaBy
          generatedOn
        }
        createdBy{
          _id
          name{
            first
          }
        }
      }

    }
`;



export const GET_SESSION_MANY_BY_CREATOR = (userID) => gql`

    query{
      jamsessionMany(filter:{createdBy: "${userID}"}){
        _id
        index
      }

      projectMany(filter:{createdBy: "${userID}"}){
        _id
        index
      }
    }

`;


export const GET_PHASES_BY_SESSION_ID = (_id) => gql`
    query{


    }

`;



export const GET_PROJECT_MANY = gql`

    query{
      projectMany{
        _id
        index
        title
        createdBy{
          _id
          name{
            first
          }
        }
        description
        workshop
      }
    }

    
`;



export const GET_PROJECT_BY_ID = (_id,userId) => gql`

    query{
      projectById(_id: "${_id}"){
        _id
        index
        title
        member{
          _id
          name{
            first
          }
        }
        createdBy{
          _id
          name{
            first
          }
        }
        jam{
          _id
          createdBy{
            _id
          }
          title
          description
          status
          results
          jamtype
          results
        }
        description
        workshop                 
      }

      jamsessionMany(filter:{createdBy: "${userId}"}){
        _id
        index
      }

      
    }



`;


export const GET_JAM_PROJECT = (_id) => gql`

    query{
      projectByJam(filter:{jamIds: "${_id}"}){
        _id
        createdBy{
          _id
        }
      }      
    }

`;





