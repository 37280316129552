import React , { Component} from 'react'
import {withStyles, Grid, Card, CardContent, IconButton,
        Divider, InputBase, Button, Box,
        Avatar, Tooltip} from '@material-ui/core';
import {Lock, PetsOutlined, Send, Widgets, DragIndicatorRounded, TableChartOutlined, ExitToApp} from '@material-ui/icons'
import {getUserId, getUsername, isLeader, replaceWhiteSpace, getEmail} from '../utility/function'
import '../app/app.css'
import socket from '../api/socket'
import GameMainPanel from '../component/game/canvas/gameMainPanel'
import GameTopPanel from '../component/game/user_interface/gameTopPanel'
import axios from 'axios'
import config from '../config/backend'
import '../app/app.css'
import {Query, withApollo} from 'react-apollo';
import {GET_GAME_BY_ID} from '../api/graphql';
import Loader from '../component/loader'
import EE from '../api/eventemitter'
import session from '../model/session'
import { withRouter, Redirect } from 'react-router-dom'
import {GAME_STATUS_UPDATE_BY_ID, GAME_GD_UPDATE_BY_ID, USER_UPDATE_ACTIVITY, GAME_UPDATE_TRANSCRIPT, GAME_DECK_UPDATE} from '../api/graphql-mutation';
import GetPassword from '../component/game/user_interface/getPassword'
import Error from './error'
import {drawLoop} from '../utility/draw'
import Linkify from 'linkifyjs/react';
import './asset/gameWaitingRoomBuffer.css'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc';
import LocalizedFormat from  'dayjs/plugin/localizedFormat';
import {ReactSortable} from "react-sortablejs";

import {userAUTH} from '../config/authetication';
import { appConfig } from '../app/app.config';

import {CursorColorTable} from "./../component/game/canvas/cursor/cursor_helper/cursorColorTable"

dayjs.extend(utc)
dayjs.extend(LocalizedFormat)
ReactSortable.prototype.onChoose = () => {};
ReactSortable.prototype.onUnchoose = () => {};

const styles = {

    '@global': {
      '*::-webkit-scrollbar': {
        width: '0.4em',
        height: '0.4em',
        backgroundColor:"#C4C4C4",
      },
      '*::-webkit-scrollbar-track': {
        '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
      },
      '*::-webkit-scrollbar-thumb': {
        backgroundColor: '#FDAF2A',
        borderRadius:"10px",
        outline: '1px solid slategrey'
      },
    },

    container: {
      width:"100%",
        margin:"0 auto",
        maxWidth:"1400px",
    },

    card:{
      background:"#FAF9F9",
      margin:"auto",
      width:"80%",
      marginTop:"1em",
      marginBottom:"1em",
      color:"#4F4F4F",
      boxShadow:"none",
      border:"1px solid #D1D1D1",
    },


    cardHeader:{
      fontWeight:"bold",
      fontSize:"1rem",
      padding:"1rem",
    },

    cardContent:{
      position:"relative",
      padding:"1rem",
      fontSize:"0.875rem",
    },

    pageHeader:{
      background:"transparent",
      border:"none",
    },

    fieldContainer:{
      display: 'inline-flex',
      width:"100%",
    },

    fieldLink: {
      padding:"0 5px",
      flex:1,
      background:"#FAF7EA",
      border:"1px solid #D1D1D1",
      margin:"0 1em 0 0"
    },

    highlight:{
      color:"#4173D6",
      fontWeight:"bold",
      fontSize:"1rem",
      margin:"0 0.3em"
    },

    buttons:{
      background:"#FA841B",
      color:"#FFFFFF",
    },

    startButton:{
      float:"right",
      background:"#FA841B"
    },

    waitButton:{
      background:"#D1D1D1",
      float:"right",
    },

    avatar:{
      margin:"auto"
    },

    list:{
      padding:"1.5em 1em",
      background:"#FAF9F9",
      borderRadius:"5px",
      border:"1px solid #D1D1D1"
    },

    names:{
      padding:"0 0.5em"
    },

    host:{
      color:"#FDAF2A",
      fontSize:"0.875rem",
      margin:"-5px 0 0 0",
      fontWeight:"normal"
    },

    nonOverlay:{
      position:"fixed",
      height:"100%",
      width:"100%"
    },

    overlay:{
      backgroundColor: "rgba(0,0,0,0.5)",
      position:"fixed",
      height:"100%",
      width:"100%",
      zIndex:1
    },

    jamEmpty:{
        color:"#C4C4C4",
        fontSize:"14px",
        width:"100%",
        textAlign:"center",
        marginBottom:"20px"
    },

    chatBubble:{
      margin: "0.2rem 0",
      width:"fit-content",
      maxWidth:"80%",
      minWidth: "40%",
      fontSize:"0.875rem",
      overflowX: 'auto',
      zIndex:"99"
    },

    chatLeft:{
      borderRadius:"5px",
      float:"left",
      textAlign:"left",
      background:"#F3F3F3",
      position:"relative",
      overflowWrap: "break-word",
      wordWrap: "break-word",
    },

    chatRight:{
      borderRadius:"5px",
      float:"right",
      textAlign:"left",
      background:"#FFF3B4",
      position:"relative",
      overflowWrap: "break-word",
      wordWrap: "break-word",
    },
    primary:{
      fontSize:"1em",
      fontFamily:"Arial",
      margin:"0px 10px 15px 10px",
      lineHeight:"14px",
    },

    secondary:{
      fontSize:"0.625rem",
      margin:"10px 10px 10px 10px",
      fontFamily:"Arial",
      fontWeight:"bold",
      color:"Grey",
    },

    chatArea:{
      display:"block",
      width:"100%",
      paddingRight:"5px",
      height:"200px",
      overflowY: 'auto',
      marginBottom:"20px"
    },

    chatRow:{
      clear:"both"
    }
}

class Game extends Component {
    constructor(props){
        super(props)
        this.state = {
          //Game data
          gameData: null,
          started: false,
          overlay: false,
          userParticipated: false,
          participants: [],

          //User data
          user: null,
          userCheck: false,
          checkPassword: false,
          copied: false,

          //Chat state
          gameTranscript: [],
          chatInput: "",

          //Setup data
          setup: false,

          gdCheck: false,

          participateCheck: false,
        }

        this.getSession();
        this.initialHand = [];
        this.usedDeck = [];
        this.tempId = null;

        this.setupResult = null;

        this.refetch = null;
    }

    componentDidMount(){
      this.setState({
        checkPassword: this.props.location?.state?.skipPassword ?? false
      });

      EE.on('Close Overlay', () =>{
        this.setState({
          overlay: false
        })
      })

      socket.on("Update Game Participant",(data)=>{ //handle different case: leaving,joining,refresh
        const uid = getUserId();
        if((data.participants.findIndex((n)=>{return n.uid === uid}) !== -1) && this.state.userParticipated == false){
          this.setState({
             userParticipated: true,
             participants: data.participants
          })
        }else if((data.participants.findIndex((n)=>{return n.uid === uid}) === -1) && this.state.userParticipated == true){
            this.setState({
              userParticipated: false,
              participants: data.participants
            })
        }else{
          this.setState({
            participants: data.participants
          })
        }
      })

      socket.on("Game Start", ()=>{
        this.props.client.mutate({
          mutation: USER_UPDATE_ACTIVITY,
          variables:{
              _id: this.state.user?._id,
              _aid: this.getGID(),
              type: "jam",
              date: new Date()
          }
       })
        window.location.reload();
      })

      socket.on("Game Interrupt", ()=>{
        this.setState({
          overlay: true
        })
      })

      socket.on("Waiting Room Send Message", (data)=>{
        const {message} = data

        this.state.userParticipated && this.setState(prev =>({
          gameTranscript: [...prev.gameTranscript, message]
        }), ()=>{

          if(this.state.setup == false){
            var objDiv = document.getElementById("chat");
            objDiv.scrollTop = objDiv.scrollHeight;
          }

        });
      })

      socket.on("Refetch game data" , () =>{
        this.refetch()
      })

      //Another dirty hack to check if user has logged out in the waiting room
      // EE.on("Logged out", () =>{
      //   socket.emit("Remove Game Participant", {
      //     uid: this.tempId ? this.tempId : this.state.user._id,
      //     gid: this.getGID()
      //   })
      // })

      //Dirty hack to remove participants from the list
      //Theory: as long as this game is in the state of frontpage.js, componentWillUnmount will never fire
      //until the state is removed from the frontpage.js 🤔
      // window.addEventListener('unload',() => {
      //   socket.emit("Remove Game Participant", {
      //     uid: this.tempId ? this.tempId : this.state.user._id,
      //     gid: this.getGID()
      //   })
      // });


      document.body.style.background = "#F2F2F2";
      document.body.style.margin = "0";
      document.body.style.zIndex = 0
    }

    // componentWillUnmount(){
    //   window.removeEventListener('unload',() => {
    //     socket.emit("Remove Game Participant", {
    //       uid: this.tempId ? this.tempId : this.state.user._id,
    //       gid: this.getGID()
    //     })
    //   });
    // }

    componentWillUnmount(){
      socket.disconnect();
    }
    
    copyToClipboard() {
        var copyText = document.getElementById("link");
        copyText.select();
        copyText.setSelectionRange(0, 99999)
        document.execCommand("copy");

        this.setState({
          copied: true
        }, () =>{
          setTimeout(() =>{
            this.setState({
              copied: false
            });
          }, 3000)
        });
    }

    render(){
      const {copied, userCheck, setup} = this.state
      const {classes } = this.props;
      const gid = this.getGID();
      const uid = getUserId();

      if(gid && userCheck){
        return (
          <Query query={GET_GAME_BY_ID(gid)}>
            {({loading, error, data, refetch}) => {

              if(loading) return <Loader/>
              if(error) return <div> <Error message="Game Not Found !!"/> </div>

              if(!this.refetch){this.refetch = refetch}

              const result = data.gameById;
              const leaderCheck = isLeader(uid,result.createdBy._id);
              const gameTranscript = result.transcript;
              if(gameTranscript){
                gameTranscript.forEach((n,index) => {
                  if(typeof(n.time) === 'string'){
                    gameTranscript[index].time = dayjs(n.time)
                  }
                });
              }

              if(result.gameData && result.status !== "started" && !this.state.gdCheck){
                this.setState({
                  gameData: {
                    gameConfig: result.gameData.gameConfig,
                    gameDeck: result.gameData.gameDeck,
                  },
                  gameTranscript,
                  gdCheck: true,
                })
              }

              if(!result.gameData /*&& result.status !== "started"*/){
                // console.log(result)
                let endpoint = result.yamlEndpoint
                axios.get(`${config.backend.uri}/api/game/configs/getGameConfig`,{params: {endpoint}}).then((res)=>{
                  let gameConfig = res.data.gameConfig
                  let deckEndpoint = gameConfig?.deck?.endpoint

                  if(deckEndpoint){

                    if(result.deck && result.deck.name){
                      this.setState({ //set state during state transition error
                        gameData: {
                          gameConfig: gameConfig,
                          gameDeck: result.deck,
                        },
                        gameTranscript,
                      }, () =>{
                        this.props.client.mutate({
                          mutation: GAME_GD_UPDATE_BY_ID,
                          variables: {
                            _id : gid,
                            gameData: this.state.gameData
                          }
                        })
                      })
                    }else{
                      // temporary method for deck endpoint , original deck creator credentials will lost here ****
                      let deckAPI = `/api/game/getCustomDeck`
                      let p = {
                          endpoint: deckEndpoint,
                          userDir: `${result.createdBy._id}-${replaceWhiteSpace(result.createdBy.name.first)}`,
                      }
                      
                      if(deckEndpoint === "storyDesign"){
                          deckAPI = `/api/game/getDeck`
                          p = {
                            endpoint: deckEndpoint,
                          }
                      }

                      axios.get(`${config.backend.uri}${deckAPI}`,{params: p}).then((res)=>{ //endpoint: "storyJam2"
                        let deck = res.data.deck
                        let finalDeck = this.processDeckData(deck)

                        this.setState({ //set state during state transition error
                          gameData: {
                            gameConfig: gameConfig,
                            gameDeck: {
                              name: deck.title,
                              deck: finalDeck
                            },
                          },
                          gameTranscript,
                        }, () =>{

                          let d = {
                            name: deck.title,
                            deck: finalDeck
                          }

                          this.props.client.mutate({
                            mutation: GAME_GD_UPDATE_BY_ID,
                            variables: {
                              _id : gid,
                              gameData: this.state.gameData
                            }
                          })

                          this.props.client.mutate({
                            mutation: GAME_DECK_UPDATE,
                            variables: {
                              _id : gid,
                              deck: d
                            }
                          })
                          
                        })
                      })
                    }

                  }
                  else{
                    this.setState({
                      gameData: {
                        gameConfig: gameConfig,
                        gameDeck: {
                          name: "",
                          deck: null
                        }
                      },
                      gameTranscript,
                    }, () =>{
                      this.props.client.mutate({
                        mutation: GAME_GD_UPDATE_BY_ID,
                        variables: {
                          _id : gid,
                          gameData: this.state.gameData
                        }
                      })
                    })
                  }
                })
              }

              if(setup && leaderCheck){

                let setupInhand = this.setupInhand();

                return(
                  <div style={{position:"fixed", height:"100%", width:"100%"}}>
                    {this.state.overlay === true ?
                    <div className={classes.overlay}></div>
                    :
                    null
                    }
                    <GameTopPanel/>
                    <div style={{position:"relative",  height:"calc(100% - 4rem)", width:"100%"}}>
                      <div style={{position:"relative", height:"100%", width:"100%"}}>
                        <GameMainPanel
                          inhand={setupInhand}
                          name={result.name}
                          gid={gid}
                          leader={result.createdBy}
                          participants={this.state.participants}
                          data={result.data}
                          automation={result.automation}
                          createdBy={result.createdBy}
                          usedDeck={this.state.gameData.gameDeck.deck}
                          yamlEndpoint={result.yamlEndpoint}
                          gameData = {result.gameData}
                          tppData = {result.tppData}
                          setup = {setup}
                          setupChanges = {this.setupChanges}
                          status={result.status}
                          gameTranscript = {gameTranscript}
                          refetch = {refetch}
                          build = {result.build}
                          builderID = {result.builderID}
                        />
                      </div>
                      {/* <div style={{position:"fixed", top:"4rem", right:"0",height:"calc(100% - 4rem)", width:"20%"}}>
                        <GameRightPanel gid={gid} gameDeck={this.state.gameDeck}  data={result.data} participants={result.participants} automation={result.automation}/>
                      </div> */}
                    </div>
                  </div>
                )
              }

              if(result.status === "started" && result.gameData){

                if(getUserId()){
                  if(result.data.general.spectateMode === true){
                    return(
                      <div style={{position:"fixed", height:"100%", width:"100%"}}>
                        {this.state.overlay === true ?
                        <div className={classes.overlay}></div>
                        :
                        null
                        }
                        <GameTopPanel/>
                        <div style={{position:"relative",  height:"calc(100% - 4rem)", width:"100%"}}>
                          <div style={{position:"relative", height:"100%", width:"100%"}}>
                            <GameMainPanel
                              inhand={result.build ? [{uid:result.createdBy,objects:[]}]  : result.inhand}
                              name={result.name}
                              gid={gid}
                              leader={result.createdBy}
                              participants={result.participants}
                              data={result.data}
                              automation={result.automation}
                              createdBy={result.createdBy}
                              usedDeck=  {result.gameData.gameDeck.deck} /*{result.build ? result.gameData.gameDeck.deck : result.deck.deck} */
                              yamlEndpoint={result.yamlEndpoint}
                              gameData = {result.gameData}
                              tppData = {result.tppData}
                              setup = {setup}
                              setupChanges = {this.setupChanges}
                              status={result.status}
                              gameTranscript = {gameTranscript}
                              build = {result.build}
                              builderID = {result.builderID}
                            />
                          </div>
                          {/* <div style={{position:"fixed", top:"4rem", right:"0",height:"calc(100% - 4rem)", width:"20%"}}>
                            <GameRightPanel gid={gid} gameDeck={this.state.gameDeck}  data={result.data} participants={result.participants} automation={result.automation}/>
                          </div> */}
                        </div>
                      </div>
                    )
                  }
  
                  if(result.participants.find((n)=>{return n.uid === uid})){ // BYPASS if TRUE
                    return(
                      <div style={{position:"fixed", height:"100%", width:"100%"}}>
                        {this.state.overlay === true ?
                        <div className={classes.overlay}></div>
                        :
                        null
                        }
                        <GameTopPanel/>
                        <div style={{position:"relative",  height:"calc(100% - 4rem)", width:"100%"}}>
                          <div style={{position:"relative", height:"100%", width:"100%"}}>
                            <GameMainPanel
                              inhand={result.build ? [{uid:result.createdBy,objects:[]}]  : result.inhand}
                              name={result.name}
                              gid={gid}
                              leader={result.createdBy}
                              participants={result.participants}
                              data={result.data}
                              automation={result.automation}
                              createdBy={result.createdBy}
                              usedDeck={result.gameData.gameDeck.deck}
                              yamlEndpoint={result.yamlEndpoint}
                              gameData = {result.gameData}
                              tppData = {result.tppData}
                              setup = {setup}
                              setupChanges = {this.setupChanges}
                              status={result.status}
                              gameTranscript = {gameTranscript}
                              build={result.build}
                              builderID = {result.builderID}
                            />
                          </div>
                          {/* <div style={{position:"fixed", top:"4rem", right:"0",height:"calc(100% - 4rem)", width:"20%"}}>
                            <GameRightPanel gid={gid} gameDeck={this.state.gameDeck}  data={result.data} participants={result.participants} automation={result.automation}/>
                          </div> */}
                        </div>
                      </div>
                    )
                  }else{
                    // IF GAME STARTED 
                    return(
                      <Error message={"Game Has Started."}/>
                    )
                  }
                }else{
                  return(
                    <Redirect to={{
                      pathname: "/login",
                      state: {
                        redirect: `/game/${this.getGID()}`,
                        skipPassword: true,
                        joining: true
                      },
                    }}
                    />
                  )
                }

              }
              else{

                // if(this.state.participateCheck === false){
                //   socket.emit("Get Game Participant")
                // }

                // IF GAME HAS PASSWORD
                if(result.password && !(this.state.checkPassword || leaderCheck)){
                  return(<GetPassword name={result.name} password={result.password} checkPassword={this.checkPassword} />)
                }
                else{
                  if(!this.state.userParticipated && ( leaderCheck || this.props.location?.state?.joining)){
                    this.userParticipate();
                }

                  return(
                      <>
                        <GameTopPanel/>
                        <Grid container className={classes.container}>
                            <Grid item xs={6}>
                                {/* THIS IS THE WAITING ROOM TITLE PANEL */}
                                <Card className={`${classes.card} ${classes.pageHeader}`}>
                                    <CardContent style={{padding:"0.5rem 1rem"}} className={classes.cardHeader}>
                                        This is a waiting room for
                                        <span className={classes.highlight}>
                                            {result.name}
                                        </span>
                                    </CardContent>
                                </Card>

                                {/* THIS IS THE SHARE LINK PANEL */}
                                <Card className={classes.card}>
                                    <CardContent className={classes.cardHeader}>
                                        Share Link
                                    </CardContent>
                                    <Divider/>
                                    <CardContent className={classes.cardContent}>
                                        <Box className={classes.fieldContainer}>
                                            <InputBase className={classes.fieldLink}
                                                placeholder="Link of Jam"
                                                id="link"
                                                value={`${appConfig.baseUrl}/game/${gid}`}
                                                onKeyPress={(event) =>this.handleKeyPress(event)}
                                                inputProps={{style: {fontSize: "0.875rem", fontFamily:"arial"}}}
                                            />
                                            <Button
                                              id="copybtn"
                                              onClick={this.copyToClipboard.bind(this)}
                                              className={classes.buttons}
                                            >
                                            {copied ?
                                              "Copied!" :
                                              "Copy"
                                            }
                                            </Button>
                                        </Box>
                                        {/* hasPassword - IF ROOM HAS PASSWORD */
                                        (result.password) ?
                                            <div style={{fontSize:"0.875rem", marginTop:"1em"}}>
                                                <Tooltip title="Jam Password">
                                                    <Lock style={{verticalAlign:"-8px", marginRight:"0.5em"}}/>
                                                </Tooltip>
                                                {result.password}
                                            </div>
                                            :
                                            null
                                        }


                                    </CardContent>
                                </Card>

                                {/* THIS IS THE HOW TO PLAY PANEL */}
                                <Card className={classes.card}>
                                    <CardContent className={classes.cardContent} style={{padding:"1rem 1rem 0 1rem", color:"#828282"}}>
                                      <TableChartOutlined style={{marginTop:"-3px", marginRight:"5px", fontSize:"1rem"}}/>
                                      {result.data.general.name}
                                    </CardContent>
                                    <CardContent className={classes.cardHeader} style={{padding:"0 1rem"}}>
                                        How to play {result.name}
                                    </CardContent>
                                    <CardContent className={classes.cardContent}>
                                      {result.data.general.rulebook.split('\n').map ((line, i) =>
                                        <p
                                          style ={{
                                            fontSize:"0.875rem"
                                          }}
                                          key={`${i}-${line}`}
                                        >
                                          {line}
                                        </p>
                                      )}
                                    </CardContent>
                                </Card>

                                {/* THIS IS THE CHAT PANEL */}
                                <Card className={classes.card}>
                                    <CardContent className={classes.cardHeader}>
                                        Chat
                                    </CardContent>
                                    <CardContent className={classes.cardContent}>
                                      {this.state.userParticipated ?
                                      <>
                                        {/* THIS IS THE CHAT MESSAGE AREA */}
                                        <div className={classes.chatArea} id="chat">
                                        {this.state.gameTranscript.map((message)=>
                                          message.owner !== getUserId() ?
                                            <div className={classes.chatRow}>
                                              <div className={`${classes.chatBubble} ${classes.chatLeft}`}>
                                                {/* Non message owner requires extra parsing possibly due to the serialization of message.time into string when socket emitting */}
                                                <p className={classes.secondary}><b>{message.ownerName}</b> • {dayjs(message.time).local().format("LT")}</p>
                                                <Linkify className={classes.primary} tagName="p" options={{className: classes.primary, target: {url: '_blank'}}}>
                                                  {message.msg}
                                                </Linkify>
                                              </div>
                                            </div> :
                                            <div className={classes.chatRow}>
                                                <div className={`${classes.chatBubble} ${classes.chatRight}`}>
                                                <p className={classes.secondary}>{message.time.local().format("LT")}</p>
                                                <Linkify className={classes.primary} tagName="p" options={{className: classes.primary, target: {url: '_blank'}}}>
                                                  {message.msg}
                                                </Linkify>
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                        {/* THIS IS THE CHAT INPUT */}
                                        <Grid container>
                                          <Grid item xs={11}>
                                            <InputBase
                                              value={this.state.chatInput}
                                              style={{
                                                  color: "#4F4F4F",
                                                  fontSize:"16px",
                                                  width:"calc(100% - 20px)",
                                                  padding:"5px 10px",
                                                  border:"1px solid #D1D1D1",
                                                  background:"#FAF7EA",
                                                  borderRadius:"10px",
                                                  outline:"none"
                                              }}
                                              inputProps={{style:{textAlign:"left"}}}
                                              name="chatInput"
                                              onKeyPress={(e) =>this.handleKeyPress(e)}
                                              onChange={(e) =>this.handleChange(e)}
                                              placeholder="Type a message"
                                            />
                                          </Grid>
                                          <Grid item xs={1}>
                                              <IconButton
                                                style={{background:"#4173D6", float:"right", outline:"none", color:"#FAF9F9"}}
                                                onClick={this.sendMessage}
                                              >
                                                <Send/>
                                              </IconButton>
                                          </Grid>
                                        </Grid>
                                      </>
                                      :
                                      <>
                                        <PetsOutlined style={{color:"#C4C4C4", fontSize:"10rem", marginLeft:"33%"}}/>
                                        <p className={classes.jamEmpty}>Only participants who are in this waiting room<br/>can use and view the chat</p>
                                      </>
                                      }

                                    </CardContent>
                                </Card>
                            </Grid>

                            <Grid item xs={6}>
                                {/* THIS IS THE PARTICIPANT NUMBER PANEL */}
                                <Card className={classes.card}>
                                    <CardContent className={classes.cardHeader}>
                                        <Grid container justify="center" alignItems="center">
                                            <Grid item xs={5} style={{fontWeight:"bold", fontSize:"1rem"}}>
                                              <span className={classes.highlight}>
                                              {this.state.participants.length}
                                              </span>
                                              Participant
                                            </Grid>
                                            <Grid item xs={7}>
                                              {/* Ha ha butt */}
                                              {this.waitingRoomButt(gid, data, classes)}

                                              {/* Setup Button */}
                                              {getUserId() === data.gameById.createdBy._id &&
                                              <Button
                                                disabled={!this.state.gameData?.gameDeck}
                                                onClick={()=>{this.setState({setup: true})}}
                                                style={{
                                                  float:"right",
                                                  marginRight:"10px",
                                                  color: this.state.gameData ? "#FA841B" : "#FED2AC",
                                                  textDecoration:"underline"
                                                }}
                                                startIcon={<Widgets/>}
                                              >
                                                Setup Cards
                                              </Button>
                                              }
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                    <Divider/>
                                    <CardContent className={classes.cardContent}>


                                      {getUserId() !== data.gameById.createdBy._id ?
                                      <span style={{fontSize:"0.875rem"}}>
                                        Game host needs to ensure <b>all players are present here before starting the game.</b> Once the game has started, no one else can join in.
                                        <br/><br/>
                                        The page will automatically refresh once host begins the session
                                      </span>
                                      :
                                      <>
                                      <b>Tips:</b> For smooth jam experience recommend Host to <b><u>setup cards</u></b> before starting a jam.
                                      <br/><br/>
                                      Host needs to ensure <b>all players are present in waiting room before clicking START NOW.</b> Once the game has started, no one else can join.
                                      </>}
                                    </CardContent>
                                </Card>

                                {/* THIS IS THE LIST OF PARTICIPANTS PANEL */}
                                <Card className={classes.card} style={{background:"transparent", border:"none"}}>
                                      <ReactSortable
                                        animation = {150}
                                        list={this.state.participants}
                                        setList={newState => this.setState({participants:newState})}
                                        disabled={uid === data.gameById.createdBy._id ? false : true}
                                        onEnd={()=>{socket.emit("Update Game Participant Sequence", {participants: this.state.participants, gid: gid})}}
                                      >
                                        {this.state.participants.map((p,index)=>
                                            <Grid container justify="center" alignItems="center" style={uid === data.gameById.createdBy._id ? {cursor:"grab"} : {cursor:"default"}} className={classes.list} key={`${index}-${p}`}>
                                                <Grid item xs={2}>
                                                    <Avatar className={classes.avatar}> {p.username.charAt(0).toUpperCase()} </Avatar>
                                                </Grid>
                                                <Grid item xs={4} className={`${classes.cardHeader} ${classes.names}`}>
                                                    {p.username}
                                                    {(p.uid === data.gameById.createdBy._id)?
                                                    <p className={classes.host}>Host</p>
                                                    :
                                                    null
                                                    }

                                                </Grid>
                                                <Grid item xs={2} style={{color:"#828282", fontSize:"14px"}}>
                                                    {this.getPosition(index)}
                                                </Grid>
                                                {p.uid === uid && !leaderCheck ?
                                                  <Grid item xs={2} style={{color:"#828282", fontSize:"14px"}}>
                                                  <Button
                                                      // disabled={!this.state.gameData?.gameDeck}
                                                      onClick={()=>{
                                                        socket.emit("Remove Game Participant", {
                                                            uid: uid,
                                                            gid: gid,
                                                        })
                                                      }}
                                                      style={{
                                                        float:"right",
                                                        marginRight:"10px",
                                                        color: "#fff",
                                                        backgroundColor:"#f25f4c",
                                                        borderRadius:"5px",
                                                      }}
                                                      startIcon={<ExitToApp/>}
                                                    >
                                                      <span style={{paddingTop:"1.5px"}}>LEAVE</span>
                                                  </Button>
                                                </Grid>
                                                : <Grid item xs={2} style={{color:"#828282", fontSize:"14px"}}></Grid>
                                                }

                                                <Grid item xs={2}>
                                                  <Tooltip title={"Only Host can re-arrange the sequence"}>
                                                    <DragIndicatorRounded style={{float:"right", color:"#C4C4C4", fontSize:"25px"}}/>
                                                  </Tooltip>
                                                </Grid>
                                            </Grid>
                                        )}
                                      </ReactSortable>
                                </Card>
                            </Grid>
                        </Grid>
                    </>
                  )
                }
              }
            }}
          </Query>
        )
      }
      else{
          return(
            <Loader/>
          )
      }
    }

    getPosition = (number) =>{
      let num = (number + 1).toString()

      if(num.charAt(num.length-1) === "1"){
        return num+"st"
      }
      else if(num.charAt(num.length-1) === "2"){
        return num+"nd"
      }
      else if(num.charAt(num.length-1) === "3"){
        return num+"rd"
      }

      return num+"th"
    }
    handleKeyPress = (e) =>{
      if (e.key === 'Enter') {
        this.sendMessage()
      }
    }

    handleChange = (event) =>{
      this.setState({
        [event.target.name] : event.target.value
      });
    }

    sendMessage = () => {
        const { chatInput} = this.state;
        if (!chatInput || !chatInput.trim()) {
            return;
        }

        const message = {
            owner: getUserId(),
            ownerName: getUsername(),
            msg: this.state.chatInput,
            time: dayjs.utc(),
        };

        this.setState(prev => ({
            chatInput: '',
            gameTranscript: [...prev.gameTranscript, message]
        }), () => {
            var objDiv = document.getElementById('chat');
            objDiv.scrollTop = objDiv.scrollHeight;

            socket.emit('Waiting Room Send Message', {message: message})

            this.props.client.mutate({
            mutation: GAME_UPDATE_TRANSCRIPT,
            variables:{
                _id: this.getGID(),
                st: message,
            }
            }).then((status)=>{console.log(status)});
        });
    };

    getGID = () => {
      return window.location.href.substring(window.location.href.indexOf("game/") + 5,window.location.href.length)
    }

    spectate = () => {
      const uid = getUserId();
      socket.emit("Game Spectator Mode", {uid})
    }

    async getSession(){
      await session.fetchLocal();
      const user = session.getUser();
      const gid = this.getGID();

      userAUTH(user,this.props.history)

      this.setState({
        user: user,
        userCheck: true
      },()=>{
        //tempid is required to allow a socket connection between the game room and user id in servers
        //this is to facilitate users that are not logged in and is at the waiting room to receive socket connection
        //this also allows the removal of non-user ids upon waiting room disconnect
        this.tempId = this.state.user?._id ? null : Math.floor(Math.random() * 100)
        // console.log("NGU")
        socket.emit("New Game User", {
          uid: this.tempId ? this.tempId : this.state.user?._id,
          gid
        });
      })
    }

    userParticipate = () => {
      const username = getUsername();
      const status = "player"
      const uid = getUserId();
      const gid = this.getGID();
      const email = getEmail();

      const colorArr = Object.keys(CursorColorTable)

      let usercolor = colorArr[Math.floor(Math.random() * colorArr.length)]
      while(true && this.state.participants.length <= 10){ //because we only have 10 colors lol
        let exists = this.state.participants.find((user) =>{return user.usercolor === usercolor})

        if(!exists){
          break
        }
        else{
          usercolor = colorArr[Math.floor(Math.random() * colorArr.length)]
        }
      }
      socket.emit("New Game Participant",{username,uid,status,usercolor,gid,email})


      this.setState({
        userParticipated: true
      })
    }

    checkPassword = (a,b) => {
      if(a === b){
        this.setState({
          checkPassword: true
        });
      }
      else{
        alert("Wrong Password!")
      }
    }

    checkInitialDraw = (gid,dep) => {
      let gameConfig = this.state.gameData.gameConfig;
      let deck = this.state.gameData.gameDeck.deck;
      let participants = this.state.participants
      let initial = [];

      if(gameConfig?.deck?.drawConfig?.initialDraw){
        //This initialDrawConfig follows the deck.drawConfig.initalDraw in YAML
        // let initialDrawConfig = gameConfig.deck.drawConfig.initialDraw;
        let initialDrawConfig = ["Archetype(3)", "Genre(2)"]
        if(dep === "storyJam"){
          for(let i = 0; i<participants.length; i++){

            var hand;
            // is leader only draw else no
            if(i === 0){
              hand = drawLoop(deck,initialDrawConfig)
            }else{
              hand = []
            }

            let player = {
              uid: participants[i].uid,
              objects: hand,
            }

            initial.push(player);
          }
        }else{
          for(let i = 0; i<participants.length; i++){
            // console.log({deck})
            var hand = drawLoop(deck,initialDrawConfig)

            let player = {
              uid: participants[i].uid,
              objects: hand,
            }

            initial.push(player);
          }
        }

        this.initial = initial;
        this.usedDeck = deck.deck;

      }else{
        // console.log("Cannot Find Initial Draw Config")
        for(let i = 0; i<participants.length; i++){

          let player = {
            uid: participants[i].uid,
            objects: [],
          }

          initial.push(player);
        }
      }

      let result = {
        initial,
        deck,
      }

      return result;
    }

  waitingRoomButt(gid, data, classes){
    if(getUserId() === data.gameById.createdBy._id){
      return(
        <Button
          className={`${classes.buttons} ${classes.startButton}`}
          onClick={()=>{
            let result = this.checkInitialDraw(gid, this.state.gameConfig?.deck?.endpoint) // supposed initial draw config set from jam builder config yaml
            this.gameStatusUpdate(gid, result, data)
          }}
        >
          Start Now
        </Button>
      )
    }
    else{
      return(
        <Button
          className={`
            ${classes.buttons}
            ${this.state.userParticipated ? classes.waitButton : classes.startButton}
          `}
          style={{
            color:"white"
          }}
          disabled={this.state.userParticipated}
          startIcon={this.state.userParticipated && <div className="buffer"></div>}
          onClick={()=>{
            if(this.tempId){
              this.props.history.push({
                pathname: "/login",
                state:{
                  redirect: `/game/${this.getGID()}`,
                  skipPassword: true,
                  joining: true,
                }
              })
            }
            else{
              this.userParticipate()
            }
          }}
        >
          {this.state.userParticipated ? "Waiting for Host" : "Join This Jam!"}
        </Button>

      )
    }
  }

  gameStatusUpdate = (gid,result,templateData) => {

    //let data = this.gamePrepareData()
    //data.general = templateData.gameById.data.general

      let {participants} = this.state
      for(let i=0;i<participants.length;i++){
        participants[i].notificationLog = []
        participants[i].unreceivedEvents = []
      }

      this.props.client.mutate({
        mutation: GAME_STATUS_UPDATE_BY_ID,
        variables: {
          _id: gid,
          status: "started",
          participants: this.state.participants,
          inhand: result.initial,
          // deck: result.deck, // old deck forward to in-hand // new deck should sync to library
        }
      }).then(()=>{
        this.props.client.mutate({
          mutation: USER_UPDATE_ACTIVITY,
          variables:{
              _id: this.state.user?._id,
              _aid: gid,
              type: "jam",
              date: new Date()
          }
        })
        window.location.reload();
        socket.emit("Game Start");
      })

  }

  gamePrepareData = () => {


    // latest object id for each participants
    let participants = this.state.participants
    let latest = []

    for(let i = 0; i<participants.length; i++){
      let n = {
        uid: participants[i].uid,
        oid: `${participants[i].uid}-1`
      }

      latest.push(n)
    }
    // end

    const data = {
      objects : this.setupResult?.['objects'] ? this.setupResult.objects : [],
      latestObject : null,
      playerTurn: 0,
      turn: 1,
      phase: [],
      latestID: this.setupResult?.['latestID']? this.setupResult.latestID : "0",
    }
    return data
  }

  setupInhand = () => {
    let participants = this.state.participants;
    let initial = []

    for(let i = 0; i<participants.length; i++){

      let player = {
        uid: participants[i].uid,
        objects: [],
      }

      initial.push(player);
    }

    return initial
  }

  setupChanges = (items) => {
    this.setupResult = {...items}

    this.setState({
      setup: false
    }, () =>{
      socket.emit("Refetch game data")
    })
  }

  processDeckData = (deck) => {
    let finalDeck = {}

    if(typeof deck["categories"][0] === "string"){
      for(let i = 0; i<deck.categories.length; i++){
        finalDeck[deck.categories[i]] = deck.elements.filter((n)=>{
            return n.category === deck.categories[i];
        })

        finalDeck[deck.categories[i]].forEach((n,index)=>{
          n.cardId = `${deck.categories[i]}-${index}`;
        })
      }
    }
    else if(typeof deck["categories"][0] === "object"){
      let categoryColorMap = new Map()

      for(let i = 0; i<deck.categories.length; i++){
        let categoryName = deck.categories[i]["name"]
        let categoryColor = deck.categories[i]["color"]

        categoryColorMap.set(categoryName, categoryColor)

        finalDeck[categoryName] = deck.elements.filter((card)=>{
          return card.category === categoryName;
        })

        finalDeck[categoryName].forEach((n,index)=>{
          n.cardId = `${categoryName}-${index}`;
        })
      }

      finalDeck.categoryColorMap = Object.fromEntries(categoryColorMap)
    }

    let n = deck.elements.filter((card)=>{
      return card.category === ''
    })

    if(n){
      finalDeck['uncategorized'] = n
      finalDeck['uncategorized'].forEach((n,index)=>{
        n.cardId = `uncategorized-${index}`
        n.category = `Uncategorized`
      })
    }
    return finalDeck;
  }

}

export default withRouter(withApollo(withStyles(styles)(Game)))
