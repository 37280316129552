import ApolloClient from 'apollo-boost';
//import {ApolloClient,InMemoryCache,HttpLink, split} from 'apollo-boost'
// import { WebSocketLink } from "apollo-link-ws";
// import { getMainDefinition } from "apollo-utilities";

const config = require("../config/backend");

// const httpLink = new HttpLink({
//   uri: config.backend.uri + "/graphql",
// });

// const wsLink = new WebSocketLink({
//   uri: config.backend.ws + "/graphql",
//   options: {
//     reconnect: true
//   }
// });

// const link = split(
//   ({ query }) => {
//     const { kind, operation } = getMainDefinition(query);
//     return kind === "OperationDefinition" && operation === "subscription";
//   },
//   wsLink,
//   httpLink
// );


export const clientURI = new ApolloClient({
  uri: config.backend.uri + "/graphql",
});


// export const clientURI = new ApolloClient({link, cache: new InMemoryCache( )});