import _ from 'lodash';
import { fromEvent } from 'rxjs';
import EventEmitter from 'eventemitter3';
import { share,map } from 'rxjs/operators';

// dec2hex :: Integer -> String
function dec2hex (dec) {
  return ('0' + dec.toString(16)).substr(-2)
}

// generateId :: Integer -> String
function generateId (len) {
  var arr = new Uint8Array((len || 40) / 2)
  window.crypto.getRandomValues(arr)
  return Array.from(arr, dec2hex).join('')
}

// const subscriptions = new WeakMap();

export default class Store {

  store = {}

  events = new EventEmitter()

  defaultKeys = []

  changes$ = fromEvent(this.events, 'change')
      .pipe(
        share()
      )
  
  store$ = fromEvent(this.events, 'change')
      .pipe(
        map( v => this.store),
        share()
      )

  setup(props) {
    const proxy = new Proxy(this, this.proxyHandler);
    this.set(props);
    return proxy;
  }

  static generateId() {
    return generateId(24);
  }

  toObject() {
    const newObject = _.transform(this.store, (acc, val, key) => {
      if (_.isEmpty(val)) {
        return acc; 
      }

      acc[key] = val;

      if (_.isArray(val)) {
        acc[key] = _.map(val, (v) => {
          if (v && v.store && v.toObject) {
            return v.toObject() || v.store;
          } 
          return v;     
        })
      } else if (val && val.store && val.toObject) {
        acc[key] = val.toObject() || val.store;
      } 
      return acc;
    }, {});
    return _.cloneDeep(newObject);
  }
  
  get(key) {
    if (_.isArray(key)) {
      return _.pick(this.store, key);
    }

    return _.get(this.store, key);
  }

  set(...args) {
    let changes = null; 
    
    if (args.length === 1) {
      const val = args.pop();
      if (_.isObject(val)) {
        changes = val;
      }
    } else {
      const [key, val] = args;
      changes = {[key]: val};
    }
    
    // Set everything to store if no defaultKeys are defined 
    if (!this.defaultKeys.length) {
      _.assign(this.store, changes);
      this.events.emit('change', changes);
      return this;
    }
    
    _.toPairs(changes).forEach(([key, val]) => {
      if (_.has(this.store, key)) {
        _.assign(this.store, {[key]: val});
        this.events.emit('change', {[key]: val});
      } else {
        _.assign(this, {[key]: val});
      }
    });

    return this;
  }

  proxyHandler = {
    has(obj, prop) {
      return _.has(obj.store, prop);
    },

    get(obj, prop) {
      if (_.has(obj, ['store', prop])) {
        return _.get(obj, ['store', prop]);
      }
      return _.get(obj, prop);
    },

    set(obj, prop, val) {
      if (obj.defaultKeys.indexOf(prop) >= 0) {
        obj.events.emit('change',{[prop]: val});
        _.assign(obj.store, {[prop]: val})
      }
      _.assign(obj, {[prop]: val});
      return this;

      // if (obj.defaultKeys.indexOf(prop) >= 0) {
      //   return _.assign(obj.store, {[prop]: val});
      // }

      // return _.assign(obj, {[prop]: val});
    }
  }
}