import React from 'react'
import { withRouter } from 'react-router-dom'
import {
    withStyles,
    Avatar,
    Grid,
    Link,
    IconButton,
    InputBase,
    Paper,
    Button,
    ClickAwayListener,
} from '@material-ui/core'
import {Search} from '@material-ui/icons'

import {getUsername, isSessionUser} from '../../../utility/function'
import frontpageConstants from '../../../page/frontpage/constants.json';
import EE from '../../../api/eventemitter'
import { unfocusInputField } from '../../../utility/unfocus-input-field.ts';
import logo from '../../../element/2024feb/logo2024.png'

const styles = {
    panel: {
        position:"-webkit-sticky",
        position:"sticky",
        top:"0",
        width:"100%",
        height:"4rem",
        padding:"0 0 0 2%",
        background:"#FFF",
        zIndex:"10"
    },
    logo:{
        background:"#4173D6",
        color:"#FFF",
    },
    searchBar: {
        display: 'inline-flex',
        margin:"0 0.6rem 0 0",
        alignItems: 'center',
        width: "300px",
        boxShadow:"none",
        background:"#FAF7EA",
        border:"1px solid #D1D1D1",
    },
    searchInput: {
        padding:"0 5px",
        flex:1
    },
    searchIcon: {
        padding:"5px",
    },
    tagline:{
        fontSize: "20px"/*'0.875rem'*/,
        margin:"0 1rem",

    },
    links:{
        fontSize:"12px",
        display:"inline",
        color:"#4F4F4F",
        margin:"0 0 0 2em",
    },

    logIn:{
        border:"1px solid #d1d1d1",
        color:"#4F4F4F",
        marginRight:"1em",
        outline:"none"
    },
    signUp:{
        background:"#FFAA0D",
        color:"#fff",
        marginRight:"1em",
        outline:"none"
    },

    logout: {
        border:"1px solid #d1d1d1",
        color:"#4F4F4F",
        marginRight:"1em",
        outline:"none"
    },

    avatar:{
        marginRight:"1em"
    }

}

class GameTopPanel extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            loggedIn : "true",
            searchString: "",
            anchorEl: null,
            redirect: null
        }
    }

    render(){
        const {classes, reqLogin} = this.props
        const isLoggedIn = isSessionUser();

        return(
            <Grid container className={classes.panel} justify="flex-start" alignItems="center" >
                <Grid item xs={3}>
                    <a href="/" style={{textDecoration:"none", fontFamily: 'Covered By Your Grace', color:"#4173D6", display:"inline", fontWeight:"bold", fontSize:"26px", margin:"0"}}>
                        <img src={logo} style={{width:"121px", height:"46.76px"}}/>
                        <span style={{fontFamily:"arial", fontWeight:"bold", color:"#4F4F4F"}} className={classes.tagline}>Play, Create.</span>
                    </a>

                </Grid>
                <Grid item xs={6}>
                    <Grid container justify="center" alignItems="center" >
                        <Grid item>
                            <Paper component="form" onSubmit={(e)=>{this.onSearch(e)}} className={classes.searchBar}>
                                <ClickAwayListener onClickAway={unfocusInputField("search-input")}>
                                    <InputBase
                                        id="search-input"
                                        className={classes.searchInput}
                                        placeholder="Join jam by ID"
                                        inputProps={{style: {fontSize: "0.875rem", fontFamily:"arial"}}}
                                        onChange={(e)=>{this.setState({searchString: e.target.value})}}

                                    />
                                </ClickAwayListener>
                                <IconButton type="submit" className={classes.searchIcon} aria-label="search">
                                    <Search />
                                </IconButton>
                            </Paper>
                            <Link
                                href={frontpageConstants.links.introduction.src}
                                className={classes.links}
                            >
                                ABOUT
                            </Link>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={3}>
                    <Grid container justify="flex-end"  alignItems="center">
                    {(isLoggedIn === true) ?
                        <div style={{display: "inherit"}}>
                            <Avatar className={classes.avatar}> {getUsername().charAt(0).toUpperCase()} </Avatar>
                            <Button
                                onClick={()=>{
                                    EE.emit("Logged out")
                                    this.props.history.push({
                                        pathname: "/logout"
                                    })
                                }}
                                className={classes.logout}
                            >
                                    Log out
                            </Button>
                        </div>
                        :
                        <div>
                            <Button
                                onClick={()=>{
                                    this.props.history.push({
                                        pathname:"/login",
                                    })
                                }}
                                className={classes.logIn}
                            >
                                Log In
                            </Button>
                            <Button
                                onClick={()=>{
                                    this.props.history.push({
                                        pathname:"/register",
                                    })
                                }}
                                className={classes.signUp}
                            >
                                Be a jammer
                            </Button>
                        </div>
                    }
                    </Grid>
                </Grid>
            </Grid>
         )

    }

    openMenu = (event) =>{
        this.setState({
            anchorEl : event.currentTarget
        })
    }

    closeMenu = () =>{
        this.setState({
            anchorEl : null
        })
    }


    onSearch = (e) => {
        e.preventDefault();
        window.location.href=`/game/${this.state.searchString}`
    }

}

export default withRouter(withStyles(styles)(GameTopPanel))
