import { configureStore } from "@reduxjs/toolkit";

import { jamReducer } from "../components/jam/jam.reducer";
import { authReducer } from "../components/auth/auth.reducer";

export const store = configureStore({
    reducer: {
        jam: jamReducer,
        auth: authReducer,
    },
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
