export const styles = {
    '@global': {
        'body':{
            fontFamily:"arial",
            color:"#4F4F4F"
        }
    },
    title:{
        fontWeight:"bold",
        color:"#4F4F4F",
        margin:"0",
        fontSize:"16px",
    },
    titleSub:{
        fontWeight:"normal",
        color:"#828282",
        margin:"10px 0 0 0",
        fontSize:"12px"
    },
    formWrapper:{
        padding:"20px"
    },
    formField:{
        marginBottom:"20px"
    },
    fieldName:{
        marginBottom:"10px", 
        fontWeight:"bold", 
        color:"#4F4F4F", 
        fontSize:"14px"
    },
    fieldText:{
        border:"1px solid #c1c1c1", 
        background:"#FAF7EA", 
        fontSize:"14px", 
        borderRadius:"5px", 
        padding:"5px 10px", 
        width:"calc(100% - 20px)"
    },
    fieldSelect:{
        border:"1px solid #c1c1c1", 
        background:"#FAF7EA", 
        fontSize:"14px", 
        borderRadius:"5px", 
        padding:"5px 10px", 
        width:"calc(100% - 20px)",
        '&:first':{
            color:"#C1C1C1"
        }
    },
    radioField:{
        marginBottom:"10px"
    },
    event:{
        background:"#F5F5F5",
        border:"1px solid #D1D1D1",
        borderRadius:"5px",
        padding:"20px"
    },
    eventField:{
        marginBottom:"10px",
        background:"#F5F5F5",
        border:"1px solid #D1D1D1",
        padding:"20px",
        borderRadius:"5px"
    },
    showAction:{
        display:"block",
    },
    closeAction:{
        display:"none"
    },
    addEvent:{
        color:"white",
    },

    addEventLink:{
        outline:"none", 
        fontWeight:"bold",
        background:"orange", 
        color:"white", 
        float:"right", 
        fontSize:"14px",
        cursor:"pointer", 
    },
    
    closeButton:{
        float:"right",
        width:"30px",
        height:"30px", 
        transition:"0.5s",
        '&:focus':{
            outline:"none"
        }
    },
    evenIcon:{
        color:"#4173D6", 
        fontSize:"17px", 
        marginRight:"5px",
        marginTop:"-3px"
    },

    oddIcon:{
        color:"#FFAA0D",
        fontSize:"17px", 
        marginRight:"5px",
        marginTop:"-3px"
    },

    selectionIcon:{
        textAlign:'center',
        marginRight:"5px", 
        color:"#4F4F4F"
    },
    
    selectionIconSize:{
        fontSize:"2.5rem",
    },

    selectionTitle:{
        margin:"0", 
        fontWeight:"bold", 
        fontSize:"14px", 
        color:"#4F4F4F"
    },

    selectionSubtitle:{
        margin:"0", 
        fontSize:"12px", 
        color:"#828282"
    },

    subtitle:{
        margin:"0", 
        marginTop:"5px", 
        fontSize:"12px", 
        color:"#828282",
        fontWeight:"normal"
    },

    defaultField:{
        margin:"0", 
        color:"#828282",
        fontSize:"14px"
    },

    fieldRadio:{
        margin:"0",
        fontSize:"14px"
    },

    eventText:{
        opacity: "1",
        fontSize:"14px", 
        marginTop:"10px",
    },

    eventTextHidden:{
        opacity: "0.5",
        fontSize:"14px", 
        marginTop:"10px",
    },

    fieldProp: {
        color: "#4F4F4F", 
        fontSize:"14px", 
        fontFamily:"Arial",
        fontWeight:"bold",
    },

    timerField:{
        border:"1px solid #c1c1c1", 
        background:"#FAF7EA", 
        fontSize:"14px", 
        borderRadius:"5px", 
        padding:"5px 10px", 
        width:"3rem",
    },
    
}