import * as React from 'react';
import { Button, ClickAwayListener, Fade, IconButton, Popper, TextField } from '@material-ui/core';
import {
    EjectRounded as EjectRoundIcon,
    Timer as TimerIcon,
} from '@material-ui/icons';

import { ITimer, IJamTimerProps } from './jam-timer.interface';
import socket from '../../../api/socket';
import EE from '../../../api/eventemitter';
import { useAppSelector } from '../../../store/use-app-selector.hook';
import { CanvasUtil } from '../../../component/game/helper/gameCanvasHelper';

/***
 * formatTime is to format time displayed
 * @param {int} time
 */
const formatTime = (time: number) => {
    if (time < 10) {
        return ("0" + time).slice(0, 2);
    }

    return time;
};

const handleTimerTextInput = (event: React.FormEvent<HTMLDivElement>) => {
    // @ts-ignore
    const num = Number(event.target.value);
    if (Math.floor(num) === num && num) {
        // @ts-ignore
        event.target.value = Math.max(0, parseInt(event.target.value))
            .toString().slice(0, 2);
        return;
    }

    // @ts-ignore
    event.target.value = 0;
};

const defaultTimerState: ITimer = {
    hr: 0,
    min: 0,
    sec: 0,
};

export const JamTimer = React.memo((props: IJamTimerProps) => {
    const {
        classes,
        initialTimerOn = false,
        initialTimerValue,
        snackbar,
    } = props;
    const [timerEl, setTimerEl] = React.useState<HTMLElement | null>(null);
    const [timerOn, setTimerOn] = React.useState(initialTimerOn);
    const [timer, setTimer] = React.useState<ITimer>(defaultTimerState);
    const timerIntervalId = React.useRef<number | null>(null);
    const jamId = useAppSelector((state) => state.jam.id);
    const username = useAppSelector((state) => state.auth.username);
    const isOpen = Boolean(timerEl);

    const toggleTimer = (event: React.MouseEvent<HTMLButtonElement>) => setTimerEl((state) => !state ? event.currentTarget : null);

    /***
     * handleTimerChange is to handle the timer change when user edits it
     */
    const handleTimerChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (event.target) {
            const name = event.target.name;

            setTimer({
                hr: name === "hr" ? parseInt(event.target.value, 10) : timer.hr,
                min: name === "min" ? parseInt(event.target.value, 10) : timer.min,
                sec: name === "sec" ? parseInt(event.target.value, 10) : timer.sec,
            });
        }
    };

    /***
     * resetTimer is to reset the timer to 00:00:00
     */
    const resetTimer = () => setTimer(defaultTimerState);

    /***
     * updateTimer is to update timer's countdown
     */
    const updateTimer = (expectedTime: number) => {
        const now = new Date().getTime();
        const distance = expectedTime - now;

        if (distance < 0) {
            return null;
        }

        const hours = Math.floor((distance / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((distance / 1000 / 60) % 60);
        const seconds = Math.floor((distance / 1000) % 60);

        return { user: username, hr: hours, min: minutes, sec: seconds };
    };

    /***
     * initiateTimer is to initiate the timer countdown
     */
    const initiateTimer = (expectedTime: number) => {
        timerIntervalId.current = window.setInterval(() => {
            const newTimer = updateTimer(expectedTime);

            if (newTimer) {
                setTimer(newTimer);
                return;
            }

            setTimerOn(false);
            if (timerIntervalId.current) {
                window.clearInterval(timerIntervalId.current);
            }
            snackbar("Time's Up!!!", "The time has ended");
        }, 500);
    };

    /**
     * timerHandler is to handle the timer during or before it begins
     * @state {obj} timer = {user, hr, min, sec}
     */
    const timerHandler = (timerOnState: boolean) => {
        const newTimer = {
            ...timer,
            user: username,
        };

        setTimerOn(timerOnState);
        setTimer(newTimer);

        if (timerOnState) {
            // sync with other player
            socket.emit("Sync Timer", { timer: newTimer, timerOn: timerOnState, cdt: (newTimer.hr*60+newTimer.min+newTimer.sec/60)*60000 });

            const hr = newTimer.hr * 60;
            const min = newTimer.min;
            const sec = newTimer.sec / 60;

            const expectedTime = (new Date().getTime() + (hr + min + sec) * 60000)
            CanvasUtil.mutateTimer(jamId, {
                timerOn: true,
                expectedTime,
                timer: newTimer,
            });

            initiateTimer(expectedTime);
        } else {
            CanvasUtil.mutateTimer(jamId, { timerOn: false, expectedTime: null });
            if (timerIntervalId.current) {
                window.clearInterval(timerIntervalId.current);
                socket.emit("Game Stop Timer")
            }
            snackbar("Time's Up!!!", "The time has ended");
        }
    };

    const closeTimer = () => {
        if (timerEl !== null) {
            setTimerEl(null);
        }
    };

    const handleEventTimer = (min: any,sec: any) => {

        const newTimer = {
            hr: 0,
            min,
            sec,
            user: username
        }

        setTimerOn(true);
        setTimer(newTimer);

        socket.emit("Sync Timer", { timer: newTimer, timerOn: true, cdt: (newTimer.hr*60+newTimer.min+newTimer.sec/60)*60000 });

        const hour = newTimer.hr * 60;
        const minute = newTimer.min;
        const second = newTimer.sec / 60;

        const expectedTime = (new Date().getTime() + (hour + minute + second) * 60000)
        CanvasUtil.mutateTimer(jamId, {
            timerOn: true,
            expectedTime,
            timer: newTimer,
        });

        if (timerIntervalId.current) {
            window.clearInterval(timerIntervalId.current)
        }
        initiateTimer(expectedTime);
    }

    React.useEffect(() => {
        /**
         * Sync Timer is calling from gameCanvas
         */
        socket.on("Sync Timer", (data: any) => {
            const { timer: timerData, timerOn: timerOnData, cdt } = data;

            setTimer(timerData);
            setTimerOn(timerOnData);

            if(cdt){
                const expectedTime = (new Date().getTime() + cdt);
                initiateTimer(expectedTime);
                return;
            }

            // if (timerOnData) {
            //     const hr = timer.hr * 60;
            //     const min = timer.min;
            //     const sec = timer.sec / 60;

            //     const expectedTime = (new Date().getTime() + (hr + min + sec) * 60000);
            //     console.log(timerOnData)
            //     initiateTimer(expectedTime);
            //     return;
            // }

            if (timerIntervalId.current) {
                window.clearInterval(timerIntervalId.current)
            }
            snackbar("Time's Up!!!", "The time has ended");
        });

        socket.on("Game Stop Timer", () => {
            if(timerIntervalId.current){
                setTimerOn(false);
                window.clearInterval(timerIntervalId.current);
                snackbar("Time's Up!!!", "The time has ended");
            }
        })

        EE.on("Jam Timer Event", (data: any) => {
            const { timerMin: min, timerSec: sec} = data.eventNode;
            handleEventTimer(min,sec);
        })

        if (initialTimerOn) {
            if (initialTimerValue) {
                initiateTimer(initialTimerValue);
            } else {
                setTimerOn(false);
            }
        }
    }, []);

    return (
        <ClickAwayListener onClickAway={closeTimer}>
            <div style={{ float: 'right', }}>
                <Popper
                    open={isOpen}
                    anchorEl={timerEl}
                    placement="bottom-end"
                    transition
                >
                    {({ TransitionProps }) => (
                        <Fade {...TransitionProps}>
                            <div className={classes.popper}>
                                <EjectRoundIcon className={classes.popperTriangle} />
                                {/* Timer buttons */}
                                <div className={classes.popperBtns}>
                                    {
                                        timerOn ?
                                            <Button
                                                className={classes.secondaryBtn}
                                                onClick={() => timerHandler(false)}
                                            >
                                                Stop
                                            </Button>
                                            :
                                            <>
                                                <Button
                                                    className={classes.secondaryBtn}
                                                    onClick={() => resetTimer()}
                                                >
                                                    Reset
                                                </Button>
                                                <Button
                                                    className={classes.primaryBtn}
                                                    disabled={timer.hr === 0 && timer.min === 0 && timer.sec === 0}
                                                    onClick={() => timerHandler(true)}
                                                >
                                                    Start
                                                </Button>
                                            </>
                                    }
                                </div>
                                <p className={classes.popperTitle}>
                                    Timer
                                </p>
                                <br />
                                {/* Timer fields */}
                                {!timerOn ?
                                    <>
                                        <TextField
                                            name="hr"
                                            className={classes.field}
                                            style={{ borderRadius: "5px 0 0 5px" }}
                                            InputProps={{
                                                disableUnderline: true,
                                                className: classes.fieldProp
                                            }}
                                            inputProps={{ style: { textAlign: "center" } }}
                                            onChange={(e) => handleTimerChange(e)}
                                            multiline
                                            value={formatTime(timer.hr)}
                                            onInput={handleTimerTextInput}
                                        />
                                        <TextField
                                            name="min"
                                            className={classes.field}
                                            InputProps={{
                                                disableUnderline: true,
                                                className: classes.fieldProp
                                            }}
                                            inputProps={{ style: { textAlign: "center" } }}
                                            onChange={(e) => handleTimerChange(e)}
                                            multiline={true}
                                            value={formatTime(timer.min)}
                                            onInput={handleTimerTextInput}
                                        />
                                        <TextField
                                            name="sec"
                                            className={classes.field}
                                            style={{ borderRadius: "0 5px 5px 0" }}
                                            InputProps={{
                                                disableUnderline: true,
                                                className: classes.fieldProp
                                            }}
                                            inputProps={{ style: { textAlign: "center" } }}
                                            onChange={(e) => handleTimerChange(e)}
                                            multiline={true}
                                            value={formatTime(timer.sec)}
                                            onInput={handleTimerTextInput}
                                        />
                                        <br />
                                        <p className={classes.timerLabels}>HOUR</p>
                                        <p className={classes.timerLabels}>MIN</p>
                                        <p className={classes.timerLabels}>SEC</p>
                                    </>
                                    :
                                    <>
                                        <p className={classes.fieldProp} style={{ fontSize: "24px", width: "210px", textAlign: "center", margin: "0" }}>
                                            {formatTime(timer.hr)} : {formatTime(timer.min)} : {formatTime(timer.sec)}
                                        </p>
                                        <p className={classes.fieldProp} style={{ fontSize: "12px", width: "210px", fontWeight: "normal", textAlign: "center", margin: "0" }}>
                                            {timer?.user && <>by <b>{timer.user}</b></>}
                                        </p>
                                    </>
                                }
                            </div>
                        </Fade>
                    )}
                </Popper>
                <p className={timerOn ? classes.timerDisplayOn : classes.timerDisplay}>
                    {formatTime(timer.hr)} : {formatTime(timer.min)} : {formatTime(timer.sec)}
                </p>
                <IconButton
                    className={classes.iconTopPanel}
                    onClick={toggleTimer}
                    style={{ borderRadius: "0px", marginTop: "-5px", color: isOpen ? "#4F4F4F" : "#FFF" }}
                >
                    <TimerIcon />
                </IconButton>
            </div>
        </ClickAwayListener>
    );
});
