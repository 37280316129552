import React from 'react'
import {withStyles} from '@material-ui/core'
import '../../../app/app.css'
import EE from '../../../api/eventemitter'
import { getUserId } from '../../../utility/function'
import {cardStyles} from '../user_interface/assets/cardUI'
// styles.n_card.marginBottom = "0"

class NotificationLog extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            cards: [],
        }
    }

    componentDidMount(){
    }

    render(){
        const {participants} = this.props
        let uid = getUserId()
        // console.log(participants)

        let curP = participants.find((p)=>{
            return p.uid === uid
        })


        // console.log(curP)

        return(
            <div style={{padding: "0 1em 0 1em", fontSize:"16px"}}>
                {curP && curP.notificationLog && curP.notificationLog.map((n)=>
                    
                        <div style={{padding:"5px",marginBottom:"1em",borderBottom:"solid 2px white"}}>
                            <p>{n}</p>
                        </div>
                    
                )}
            </div>
        )
    }
}

export default withStyles(cardStyles)(NotificationLog)
