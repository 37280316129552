/** Built-in Modules */

/** Third-Party Modules */
import React from 'react';
import sanitizeHtml from 'sanitize-html';
import {
  Card as MuiCard,
  ClickAwayListener,
} from '@material-ui/core';
import moment from 'moment';

/** Types */
import { ICard, ICardProps } from './card.interface';
import {
  IGameObjectLiveTypingData,
  IGameObjectParseLinksData,
} from '../game-object/game-object.interface';
import { GameObjectEvents } from '../game-object/game-object.events';

/** Config */

/** API */
import socket from '../../api/socket';

/** Components */
import { CardBack } from './card-back/card-back';
import { CardFront } from './card-front/card-front';
import { CardStackMenu } from './card-stack-menu/card-stack-menu';

/** Styling */
import classes from './card.module.scss';

const linkify = require('linkify-it')();

const sanitizeHtmlConfig = {
  allowedTags: ['b', 'i', 'em', 'strong', 'a', 'p', 'h1', 'ol', 'ul'],
  allowedAttributes: { a: ['href'] },
};

const checkLink = (content: string): string => {
  let newContent = content;
  const listOfUrls = linkify.match(content);

  //If there is a valid array and link
  if (listOfUrls && listOfUrls.length > 0) {
    //The reason why we skip every two iteration is because whenever the link gets shortened,
    //linkify will think it's a new link, therefore we always skip the extra result
    for (let i = 0; i < listOfUrls.length; i += 2) {
      const url = listOfUrls[i]
      // let urlLastIndex = url.text.length > 23 ? url.index + 26 : url.lastIndex
      //Check if there is a href string due to us parsing pure HTML rather than string
      const hrefString = newContent.substring(url.index - 2, url.index - 6);
      //Check if the closing tag is "a" to prevent double operation
      const closingTag = newContent.substring(url.lastIndex + 2, url.lastIndex + 3)

      if (closingTag !== 'a' && hrefString !== 'href') {
        const slicedHTMLBeforeLink = newContent.substr(0, url.index)
        const sliceHTMLAfterLink = newContent.substr(url.lastIndex, newContent.length)
        const shortenedText = url.text.length > 23 ? url.text.substring(0, 23).concat('...') : url.text

        newContent = slicedHTMLBeforeLink + `<a href="${url.url}">${shortenedText}</a>` + sliceHTMLAfterLink
      }
    }
  }

  return newContent;
};

export const Card = React.memo((props: ICardProps): React.ReactElement => {
  const {
    id,
    stack,
    stackId,
    stackIndex,
    isManage,
    categoryColor,
    flipped,
    flipStackFn: flipStack,
    bringBackward,
    bringForward,
    setSubDragging,
    formDeck,
    popCard,
    updateCardContent,
    quitManage,
    setManage,
    receiveTargetTextfield,

    obj,
  } = props;

  const [pinned] = React.useState<boolean>(false);
  const [readonly, setReadonly] = React.useState<boolean>(true);
  const [content, setContent] = React.useState<string>(obj.content);
  const [edited] = React.useState<boolean>(false);
  const [category] = React.useState<string>(obj.category);
  const [labels] = React.useState<string[]>((obj.labels ? obj.labels : obj.label) as string[]);
  const [origin] = React.useState<ICard['origin']>(obj.origin);
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [time] = React.useState<string | null>(obj.time);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const inputRef = React.createRef<HTMLDivElement>();

  const openMenu = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
  const closeMenu = () => {
    if (anchorEl) {
      setAnchorEl(null);
    }
  };

  const { imgUrl } = obj;

  const modTime = RegExp('(pm|am)', 'g').test(time!) ? time : moment(time!).local().format('h:mm a');

  // So the new content is now a HTML string and to change the value into a string we do this
  const stringText = new DOMParser().parseFromString(content!, 'text/html').body.textContent;

  const manageChecker = isManage === stackIndex;

  React.useEffect(() => {
    const socketEventListeners = {
      [GameObjectEvents.LIVE_TYPING]: (data: IGameObjectLiveTypingData) => {
        const { content, objId } = data;

        if (id === objId) {
          setContent(content);
        }
      },
      [GameObjectEvents.PARSE_LINKS]: (data: IGameObjectParseLinksData) => {
        const { selectedIDs } = data;

        if (selectedIDs.includes(id)) {
          setContent(sanitizeHtml(checkLink(content), sanitizeHtmlConfig));
        }
      },
    };

    if (!loaded) {
      setContent(sanitizeHtml(checkLink(content), sanitizeHtmlConfig));
      setLoaded(true);

      socket.on(GameObjectEvents.LIVE_TYPING, socketEventListeners[GameObjectEvents.LIVE_TYPING]);
      socket.on(GameObjectEvents.PARSE_LINKS, socketEventListeners[GameObjectEvents.PARSE_LINKS]);
    }

    return () => {
      socket.off(GameObjectEvents.LIVE_TYPING, socketEventListeners[GameObjectEvents.LIVE_TYPING]);
      socket.off(GameObjectEvents.PARSE_LINKS, socketEventListeners[GameObjectEvents.PARSE_LINKS]);
    };
  }, [loaded, id, content]);

  if (!loaded) {
    return <></>;
  }

  return (
    <>
      <ClickAwayListener onClickAway={quitManage}>
        <MuiCard
          onBlur={() => { quitManage(); }}
          onDoubleClick={() => {
            setSubDragging(true);
            if (isManage) {
              setReadonly(false);
              if (inputRef?.current) {
                inputRef.current.focus();
              }
              return;
            }

            setManage(stackIndex);
          }}
          draggable={manageChecker}
          onDragEnd={(event) => popCard(event, stackIndex)}
          onContextMenu={(event) => {
            event.preventDefault();
            event.stopPropagation();
            openMenu(event);
          }}
          className={classes.n_card}
          data-card-id={id}
        >
          <CardBack
            id={id}
            stack={stack}
            stackIndex={stackIndex}
            category={category}
            categoryColor={categoryColor}
            classes={classes}
            content={content}
            setContent={setContent}
            labels={labels as string[]}
            openContextMenu={openMenu}
            receiveTargetTextfield={receiveTargetTextfield}
            imgUrl={imgUrl}
            stringText={stringText!}
            readonly={readonly}
            setReadonly={setReadonly}
            updateCardContent={updateCardContent}
            contentInputRef={inputRef}
            edited={edited}
            flippedStack={flipped}
            manageChecker={manageChecker}
            modTime={modTime!}
            pinned={pinned}
            username={origin!.username}
          />
          <CardFront
            category={category}
            categoryColor={categoryColor}
            flipped={flipped}
            edited={edited}
            updatedAt={modTime!}
            pinned={pinned}
            username={origin!.username}
            openContextMenu={openMenu}
            classes={classes}
          />
        </MuiCard>
      </ClickAwayListener>
      {(Boolean(anchorEl) && stack) && (
        <CardStackMenu
          anchorEl={anchorEl}
          stackId={stackId}
          stackIndex={stackIndex}
          bringBackward={bringBackward}
          bringForward={bringForward}
          closeMenu={closeMenu}
          flipStack={flipStack}
          formDeck={formDeck}
          popCard={popCard}
        />
      )}
    </>
  );
});
