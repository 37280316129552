import React from 'react'
import {withStyles, Grid, Avatar, InputBase, Button, Modal} from '@material-ui/core'
import {Query,Mutation} from 'react-apollo'
import {withRouter} from 'react-router-dom'
import Loader from '../component/loader'
import {GET_USER_BY_TOKEN} from '../api/graphql'
import {USER_UPDATE_PASSWORD_BY_ID} from '../api/graphql-mutation'
import {isEmpty} from '../utility/function'
import Error from './error'

const styles = {
    container: {
        position:"fixed",
        height:"100%", 
        width:"100%",
        background:"#F2F2F2",
        fontFamily:"arial",
    },

    box:{
        margin:"auto",
        height:"90%",
    },

    field:{
        display:"block",
        padding:"0 5px",
        width:"15rem",
        background:"#FAF7EA",
        border:"1px solid #D1D1D1",
        borderRadius:"5px",
        marginBottom:"1em",
    },

    buttons:{
        width:"15rem",
        background:"#D1D1D1",
        color:"#FFFFFF",
    },

    colorButton:{
        background:"#FDAF2A",
    },

    avatar:{
        width:"7rem",
        height:"7rem"
    },
    
    header:{
        fontWeight:"bold",
        fontSize:"1rem",
    },

    hint: {

    },

    modal:{
        padding:"40px", 
        borderRadius:"5px", 
        width:"300px", 
        margin:"auto", 
        marginTop:"12%", 
        background:"white",
        border:'none',
        outline:'none'
    },
    modalHeader:{
        color:"#4F4F4F", 
        fontSize:"14px", 
        fontFamily:"arial", 
        margin:"0", 
        marginBottom:"20px",
        textAlign:"center",
        fontWeight:"bold"
    },
    modalText:{
        color:"#4F4F4F", 
        fontSize:"0.8rem", 
        fontFamily:"arial", 
        margin:"0", 
        marginBottom:"20px",
        textAlign:"center"
    }
}


class PasswordReset extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            password: "",
            retype: "",
            open: false,
            error: false
        }
        
        this.loginTimeOut = null
    }

    onChange(event){
        if(this.state.retype === "" || this.state.password === ""){
            document.getElementById("button").style.background = "#D1D1D1"
        }else{
            document.getElementById("button").style.background = "#FDAF2A"
        }
    }

    handleChange(event){
        let n = event.target.name
        this.setState({
            [n]: event.target.value
        })
    }

    passwordChecker(){
        if(this.state.password === this.state.retype && this.state.retype !== ""){
            return true
        }else{
            return false
        }
    }

    render(){
        const token = this.getToken();
        const {classes} = this.props

        console.log(this.state)

        if(token){
            return(
                <Query query={GET_USER_BY_TOKEN(token)}>
                    {({loading,error,data}) => {
                        if(loading) return <Loader/>
                        if(error) return <div> Error :( , Cant Find </div>
                        
                        if(isEmpty(data.userMany)){
                            return (<Error message="Invalid Token."/>)
                        }else{

                            const result = data.userMany[0]

                            return(
                                <div className={classes.container}>
                                    <Grid container justify="center" alignItems="center" className={classes.box}>
                                        <Grid direction="column" justify="center" alignItems="center" spacing={2} container>
                                            <Grid item>
                                                <Avatar className={classes.avatar} src={require('../element/reset-password.png')}></Avatar>
                                            
                                            </Grid>
                                            <Grid item className={classes.header}>
                                                🗄 Reset your password
                                            </Grid>
                                            <Grid item>
                                            {this.state.error && 
                                            <p style={{color:"#E97610", textAlign:"center", margin:"0"}}>
                                                Please make sure both of your passwords match
                                            </p> }
                                            </Grid>
                                            <Grid item>
                                                <form onSubmit={(e)=>{e.preventDefault()}} noValidate autoComplete="off">
                                                    <InputBase className={classes.field}
                                                        value={this.state.password}
                                                        name="password"
                                                        type="password"
                                                        placeholder="Type your password"
                                                        onClick={() =>{this.setState({error: false})}}
                                                        onChange={(e)=>{this.onChange(e); this.handleChange(e)}}
                                                        inputProps={{style: {fontSize: "0.875rem", fontFamily:"arial", textAlign:"center"}}}
                                                    />
                                                    <InputBase className={classes.field}
                                                        value={this.state.retype}
                                                        name="retype"
                                                        type="password"
                                                        placeholder="Re-type your password"
                                                        onClick={() =>{this.setState({error: false})}}
                                                        onChange={(e)=>{this.onChange(e); this.handleChange(e)}}
                                                        inputProps={{style: {fontSize: "0.875rem", fontFamily:"arial", textAlign:"center"}}}
                                                    />
                                                    <Mutation mutation={USER_UPDATE_PASSWORD_BY_ID} variables={{_id: result._id, password: this.state.retype}}>
                                                        {passwordByUID => 
                                                            <Button 
                                                                type="submit"
                                                                onClick={()=>{
                                                                    if(this.passwordChecker()){
                                                                        passwordByUID().then(()=>{
                                                                            this.openPopUp()
                                                                        })
                                                                    }else{
                                                                        this.setState({
                                                                            error: true
                                                                        })
                                                                    }
                                                                }} 
                                                                className={classes.buttons} id="button"
                                                            >
                                                                CONFIRM
                                                            </Button> 
                                                        }
                                                    </Mutation>
                                                </form>
                                                <p className={classes.hint}>Hint: Set a strong password that you can remember.</p> 
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Modal open={this.state.open}  onClose={this.closePopUp} aria-labelledby="Forgot Password Succeed">
                                        <div className={classes.modal}>
                                            <p className={classes.modalHeader}>
                                                Password Updated
                                            </p>
                                            <Avatar className={classes.avatar} style={{left:"25%", marginTop:"10px", marginBottom:"20px"}} 
                                            src={require('../element/passwordUpdatedLady.png')}></Avatar>
                                            <Button 
                                                type="submit" 
                                                onClick={()=>{
                                                    clearTimeout(this.loginTimeOut)
                                                    this.props.history.replace({pathname: "/login"})
                                                }}
                                                className={classes.buttons}
                                                style = {{
                                                    background:"#FDAF2A",
                                                    width: "100%",
                                                }}
                                            >
                                                LOG IN NOW
                                            </Button>  
                                            
                                            <p 
                                                className={classes.modalText} 
                                                style={{
                                                    marginTop:"20px", 
                                                    marginBottom:"0",
                                                }}
                                            >
                                                You will be directed to login page in 5 seconds...
                                            </p>
                                            
                                        </div>

                                    </Modal>
                                </div>
                            )
    
                        }

                        
                    }}
                </Query>
            )
        }

    }
    openPopUp = () =>{
        this.setState({
            open: true
        }, () =>{
            this.loginTimeOut = setTimeout(() =>{
                this.props.history.replace({
                    pathname: "/login"
                })
            }, 5000)
        })
    }

    closePopUp = () =>{
        this.setState({
            open: false
        })
    }
    getToken = () => {
        return window.location.href.substring(window.location.href.indexOf("reset/") + 6,window.location.href.length)
    }
}


export default withRouter(withStyles(styles)(PasswordReset))