import React from 'react';
import {withStyles, Modal, Avatar, InputBase, Button, Grid} from '@material-ui/core';
import {withApollo} from 'react-apollo';
import { withRouter } from 'react-router-dom'

import {USER_RESET_EMAIL} from '../../../api/graphql'

const styles = {
    container: {
        position:"fixed",
        height:"100%", 
        width:"100%",
        overflowY:"auto",
        overflowX:"hidden",
        background:"#F2F2F2",
        fontFamily:"arial",
    },

    box:{
        margin:"auto",
        height:"90%"
    },

    field:{
        display:"block",
        padding:"0 5px",
        width:"15rem",
        background:"#FAF7EA",
        border:"1px solid #E97610",
        borderRadius:"5px",
        marginBottom:"1em",
    },

    buttons:{
        width:"15rem",
        background:"#D1D1D1",
        color:"#FFFFFF",
    },

    colorButton:{
        
        margin:"auto",
        background:"#FDAF2A",
    },

    avatar:{
        width:"7rem",
        height:"7rem"
    },
    
    header:{
        fontWeight:"bold",
        fontSize:"1rem",
    },

    modal:{
        padding:"40px", 
        borderRadius:"5px", 
        width:"300px", 
        margin:"auto", 
        marginTop:"15%", 
        background:"white",
        border:'none',
        outline:'none'
    },
    modalHeader:{
        color:"#4F4F4F", 
        fontSize:"14px", 
        fontFamily:"arial", 
        margin:"0", 
        marginBottom:"20px",
        textAlign:"center",
        fontWeight:"bold"
    },
    modalText:{
        color:"#4F4F4F", 
        fontSize:"0.8rem", 
        fontFamily:"arial", 
        margin:"0", 
        marginBottom:"20px",
        textAlign:"center"
    }
}

class ForgotPassword extends React.Component {
    constructor(props) { //holds data to be parsed into the page
        super(props);
        this.state = {
            email: '',
            open: false,
            invalidEmail: false,
        };
    }
    
    render() {
        
        const {classes } = this.props
        const {email, open, invalidEmail} = this.state

        return (
            <div className={classes.container}>
                <Grid container  justify="center" alignItems="center" className={classes.box}>
                    <Grid direction="column" justify="center" alignItems="center" spacing={2} container>
                        <Grid item>
                            <Avatar className={classes.avatar} src={require('../../../element/forgotPasswordLady.png')}></Avatar>                            
                        </Grid>
                        <Grid item className={classes.header}>
                            Forgot Password
                        </Grid>
                        <Grid item>
                            <p style={{color:"#E97610", textAlign:"center", margin:"0"}}>
                                Please input a valid email
                            </p> 
                        </Grid>
                        <Grid item>
                            <form  onSubmit={(e)=>{e.preventDefault();}} noValidate autoComplete="off">
                                <InputBase className={classes.field}
                                    autoFocus
                                    value={email}
                                    name="email"
                                    placeholder="your email address"
                                    onChange={(e)=>{this.handleChange(e)}}
                                    inputProps={{style: {fontSize: "0.875rem", fontFamily:"arial", textAlign:"center"}}}
                                />
                                <Button 
                                    type="submit" 
                                    id="button" 
                                    onClick={this.submitEmail}
                                    className={classes.buttons}
                                    style = {email==="" ? {color:"white", background:"#D1D1D1"} : {color:"white", background:"#FDAF2A"}}
                                    disabled = {email==="" ? true:false}
                                >
                                    RESET PASSWORD
                                </Button>  

                                <p style={{textAlign:"center", marginTop:"10px", color:"#4F4F4F", fontSize:"0.8rem"}}>
                                    Enter your email address and we will<br/>send you a link to reset your password.
                                </p>     
                            </form>
                        </Grid>
                    </Grid>
                </Grid>
                <Modal open={open} onClose={this.closePopUp} aria-labelledby="Forgot Password Succeed">
                    <div className={classes.modal}>
                        <p className={classes.modalHeader}>
                            {invalidEmail ?
                                "Email not found!" :
                                "Hang in there, check your email" 
                            }
                        </p>
                        <p className={classes.modalText}>
                            {invalidEmail ?
                            <>
                                Hmm, I'm sorry though it seems that <b>{email}</b> is not registered in this platform<br/><br/>
                            </> :
                            <>
                                We have sent a set of instruction to <b>{email}</b> for you to change your password.<br/><br/>
                                Can't find it? Please check your Spam folder.
                            </>
                            }
                        </p>
                        
                        <Button 
                            type="submit" 
                            onClick={() =>{
                                this.closePopUp()
                                !invalidEmail && this.props.history.push({pathname:'/login'});
                            }}
                            className={classes.buttons}
                            style = {{
                                background:"#FDAF2A",
                                width: "100%",
                            }}
                        >
                            OKAY, GOT IT
                        </Button>  
                    </div>

                </Modal>
            </div>
        );
        
    }

    handleChange = (event) =>{
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    submitEmail = () =>{
        this.props.client.query({
            query: USER_RESET_EMAIL(this.state.email)
        })
        .then((res) =>{
            this.setState({
                invalidEmail: Boolean(!res.data.userResetEmail),
                open: true
            }, () =>{
                if(!this.state.invalidEmail){
                    setTimeout(() =>{
                        this.props.history.push({
                            pathname:'/login'
                        })
                    }, 5000)
                }
            });
        })
    }

    openPopUp = () =>{
        this.setState({
            open: true
        })
    }

    closePopUp = () =>{
        this.setState({
            open: false
        })
    }
}

export default withApollo(withRouter(withStyles(styles)(ForgotPassword)))