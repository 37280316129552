//Node modules
import React from 'react'
import {withStyles} from '@material-ui/core'
import { ReactSortable } from "react-sortablejs";
import {withApollo} from 'react-apollo'
import moment from "moment";
import request from 'superagent';
import ContentEditable from 'react-contenteditable';

//Other modules
import '../../../app/app.css'
import EE from '../../../api/eventemitter'
import config from '../../../config/backend'
import {getUserId, getUsername, isEmpty, isLeader} from '../../../utility/function'
import {GAME_UPDATE_PLAYER_HAND, GAME_UPDATE_DECK, GAME_UPDATE_INHAND} from '../../../api/graphql-mutation'
import {cardStyles} from '../user_interface/assets/cardUI'
import HandCard from '../objects/hand/card';
import socket from '../../../api/socket';
import {drawLoop, drawToMany} from '../../../utility/draw'
import PlayerState from '../state/playerState'
import sanitizeHtml from "sanitize-html"
import groupCard from '../../../element/2024feb/groupCard.png';
let linkify = require('linkify-it')()

ReactSortable.prototype.onChoose = () => {};
ReactSortable.prototype.onUnchoose = () => {};

//TODO: Sync the card styles with the new card style
class GamePlayerHand extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            playerHand: [],
            createdHand: [],
            onDragIndex: null,
            onSumbitIndex: null,
            filtered: false,
            defaultDraw: false,
            draggedObjContent: null,
            blankDragged: false,
            gameDeck: this.props.gameDeck,

            html: ""
        }
        this.Content = [];
        this.timer = null
    }

    componentDidMount(){
        this.setState({
            playerHand: this.getInhand()
        }, ()=>{
            PlayerState.cards = this.getCardsLeft()
        });

        // if(isEmpty(this.state.playerHand)){
        //     console.log("haha")
        //     this.initialDraw()
        // }

        // socket.on("Game Initial Draw", (data)=>{
        //     let uid = getUserId();
        //     let index = data.initial.findIndex((n)=>{return n.uid === uid})

        //     this.setState({
        //         gameDeck: data.deck,
        //         playerHand: data.initial[index].objects,
        //         defaultDraw: true,
        //     },()=>{
        //         this.mutatePlayerHand()
        //     })
        // })

        socket.on("Game Sync Deck", (data)=>{
            // console.log("Game Sync Deck",data)

            this.setState({
                gameDeck: data.deck
            })
        })

        EE.on("Leader Initial Draw Loop",()=>{
            setTimeout(()=>{
                this.initialDraw();
            },1000)
        })

        EE.on("Game Draw To Many",(data)=>{
            const {script} = data
            let deck = this.state.gameDeck
            let participants = this.props.participants
            let gid = this.props.gid
            drawToMany(participants,deck,script,gid)
        })

        EE.on("Game Unreceived Draw All",(data)=>{
            //const {hand} = data
            //console.log(data)
            this.setState((prev)=>({
                playerHand:[...data, ...prev.playerHand]
            }),()=>{
                PlayerState.cards = this.getCardsLeft()
                this.mutatePlayerHand()
            })
        })

        EE.on("Game Unreceived Draw Action",(data)=>{
            const {event, penalizeId} = data
            let uid = getUserId()
            console.log(data,uid)
            if(uid === penalizeId){

                // let custom = this.props.deckConfig.drawConfig.onCustomEvent;
                // console.log(custom)
                // let e = custom.find((n)=>{return n.name === event.name })
                // // console.log(e)

                if(event){
                    let deck = this.state.gameDeck
                    let hand = drawLoop(deck, event)
                    hand = hand.filter((n)=>{return n !== undefined})
                    this.setState((prev)=>({
                        playerHand: [...hand, ...prev.playerHand]
                    }),()=>{
                        PlayerState.cards = this.getCardsLeft()
                        this.mutateDeck()
                        this.mutatePlayerHand()
                        this.syncDeck()
                    })
                }
            }
        })

        socket.on("Game Custom Draw Action", (data)=>{ // automation **
            const {event, penalizeId} = data
            //console.log(data)
            let uid = getUserId()
            if(uid === penalizeId){

                // let custom = this.props.deckConfig.drawConfig.onCustomEvent;
                // console.log(custom)
                // let e = custom.find((n)=>{return n.name === event.name })
                // // console.log(e)

                if(event){
                    let deck = this.state.gameDeck
                    let hand = drawLoop(deck, event)
                    hand = hand.filter((n)=>{return n !== undefined})
                    this.setState((prev)=>({
                        playerHand: [...hand, ...prev.playerHand]
                    }),()=>{
                        PlayerState.cards = this.getCardsLeft()
                        this.mutateDeck()
                        this.mutatePlayerHand()
                        this.syncDeck()
                    })
                }
            }
        })

        socket.on("Game Draw To Many",(data)=>{
            const {dom,deck} = data
            let uid = getUserId()

            let n = dom.find((i)=>{return i.uid === uid})
            this.setState((prev)=>({
                gameDeck: deck,
                playerHand: [...prev.playerHand, ...n.objects]
            }),()=>{
                this.mutatePlayerHand()
            })
        })

        EE.on("Game OnPass Draw Action", ()=>{
            let onPass = this.props.deckConfig.drawConfig.onPass;
            let deck = this.state.gameDeck
            let hand = drawLoop(deck,onPass)

            this.setState((prev)=>({
                playerHand: [...prev.playerHand, ...hand]
            }),()=>{
                PlayerState.cards = this.getCardsLeft()
                this.mutateDeck()
                this.mutatePlayerHand()
                this.syncDeck()
            })

        })

        EE.on("Create Card", ()=>{
            this.createCard()
        })

        EE.on("Game Remove Card", ()=>{
            if(this.state.onDragIndex !== null){
                this.removeCards(this.state.onDragIndex)
                this.setState({
                    onDragIndex: null,
                })
            }
        })

        EE.on("Game Recover Card", (data)=>{
            this.setState((prev)=>({
                playerHand: [...prev.playerHand, data.card]
            }), ()=>{
                PlayerState.cards = this.getCardsLeft()
            })
        })

        EE.on("Game Draw One Card", ()=>{
            this.drawOneStory(this.props.gameDeck)
        })

        EE.on("Game Interrupt Failed Draw", (data)=>{
            const id = getUserId()
            if(data.id === id){
                this.drawOneStory(this.props.gameDeck)
                this.drawOneStory(this.props.gameDeck)
            }else{
                return
            }
        })

        EE.on("Game Interrupt Success Draw", (data)=>{
            const id = getUserId()
            if(data.id === id){
                this.drawOneStory(this.props.gameDeck)
            }else{
                return
            }
        })

        //Received from blankCard
        EE.on("IHL Blank Card", () =>{
            var newCard = {
                content: "",
                time: moment().local().format("h:mm a"),
                username: getUsername(),
                tag: ["Story jam Card"],
                label: ["Blank"],
                value: "",
                flip: false,
                status: "incomplete",
                anchorEl : null,
                type: 'blank',
                category: "Blank",
                imgUrl: null,
            }

            this.setState((prev) =>({
                playerHand: [newCard, ...prev.playerHand]
            }),()=>{
                PlayerState.cards = this.getCardsLeft()
                this.mutatePlayerHand()
            });
        })

        //Received from card
        EE.on("Privatise Card", (data) =>{
            const {id, flip, time, content, category, type, label, styling, origin, imgUrl} = data.cardState
            let newCard = {
                content: content,
                time: time,
                username: origin.username,
                flip: flip,
                value: content,
                tag: ["Story jam Card"],
                anchorEl : null,
                type: type,
                label: label,
                styling: styling,
                category: category,
                id: id,
                imgUrl: imgUrl
            }

            this.setState((prev)=>({
                playerHand: [newCard, ...prev.playerHand]
            }),()=>{
                PlayerState.cards = this.getCardsLeft()
                this.mutatePlayerHand()
            })

        })

        EE.on("Multiple Privatise Card", (data) =>{

            const {cardArr} = data

            this.setState((prev) =>({
                playerHand: [...cardArr, ...prev.playerHand]
            }), () =>{
                PlayerState.cards = this.getCardsLeft()
                this.mutatePlayerHand()
            })
        })

        //Called from gameCanvas
        EE.on("Clear In Hand", () =>{
            let newPlayerHand = this.state.playerHand.filter((card) =>{
                return card.type !== "predefined"
            })
            this.setState({
                playerHand: newPlayerHand
            },()=>{
                if(this.props.deckConfig.drawConfig.loop === true){
                    let uid = getUserId();
                    if(isLeader(uid,this.props.createdBy._id)){
                        this.initialDraw();
                    }
                }else{
                    this.mutatePlayerHand()
                }
                PlayerState.cards = this.getCardsLeft()
            });
        })

        this.updateLinkInCard()
    }

    getColortag(classes, card){
        var tag = card.tag[card.tag.length-1]
        for(var i=0; i<this.state.colortag.length; i++){
            if(this.state.colortag[i].category===tag){
                var color = this.state.colortag[i].color
                return (<div className={classes.cardtag} style={{background:color}}></div>)
            }
        }
        return (<div className={classes.cardtag}></div>)
    }

    getInhand(){
        let x = this.props.inhand;
        let uid = getUserId();
        let private_inhand = x.filter((n)=>{
            return n.uid === uid
        })


        if(private_inhand[0]){
            if(!isEmpty(private_inhand[0].objects)){
                return private_inhand[0].objects
            }else{
                return []
            }
        }else{
            return []
        }


    }

    checkComplete = (card) => {
        if(card.status === "completed"){
            return "true"
        }else{
            return "false"
        }
    }

    render(){
        const {classes} = this.props
        const editable = true
        const hasFlip = true
        const hasPin = false
        const hasImage = true
        const hasMenu = true
        // console.log(this.state.playerHand)

        if(this.state.playerHand){

            let {setOnDrag, openMenu, onClick, onChange, closeMenu, flipCard, onSubmit, handleChangePlayerHand} = this
            let func = {
                setOnDrag,
                openMenu,
                onClick,
                onChange,
                closeMenu,
                flipCard,
                onSubmit,
                handleChangePlayerHand,
            }

            return(
                <div onDrop={()=>{this.checkDrop()}}>
                    <div 
                    onClick={()=>{EE.emit("IHL Blank Card")}}
                    style={{
                        color:"#4f4f4f",
                        padding:"1em",
                        fontSize:"14px",
                        fontWeight:"bold",
                        background:"white",
                        display:"flex",
                        width:"80%",
                        margin:'auto',
                        justifyContent:"center",
                        borderRadius:"10px",
                        cursor:"pointer",
                        marginBottom:"1em"
                    }}
                    >Draw Card&nbsp;
                    <span style={{fontSize:"14px",fontWeight:"bold",color:"#ffaa0d"}}>{`(Blank)`}&nbsp;&nbsp;</span> 
                    <img src={groupCard} width={"15px"}/></div>
                    <ReactSortable
                        animation = {150}
                        list={this.state.playerHand}
                        filter="#editable-menu-inhand, #inhand-editable-sub"
                        preventOnFilter={false}
                        setList={newState => this.setState({playerHand:newState})}
                    >
                        {this.state.playerHand && this.state.playerHand.map((card,index)=>{
                            return (
                                <HandCard
                                    key={`${index}-${card}`}
                                    {...func}
                                    index={index}
                                    card={card}
                                    editable={editable}
                                    hasFlip={hasFlip}
                                    hasImage={hasImage}
                                    hasPin={hasPin}
                                    hasMenu={hasMenu}
                                    listPinIds={this.props.listPinIds}
                                    categoryColor={this.props.categoryColorMap.get(card?.category)}
                                    imgData = {this.props.imgData}
                                    onImgUpload = {this.onImgUpload}
                                    onImgPaste = {this.onImgPaste}
                                    removeImg = {this.removeImg}
                                    onDrop = {this.onDrop}
                                    checkLink = {this.checkLink}
                                    isPlayerHand
                                    removeCards = {this.removeCards}
                                />
                            )
                        })}
                    </ReactSortable>
                </div>
            )
        }
        else {return null}
    }

    flipCard = (index) =>{
        const playerHandClone = this.state.playerHand
        playerHandClone[index].flip = !playerHandClone[index].flip
        playerHandClone[index].anchorEl = null
        this.setState({
            playerHand: playerHandClone
        },()=>{
            this.mutatePlayerHand()
        })
    }

    onSubmit = (e, index) =>{
        e.preventDefault();
        const playerHandClone = this.state.playerHand
        playerHandClone[index].status = "completed"
        this.setState({
            playerHand: playerHandClone
        },()=>{
            this.mutatePlayerHand()
        })

        // console.log(this.state.playerHand)
    }

    onClick = (e, index) =>{
        const playerHandClone = this.state.playerHand
        playerHandClone[index].status = "incomplete"
        this.setState({
            playerHand: playerHandClone
        })
        // console.log(this.state.playerHand)
    }

    onChange = (e, index) =>{
        if(e.target.value === ""){
            document.getElementById(index+"-sendBtnPH").style.color = "#C4C4C4"
            // document.getElementById(objId+"-sendBtnMain").disabled = true
        }else{
            document.getElementById(index+"-sendBtnPH").style.color = "#4173D6"
            // document.getElementById(objId+"-sendBtnMain").disabled = false
        }
        if(e.target.value.length > 78){
          return false;
        }
        document.getElementById(index+"-noWordPH").innerHTML = (78 - e.target.value.length)
    }

    openMenu = (index) => (e) =>{
        const playerHandClone = this.state.playerHand
        playerHandClone[index].anchorEl = e.currentTarget
        this.setState({
            playerHand: playerHandClone
        })
    }

    closeMenu = (index) => (e) => {
        const playerHandClone = this.state.playerHand
        playerHandClone[index].anchorEl = null
        this.setState({
            playerHand: playerHandClone
        })
    }


    checkDrop = () => {
        if(this.state.blankDragged === true){
            this.createCard()
            this.setState({
                blankDragged: false
            })
        }else{
            return
        }
    }

    handleChange = (e,index) =>{
        clearTimeout(this.timer)

        let n = this.state.createdHand
        n[index].value = e.target.value

        this.timer = setTimeout(this.triggerChange(n),1000)
    }

    triggerChange = (n) =>{
        this.setState({createdHand: n})
    }

    handleChangePlayerHand = (e, index) =>{
        //e.persist()
        let n = this.state.playerHand
        n[index].content = e.target.value.substring(0,150)

        // console.log(e.target.value.substring(0,150))
        this.triggerChangePlayerHand(n)
    }

    /**
     * updateLinkInCard - updates all cards' links
     */
    updateLinkInCard = () =>{
        this.setState(state=>{
            const playerHand = state.playerHand

            for(var i=0; i<playerHand.length; i++){
                let newContent = playerHand[i].content
                let listOfUrls = linkify.match(playerHand[i].content)

                if(listOfUrls && listOfUrls.length > 0){
                    for(let i = 0; i < listOfUrls.length; i++){
                    let url = listOfUrls[i]
                    //Check if there is a href string due to us parsing pure HTML rather than string
                    let hrefString = newContent.substring(url.index - 2, url.index - 6)
                    //Check if the closing tag is "a" to prevent double operation
                    let closingTag = newContent.substring(url.lastIndex + 2, url.lastIndex + 3)

                    if(closingTag !== "a" && hrefString !== 'href'){
                        let slicedHTMLBeforeLink = newContent.substr(0, url.index)

                        let sliceHTMLAfterLink = newContent.substr(url.lastIndex, newContent.length)

                        newContent = slicedHTMLBeforeLink + `<a href="${url.url}" >${url.text.length > 23 ? url.text.substring(0,23).concat("...") : url.text}</a>`+ sliceHTMLAfterLink
                    }
                    }
                }
                playerHand[i].content = sanitizeHtml(newContent, this.sanitizeConf)
            }
            return {playerHand}
        })
    }

    /**
     * checkLink - checks if link exists
     */
    checkLink = (index) =>{
        let newContent = this.state.playerHand[index].content
        let listOfUrls = linkify.match(this.state.playerHand[index].content)
        //If there is a valid array and link
        if(listOfUrls && listOfUrls.length > 0){
          for(let i = 0; i < listOfUrls.length; i++){
            let url = listOfUrls[i]
            //Check if there is a href string due to us parsing pure HTML rather than string
            let hrefString = newContent.substring(url.index - 2, url.index - 6)
            //Check if the closing tag is "a" to prevent double operation
            let closingTag = newContent.substring(url.lastIndex + 2, url.lastIndex + 3)

            if(closingTag !== "a" && hrefString !== 'href'){
                const slicedHTMLBeforeLink = newContent.substr(0, url.index)
                const sliceHTMLAfterLink = newContent.substr(url.lastIndex, newContent.length)
      
                newContent = slicedHTMLBeforeLink + `<a href="${url.url}">${url.text}</a>` + sliceHTMLAfterLink;
            }
          }
        }

        this.setState(state=>{
            const playerHand = state.playerHand
            playerHand[index].content = sanitizeHtml(newContent, this.sanitizeConf)

            return {playerHand}
        })
    }

    triggerChangePlayerHand = (n) =>{
        this.setState({playerHand: n},()=>{
            this.mutatePlayerHand()
        })
    }

    getCardsLeft = () =>{
        let cards = {}

        for(let i = 0; i < this.state.playerHand.length; i++){
            let card = this.state.playerHand[i]
            let cardType = card.type
            let cardCategory = card.category

            if(!cards.hasOwnProperty(cardType)){
                cards[cardType] = {}
            }

            if(!cards[cardType].hasOwnProperty(cardCategory)){
                cards[cardType][cardCategory] = 1
            }
            else{
                ++cards[cardType][cardCategory]
            }
        }

        /*
        Example structure
        card = {
            blank:
                blank: 1

            predefined:
                story: 4
                ending: 1
        }
        */

        return cards
    }

    // ruleCard() {
    //     var dots = document.getElementById("dots");
    //     var text = document.getElementById("more");
    //     var btn = document.getElementById("readMoreBtn");

    //     if (dots.style.display === "none") {
    //         dots.style.display = "inline";
    //         btn.innerHTML = "Read More";
    //         text.style.display = "none";
    //     } else {
    //         dots.style.display = "none";
    //         btn.innerHTML = "Read Less";
    //         text.style.display = "inline";
    //     }

    // }

    createCard = () =>{
        var newCard = {
            Content: this.state.createdHand.length,
            tag: ["Blank Card"],
        }

        this.setState((prev)=>({
            createdHand: [newCard, ...prev.createdHand]
        }),()=>{
            PlayerState.cards = this.getCardsLeft()
        })
        // console.log(this.state.createdHand.length)
    }

    removeCard = (index) => {
        const newcreatedHand = this.state.createdHand
        newcreatedHand.splice(index,1)
        this.setState({
            createdHand: newcreatedHand
        }, ()=>{
            PlayerState.cards = this.getCardsLeft()
        })
    }

    mutatePlayerHand = () => {
        this.props.client.mutate({
            mutation: GAME_UPDATE_PLAYER_HAND,
            variables: {
                _id: this.props.gid,
                uid: getUserId(),
                hand: this.state.playerHand,
            }
        })
    }

    drawInterrupt = (deck, arr) => {
        let interrupt = deck.story.filter((n)=>{return n.label.includes("Interrupt")})
        // console.log(interrupt)

        var r = Math.floor(Math.random() * interrupt.length);
        arr.push(interrupt[r])

    }

    drawElement = (deck, arr, n) => {

        while(arr.length < n){
            var r = Math.floor(Math.random() * deck.story.length);
            arr.push(deck.story[r])
        }
        return arr
    }

    drawEnding = (deck,arr) => {
        var r = Math.floor(Math.random() * deck.ending.length);
        arr.push(deck.ending[r])
    }

    drawCards = (deck) => {
        // console.log(deck)
        var arr = []
        this.drawElement(deck,arr,3);
        this.drawInterrupt(deck,arr)
        this.drawEnding(deck,arr);

        return arr;
    }

    drawOneStory = (deck) => {
        // console.log("Drawing")
        var r = Math.floor(Math.random() * deck.story.length) + 1;
        var newCard = deck.story[r]

        // console.log(newCard)

        this.setState((prev)=>({
            playerHand: [...prev.playerHand, newCard]
        }), ()=>{
            PlayerState.cards = this.getCardsLeft()
        })
    }

    drawOneEnding = (deck) => {
        var r = Math.floor(Math.random() * deck.ending.length) + 1;
        var newCard = deck.ending[r]

        this.setState((prev)=>({
            playerHand: [...prev.playerHand, newCard]
        }), ()=>{
            PlayerState.cards = this.getCardsLeft()
        })
    }

    removeCards = (index) => {
        // console.log(index)
        const temp = this.state.playerHand
        temp.splice(index,1)
        this.setState({
            playerHand: temp
        },()=>{
            PlayerState.cards = this.getCardsLeft()
            this.mutatePlayerHand()
        })
    }

    setOnDrag = (index) => {
        this.setState({
            onDragIndex: index
        })
    }

    initialDraw = () => {
        let deck = this.props.gameDeck
        let participants = this.props.participants
        let initial = []
        let initialDrawConfig = this.props.deckConfig.drawConfig.initialDraw

        for(var i = 0; i<participants.length; i++){

            // draw loop
            var hand = drawLoop(deck,initialDrawConfig)

            let player = {
                uid: participants[i].uid,
                objects: hand
            }

            initial.push(player);
        }

        this.setState({
            gameDeck: deck
        },()=>{
            this.mutateDeck();
            socket.emit("Game Initial Draw", {initial: initial, deck: this.state.gameDeck})
        })
    }

    normalDraw = (n,labels,cat) => {
        this.drawCard(this.state.gameDeck,n,labels,cat)
        this.syncDeck();
    }

    clearHand = () => {
        this.setState({
            playerHand: []
        },()=>{
            this.mutatePlayerHand()
        })
    }

    // sync & backend functions

    syncDraw = () => {
        let draw = ''
        socket.emit("Game Sync Draw",{draw: draw})
    }

    syncDeck = () => {
        let deck = this.state.gameDeck
        socket.emit("Game Sync Deck", {deck:deck})
    }

    mutateDeck = () => {
        this.props.client.mutate({
            mutation: GAME_UPDATE_DECK,
            variables: {
                _id: this.props.gid,
                deck: this.state.gameDeck,
            }
        })
        // .then((status)=>{console.log(status)})
    }

    mutateInhand = (inhand) => {
        this.props.client.mutate({
            mutation: GAME_UPDATE_INHAND,
            variables: {
                _id: this.props.gid,
                inhand: inhand
            }
        })
        // .then((status)=>{console.log(status)})
    }

    /***
 * onImgUpload is to handle img upload
 */
    onImgUpload = (event, index) =>{

        if (event.target.files && event.target.files[0]) {
            // let imgTemp = {
            //     imgSrc : URL.createObjectURL(event.target.files[0]),
            //     imgUploaded: true
            // }
            // this.setState({
            //     imgData: imgTemp
            // })

            const file = event.target.files[0];
            const data = new FormData();
            data.append('image', file);
            const req = request.post(`${config.backend.uri}/api/upload`);

            req.on('progress',(event)=>{
                const percent = Math.floor(event.percent);
                if (percent >= 100) {
                    this.setState({ imageCompleted: 100 });
                } else {
                    this.setState({ imageCompleted: percent });
                }
            });

            req.send(data);
            req.end((err, res) => {

                // this.changeCard(index, {type: "Card Image", data: res.body.data.upload.url})

                let n = this.state.playerHand
                // console.log(n, n[index])
                n[index].imgUrl = res.body.data.upload.url
                this.triggerChange(n)


            });
        }
    }
    /***
     * onImgPaste is to handle paste event in textarea if img is pasted
     */
    onImgPaste = (event, index) =>{
        // use event.originalEvent.clipboard for newer chrome versions
        // console.log(event.clipboardData)
        // console.log(event.clipboardData.getData('text'));

        var items = (event.clipboardData  || event.originalEvent.clipboardData).items;
        // find pasted image among pasted items
        var blob = null;
        for (var i = 0; i < items.length; i++) {
            if (items[i].type.indexOf("image") === 0) {
            blob = items[i].getAsFile();
            }
        }

        if(blob !== null){
            const data = new FormData();
            data.append('image', blob);

            const req = request.post(`${config.backend.uri}/api/upload`);

            req.on('progress',(event)=>{
                const percent = Math.floor(event.percent);
                if (percent >= 100) {
                    this.setState({ imageCompleted: 100 });
                } else {
                    this.setState({ imageCompleted: percent });
                }
            });

            req.send(data);
            req.end((err,res)=>{

                let n = this.state.playerHand
                n[index].imgUrl = res.body.data.upload.url
                this.triggerChangePlayerHand(n)
            })
        }else{
            alert("No image detected!")
        }

        // load image if there is a pasted image & if textarea is null
        // if (blob !== null && !this.state.content) {
        //     var reader = new FileReader();
        //     let result = ""

        //     reader.onload = function(event) {
        //         result = event.target.result;
        //     };

        //     let imgTemp = {
        //         imgSrc : result,
        //         imgUploaded: true
        //     }

        //     this.setState({
        //         imgData: imgTemp
        //     })

        //     reader.readAsDataURL(blob);
        // }

    }
    /**
     * removeImg is to remove the image on card
     */
    removeImg = (index) =>{
        // let imgTemp = {
        //     imgSrc : "",
        //     imgUploaded: false
        // }

        // this.setState({
        //     imgData: imgTemp
        // })

        let n = this.state.playerHand
        n[index].imgUrl = null
        this.triggerChangePlayerHand(n)
    }

    // Dropzone
    onDrop = (files, index) => {

        const file = files.shift();
        const data = new FormData();
        data.append('image', file);
        const req = request.post(`${config.backend.uri}/api/upload`);

        req.on('progress',(event)=>{
            const percent = Math.floor(event.percent);
            if (percent >= 100) {
                this.setState({ imageCompleted: 100 });
            } else {
                this.setState({ imageCompleted: percent });
            }
        });

        req.send(data);
        req.end((err, res) => {


            let n = this.state.playerHand
            n[index].imgUrl = res.body.data.upload.url
            this.triggerChangePlayerHand(n)

        });

        // this.setState(state=>{
        //     let imgData = state.imgData
        //     files.map((file) =>
        //         imgData.imgSrc = URL.createObjectURL(file)
        //     )
        //     imgData.imgUploaded = true

        //     return imgData
        // })

        // this.setState({
        //     imgSrc:  URL.createObjectURL(files[0]),
        //     imgUploaded: true
        // })
    };
}

export default withApollo(withStyles(cardStyles)(GamePlayerHand))

