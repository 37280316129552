import * as React from 'react';
import { Typography } from '@material-ui/core';
import moment from 'moment';

import { IVersionInfo } from './version-info.interface';

import classes from './version-info.module.scss';

export const VersionInfo = (props: { info: IVersionInfo }): React.ReactElement => {
    const { info } = props;
    const { name, version, build, updatedAt } = info;

    return (
        <div className={classes.versionInfoContainer}>
            <Typography
                className={classes.versionInfo}
                variant="caption"
            >
                {name}
            </Typography>
            <Typography
                className={classes.versionInfo}
                variant="caption"
            >
                {version}
                -
                {build}
            </Typography>
            <Typography
                className={classes.versionInfo}
                variant="caption"
            >
                Updated {moment(updatedAt).fromNow()}
            </Typography>
        </div>
    );
};
