/** Built-in Modules */

/** Third-Party Modules */
import * as React from 'react';

/** Types */
import { ICharacterCounter } from './character-counter.interface';

/** Config */

/** API */

/** Components */

/** Styling */
import classes from './character-counter.module.scss';

export const CharacterCounter = (props: ICharacterCounter): React.ReactElement => {
  const {
    imgUrl,
    maxCount = 150,
    count = 0,
  } = props;

  return (
    <p
      id="words"
      title="Character Count"
      className={classes.characterCounter}
    >
      {imgUrl ? 0 : (maxCount - count)}
    </p>
  );
};
