import React from 'react'
import '../../../app/app.css'
import socket from '../../../api/socket'
import Linkify from 'linkifyjs/react';
import {getUsername, getUserId, isEmpty, getGeneralUserId} from '../../../utility/function'
import {withStyles, IconButton, InputBase, ClickAwayListener} from '@material-ui/core';
import {withApollo} from 'react-apollo';
import {ContactSupportOutlined, Send} from '@material-ui/icons'
import moment from "moment";

import {GAME_UPDATE_TRANSCRIPT} from '../../../api/graphql-mutation'
import EE from '../../../api/eventemitter';
import { unfocusInputField } from '../../../utility/unfocus-input-field.ts';
import { ChatMessage } from '../../../components/chat/chat-message/chat-message.tsx';

const styles = {
    /*chat wrapper*/
    chatSection: {
        position:"relative",
        background:"transparent",
        height:"100%"
    },


    /*chat message area*/
    messageArea: {
        display:"block",
        position:"absolute",
        height:"calc(100% - 60px)",
        width:"100%",
        overflowY: 'auto',
        fontFamily:"Montserrat, san-serif",
    },


    /*chat header*/
    chatHeader: {
        color: "#828282",
        padding: "0.5rem 1rem",
        fontSize:"1rem"
    },


    /*chat bubble replies*/
    chatBubble:{
        margin: "0.2rem 1rem",
        width:"fit-content",
        maxWidth:"70%",
        minWidth: "40%",
        fontSize:"0.875rem",
        overflowX: 'auto',
        zIndex:"99"
    },

    chatBot:{
        borderRadius:"5px",
        float:"left",
        textAlign:"left",
        background:"#DFEEF1",
        position:"relative",
        overflowWrap: "break-word",
        wordWrap: "break-word",
    },

    chatLeft:{
        borderRadius:"5px",
        float:"left",
        textAlign:"left",
        background:"#F3F3F3",
        position:"relative",
        overflowWrap: "break-word",
        wordWrap: "break-word",
    },

    chatRight:{
        borderRadius:"5px",
        float:"right",
        textAlign:"left",
        background:"#FFF3B4",
        position:"relative",
        overflowWrap: "break-word",
        wordWrap: "break-word",
    },

    primary:{
        fontSize:"1em",
        fontFamily:"arial",
        margin:"0px 10px 15px 10px",
        lineHeight:"14px",
    },

    secondary:{
        fontSize:"0.625rem",
        margin:"10px 10px 10px 10px",
        fontFamily:"arial",
        color:"Grey",
    },

    /*chat input*/
    inputWrapper:{
        position:"absolute",
        bottom: "10px",
        left:"0",
        width:"100%",
        height: "3rem",
    },

    typingIndicator: {
        fontSize: "0.625rem",
        color: "#4F4F4F",
        fontFamily: "arial",
        margin: "0 0 0 1.525rem",
        position: "absolute",
        bottom: "45px",
    },

    inputBox: {
        height:"100%",
        display: 'flex',
        margin: "0 0.5em",
        alignItems: 'center',
        background:"transparent",
    },


    inputFont: {
        fontSize:"0.875rem",
        fontFamily:"arial",
    },

    input: {
        marginLeft: "10px",
        flex: 1,
    },

    chatRow:{
        clear:"both"
    },

    sendBtn:{
        padding:"0.6rem",
        background:"#4173D6",
        marginLeft:"5px",
        transition:"0.5s",
        '&:hover':{
            background:"#5b8bea",
        },
        '&:focus':{
            outline:"none"
        }
    }
}

class GameGeneralChat extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            messageCount: 0,
            transcript: this.props.gameTranscript || [],
            userInput: "",
            botTranscript: ["Meow! Welcome to Once Upon a Time game jam!",
            "Check My Hand card to view your Storytelling cards",
            "If you are the Storyteller, drag the blank Story card on Canvas to write in your story.",
            "If you are NOT the Storyteller, you can steal your turn by dragging Interrupt card or use a storytelling card with same meaning as played card."],

            whoisTyping: [],
        }

        // this.typingTimer = null;
        // this.typingID = getUserId();
        // this.stopTypingInterval = 5000;
    }

    componentDidMount(){
        this.props.updateMessageCount(this.state.messageCount)

        socket.on("Game Send Message", data => {
            const isOpen = this.props.isOpen

            this.setState(prev =>({
                transcript: [...this.state.transcript,data.payload.message],
                messageCount: prev.messageCount + 1

            }),()=>{
                this.scrolltoBottom();
                // let ring = new Audio(ChatTone)
                // ring.play();
            })
        })

        socket.on("Game General Chat Typing", data => {
            const {user} = data

            if(this.state.whoisTyping.findIndex((n)=>{return n.uid === user.uid}) === -1){
                this.setState(prev=>({
                    whoisTyping: [...prev.whoisTyping,user]
               }))
            }
        })

        socket.on("Game General Chat Done Typing", data => {
            const {user} = data
            let index = this.state.whoisTyping.findIndex((n)=>{return n.uid === user.uid})

            if(index !== -1){
               let temp = this.state.whoisTyping
               temp.splice(index,1)

               this.setState({
                   whoisTyping: temp
               })
            }
        })
    }

    componentDidUpdate(){
        const isOpen = this.props.isOpen

        if(!isOpen && this.state.messageCount !== this.props.messageCount){
            this.props.updateMessageCount(this.state.messageCount)
        }
        else if(isOpen && this.state.messageCount !== this.props.messageCount){
            this.setState({
                messageCount: 0
            }, ()=>{
                this.props.updateMessageCount(this.state.messageCount)
            });
        }
    }

    render(){
        const {classes,isSpectate,spectateInfo} = this.props
        let uid = getGeneralUserId(isSpectate,spectateInfo) // to distinguish user/spectator/guest

        return(
        <div className={classes.chatSection}>
            <div className={classes.messageArea} id="game-general-chat-box">
                {/* Abu The Bot */}
                {/* {this.state.botTranscript.map((script, index)=>(
                    <div key={index} className={classes.chatRow}>
                        <div className={`${classes.chatBubble} ${classes.chatBot}`}>
                            <p className={classes.secondary}><b>Abu The Bot</b> • {moment(moment.utc()).local().format("h:mm a")}</p>
                            <Linkify className={classes.primary}  tagName="p" options={{className: classes.primary, target: {url: '_blank'}}}>
                                {script}
                            </Linkify>
                        </div>
                    </div>
                ))} */}

                {this.state.transcript.map((script, index) => (
                    <ChatMessage
                        key={index}
                        classes={classes}
                        time={script.time}
                        isOwner={script.owner !== uid}
                        ownerName={script.ownerName}
                    >
                        {script.msg}
                    </ChatMessage>
                ))}
            </div>
            <div
                 className={classes.inputWrapper}
                 onClick={(e)=>{
                    if(this.props.isSpectate && this.props.spectateInfo == null && !getUserId()){
                        e.preventDefault();
                        EE.emit("Spectator Popup");
                    }
                 }}
            >
                    <div className={classes.typingIndicator}>{this.typingIndicator()}</div>
                    <div className={classes.inputBox}>
                        <ClickAwayListener onClickAway={unfocusInputField("game-general-chat-input")}>
                            <InputBase
                                id="game-general-chat-input"
                                onFocus={()=>{
                                    if(!spectateInfo){
                                        this.isTyping()
                                    }
                                }}
                                onBlur={()=>{
                                    if(!spectateInfo){
                                        this.doneTyping()
                                    }
                                }}
                                className={classes.input}
                                placeholder="Type a Message"
                                inputProps={{style: {fontSize: "0.8rem", padding:"0.5rem", borderRadius:"5px", fontFamily:"Montserrat", background:"#fff", border:"1px solid #ccc"}}}
                                autoFocus onKeyPress={(e)=>{if(e.key === 'Enter'){this.sendGameMsg()}}}
                                value={this.state.userInput}
                                onChange={this.onInputChange.bind(this)}
                            />
                        </ClickAwayListener>
                        <IconButton className={classes.sendBtn} aria-label="Submit" type="submit" onClick={()=>{this.sendGameMsg()}}>
                            <Send style={{fontSize:"0.9rem", color:"#E1E1E1"}}/>
                        </IconButton>
                    </div>
            </div>
        </div>

        )
    }

    typingIndicator = () => {
        let typers = this.state.whoisTyping;

        if(!isEmpty(typers)){
            if(typers.length > 1){
                return "Multiple people are typing..."
            }else{
                let x = typers[0]
                return `${x.name[0].toUpperCase() + x.name.substring(1)} is typing...`
            }
        }else{
            return null
        }
    }

    isTyping = () => {
        let user = {
            uid: getUserId(),
            name: getUsername(),
        }
        socket.emit("Game General Chat Typing",{user})
    }

    doneTyping = () => {
        let user = {
            uid: getUserId(),
            name: getUsername(),
        }
        socket.emit("Game General Chat Done Typing",{user})
    }

    onInputChange = (e) => {
        this.setState({
            userInput: e.target.value
        })
    }

    sendGameMsg = () => {
        const { participants } = this.props;

        const userinfo = participants.filter((e) => e.uid === getUserId());
        // const usercolor = userinfo[0].usercolor
        // console.log(usercolor)

        if (this.state.userInput?.trim() !== '') {
            var message;
            let {spectateInfo, isSpectate} = this.props
            if(spectateInfo && isSpectate){
                message = {
                    owner: spectateInfo.spectator_id,
                    ownerName: spectateInfo.spectator_name,
                    msg: this.state.userInput,
                    time: moment.utc(),
                    email: spectateInfo.spectator_email
                }

            }else{
                message = {
                    owner: getUserId(),
                    ownerName: getUsername(),
                    msg: this.state.userInput,
                    time: moment.utc(),
                }
            }

            socket.emit("Game Send Message", {message})

            this.props.client.mutate({
                mutation: GAME_UPDATE_TRANSCRIPT,
                variables:{
                    _id: this.props.gid,
                    st: message,
                }
              })/*.then((status)=>{console.log(status)})*/

            this.setState({
                userInput: ""
            }, ()=>{
                this.scrolltoBottom();
            })
        }
    }

    scrolltoBottom = () => {
        var objDiv = document.getElementById("game-general-chat-box");
        objDiv.scrollTop = objDiv.scrollHeight;
    }
}

export default withApollo(withStyles(styles)(GameGeneralChat))
