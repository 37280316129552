import './font.css'
import { createMuiTheme } from '@material-ui/core';

export const theme = createMuiTheme({
    palette: {
        primary: { main: '#FFAA0D' },
        secondary: { main: '#4173D6' }, 
    },
    typography: { fontFamily: [
        'Montserrat',
        'Covered By Your Grace'
        ].join(','),
        fontSize:14,
    },
    overrides: {
        MuiCssBaseline: {
        '@global': {
            '@font-face': ['Montserrat'],
        },
        },
    },
});
