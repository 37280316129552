export const styles = {
    card:{
        background:"transparent",
        fontFamily:"Montserrat, sans-serif",
        boxShadow:"none",
        overflow:"hidden", 
        position:"relative", 
        margin:"5px auto", 
        width:"160px", 
        height:"230px", 
        borderRadius:"5px"
    },
    
    /* Front Card Cover */
    cardContent:{
        position:"absolute",
        height:"calc(100%)",
        width:"100%",
        padding:"10px",
        top:"0",
        left:"0",
        background:"#FFF",
        transition:"0.5s",
        transformStyle:"preserve-3d",
        transform:"rotateY(0deg)",
        border:"0.5px solid #c4c4c4",
        borderRadius:"5px",
        opacity:1,
        zIndex:1
    },

    cardContentFlip:{
        position:"absolute",
        height:"calc(100%)",
        width:"100%",
        padding:"10px",
        background:"#FFF",
        top:"0",
        left:"0",
        transition:"0.5s",
        transformStyle:"preserve-3d",
        transform:"rotateY(180deg)",
        border:"0.5px solid #c4c4c4",
        borderRadius:"5px",
        opacity:0,
        zIndex:0
    },

    iconRight:{
        fontSize:"10px",
        float:"right",
        '&:focus':{
            outline:"none"
        }
    },

    sendRight:{
        float:"right",
        width:"30px", 
        height:"30px", 
        fontSize:"16px",
        marginTop:"10px", 
        color:"#4173D6", 
        opacity:1,
        '&:focus':{
            outline:"none"
        }
    },

    sendRightHide:{
        float:"right",
        width:"30px", 
        height:"30px", 
        fontSize:"16px",
        marginTop:"10px", 
        color:"#4173D6", 
        opacity:0,
        '&:focus':{
            outline:"none"
        }
    },

    contentWrapper:{
        margin:"0 0 5px 0",
        height:"calc(100% - 95px)"
    },

    content:{
        width:"100%",
        fontFamily:"Inter",
        fontWeight:"500"
    },

    counterShow:{
        display:"inline-block",
        fontSize:"10px",
        margin:"0",
        marginTop:"0px",
        opacity:1,
        color:"#C4C4C4"
    },

    counterHide:{

        opacity:0
    },

    username:{
        fontSize:"10px",
        margin:"0", 
        fontWeight:"bold"
    },

    pin:{
        fontSize:"12px", 
        marginLeft:"2px", 
        marginTop:"2px"
    },
    /* Back Card Cover */
    cardCover:{
        position:"absolute",
        height:"calc(100%)",
        width:"100%",
        padding:"10px",
        transition:"0.5s",
        top:"0",
        left:"0",
        background:"#FFF",
        opacity:1,
        transformStyle:"preserve-3d",
        transform:"rotateY(180deg)",
        border:"0.5px solid #c4c4c4",
        borderRadius:"5px",
        zIndex:0
    },

    cardCoverFlip:{
        position:"absolute",
        height:"calc(100%)",
        width:"100%",
        padding:"10px",
        top:"0",
        left:"0",
        transition:"0.5s",
        background:"#FFF",
        opacity: 1,
        transformStyle:"preserve-3d",
        transform:"rotateY(0deg)",
        border:"0.5px solid #c4c4c4",
        borderRadius:"5px",
        zIndex:1
    },

    cardCoverIconWrapper:{
        margin:"25px 0",
    },
    
    cardCoverCategory:{
        fontSize:"12px",
        textAlign:"center",
        fontWeight:"bold"
    },

    cardCoverInfo:{
        fontSize:"10px",
        textAlign:"center"
    },

    /* Menu */
    menuItem:{
        fontSize:"12px"
    },
    menuIcon:{
        fontSize:"16px",
        marginRight:"5px"
    },

    tag:{
        display:"inline-block", 
        cursor:"pointer", 
        background:"#D1D1D1", 
        width:"fit-content", 
        margin:"0", 
        outline:"none", 
        color:"#4F4F4F", 
        marginTop:"10px",
        fontSize:"10px",
        padding:"5px 10px", 
        marginRight:"10px", 
        borderRadius:"10px"
    },
    imgHolder:{
        position:"relative", 
        width:"100%", 
        height:"125px", 
        overflow:"hidden",
        "&:hover $imgDelete":{ 
            background:"rgba(0,0,0, 0.7)",
            color:"white"
        }
    },

    imgDelete:{
        position:"absolute", 
        right:"5px", 
        top:"5px", 
        width:"10px", 
        height:"10px", 
        background:"transparent", 
        color:"transparent", 
        zIndex:"2", 
        transition:"0.5s", 
    },

    dropZone:{
        width:"100%", 
        height:"125px", 
        border:"1px dashed #C4C4C4", 
        background:"#F2F2F2", 
        color:"#C4C4C4", 
        lineHeight: "110px", 
        fontSize:"8px", 
        fontFamily:"Montserrat", 
        textAlign:"center"
    }
}

export default styles;