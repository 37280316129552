import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface IAuthStoreState {
    userId: string;
    username: string;
}

export const initialState: IAuthStoreState = {
    userId: "",
    username: "",
};

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setUserId: (state: IAuthStoreState, action: PayloadAction<IAuthStoreState['userId']>) => {
            state.userId = action.payload;
        },
        setUsername: (state: IAuthStoreState, action: PayloadAction<IAuthStoreState['username']>) => {
            state.username = action.payload;
        },
    },
});

export const { setUserId, setUsername } = authSlice.actions;

export const authReducer = authSlice.reducer;
