import * as React from "react";

export const unfocusInputField =
    (inputFieldId: string) => (event: React.MouseEvent<Document>) => {
        const inputFieldEl = document.getElementById(inputFieldId) as HTMLFormElement;

        if (inputFieldEl && !event.currentTarget.isSameNode(inputFieldEl)) {
            inputFieldEl.blur();
        }
    };
