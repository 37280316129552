import * as React from 'react';
import Linkify from 'linkifyjs/react';
import moment from 'moment';

import { IChatMessageProps } from './chat-message.interface';

export const ChatMessage = (props: IChatMessageProps) => {
    const {
        classes,
        children,
        time,
        isOwner = false,
        ownerName = '',
    } = props;

    const timestamp = (typeof time === 'string' ? moment(time) : time).local().format('h:mm a');

    return (
        <div className={classes.chatRow}>
            <div className={`${classes.chatBubble} ${isOwner ? classes.chatLeft : classes.chatRight}`}>
                <p className={classes.secondary}>
                    {isOwner && (<><b>{ownerName}</b> • </>)}
                    {timestamp}
                </p>
                <Linkify
                    // @ts-ignore
                    className={classes.primary}
                    tagName="p"
                    options={{className: classes.primary, target: {url: '_blank'}}}
                >
                    {children}
                </Linkify>
            </div>
        </div>
    );
};
