import axios from 'axios'
import session from '../model/session'
import config from './backend'

export const userAUTH = (user,history) => {

         if(user._id){
            axios({
              url:  `${config.backend.uri}/graphql` ,
              method: 'post',
              data: {
                query: `
                  query{
                    userById(_id: "${user._id}"){
                      _id
                      name{
                        first
                        last
                      }
                      email
                    }
                  }

                `
              }
            }).then((result) => {
              if(result.data.data.userById){
                console.log("user found")
              }else{
                session.clear().then(()=>{
                  if(history){
                    history.push({
                      pathname:`/login`,
                    })
                  }else{
                    window.location.href = "/login"
                  }

                });
              }
            }).catch((error)=>{
                console.log(error)
            });
        }else{
          console.log("null ID")
        }
  
}