import localforage from 'localforage';
import Store from './store';
import User from './user';

export class Session extends Store {
  store = {
    user: User.mock(),
    topics: [],
    jamSessionId: ''
  }

 /* clearSessionsExceptUserSession() {
    this.set({
      draft: null,
      template: null,
      story: null,
      selectedParents: [],
      draftSession: null,
      draftSessions: {
        next: null,
        current: null,
        completed: [],
        remaining: [],
      },
    });
    // await this.save();
    return this;
  } */

  async clear() {
    await localforage.clear();
    await this.fetchLocal();
    return this;
  }

 /* set template(template) {
    this.store.template = template;
    return this.store.template;
  }



  get template() {
    return this.store.template;
  } */

  save() {
    localforage.setItem('session', this.toObject());
  }

  get user() {
    return this.store.user;
  }

  set user(user) {
    this.set('user', new User(user));
  }

  getUser() {
    // return this.store.user;
    let u = this.store.user;
    return u
  }

  setUser(user) {
    this.set('user', new User(user));
    this.save();
    return this;
  }

  setTopic(selected) {
    this.set('topics', selected );
    this.save();
    return this;
  }

  getTopic() {
    return this.store.topics;
  }


  removeTopic(){
    this.set('topics', []);
    this.save();
    return this;
  }

  setJamSessionId(id){
    this.set('jamSessionId', id);
    this.save();
    return this;
  }

  getJamSessionId(){
    return this.store.jamSessionId;
  }

  removeJamSessionId(){
    this.set('jamSessionId', '');
    this.save();
    return this;
  }

  async fetchLocal() {
    const session = await localforage.getItem('session');
    if (session) { 
      if (session.user) {
        this.set({ user: new User(session.user) });
      }
      if (session.topics){
        this.set({ topics: session.topics})
      }
      if(session.jamSessionId){
        this.set({ jamSessionId: session.jamSessionId})
      }
    }
  }

 /* selectTemplate(template) {
    if (!template) {
      return this;
    }

    this.set({
      template,
      selectedParents: [],
    });

    this.save();
    return this;
  }

  getTemplate() {
    return this.store.template;
  }

  set selectedCard(selectedParents) {
    this.set({ selectedParents });
    console.log('Setting the selectedCard you idiot', selectedParents);

    this.save();

    return this;
  }

  get selectedCard() {
    return this.store.selectedParents;
  }

  selectCard(selectedParents) {
    if (!selectedParents) {
      return;
    }
    const cleanSelectedParents = selectedParents.map((parent) => {
      const newParent = _.clone(parent);
      _.unset(newParent, 'getRandomCard');
      _.unset(newParent, 'classes');
      return newParent;
    });
    console.log('Setting the selectedParents you idiot cibai', selectedParents);
    console.log('cleanSelectedParents', cleanSelectedParents);

    this.set('selectedParents', cleanSelectedParents);
    this.save();
    return this;
  }

  getselectedCard() {
    return this.store.selectedParents;
  }

  createDraft(props = {}) {
    const draft = new Draft({
      ...props,
      _id: Draft.generateId(),
      template: props.template || this.get('template'),
      parents: props.parents || this.get('selectedParents'),
    });

    this.set('draft', draft);

    if (!draft.template) {
      draft.set('template', this.get('template'));
    }

    draft.save();
    return draft;
  }

  startJam(draft) {
    console.log('draftdraftdraftdraftdraftdraft', draft);
    if (draft) {
      this.set('draft', new Draft(draft));
      this.set('template', new Template(draft.template));
    }

    // const draft = new Draft(props.toObject());

    // if (!draft) {
    //   throw new Error('Draft required to start jam');
    // }

    // draft.set({
    //   createdBy: this.get('user')
    // });

    // const { draftSessions, template } = this.store;

    // const newDraftSessions = _.cloneDeep({
    //   current: {},
    //   next: _.head(template.sessions),
    //   remaining: template.sessions,
    //   completed: []
    // });

    // console.log('Saving draft sessions', newDraftSessions);

    // this.set({
    //   draftSessions: thraftSessions.set(newDraftSessions)
    // })

    this.save();
    return this;
  }

  startDraftSession(draftSession, draft) {
    // const { draftSessions, template } = this.store;

    // const remainingSessions = _.dropWhile(template.sessions, (s) => s._id !== draftSession._id);

    // const newDraftSessions = _.cloneDeep({
    //   current: draftSession,
    //   next: _.nth(remainingSessions, 1),
    //   remaining: remainingSessions,
    //   completed: draftSessions.completed
    // });

    // this.set({
    //   draftSessions: this.store.draftSessions.set(newDraftSessions)
    // })

    this.set('draftSession', new DraftSession(draftSession));
    this.set('draft', new Draft(draft));

    console.log('Set draft session', draftSession);
  }

  endDraftSession(draftSession) {
    if (!this.store.draftSession) {
      return;
    }
    console.log('End draft session', this.store.draftSession.toObject());

    this.store.draftSession.syncCreate();

    // const { draftSessions, template } = this.store;

    // const remainingSessions = _.slice(_.dropWhile(template.sessions,
    //   (s) => s._id !== draftSessions.current._id), 1);

    // const newDraftSessions = new DraftSession({
    //   current: draftSessions.current,
    //   next: draftSessions.next,
    //   remaining: remainingSessions,
    //   completed: [...draftSessions.completed, draftSessions.current]
    // });

    // console.log('Got new draft sessions', draftSessions, newDraftSessions);

    // this.set({
    //   draftSessions: this.store.draftSessions.set(newDraftSessions)
    // });

    this.save();
    var ds = this.store.draftSession.store.fields
    console.log(ds)
    this.store.temp.push(ds)
    console.log(this)
    console.log('Ended draft session', this.store.draftSession);
  }*/
}

const session = new Session();
export default session;
