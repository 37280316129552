export const styles = {
    '@global': {
        '*::-webkit-scrollbar': {
          width: '0.4em',
          height: '0.4em',
          backgroundColor:"#C4C4C4",
        },
        '*::-webkit-scrollbar-track': {
          '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        '*::-webkit-scrollbar-thumb': {
          backgroundColor: '#FDAF2A',
          borderRadius:"10px",
          outline: '1px solid slategrey'
        },
    },
    container:{
        width:"100%",
        margin:"0 auto", 
        maxWidth:"1400px",
    },
    
    banner:{
        width:"100%",
        borderRadius:"5px"
    },

    card:{
        background:"#FAF9F9",
        margin:"auto",
        marginTop:"1em",
        marginBottom:"1em",
        color:"#4F4F4F",
        boxShadow:"none",
        border:"1px solid #D1D1D1",
    },

    cardHeaderContainer:{
        background:"transparent",
        border:"none",
        padding:"1rem 1rem 0 0.25rem",
        marginTop:"2em"
    },
    
    cardHeader:{
        fontWeight:"bold",
        fontSize:"1rem",
        textAlign:"left",
        fontFamily: "Inter",
    },

    cardContent:{ 
        position:"relative",
        padding:"1rem",
        fontSize:"0.875rem",
        textAlign:"left"
    },

    highlight:{
        color:"#4173D6",
        fontSize:"0.7rem",
        cursor: "pointer"
    },



    yellowCard:{
        background:"#F2C94C",
    },

    blueCard:{
        background:"#4173D6",
    },


    cardList:{
        borderRadius:"5px",
        margin:"0 .5rem",
        padding: '1rem'
    },

    cardListText:{
        margin:"0",
        color:"white",
        textAlign:"center",
        fontSize:"0.7rem",
        fontWeight:"bold",
    },
    cardListHeader:{
        textOverflow: "ellipsis", 
        overflow: "hidden",
        whiteSpace: "nowrap",
        fontSize:"1rem",
    },

    cardListAvatar:{
        margin:"auto",
        marginTop:"1rem",
        marginBottom:"1rem",
        width:"4rem",
        height:"4rem",
        background:"#4F4F4F"
    },
    cardListButton:{
        width:"100%",
        background:"rgba(255,255,255,.5)"
    },

    popUp: {
        width: "24rem",
        background: "#FAF9F9",
        border: '1px solid #d1d1d1',
        fontFamily:"arial",
        padding:"0",
        borderRadius:"5px",
    },

    popUpHeader:{
        margin:"1.8rem",
        fontWeight:"bold",
        fontSize:"1rem",
        color:"#4F4F4F",
    },

    popUpContent:{
        margin:"1.8rem",
    },

    field:{
        display:"block",
        background:"#FAF7EA",
        border:"1px solid #d1d1d1",
        borderRadius:"5px",
        margin:"0.5em 0 1em 0",
        padding:"0.5em 1em"
    },

    selectField:{
        fontSize: "0.875rem", 
        margin:"0.5em 0 1em 0", 
        background:"#FAF7EA", 
        borderRadius:"5px", 
        border:"1px solid #d1d1d1",
        
        padding:"0 0.5em"
    },

    popUpBtn:{
        display:"block",
        background:"#FDAF2A",
        color:"#fff",
        borderRadius:"5px",
        width:"100%",
        padding:"0.5rem 0.5rem",
        marginTop:"0.5rem",
        fontSize: "0.875rem",
        fontWeight:"bold"
    },

    popUpBtnDisabled:{
        display:"block",
        background:"#D1D1D1",
        color:"#fff",
        borderRadius:"5px",
        width:"100%",
        padding:"0.5rem 0.5rem",
        marginTop:"0.5rem",
        fontSize: "0.875rem",
        fontWeight:"bold"
    },

    modal:{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },

    gridList: {
        paddingBottom:"0.5rem",
        flexWrap: 'nowrap',
        transform: 'translateZ(0)',
    },

    gridListCard: {
        margin: "0 0.5rem",
        borderRadius: "5px",
        overflowWrap: "break-word",
        wordWrap: "break-word",
    },

    gridListCardTile: {
        padding: '1rem'
    },

    createButton:{
        float:"right", 
        marginTop:"-7px", 
        border:"2px solid orange", 
        color:"orange",
        "&:focus":{
            outline:"none"
        }
    },

    gameCreationGrid:{
        display:"inline-block",
        backgroundSize:"180px 200px",
        height:"200px", 
        width:"180px", 
        borderRadius:"5px", 
        padding:"1rem",
        margin:"10px"
    },
    //New

    jamBox:{
        borderRadius:"10px", 
        overflow:"hidden", 
        height:"100%",
        marginBottom:"10px",
        
        border:"0.2px solid #d1d1d1",
        background:"white",
        transition:"0.5s",
        '&:hover':{
            cursor:"pointer",
            opacity:"0.8"
        }
    },
    

    jamBoxImg:{
        background:"#3E4554", 
        height:"100%", 
        color:"white", 
        textAlign:"center", 
        padding:"5px"
    },
    jamBoxFont:{
        position:"relative", 
        color:"#4F4F4F", 
        padding:"5px 1rem", 
        fontSize:"16px",
        fontFamily: "Inter",
        fontWeight:"500",
    },
    jamBoxType:{
        position:"absolute", 
        borderRadius:"5px", 
        padding:"2px 5px", 
        bottom:"0", 
        right:"8px", 
        background:"orange", 
        color:"white",
        fontSize:"10px"
    },
    jamBoxIcon:{
        fontSize:"14px", 
        marginTop:"-5px",
        marginRight:"5px",
        color:"#4f4f4f"
    },
    jamEmpty:{
        color:"#C4C4C4", 
        fontSize:"14px",
        width:"100%", 
        textAlign:"center", 
        marginBottom:"20px"
    },
    //Button
    hostBtn:{
        background:"orange", 
        color:"white", 
        padding:"5px 20px", 
        marginRight:"10px",
        transition:"0.5s",
        outline:"none",
    },

    displayBtn:{
        background:"transparent", 
        marginLeft:"-5px",
        width:"fit-content",
        color:"#2554C7", 
        padding:"5px", 
        borderRadius:"5px",
        marginRight:"10px"
    },

    modBtn:{
        position:"absolute",
        bottom:"20px", 
        right:"20px",
        background:"transparent", 
        color:"#FA841B",  
        border:"1px solid #FA841B",
        padding:"5px 20px",
        outline:"none",
        fontWeight:"bold",
        transition:"0.5s",
    },

    filterBtn:{
        borderRadius:"20px", 
        background:"#F2F2F2", 
        padding:"5px 20px",
        border:"1px solid #C4C4C4", 
        marginRight:"10px",
        color:"#4F4F4F",
        transition:"0.5s",
        '&:hover':{
            opacity:"0.5"
        },
        '&:focus':{
            outline:"none"
        }
    },
    moreBtn:{
        width:"100%",
        padding:"10px 0px",
        '&:focus':{
            outline:"none"
        }
    },

    ownJamType:{
        padding:"5px", 
        float:"left", 
        borderRadius:"5px", 
        background:"orange",
        color:"white",
        margin:"0px 5px",
        marginTop:"10px"
    },

    ownJamLabel:{
        height:"20px", 
        color:"white", 
        fontSize:"2px", 
        display:"inlne-block"
    },
    ownJamEmpty:{
        color:"#C4C4C4", 
        width:"100%", 
        textAlign:"center", 
        marginBottom:"20px"
    },
    ownJamEmptyIcon:{
        color:"#C4C4C4", 
        fontSize:"50px", 
        margin:"auto"
    },

    ownJamIcon:{
        margin:"0", 
        marginTop:"7px", 
        fontSize:"36px", 
        fontWeight:"bold"
    },

    ownJamHost:{
        float:"right", 
        marginTop:"5px", 
        color:"orange", 
        cursor:"pointer"
    },

    featuredTitle:{
        textAlign:"center", 
        fontSize:"18px", 
        position:"absolute", 
        bottom:"0", 
        width:"calc(100% - 10px)", 
        left:"5px", 
        color:"white"
    },

    featuredType:{
        position:"absolute", 
        borderRadius:"2px", 
        padding:"2px 10px", 
        top:"10px",
        left:"10px", 
        background:"orange", 
        color:"white"
    },

    //TEMPLATE PREVIEW
    previewCard:{
        display:"block", 
        borderRadius:"5px", 
        marginBottom:"10px",
        padding:"10px", 
        border:"0.5px solid #C4C4C4",
        overflowWrap: "break-word",
        wordWrap: "break-word",
    },

    previewCardIcon:{
        fontSize:"10px", 
        marginRight:"5px", 
        marginTop:"-2px"
    },

    previewCardLabel:{
        display:"inline-block", 
        margin:"0", 
        marginRight:"5px", 
        padding:"2px 5px", 
        borderRadius:"5px", 
        fontSize:"10px",  
        color:"white"
    },

    previewBtn:{
        background:"orange", 
        width:"100%", 
        marginBottom:"10px", 
        color:"white"
    },

    preview:{
        width:"36rem",
        padding:"1rem", 
        borderRadius:"10px", 
        marginBottom:"10px", 
        background:"#FAF9FA"
    },

    previewImage:{
        width:"350px", 
        height:"180px", 
        marginBottom:"20px",
        backgroundPosition:"center center", 
        backgroundRepeat: "no-repeat",
    },

    displayBox:{
        position:"relative",
        borderRadius:"10px", 
        marginTop:"1em",
        transition:"0.5s",
        '&:hover':{
            cursor:"pointer",
            opacity:"0.8"
        }
    },
    displayImg:{
        position:"absolute", 
        height:"120px", 
        left:"0", 
        borderRadius:"0 0 0 10px", 
        zIndex:"1"
    },

    displayBg:{
        position:"relative", 
        width:"100%", 
        height:"120px", 
        //marginTop:"20px", 
        borderRadius:"10px", 
        background:"#f1e78a", 
        border:"1px solid #f1e78a", 
        zIndex:'0'
    },

    displayTitle:{
        margin:"0", 
        marginBottom:"5px", 
        fontWeight:"900",
        fontSize:"20px",
        color:"black",
    },

    displaySubTitle:{
        margin:"0", 
        paddingRight:"10px",
        fontSize:"16px", 
        color:"black",
        fontWeight: "500",
        lineHeight: "normal"
    },

    displayTitleWrapper:{
        marginLeft:"140px", 
        marginTop:"7%",
        fontFamily:"Inter",
    },

    dropzone: {
        width: "100%",
        height: 300,
        background: "#eee",
      },
      
    dropzoneInput: {
    width: "100%",
        height: "100%",
    },

    pagination: {
        padding: '0 10px 0 0'
    },

    paginationToolbar: {
        padding: 0,
    },
}

