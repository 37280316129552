import React from 'react';

import '../app/app.css';

export default function Loader() {
    return (
        <div className="Loader">
        </div>
    );
}
